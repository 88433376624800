import { Spacer } from "../../../../components/Layout/Layout";
import ListWithDot from "../../../../ui-lib/components/ListWithDot/ListWithDot";
import { AlarmRuleResponse } from "../../../../core/api/alarmCodeRule";
import NumberInput from "../../../../ui-lib/components/Inputs/NumberInput";
import Icon from "../../../../ui-lib/components/Icon";
import styles from "./CodeOverrideModal.module.css";
import useTranslations from "../../../../core/i18n/useTranslations";

interface CodeOverrideModalProps {
  ruleData: AlarmRuleResponse;
  rulesError: string;
  newAlarmCode?: string | number;
  setNewAlarmCode: (code: string) => void;
  disableButtons: (state: boolean) => void;
  getValidationError: () => string | undefined;
}

export const CodeOverrideModal = ({
  ruleData,
  rulesError,
  newAlarmCode,
  setNewAlarmCode,
  disableButtons,
  getValidationError,
}: CodeOverrideModalProps) => {
  const t = useTranslations();

  if (rulesError.length) {
    disableButtons(true);
  } else {
    disableButtons(false);
  }

  const getNewClassifications = () =>
    ruleData?.classifications.map(
      (classification) =>
        `${t(`CommonEnum:AlarmFlagEnum${classification}`)} (${classification})`
    ) || [];

  return (
    <>
      <p>{t("AlarmHistory:alarm_code_description")}</p>
      <Spacer size={16} />
      <p>{t("AlarmHistory:alarm_code_classifications")}</p>
      <ListWithDot items={getNewClassifications()} />
      <Spacer size={8} />
      <NumberInput
        label={t("AlarmHistory:alarm_code_new_label")}
        placeholder={t("AlarmHistory:alarm_code_new_placeholder")}
        onChange={(e) =>
          setNewAlarmCode(`${Math.trunc(parseInt(e.currentTarget.value, 10))}`)
        }
        value={newAlarmCode ? `${newAlarmCode}` : ""}
        validationError={getValidationError()}
      />
      <Spacer size={16} />
      <div className={styles.linkWrapper}>
        <a
          className={styles.alarmCodesLink}
          href="https://skyresponse.com/development/alarmclient/programming-details/alarmcodes/"
          target="blank"
        >
          {t("AlarmHistory:alarm_code_view_other")}
        </a>
        <Icon name="external-link" color="Primary-700" />
      </div>
    </>
  );
};
