import Flag from "react-country-flag";
import Icon from "../../Icon";
import styles from "../Menu.module.css";

export interface PopupContextMenuLinkButtonProps {
  text: any;
  icon?: string;
  flag?: string;
  onClick?: (e?: Event) => void;
  disabled?: boolean;
}

export const PopupContextMenuLinkButton = ({
  text,
  icon = undefined,
  flag = undefined,
  onClick = undefined,
  disabled = false,
}: PopupContextMenuLinkButtonProps) => (
  <button
    type="button"
    className={styles.popupContextMenuLinkButton}
    onClick={() => onClick && onClick()}
    disabled={disabled}
  >
    {icon ? <Icon name={icon} size={16} color="Grey-400" /> : null}
    {flag ? (
      <Flag
        svg
        countryCode={flag}
        className={styles.dropdownFlag}
        aria-label="language"
      />
    ) : null}
    <span className={styles.popupContextMenuLinkButtonText}>{text}</span>
  </button>
);
