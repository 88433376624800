import useTranslations from "../../../core/i18n/useTranslations";
import StyledModal from "../../../ui-lib/components/Modal/Modal";
import { Spacer } from "../../../components/Layout/Layout";
import { ITwoFactorData } from "../../../core/api/administrators/types";

export const TOTPModal = ({
  onClose,
  isOpen,
  data,
}: {
  onClose: () => void;
  isOpen: boolean;
  data?: ITwoFactorData;
}) => {
  const t = useTranslations();
  return (
    <StyledModal
      onClose={onClose}
      isOpen={isOpen}
      modalTitle={t(
        "Administrator:general_account_security_input_auth_type_google"
      )}
      closeOnDocumentClick={false}
      css={{ maxWidth: 960 }}
      approveBtnText={t("Common:ok")}
      onApproveBtnClick={onClose}
    >
      <p style={{ color: "var(--Grey-600)" }}>{t("Administrator:totp_text")}</p>
      <Spacer size={16} />
      <p>{data?.preSharedSecret}</p>
    </StyledModal>
  );
};
