import { useEffect, useRef, useState } from "react";
import { classNames } from "primereact/utils";
import Collapse from "react-css-collapse";
import { Icon } from "../Icon";

import styles from "./CollapsibleBlock.module.css";

export interface CollapsibleProps {
  title: string;
  onClick(): void;
  isShown: boolean;
  withScroll?: boolean;
}
export default function CollapsibleBlock({
  title,
  onClick,
  isShown,
  withScroll,
}: Readonly<CollapsibleProps>) {
  const collapseRef = useRef<any>();

  useEffect(() => {
    if (isShown && withScroll)
      setTimeout(() => {
        collapseRef?.current?.scrollIntoView({
          block: "start",
          behavior: "smooth",
        });
      }, 250);
  }, [isShown]);

  return (
    <button
      ref={collapseRef}
      className={styles.container}
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
      onKeyDown={(e) => {
        if (e.code === "Enter" || e.code === "Space") {
          e.preventDefault();
          onClick();
        }
      }}
    >
      <div className={styles.titleContainer}>
        <span className={styles.title}>{title}</span>
        {isShown ? (
          <Icon name="chevron-up" color="Primary-700" size={18} />
        ) : (
          <Icon name="chevron-down" color="Primary-700" size={18} />
        )}
      </div>
    </button>
  );
}
export function CollapsibleSelfcontainedBlock({
  title,
  children,
  noScroll,
}: Readonly<{
  title: string;
  children: React.ReactNode;
  noScroll?: boolean;
}>) {
  const [isShown, setIsShown] = useState<boolean>(false);

  const refObject = useRef<any>();

  const onClick = () => {
    setIsShown(!isShown);
  };

  useEffect(() => {
    if (isShown && !noScroll)
      setTimeout(() => {
        refObject?.current?.scrollIntoView({
          block: "start",
          behavior: "smooth",
        });
      }, 250);
  }, [isShown]);

  return (
    <div>
      <button
        className={styles.container}
        onClick={(e) => {
          e.preventDefault();
          onClick();
        }}
        onKeyDown={(e) => {
          if (e.code === "Enter" || e.code === "Space") {
            e.preventDefault();
            onClick();
          }
        }}
        ref={refObject}
      >
        <div className={styles.titleContainer}>
          <span className={styles.title}>{title}</span>
          {isShown ? (
            <Icon name="chevron-up" color="Primary-700" size={18} />
          ) : (
            <Icon name="chevron-down" color="Primary-700" size={18} />
          )}
        </div>
      </button>
      <Collapse
        isOpen={isShown}
        aria-hidden={isShown ? "false" : "true"}
        className={classNames({ [styles.hidden]: !isShown })}
      >
        {children}
      </Collapse>
    </div>
  );
}
