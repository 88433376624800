import { ReactNode } from "react";
import useUser from "../core/user/useUser";

export enum Role {
  UserAdminsAll = "UserAdminRelation",
  AlarmReceptionsCreate = "RcCreate",
  OrganizationsList = "Organizations",
  EditAlarmReceptionMenu = "ResponseCenterMenuEdit",
  EditIPWhiteList = "IPWhiteListEdit",
  EditAlarmReceptionAdvancedSettings = "RcAdvancedSettings",
  EmergencyCenterSettings = "EmergencyCenterSettings",
  AlarmReceptionCalendarEdit = "RcCalendarEdit",
  PeriodicInteractions = "User/Interactions",
  BetaAccess = "NewAdminBeta",
  AlarmReceptionsCreatePublic = "RcCreatePublic",
  ObjectTestAlarm = "UserTestAlarm",
  ObjectDelete = "DeleteUser",
  SuperAdmin = "SkygdSuperAdmin",
  DownloadAdminEvents = "DownloadAdminEvents",
  EditOrganizations = "EditOrganizations",
  DeleteOrganizations = "DeleteOrganizations",
  ActivateSubscriptions = "ActivateWithoutCode",
  ApplicationMenuAlternatives = "ApplicationMenuAlternatives",
}

export const RoleFeature = ({
  requires,
  children,
  inclusive = false,
}: {
  requires: Role[];
  children: ReactNode;
  inclusive?: boolean;
}) => {
  const { config } = useUser();

  let hasPremissions = !inclusive;
  requires.forEach((role) => {
    if (inclusive && config?.show?.includes(role)) {
      hasPremissions = true;
    }
    if (!inclusive && !config?.show?.includes(role)) {
      hasPremissions = false;
    }
  });

  return hasPremissions ? <>{children}</> : null;
};
