import { Controller, useForm } from "react-hook-form";
import React, { useState } from "react";
import classNames from "classnames";
import moment from "moment";
import { useParams } from "react-router-dom";
import {
  ContactDetails,
  IScheduleItem,
} from "../../../core/api/contacts/types";
import useTranslations from "../../../core/i18n/useTranslations";
import { Column, Row, Spacer } from "../../../components/Layout/Layout";
import styles from "../../../ui-lib/components/Scheduler/ScheduleItem.module.css";
import Icon from "../../../ui-lib/components/Icon";
import InputContainer from "../../../ui-lib/components/Inputs/InputContainer";
import TimePicker from "../../../ui-lib/components/DateAndTime/TimePicker";
import Button from "../../../ui-lib/components/Button/Button";
import { Horizontal } from "../../../ui-lib/components/Divider/Divider.stories";
import StyledModal from "../../../ui-lib/components/Modal/Modal";
import useUser from "../../../core/user/useUser";
import { NoScheduleItems } from "./NoScheduleItems";
import { notifyApiErrors } from "../../../core/helpers/helpers";
import { encodeTime } from "../../../core/api/contacts/helpers";
import { updateScheduleItem } from "../../../core/api/contacts/contacts";

export const OldScheduleHandler = ({
  contactData,
  update,
}: {
  contactData: ContactDetails;
  update: (data: ContactDetails) => Promise<void>;
}) => {
  const t = useTranslations();
  const { id: contactId } = useParams<{ id: string }>();
  const { config, authenticatedRequest } = useUser();
  const { control, handleSubmit, reset } = useForm<ContactDetails>({
    defaultValues: {
      ...contactData,
    },
  });
  const isNewSchedulerAvailable = config?.show.includes("ContactSchedule");

  const [isFormEditable, setIsFormEditable] = useState(
    !contactData.startTime && !contactData.endTime
  );
  const [showSchedule, setShowSchedule] = useState(
    !!(contactData.startTime || contactData.endTime)
  );
  const [isChangeAvailabilityModalOpened, setIsChangeAvailabilityModalOpened] =
    useState(false);

  const formatTime = (value: string) =>
    value ? `${value.slice(0, 2)}:${value.slice(2)}` : value;

  const clearTime = async () => {
    const contactDataToUpdate = { ...contactData };
    contactDataToUpdate.startTime = undefined;
    contactDataToUpdate.endTime = undefined;
    update(contactDataToUpdate);
  };

  const changeToNewScheduler = async () => {
    try {
      const newScheduleItems = [
        {
          startTime: encodeTime(contactData.startTime),
          endTime: encodeTime(contactData.endTime),
          startDate: moment(new Date()).format("YYYY-MM-DD"),
          repeatDays: [0, 1, 2, 3, 4, 5, 6],
          repeatType: "RepeatDays",
        },
      ] as IScheduleItem[];
      if (contactData?.endTime! < contactData?.startTime!) {
        newScheduleItems[0].endTime = "23:59";
        newScheduleItems.push({
          startTime: "00:00",
          endTime: encodeTime(contactData.endTime),
          startDate: moment(new Date()).format("YYYY-MM-DD"),
          repeatDays: [0, 1, 2, 3, 4, 5, 6],
          repeatType: "RepeatDays",
        } as IScheduleItem);
      }
      const promises = newScheduleItems.map((item) =>
        updateScheduleItem(+contactId, item, authenticatedRequest)
      );
      await Promise.all(promises);
      clearTime();
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    }
  };

  return (
    <Column type="top" align="start" style={{ width: "100%" }}>
      <StyledModal
        onClose={() => {
          setIsChangeAvailabilityModalOpened(false);
        }}
        isOpen={isChangeAvailabilityModalOpened}
        modalTitle={t("Contacts:change_availability_type")}
        closeOnDocumentClick={false}
        css={{ maxWidth: 960 }}
        customFooter={() => (
          <Row type="left">
            <Button text={t("Common:ok")} onClick={clearTime} />
            <Spacer size={8} />
            <Button
              variant="secondary"
              text={t("Common:cancel")}
              onClick={() => {
                setIsChangeAvailabilityModalOpened(false);
              }}
            />
          </Row>
        )}
      >
        <p style={{ color: "var(--Grey-600)" }}>
          {t("Contacts:change_availability_confirmation")}
        </p>
      </StyledModal>

      <Row type="fill" align="end">
        <Column align="start">
          <h1>{t("Menu:availability")}</h1>
          <Spacer size={8} />
          <p>{t("Contacts:availability_description")}</p>
        </Column>

        {!contactData.endTime && !contactData.startTime && !showSchedule && (
          <Button
            variant="border-link"
            image="plus"
            text={t("Contacts:set_availability")}
            onClick={() => setShowSchedule(true)}
          />
        )}
      </Row>

      <Spacer size={32} />

      {showSchedule ? (
        <Row type="space" align="end">
          {isFormEditable ? (
            <form
              id="old-scheduler"
              onSubmit={handleSubmit(async (values: ContactDetails) => {
                if (values.startTime || values.endTime) {
                  if (values.startTime) {
                    values.startTime = values.startTime?.replace(":", "");
                  } else {
                    values.startTime = "0000";
                  }
                  if (values.endTime) {
                    values.endTime = values.endTime?.replace(":", "");
                  } else {
                    values.endTime = "2359";
                  }
                }
                await update(values);
                setIsFormEditable(false);
              })}
              style={{ width: "394px" }}
            >
              <InputContainer
                label={
                  <label htmlFor="between_times">
                    {t("Contacts:between_times")}
                  </label>
                }
                input={
                  <Row>
                    <Controller
                      name="startTime"
                      render={({ field }) => (
                        <TimePicker
                          value={
                            !field.value?.includes(":")
                              ? formatTime(field.value!)
                              : field.value
                          }
                          onChange={field.onChange}
                        />
                      )}
                      control={control}
                    />
                    <div className={styles.oldSlash} />
                    <Controller
                      name="endTime"
                      render={({ field }) => (
                        <TimePicker
                          value={
                            !field.value?.includes(":")
                              ? formatTime(field.value!)
                              : field.value
                          }
                          onChange={field.onChange}
                        />
                      )}
                      control={control}
                    />
                  </Row>
                }
              />
            </form>
          ) : (
            <div style={{ width: "394px" }}>
              <InputContainer
                label={
                  <label htmlFor="between_times">
                    {t("Contacts:between_times")}
                  </label>
                }
                input={
                  <div className={styles.dataWrapper}>
                    <Icon name="clock" size={24} />
                    <Spacer size={8} />
                    <span>
                      {!!contactData?.startTime &&
                        formatTime(contactData.startTime)}
                    </span>
                    {!!contactData?.endTime && (
                      <>
                        <Spacer size={8} />
                        <div
                          className={classNames(styles.slash, styles.viewSlash)}
                        />
                        <Spacer size={8} />
                        <Icon name="clock" size={24} />
                        <Spacer size={8} />
                        <span>
                          {!!contactData.endTime &&
                            formatTime(contactData?.endTime)}
                        </span>
                      </>
                    )}
                  </div>
                }
              />
            </div>
          )}
          <Row style={{ marginLeft: "16px" }}>
            <Button
              form={isFormEditable ? "old-scheduler" : undefined}
              type={isFormEditable ? "submit" : "button"}
              iconVariant="border-link"
              variant="secondary"
              image={isFormEditable ? "check" : "pencil"}
              onClick={(event) => {
                if (!isFormEditable) {
                  setIsFormEditable(true);
                  event.preventDefault();
                }
              }}
            />

            <Spacer size={8} />
            <Button
              variant="secondary"
              image="x"
              onClick={() => {
                if (!contactData.startTime && !contactData.endTime) {
                  setShowSchedule(false);
                  return;
                }
                if (isFormEditable) {
                  setIsFormEditable(false);
                  reset();
                } else if (isNewSchedulerAvailable) {
                  setIsChangeAvailabilityModalOpened(true);
                } else {
                  clearTime();
                }
              }}
            />
          </Row>
        </Row>
      ) : (
        <NoScheduleItems />
      )}

      <Spacer size={16} />
      {isNewSchedulerAvailable && (
        <>
          <Horizontal />
          <Spacer size={16} />
          <Button
            variant="border-link"
            text={t("Contacts:change_schedule")}
            onClick={changeToNewScheduler}
          />
        </>
      )}
    </Column>
  );
};
