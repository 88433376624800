import { toSafeInteger } from "lodash";
import { useCallback, useMemo } from "react";
import { useQueryState } from "./useQueryState";
import { SortOrderParams as SortParams } from "../../../ui-lib/components/Tables/Table";

type SortState = { sortField?: string; sortOrder?: string };

const toSortParams = (state: SortState): SortParams | undefined => {
  if (!state.sortField) {
    return undefined;
  }

  const sortOrder = (() => {
    const value = toSafeInteger(state.sortOrder);

    switch (value) {
      case -1:
      case 0:
      case 1:
        return value;
      default:
        return undefined;
    }
  })();

  return {
    field: state.sortField,
    sortOrder,
  };
};

const toSortState = (params?: SortParams): SortState => ({
  sortField: params?.field,
  sortOrder: params?.sortOrder?.toString(),
});

const DEFAULT: SortState = {
  sortField: undefined,
  sortOrder: undefined,
};

export const useSortParams = (searchObj?: any) => {
  const stateToUse = !searchObj
    ? DEFAULT
    : {
        sortField: searchObj.sortField,
        sortOrder: searchObj.sortOrder,
      };
  const [state, setState] = useQueryState<SortState>(stateToUse);

  const sortParams = useMemo(() => toSortParams(state), [state]);

  const setSortParams = useCallback(
    (args?: SortParams) => setState(toSortState(args)),
    [setState]
  );

  const resetSortParams = useCallback(() => setState(DEFAULT), [setState]);

  return { sortParams, setSortParams, resetSortParams };
};
