import { TreeSelectSelectionKeysType } from "primereact/treeselect";
import { ITreeData } from "../../ui-lib/components/Hierarchy/Tree";
import { NestedOrganizationProps } from "../api/organizations/organizations";

export const transformOrganizationsToTreeData = (
  items?: NestedOrganizationProps[]
) =>
  items
    ? items
        .toSorted((org1, org2) => {
          if (org1.item.name.toLowerCase() < org2.item.name.toLowerCase())
            return -1;
          if (org1.item.name.toLowerCase() > org2.item.name.toLowerCase())
            return 1;
          return 0;
        })
        .map(({ item: Item, children: Children }) => {
          const treeItem: ITreeData = {
            key: Item.id,
            label: Item.name,
            data: Item.name,
            children: transformOrganizationsToTreeData(Children),
            hasSelectedChild: false,
            parentId: Item.parentId,
            requireCostCenter: Item.requireCostCenter,
          };
          return treeItem;
        })
    : [];

export const markParentNodeForSelectedItem = (
  selectedNodeId: TreeSelectSelectionKeysType | number,
  nodes: ITreeData[],
  callback?: () => void
) =>
  nodes.map((el) => {
    const callbackFunc = () => {
      el.hasSelectedChild = true;
      el.className = el.parentId ? "small-dot" : "dot";
      if (callback) callback();
    };

    const hasSelectedItem =
      !!el.children?.length &&
      el.children.find((item: ITreeData) => item.key === selectedNodeId);

    if (hasSelectedItem) {
      el.hasSelectedChild = true;
      el.className = el.parentId ? "small-dot" : "dot";
      if (callback) {
        callback();
      }
    } else {
      el.hasSelectedChild = false;
      el.className = "";
      if (el.children?.length)
        markParentNodeForSelectedItem(
          selectedNodeId,
          el.children,
          callbackFunc
        );
    }
    return el;
  });

export const findNodeById = (
  nodes: ITreeData[],
  id?: TreeSelectSelectionKeysType | number
): ITreeData | null => {
  let result = null;
  nodes.forEach((el) => {
    if (Number(el.key) === id) result = el;
    if (el.children) {
      const findedEl = findNodeById(el.children, id);
      if (findedEl) result = findedEl;
    }
  });

  return result;
};

export const getAllOrganizationsRecursive = (
  allOrgArr: ITreeData[] = []
): ((subArr: ITreeData[]) => ITreeData[]) => {
  function innerRecursive(subArr: ITreeData[]): ITreeData[] {
    subArr.forEach((item: ITreeData) => {
      allOrgArr.push(item);
      if (item.children?.length) innerRecursive(item.children);
    });
    return allOrgArr;
  }
  return innerRecursive;
};

export const findRoot = (
  id: TreeSelectSelectionKeysType | number,
  nodes: ITreeData[]
): ITreeData | null => {
  const org = findNodeById(nodes, id);
  if (!org?.parentId) return org;
  return findRoot(org.parentId, nodes);
};
