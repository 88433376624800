import { useLayoutEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import useTranslations from "../core/i18n/useTranslations";
import Modal from "../ui-lib/components/Modal/Modal";

export const UnsavedChanges = ({
  isDirty,
  onReset,
}: {
  isDirty: boolean;
  onReset?(): void;
}) => {
  const t = useTranslations();
  const history = useHistory();
  const [modalOpen, setModalOpen] = useState(false);
  const [goTo, setGoTo] = useState<string>();

  useLayoutEffect(() => {
    const unblock = history.block((location) => {
      // Allow navigation if no changes or if modal is open
      if (!isDirty || modalOpen) {
        return undefined;
      }

      // Show modal and block navigation
      setGoTo(location.pathname);
      setModalOpen(true);
      return false;
    });

    return () => {
      unblock();
    };
  }, [isDirty, modalOpen]);

  return (
    <Modal
      isOpen={modalOpen}
      onClose={() => setModalOpen(false)}
      modalTitle={t("Common:unsaved_changes_title")}
      approveBtnText={t("Common:unsaved_changes_stay")}
      onApproveBtnClick={() => setModalOpen(false)}
      cancelBtnText={t("Common:unsaved_changes_leave")}
      onCancelBtnClick={() => {
        if (goTo) {
          onReset?.();
          history.push(goTo);
        }

        setModalOpen(false);
      }}
    >
      <p style={{ color: "var(--Grey-600)" }}>
        {t("Common:unsaved_changes_description")}
      </p>
    </Modal>
  );
};
