import { useCallback, useMemo } from "react";
import { useQueryState } from "./useQueryState";

type AdminFiltersState = {
  adminType?: string;
  orgId?: string;
  sellerId?: string;
  responsecenter?: string;
};

const DEFAULT_STATE = {
  adminType: undefined,
  orgId: undefined,
  sellerId: undefined,
  responsecenter: undefined,
};

export const useFiltersByAdminType = (search: { [key: string]: string }) => {
  const [state, setState] = useQueryState<AdminFiltersState>(
    search ?? DEFAULT_STATE
  );

  const FiltersByAdminType = useMemo(() => state, [state]);

  const setFiltersByAdminType = useCallback(
    (args: AdminFiltersState) => setState((prev) => ({ ...prev, ...args })),
    [setState]
  );

  const resetFiltersByAdminType = useCallback(
    () => setState(DEFAULT_STATE),
    [setState]
  );

  return { FiltersByAdminType, setFiltersByAdminType, resetFiltersByAdminType };
};
