import moment from "moment";
import { TimeSpan, TimeSpanType } from "./types";

const getTimeSpan = ({
  type,
  setDefaultEndTime,
}: {
  type: TimeSpanType;
  setDefaultEndTime?: boolean;
}): TimeSpan => {
  const today = (() => {
    const now = new Date();
    now.setHours(0, 0, 0, 0);

    return now.getTime();
  })();

  const oneDay = 24 * 60 * 60 * 1000;

  const startTime = (startNumber: number) => {
    const startTimeDate = new Date(startNumber);
    startTimeDate.setHours(0, 0, 0, 0);
    return startTimeDate;
  };

  const endTime = () => {
    const endTimeDate = new Date(today);
    endTimeDate.setHours(23, 59, 59, 0);
    return endTimeDate;
  };

  switch (type) {
    case TimeSpanType.Today: {
      return {
        start: startTime(today),
        end: setDefaultEndTime ? endTime() : undefined,
      };
    }
    case TimeSpanType.Last7Days: {
      return {
        start: startTime(today - 6 * oneDay),
        end: setDefaultEndTime ? endTime() : undefined,
      };
    }
    case TimeSpanType.Last10Days: {
      return {
        start: startTime(today - 9 * oneDay),
        end: setDefaultEndTime ? endTime() : undefined,
      };
    }
    case TimeSpanType.Last30Days: {
      return {
        start: startTime(today - 29 * oneDay),
        end: setDefaultEndTime ? endTime() : undefined,
      };
    }
    case TimeSpanType.LastMonth: {
      const start = new Date(today);
      start.setHours(0, 0, 0, 0);
      start.setDate(1);

      return { start, end: setDefaultEndTime ? endTime() : undefined };
    }
    case TimeSpanType.PreviousMonth: {
      const start = new Date(today);
      start.setHours(0, 0, 0, 0);
      start.setDate(1);
      start.setMonth(start.getMonth() - 1);

      const end = new Date(today);
      end.setDate(0);
      end.setHours(23, 59, 59, 0);

      return { start, end };
    }
    case TimeSpanType.AllTime:
    default: {
      return { start: undefined, end: undefined };
    }
  }
};

const getTimeSpanType = (savedUrl: string) => {
  const startTimeFromUrl = savedUrl.split("T")[0];

  // @ts-ignore
  const timeDiffFromNow = moment(new Date(), "YYYY-MM-DD").diff(
    moment(startTimeFromUrl).format("YYYY-MM-DD"),
    "days"
  );

  if (timeDiffFromNow === TimeSpanType.Today) {
    return 0;
  }
  // last 7 days
  if (timeDiffFromNow <= 7) {
    return 1;
  }
  if (timeDiffFromNow <= 10 && timeDiffFromNow > 7) {
    return 2;
  }
  // last 30 days
  return 3;
};

const dateToString = (date: Date, locale: string) =>
  date.toLocaleDateString(locale, {
    day: "numeric",
    month: "short",
  });

const dateToTimeString = (date: Date, locale: string) =>
  date.toLocaleTimeString(locale, {
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
  });

const dateToDetailedString = (date: Date, locale: string) =>
  date.toLocaleDateString(locale, {
    day: "numeric",
    month: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
  });

const timeSpanToString = ({
  type,
  locale,
}: {
  type: TimeSpanType;
  locale: string;
}): string | undefined => {
  const { start, end } = getTimeSpan({ type });

  switch (type) {
    case TimeSpanType.Today:
      return dateToString(start as Date, locale);
    case TimeSpanType.AllTime:
    case TimeSpanType.Custom:
      return undefined;
    default: {
      const startString = dateToString(start as Date, locale);
      const endString = end
        ? dateToString(end, locale)
        : dateToString(new Date(), locale); // No end date equals now

      return `${startString} - ${endString}`;
    }
  }
};

const customTimeSpanToString = ({
  timeSpan,
  locale,
}: {
  timeSpan: TimeSpan;
  locale: string;
}): string => {
  const { start, end } = timeSpan;

  if (!start || !end) {
    throw new Error("Custom time span should include both start and end date");
  }

  const startString = dateToDetailedString(start, locale);
  const endString = dateToDetailedString(end, locale);

  return `${startString} - ${endString}`;
};

export {
  getTimeSpan,
  getTimeSpanType,
  timeSpanToString,
  customTimeSpanToString,
  dateToTimeString,
};
