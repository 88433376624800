import useTranslations from "../../core/i18n/useTranslations";
import useUser from "../../core/user/useUser";
import { useState } from "react";
import { notifyApiErrors } from "../../core/helpers/helpers";
import PrimeModal from "../../ui-lib/components/PrimeModal/PrimeModal";
import { removeTransmitterFromObject } from "../../core/api/transmitters/transmitters";

export const DeleteTransmitterModal = ({
  transmitterId,
  userId,
  onClose,
  onRemove,
}: {
  transmitterId: string;
  userId: string;
  onClose: () => void;
  onRemove: () => void;
}) => {
  const t = useTranslations();
  const { authenticatedRequest } = useUser();

  const [isLoading, setIsLoading] = useState(false);

  const removeTransmitter = async () => {
    try {
      setIsLoading(true);
      await removeTransmitterFromObject(
        userId,
        transmitterId,
        authenticatedRequest
      );
      onRemove();
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PrimeModal
      withFooter
      withHeader
      isOpen={!!transmitterId}
      onClose={onClose}
      style={{ width: "576px" }}
      loading={isLoading}
      header={t("Objects:remove_transmitter_from_object")}
      submitBtn={{
        text: t("Objects:remove_transmitter"),
        variant: "primary",
        onClick: removeTransmitter,
        disabled: isLoading,
      }}
      cancelBtn={{ text: t("Common:cancel"), onClick: onClose }}
    >
      <p style={{ color: "var(--Grey-600)" }}>
        {t("Objects:remove_transmitter_from_object_description")}
      </p>
    </PrimeModal>
  );
};
