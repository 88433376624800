import { AxiosInstance } from "axios";
import querystring from "query-string";
import { ApiSearch } from "../../interfaces/Api";
import API_HOST from "../apiHost";
import useAuthenticatedFetch from "../useAuthenticatedFetch";
import { RoleTemplate, NewRoleTemplate } from "./types";

const useRoleTemplates = (query: ApiSearch) => {
  const clearQuery = Object.fromEntries(
    Object.entries(query).filter(([_, v]) => !!v)
  );
  const parsedQuery = querystring.stringify({
    ...clearQuery,
  });

  return useAuthenticatedFetch<RoleTemplate[]>(
    `${API_HOST}api/v2/roletemplate?${parsedQuery}`
  );
};

const useRoleTemplate = (id: number) =>
  useAuthenticatedFetch<NewRoleTemplate>(
    `${API_HOST}api/v2/roletemplate/${id}`
  );

const getRoleTemplates = async (
  params: {
    q?: string;
    page?: number;
    pageSize?: number;
    adminType?: string;
    organization?: number | string;
    responsecenter?: string;
    publicArc?: boolean;
  },
  authenticatedRequest: AxiosInstance
) => {
  const settings = querystring.stringify({
    ...params,
  });

  return authenticatedRequest.get<RoleTemplate[]>(
    `${API_HOST}api/v2/roletemplate?${settings}`
  );
};

const deleteRoleTemplate = async (
  id: string | number,
  authenticatedRequest: AxiosInstance
) => authenticatedRequest.delete<{}>(`${API_HOST}api/v2/roletemplate/${id}`);

const createRoleTemplate = async (
  roleModel: NewRoleTemplate,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.post<NewRoleTemplate>(
    `${API_HOST}api/v2/roletemplate`,
    roleModel
  );

const editRoleTemplate = async (
  id: number,
  roleModel: NewRoleTemplate,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.put<NewRoleTemplate>(
    `${API_HOST}api/v2/roletemplate/${id}`,
    roleModel
  );

export {
  useRoleTemplates,
  getRoleTemplates,
  deleteRoleTemplate,
  createRoleTemplate,
  useRoleTemplate,
  editRoleTemplate,
};
