import { observer } from "mobx-react-lite";
import { getErrorKey } from "../../../../components/Errors/ErrorAlert";
import {
  updateResponseCenterDistribution,
  useResponseCenterDistribution,
} from "../../../../core/api/responsecenters/responsecenters";
import { ResponseCenterDistributionParams } from "../../../../core/api/responsecenters/types";
import useTranslations from "../../../../core/i18n/useTranslations";
import useUser from "../../../../core/user/useUser";
import { notify } from "../../../../ui-lib/components/Alerts/Toast";
import LoadingSpinner from "../../../../ui-lib/components/Loading/LoadingSpinner";
import { DistributorForm } from "./DistributorForm";

const EditDistributorModal = ({
  responseCenterId,
  responseCenterContactId,
  onClose,
  onEdit,
}: {
  responseCenterId: string | number;
  responseCenterContactId: string | number;
  onClose: () => void;
  onEdit: () => void;
}) => {
  const t = useTranslations();
  const { authenticatedRequest } = useUser();

  const { data } = useResponseCenterDistribution(
    responseCenterId,
    responseCenterContactId
  );

  if (!data) {
    return <LoadingSpinner theme="primary" />;
  }

  const onSubmit = async (params: ResponseCenterDistributionParams) => {
    if (!responseCenterContactId) {
      return;
    }

    try {
      await updateResponseCenterDistribution(
        responseCenterId,
        responseCenterContactId,
        params,
        authenticatedRequest
      );
      notify({
        message: t("Messages:update_alarm_reception_distributor_success"),
      });
      onEdit();
      onClose();
    } catch (error) {
      const errorKey = getErrorKey(error);
      notify({
        message: t(`Errors:${errorKey}`),
        variant: "error",
      });
    }
  };

  return (
    <DistributorForm
      labels={{
        title: t("AlarmReceptions:update_distributor"),
        description: t("AlarmReceptions:update_distributor_description"),
        submit: t("Common:save_changes"),
      }}
      defaultValues={data}
      onSubmit={onSubmit}
      onClose={onClose}
    />
  );
};

export default observer(EditDistributorModal);
