export const urlSearchParser = (url?: string) => {
  if (!url) {
    return;
  }
  const parsedParam = {} as any;
  const search = decodeURIComponent(url).substring(1);
  search.split("&").forEach((param) => {
    const [key, value] = param.split(/=(.*)/s);
    parsedParam[key] = value;
  });
  return parsedParam;
};

export const searchStringParser = (stringToParse?: string) => {
  if (!stringToParse) {
    return undefined;
  }
  const beginWithPlus = stringToParse[0] === "+";
  const parsedSting = stringToParse
    .substring(beginWithPlus ? 1 : 0)
    .replace(/\+/g, " ");
  return `${beginWithPlus ? "+" : ""}${parsedSting}`;
};
