import { ContactDetails } from "./types";

const decodeTime = (time?: string) =>
  time?.length === 5 ? time.replace(":", "") : undefined;

const encodeTime = (time?: string) =>
  time?.length === 4
    ? `${time.substring(0, 2)}:${time.substring(2, 4)}`
    : undefined;

const decodeContactDetails = (values: ContactDetails): ContactDetails => ({
  ...values,
  startTime: decodeTime(values.startTime),
  endTime: decodeTime(values.endTime),
});

const encodeContactDetails = (values: ContactDetails): ContactDetails => ({
  ...values,
  startTime: encodeTime(values.startTime),
  endTime: encodeTime(values.endTime),
});

export { decodeContactDetails, encodeContactDetails, decodeTime, encodeTime };
