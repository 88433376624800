/* eslint-disable react/jsx-props-no-spreading */
import { toNumber } from "lodash";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { Column, Row, Spacer } from "../../../../components/Layout/Layout";
import { ResponseCenterAlarmCodeRuleDetails } from "../../../../core/api/responsecenters/types";
import useTranslations from "../../../../core/i18n/useTranslations";
import Button from "../../../../ui-lib/components/Button/Button";
import NumberInput from "../../../../ui-lib/components/Inputs/NumberInput";
import MultiCodeInput from "../../../../ui-lib/components/MultiInput/MultiCodeInput";
import ModalContent from "../../../../ui-lib/components/Popup/ModalContent";
import { CheckboxControl } from "../AlarmHandling/Controls/CheckboxControl";
import TextInput from "../../../../ui-lib/components/Inputs/TextInput";

export interface AlarmCodeRuleFormProps {
  labels: {
    title: string;
    description: string;
    submit: string;
  };
  defaultValues?: ResponseCenterAlarmCodeRuleDetails;
  onSubmit: (values: ResponseCenterAlarmCodeRuleDetails) => void;
  onClose: () => void;
}

export const AlarmCodeRuleForm = ({
  labels,
  defaultValues,
  onSubmit,
  onClose,
}: AlarmCodeRuleFormProps) => {
  const t = useTranslations();

  const methods = useForm<ResponseCenterAlarmCodeRuleDetails>({
    defaultValues: {
      requireAll: true,
      ...defaultValues,
    },
  });

  const {
    control,
    setValue,
    trigger,
    formState: { isSubmitting, errors },
    handleSubmit,
  } = methods;

  return (
    <ModalContent onClose={onClose} width="848px">
      <h2 className="subTitle">{labels.title}</h2>
      <Spacer size={16} />
      <p style={{ color: "var(--Grey-600)" }}>{labels.description}</p>

      <Spacer size={32} />

      <FormProvider {...methods}>
        <form
          onSubmit={(e) => {
            // To stop main form to submit
            e.preventDefault();
            e.stopPropagation();

            handleSubmit(onSubmit)(e);
          }}
        >
          <Controller
            name="flags"
            control={control}
            rules={{ required: true }}
            render={({ field }) => {
              const length = field.value?.length ?? 0;
              const values =
                length > 0
                  ? field.value?.split(",").map((stringValue) => stringValue)
                  : [];

              return (
                <MultiCodeInput
                  required
                  label={t(
                    "AlarmReceptions:alarm_handling_alarm_code_rules_input_classifications"
                  )}
                  placeholder={t("Common:input_multi_code_placeholder")}
                  values={values}
                  setValues={(updated) => {
                    const value = updated.join(",");
                    setValue(field.name, value, { shouldDirty: true });
                  }}
                  validationError={
                    errors.flags && t("Errors:input_field_required")
                  }
                />
              );
            }}
          />

          <Spacer size={16} />

          <Row align="start">
            <Column>
              <Controller
                name="alarmCode"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <TextInput
                    required
                    label={t(
                      "AlarmReceptions:alarm_handling_alarm_code_rules_input_alarm_code"
                    )}
                    placeholder={t("Common:integer_number")}
                    value={`${field.value ?? ""}`}
                    onChange={field.onChange}
                    validationError={
                      errors.alarmCode && t("Errors:input_field_required")
                    }
                  />
                )}
              />
            </Column>
            <Spacer size={32} />
            <Column>
              <Controller
                name="order"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <NumberInput
                    required
                    label={t(
                      "AlarmReceptions:alarm_handling_alarm_code_rules_input_order"
                    )}
                    placeholder={t("Common:integer_number")}
                    value={`${field.value}`}
                    onChange={(e) => {
                      const stringValue = e.currentTarget.value;
                      const updatedValue = toNumber(stringValue);
                      setValue(field.name, updatedValue);
                      trigger(field.name);
                    }}
                    validationError={
                      errors.order && t("Errors:input_field_required")
                    }
                  />
                )}
              />
            </Column>
          </Row>

          <Spacer size={16} />

          <Row align="start">
            <CheckboxControl
              name="requireAll"
              label={t(
                "AlarmReceptions:alarm_handling_alarm_code_rules_input_require_all"
              )}
              description={t(
                "AlarmReceptions:code_rules_require_all_description"
              )}
            />
          </Row>

          <Spacer size={32} />

          <Row type="left">
            <Button
              type="submit"
              variant="primary"
              text={labels.submit}
              loading={isSubmitting}
            />
            <Spacer size={8} />
            <Button
              variant="secondary"
              text={t("Common:cancel")}
              onClick={onClose}
            />
          </Row>
        </form>
      </FormProvider>
    </ModalContent>
  );
};
