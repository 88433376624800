import { ReactNode, createContext, useEffect, useRef, useState } from "react";
import { IResellerDetails } from "../../../core/api/sellers/types";
import {
  getSingleSeller,
  updateReseller,
} from "../../../core/api/sellers/sellers";
import useUser from "../../../core/user/useUser";

export const SharedResellerContext = createContext<{
  data?: IResellerDetails;
  isLoading: boolean;
  isError: boolean;
  fetchReseller: () => Promise<void>;
  update: (data: IResellerDetails) => Promise<void>;
}>({
  data: undefined,
  isLoading: false,
  isError: false,
  fetchReseller: async () => {},
  update: async () => {},
});

export const SharedResellerProvider = ({
  sellerId,
  children,
}: {
  sellerId: string;
  children: ReactNode;
}) => {
  const { authenticatedRequest } = useUser();
  const isAlive = useRef(false);

  const [data, setData] = useState<IResellerDetails>();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const fetchReseller = async () => {
    setData(undefined);
    setIsLoading(true);

    try {
      const result = await getSingleSeller(authenticatedRequest, +sellerId);
      if (isAlive.current) {
        setData(result.data);
      }
    } catch (error: any) {
      if (isAlive.current) {
        setIsError(true);
      }
    }
    if (isAlive.current) {
      setIsLoading(false);
    }
  };

  const update = async (sellerData: IResellerDetails) => {
    setIsLoading(true);

    try {
      const response = await updateReseller(
        authenticatedRequest,
        +sellerId,
        sellerData
      );
      setData(response.data);
    } catch (error) {
      setIsError(true);
      throw error;
    } finally {
      setIsLoading(false);
      setIsError(false);
    }
  };

  useEffect(() => {
    fetchReseller();
  }, [sellerId]);

  useEffect(() => {
    isAlive.current = true;

    return () => {
      isAlive.current = false;
    };
  }, []);

  return (
    <SharedResellerContext.Provider
      value={{ data, isLoading, isError, fetchReseller, update }}
    >
      {children}
    </SharedResellerContext.Provider>
  );
};
