import { TransmitterLog } from "../../../core/api/transmitters/types";
import { Row, Spacer } from "../../../components/Layout/Layout";
import useSharedTransmitter from "../SharedTransmitterContext/useSharedTransmitter";
import LoadingSpinner from "../../../ui-lib/components/Loading/LoadingSpinner";
import { TransmitterEditForm } from "../../../components/Transmitters/TransmitterEditForm";
import { CreateTransmitterEventModal } from "../../../components/Transmitters/CreateTransmitterEventModal";
import Table from "../../../ui-lib/components/Tables/Table";
import TableCell, {
  MissingValueTableCell,
} from "../../../ui-lib/components/Tables/TableCell";
import { CustomTableCell } from "../../Objects/ObjectResponse/CustomTableCell/CustomTableCell";
import moment from "moment/moment";
import { MutableRefObject } from "react";
import useTranslations from "../../../core/i18n/useTranslations";

export const TransmitterGeneral = ({
  editTransmitterRef,
}: {
  editTransmitterRef: MutableRefObject<any>;
}) => {
  const t = useTranslations();
  const { transmitterData, credentialsData, isLoading, fetchTransmitter } =
    useSharedTransmitter();

  const getLogText = (log: TransmitterLog): string =>
    log.logType === 0
      ? log.logText
      : t(`Transmitters:TransmitterLogType_${log.logType}`);

  return (
    <>
      <h1>{t("Transmitters:details_page_title")}</h1>
      <Spacer size={8} />
      <p>{t("Transmitters:details_page_description")}</p>

      <Spacer size={32} />

      <h2>{t("Transmitters:basic_details_title")}</h2>
      <Spacer size={8} />
      <p>{t("Transmitters:basic_details_description")}</p>
      <Spacer size={16} />
      {(transmitterData === undefined || isLoading) && (
        <LoadingSpinner theme="primary" />
      )}
      {((transmitterData &&
        credentialsData &&
        !!transmitterData.sipCredentialType) ||
        transmitterData) && (
        <>
          <TransmitterEditForm
            ref={editTransmitterRef}
            transmitter={transmitterData}
            credentials={credentialsData}
            uploadTransmitterData={fetchTransmitter}
            dataLoading={isLoading}
            showBroken
          />
          <Row type="space" style={{ alignSelf: "stretch" }}>
            <h2>{t("Transmitters:eventlog_table_title")}</h2>
            {transmitterData && (
              <CreateTransmitterEventModal
                transmitter={transmitterData}
                onSubmit={fetchTransmitter}
                triggerText={t("Transmitters:eventlog_table_add")}
              />
            )}
          </Row>
          <Spacer size={16} />
          <Table<TransmitterLog>
            columns={[
              {
                header: t("Transmitters:eventlog_table_logtype"),
                fieldTemplate: (rowData) => (
                  <TableCell
                    value={t(
                      `Transmitters:TransmitterLogType_${rowData.logType}`
                    )}
                  />
                ),
              },
              {
                header: t("Transmitters:eventlog_table_admin"),
                fieldTemplate: (rowData) => <TableCell value={rowData.name} />,
              },
              {
                header: t("Transmitters:eventlog_table_time"),
                fieldTemplate: (rowData) => (
                  <CustomTableCell
                    label={moment(rowData.created).format("HH:mm:ss")}
                    description={moment(rowData.created).format("DD MMMM YYYY")}
                  />
                ),
              },
              {
                header: t("Transmitters:eventlog_table_text"),
                fieldTemplate: (rowData) => (
                  <MissingValueTableCell
                    value={`${getLogText(rowData)}`}
                    iconColor="Grey-300"
                    className="cellContentEllipsis"
                    missingValueText=""
                  />
                ),
              },
            ]}
            items={transmitterData?.transmitterLogs}
            hideEmptyMessage
            noRowsMessage={t("Transmitters:eventlog_table_nomatch")}
            showRowHover
          />
        </>
      )}
    </>
  );
};
