import { useCallback, useMemo } from "react";
import { useQueryState } from "../filters/useQueryState";

type respondentGroupIdState = { respondentGroupId?: string };
type respondentGroupIdFilter = { respondentGroupId?: number };

const toRespondentGroupIdFilter = (
  state: respondentGroupIdState
): respondentGroupIdFilter => ({
  respondentGroupId: state.respondentGroupId
    ? parseInt(state.respondentGroupId, 10)
    : undefined,
});

const toRespondentGroupIdState = (
  filter: respondentGroupIdFilter
): respondentGroupIdState => ({
  respondentGroupId: filter.respondentGroupId
    ? `${filter.respondentGroupId}`
    : "",
});

const DEFAULT: respondentGroupIdFilter = {
  respondentGroupId: undefined,
};

export const userespondentGroupId = (searchObj?: any) => {
  const stateToUse = !searchObj
    ? DEFAULT
    : {
        respondentGroupId: searchObj.respondentGroupId,
      };
  const [state, setState] = useQueryState<any>(
    toRespondentGroupIdState(stateToUse)
  );

  const respondentGroupId = useMemo(
    () => toRespondentGroupIdFilter(state),
    [state]
  );

  const setRespondentGroupId = useCallback(
    (args: any) => {
      setState(toRespondentGroupIdState(args));
    },
    [setState]
  );

  const resetRespondentGroupId = useCallback(
    () => setState(toRespondentGroupIdState(DEFAULT)),
    [setState]
  );

  return {
    respondentGroupId,
    setRespondentGroupId,
    resetRespondentGroupId,
  };
};
