import { toLower } from "lodash";
import { useEffect, useState } from "react";
import DateFilterRadioGroup from "../../components/DateFilter/DateFilterRadioGroup";
import { BaseTableDataParams } from "../../core/api/common_table";
import useTranslations from "../../core/i18n/useTranslations";
import Button from "../../ui-lib/components/Button/Button";
import Dropdown from "../../ui-lib/components/Dropdown/Dropdown";
import Icon from "../../ui-lib/components/Icon";
import { TimeSpan, TimeSpanType } from "../DateFilter/types";
import { Row, Spacer } from "../Layout/Layout";
import ColumnInfo from "./ColumnInfo";
import styles from "./ExportSettings.module.css";
import PeriodicReportSettings from "./PeriodicReportSettings";
import { ExportProps } from "./types";
import { getTimeSpan } from "../DateFilter/timeSpan";
import PrimeModal from "../../ui-lib/components/PrimeModal/PrimeModal";

const getInitialDateSettings = (
  withDateRange: boolean,
  tableParams?: BaseTableDataParams
): { type: TimeSpanType; timeSpan: TimeSpan } => {
  // Preselect custom range with time
  if (tableParams?.preselectedDateType === TimeSpanType.Custom) {
    return {
      type: TimeSpanType.Custom,
      timeSpan: {
        start: new Date(tableParams?.start!),
        end: tableParams?.end ? new Date(tableParams.end) : new Date(),
      },
    };
  }

  // Preselect date filter if it was selected on land page and it's not custom range
  if (Number.isInteger(tableParams?.preselectedDateType)) {
    const selectedTimeSpan = getTimeSpan({
      type: tableParams?.preselectedDateType!,
    });
    return {
      type: tableParams?.preselectedDateType!,
      timeSpan: {
        start: selectedTimeSpan.start,
        end: selectedTimeSpan.end,
      },
    };
  }

  // Preselect Today by default
  return {
    type: withDateRange ? TimeSpanType.Today : TimeSpanType.AllTime,
    timeSpan: withDateRange
      ? getTimeSpan({ type: TimeSpanType.Today })
      : {
          start: undefined,
          end: undefined,
        },
  };
};

const ExportSettings = ({
  labels,
  settings,
  tableParams,
  state,
  onClose,
  onExport,
  isLoading,
  dataCy,
}: ExportProps) => {
  const initialDateSettings = getInitialDateSettings(
    settings.supportDateRange,
    tableParams
  );
  const [dateSettings, setDateSettings] = useState(initialDateSettings);
  const [showStopDownloadModal, setShowStopDownloadModal] = useState(false);

  const t = useTranslations();

  useEffect(() => {
    setDateSettings(initialDateSettings);
  }, [settings.supportDateRange]);

  return (
    <div className={styles.container} data-cy={dataCy}>
      <PrimeModal
        withHeader
        withFooter
        header={t("Objects:title_stop_download")}
        submitBtn={{
          text: t("Common:label_stop"),
          variant: "destructive",
          onClick: () => {
            setShowStopDownloadModal(false);
            onClose();
          },
        }}
        cancelBtn={{
          text: t("Objects:label_keep_downloading"),
          onClick: () => setShowStopDownloadModal(false),
        }}
        isOpen={showStopDownloadModal}
        onClose={() => setShowStopDownloadModal(false)}
      >
        <p className={styles.description}>
          {t("Objects:description_stop_downloading")}
        </p>
      </PrimeModal>
      <div
        role="button"
        aria-label="Close"
        onClick={onClose}
        onKeyUp={onClose}
        className={styles.closeButton}
      >
        <Icon name="x" />
      </div>
      <div className={styles.content}>
        <h2 className="subTitle">{labels.title}</h2>
        <Spacer size={16} />
        <p className={styles.description}>
          {t("Common:export")} <strong>{toLower(labels.description)}</strong>{" "}
          {t("Common:inlist")} {t("Common:toreport")}
        </p>

        {settings.exportTypes && (
          <>
            <Spacer size={16} />
            <Dropdown
              title={t("Common:export_type")}
              selectedItem={settings.exportTypes.items.find(
                (i) => i.isSelected
              )}
              items={settings.exportTypes.items}
              onSelectItem={settings.exportTypes.onSelectItem}
              maxVisible={8}
              disabled={state?.loading}
            />
            <p className={styles.labelSmall}>
              {settings.exportTypes.description}
            </p>
          </>
        )}

        {settings.periodicReports && (
          <>
            <Spacer size={16} />
            <PeriodicReportSettings
              options={settings.periodicReports.options}
              setOptions={settings.periodicReports.setOptions}
              validationErrors={state?.validationErrors}
              disabled={state?.loading}
            />
          </>
        )}

        {settings.supportDateRange && (
          <>
            <Spacer size={16} />
            <p className={styles.label}>{t("Common:export_date_range")}</p>
            <div className={styles.dateFilterWrapper}>
              <DateFilterRadioGroup
                value={dateSettings}
                onChange={setDateSettings}
                disabled={state?.loading}
              />
            </div>
          </>
        )}

        {settings.columnInfo && (
          <>
            <Spacer size={16} />
            <ColumnInfo columnInfo={settings.columnInfo} />
          </>
        )}

        <Spacer size={32} />

        <Row type="left">
          <Button
            text={labels.submit ?? t("Common:export")}
            loading={state?.loading}
            disabled={state?.loading}
            onClick={() =>
              onExport({
                timeSpan: dateSettings.timeSpan
                  ? dateSettings.timeSpan
                  : undefined,
              })
            }
          />
          <Spacer size={8} />
          <Button
            text={t("Common:cancel")}
            variant="secondary"
            onClick={() => {
              if (isLoading) {
                setShowStopDownloadModal(true);
              } else {
                onClose();
              }
            }}
          />
        </Row>
      </div>
    </div>
  );
};

export default ExportSettings;
