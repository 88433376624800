import Icon from "../../../../../ui-lib/components/Icon";

import styles from "./AlarmSubscriptionDetails.module.css";

const SubscriptionInfoItem = ({
  text = "",
  iconName,
  maxWidth,
  onClick,
}: {
  text?: string;
  iconName?: string;
  maxWidth?: string;
  onClick?: () => void;
}) => (
  <div className={styles.subscriptionItem} style={{ maxWidth }}>
    <span>{text}</span>
    {iconName && <Icon onClick={onClick} name={iconName} size={16} />}
  </div>
);

export default SubscriptionInfoItem;
