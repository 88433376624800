import { RefObject } from "react";
import classnames from "classnames";
import { Tooltip } from "primereact/tooltip";

import "./PrimeTooltip.module.css";

interface IPrimeTooltipProps {
  theme?: "dark" | "light";
  target?: string | HTMLElement | string[] | RefObject<HTMLElement>;
  position?: "top" | "bottom" | "left" | "right" | "mouse";
  autoHide?: boolean;
  showEvent?: string;
  hideEvent?: string;
}

const PrimeTooltip = ({
  target,
  theme = "light",
  position = "top",
  autoHide = true,
  showEvent = "mouseenter",
  hideEvent = "mouseleave",
}: IPrimeTooltipProps) => {
  return (
    <Tooltip
      showEvent={showEvent}
      hideEvent={hideEvent}
      autoHide={autoHide}
      target={target}
      className={classnames({
        dark: theme === "dark",
      })}
      position={position}
    />
  );
};

export default PrimeTooltip;
