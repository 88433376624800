import { Dispatch, SetStateAction, useState } from "react";
import useTranslations from "../../../core/i18n/useTranslations";
import Button from "../../../ui-lib/components/Button/Button";
import PopupContextMenu from "../../../ui-lib/components/Menus/PopupContextMenu/PopupContextMenu";
import { PopupContextMenuLinkButton } from "../../../ui-lib/components/Menus/PopupContextMenu/PopupContextMenuLinkButton";
import Modal from "../../../ui-lib/components/Popup/Modal";
import { useSaveObject } from "../../../core/SaveObjectContext/hooks";
import DeleteObjectModal from "./DeleteObjectModal";
import DownloadLinkModal from "./DownloadLinkModal";
import TestAlarmModal from "./TestAlarmModal";
import { notifyApiErrors } from "../../../core/helpers/helpers";
import { requestDeviceLog } from "../../../core/api/objects/objects";
import useUser from "../../../core/user/useUser";
import { notify } from "../../../ui-lib/components/Alerts/Toast";
import usePageState from "../../../core/pagestate/usePageState";
import DateOfDeathModal from "./DateOfDeathModal";
import useSharedObject from "../SharedObjectContext/useSharedObject";
import { ScheduleAbsenceModal } from "./ScheduleAbsenceModal";
import Tooltip from "../../../ui-lib/components/Tooltip/Tooltip";
import { CancelAbsenceModal } from "./CancelAbsenceModal";
import { AbsenceHistory } from "./AbsenceHistory";
import Divider from "../../../ui-lib/components/Divider/Divider";
import PrimeModal from "../../../ui-lib/components/PrimeModal/PrimeModal";
import { Row, Spacer } from "../../../components/Layout/Layout";
import {
  ObjectDetailsModel,
  ObjectDetailsParams,
} from "../../../core/api/objects/types";

const DeceasedFooter = ({
  data,
  deceasedDate,
  isLoading,
  t,
  setDeceasedDate,
  update,
  setDeceasedModalOpen,
}: {
  data?: ObjectDetailsModel;
  deceasedDate?: Date;
  isLoading: boolean;
  update: (objectDetails: ObjectDetailsParams) => Promise<void>;
  t: (value: string) => string;
  setDeceasedDate: Dispatch<SetStateAction<Date | undefined>>;
  setDeceasedModalOpen: Dispatch<SetStateAction<boolean>>;
}) => (
  <Row type="left">
    <Button
      text={
        !data?.dateOfDeath
          ? t("Common:save")
          : t("Objects:confirm_not_deceased")
      }
      onClick={async () => {
        try {
          if (deceasedDate) {
            setDeceasedDate(undefined);
          }

          await update({ ...data!, dateOfDeath: deceasedDate });
          notify({
            message: t("Objects:edited_success"),
          });
          setDeceasedModalOpen(false);
        } catch (error: any) {
          notifyApiErrors(error?.response?.data?.errors);
        }
      }}
      disabled={isLoading}
    />
    <Spacer size={8} />
    <Button
      variant="secondary"
      text={t("Common:cancel")}
      onClick={() => setDeceasedModalOpen(false)}
      disabled={isLoading}
    />
  </Row>
);

const ObjectActions = ({ active }: { active: boolean }) => {
  const t = useTranslations();
  const { canSave, isDirty, isSaving, onSave } = useSaveObject();
  const { authenticatedRequest, config } = useUser();
  const pageState = usePageState();
  const { data, absenceData, reloadAbsence, update, isLoading } =
    useSharedObject();

  const [scheduleAbsenceModalOpen, setScheduleAbsenceModalOpen] =
    useState(false);
  const [absenceHistoryModalOpen, setAbsenceHistoryModalOpen] = useState(false);
  const [cancelAbsenceModalOpen, setCancelAbsenceModalOpen] = useState(false);
  const [isEditAbsence, setIsEditAbsence] = useState(false);
  const [testAlarmOpen, setTestAlarmOpen] = useState(false);
  const [deceasedModalOpen, setDeceasedModalOpen] = useState(false);
  const [deceasedDate, setDeceasedDate] = useState(data?.dateOfDeath);

  const showOtherBtn = config?.show.some((item) =>
    [
      "UserTestAlarm",
      "DeleteUser",
      "DateOfDeath",
      "UserAbsence",
      "SystemLogAccess",
    ].includes(item)
  );

  const absenceTextToDisplay = (cancel = false) => {
    if (cancel) {
      if (absenceData?.ongoing.length) {
        return t("Objects:end_ongoing_absence");
      }
    }
    return t("Objects:schedule_absence");
  };

  return (
    <>
      {data?.active && (
        <PrimeModal
          withHeader
          style={{ width: "848px" }}
          header={t("Objects:schedule_the_absence")}
          isOpen={scheduleAbsenceModalOpen}
          onClose={() => {
            setScheduleAbsenceModalOpen(false);
            setIsEditAbsence(false);
          }}
        >
          <ScheduleAbsenceModal
            defaultValues={
              isEditAbsence ? absenceData?.ongoing?.[0] : undefined
            }
            reloadAbsence={reloadAbsence}
            onClose={() => {
              setScheduleAbsenceModalOpen(false);
              setIsEditAbsence(false);
            }}
            adminId={data?.id}
            isOngoing={!!absenceData?.ongoing.length && isEditAbsence}
          />
        </PrimeModal>
      )}
      <PrimeModal
        withHeader
        withFooter
        style={{ width: "848px" }}
        header={t("Objects:absence_history")}
        submitBtn={{
          text: t("Common:ok"),
          variant: "secondary",
          onClick: () => setAbsenceHistoryModalOpen(false),
        }}
        isOpen={absenceHistoryModalOpen}
        onClose={() => setAbsenceHistoryModalOpen(false)}
      >
        <AbsenceHistory
          adminId={data?.id!}
          absenceData={absenceData!}
          reloadAbsence={reloadAbsence}
        />
      </PrimeModal>
      <PrimeModal
        isOpen={cancelAbsenceModalOpen}
        onClose={() => setCancelAbsenceModalOpen(false)}
      >
        <CancelAbsenceModal
          isEnd={!!absenceData?.ongoing.length}
          onClose={() => setCancelAbsenceModalOpen(false)}
          adminId={data?.id!}
          absenceId={
            absenceData?.ongoing?.[0]?.id ?? absenceData?.upcoming?.[0]?.id
          }
          reloadAbsence={reloadAbsence}
        />
      </PrimeModal>
      {canSave && !pageState.hideSaveButton && (
        <Button
          text={t("Common:save")}
          disabled={!isDirty}
          loading={isSaving}
          onClick={onSave}
        />
      )}
      <PrimeModal
        withHeader
        contentStyle={{ width: "576px" }}
        header={t("Objects:test_alarm_create")}
        isOpen={testAlarmOpen}
        onClose={() => setTestAlarmOpen(false)}
      >
        <TestAlarmModal onClose={() => setTestAlarmOpen(false)} />
      </PrimeModal>
      <PrimeModal
        withHeader
        contentStyle={{ width: "576px" }}
        header={
          data?.dateOfDeath
            ? t("Objects:mark_as_not_deceased")
            : t("Objects:mark_as_deceased")
        }
        customFooter={
          <DeceasedFooter
            data={data}
            deceasedDate={deceasedDate}
            t={t}
            setDeceasedDate={setDeceasedDate}
            update={update}
            setDeceasedModalOpen={setDeceasedModalOpen}
            isLoading={isLoading}
          />
        }
        isOpen={deceasedModalOpen}
        onClose={() => setDeceasedModalOpen(false)}
      >
        <DateOfDeathModal
          deceasedDate={deceasedDate}
          setDeceasedDate={setDeceasedDate}
        />
      </PrimeModal>
      {showOtherBtn && (
        <PopupContextMenu
          className="mt-8"
          key="object_details_actions"
          position="right bottom"
          nested
          trigger={() => (
            <div>
              <Button
                type="button"
                variant="secondary"
                image="dots-horizontal"
                text={t("Common:other")}
              />
            </div>
          )}
        >
          <>
            {config?.show.includes("UserAbsence") && (
              <>
                {data?.active && (
                  <PopupContextMenuLinkButton
                    icon="calendar"
                    text={
                      <Tooltip
                        trigger={() => <span>{absenceTextToDisplay()}</span>}
                        position="right center"
                        text={absenceTextToDisplay()}
                      />
                    }
                    onClick={() => setScheduleAbsenceModalOpen(true)}
                  />
                )}

                {config?.show.includes("UserAbsence") && (
                  <PopupContextMenuLinkButton
                    icon="history"
                    onClick={() => setAbsenceHistoryModalOpen(true)}
                    text={
                      <Tooltip
                        trigger={() => (
                          <span>{t("Objects:absence_history")}</span>
                        )}
                        position="right center"
                        text={t("Objects:absence_history")}
                      />
                    }
                  />
                )}

                {absenceData?.ongoing?.[0] && data?.active && (
                  <PopupContextMenuLinkButton
                    icon="pencil"
                    onClick={() => {
                      setIsEditAbsence(true);
                      setScheduleAbsenceModalOpen(true);
                    }}
                    text={
                      <Tooltip
                        trigger={() => (
                          <span>{t("Objects:edit_ongoing_absence")}</span>
                        )}
                        position="right center"
                        text={t("Objects:edit_ongoing_absence")}
                      />
                    }
                  />
                )}
              </>
            )}

            {config?.show.includes("UserAbsence") &&
            absenceData?.ongoing.length ? (
              <PopupContextMenuLinkButton
                icon="calendar_unavailable"
                onClick={() => setCancelAbsenceModalOpen(true)}
                text={
                  <Tooltip
                    trigger={() => <span>{absenceTextToDisplay(true)}</span>}
                    position="right center"
                    text={absenceTextToDisplay(true)}
                  />
                }
              />
            ) : null}

            <Divider />

            {config?.show.find((key) => key === "UserTestAlarm") && active && (
              <PopupContextMenuLinkButton
                icon="beaker"
                text={t("Objects:test_alarm_create")}
                onClick={() => setTestAlarmOpen(true)}
              />
            )}

            {config?.show.includes("SystemLogAccess") &&
              config?.show.includes("NewAdminBeta") && (
                <PopupContextMenuLinkButton
                  icon="clipboard-list"
                  text={t("Objects:request_logs")}
                  onClick={async () => {
                    if (data) {
                      try {
                        await requestDeviceLog(data.id, authenticatedRequest);

                        notify({
                          message: t("Objects:request_successfully_send"),
                        });
                      } catch (error: any) {
                        notifyApiErrors(
                          error.response?.data?.errors,
                          error.response?.status
                        );
                      }
                    }
                  }}
                />
              )}

            {config?.show.includes("NewAdminBeta") && (
              <Modal
                trigger={() => (
                  <PopupContextMenuLinkButton
                    icon="link"
                    text={t("Objects:download_link_send")}
                  />
                )}
              >
                {(close) => <DownloadLinkModal onClose={close} />}
              </Modal>
            )}

            {config?.show.find((key) => key === "DateOfDeath") && (
              <PopupContextMenuLinkButton
                icon={!data?.dateOfDeath ? "heart-deceased" : "heart-alive"}
                text={
                  !data?.dateOfDeath
                    ? t("Objects:set_date_of_death")
                    : t("Objects:remove_date_of_death")
                }
                onClick={() => setDeceasedModalOpen(true)}
              />
            )}

            {config?.show.find((key) => key === "DeleteUser") && (
              <Modal
                trigger={() => (
                  <PopupContextMenuLinkButton
                    icon="trash"
                    text={t("Objects:delete_object")}
                  />
                )}
              >
                {(close) => <DeleteObjectModal onClose={close} />}
              </Modal>
            )}
          </>
        </PopupContextMenu>
      )}
    </>
  );
};

export default ObjectActions;
