import classNames from "classnames";
import { InputSwitch } from "primereact/inputswitch";
import styles from "./ToggleSwitch.module.css";

export interface ToggleSwitchProps {
  name: string;
  id?: string;
  label?: string;
  leftLabel?: string;
  checked?: boolean;
  onChange(arg0: any): void;
  disabled?: boolean;
  color?: string;
  noWrapLabel?: boolean;
}

export const ToggleSwitch = ({
  name,
  id,
  label,
  leftLabel,
  checked,
  onChange,
  disabled,
  color,
  noWrapLabel,
}: ToggleSwitchProps) => (
  <div className={styles.toggleSwitchWrapper}>
    {leftLabel && (
      <label
        style={{
          marginRight: "8px",
          color,
          ...(noWrapLabel && { whiteSpace: "nowrap" }),
        }}
        className={styles.labelRowLabel}
        htmlFor={id || name}
      >
        {leftLabel}
      </label>
    )}
    <div
      className={classNames([styles.toggleSwitchContainer], {
        [styles.toggleSwitchDisabled]: disabled,
      })}
    >
      <InputSwitch
        name={name}
        inputId={id}
        checked={!!checked}
        disabled={disabled}
        onChange={onChange}
      />
    </div>
    {label && (
      <label
        style={{
          marginLeft: "8px",
          color,
          ...(noWrapLabel && { whiteSpace: "nowrap" }),
        }}
        className={styles.labelRowLabel}
        htmlFor={id || name}
      >
        {label}
      </label>
    )}
  </div>
);

export default ToggleSwitch;
