import moment from "moment-timezone";
import { getStoredToken } from "../user/token";

export class DateTimeHelper {
  constructor() {
    throw new Error(`Static class!`);
  }

  /** Checks that string is time string with specified format */
  static checkIsTimeString(
    timeString: string,
    timeFormat: string = "HH:mm"
  ): boolean {
    return moment(timeString, timeFormat, true).isValid();
  }

  static checkIsDateTimeString(timestamp: string) {
    return moment(timestamp, "YYYY-MM-DDTHH:mm:ss", true).isValid();
  }

  /** Returns time components from time string */
  static getTimeComponentsFromTimeString(
    timeString: string,
    timeFormat: string = "HH:mm"
  ): { hours: number; minutes: number } {
    const momentObj = moment(timeString, timeFormat, true);

    return {
      hours: momentObj.hours(),
      minutes: momentObj.minutes(),
    };
  }

  /** Returns time string from time components */
  static getTimeStringFromTimeComponents(
    hour: number,
    minute: number,
    timeFormat: string = "HH:mm"
  ): string {
    const momentObj = moment().set({ hour, minute }).format(timeFormat);

    return momentObj.toString();
  }

  /** For specified date time range, destructs it to separate date range (with 00:00:00 time set) and time range */
  static destructDateTimeRange(
    dateTimeRange: Date[],
    timeFormat: string
  ): { dateRange: Date[]; timeRange: string[] } {
    return {
      dateRange: [
        moment(dateTimeRange[0])
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          .toDate(),
        moment(dateTimeRange[1])
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          .toDate(),
      ],
      timeRange: [
        moment(dateTimeRange[0]).format(timeFormat),
        moment(dateTimeRange[1]).format(timeFormat),
      ],
    };
  }

  /** Builds date time range from separate date range (with 00:00:00 time set) and time range */
  static buildDateTimeRange(
    dateRange: Date[],
    timeRange: string[],
    timeFormat: string
  ): Date[] {
    const timeStartComps = DateTimeHelper.getTimeComponentsFromTimeString(
      timeRange[0],
      timeFormat
    );
    const timeEndComps = DateTimeHelper.getTimeComponentsFromTimeString(
      timeRange[1],
      timeFormat
    );
    const start: Date = moment(dateRange[0])
      .set({
        hours: timeStartComps.hours,
        minutes: timeStartComps.minutes,
        seconds: 0,
        milliseconds: 0,
      })
      .toDate();
    const end: Date = moment(dateRange[1])
      .set({
        hours: timeEndComps.hours,
        minutes: timeEndComps.minutes,
        seconds: 0,
        milliseconds: 0,
      })
      .toDate();

    return [start, end];
  }

  static prettyDateFromNow(
    theDate: Date,
    last: string,
    yesterday: string,
    today: string,
    tomorrow: string
  ) {
    const fromNow = moment(theDate).fromNow();
    return moment(theDate).calendar(null, {
      lastWeek: `[${last}] dddd`,
      lastDay: `[${yesterday}]`,
      sameDay: `[${today}]`,
      nextDay: `[${tomorrow}]`,
      nextWeek: "dddd",
      sameElse() {
        return `[${fromNow}]`;
      },
    });
  }

  static formatDate(datetime: Date): string {
    const dateFormat = getStoredToken()?.guiConfig.dateFormat;
    const timezone = getStoredToken()?.guiConfig.timezone.timezoneName!;
    return moment(datetime).utc().tz(timezone).format(dateFormat);
  }

  static formatDateISOFormat(datetime: Date): string {
    return moment(datetime).format("YYYY-MM-DD");
  }

  static formatDateString(datetime: string): string {
    const dateFormat = getStoredToken()?.guiConfig.dateFormat;
    const timezone = getStoredToken()?.guiConfig.timezone.timezoneName!;
    if (!datetime.endsWith("Z") && !datetime.includes("+")) {
      datetime += "Z";
    }
    return moment(datetime).utc().tz(timezone).format(dateFormat);
  }

  static formatTime(datetime: Date): string {
    const timeFormat = getStoredToken()?.guiConfig.timezone.timeFormat;
    const timezone = getStoredToken()?.guiConfig.timezone.timezoneName!;
    return moment(datetime).tz(timezone).format(timeFormat);
  }

  static formatTimeString(datetime: string, forceFormat: string = ""): string {
    const timeFormat =
      forceFormat ?? getStoredToken()?.guiConfig.timezone.timeFormat;
    const timezone = getStoredToken()?.guiConfig.timezone.timezoneName!;
    // if our ISO datetime string is not really an ISO datetime string we need to add a 'Z' so that timezones are handled correctly
    if (!datetime.endsWith("Z") && !datetime.includes("+")) {
      datetime += "Z";
    }

    return moment(datetime).tz(timezone).format(timeFormat);
  }

  static formatDateTimeString(datetime: string, format?: string): string {
    const datetimeFormat = getStoredToken()?.guiConfig.timezone.datetimeFormat;
    const timezone = getStoredToken()?.guiConfig.timezone.timezoneName!;

    // if our ISO datetime string is not really an ISO datetime string we need to add a 'Z' so that timezones are handled correctly
    if (!datetime.endsWith("Z") && !datetime.includes("+")) {
      datetime += "Z";
    }

    return moment(datetime)
      .tz(timezone)
      .format(format || datetimeFormat);
  }

  static formatDateTime(datetime: Date, format?: string): string {
    const datetimeFormat = getStoredToken()?.guiConfig.timezone.datetimeFormat;
    const timezone = getStoredToken()?.guiConfig.timezone.timezoneName!;
    return moment(datetime)
      .tz(timezone)
      .format(format || datetimeFormat);
  }

  static formatDateTimeWithoutTimezone(datetime: Date): string {
    const datetimeFormat = getStoredToken()?.guiConfig.timezone.datetimeFormat;
    return moment(datetime).format(datetimeFormat);
  }

  static timezoneDateTime(datetime: string): string {
    const timezone = getStoredToken()?.guiConfig.timezone.timezoneName!;
    // if our ISO datetime string is not really an ISO datetime string we need to add a 'Z' so that timezones are handled correctly
    if (!datetime.endsWith("Z") && !datetime.includes("+")) {
      datetime += "Z";
    }
    return moment(datetime).tz(timezone).format("YYYY-MM-DDTHH:mm:ssZ");
  }
}
export default DateTimeHelper;
