import { useState } from "react";
import { ContactResponse } from "../../../../core/api/contacts/types";
import useTranslations from "../../../../core/i18n/useTranslations";
import useUser from "../../../../core/user/useUser";
import Button from "../../../../ui-lib/components/Button/Button";
import PopupContextMenu from "../../../../ui-lib/components/Menus/PopupContextMenu/PopupContextMenu";
import { PopupContextMenuLinkButton } from "../../../../ui-lib/components/Menus/PopupContextMenu/PopupContextMenuLinkButton";
import AddCommonContactModal from "./AddCommonContactModal";
import CreatePrivateContactModal from "./CreatePrivateContactModal";

const AddContactActions = ({
  objectId,
  contacts,
  onContactUpdate,
}: {
  objectId: string;
  contacts: ContactResponse[];
  onContactUpdate: () => void;
}) => {
  const t = useTranslations();
  const { config } = useUser();

  const [createCommonContactModalOpen, setCreateCommonContactModalOpen] =
    useState(false);
  const [addContactModalOpen, setAddContactModalOpen] = useState(false);

  const hasHideAddObjectCommonContactPermission = config?.show.includes(
    "HideAddObjectCommonContact"
  );

  return (
    <>
      <PopupContextMenu
        nested
        position="bottom right"
        className="autoWidth"
        trigger={() => (
          <div>
            <Button
              type="button"
              variant="secondary"
              image="user-add"
              text={t("Contacts:add")}
            />
          </div>
        )}
      >
        <>
          <PopupContextMenuLinkButton
            icon="user"
            text={t("Contacts:create_contact")}
            onClick={() => setCreateCommonContactModalOpen(true)}
          />

          {!hasHideAddObjectCommonContactPermission && (
            <PopupContextMenuLinkButton
              icon="user-group"
              text={t("Contacts:add")}
              onClick={() => setAddContactModalOpen(true)}
            />
          )}
        </>
      </PopupContextMenu>
      {createCommonContactModalOpen && (
        <CreatePrivateContactModal
          createCommonContactModalOpen={createCommonContactModalOpen}
          setCreateCommonContactModalOpen={setCreateCommonContactModalOpen}
          objectId={objectId}
          onAdd={() => {
            onContactUpdate();
            setCreateCommonContactModalOpen(false);
          }}
        />
      )}
      {addContactModalOpen && (
        <AddCommonContactModal
          addContactModalOpen={addContactModalOpen}
          objectId={objectId}
          added={contacts.map((c) => c.id)}
          onClose={() => setAddContactModalOpen(false)}
          onAdd={onContactUpdate}
        />
      )}
    </>
  );
};

export default AddContactActions;
