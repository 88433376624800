import { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { IOrganizationProps } from "../../core/api/organizations/types";
import { transformOrganizationsToTreeData } from "../../core/utils/organizations";
import { Column, Row, Spacer } from "../../components/Layout/Layout";
import { RowData } from "./Organizations";
import { ITreeData } from "../../ui-lib/components/Hierarchy/Tree";
import {
  deleteOrganization,
  getOrganizationTrees,
} from "../../core/api/organizations/organizations";
import TableRowSelectionPopup, {
  TableRowSelectionPopupActions,
} from "../../ui-lib/components/Tables/TableRowSelectionPopup";
import usePageState from "../../core/pagestate/usePageState";
import useTranslations from "../../core/i18n/useTranslations";
import ChildrenTreeModal from "../../ui-lib/components/ChildrenTreePopup/childrenTreeModal";
import useUser from "../../core/user/useUser";
import NoItems from "../../ui-lib/components/Tables/NoItems";
import Button from "../../ui-lib/components/Button/Button";
import Table from "../../ui-lib/components/Tables/Table";
import TableCell from "../../ui-lib/components/Tables/TableCell";
import BulkDeleteModal from "../../components/BulkDeleteModal";
import Icon from "../../ui-lib/components/Icon";
import PrimeTooltip from "../../ui-lib/components/PrimeTooltip/PrimeTooltip";

import styles from "../Layout.module.css";

export const OrganizationsTable = observer(
  ({
    uploadNewOrganizations,
    items,
    subOrganizations,
    pageFilter,
    setPageFilter,
    tableName,
    searchParam,
    totalItems,
  }: {
    organizationsData: ITreeData[];
    searchParam: string;
    totalItems: number;
    uploadNewOrganizations: () => void;
    uploadSubOrg?: () => void;
    items: IOrganizationProps[];
    tableName?: string;
    subOrganizations?: boolean;
    pageFilter: {
      page: number;
      pageSize: number;
    };
    setPageFilter: (args: { page: number; pageSize: number }) => void;
  }) => {
    const { authenticatedRequest } = useUser();

    const { id } = useParams<{ id: string }>();
    const t = useTranslations();
    const history = useHistory();
    const clearSelectedFunction = useRef<Function>();
    const selectedItemRows = useRef<RowData[]>([]);
    const childrenData = useRef<RowData>();
    const tableRowSelectionPopupRef =
      useRef<TableRowSelectionPopupActions<Record<string, any>>>(null);
    const [deletionModalOpen, setDeletionModalOpen] = useState<boolean>(false);
    const [childrenTreeModalOpen, setChildrenTreeModalOpen] =
      useState<boolean>(false);
    const pageState = usePageState();

    useEffect(() => {
      sessionStorage.removeItem("prevPath");
    }, []);

    return (
      <>
        <BulkDeleteModal
          isOpen={deletionModalOpen}
          onClose={() => setDeletionModalOpen(false)}
          onDelete={(success) => {
            setDeletionModalOpen(false);
            tableRowSelectionPopupRef.current?.close(true);
            clearSelectedFunction.current?.();
            if (success) {
              setTimeout(() => {
                uploadNewOrganizations();
              }, 500);
            }
          }}
          ids={
            selectedItemRows.current?.map((s) => (s.id || "").toString()) || []
          }
          labels={{
            single: t("Organizations:type"),
            multi: t("Organizations:type_multi"),
          }}
          apiFunc={deleteOrganization}
        />
        <TableRowSelectionPopup
          ref={tableRowSelectionPopupRef}
          renderBody={(selectedItems) => (
            <Row type="right" align="center">
              <Spacer size={16} />
              <Button
                variant="destructive"
                text={`${t("Common:delete_selection")} (${
                  selectedItems.length
                })`}
                onClick={() => {
                  selectedItemRows.current = selectedItems as RowData[];
                  setDeletionModalOpen(true);
                }}
              />
            </Row>
          )}
        />
        <ChildrenTreeModal
          organizations={childrenData.current as RowData}
          isOpen={childrenTreeModalOpen}
          onClose={() => {
            setChildrenTreeModalOpen(false);
          }}
        />
        <Column className={styles.wrapper}>
          <Column
            className={!subOrganizations ? styles.content : ""}
            style={{ paddingTop: "16px", width: "100%" }}
          >
            {items.length > 0 ? (
              <Column className={styles.tableContainer} type="top">
                <Table<IOrganizationProps>
                  tableName={tableName}
                  showRowHover
                  withRowSelection
                  withLazyLoading
                  hideEmptyMessage
                  withPagination
                  rowActionsFixed
                  rowActionsColWidth={100}
                  items={items}
                  onRowSelectionChange={(selectedItems) => {
                    tableRowSelectionPopupRef.current?.open(selectedItems);
                  }}
                  setClearItemsFunction={(func) =>
                    (clearSelectedFunction.current = func)
                  }
                  columns={[
                    {
                      style: {
                        minWidth: "unset",
                        width: "48px",
                        textAlign: "center",
                      },
                      fieldTemplate: (rowData) =>
                        !rowData.parentId ? (
                          <>
                            <PrimeTooltip
                              target={`#org${rowData.id}`}
                              position="top"
                            />
                            <span
                              id={`org${rowData.id}`}
                              data-pr-tooltip={t(
                                "Organizations:first_level_organisation"
                              )}
                            >
                              <Icon name="arrow-up" size={16} />
                            </span>
                          </>
                        ) : null,
                    },
                    {
                      header: t("Organizations:org_col_name"),
                      fieldTemplate: (rowData) => (
                        <TableCell
                          value={rowData.name}
                          linkTo={`/adminportal/organizations/${rowData.id}`}
                          onClick={() => {
                            pageState.setInitialIds({
                              name: rowData.name,
                              id: id ? +id : 1,
                            });
                          }}
                        />
                      ),
                    },
                  ]}
                  rowActions={[
                    {
                      icon: "hierarchy",
                      text: t("Common:view_hierarchy"),
                      onClick: (rowData) => {
                        getOrganizationTrees(
                          { parentId: rowData.id, getRoot: true },
                          authenticatedRequest
                        ).then((res: any) => {
                          const tree = transformOrganizationsToTreeData(
                            res.data
                          )[0];

                          const tmp: RowData = {
                            id: rowData.id.toString(),
                            value: rowData.name,
                            children: tree.children || [],
                            root: tree,
                          };
                          childrenData.current = tmp;
                          setChildrenTreeModalOpen(true);
                        });
                      },
                    },
                    {
                      icon: "pencil-alt",
                      text: t("Common:label_edit"),
                      onClick: (rowData) => {
                        history.push(
                          `/adminportal/organizations/${rowData.id}`
                        );
                      },
                    },
                    {
                      icon: "trash",
                      text: t("Common:delete"),
                      iconVariant: "secondary",
                      onClick: async (rowData: any) => {
                        selectedItemRows.current = [rowData];
                        setDeletionModalOpen(true);
                      },
                    },
                  ]}
                  paginatedItems={{
                    items,
                    pagination: {
                      offset: pageFilter.pageSize * (pageFilter.page - 1),
                      limit: pageFilter.pageSize,
                      total: totalItems,
                    },
                  }}
                  onPageChange={(nextPage) => {
                    const page = Math.floor(nextPage.offset / nextPage.limit);

                    if (!Number.isNaN(page) && nextPage.limit) {
                      setPageFilter({
                        page: page + 1,
                        pageSize: nextPage.limit,
                      });
                    }
                  }}
                />
              </Column>
            ) : (
              <NoItems
                title={
                  !searchParam
                    ? t("Table:noresult_title")
                    : t("Table:no_results_found")
                }
                icon={!searchParam ? "eye-off" : undefined}
                subTitle={
                  !searchParam
                    ? t("Table:noresult_subtitle")
                    : t("Table:adjust_filter_description")
                }
                clear={undefined}
              />
            )}
          </Column>
        </Column>
      </>
    );
  }
);
