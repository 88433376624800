import { action, makeObservable, observable } from "mobx";
import RootStore from "../RootStore";

interface IInitialIds {
  name: string;
  id: number;
}

interface IAdvancedStateData {
  [key: string]: { customerId: number; value?: string | number };
}

class PageStateStore {
  pageSubTitle: string | undefined;

  pageIcon: string | undefined;

  path: string;

  initialIds: IInitialIds[];

  showNavigation: boolean;

  minimizeNavigation: boolean;

  rootStore: RootStore;

  headerFlag: boolean;

  hideSaveButton: boolean;

  advancedSettingData: IAdvancedStateData;

  isSpecialSearch: boolean;

  advancedSettingError: boolean;

  columnSize: number | string;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.pageSubTitle = undefined;
    this.pageIcon = undefined;
    this.showNavigation = false;
    this.minimizeNavigation = false;
    this.path = "/";
    this.initialIds = [];
    this.headerFlag = false;
    this.hideSaveButton = false;
    this.advancedSettingData = {};
    this.isSpecialSearch = false;
    this.advancedSettingError = false;
    this.columnSize = 0;

    makeObservable(this, {
      pageSubTitle: observable,
      path: observable,
      initialIds: observable,
      pageIcon: observable,
      showNavigation: observable,
      minimizeNavigation: observable,
      headerFlag: observable,
      advancedSettingData: observable,
      hideSaveButton: observable,
      isSpecialSearch: observable,
      advancedSettingError: observable,
      columnSize: observable,
      setPageSubTitle: action,
      setPath: action,
      setPageIcon: action,
      setShowNavigation: action,
      setMinimizeNavigation: action,
      setInitialIds: action,
      removeInitialId: action,
      setHeaderFlag: action,
      setHideSaveButton: action,
      setAdvancedSettingData: action,
      setAdvancedSettingDataEmpty: action,
      setIsSpecialSearch: action,
      setAdvancedSettingError: action,
      setColumnSize: action,
    });
  }

  setPageSubTitle(subTitle: string | undefined) {
    this.pageSubTitle = subTitle;
  }

  setPath(path: string) {
    this.path = path;
  }

  setInitialIds(obj: IInitialIds) {
    if (Object.keys(obj).length) {
      this.initialIds.push(obj);
    } else {
      this.initialIds = [];
    }
  }

  removeInitialId() {
    this.initialIds.pop();
  }

  setPageIcon(icon: string | undefined) {
    this.pageIcon = icon;
  }

  setShowNavigation(open: boolean) {
    this.showNavigation = open;
  }

  setMinimizeNavigation(minimized: boolean) {
    this.minimizeNavigation = minimized;
  }

  setHeaderFlag(flag: boolean) {
    this.headerFlag = flag;
  }

  setHideSaveButton(value: boolean) {
    this.hideSaveButton = value;
  }

  setAdvancedSettingData(obj: IAdvancedStateData) {
    this.advancedSettingData = { ...this.advancedSettingData, ...obj };
  }

  setAdvancedSettingDataEmpty() {
    this.advancedSettingData = {};
  }

  setIsSpecialSearch(specialSearch: boolean) {
    this.isSpecialSearch = specialSearch;
  }

  setAdvancedSettingError(error: boolean) {
    this.advancedSettingError = error;
  }
  setColumnSize(size: number | string) {
    this.columnSize = size;
  }
}

export default PageStateStore;
