import { Dispatch, SetStateAction, useEffect, useRef } from "react";
import { matchPath, useLocation, Link, RouteProps } from "react-router-dom";
import classnames from "classnames";
import Icon from "../../Icon";
import { Tooltip } from "../../Tooltip/Tooltip";

import styles from "../Menu.module.css";

export interface SideBarLinkProps {
  to: string;
  matchProps?: RouteProps;
  text: string;
  hideText?: boolean;
  icon?: string;
  importantIcon?: string;
  showTooltip?: boolean;
  withSpace?: boolean;
  watchKey?: string;

  onClick?(): any;
  dataCy?: string;
  setIsSubLinkActive?: Dispatch<SetStateAction<boolean>>;
}

export const SideBarLink = ({
  to,
  matchProps,
  text,
  hideText = false,
  icon = undefined,
  importantIcon = undefined,
  showTooltip = false,
  onClick,
  dataCy,
  withSpace,
  //  Key passed from submenu items that contained in page path
  watchKey,
  setIsSubLinkActive,
}: SideBarLinkProps) => {
  const location = useLocation();
  const linkRef = useRef<HTMLAnchorElement>(null);
  const match = matchPath(
    location.pathname,
    matchProps ?? {
      path: to,
      exact: true,
      strict: false,
    }
  );

  const isActive = !!match;

  useEffect(() => {
    if (isActive) {
      linkRef?.current?.focus();
    }
    /** Hihglight dropdown submenu item */
    watchKey && location.pathname.includes(watchKey)
      ? setIsSubLinkActive?.(true)
      : setIsSubLinkActive?.(false);
  }, [isActive]);

  const element = (
    <span
      className={classnames({
        [styles.linkWrapper]: true,
        [styles.activeLinkWrapper]: isActive,
      })}
    >
      <Link
        data-cy={dataCy}
        to={to}
        className={classnames({
          [styles.sideBarLink]: true,
          [styles.withSpace]: withSpace,
          [styles.sideBarLinkActive]: isActive,
        })}
        onClick={onClick}
        innerRef={linkRef}
        tabIndex={isActive ? -1 : 0}
      >
        {icon ? (
          <Icon
            name={icon}
            size={24}
            color={isActive ? "Primary-700" : "Grey-500"}
            className={styles.sideBarLinkIcon}
          />
        ) : null}
        {!hideText ? (
          <span className={styles.sideBarLinkText}>{text}</span>
        ) : null}
        {importantIcon ? (
          <Icon
            name={importantIcon}
            size={24}
            color="Primary-700"
            className={styles.sideBarLinkImportantIcon}
          />
        ) : null}
      </Link>
    </span>
  );

  return showTooltip ? (
    <Tooltip
      trigger={() => element}
      position="right center"
      text={text}
      disabled={false}
    />
  ) : (
    element
  );
};
