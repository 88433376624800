/* eslint-disable react/jsx-props-no-spreading */
import { ReactNode, RefObject } from "react";
import ReactJSPopup from "reactjs-popup";
import { defaultPopupProps } from "./defaultPopupProps";

type CloseFunction = () => void;

export interface ModalActions {
  open: () => void;
  close: () => void;
  toggle: () => void;
}

interface ModalProps {
  modalRef?: RefObject<ModalActions>;

  /* Function that returns element that triggers menu. Note: if custom component generates ref issues, try to wrap in div, for instance. */
  trigger?: () => JSX.Element;

  /* When set to true, component is rendered as usual but menu is disabled for it */
  disabled?: boolean;

  /* Starting from v2 you should add nested props if you are going to use nested popups ( to make sure onClickOutside behavior will work as expected) */
  nested?: boolean;

  closeOnDocumentClick?: boolean;

  children: (close: CloseFunction) => ReactNode;
}

const Modal = ({
  modalRef,
  trigger,
  disabled = false,
  nested = true,
  closeOnDocumentClick,
  children,
}: ModalProps) => (
  <ReactJSPopup
    {...defaultPopupProps}
    ref={modalRef}
    modal
    on={["click"]}
    trigger={trigger}
    position="center center"
    disabled={disabled}
    nested={nested}
    closeOnDocumentClick={closeOnDocumentClick || false}
    overlayStyle={{
      backgroundColor: `rgba(0, 0, 0, 0.25)`,
      zIndex: 9999,
    }}
  >
    {/* @ts-ignore */}
    {(close: CloseFunction) => children(close)}
  </ReactJSPopup>
);

export default Modal;
