import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ModalContent from "../../../../ui-lib/components/Popup/ModalContent";
import useTranslations from "../../../../core/i18n/useTranslations";
import { Row, Spacer } from "../../../../components/Layout/Layout";
import Button from "../../../../ui-lib/components/Button/Button";
import {
  AdministratorDetails,
  PasswordConstraints,
} from "../../../../core/api/administrators/types";
import { ResponseCenterDetails } from "../../../../core/api/responsecenters/types";
import {
  validateEmailIfExists,
  validateForbiddenChars,
  validateLowerCase,
  validateNumberCount,
  validatePhoneNumberIfExists,
  validateUpperCase,
} from "../../../../core/helpers/validation";
import TextInput from "../../../../ui-lib/components/Inputs/TextInput";
import PasswordInput from "../../../../ui-lib/components/Inputs/PasswordInput";
import {
  createAdministrator,
  getPasswordConstraints,
} from "../../../../core/api/administrators/administrators";
import {
  getPasswordErrorMessage,
  notifyApiErrors,
} from "../../../../core/helpers/helpers";
import useUser from "../../../../core/user/useUser";
import { notify } from "../../../../ui-lib/components/Alerts/Toast";

export const CreateRespondentModal = ({
  onClose,
  receptionData,
}: {
  onClose: () => void;
  receptionData: ResponseCenterDetails;
}) => {
  const t = useTranslations();
  const history = useHistory();
  const { authenticatedRequest } = useUser();
  const [isLoading, setIsLoading] = useState(false);
  const [passData, setPassData] = useState<PasswordConstraints>();

  const onSubmit = async (values: AdministratorDetails) => {
    try {
      setIsLoading(true);
      const result = await createAdministrator(values, authenticatedRequest);
      notify({ message: t("AlarmReceptions:respondent_success") });
      onClose();
      history.push(`/adminportal/administrators/${result.data}`);
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchPasswordComplexity = async () => {
    try {
      setIsLoading(true);
      const result = await getPasswordConstraints(
        `${receptionData.id}`,
        authenticatedRequest,
        "responseCenterId"
      );
      setPassData(result.data);
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPasswordComplexity();
  }, []);

  const {
    control,
    formState: { isSubmitting, errors },
    handleSubmit,
    reset,
    trigger,
    setValue,
  } = useForm<AdministratorDetails>({
    defaultValues: {
      responseCenterId: receptionData.id,
      displayName: "RoleOperator",
    },
  });

  return (
    <ModalContent onClose={onClose} width="848px">
      <h2 className="subTitle">{t("AlarmReceptions:add_respondent")}</h2>
      <Spacer size={16} />
      <p style={{ color: "var(--Grey-600)" }}>
        {t("AlarmReceptions:add_respondent_subtitle")}
      </p>
      <Spacer size={32} />
      <form
        onSubmit={handleSubmit(async (values) => {
          await onSubmit(values);
          reset(values);
        })}
      >
        <Row>
          <TextInput
            css={{ width: "48%" }}
            label={t("Common:belongs_to")}
            value={receptionData.displayName}
            disabled
          />
        </Row>
        <Spacer size={16} />
        <Row>
          <Controller
            name="username"
            control={control}
            rules={{
              required: true,
              minLength: 1,
              maxLength: 60,
            }}
            render={({ field }) => (
              <TextInput
                required
                label={t("Administrator:general_input_username")}
                placeholder={t("Common:enter_username")}
                value={field.value}
                onChange={(e) => {
                  setValue("username", e.target.value);
                  trigger(field.name);
                }}
                validationError={
                  errors?.username && t("Errors:input_organization_name")
                }
              />
            )}
          />
          <Spacer size={32} />
          <Controller
            name="password"
            control={control}
            rules={{
              required: true,
              minLength: passData?.minLength,
              validate: {
                lowerCase: (v) => validateLowerCase(v, passData!),
                upperCase: (v) => validateUpperCase(v, passData!),
                countNumbers: (v) => validateNumberCount(v, passData!),
                forbiddenChars: (v) => validateForbiddenChars(v, passData!),
              },
            }}
            render={({ field }) => (
              <PasswordInput
                required
                label={t("Common:login_input_password")}
                placeholder={
                  passData
                    ? t(
                        "Administrator:password_constraints_minimum_length"
                      ).replace("{0}", `${passData.minLength}`)
                    : ""
                }
                value={field.value}
                onChange={field.onChange}
                disabled={!passData || isLoading}
                validationError={
                  errors?.password &&
                  getPasswordErrorMessage(errors.password.type, passData!)
                }
              />
            )}
          />
        </Row>
        <Spacer size={16} />
        <Row style={{ width: "48%" }}>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextInput
                label={t("Common:name")}
                placeholder={t("Administrator:enter_name")}
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
        </Row>
        <Spacer size={16} />
        <Row>
          <Controller
            name="email"
            control={control}
            rules={{
              maxLength: 60,
              validate: validateEmailIfExists,
            }}
            render={({ field }) => (
              <TextInput
                placeholder={t("Common:placeholder_email")}
                label={t("Contacts:input_email_address")}
                value={field.value}
                onChange={field.onChange}
                validationError={
                  errors?.email && t("Errors:input_email_format")
                }
              />
            )}
          />
          <Spacer size={32} />
          <Controller
            name="telephone"
            control={control}
            rules={{
              validate: validatePhoneNumberIfExists,
            }}
            render={({ field }) => (
              <TextInput
                placeholder={t("Common:phone_number_placeholder")}
                label={t("Filters:Telephone")}
                value={field.value}
                onChange={field.onChange}
                validationError={
                  errors?.telephone &&
                  t("Errors:ValidationHint_TelephoneNumber")
                }
              />
            )}
          />
        </Row>
        <Spacer size={32} />
        <Row type="left">
          <Button
            text={t("Administrator:create_administrator")}
            type="submit"
            disabled={isSubmitting}
          />
          <Spacer size={16} />
          <Button
            variant="secondary"
            text={t("Common:cancel")}
            onClick={onClose}
          />
        </Row>
      </form>
    </ModalContent>
  );
};
