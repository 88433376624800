import { useState } from "react";
import { IPFilter } from "../../../core/api/organizations/types";
import { notify } from "../../../ui-lib/components/Alerts/Toast";
import { notifyApiErrors } from "../../../core/helpers/helpers";
import { Row, Spacer } from "../../../components/Layout/Layout";
import { putResellerIP } from "../../../core/api/sellers/sellers";

import useTranslations from "../../../core/i18n/useTranslations";
import useUser from "../../../core/user/useUser";
import StyledModal from "../../../ui-lib/components/Modal/Modal";
import Button from "../../../ui-lib/components/Button/Button";
import { ResellerIPForm } from "./ResellerIPForm";

export const CreateEditResellerIPModal = ({
  sellerId,
  onClose,
  isOpen,
  defaultValues,
  filters,
}: {
  sellerId: number;
  onClose: (reload?: boolean) => void;
  isOpen: boolean;
  defaultValues?: IPFilter;
  filters?: IPFilter[];
}) => {
  const t = useTranslations();
  const { authenticatedRequest } = useUser();

  const [isLoading, setIsLoading] = useState(false);
  const updating = !!defaultValues;

  const onSubmit = async (values: IPFilter) => {
    setIsLoading(true);
    let updatedIpList: IPFilter[] = [];

    if (updating) {
      updatedIpList =
        filters?.map((item) => (item.id === values.id ? values : item)) || [];
    }

    const filtersData = () => {
      if (updating) {
        return updatedIpList;
      }
      if (filters && filters.length) {
        return [...filters, values];
      }
      return [values];
    };
    try {
      await putResellerIP(sellerId, authenticatedRequest, {
        filters: filtersData(),
      });
      onClose(true);
      notify({
        message: t(
          `AlarmReceptions:ip_${defaultValues ? "edit" : "create"}_success`
        ),
      });
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <StyledModal
      modalTitle={t(
        `Organizations:${defaultValues ? "edit" : "create"}_ip_title`
      )}
      isOpen={isOpen}
      onClose={() => onClose()}
      width="848px"
      closeOnDocumentClick={false}
      customFooter={() => (
        <Row type="left">
          <Button
            form="ip_address_from"
            type="submit"
            loading={isLoading}
            variant="primary"
            disabled={isLoading}
            text={t(
              defaultValues
                ? "Common:save_changes"
                : "AlarmReceptions:create_ip_address"
            )}
          />
          <Spacer size={8} />
          <Button
            variant="secondary"
            text={t("Common:cancel")}
            onClick={() => onClose()}
          />
        </Row>
      )}
    >
      <ResellerIPForm onSubmit={onSubmit} defaultValues={defaultValues} />
    </StyledModal>
  );
};
