import { observer } from "mobx-react";
import { useState } from "react";
import LoadingSpinner from "../../../ui-lib/components/Loading/LoadingSpinner";
import { Spacer } from "../../../components/Layout/Layout";
import Alert from "../../../ui-lib/components/Alerts/Alert";
import ObjectSubscriptionDetails from "./ObjectSubscriptionDetails";
import useTranslations from "../../../core/i18n/useTranslations";
import styles from "./ObjectSubscription.module.css";
import useSharedObject from "../SharedObjectContext/useSharedObject";
import { ObjectActivationData } from "../../../core/api/objects/types";

const ObjectSubscription = ({
  objectId,
  subscriptionDataObject: { fetchObjectSubscription, subscription },
}: {
  objectId: string;
  subscriptionDataObject: {
    fetchObjectSubscription: () => Promise<void>;
    subscription: ObjectActivationData;
  };
}) => {
  const t = useTranslations();
  const [isUpdating, setIsUpdating] = useState(false);
  const { data, update } = useSharedObject();

  if (!data || !subscription || isUpdating) {
    return <LoadingSpinner theme="primary" />;
  }

  const renderWarnings = () => (
    <div>
      {!subscription.subscriptionActive && (
        <Alert
          title={t("Common:warning_no_subscription")}
          withDefaultIcon
          variant="warning"
          dataCy="subscription_inactive_alert"
        />
      )}

      {data.demoAccount && (
        <>
          <Spacer size={8} />

          <Alert
            title={t("Objects:info_demo_object")}
            withDefaultIcon
            variant="info"
          />
        </>
      )}

      {(!subscription.subscriptionActive || data.demoAccount) && (
        <Spacer size={32} />
      )}
    </div>
  );

  return (
    <div style={{ width: "784px" }}>
      {renderWarnings()}

      <h1>{t("Common:labels_subscription")}</h1>
      <Spacer size={8} />
      <p className={styles.additionalInfo}>
        {t("Common:description_subscription")}
      </p>

      <Spacer size={32} />

      <ObjectSubscriptionDetails
        subscriptionData={subscription}
        objectId={objectId}
        fetchObjectSubscription={fetchObjectSubscription}
        subscriptionUpdateInProgress={setIsUpdating}
        objectData={{ data: { ...data }, update }}
      />
    </div>
  );
};

export default observer(ObjectSubscription);
