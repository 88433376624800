import { useHistory } from "react-router-dom";
import { CSSProperties } from "react";
import Button from "../Button/Button";

export interface ClearFiltersProps {
  text: string;
  filterToWatch?: any;
  filtersToWatch?: any[];
  onClearClick: () => void;
  customStyles?: CSSProperties;
  propertiesToExclude?: any;
}

export const ClearFilter = ({
  text,
  onClearClick,
  filterToWatch,
  filtersToWatch,
  customStyles,
  propertiesToExclude,
}: ClearFiltersProps) => {
  const history = useHistory();

  const showClearButton = () => {
    if (filterToWatch) {
      return Object.values(filterToWatch).find((el) => !!el);
    }
    if (filtersToWatch) {
      let showBtn = false;
      filtersToWatch.forEach((filter) => {
        const updatedFilter = { ...filter };
        if (propertiesToExclude) {
          const keysToExclude = Object.keys(propertiesToExclude);
          keysToExclude.forEach((key) => {
            if (
              updatedFilter[key] &&
              updatedFilter[key] === propertiesToExclude[key]
            ) {
              delete updatedFilter[key];
            }
          });
        }
        if (Object.values(updatedFilter).find((el) => !!el)) {
          showBtn = true;
        }
      });
      return showBtn;
    }
    const searchParams = history.location.search;
    // exclude page and sorting params
    return !!searchParams
      .split("&")
      .find(
        (el) =>
          !el.includes("page") &&
          !el.includes("sortOrder") &&
          !el.includes("sortField")
      );
  };

  return (
    <>
      {showClearButton() && (
        <Button
          variant="destructive"
          type="button"
          onClick={onClearClick}
          text={text}
          customStyles={{ height: "40px", ...customStyles }}
          dataCy="clear_all_filters_btn"
        />
      )}
    </>
  );
};
