import { createContext, RefObject, useCallback, useState } from "react";
import { UnsavedChanges } from "./UnsavedChanges";

interface FormState {
  isDirty: boolean;
  isSubmitting: boolean;
  reset: () => void;
}

export const SaveObjectContext = createContext<{
  setFormRef: (ref?: RefObject<HTMLFormElement>) => void;
  setFormState: (formState?: FormState) => void;
  canSave: boolean;
  isDirty: boolean;
  isSaving: boolean;
  onSave: () => void;
  onReset: () => void;
}>({
  setFormRef: () => {},
  setFormState: () => {},
  canSave: false,
  isDirty: false,
  isSaving: false,
  onSave: () => {},
  onReset: () => {},
});

export const SaveObjectProvider = ({
  children,
  checkChanges = true,
}: {
  children: React.ReactNode;
  checkChanges?: boolean;
}) => {
  const [formRef, setFormRef] = useState<RefObject<HTMLFormElement>>();
  const [formState, setFormState] = useState<FormState>();

  const canSave = !!formRef?.current && !!formState;
  const isDirty = formState?.isDirty ?? false;
  const isSaving = formState?.isSubmitting ?? false;
  const onSave = useCallback(
    () =>
      formRef?.current?.dispatchEvent(
        new Event("submit", { cancelable: true, bubbles: true })
      ),
    [formRef]
  );
  const onReset = useCallback(formState?.reset ?? (() => {}), [
    formState?.reset,
  ]);

  return (
    <SaveObjectContext.Provider
      value={{
        setFormRef,
        setFormState,
        canSave,
        isDirty,
        isSaving,
        onSave,
        onReset,
      }}
    >
      {children}
      <UnsavedChanges checkChanges={checkChanges} />
    </SaveObjectContext.Provider>
  );
};
