/* eslint-disable react/jsx-props-no-spreading */
import _ from "lodash";
import { ReactNode } from "react";
import { FormProvider } from "react-hook-form";
import {
  ResponseCenterDetails,
  ResponseCenterDetailsParams,
} from "../../../../core/api/responsecenters/types";
import useTranslations from "../../../../core/i18n/useTranslations";
import {
  useObjectForm,
  useObjectFormRef,
} from "../../../../core/SaveObjectContext/hooks";
import { notify } from "../../../../ui-lib/components/Alerts/Toast";
import useSharedAlarmReception from "../SharedAlarmReceptionContext/useSharedAlarmReception";
import { notifyApiErrors } from "../../../../core/helpers/helpers";

export const AlarmHandlingForm = ({ children }: { children: ReactNode }) => {
  const t = useTranslations();
  const { data = {}, update } = useSharedAlarmReception();
  const formRef = useObjectFormRef();
  const methods = useObjectForm<ResponseCenterDetails>({
    defaultValues: { ...data },
    shouldUnregister: false,
  });

  const onSubmit = async (values: ResponseCenterDetailsParams) => {
    try {
      await update(values);

      notify({
        message: t("AlarmReceptions:edited_success"),
      });
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    }
  };

  return (
    <FormProvider {...methods}>
      <form
        ref={formRef}
        onSubmit={methods.handleSubmit(async (values) => {
          const parsed = _.omit(values, "CustomerId");
          await onSubmit(parsed);
          methods.reset(values);
        })}
        style={{ width: "100%" }}
      >
        {children}
      </form>
    </FormProvider>
  );
};
