import { AxiosInstance } from "axios";
import API_HOST from "./apiHost";

export const useObjectThumbnail = (
  objectId: string,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.get<string>(
    `${API_HOST}api/v2/users/${objectId}/thumbnail`
  );
