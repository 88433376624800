import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Controller, useForm } from "react-hook-form";
import { AxiosInstance, AxiosResponse } from "axios";
import { useTranslation } from "react-i18next";
import { Row, Spacer } from "../../../../../components/Layout/Layout";
import {
  INewReceptionSubscription,
  IReceptionSubscription,
} from "../../../../../core/api/responsecenters/types";
import { notifyError } from "../../../../../components/Errors/ErrorAlert";
import {
  addARCSubscriptionOrganisation,
  addARCSubscriptionSeller,
  updateARCSubscriptionName,
} from "../../../../../core/api/responsecenters/responsecenters";
import { notify } from "../../../../../ui-lib/components/Alerts/Toast";
import { IItemProp } from "../../../../../ui-lib/components/Dropdown/DropdownItem";

import TextInput from "../../../../../ui-lib/components/Inputs/TextInput";
import Button from "../../../../../ui-lib/components/Button/Button";
import Modal from "../../../../../ui-lib/components/Modal/Modal";
import useUser from "../../../../../core/user/useUser";
import Dropdown from "../../../../../ui-lib/components/Dropdown/Dropdown";
import Tree, {
  ITreeData,
} from "../../../../../ui-lib/components/Hierarchy/Tree";

export declare type EditSubscriptionActions = {
  open: (
    arcId: number,
    type: "description" | "subscriptionPeriod" | "sellerId" | "orgId"
  ) => void;
};
export interface EditSubscriptionProps {
  subscription?: IReceptionSubscription;
  sellers?: any;
  isEditSubscriptionOpen: boolean;
  organisations?: ITreeData[];
  setIsEditSubscriptionOpen: (val: boolean) => void;
  onSibmit: (id: number) => void;
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SubscriptionEditModal = forwardRef<
  EditSubscriptionActions,
  EditSubscriptionProps
>(
  (
    {
      subscription,
      sellers,
      isEditSubscriptionOpen,
      organisations,
      setIsEditSubscriptionOpen,
      onSibmit,
      setIsDirty,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const { authenticatedRequest } = useUser();
    const arcId = useRef<number | undefined>(undefined);
    const currentType = useRef<
      "description" | "subscriptionPeriod" | "sellerId" | "orgId"
    >("description");

    const [isLoading, setIsLoading] = useState(false);
    const [dropdownItems, setDropdownItems] = useState<IItemProp[]>([]);

    const {
      control,
      formState: { errors },
      handleSubmit,
      setValue,
      trigger,
    } = useForm<INewReceptionSubscription>();

    useImperativeHandle<EditSubscriptionActions, EditSubscriptionActions>(
      ref,
      () => {
        const actions: EditSubscriptionActions = {
          open: function open(
            id: number,
            type: "description" | "subscriptionPeriod" | "sellerId" | "orgId"
          ) {
            arcId.current = id;
            currentType.current = type;
            setIsEditSubscriptionOpen(true);
          },
        };
        return actions;
      }
    );

    useEffect(() => {
      if (currentType.current === "description") {
        setValue("description", subscription?.description || "");
      }
      if (currentType.current === "sellerId") {
        setDropdownItems(sellers);
      }
    }, [isEditSubscriptionOpen]);

    const getUpdateData = (type: string): any => {
      const updateFunctions: { [key: string]: any } = {
        description: {
          title: t("AlarmReceptions:edit_subscription_name"),
          apiCall: updateARCSubscriptionName,
        },
        sellerId: {
          title: t("AlarmReceptions:edit_subscription_seller"),
          apiCall: addARCSubscriptionSeller,
        },
        orgId: {
          title: t("AlarmReceptions:edit_subscription_org"),
          apiCall: addARCSubscriptionOrganisation,
        },
      };

      if (!updateFunctions[type]) {
        return null;
      }

      return updateFunctions[type];
    };

    const submitForm = async (
      apiFunc: (
        authenticatedRequest: AxiosInstance,
        arcId: number,
        subscriptionId: number,
        data: INewReceptionSubscription
      ) => Promise<AxiosResponse<any>>,
      value: INewReceptionSubscription
    ) => {
      setIsLoading(true);
      let result;
      try {
        result = await apiFunc(
          authenticatedRequest,
          arcId.current!,
          subscription!.subscriptionGroupId,
          value
        );
        onSibmit(result?.data);
        notify({ message: t("AlarmReceptions:subscriptions_updated") });
      } catch (error: any) {
        notifyError({ error, t });
      } finally {
        setIsLoading(false);
        setIsDirty(true);
        setIsEditSubscriptionOpen(false);
      }
    };

    return (
      <Modal
        hideFooter
        closeOnDocumentClick={false}
        modalTitle={getUpdateData(currentType.current).title}
        isOpen={isEditSubscriptionOpen}
        onClose={() => setIsEditSubscriptionOpen(false)}
      >
        <Spacer size={16} />
        <form
          onSubmit={handleSubmit(async (value) => {
            submitForm(getUpdateData(currentType.current).apiCall, value);
          })}
        >
          {currentType.current === "description" ? (
            <Row type="space">
              <Controller
                name={currentType.current}
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextInput
                    required
                    label={t("Common:name")}
                    placeholder={t(
                      "AlarmReceptions:subscription_name_placeholder"
                    )}
                    value={field.value}
                    onChange={field.onChange}
                    validationError={
                      errors?.description && t("Errors:input_field_required")
                    }
                  />
                )}
              />
            </Row>
          ) : (
            <Row type="left" style={{ maxWidth: "376px" }}>
              <Controller
                name={currentType.current}
                control={control}
                rules={{ required: true }}
                render={({ field }) =>
                  currentType.current === "orgId" ? (
                    <Tree
                      required
                      showClearOption={false}
                      label={t("Common:organisation")}
                      wrapperStyle={{ width: "100%" }}
                      placeholder={t("Common:select_organisation")}
                      items={organisations || []}
                      onSelectItem={(data?: ITreeData) => {
                        if (data?.key) {
                          setValue(field.name, data?.key as number | undefined);
                        }
                        trigger(field.name);
                      }}
                      validationError={
                        errors.orgId && t("Errors:input_field_required")
                      }
                    />
                  ) : (
                    <Dropdown
                      required
                      title={
                        currentType.current === "sellerId"
                          ? t("Common:seller")
                          : `${t("Common:period")} (${t("Common:months")})`
                      }
                      placeholder={
                        currentType.current === "sellerId"
                          ? t("Administrator:seller_placeholder")
                          : t("Common:select_month_period")
                      }
                      selectedItem={dropdownItems.find((i) => i.isSelected)}
                      items={dropdownItems}
                      onSelectItem={(i) => {
                        setDropdownItems(
                          dropdownItems.map((item) => ({
                            ...item,
                            isSelected: item.id === i.id,
                          }))
                        );
                        setValue(field.name, +i.id);
                        trigger(field.name);
                      }}
                      validationError={
                        errors[currentType.current] &&
                        t("Errors:input_field_required")
                      }
                    />
                  )
                }
              />
            </Row>
          )}

          <Spacer size={32} />

          <Row type="left">
            <Button
              text={t("Common:save_changes")}
              type="submit"
              variant="primary"
              loading={isLoading}
              disabled={isLoading}
            />
            <Spacer size={16} />
            <Button
              variant="secondary"
              text={t("Common:cancel")}
              disabled={isLoading}
              onClick={() => setIsEditSubscriptionOpen(false)}
            />
          </Row>
        </form>
      </Modal>
    );
  }
);

SubscriptionEditModal.defaultProps = {
  subscription: undefined,
  sellers: [],
  organisations: [],
};
