import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";

import { INewReceptionSubscription } from "../../../../core/api/responsecenters/types";
import { Row, Spacer } from "../../../../components/Layout/Layout";
import { createARCSubscription } from "../../../../core/api/responsecenters/responsecenters";

import Button from "../../../../ui-lib/components/Button/Button";
import TextInput from "../../../../ui-lib/components/Inputs/TextInput";
import useUser from "../../../../core/user/useUser";
import { notifyError } from "../../../../components/Errors/ErrorAlert";
import { notify } from "../../../../ui-lib/components/Alerts/Toast";

const CreateSubscriptionModal = ({
  arcId,
  onClose,
  onSibmit,
}: {
  arcId: number;
  onClose: () => void;
  onSibmit: (id: number) => void;
}) => {
  const { t } = useTranslation();
  const { authenticatedRequest } = useUser();

  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<INewReceptionSubscription>();

  const onCreateArcSubscription = async (values: INewReceptionSubscription) => {
    setIsLoading(true);
    try {
      const response = await createARCSubscription(
        authenticatedRequest,
        arcId,
        values
      );
      notify({ message: t("AlarmReceptions:subscription_created") });
      onSibmit(response.data);
    } catch (error: any) {
      notifyError({ error, t });
    } finally {
      setIsLoading(false);
      onClose();
    }
  };

  return (
    <>
      <Spacer size={32} />
      <form
        onSubmit={handleSubmit(async (values) =>
          onCreateArcSubscription(values)
        )}
      >
        <Row type="space">
          <Controller
            name="description"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextInput
                required
                label={t("Common:name")}
                placeholder={t("AlarmReceptions:subscription_name_placeholder")}
                value={field.value}
                onChange={field.onChange}
                validationError={
                  errors?.description && t("Errors:input_organization_name")
                }
              />
            )}
          />
        </Row>

        <Spacer size={32} />

        <Row type="left">
          <Button
            text={t("AlarmReceptions:create_subscription")}
            type="submit"
            variant="primary"
            loading={isLoading}
            disabled={isLoading}
          />
          <Spacer size={16} />
          <Button
            variant="secondary"
            text={t("Common:cancel")}
            onClick={onClose}
            disabled={isLoading}
          />
        </Row>
      </form>
    </>
  );
};

export default CreateSubscriptionModal;
