import { Column, Spacer } from "../../../components/Layout/Layout";
import { MenuAlternativesController } from "../../../components/MenuAlternatives/MenuAlternativesController";
import useTranslations from "../../../core/i18n/useTranslations";

const ResellerMenuAlternatives = ({ id }: { id: number }) => {
  const t = useTranslations();

  return (
    <Column style={{ width: "100%" }} type="top" align="start">
      <h1>{t("Menu:Menu_Alternatives")}</h1>
      <Spacer size={8} />
      <p style={{ color: "var(--Grey-600)" }}>
        {t("Administrator:Menu_Alternatives_Description")}
      </p>

      <Spacer size={32} />

      <MenuAlternativesController
        ownerId={id}
        alternativesType="sellerId"
        accordionTitle={t("Organizations:alternative_title")}
        accordionDescription={t(
          "Organizations:settings_menu_alternatives_description"
        )}
        renderInAccordion
      />

      <Spacer size={32} />

      <MenuAlternativesController
        ownerId={id}
        alternativesType="sellerId"
        accordionTitle={t("Organizations:app_alternative_title")}
        accordionDescription={t(
          "Organizations:settings_app_menu_alternatives_description"
        )}
        renderInAccordion
        isMobile
      />
    </Column>
  );
};

export default ResellerMenuAlternatives;
