import { useRef, useState } from "react";
import {
  AdministratorDetails,
  IUserSuggest,
} from "../../../../core/api/administrators/types";
import { Row, Spacer } from "../../../../components/Layout/Layout";
import Table from "../../../../ui-lib/components/Tables/Table";
import TableCell from "../../../../ui-lib/components/Tables/TableCell";
import useTranslations from "../../../../core/i18n/useTranslations";
import {
  NewObjectData,
  ObjectDetailsModel,
} from "../../../../core/api/objects/types";
import { deleteObject } from "../../../../core/api/objects/objects";
import { useOrganizationsTree } from "../../../../core/api/organizations/organizations";
import {
  AddObject,
  ConfigurationAddObjectActions,
} from "../../../Objects/AddObject";
import BulkDeleteModal from "../../../../components/BulkDeleteModal";
import { Transmitter } from "../../../../core/api/transmitters/types";
import { ResponseCenterDetails } from "../../../../core/api/responsecenters/types";
import Divider from "../../../../ui-lib/components/Divider/Divider";
import Button from "../../../../ui-lib/components/Button/Button";

export const RelatedObject = ({
  adminData,
  objectData,
  suggestData,
  arcData,
  transmitterData,
  actions,
  removeRelatedObject,
  fetchUserSuggest,
}: {
  adminData: AdministratorDetails;
  objectData?: ObjectDetailsModel;
  suggestData?: IUserSuggest;
  arcData?: ResponseCenterDetails;
  transmitterData?: Transmitter[];
  actions?: string[];
  removeRelatedObject: () => Promise<void>;
  fetchUserSuggest: () => Promise<void>;
}) => {
  const [deletionModalOpen, setDeletionModalOpen] = useState<boolean>(false);
  const { data: organizations, isLoading } = useOrganizationsTree();
  const t = useTranslations();

  const addObjectModalRef = useRef<ConfigurationAddObjectActions>(null);

  return (
    <>
      <Spacer size={32} />
      <Divider />
      <Spacer size={32} />
      <BulkDeleteModal
        isOpen={deletionModalOpen}
        onClose={() => {
          setDeletionModalOpen(false);
        }}
        onDelete={() => {
          setDeletionModalOpen(false);
          removeRelatedObject();
        }}
        ids={objectData?.objectId ? [objectData.objectId] : []}
        labels={{
          single: t("Objects:type"),
          multi: t("Objects:type_multi"),
        }}
        apiFunc={deleteObject}
      />
      <Row type="space" align="center">
        <h2>{t("Administrator:related_object")}</h2>
        {suggestData?.canCreateUser &&
          actions?.find((key) => key === "M:Users/Add") && (
            <>
              <Button
                variant="secondary"
                text={t("Objects:addobject")}
                image="cube"
                disabled={isLoading}
                loading={isLoading}
                onClick={() => {
                  const defaultValues = {
                    responseCenterId: adminData.responseCenterId,
                    adminId: adminData.id,
                    productCategoryId: suggestData?.transmitterProductType,
                    identifier: suggestData?.transmitterIdentifier,
                    telephoneNumber: suggestData?.transmitterIdentifier,
                    organizationId: arcData?.organizationId,
                    name: suggestData?.name,
                  };
                  addObjectModalRef.current?.open(
                    transmitterData?.[0],
                    defaultValues as NewObjectData
                  );
                }}
              />
              <AddObject
                relatedObject
                ref={addObjectModalRef}
                organisations={organizations}
                onSubmit={() => {
                  fetchUserSuggest();
                }}
              />
            </>
          )}
      </Row>
      <Spacer size={16} />
      <Table<ObjectDetailsModel>
        columns={[
          {
            header: t("Common:name"),
            fieldTemplate: (rowData) => (
              <TableCell
                className="tableCell-bold"
                value={rowData.name}
                linkTo={`/adminportal/objects/${rowData.id}`}
              />
            ),
          },
          {
            header: t("Common:organisation"),
            fieldTemplate: (rowData) => (
              <TableCell
                leftIcon="building"
                iconColor="grey"
                className="tableCell-bold"
                value={rowData.organizationName}
              />
            ),
          },
          {
            header: t("Table:TelephoneNumber"),
            fieldTemplate: (rowData) => (
              <TableCell
                leftIcon="phone"
                iconColor="grey"
                value={rowData.identifier}
              />
            ),
          },
        ]}
        items={objectData ? [objectData] : undefined}
        rowActions={[
          {
            icon: "x",
            text: t("Objects:accessories_table_remove"),
            onClick: () => setDeletionModalOpen(true),
            iconVariant: "secondary",
          },
        ]}
        rowActionsColWidth={45}
        rowActionsFixed
        hideEmptyMessage
        noRowsMessage={t("Administrator:no_related_object")}
        showRowHover
        withShowMore
      />
    </>
  );
};
