import classNames from "classnames";
import {
  CSSProperties,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { Icon } from "../Icon";
import { PermissionContext } from "../../../components/Administrator/PermissionList";

import styles from "./Accordion.module.css";

export interface AccordionProps {
  title: string;
  description: string;
  icon?: string;
  details?: string;
  bodyClassName?: string;
  containerStyle?: CSSProperties;
  children: ReactNode;
  accordionKey?: string;
}

export default function Accordion({
  title,
  description,
  icon,
  details,
  bodyClassName,
  containerStyle,
  children,
  accordionKey,
}: Readonly<AccordionProps>) {
  const accordionsContextData = useContext(PermissionContext);
  const [isOpen, setIsOpen] = useState(false);
  const [shown, setShown] = useState(false);

  function onOpen() {
    setIsOpen(!isOpen);
    setTimeout(() => {
      setShown(!shown);
    }, 50);
  }

  // Toggle open all accordions
  useEffect(() => {
    if (accordionsContextData) {
      setIsOpen(accordionsContextData.showAll);
      setShown(accordionsContextData.showAll);
    }
  }, [accordionsContextData?.showAll]);

  // Get open state for each accordion and set switcher state
  useEffect(() => {
    if (accordionKey) {
      const newAccordionsState = accordionsContextData?.accordions || {};
      newAccordionsState[accordionKey] = isOpen;
      if (Object.values(newAccordionsState).every((el) => el)) {
        accordionsContextData?.setShowAll(true);
      }
      if (Object.values(newAccordionsState).every((el) => !el)) {
        accordionsContextData?.setShowAll(false);
      }
    }
  }, [isOpen]);

  const bodyClassNames = classNames({
    [styles.body]: true,
    [styles.visibleBody]: shown,
    ...(bodyClassName ? { [bodyClassName]: true } : {}),
  });

  return (
    <div className={styles.container} style={containerStyle}>
      <button
        className={styles.header}
        onClick={(e) => {
          e.preventDefault();
          onOpen();
        }}
        aria-label="Accordion toggler"
        style={{ borderRadius: isOpen ? "4px 4px 0 0" : "4px" }}
      >
        <div className={styles.headerLeftSide}>
          {icon && (
            <div className={styles.iconWrapper}>
              <Icon name={icon} color="Primary-700" />
            </div>
          )}
          <div className={styles.mainGroupInfo}>
            <div className={styles.title}>{title}</div>
            <div className={styles.description}>{description}</div>
          </div>
        </div>
        <div className={styles.headerRightSide}>
          {details && <div className={styles.details}>{details}</div>}
          <div>
            {isOpen ? <Icon name="chevron-up" /> : <Icon name="chevron-down" />}
          </div>
        </div>
      </button>
      {isOpen && (
        <div className={bodyClassNames}>
          <div className={styles.innerBody}>{children}</div>
        </div>
      )}
    </div>
  );
}
