import { useCallback, useMemo } from "react";
import { useQueryState } from "./useQueryState";

type AlarmCodesStateFilter = { alarmCodes?: string };

const toAlarmCodesFilter = (
  state: AlarmCodesStateFilter
): AlarmCodesStateFilter => ({
  alarmCodes: state.alarmCodes || undefined,
});

const toAlarmCodesState = (
  filter: AlarmCodesStateFilter
): AlarmCodesStateFilter => ({
  alarmCodes: filter.alarmCodes,
});

const DEFAULT: AlarmCodesStateFilter = {
  alarmCodes: undefined,
};

export const useAlarmCodesFilter = (searchObj?: any) => {
  const stateToUse = !searchObj
    ? DEFAULT
    : {
        alarmCodes: searchObj.alarmCodes,
      };

  const [state, setState] = useQueryState<AlarmCodesStateFilter>(stateToUse);

  const alarmCodesFilter = useMemo(() => toAlarmCodesFilter(state), [state]);

  const setAlarmCodesFilter = useCallback(
    (args: AlarmCodesStateFilter) => setState(toAlarmCodesState(args)),
    [setState]
  );

  const resetAlarmCodesFilter = useCallback(
    () => setState(DEFAULT),
    [setState]
  );

  return { alarmCodesFilter, setAlarmCodesFilter, resetAlarmCodesFilter };
};
