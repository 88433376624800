import { Rating } from "primereact/rating";
import "./RatingContainer.module.css";

export const RatingContainer = ({
  onChange,
  disabled,
  value,
  cancel = false,
  stars = 5,
}: {
  onChange: (rating: any) => void;
  disabled?: boolean;
  value: number;
  cancel?: boolean;
  stars?: number;
}) => (
  <Rating
    onChange={onChange}
    disabled={disabled}
    value={value}
    cancel={cancel}
    stars={stars}
  />
);
