import { useCallback, useMemo } from "react";
import { useQueryState } from "./useQueryState";

export type AutoDeactivatedState = { hideautodeactivated?: string };
export type AutoDeactivatedFilter = { hideautodeactivated?: boolean };

const toAutoDeactivatedFilter = (
  state: AutoDeactivatedState
): AutoDeactivatedFilter => ({
  hideautodeactivated: state.hideautodeactivated ? true : undefined,
});

const toAutoDeactivatedState = (
  filter: AutoDeactivatedFilter
): AutoDeactivatedState => ({
  hideautodeactivated: filter.hideautodeactivated?.toString(),
});

const DEFAULT: AutoDeactivatedState = {
  hideautodeactivated: undefined,
};

export const useAutoDeactivatedFilter = (searchObj?: any) => {
  const stateToUse = !searchObj
    ? DEFAULT
    : {
        hideautodeactivated: searchObj.hideautodeactivated,
      };
  const [state, setState] = useQueryState<AutoDeactivatedState>(stateToUse);

  const autoDeactivatedFilter = useMemo(
    () => toAutoDeactivatedFilter(state),
    [state]
  );

  const setAutoDeactivatedFilter = useCallback(
    (args: AutoDeactivatedFilter) => setState(toAutoDeactivatedState(args)),
    [setState]
  );

  const resetAutoDeactivatedFilter = useCallback(
    () => setState(DEFAULT),
    [setState]
  );

  return {
    autoDeactivatedFilter,
    setAutoDeactivatedFilter,
    resetAutoDeactivatedFilter,
  };
};
