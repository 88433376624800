import React, { ReactNode } from "react";
import { Dialog } from "primereact/dialog";
import { Row, Spacer } from "../../../components/Layout/Layout";
import Icon from "../Icon";
import Button from "../Button/Button";

import styles from "./PrimeModal.module.css";

interface FooterButton {
  text: string;
  onClick: () => Promise<void> | void;
  variant?: "primary" | "destructive" | "secondary";
  disabled?: boolean;
  hidden?: boolean;
}

const PrimeModal = ({
  children,
  isOpen,
  onClose,
  withHeader,
  header,
  withFooter,
  style,
  contentStyle,
  modal = true,
  zIndex,
  submitBtn,
  cancelBtn,
  loading,
  customFooter,
  className,
}: {
  children: ReactNode;
  isOpen: boolean;
  onClose(): void;
  withHeader?: boolean;
  header?: string | ReactNode;
  style?: React.CSSProperties;
  contentStyle?: React.CSSProperties;
  modal?: boolean;
  withFooter?: boolean;
  zIndex?: number;
  submitBtn?: FooterButton;
  cancelBtn?: FooterButton;
  loading?: boolean;
  customFooter?: ReactNode;
  className?: string;
}) => {
  const renderFooter = () => {
    if (customFooter) return customFooter;
    if (withFooter) {
      return (
        <Row type="left">
          {!submitBtn?.hidden && (
            <Button
              text={submitBtn?.text}
              variant={submitBtn?.variant ?? "primary"}
              onClick={submitBtn?.onClick}
              disabled={loading || submitBtn?.disabled}
              loading={loading}
            />
          )}
          {cancelBtn && (
            <>
              {!submitBtn?.hidden && <Spacer size={8} />}
              <Button
                text={cancelBtn?.text}
                variant={cancelBtn?.variant ?? "secondary"}
                onClick={cancelBtn?.onClick}
                disabled={loading}
              />
            </>
          )}
        </Row>
      );
    }
  };

  return (
    <Dialog
      closeOnEscape={false}
      draggable={false}
      visible={isOpen}
      onHide={onClose}
      showHeader={withHeader}
      header={header}
      modal={modal}
      contentStyle={{ padding: 0 }}
      style={style}
      resizable={false}
      footer={renderFooter()}
      baseZIndex={zIndex}
      closeIcon={<Icon name="x" />}
      className={className}
    >
      <div className={styles.primeWrapper} style={contentStyle}>
        {children}
      </div>
    </Dialog>
  );
};

export default PrimeModal;
