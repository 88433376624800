import { Column, Row, Spacer } from "../../../../../components/Layout/Layout";
import useTranslations from "../../../../../core/i18n/useTranslations";
import { CheckboxControl } from "../../AlarmHandling/Controls/CheckboxControl";

export const CreateNewSettings = () => {
  const t = useTranslations();

  return (
    <>
      <span className="strong">
        {t("AlarmReceptions:alarm_handling_alarm_code_input_create_new_if")}
      </span>
      <Spacer size={8} />
      <Row>
        <CheckboxControl
          name="createNewIfHigherPriorityAlarm"
          label={t(
            "AlarmReceptions:alarm_handling_alarm_code_input_create_new_if_0"
          )}
        />
        <Spacer size={32} />
        <CheckboxControl
          name="createNewIfHigherPriorityAcceptedAlarm"
          label={t(
            "AlarmReceptions:alarm_handling_alarm_code_input_create_new_if_1"
          )}
        />
      </Row>
      <Spacer size={8} />
      <Row>
        <CheckboxControl
          name="createNewIfLowerPriorityAlarm"
          label={t(
            "AlarmReceptions:alarm_handling_alarm_code_input_create_new_if_2"
          )}
        />
        <Spacer size={32} />
        <CheckboxControl
          name="createNewIfLowerPriorityAcceptedAlarm"
          label={t(
            "AlarmReceptions:alarm_handling_alarm_code_input_create_new_if_3"
          )}
        />
      </Row>
      <Spacer size={8} />
      <Row>
        <CheckboxControl
          name="createNewIfSamePriorityAlarm"
          label={t(
            "AlarmReceptions:alarm_handling_alarm_code_input_create_new_if_4"
          )}
        />
        <Spacer size={32} />
        <CheckboxControl
          name="createNewIfSamePriorityAcceptedAlarm"
          label={t(
            "AlarmReceptions:alarm_handling_alarm_code_input_create_new_if_5"
          )}
        />
      </Row>
      <Spacer size={8} />
      <Row>
        <CheckboxControl
          name="createNewIfHigherPriorityPresenceAlarm"
          label={t(
            "AlarmReceptions:alarm_handling_alarm_code_input_create_new_if_6"
          )}
        />
        <Spacer size={32} />
        <Column style={{ width: "100%" }} />
      </Row>
      <Spacer size={8} />
      <Row>
        <CheckboxControl
          name="createNewIfLowerPriorityPresenceAlarm"
          label={t(
            "AlarmReceptions:alarm_handling_alarm_code_input_create_new_if_7"
          )}
        />
        <Spacer size={32} />
        <Column style={{ width: "100%" }} />
      </Row>
      <Spacer size={8} />
      <Row>
        <CheckboxControl
          name="createNewIfSamePriorityPresenceAlarm"
          label={t(
            "AlarmReceptions:alarm_handling_alarm_code_input_create_new_if_8"
          )}
        />
        <Spacer size={32} />
        <Column style={{ width: "100%" }} />
      </Row>
    </>
  );
};
