import { Row } from "../../../../components/Layout/Layout";
import { AdministratorResponse } from "../../../../core/api/administrators/types";
import useTranslations from "../../../../core/i18n/useTranslations";
import Checkbox from "../../../../ui-lib/components/Checkbox/Checkbox";
import Table from "../../../../ui-lib/components/Tables/Table";
import TableCell from "../../../../ui-lib/components/Tables/TableCell";

export const SelectAdminsTable = ({
  admins,
  added,
  isLoading,
  upForAdd,
  setUpForAdd,
  onPageSettingsChange,
  pageSettings,
}: {
  admins: AdministratorResponse[];
  added: number[];
  isLoading: boolean;
  upForAdd: number[];
  setUpForAdd: (adminId: number) => void;
  pageSettings: {
    page: number;
    pageSize: number;
  };
  onPageSettingsChange: (args: { page: number; pageSize: number }) => void;
}) => {
  const t = useTranslations();

  const showMore = admins.length === pageSettings.pageSize * pageSettings.page;

  return (
    <Table<AdministratorResponse>
      columns={[
        {
          fieldTemplate: (rowData) => <TableCell value={rowData.username} />,
        },
        {
          fieldTemplate: (rowData) => <TableCell value={rowData.name} />,
        },
        {
          fieldTemplate: (rowData) => {
            const isAdded = !!added.find((id) => rowData.id === id);
            const isChecked =
              isAdded || !!upForAdd.find((elToAdd) => elToAdd === rowData.id);
            const label = t(`Objects:labels_admins_${rowData.type}`);
            const icon =
              rowData.type === "respondent" ? "phone-incoming" : "user";

            return (
              <Row>
                <TableCell
                  leftIcon={icon}
                  iconColor="Grey-300"
                  iconSize={16}
                  value={label}
                />

                <Checkbox
                  checked={isChecked}
                  disabled={isAdded}
                  onChange={() => setUpForAdd(rowData.id)}
                />
              </Row>
            );
          },
        },
      ]}
      isLoading={isLoading}
      items={admins}
      withLazyLoading
      withShowMore
      paginatedItems={{
        items: admins,
        pagination: {
          offset: 0,
          limit: admins.length,
          total: admins.length + (showMore ? 1 : 0), // To control show more button visibility
        },
      }}
      onShowMoreClick={() => {
        onPageSettingsChange({
          page: pageSettings.page + 1,
          pageSize: pageSettings.pageSize,
        });
      }}
      hideHeader
      showRowHover
      hideEmptyMessage
      noRowsMessage={t("Table:no_records_found")}
    />
  );
};
