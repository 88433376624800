export const TAB_KEY = 9;

export const ARROW_UP_KEY = 38;

export const ARROW_DOWN_KEY = 40;

export const ENTER_KEY = 13;

export const SPACE_KEY = 32;

export const SHIFT_KEY = 16;

export const ESCAPE_KEY = 27;
