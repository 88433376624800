import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Spacer } from "../../components/Layout/Layout";

import Modal from "../../ui-lib/components/Modal/Modal";
import TextInput from "../../ui-lib/components/Inputs/TextInput";
import useUser from "../../core/user/useUser";
import { createReseller } from "../../core/api/sellers/sellers";
import { INewReseller } from "../../core/api/sellers/types";
import { notifyApiErrors } from "../../core/helpers/helpers";
import { notify } from "../../ui-lib/components/Alerts/Toast";

const AddResellerModal = ({
  isAddSellerModalOpen,
  setIsAddSellerModalOpen,
  getAllSellers,
  submitHandler,
}: {
  isAddSellerModalOpen: boolean;
  setIsAddSellerModalOpen: (value: React.SetStateAction<boolean>) => void;
  getAllSellers: () => Promise<void>;
  submitHandler: (id: number) => void;
}) => {
  const { t } = useTranslation();
  const { authenticatedRequest } = useUser();

  const {
    control,
    formState: { errors },
    handleSubmit,
    clearErrors,
    reset,
  } = useForm();

  const onClose = () => {
    clearErrors();
    reset();
    setIsAddSellerModalOpen(false);
  };

  const onSubmit = async (values: INewReseller) => {
    try {
      const result = await createReseller(authenticatedRequest, values);
      getAllSellers();
      notify({ message: "Administrator:reseller_created_successfully" });
      submitHandler(result.data.id);
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    }
  };

  return (
    <Modal
      modalTitle={t("Common:add_seller")}
      isOpen={isAddSellerModalOpen}
      onClose={onClose}
      approveBtnText={t("Common:create_seller")}
      cancelBtnText={t("Common:cancel")}
      onApproveBtnClick={() =>
        handleSubmit((values) => onSubmit(values as INewReseller))()
      }
      onCancelBtnClick={onClose}
    >
      <p>{t("Administrator:add_reseller_description")}</p>
      <Spacer size={16} />
      <form>
        <Controller
          name="name"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextInput
              required
              label={t("Common:name")}
              placeholder="Enter reseller name"
              value={field.value}
              onChange={field.onChange}
              validationError={errors?.name && t("Errors:input_field_required")}
            />
          )}
        />
      </form>
    </Modal>
  );
};

export default AddResellerModal;
