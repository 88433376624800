import { Controller } from "react-hook-form";
import { OperatorAccessType } from "../../core/api/administrators/types";
import Dropdown from "../../ui-lib/components/Dropdown/Dropdown";
import useUser from "../../core/user/useUser";

export const OperatorAccessDropdown = ({
  control,
  setValue,
  t,
}: {
  control: any;
  setValue: any;
  t: any;
}) => {
  const { config } = useUser();
  return (
    <Controller
      control={control}
      name="operatorAccess"
      defaultValue={OperatorAccessType.WebCentral}
      render={({ field }) => {
        const dropdownItems = [
          {
            id: 0,
            name: t("Administrator:operator_access_no"),
            isSelected: field.value === 0,
            disabled: !config?.show.includes("ChangeOperatorAccessNoAccess"),
          },
          {
            id: 1,
            name: t("Administrator:operator_access_mobile"),
            isSelected: field.value === 1,
            disabled: !config?.show.includes(
              "ChangeOperatorAccessMobileResponse"
            ),
          },
          {
            id: 2,
            name: t("Administrator:operator_access_central"),
            isSelected: field.value === 2,
            disabled: !config?.show.includes("ChangeOperatorAccessWebCentral"),
          },
          {
            id: 3,
            name: t("Administrator:operator_access_mobile_central"),
            isSelected: field.value === 3,
            disabled: !config?.show.includes(
              "ChangeOperatorAccessMobileResponseAndWebCentral"
            ),
          },
          {
            id: 4,
            name: t("Administrator:operator_access_integrations"),
            isSelected: field.value === 4,
            disabled: !config?.show.includes(
              "ChangeOperatorAccessIntegrations"
            ),
          },
        ];

        return (
          <Dropdown
            title={t("Administrator:operator_access")}
            width="100%"
            selectedItem={dropdownItems.find((i) => i.isSelected)}
            items={dropdownItems}
            disabled={!config?.show.includes("ChangeOperatorAccess")}
            onSelectItem={(item) => {
              setValue(field.name, item.id as number);
            }}
          />
        );
      }}
    />
  );
};
