import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { Row, Spacer } from "../../components/Layout/Layout";
import Button from "../../ui-lib/components/Button/Button";
import TextInput from "../../ui-lib/components/Inputs/TextInput";
import PasswordInput from "../../ui-lib/components/Inputs/PasswordInput";
import useTranslations from "../../core/i18n/useTranslations";
import useBranding from "../../core/branding/useBranding";
import Divider from "../../ui-lib/components/Divider/Divider";

export interface Step1FormData {
  username: string;
  password: string;
}

const ADLinks: { [key: string]: string } = {
  default: "http://login.skyresponse.com",
  skyresponse: "http://login.skyresponse.com",
  hepro: "https://login.skyresponse.com/?brand=hepro",
  careium: "http://login.skyresponse.com?brand=careium",
};

export const Step1 = ({
  onSubmit,
}: {
  onSubmit: (formData: Step1FormData) => void;
}) => {
  const {
    control,
    formState: { isSubmitting, errors },
    handleSubmit,
  } = useForm<Step1FormData>();

  const onFocusChange = () => {
    const token = JSON.parse(window.localStorage.getItem("auth-token") || "{}");
    if (token?.token) {
      window.location.replace("/adminportal");
    }
  };

  useEffect(() => {
    window.addEventListener("focus", onFocusChange);
    return () => window.removeEventListener("focus", onFocusChange);
  }, []);

  const t = useTranslations();
  const brandingData = useBranding();

  const getADLoginLink = () => {
    if (
      process.env.REACT_APP_ENVIRONMENT === "dev" ||
      process.env.REACT_APP_ENVIRONMENT === "stage"
    ) {
      return "http://login.dev.skyresponse.com/";
    }
    return ADLinks[brandingData.customerKey];
  };

  return (
    <>
      <h1>{t("Common:login")}</h1>
      <Spacer size={32} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="username"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextInput
              required
              label={t("Common:username")}
              value={field.value}
              onChange={field.onChange}
              validationError={
                errors.username && t("Errors:input_field_required")
              }
            />
          )}
        />
        <Spacer size={16} />
        <Controller
          name="password"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <PasswordInput
              required
              label={t("Common:login_input_password")}
              value={field.value}
              onChange={field.onChange}
              validationError={
                errors.password && t("Errors:input_field_required")
              }
            />
          )}
        />
        <Spacer size={40} />
        <Row type="space">
          <Button
            type="submit"
            text={t("Common:login")}
            loading={isSubmitting}
          />
          {brandingData.isForgotPasswordEnabled && (
            <Link
              to="/adminportal/forgotPassword"
              style={{ fontWeight: 600, fontSize: "14px" }}
            >
              {t("Common:forgot_password")}
            </Link>
          )}
        </Row>

        <Spacer size={32} />
        <Divider costomStyles={{ backgroundColor: "var(--Grey-100)" }} />
        <Spacer size={32} />

        <Link to={{ pathname: getADLoginLink() }} target="_blank">
          <Button
            image="login"
            text={t("Common:ad_login_link_text")}
            variant="secondary"
            iconVariant="border-link"
          />
        </Link>
      </form>
    </>
  );
};
