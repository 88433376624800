import axios from "axios";
import _ from "lodash";
import { toast } from "react-toastify";
import useTranslations from "../../core/i18n/useTranslations";
import Alert from "../../ui-lib/components/Alerts/Alert";

export const getErrorKey = (error: any): string => {
  if (axios.isAxiosError(error)) {
    let errorKey: string;

    const errorList = error.response?.data?.errors;

    if (error.response?.status === 500) {
      return `response_general_500_error`;
    }

    if (errorList && errorList.length > 0) {
      errorKey = errorList[0]?.name;
      if (errorKey) {
        return `response_${_.snakeCase(errorKey)}`;
      }
      errorKey = errorList[0]?.errorMessage;
      if (errorKey && errorKey.endsWith("Exception")) {
        errorKey = errorKey.replace("Exception", "");
      }

      if (errorKey) {
        return `response_${_.snakeCase(errorKey)}`;
      }
    }

    errorKey =
      (error.response?.data?.error as string) ||
      (error.response?.statusText as string);

    if (errorKey) {
      return `response_${_.snakeCase(errorKey)}`;
    }
  } else if (typeof error === "string" || error instanceof String) {
    return _.snakeCase(error.toString());
  }

  return "unknown";
};

export const notifyError = ({
  error,
  t,
}: {
  error: Error;
  t: (key: string[]) => string;
}) => {
  const errorKey = getErrorKey(error);
  const message = t([`Errors:${errorKey}`, "Errors:unknown"]);

  toast(<Alert title={message} variant="error" />);
};

const ErrorAlert = ({ errorKey }: { errorKey: string }) => {
  const t = useTranslations();

  return (
    <Alert title={t(`Errors:${errorKey}`)} variant="error" withDefaultIcon />
  );
};

export default ErrorAlert;
