import { observer } from "mobx-react-lite";
import { getErrorKey } from "../../../../components/Errors/ErrorAlert";
import { createResponseCenterDistribution } from "../../../../core/api/responsecenters/responsecenters";
import { ResponseCenterDistributionParams } from "../../../../core/api/responsecenters/types";
import useTranslations from "../../../../core/i18n/useTranslations";
import useUser from "../../../../core/user/useUser";
import { notify } from "../../../../ui-lib/components/Alerts/Toast";
import { DistributorForm } from "./DistributorForm";

const CreateDistributorModal = ({
  responseCenterId,
  onClose,
  onAdd,
}: {
  responseCenterId: string | number;
  onClose: () => void;
  onAdd: () => void;
}) => {
  const t = useTranslations();
  const { authenticatedRequest } = useUser();

  const onSubmit = async (params: ResponseCenterDistributionParams) => {
    try {
      await createResponseCenterDistribution(
        responseCenterId,
        params,
        authenticatedRequest
      );
      notify({
        message: t("Messages:create_alarm_reception_distributor_success"),
      });
      onAdd();
      onClose();
    } catch (error) {
      const errorKey = getErrorKey(error);
      notify({
        message: t(`Errors:${errorKey}`),
        variant: "error",
      });
    }
  };

  return (
    <DistributorForm
      labels={{
        title: t("AlarmReceptions:create_distributor"),
        description: t("AlarmReceptions:create_distributor_description"),
        submit: t("AlarmReceptions:create_distributor_submit"),
      }}
      onSubmit={onSubmit}
      onClose={onClose}
    />
  );
};

export default observer(CreateDistributorModal);
