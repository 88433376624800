import classnames from "classnames";
import Icon from "../Icon";
import styles from "./Table.module.css";

export interface NoItemsProps {
  icon?: string;
  title: string;
  subTitle?: string;
  clear?: {
    text: string;
    onClick: () => void;
  };
  className?: string;
  noIcon?: boolean;
  style?: React.CSSProperties;
}

const NoItems = ({
  icon,
  title,
  subTitle,
  clear,
  noIcon,
  className,
  style,
}: NoItemsProps) => (
  <div
    data-cy="NoItemContainer"
    className={classnames({
      [styles.tableNoDataContainer]: true,
      [className ?? ""]: true,
    })}
    style={style}
  >
    {!noIcon && (
      <div className="mb-8">
        <Icon name={icon ?? "adjustments"} size={40} color="Grey-500" />
      </div>
    )}
    <div data-cy="NoItemTitle" className={`${styles.noDataTitle} mb-8`}>
      {title}
    </div>
    {subTitle && (
      <div data-cy="NoItemSubTitle" className={`${styles.noDataSubTitle} mb-8`}>
        {subTitle}
      </div>
    )}
    {clear && (
      <span
        role="button"
        className={`${styles.noDataReset} clickable`}
        onClick={clear.onClick}
        onKeyUp={clear.onClick}
        aria-label="Clear"
      >
        {clear.text}
      </span>
    )}
  </div>
);

export default NoItems;
