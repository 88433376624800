import { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import { useQueryState } from "../filters/useQueryState";

type ModalIdState = {
  roleId?: string;
  alarmId?: string;
  integrationId?: string;
  handlingId?: string;
  addressId?: string;
  contactId?: string;
  transmitterId?: string;
  periodicDisables?: string;
};
type ModalIdFilter = { id?: string };

const toIdFilter = (state: any, field: string): ModalIdFilter => ({
  id: state[field],
});

const toIdState = (filter: string | undefined, field: string): ModalIdState => {
  let state: any = {};
  state[field] = filter ? `${filter}` : "";
  return state as ModalIdState;
};

const DEFAULT: ModalIdFilter = {
  id: undefined,
};

export const useModalIdHook = (
  searchObj: string,
  searchField: string
): [
  ModalIdFilter,
  Dispatch<SetStateAction<number | undefined>>,
  () => void
] => {
  const stateToUse = !searchObj
    ? DEFAULT
    : {
        id: searchObj,
      };
  const [state, setState] = useQueryState<any>(
    toIdState(stateToUse.id, searchField)
  );

  const id = useMemo(() => toIdFilter(state, searchField), [state]);

  const setId = useCallback(
    (args: any) => {
      setState(toIdState(args, searchField));
    },
    [setState]
  );

  const resetId = useCallback(
    () => setState(toIdState(DEFAULT.id, searchField)),
    [setState]
  );

  return [id, setId, resetId];
};
