import { useContext, useEffect, useRef } from "react";
import {
  FieldValues,
  useForm,
  UseFormProps,
  UseFormSetValue,
} from "react-hook-form";
import { SaveObjectContext } from "./SaveObjectContext";

const useSaveObject = () => useContext(SaveObjectContext);

const useObjectFormRef = () => {
  const { setFormRef } = useSaveObject();
  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    setFormRef(formRef);

    return () => {
      setFormRef();
    };
  }, []);

  return formRef;
};

const useObjectForm = <T extends FieldValues>(props: UseFormProps<T>) => {
  const { setFormState } = useSaveObject();

  const values = useForm<T>(props);
  const {
    formState: { isDirty, isSubmitting },
    setValue,
    reset,
  } = values;

  useEffect(() => {
    setFormState({
      isDirty,
      isSubmitting,
      reset,
    });

    return () => {
      setFormState();
    };
  }, [isDirty, isSubmitting]);

  const setValueDirty = (name: any, value: any, options: any) => {
    setValue(name, value, {
      shouldDirty: true,
      ...(options ?? {}),
    });
    // if the shouldDirty option is set then force a update to the form state
    if (options?.shouldDirty) {
      setFormState({
        isDirty: options.shouldDirty ?? false,
        isSubmitting: false,
        reset,
      });
    }
  };

  return { ...values, setValue: setValueDirty as UseFormSetValue<T> };
};

export { useSaveObject, useObjectFormRef, useObjectForm };
