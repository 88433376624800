import { useEffect, useRef, useState } from "react";
import useTranslations from "../../core/i18n/useTranslations";
import Accordion from "../../ui-lib/components/Accordion/Accordion";
import { Row, Spacer } from "../../components/Layout/Layout";
import { useOrganizationsTree } from "../../core/api/organizations/organizations";
import useUser from "../../core/user/useUser";
import { notifyApiErrors } from "../../core/helpers/helpers";
import Table from "../../ui-lib/components/Tables/Table";
import { AlternativeCell } from "./AlternativeCell";
import Modal, { ModalActions } from "../../ui-lib/components/Popup/Modal";
import { EditAlternativeModal } from "./EditAlternativeModal";
import Button from "../../ui-lib/components/Button/Button";
import { DeleteAlternativesModal } from "./DeleteAlternativesModal";
import { IAlternatives } from "../../core/api/alternatives/types";
import {
  getAlternatives,
  reorderAlternatives,
} from "../../core/api/alternatives/alternatives";
import { notify } from "../../ui-lib/components/Alerts/Toast";
import LoadingSpinner from "../../ui-lib/components/Loading/LoadingSpinner";

export const MenuAlternativesController = ({
  isMobile = false,
  bothAppLinksRequired = false,
  ownerId,
  alternativesType,
  renderInAccordion = false,
  withOrganizationsSelection = false,
  accordionTitle = "",
  accordionDescription = "",
  tableHeader = "AlarmReceptions:advanced_settings_menualternatives_label",
}: {
  isMobile?: boolean;
  bothAppLinksRequired?: boolean;
  ownerId: number;
  alternativesType:
    | "adminId"
    | "organizationId"
    | "responseCenterId"
    | "sellerId";
  renderInAccordion?: boolean;
  withOrganizationsSelection?: boolean;
  accordionTitle?: string;
  accordionDescription?: string;
  tableHeader?: string;
}) => {
  const t = useTranslations();
  const { authenticatedRequest } = useUser();
  const { data: organisations } = useOrganizationsTree(
    alternativesType === "organizationId" ? +ownerId : undefined
  );
  const [isLoading, setIsLoading] = useState(false);

  const [alternatives, setAlternatives] = useState<IAlternatives[]>([]);
  const [upForEdit, setUpForEdit] = useState<number | undefined>();
  const editModalRef = useRef<ModalActions>(null);
  const deleteModalRef = useRef<ModalActions>(null);
  const [upForDelete, setUpForDelete] = useState<number | undefined>();

  const fetchLinks = async () => {
    try {
      setIsLoading(true);
      const result = await getAlternatives(
        { [alternativesType]: ownerId },
        authenticatedRequest
      );
      setAlternatives(
        result.data.filter((alternative) =>
          isMobile ? !alternative.url : alternative.url
        )
      );
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    } finally {
      setIsLoading(false);
    }
  };

  const reorderLinks = async (dataToReorder: IAlternatives[]) => {
    try {
      setIsLoading(true);
      const reorderedData = dataToReorder.map((alternative, index) => ({
        ...alternative,
        order: index,
      }));
      await reorderAlternatives(
        alternativesType,
        ownerId,
        reorderedData,
        authenticatedRequest
      );
      await fetchLinks();
      notify({
        message: t("Common:links_reordered"),
      });
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchLinks();
  }, []);

  const renderAccordionContent = () => (
    <div style={{ width: "100%" }}>
      <Row type="space">
        <h2>{t(tableHeader)}</h2>

        <Button
          variant="secondary"
          image="link"
          text={t("Administrator:add_new_link")}
          onClick={() => {
            editModalRef?.current?.open();
          }}
        />
      </Row>

      <Spacer size={18} />

      {isLoading ? (
        <LoadingSpinner theme="primary" />
      ) : (
        <Table<IAlternatives>
          columns={[
            {
              fieldTemplate: (rowData) => (
                <div style={{ height: "100%" }}>
                  <span
                    style={{
                      whiteSpace: "normal",
                    }}
                  >
                    {rowData.title}
                  </span>
                </div>
              ),
              style: {
                width: "150px",
              },
            },
            {
              fieldTemplate: (rowData) => (
                <AlternativeCell
                  data={rowData}
                  withOrgRelation={alternativesType !== "adminId"}
                />
              ),
            },
          ]}
          items={alternatives.filter((alternative) =>
            isMobile
              ? alternative.androidLink ?? alternative.iosLink
              : !alternative.androidLink && !alternative.iosLink
          )}
          rowActions={[
            {
              text: t("Objects:edit_details"),
              icon: "pencil-alt",
              iconVariant: "secondary",
              iconSize: 16,
              onClick: (rowData) => {
                setUpForEdit(rowData.id);
                editModalRef?.current?.open();
              },
            },
            {
              text: t("Objects:remove_from"),
              icon: "x",
              iconVariant: "secondary",
              iconSize: 16,
              onClick: (rowData) => {
                setUpForDelete(rowData.id);
                deleteModalRef?.current?.open();
              },
            },
          ]}
          hideHeader
          hideEmptyMessage
          noRowsMessage={t("Table:no_records_found")}
          showRowHover
          rowActionContextMenu
          withShowMore
          reorderableRows
          onRowReorder={(data) => reorderLinks(data.value)}
        />
      )}
    </div>
  );

  const renderAccordion = () => (
    <Accordion title={accordionTitle} description={accordionDescription}>
      {renderAccordionContent()}
    </Accordion>
  );

  return (
    <>
      <Modal modalRef={editModalRef}>
        {(close) => (
          <EditAlternativeModal
            alternativeData={
              alternatives.find((alternative) => alternative.id === upForEdit)!
            }
            ownerId={ownerId}
            alternativesType={alternativesType}
            onClose={() => {
              close();
              setUpForEdit(undefined);
            }}
            onSubmitEnd={() => {
              fetchLinks();
            }}
            organisations={organisations}
            isMobile={isMobile}
            withOrganizationsSelection={withOrganizationsSelection}
            bothAppLinksRequired={bothAppLinksRequired}
          />
        )}
      </Modal>
      <Modal modalRef={deleteModalRef}>
        {(close) => (
          <DeleteAlternativesModal
            alternativeId={upForDelete}
            adminId={-1}
            onClose={close}
            onDelete={() => {
              close();
              fetchLinks();
            }}
          />
        )}
      </Modal>
      {renderInAccordion ? renderAccordion() : renderAccordionContent()}
    </>
  );
};
