import { useRef, useState } from "react";
import useTranslations from "../../../core/i18n/useTranslations";
import Button from "../../../ui-lib/components/Button/Button";
import { PopupContextMenuLinkButton } from "../../../ui-lib/components/Menus/PopupContextMenu/PopupContextMenuLinkButton";
import PrimeModal from "../../../ui-lib/components/PrimeModal/PrimeModal";
import RequestLogModal from "../../Organizations/RequestOrganizationLogModal";
import { OverlayPanel } from "primereact/overlaypanel";

const IdentityActions = () => {
  const t = useTranslations();
  const [requestLogModalOpen, setRequestLogModalOpen] = useState(false);

  const contextMenuRef = useRef<any>(null);

  return (
    <>
      <Button
        type="button"
        variant="secondary"
        image="dots-horizontal"
        text={t("Common:other")}
        onClick={(e) => contextMenuRef.current?.toggle(e)}
      />
      <OverlayPanel ref={contextMenuRef}>
        <PopupContextMenuLinkButton
          icon="download"
          text={t("Administrator:request_log")}
          onClick={() => setRequestLogModalOpen(true)}
        />
      </OverlayPanel>
      <PrimeModal
        withHeader
        header={t("Administrator:request_log")}
        isOpen={requestLogModalOpen}
        onClose={() => setRequestLogModalOpen(false)}
      >
        <RequestLogModal
          onClose={() => setRequestLogModalOpen(false)}
          logTarget="Identity"
        />
      </PrimeModal>
    </>
  );
};

export default IdentityActions;
