import useTranslations from "../../../../core/i18n/useTranslations";
import BulkDeleteModal from "../../../../components/BulkDeleteModal";
import { deleteAdminSetting } from "../../../../core/api/administrators/administrators";

export const DeleteSettingsModal = ({
  adminId,
  idToDelete,
  onDelete,
  onClose,
  isOpen,
}: {
  adminId: number;
  idToDelete: number;
  onClose: () => void;
  onDelete: () => void;
  isOpen: boolean;
}) => {
  const t = useTranslations();

  return (
    <BulkDeleteModal
      isOpen={isOpen}
      onClose={onClose}
      onDelete={onDelete}
      labels={{
        single: t("Administrator:special_setting_type"),
        multi: t("AlarmReceptions:special_setting_type_multi"),
      }}
      ids={[`${idToDelete}`]}
      apiFunc={(id, authReq) => deleteAdminSetting(adminId, +id, authReq)}
    />
  );
};
