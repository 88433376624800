import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import moment from "moment-timezone";
import { Controller, FieldError, FormProvider } from "react-hook-form";
import { LanguageDropdown } from "../../../../components/LanguageDropdown/LanguageDropdown";
import { Column, Row, Spacer } from "../../../../components/Layout/Layout";
import { OrganisationPicker } from "../../../../components/OrganisationPicker";
import {
  AdministratorDetails,
  Timezone,
  DateTimeFormat,
  IUserSuggest,
} from "../../../../core/api/administrators/types";
import { AdminType } from "../../../../core/enumerations/enumerations";
import {
  validateEmail,
  validateEmailIfExists,
  validatePhoneNumber,
} from "../../../../core/helpers/validation";
import useTranslations from "../../../../core/i18n/useTranslations";
import {
  useObjectForm,
  useObjectFormRef,
} from "../../../../core/SaveObjectContext/hooks";
import { notify } from "../../../../ui-lib/components/Alerts/Toast";
import Divider from "../../../../ui-lib/components/Divider/Divider";
import Dropdown from "../../../../ui-lib/components/Dropdown/Dropdown";
import ExpandableList from "../../../../ui-lib/components/ExpandableList/ExpandableList";
import TextInput from "../../../../ui-lib/components/Inputs/TextInput";
import AccountSecuritySection, { AuthType } from "./AccountSecuritySection";
import PositioningTransmitter from "./PositioningTransmitter";
import { RelatedObject } from "./RelatedObject";
import { Horizontal } from "../../../../ui-lib/components/Divider/Divider.stories";
import useUser from "../../../../core/user/useUser";
import { notifyApiErrors } from "../../../../core/helpers/helpers";
import {
  getAdminTemplates,
  getTransmitters,
  getUserSuggest,
  updateAdminTemplate,
} from "../../../../core/api/administrators/administrators";
import { IItemProp } from "../../../../ui-lib/components/Dropdown/DropdownItem";
import ToggleSwitch from "../../../../ui-lib/components/Checkbox/ToggleSwitch";
import DatePicker from "../../../../ui-lib/components/DateAndTime/DatePicker";
import Checkbox from "../../../../ui-lib/components/Checkbox/Checkbox";
import { Transmitter } from "../../../../core/api/transmitters/types";
import { notifyError } from "../../../../components/Errors/ErrorAlert";
import { deleteObject, getObject } from "../../../../core/api/objects/objects";
import { ObjectDetailsModel } from "../../../../core/api/objects/types";
import { ResponseCenterDetails } from "../../../../core/api/responsecenters/types";
import { getResponseCenter } from "../../../../core/api/responsecenters/responsecenters";
import LoadingSpinner from "../../../../ui-lib/components/Loading/LoadingSpinner";
import { OperatorAccessDropdown } from "../../../../components/Administrator/OperatorAccess";

const GeneralForm = ({
  defaultValues,
  onSubmit,
  timezones,
  dateTimeFormats,
}: {
  defaultValues?: AdministratorDetails;
  onSubmit: (values: AdministratorDetails) => Promise<void>;
  timezones: Timezone[];
  dateTimeFormats: DateTimeFormat[];
}) => {
  const t = useTranslations();
  const { authenticatedRequest, config, dateFormat, accountId, data } =
    useUser();
  const actions = config?.show;
  const formRef = useObjectFormRef();

  const [isLoading, setIsLoading] = useState(true);
  const [templateData, setTemplateData] = useState<IItemProp[]>([]);
  const [refetch, setRefetch] = useState(false);
  const [transmitterData, setTransmitterData] = useState<Transmitter[]>();
  const [suggestData, setSuggestData] = useState<IUserSuggest>();
  const [objectData, setObjectData] = useState<ObjectDetailsModel>();
  const [arcData, setArcData] = useState<ResponseCenterDetails>();

  const methods = useObjectForm<AdministratorDetails>({
    defaultValues,
    shouldUnregister: false,
  });

  const currentUser = defaultValues?.id === accountId;

  const {
    control,
    formState: { errors },
    watch,
    setValue,
    getValues,
    handleSubmit,
    reset,
    trigger,
  } = methods;

  const restrictLogin = watch("restrictLogin");

  const fetchTemplate = async () => {
    setIsLoading(true);
    try {
      const result = await getAdminTemplates(
        defaultValues?.id!,
        authenticatedRequest
      );
      setTemplateData([
        {
          id: -1,
          name: t("Administrator:clear_template"),
          isSelected: true,
        },
        ...result.data.map((template: any) => ({
          id: template.templateId,
          name: template.name,
          isSelected: false,
        })),
      ]);
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    } finally {
      setIsLoading(false);
    }
  };

  const updateTemplateId = async (id: number | string) => {
    setIsLoading(true);
    try {
      await updateAdminTemplate(defaultValues?.id!, id, authenticatedRequest);
      notify({
        message: t("Administrator:template_updated"),
      });
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchObjectData = async (objectId: number) => {
    const objectResult = await getObject(objectId, authenticatedRequest);
    setObjectData(objectResult.data);
  };

  const fetchTransmitter = async () => {
    setIsLoading(true);
    if (defaultValues) {
      try {
        const transmitterResult = await getTransmitters(
          defaultValues.id,
          authenticatedRequest
        );
        setTransmitterData(transmitterResult.data || []);
      } catch (error: any) {
        notifyError({ error, t });
      } finally {
        setIsLoading(false);
      }
    }
  };
  const fetchUserSuggest = async () => {
    setIsLoading(true);
    try {
      const result = await getUserSuggest(
        defaultValues?.id!,
        authenticatedRequest
      );
      setSuggestData(result.data);
      if (!result.data.canCreateUser && result.data.existingUser) {
        await fetchObjectData(result.data.existingUser);
      }
      if (
        defaultValues?.responseCenterId &&
        defaultValues?.responseCenterId !== 1
      ) {
        const arcDataResult = await getResponseCenter(
          defaultValues?.responseCenterId,
          authenticatedRequest
        );
        setArcData(arcDataResult.data);
      }
    } catch (error: any) {
      notifyError({ error, t });
    } finally {
      setIsLoading(false);
    }
  };

  const removeRelatedObject = async () => {
    setIsLoading(true);
    try {
      await deleteObject(suggestData?.existingUser!, authenticatedRequest);
      fetchUserSuggest();
      setObjectData(undefined);
      fetchTransmitter();
      setRefetch(!refetch);
    } catch (error: any) {
      notifyError({ error, t });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setObjectData(undefined);
    fetchUserSuggest();
    if (actions?.includes("AdminAssociateTransmitter")) {
      fetchTransmitter();
    }
    if (defaultValues?.type !== "respondent") {
      fetchTemplate();
    }
  }, []);

  useEffect(() => {
    if (
      defaultValues?.id !== getValues().id ||
      // This need for proper render ToggleSwitch value when
      // admin not active due validTo date
      defaultValues.active !== getValues("active")
    )
      reset(defaultValues);
  }, [defaultValues?.id, defaultValues?.active]);

  const adminType: AdminType =
    defaultValues?.type === "respondent" ||
    defaultValues?.type === "responscenter"
      ? AdminType.Respondent
      : AdminType.Customer;

  let belongsTo;
  if (adminType === AdminType.Customer || defaultValues?.organizationId) {
    belongsTo = (
      <OrganisationPicker
        name="organizationId"
        label={t("Administrator:general_input_organisation")}
        disabled
      />
    );
  } else if (adminType === AdminType.Respondent) {
    belongsTo = (
      <Controller
        name="responseCenter"
        control={control}
        render={({ field }) => (
          <TextInput
            label={t("Administrator:general_input_alarm_reception")}
            disabled
            value={field.value ?? ""}
            onChange={field.onChange}
            validationError={errors.name && t("Errors:input_field_required")}
          />
        )}
      />
    );
  }
  if (defaultValues?.type === "seller") {
    belongsTo = (
      <TextInput
        label={t("Filters:Seller")}
        disabled
        value={defaultValues?.seller}
        onChange={() => {}}
      />
    );
  }

  const internalSubmit = async (values: AdministratorDetails) => {
    setIsLoading(true);
    try {
      const updatedValues = { ...values };
      // Remove validTo date if activating/deactivating admin with ToggleSwitch
      if (updatedValues.restrictLogin !== defaultValues?.restrictLogin) {
        updatedValues.validTo = null;
      }
      updatedValues.passwordConstraints = defaultValues?.passwordConstraints;
      reset({ ...updatedValues });
      await onSubmit(updatedValues);
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    } finally {
      setIsLoading(false);
    }
  };

  const forcePasswordSubmit = async () => {
    const values = getValues();
    handleSubmit(async (dataValues) => {
      internalSubmit({ ...dataValues, forcePasswordReset: true });
    })(values as any);
  };

  const getErrorMessage = (error?: FieldError) => {
    if (!error) return "";
    if (error.type === "required") {
      return t("Errors:input_field_required");
    }
    if (error.type === "validate") {
      return t("Errors:WrongFormat");
    }
    return "Undefined error";
  };

  if (isLoading) {
    return (
      <Column style={{ width: "100%" }}>
        <LoadingSpinner theme="primary" />
      </Column>
    );
  }

  return (
    <FormProvider {...methods}>
      <form
        ref={formRef}
        onSubmit={handleSubmit(async (values) => {
          internalSubmit(values);
        })}
        style={{ width: "100%" }}
      >
        <Row align="start">
          <Column style={{ width: "100%" }}>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextInput
                  label={t("Common:name")}
                  value={field.value ?? ""}
                  onChange={field.onChange}
                />
              )}
            />
          </Column>
          <Spacer size={32} />
          <Column style={{ width: "100%" }}>
            <Controller
              name="telephone"
              control={control}
              rules={{
                validate: (value) => {
                  const requirePhone =
                    watch("twoFactorAuthenticationType") === AuthType.Sms ||
                    watch("passwordResetPreference") === 2;

                  if (requirePhone || (value && value.length > 0)) {
                    return validatePhoneNumber(value);
                  }

                  return true;
                },
              }}
              render={({ field }) => (
                <TextInput
                  label={t("Administrator:telephone")}
                  placeholder={t("Common:phone_number_placeholder")}
                  value={field.value ?? ""}
                  onChange={field.onChange}
                  validationError={
                    errors.telephone &&
                    t("Objects:Validation_identifier_format")
                  }
                />
              )}
            />
          </Column>
        </Row>

        <Spacer size={16} />

        <Row align="start">
          <Column style={{ width: "100%" }}>
            <Controller
              name="email"
              control={control}
              rules={{
                validate: (value) => {
                  const passwordResetPreference = watch(
                    "passwordResetPreference"
                  );

                  if (passwordResetPreference === 1) {
                    return validateEmail(value);
                  }

                  return validateEmailIfExists(value);
                },
              }}
              render={({ field }) => (
                <TextInput
                  label={t("Common:email")}
                  placeholder={t("Common:placeholder_email")}
                  value={field.value ?? ""}
                  onChange={field.onChange}
                  validationError={
                    errors.email && t("Errors:input_email_format")
                  }
                />
              )}
            />
          </Column>
          <Spacer size={32} />
          <Column style={{ width: "100%" }}>{belongsTo}</Column>
        </Row>

        <Spacer size={16} />

        <Row align="start">
          <Column style={{ width: "100%" }}>
            <Controller
              name="username"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <TextInput
                  required
                  label={t("Common:username")}
                  value={field.value}
                  onChange={field.onChange}
                  validationError={getErrorMessage(errors.username)}
                />
              )}
            />
          </Column>
          <Spacer size={32} />
          <Column style={{ width: "100%" }}>
            <Controller
              name="initials"
              control={control}
              render={({ field }) => (
                <TextInput
                  label={t("Administrator:general_input_initials")}
                  placeholder={t(
                    "Administrator:general_input_initials_placeholder"
                  )}
                  value={field.value ?? ""}
                  onChange={field.onChange}
                />
              )}
            />
          </Column>
        </Row>

        <Spacer size={16} />

        <Row align="start">
          <Column align="start" style={{ width: "100%" }}>
            <Controller
              name="passwordResetPreference"
              control={control}
              render={({ field }) => {
                const dropdownItems = [
                  {
                    id: 0,
                    name: t("Common:not_allowed"),
                    isSelected: field.value === 0,
                  },
                  {
                    id: 1,
                    name: t("Common:email"),
                    isSelected: field.value === 1,
                  },
                  {
                    id: 2,
                    name: t("Common:labels_sms"),
                    isSelected: field.value === 2,
                  },
                ];
                return (
                  <Dropdown
                    title={t("Administrator:password_reset_preference")}
                    selectedItem={dropdownItems.find((i) => i.isSelected)}
                    onSelectItem={(item) => {
                      setValue(field.name, item.id as number);
                      trigger("email");
                    }}
                    items={dropdownItems}
                  />
                );
              }}
            />
          </Column>
          <Spacer size={32} />
          <Column align="start" style={{ width: "100%" }} />
        </Row>

        <Spacer size={32} />

        <ExpandableList noScroll title={t("Common:labels_advanced_section")}>
          <>
            <Spacer size={16} />
            <Row align="start">
              <Column align="start" style={{ width: "100%" }}>
                <Controller
                  name="primaryLanguage"
                  control={control}
                  render={({ field }) => (
                    <LanguageDropdown
                      label={t("Administrator:general_input_primary_language")}
                      selected={field.value}
                      onSelect={(value) => setValue(field.name, value)}
                    />
                  )}
                />
              </Column>
              <Spacer size={32} />
              <Column style={{ width: "100%" }}>
                {adminType !== AdminType.Respondent && (
                  <Controller
                    name="secondaryLanguage"
                    control={control}
                    render={({ field }) => (
                      <LanguageDropdown
                        label={t(
                          "Administrator:general_input_secondary_language"
                        )}
                        selected={field.value}
                        onSelect={(value) => setValue(field.name, value)}
                        withClearItem
                      />
                    )}
                  />
                )}
              </Column>
            </Row>

            <Spacer size={16} />

            <Row align="start">
              <Column style={{ width: "100%" }}>
                <Controller
                  name="timezoneId"
                  control={control}
                  render={({ field }) => {
                    const dropdownItems = timezones.map((timezone) => ({
                      id: timezone.id,
                      name: timezone.timezoneName,
                      isSelected: timezone.id === field.value,
                    }));

                    return (
                      <Dropdown
                        title={t("Administrator:general_input_timezone")}
                        width="100%"
                        selectedItem={dropdownItems.find((i) => i.isSelected)}
                        items={dropdownItems}
                        onSelectItem={(item) =>
                          setValue(field.name, item.id as number)
                        }
                      />
                    );
                  }}
                />
              </Column>
              <Spacer size={32} />
              <Column style={{ width: "100%" }}>
                <Controller
                  name="dateTimeFormatId"
                  control={control}
                  render={({ field }) => {
                    const dropdownItems = dateTimeFormats.map(
                      (dateTimeFormat) => ({
                        id: dateTimeFormat.id,
                        name: dateTimeFormat.dateTimeFormat,
                        isSelected: dateTimeFormat.id === field.value,
                      })
                    );

                    return (
                      <Dropdown
                        title={t(
                          "Administrator:general_input_date_time_format"
                        )}
                        width="100%"
                        selectedItem={dropdownItems.find((i) => i.isSelected)}
                        items={dropdownItems}
                        onSelectItem={(item) =>
                          setValue(field.name, item.id as number)
                        }
                      />
                    );
                  }}
                />
              </Column>
            </Row>

            {adminType === AdminType.Respondent && (
              <>
                <Spacer size={16} />
                <Row align="start">
                  <Column style={{ width: "100%" }}>
                    <Controller
                      control={control}
                      name="notifyIfNotLoggedIn"
                      defaultValue={0}
                      render={({ field }) => {
                        const dropdownItems = [
                          {
                            id: 0,
                            name: t("Administrator:no_notification"),
                            isSelected: field.value === 0,
                          },
                          {
                            id: 2,
                            name: t("Administrator:sms_app"),
                            isSelected: field.value === 2,
                          },
                        ];

                        return (
                          <Dropdown
                            title={t("Administrator:notify_if_not_logged_in")}
                            width="100%"
                            selectedItem={dropdownItems.find(
                              (i) => i.isSelected
                            )}
                            items={dropdownItems}
                            onSelectItem={(item) => {
                              setValue(field.name, item.id as number);
                            }}
                          />
                        );
                      }}
                    />
                  </Column>
                  <Spacer size={32} />
                  <Column style={{ width: "100%" }}>
                    <OperatorAccessDropdown
                      control={control}
                      setValue={setValue}
                      t={t}
                    />
                  </Column>
                </Row>
                <Spacer size={16} />
                <Row align="start">
                  <Column align="start" style={{ width: "100%" }}>
                    <Controller
                      control={control}
                      name="readOnly"
                      render={({ field }) => (
                        <Checkbox
                          label={t("Administrator:read_only")}
                          checked={!!field.value}
                          onChange={() => {
                            setValue(field.name, !field.value);
                          }}
                          style={{ padding: 0 }}
                        />
                      )}
                    />
                  </Column>
                </Row>
              </>
            )}

            {(templateData || []).length > 1 &&
              actions?.find((key) => key === "ChangeAdmins") && (
                <>
                  <Spacer size={32} />

                  <Horizontal />

                  <Spacer size={16} />

                  <Dropdown
                    title={t("Common:template_label")}
                    selectedItem={templateData.find((i) => i.isSelected)}
                    width="376px"
                    items={templateData}
                    disabled={isLoading}
                    onSelectItem={(item) => {
                      const updatedTemplateData = templateData?.map(
                        (template) => ({
                          ...template,
                          isSelected: template.id === item.id,
                        })
                      );
                      setTemplateData(updatedTemplateData);
                      updateTemplateId(item.id);
                    }}
                  />
                </>
              )}
          </>
        </ExpandableList>

        {!currentUser && (
          <>
            <Spacer size={32} />

            <Divider />
            <Spacer size={32} />
            <Column align="start" type="fill">
              <Row align="start" type="left">
                <Controller
                  name="restrictLogin"
                  control={control}
                  render={({ field }) => (
                    <ToggleSwitch
                      checked={!field.value}
                      onChange={(e) => {
                        setValue("restrictLogin", !e.value);
                      }}
                      name={field.name}
                      id={field.name}
                      leftLabel={t("Administrator:active_admin")}
                    />
                  )}
                />
              </Row>

              {!restrictLogin && (
                <>
                  <Spacer size={32} />

                  <p>{t("Administrator:scheduled_deactivation_label")}</p>

                  <Spacer size={16} />

                  <Row align="start" type="fill">
                    <Column align="start" style={{ width: "100%" }}>
                      <Controller
                        name="validTo"
                        control={control}
                        render={({ field }) => (
                          <DatePicker
                            minDate={new Date()}
                            dateFormat={dateFormat?.split("/").join("-")}
                            placeholder={dateFormat?.split("/").join("-")}
                            value={(() => {
                              if (field.value) {
                                return moment.tz(field.value, "UTC").toDate();
                              }
                              return undefined;
                            })()}
                            onChange={field.onChange}
                            label={t("Administrator:deactivate_user_on")}
                          />
                        )}
                      />
                    </Column>
                    <Spacer size={32} />
                    <Column align="start" style={{ width: "100%" }} />
                  </Row>
                </>
              )}
            </Column>
          </>
        )}

        {defaultValues?.type === "respondent" &&
          actions?.includes("AdminAssociateTransmitter") &&
          data?.type !== "respondent" && (
            <RelatedObject
              adminData={defaultValues}
              objectData={objectData}
              suggestData={suggestData}
              arcData={arcData}
              transmitterData={transmitterData}
              removeRelatedObject={removeRelatedObject}
              fetchUserSuggest={fetchUserSuggest}
              actions={actions}
            />
          )}

        <Spacer size={32} />
        <Divider />
        <Spacer size={32} />

        {actions?.includes("AdminAssociateTransmitter") &&
          defaultValues?.type === "respondent" &&
          data?.type !== "respondent" && (
            <>
              <PositioningTransmitter
                data={defaultValues}
                authRequest={authenticatedRequest}
                refetchState={refetch}
                transmitterData={transmitterData}
                refetchRelatedObject={setRefetch}
                fetchTransmitters={fetchTransmitter}
              />

              <Spacer size={32} />
              <Divider />
              <Spacer size={32} />
            </>
          )}

        <AccountSecuritySection
          actions={actions}
          forcePasswordRequest={forcePasswordSubmit}
          forceRequestSended={defaultValues?.forcePasswordReset || false}
          userName={defaultValues?.username ?? ""}
          userType={defaultValues?.type!}
        />
      </form>
    </FormProvider>
  );
};

export default observer(GeneralForm);
