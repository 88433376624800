import { useCallback, useMemo } from "react";
import moment from "moment";
import { useQueryState } from "./useQueryState";

type DateState = { start?: string; end?: string };
type DateFilter = { start?: Date; end?: Date };

const toTimeSpanFilter = (state: DateState): DateFilter => ({
  start: state.start ? new Date(state.start) : undefined,
  end: state.end ? new Date(state.end) : undefined,
});

const toTimeSpanState = (filter: DateFilter): DateState => ({
  start: filter.start
    ? moment(filter.start).format("YYYY-MM-DDTHH:mm:ssZ")
    : "",
  end: filter.end ? moment(filter.end).format("YYYY-MM-DDTHH:mm:ssZ") : "",
});

const DEFAULT: DateFilter = {
  start: undefined,
  end: undefined,
};

export const useDateFilter = (searchObj?: any) => {
  const stateToUse = !searchObj
    ? DEFAULT
    : {
        start: searchObj.start,
        end: searchObj.end,
      };
  const [state, setState] = useQueryState<DateState>(
    toTimeSpanState(stateToUse)
  );

  const dateFilter = useMemo(() => toTimeSpanFilter(state), [state]);

  const setDateFilter = useCallback(
    (args: DateFilter) => setState(toTimeSpanState(args)),
    [setState]
  );

  const resetDateFilter = useCallback(
    () => setState(toTimeSpanState(DEFAULT)),
    [setState]
  );

  return {
    dateFilter,
    setDateFilter,
    resetDateFilter,
  };
};
