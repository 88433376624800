import { useEffect, useRef, useState } from "react";
import useTranslations from "../../../../core/i18n/useTranslations";
import Accordion from "../../../../ui-lib/components/Accordion/Accordion";
import { getGroupRespondentsList } from "../../../../core/api/responsecenters/responsecenters";
import useUser from "../../../../core/user/useUser";
import { notifyApiErrors } from "../../../../core/helpers/helpers";
import LoadingSpinner from "../../../../ui-lib/components/Loading/LoadingSpinner";
import Table from "../../../../ui-lib/components/Tables/Table";
import TableCell from "../../../../ui-lib/components/Tables/TableCell";
import Badge from "../../../../ui-lib/components/Badges/Badge";
import { ILinkedRespondent } from "../../../../core/api/responsecenters/types";
import Dropdown from "../../../../ui-lib/components/Dropdown/Dropdown";
import { IDropdownItemsWithIcons } from "../../../../ui-lib/components/Dropdown/DropdownItem";
import { Row, Spacer } from "../../../../components/Layout/Layout";
import Button from "../../../../ui-lib/components/Button/Button";
import TableRowSelectionPopup, {
  TableRowSelectionPopupActions,
} from "../../../../ui-lib/components/Tables/TableRowSelectionPopup";
import { DeleteRespondentsFromGroupModal } from "./DeleteRespondentsFromGroupModal";
import SearchInput from "../../../../ui-lib/components/Inputs/SearchInput";

export const GroupRespondentsAccessManager = ({
  arcId,
  groupId,
  groupName,
  shouldUpdateRespondents,
  respondentsSubmit,
}: {
  arcId: number;
  groupId: number;
  groupName: string;
  shouldUpdateRespondents: boolean;
  respondentsSubmit: (respondents: { id: number; forced: boolean }[]) => void;
}) => {
  const t = useTranslations();
  const { authenticatedRequest } = useUser();
  const [isLoading, setIsLoading] = useState(false);
  const [pageSettings, setPageSettings] = useState({ page: 1, pageSize: 10 });
  const [searchText, setSearchText] = useState("");
  const [respondentsList, setRespondentsList] = useState<ILinkedRespondent[]>(
    []
  );
  const [dataToUpdate, setDataToUpdate] = useState<
    { id: number; forced: boolean; changed: boolean }[]
  >([]);
  const [upForDelete, setUpForDelete] = useState<number[]>([]);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const selectionPopupRef =
    useRef<TableRowSelectionPopupActions<ILinkedRespondent>>(null);

  const fetchLinkedRespondents = async () => {
    try {
      setIsLoading(true);
      const results = await getGroupRespondentsList(
        arcId,
        groupId,
        { ...pageSettings, searchText },
        authenticatedRequest
      );
      setRespondentsList(results.data);
      setDataToUpdate(
        pageSettings.page === 1
          ? results.data.map((r) => ({
              id: r.id,
              forced: r.forcedAccess,
              changed: false,
            }))
          : [
              ...dataToUpdate,
              ...results.data.map((r) => ({
                id: r.id,
                forced: r.forcedAccess,
                changed: false,
              })),
            ]
      );
      setRespondentsList(
        pageSettings.page === 1
          ? results.data
          : [...respondentsList, ...results.data]
      );
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchLinkedRespondents();
  }, [pageSettings.page, pageSettings.pageSize]);

  useEffect(() => {
    if (shouldUpdateRespondents) {
      const respondentsToSubmit = dataToUpdate
        .filter((d) => d.changed)
        .map((d) => ({ id: d.id, forced: d.forced }));
      if (respondentsToSubmit.length) {
        respondentsSubmit(respondentsToSubmit);
      }
    }
  }, [shouldUpdateRespondents]);

  const showMore =
    pageSettings.page * pageSettings.pageSize === respondentsList.length;

  return (
    <Accordion
      title={t("Menu:AlarmReceptions_Respondents")}
      description={t(
        "AlarmReceptions:group_respondents_list_description"
      ).replace("{name}", groupName)}
    >
      {isLoading ? (
        <LoadingSpinner theme="primary" />
      ) : (
        <>
          <TableRowSelectionPopup
            ref={selectionPopupRef}
            renderBody={(selectedItems) => (
              <Row>
                <Spacer size={16} />
                <Button
                  variant="destructive"
                  text={`${t("Common:delete_selection")} (${
                    selectedItems.length
                  })`}
                  onClick={() => {
                    setUpForDelete(selectedItems.map((i) => i.id as any));
                    setDeleteOpen(true);
                  }}
                />
              </Row>
            )}
          />
          <DeleteRespondentsFromGroupModal
            arcId={arcId}
            groupId={groupId}
            isOpen={deleteOpen}
            ids={upForDelete}
            onClose={() => setDeleteOpen(false)}
            onDelete={() => {
              setDeleteOpen(false);
              selectionPopupRef.current?.close(true);
              setPageSettings({ page: 1, pageSize: 10 });
            }}
          />

          <SearchInput
            placeholder={t("Administrator:administrators_search_placeholder")}
            value={searchText}
            onChange={(value) => {
              setSearchText(value);
              setPageSettings({
                ...pageSettings,
                page: 1,
              });
            }}
            limit={1}
          />
          <Table<ILinkedRespondent>
            columns={[
              {
                header: t("Common:username"),
                fieldTemplate: (rowData) => (
                  <TableCell value={rowData.username} />
                ),
              },
              {
                header: t("Common:status"),
                fieldTemplate: (rowData) => {
                  const badgeTitle = rowData.active
                    ? t("Administrator:active")
                    : t("Administrator:inactive");
                  const badgeVariant = rowData.active ? "success" : "secondary";

                  return <Badge title={badgeTitle} variant={badgeVariant} />;
                },
              },
              {
                header: t("Common:labels_phone_number"),
                fieldTemplate: (rowData) => (
                  <TableCell value={rowData.telephone} />
                ),
              },
              {
                header: t("Common:role_template"),
                fieldTemplate: (rowData) => (
                  <TableCell
                    leftIcon="phone-incoming"
                    iconColor="Grey-300"
                    value={
                      rowData.templateName === "LegacyAdmin"
                        ? t("Administrator:LegacyAdmin")
                        : rowData.templateName
                    }
                  />
                ),
              },
              {
                header: t("Common:access"),
                fieldTemplate: (rowData) => {
                  const dropdownItems: IDropdownItemsWithIcons[] = [
                    {
                      id: 0,
                      name: t("Common:forced"),
                      isSelected: dataToUpdate.find((d) => d.id === rowData.id)!
                        .forced,
                      icon: "check",
                      color: "Primary-700",
                      onlySelected: true,
                    },
                    {
                      id: 1,
                      name: t("Common:optional"),
                      isSelected: !dataToUpdate.find(
                        (d) => d.id === rowData.id
                      )!.forced,
                      icon: "minus",
                      color: "Grey-500",
                      onlySelected: true,
                    },
                  ];

                  return (
                    <Dropdown
                      onSelectItem={(item) => {
                        const newDateToUpdate = [...dataToUpdate];
                        const itemToChange = newDateToUpdate.find(
                          (d) => d.id === rowData.id
                        );
                        itemToChange!.forced = item.id === 0;
                        itemToChange!.changed = true;
                        setDataToUpdate(newDateToUpdate);
                      }}
                      items={dropdownItems}
                      selectedItem={dropdownItems.find((i) => i.isSelected)}
                      disabled={rowData.isAdRole}
                    />
                  );
                },
              },
            ]}
            tableTexts={{
              apply: t("Table:apply"),
              cancel: t("Table:cancel"),
              tableData: t("Table:table_data"),
              rowsPerPage: t("Table:rows_per_page"),
              showing: t("Table:showing"),
              of: t("Table:of"),
              results: t("Table:results"),
              showMore: t("Table:show_more"),
            }}
            hideEmptyMessage
            items={respondentsList}
            showRowHover
            withLazyLoading
            withShowMore
            withRowSelection
            paginatedItems={{
              items: respondentsList,
              pagination: {
                offset: 0,
                limit: respondentsList.length,
                total: respondentsList.length + (showMore ? 1 : 0),
              },
            }}
            onShowMoreClick={() => {
              setPageSettings({
                page: pageSettings.page + 1,
                pageSize: pageSettings.pageSize,
              });
            }}
            rowActionsFixed
            onRowSelectionChange={(selectedItems) => {
              const filtered = selectedItems.filter(
                (i) => !!respondentsList.find((row) => row.id === i.id)
              );
              selectionPopupRef.current?.open(filtered);
            }}
          />
        </>
      )}
    </Accordion>
  );
};
