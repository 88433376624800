import { observer } from "mobx-react";
import { getErrorKey } from "../../../../components/Errors/ErrorAlert";
import { Row, Spacer } from "../../../../components/Layout/Layout";
import {
  deleteCommonContact,
  deletePrivateContact,
  unassociateCommonContact,
} from "../../../../core/api/contacts/contacts";
import useTranslations from "../../../../core/i18n/useTranslations";
import useUser from "../../../../core/user/useUser";
import { notify } from "../../../../ui-lib/components/Alerts/Toast";
import Button from "../../../../ui-lib/components/Button/Button";
import ModalContent from "../../../../ui-lib/components/Popup/ModalContent";
import { useState } from "react";

const DeleteContactModal = ({
  objectId,
  contact,
  onClose,
  onDelete,
}: {
  objectId?: string;
  contact?: {
    id: number;
    userId?: number;
  };
  onClose: () => void;
  onDelete: () => void;
}) => {
  const t = useTranslations();

  const { authenticatedRequest } = useUser();
  const [submitting, setSubmitting] = useState(false);

  const onSubmit = async () => {
    if (!contact) {
      return;
    }

    setSubmitting(true);
    try {
      if (objectId) {
        if (contact.userId) {
          await deletePrivateContact(
            objectId,
            contact.id,
            authenticatedRequest
          );
        } else {
          await unassociateCommonContact(
            objectId,
            contact.id,
            authenticatedRequest
          );
        }
      } else {
        await deleteCommonContact(contact.id, authenticatedRequest);
      }

      notify({
        message: t("Messages:delete_contact_success"),
      });
      onDelete();
    } catch (error) {
      const errorKey = getErrorKey(error);
      notify({
        message: t(`Errors:${errorKey}`),
        variant: "error",
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <ModalContent onClose={onClose} width="576px">
      <h2 className="subTitle">{t("Contacts:delete")}</h2>
      <Spacer size={16} />
      <p style={{ color: "var(--Grey-600)" }}>
        {objectId
          ? t("AlarmHistory:delete_object_contact_confirm")
          : t("Messages:delete_contact_confirm")}
      </p>

      <Spacer size={32} />

      <Row type="left">
        <Button
          variant="destructive"
          text={t("Contacts:delete")}
          onClick={onSubmit}
          loading={submitting}
          disabled={submitting}
        />
        <Spacer size={8} />
        <Button
          variant="secondary"
          text={t("Common:cancel")}
          onClick={onClose}
        />
      </Row>
    </ModalContent>
  );
};

export default observer(DeleteContactModal);
