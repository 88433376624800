import { Namespace, TFunction } from "i18next";
import useTranslations from "../../core/i18n/useTranslations";
import { Column, Row, Spacer } from "../../components/Layout/Layout";
import Icon from "../../ui-lib/components/Icon";
import { IAlternatives } from "../../core/api/alternatives/types";
import Tooltip from "../../ui-lib/components/Tooltip/Tooltip";

const openTypesTranslation = (t: TFunction<Namespace<string>, undefined>) => [
  t("Administrator:link_open_type_0"),
  t("Administrator:link_open_type_1"),
  t("Administrator:link_open_type_2"),
];

const applicationOpenTypes = (t: TFunction<Namespace<string>, undefined>) => [
  "",
  t("Administrator:link_open_type_1"),
  t("Administrator:application_open_type_2"),
];

export const AlternativeCell = ({
  data,
  withOrgRelation = true,
}: {
  data: IAlternatives;
  withOrgRelation?: boolean;
}) => {
  const t = useTranslations();
  const isMobile = !!(data.androidLink || data.iosLink);

  return (
    <Column type="center">
      <Row type="fill">
        {!isMobile ? (
          <>
            <Icon name="link" size={16} color="Grey-300" />
            <Spacer size={8} />
            <span style={{ color: "var(--Grey-300)" }}>{t("Common:url")}:</span>
            <Spacer size={8} />
            <span>{data.url}</span>
          </>
        ) : (
          <>
            {data.iosLink && (
              <Row type="left" style={{ width: "48%" }}>
                <Icon name="link" size={16} color="Grey-300" />
                <Spacer size={8} />
                <span style={{ color: "var(--Grey-300)" }}>
                  {t("Common:ios")}:
                </span>
                <Spacer size={8} />
                <Tooltip
                  text={data.iosLink}
                  trigger={() => (
                    <span
                      style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                    >
                      {data.iosLink}
                    </span>
                  )}
                />
              </Row>
            )}
            <Spacer size={24} />
            {data.androidLink && (
              <Row type="left" style={{ width: "50%" }}>
                <Icon name="link" size={16} color="Grey-300" />
                <Spacer size={8} />
                <span style={{ color: "var(--Grey-300)" }}>
                  {t("Common:android")}:
                </span>
                <Spacer size={8} />
                <Tooltip
                  text={data.iosLink}
                  trigger={() => (
                    <span
                      style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                    >
                      {data.androidLink}
                    </span>
                  )}
                />
              </Row>
            )}
          </>
        )}
      </Row>
      <Spacer size={16} />
      <Row type="fill">
        {withOrgRelation && (
          <Row type="left" style={{ width: "50%" }}>
            <Icon name="hierarchy" size={16} color="Grey-300" />
            <Spacer size={8} />
            <span style={{ color: "var(--Grey-300)" }}>
              {t("Common:belongs_to")}:
            </span>
            <Spacer size={8} />
            <span>{data.belongsTo}</span>
          </Row>
        )}
        <div />
        <Row type="left" style={{ width: "50%" }}>
          <Icon name="external-link" size={16} color="Grey-300" />
          <Spacer size={8} />
          <span style={{ color: "var(--Grey-300)" }}>
            {t("Common:open_in")}:
          </span>
          <Spacer size={8} />
          <span>
            {isMobile
              ? applicationOpenTypes(t)[data.openType]
              : openTypesTranslation(t)[data.openType]}
          </span>
        </Row>
      </Row>
    </Column>
  );
};
