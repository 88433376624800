/* eslint-disable react/jsx-props-no-spreading */
import { Row, Spacer } from "../../../../components/Layout/Layout";
import useTranslations from "../../../../core/i18n/useTranslations";
import { PSTNControl } from "./Controls/PSTNControl";
import { SIPControl } from "./Controls/SIPControl";

export const CallHandlingForm = () => {
  const t = useTranslations();

  return (
    <>
      <h2>{t("AlarmReceptions:call_handling_title")}</h2>
      <p style={{ color: "var(--Grey-600)" }}>
        {t("AlarmReceptions:call_handling_description")}
      </p>

      <Spacer size={16} />

      <Row align="start" style={{ width: "100%" }}>
        <PSTNControl />
      </Row>

      <Spacer size={16} />

      <Row align="start" style={{ width: "100%" }}>
        <SIPControl />
      </Row>
    </>
  );
};
