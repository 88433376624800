import { TFunction } from "i18next";
import moment from "moment";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Column,
  Row,
  Spacer,
  TwoColGrid,
} from "../../components/Layout/Layout";
import { getCameraStatus, saveCamera } from "../../core/api/cameras/cameras";
import {
  CameraConnectionStatus,
  CameraStatus,
  EditCameraModel,
} from "../../core/api/cameras/types";
import { CameraProvisioning } from "../../core/enumerations/enumerations";
import {
  getNonEmptyProps,
  isEmpty,
  notifyApiErrors,
} from "../../core/helpers/helpers";
import { validateIPAddress } from "../../core/helpers/validation";
import useTranslations from "../../core/i18n/useTranslations";
import {
  useObjectForm,
  useObjectFormRef,
} from "../../core/SaveObjectContext/hooks";
import useUser from "../../core/user/useUser";
import Alert from "../../ui-lib/components/Alerts/Alert";
import { notify } from "../../ui-lib/components/Alerts/Toast";
import Badge from "../../ui-lib/components/Badges/Badge";
import Button from "../../ui-lib/components/Button/Button";
import Checkbox from "../../ui-lib/components/Checkbox/Checkbox";
import Divider from "../../ui-lib/components/Divider/Divider";
import Dropdown from "../../ui-lib/components/Dropdown/Dropdown";
import { IDropdownItem } from "../../ui-lib/components/Dropdown/DropdownItem";
import Tree, { ITreeData } from "../../ui-lib/components/Hierarchy/Tree";
import inputStyles from "../../ui-lib/components/Inputs/Input.module.css";
import InputContainer from "../../ui-lib/components/Inputs/InputContainer";
import NumberStepInput from "../../ui-lib/components/Inputs/NumberStepInput";
import TextInput from "../../ui-lib/components/Inputs/TextInput";
import ToggleSwitch from "../../ui-lib/components/Checkbox/ToggleSwitch";

export declare type EditCameraActions = {
  submitForm: () => Promise<SubmitResult>;
  reset: () => void;
};

export interface SubmitResult {
  status: boolean;
  data: number;
}

export interface EditCameraProps {
  camera?: EditCameraModel;
  organisationTree: ITreeData[];
  isNewCamera?: boolean;
}

const regular: number[] = [0, 2, 7];
const gateways: number[] = [3, 5];

const getProvisioningStatusText = (
  t: TFunction,
  provisioning?: CameraProvisioning
): string => {
  switch (provisioning) {
    case CameraProvisioning.NotNeeded:
      return t("Cameras:provisioning_not_needed");
    case CameraProvisioning.Succeeded:
      return t("Cameras:provisioning_succeeded");
    case CameraProvisioning.InProgress:
      return t("Cameras:provisioning_in_progress");
    default:
      return "";
  }
};

const getCameraActive = (status?: CameraConnectionStatus | string): boolean => {
  if (isEmpty(status)) {
    return false;
  }
  if (status) {
    const camStatus = status as CameraConnectionStatus;
    if (camStatus && camStatus.SSH === "True") {
      return true;
    }
  }
  return false;
};

const ProvisioningStatus = ({
  provisioning,
}: {
  provisioning?: CameraProvisioning;
}) => {
  const t = useTranslations();
  const status = getProvisioningStatusText(t, provisioning);

  return (
    <Column type="top" align="fill">
      <Spacer size={16} />
      <span className="smallStrong">{status}</span>
      <Spacer size={16} />
      {provisioning === CameraProvisioning.InProgress && (
        <Alert
          variant="info"
          icon="information-circle"
          title={t("Cameras:provisioning_in_progress_description")}
        />
      )}
    </Column>
  );
};

const DeviceStatus = ({ cameraStatus }: { cameraStatus?: CameraStatus }) => {
  const t = useTranslations();
  const { config } = useUser();

  return (
    <Row
      type="space"
      breakPoint={690}
      responsive
      style={{ backgroundColor: "var(--Grey-50)", padding: "8px" }}
    >
      <Row type="left" align="center">
        <span className={inputStyles.label} style={{ marginRight: "16px" }}>
          {t("Cameras:connection_status")}
        </span>
        {cameraStatus?.status && (
          <Badge
            variant={
              getCameraActive(cameraStatus.status) ? "success" : "secondary"
            }
            title={
              getCameraActive(cameraStatus.status)
                ? t("Cameras:status_active")
                : t("Cameras:status_inactive")
            }
          />
        )}
      </Row>
      <Row type="right" align="center">
        <p>
          <span style={{ color: "var(--Grey-600)" }}>
            {t("Cameras:last_status_update")}
          </span>{" "}
          <span style={{ color: "var(--Grey-800)" }}>
            {cameraStatus?.lastStatusUpdate
              ? moment
                  .utc(cameraStatus?.lastStatusUpdate)
                  .tz(config?.timezone.timezoneName ?? "")
                  .format("YYYY-MM-DD HH:mm:ss")
              : "--"}
          </span>
        </p>
      </Row>
    </Row>
  );
};

export const CameraEditForm = forwardRef<EditCameraActions, EditCameraProps>(
  (props: EditCameraProps, ref) => {
    const { camera, organisationTree, isNewCamera } = props;
    const t = useTranslations();
    const formRef = isNewCamera ? useObjectFormRef() : useRef(null);
    const [cameraStatus, setCameraStatus] = useState<CameraStatus>();
    const { authenticatedRequest } = useUser();
    const [isRefreshingStatus, setIsRefreshingStatus] =
      useState<boolean>(false);
    const [original, setOriginal] = useState<EditCameraModel | undefined>();
    const isNew = useRef<boolean>(camera === undefined);
    const [belongsToId, setBelongsToId] = useState(
      camera?.organizationId ?? undefined
    );
    const [enableShortWindowTime, setEnableShortWindowTime] = useState(
      !!camera?.shortWindowTime
    );
    const getFormModel = (): EditCameraModel => {
      const data: EditCameraModel = {
        externalPort: undefined,
        title: undefined,
        description: undefined,
        organizationId: undefined,
        cameraType: isNew.current ? 3 : undefined,
        cameraAddress: undefined,
        cameraPort: undefined,
        hlsPath: undefined,
        rtmpPath: undefined,
        rtspPath: undefined,
        jpegPath: undefined,
        mjpegPath: undefined,
        webmPath: undefined,
        audioPath: undefined,
        username: undefined,
        password: undefined,
        ssl: false,
        externalId: undefined,
        shortWindowTime: 120,
        ...camera,
      };
      return data;
    };
    const {
      control,
      formState: { errors },
      handleSubmit,
      reset,
      setValue,
      getValues,
      trigger,
    } = isNewCamera
      ? useForm<EditCameraModel>({
          defaultValues: getFormModel(),
        })
      : useObjectForm<EditCameraModel>({
          defaultValues: getFormModel(),
        });

    const cameraTypes: IDropdownItem[] = [];

    [...regular, ...gateways].forEach((val) => {
      cameraTypes.push({
        id: val.toString(),
        name: t(`Cameras:CameraType_${val}`),
        disabled:
          !isNew.current &&
          gateways.indexOf(val) > -1 &&
          gateways.indexOf(original?.cameraType ?? -1) === -1,
        isSelected: val === getValues("cameraType"),
      });
    });

    const isCameraProxy = getValues("cameraType") === 2;

    const setCamera = async (): Promise<any> => {
      try {
        const model = getFormModel();
        reset(model);
        setOriginal(model);
      } catch (error: any) {
        notifyApiErrors(error.response?.data?.errors);
      }
    };

    const validateIp = (value: string): boolean => {
      const isIPAddress = validateIPAddress(value);
      if (!isIPAddress) {
        return value.includes(".") && value.indexOf(".") < value.length - 1;
      }
      return isIPAddress;
    };

    useImperativeHandle<EditCameraActions, EditCameraActions>(ref, () => {
      const actions: EditCameraActions = {
        submitForm: async (): Promise<any> => {
          let success = true;
          let data = {};
          try {
            await handleSubmit(
              async (formData: EditCameraModel): Promise<any> => {
                try {
                  const dirtyProps = getNonEmptyProps(formData);
                  const patchData: any = {};
                  dirtyProps.forEach((prop) => {
                    patchData[prop] = formData[prop];
                  });
                  const result = await saveCamera(
                    patchData,
                    authenticatedRequest,
                    formData.id
                  );
                  data = result;

                  success = true;
                  notify({
                    message: t(
                      `Cameras:${
                        isNewCamera ? "add_success" : "update_success"
                      }`
                    ),
                  });
                } catch (error: any) {
                  notifyApiErrors(error.response?.data?.errors);
                  success = false;
                }
              },
              () => {
                success = false;
              }
            )().catch(() => {
              success = false;
            });
          } catch (error) {
            success = false;
          }
          return { status: success, data };
        },
        reset,
      };
      return actions;
    });

    const loadStatus = async () => {
      if (camera?.id) {
        const status = await getCameraStatus(
          camera?.id?.toString(),
          authenticatedRequest
        );
        setCameraStatus(status.data);
      }
    };

    const checkCameraType = (isRegular = true) => {
      const sectionToCheck = isRegular ? regular : gateways;
      const selectedCameraType = getValues("cameraType");
      if (selectedCameraType === undefined) {
        return -1;
      }
      return sectionToCheck.indexOf(selectedCameraType);
    };

    useEffect(() => {
      setCamera();
      loadStatus();
    }, [camera]);

    useEffect(() => {
      if (isCameraProxy && !enableShortWindowTime) {
        setValue("shortWindowTime", 0);
      }
    }, [getValues("cameraType"), enableShortWindowTime]);

    return (
      <form ref={formRef} style={{ width: "100%" }}>
        <Column type="top">
          <Row
            type="fill"
            align="fill"
            responsive
            breakPoint={1040}
            style={{ marginTop: "16px", alignSelf: "stretch" }}
          >
            <Controller
              name="title"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextInput
                  required
                  label={t("Common:name")}
                  placeholder={t("Cameras:placeholder_name")}
                  value={field.value}
                  onChange={field.onChange}
                  validationError={errors.title && t("Cameras:validation_name")}
                />
              )}
            />
            <Spacer size={16} />
            <Controller
              name="organizationId"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <InputContainer
                  required
                  label={
                    <label htmlFor="addcamera_organisation">
                      {t("Cameras:organisation")}
                    </label>
                  }
                  input={
                    <Tree
                      id="addcamera_organisation"
                      className="stretch"
                      placeholder={t("Common:select_organisation")}
                      validationError={
                        errors.organizationId &&
                        t("Cameras:organisationvalidation")
                      }
                      items={organisationTree}
                      selectedTreeItem={belongsToId ? field.value : undefined}
                      onSelectItem={(data: ITreeData) => {
                        if (data?.key) {
                          setValue(field.name, +data.key);
                          setBelongsToId(+data.key);
                        }
                        trigger(field.name);
                      }}
                    />
                  }
                />
              )}
            />
          </Row>
          <Spacer size={16} />
          <TwoColGrid>
            <Controller
              name="cameraType"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <InputContainer
                  required
                  label={
                    <label htmlFor="addcamera_type">
                      {t("Cameras:create_camera_type")}
                    </label>
                  }
                  input={
                    <Dropdown
                      id="addcamera_type"
                      items={cameraTypes}
                      disabled={isNew.current !== true}
                      placeholder={t("Cameras:select_type")}
                      selectedItem={cameraTypes.find((r) => r.isSelected)}
                      onSelectItem={(item) => {
                        setValue(field.name, +item.id);
                        setValue("shortWindowTime", +item.id === 7 ? 60 : 120);
                        setValue("ssl", +item.id === 7);
                        trigger(field.name);
                      }}
                      invalid={errors.cameraType !== undefined}
                      validationError={
                        errors.cameraType && t("Cameras:validation_type")
                      }
                    />
                  }
                />
              )}
            />
            {checkCameraType() === -1 ? (
              <Controller
                name="externalId"
                control={control}
                rules={{ required: true, maxLength: 45 }}
                render={({ field }) => (
                  <div>
                    <TextInput
                      required
                      dataCy="externalId"
                      label={t("Cameras:labels_externalid")}
                      placeholder={t("Cameras:placeholder_externalid")}
                      value={field.value}
                      onChange={field.onChange}
                      validationError={
                        errors.externalId &&
                        (errors.externalId.type === "maxLength"
                          ? t("Errors:max_chars_exceeded").replace("{0}", "45")
                          : t("Cameras:validation_externalid"))
                      }
                    />
                    <p
                      style={{
                        color: "var(--Grey-500)",
                        height: 0,
                        fontSize: "12px",
                        letterSpacing: "0.2px",
                        lineHeight: "20px",
                      }}
                    >
                      {t("Cameras:externalId_description")}
                    </p>
                  </div>
                )}
              />
            ) : (
              <div style={{ width: "100%" }} />
            )}
          </TwoColGrid>
          {isCameraProxy ? (
            <>
              <Spacer size={16} />
              <Divider />
              <Spacer size={16} />
              <Column align="start" style={{ width: "100%" }}>
                <Row>
                  <ToggleSwitch
                    checked={enableShortWindowTime}
                    onChange={() => {
                      setEnableShortWindowTime(!enableShortWindowTime);
                      setValue("shortWindowTime", 120);
                    }}
                    name="enableShortWindowTime"
                    id="enableShortWindowTime"
                    label={t("Cameras:toggle_short_window_time")}
                    color="var(--Grey-600)"
                  />
                </Row>
                {enableShortWindowTime && (
                  <>
                    <Spacer size={16} />
                    <Row>
                      <div style={{ width: "200px" }}>
                        <Controller
                          name="shortWindowTime"
                          control={control}
                          render={({ field }) => (
                            <NumberStepInput
                              label={t("Cameras:labels_shortwindowtime")}
                              placeholder={t(
                                "Cameras:placeholder_shortwindowtime"
                              )}
                              value={field.value?.toString()}
                              onChange={field.onChange}
                              validationError={
                                errors.shortWindowTime &&
                                t("Cameras:validation_shortwindowtime")
                              }
                            />
                          )}
                        />
                      </div>
                    </Row>
                  </>
                )}
              </Column>
              <Spacer size={16} />
              <Divider />
              <Spacer size={16} />
            </>
          ) : (
            <>
              <Spacer size={16} />
              <TwoColGrid>
                <div style={{ width: "200px" }}>
                  <Controller
                    name="shortWindowTime"
                    control={control}
                    render={({ field }) => (
                      <NumberStepInput
                        label={t("Cameras:labels_shortwindowtime")}
                        placeholder={t("Cameras:placeholder_shortwindowtime")}
                        value={field.value?.toString()}
                        onChange={field.onChange}
                        validationError={
                          errors.shortWindowTime &&
                          t("Cameras:validation_shortwindowtime")
                        }
                      />
                    )}
                  />
                </div>
              </TwoColGrid>
              <Spacer size={16} />
            </>
          )}
          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <TextInput
                dataCy="description"
                label={t("Cameras:labels_description")}
                placeholder={t("Cameras:placeholder_description")}
                value={field.value}
                onChange={field.onChange}
                validationError={
                  errors.description && t("Cameras:validation_description")
                }
              />
            )}
          />
          {checkCameraType() > -1 && (
            <>
              <Spacer size={16} />
              <Divider />
              <Spacer size={16} />
              <TwoColGrid>
                <Controller
                  name="ssl"
                  control={control}
                  render={({ field }) => (
                    <Row style={{ justifyContent: "flex-start" }}>
                      <Checkbox
                        dataCy="ssl"
                        checked={field.value}
                        onChange={field.onChange}
                        style={{ padding: 0 }}
                        label={t("Cameras:inputs_ssl")}
                        disabled={getValues("cameraType") === 7}
                      />
                    </Row>
                  )}
                />
              </TwoColGrid>
              <Spacer size={16} />
              <TwoColGrid>
                <Controller
                  name="cameraAddress"
                  control={control}
                  rules={{ required: true, validate: validateIp as any }}
                  render={({ field }) => (
                    <TextInput
                      required
                      dataCy="cameraAddress"
                      label={t("Cameras:labels_adress")}
                      placeholder={t("Cameras:placeholder_adress")}
                      value={field.value}
                      onChange={field.onChange}
                      validationError={
                        errors.cameraAddress &&
                        ((errors.cameraAddress.type === "required" &&
                          t("Cameras:validation_adress")) ||
                          t("Cameras:validation_ip_format"))
                      }
                    />
                  )}
                />
                <Controller
                  name="cameraPort"
                  control={control}
                  rules={{
                    required: true,
                    validate: () => Number(getValues("cameraPort")) > 0,
                  }}
                  render={({ field }) => (
                    <TextInput
                      required
                      dataCy="cameraPort"
                      type="number"
                      label={t("Cameras:labels_port")}
                      placeholder={t("Cameras:placeholder_port")}
                      value={field.value?.toString()}
                      onChange={(e, val: string) => {
                        if (!isEmpty(val)) {
                          setValue(field.name, +val);
                        } else {
                          setValue(field.name, undefined);
                        }
                        trigger(field.name);
                      }}
                      validationError={
                        errors.cameraPort &&
                        ((errors.cameraPort.type === "required" &&
                          t("Cameras:validation_port")) ||
                          t("Cameras:validation_port_positive"))
                      }
                    />
                  )}
                />
              </TwoColGrid>
              <Spacer size={16} />
              <Divider />
              <Spacer size={16} />
              {camera?.externalPort && (
                <TextInput
                  dataCy="cameraExternalPort"
                  value={`${camera?.externalPort}`}
                  disabled
                  label={t("Cameras:labels_externalport")}
                />
              )}
            </>
          )}
          {!isNew.current && (
            <>
              <Spacer size={32} />
              <Divider />
              <Spacer size={32} />
            </>
          )}
          {!isNew.current && checkCameraType() === -1 && (
            <>
              <Column align="fill" style={{ alignSelf: "stretch" }}>
                <h2>{t("Cameras:status_details_title")}</h2>
                <p>{t("Cameras:status_details_description")}</p>
                <Spacer size={16} />
              </Column>
              <Column type="fill" align="fill">
                {cameraStatus && (
                  <>
                    <Row
                      style={{
                        backgroundColor: "var(--Grey-50)",
                        padding: "8px",
                      }}
                    >
                      <InputContainer
                        label={
                          <span className="labelRowLabel">
                            {t("Cameras:labels_external_configuration")}
                          </span>
                        }
                        input={
                          <ProvisioningStatus
                            provisioning={cameraStatus.provisioning}
                          />
                        }
                      />
                    </Row>
                    <Spacer size={16} />
                    <DeviceStatus cameraStatus={cameraStatus} />
                    <Spacer size={16} />
                    <div>
                      <Button
                        loading={isRefreshingStatus}
                        variant="secondary"
                        image="refresh"
                        text={t("Cameras:buttons_refresh_connectivity")}
                        onClick={async () => {
                          setIsRefreshingStatus(true);
                          await loadStatus();
                          setIsRefreshingStatus(false);
                        }}
                      />
                    </div>
                  </>
                )}
              </Column>
            </>
          )}
          {checkCameraType() > -1 && (
            <>
              <Column align="fill" style={{ alignSelf: "stretch" }}>
                <Spacer size={16} />
                <h2>{t("Cameras:advanced_details_title")}</h2>
                <p>{t("Cameras:advanced_details_description")}</p>
                <Spacer size={16} />
              </Column>
              <Row type="fill" align="fill" style={{ alignSelf: "stretch" }}>
                <Controller
                  name="mjpegPath"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      type="text"
                      label={
                        getValues("cameraType") === 7
                          ? t("Cameras:labels_mjpeg").replace("MJPEG p", "P")
                          : t("Cameras:labels_mjpeg")
                      }
                      placeholder={t("Cameras:placeholder_mjpeg")}
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />
              </Row>
              <Row
                type="fill"
                align="fill"
                style={{ marginTop: "16px", alignSelf: "stretch" }}
              >
                <Controller
                  name="username"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      type="text"
                      label={t("Common:username")}
                      placeholder={t("Cameras:placeholder_username")}
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />

                <Spacer size={16} />

                <Controller
                  name="password"
                  control={control}
                  rules={{
                    minLength: 1,
                  }}
                  render={({ field }) => (
                    <TextInput
                      autoComplete="new-password"
                      type="password"
                      label={t("Cameras:labels_password")}
                      placeholder={t("Cameras:placeholder_password")}
                      value={field.value}
                      onChange={field.onChange}
                      validationError={
                        errors.password && t("Errors:password_format")
                      }
                    />
                  )}
                />
              </Row>
              {!isNewCamera &&
                (getValues("password") || getValues("username")) && (
                  <Row type="fill" align="fill" style={{ marginTop: "16px" }}>
                    <Button
                      variant="link"
                      text={t("Cameras:buttons_clear_user_pass")}
                      onClick={() => {
                        setValue("password", undefined);
                        setValue("username", undefined);
                      }}
                    />
                  </Row>
                )}
            </>
          )}
        </Column>
        <Spacer size={16} />
      </form>
    );
  }
);

CameraEditForm.displayName = "CameraEditForm";

CameraEditForm.defaultProps = {
  camera: undefined,
  isNewCamera: false,
};
