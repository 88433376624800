import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction,
} from "mobx";
import { BrandingContent } from "./types";
import RootStore from "../RootStore";
import BrandingService from "./BrandingService";
import { Dictionary } from "../interfaces/Dictionary";

class BrandingStore {
  currentDomain: string;

  brandingContent: BrandingContent | undefined;

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.currentDomain = "default";
    this.brandingContent = undefined;

    makeObservable(this, {
      currentDomain: observable,
      brandingContent: observable,
      currentAboutContent: computed,
      currentTitle: computed,
      currentFavicon: computed,
      currentHomeImage: computed,
      currentPartnerLink: computed,
      customerKey: computed,
      currentCss: computed,
      currentLoginLanguage: computed,
      currentSecondaryLanguages: computed,
      isForgotPasswordEnabled: computed,
      getBranding: action,
    });
  }

  async getBranding() {
    const brandingResponse = await BrandingService.GetBranding();
    runInAction(() => {
      this.brandingContent = brandingResponse.content;
      this.currentDomain = brandingResponse.domain;
    });
  }

  get isForgotPasswordEnabled() {
    if (this.brandingContent !== undefined) {
      return this.brandingContent.brands[this.customerKey].enableForgotPassword;
    }
    return undefined;
  }

  get customerKey() {
    const customerName =
      this.brandingContent?.domainsBrandMap[this.currentDomain];

    return customerName ?? "default";
  }

  get currentAboutContent() {
    if (!this.brandingContent) {
      return undefined;
    }

    const about =
      this.brandingContent.brands[this.customerKey].about ??
      this.brandingContent.brands.default.about;

    const title = this.currentTitle;

    if (!about || !title) {
      return undefined;
    }

    const parsed: Dictionary<string> = {};
    Object.keys(about).forEach((key) => {
      parsed[key] = about[key].replaceAll("%%%title%%%", title);
    });

    return parsed;
  }

  get currentTitle() {
    if (this.brandingContent !== undefined) {
      return this.brandingContent.brands[this.customerKey].title;
    }
    return undefined;
  }

  get currentFavicon() {
    if (this.brandingContent !== undefined) {
      return this.brandingContent.brands[this.customerKey].ico;
    }
    return undefined;
  }

  get currentHomeImage() {
    if (this.brandingContent !== undefined) {
      return this.brandingContent.brands[this.customerKey].homeImage;
    }
    return undefined;
  }

  get currentPartnerLink() {
    if (this.brandingContent !== undefined) {
      return this.brandingContent.brands[this.customerKey].partnerLink;
    }
    return undefined;
  }

  get currentLogo() {
    if (this.brandingContent !== undefined) {
      return this.brandingContent.brands[this.customerKey].logo;
    }
    return undefined;
  }

  get currentLogoSize() {
    if (this.brandingContent !== undefined) {
      return this.brandingContent.brands[this.customerKey].logoSize;
    }
    return undefined;
  }

  get currentCss() {
    if (this.brandingContent !== undefined) {
      return this.brandingContent.brands[this.customerKey].css;
    }
    return undefined;
  }

  get currentLoginLanguage() {
    if (this.brandingContent !== undefined) {
      return this.brandingContent.brands[this.customerKey].loginLanguage;
    }
    return undefined;
  }

  get currentSecondaryLanguages() {
    if (this.brandingContent !== undefined) {
      return this.brandingContent.brands[this.customerKey].secondaryLanguages;
    }
    return undefined;
  }
}

export default BrandingStore;
