import { useEffect, useState } from "react";
import classNames from "classnames";
import Collapse from "react-css-collapse";
import Icon from "../../../Icon";
import useTranslations from "../../../../../core/i18n/useTranslations";
import { SubNavLink } from "../../../../../core/interfaces/SubNav";
import { SideBarLink } from "../SideBarLink";

import styles from "./CollapsibleSubItem.module.css";

export const CollapsibleSubItem = ({
  title,
  subItems,
}: {
  title: string;
  subItems?: SubNavLink[];
}) => {
  const t = useTranslations();
  const [isShown, setIsShown] = useState<boolean>(false);
  const [isSubLinkActive, setIsSubLinkActive] = useState<boolean>(false);

  useEffect(() => {
    if (isSubLinkActive) {
      setIsShown(true);
    }
  }, [isSubLinkActive]);

  return (
    <div>
      <button
        className={classNames(styles.collapsibleItem, {
          [styles.collapsibleTitleWrapperOpen]: isSubLinkActive,
        })}
        onClick={() => {
          setIsShown(!isShown);
        }}
        onKeyDown={(e) => {
          if (e.code === "Enter" || e.code === "Space") {
            e.preventDefault();
            setIsShown(!isShown);
          }
        }}
      >
        <div className={styles.collapsibleTitleWrapper}>
          <span>{title}</span>
          <Icon
            className={styles.chevronIcon}
            color={isSubLinkActive ? "Primary-700" : undefined}
            name={isShown ? "chevron-up" : "chevron-down"}
            size={18}
          />
        </div>
      </button>
      <Collapse isOpen={isShown} aria-hidden={isShown.toString()}>
        {subItems?.map((el) => (
          <SideBarLink
            withSpace
            // Key that contained in subpage path
            watchKey={el.watchKey}
            key={el.key}
            text={t(`${el.key}`)}
            to={el.to}
            setIsSubLinkActive={setIsSubLinkActive}
          />
        ))}
      </Collapse>
    </div>
  );
};
