import AlarmHistory from "../../AlarmHistory/AlarmHistory";
import { Spacer } from "../../../components/Layout/Layout";
import useTranslations from "../../../core/i18n/useTranslations";

const ObjectAlarmHistory = ({ objectId }: { objectId: string }) => {
  const t = useTranslations();

  const sectionsToHide = [
    "headerExport",
    "contentWrapper",
    "customFilters",
    "tableSearch",
  ];

  return (
    <>
      <h1>{t("Objects:Menu_AlarmHistory")}</h1>
      <Spacer size={8} />
      <p>{t("Objects:alarm_history_description")}</p>
      <Spacer size={32} />

      <AlarmHistory objectId={objectId} sectionsToHide={sectionsToHide} />
    </>
  );
};

export default ObjectAlarmHistory;
