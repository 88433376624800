export const addPrivateContactToStore = (
  contactId: number,
  pathToObject: string
) => {
  const privateContacts = JSON.parse(
    localStorage.getItem("privateContacts") || "{}"
  );
  privateContacts[contactId] = pathToObject;
  localStorage.setItem("privateContacts", JSON.stringify(privateContacts));
};

export const getPrivateContactFromStore = (contactId: number) => {
  const privateContacts = JSON.parse(
    localStorage.getItem("privateContacts") || "{}"
  );
  return privateContacts[contactId];
};
