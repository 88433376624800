import { useEffect, useRef, useState } from "react";
import { notifyApiErrors } from "../../../../core/helpers/helpers";
import { getRespondentGroupsList } from "../../../../core/api/responsecenters/responsecenters";
import { Column, Row, Spacer } from "../../../../components/Layout/Layout";
import { CreateRespondentGroupModal } from "./CreateRespondentGroupModal";
import { DeleteRespondentGroupModal } from "./DeleteRespondentGroupModal";
import { userespondentGroupId } from "../../../../core/hooks/modalIdsHandlers/useRespondentGroupID";
import { usePageFilter } from "../../../../core/hooks/filters/usePageFilter";
import { useQueryState } from "../../../../core/hooks/filters/useQueryState";
import TableRowSelectionPopup, {
  TableRowSelectionPopupActions,
} from "../../../../ui-lib/components/Tables/TableRowSelectionPopup";
import {
  AlarmReceptionGroup,
  IArcGroups,
  ResponseCenterDetails,
} from "../../../../core/api/responsecenters/types";
import Button from "../../../../ui-lib/components/Button/Button";
import useTranslations from "../../../../core/i18n/useTranslations";
import useUser from "../../../../core/user/useUser";
import LoadingSpinner from "../../../../ui-lib/components/Loading/LoadingSpinner";
import Table, { TableColumn } from "../../../../ui-lib/components/Tables/Table";
import TableCell from "../../../../ui-lib/components/Tables/TableCell";
import SearchInput from "../../../../ui-lib/components/Inputs/SearchInput";

import layoutStyles from "../../../Layout.module.css";

const columns = (
  t: (key: string) => string
): TableColumn<AlarmReceptionGroup>[] => [
  {
    header: t("AlarmReceptions:distribution_table_column_name"),
    fieldTemplate: (rowData) => <TableCell value={rowData.description} />,
  },
];

export const RespondentGroupsTable = ({
  receptionData,
}: {
  receptionData: ResponseCenterDetails;
}) => {
  const t = useTranslations();
  const { authenticatedRequest } = useUser();
  const { pageFilter, setPageFilter, resetPageFilter } = usePageFilter();
  const [searchParam, setSearchParam] = useQueryState<{ q: string }>({
    q: "",
  });
  const { respondentGroupId, setRespondentGroupId, resetRespondentGroupId } =
    userespondentGroupId();

  const selectionPopupRef =
    useRef<TableRowSelectionPopupActions<AlarmReceptionGroup>>(null);

  const [upForDelete, setUpForDelete] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState<IArcGroups>();
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [selected, setSelected] = useState<{ id?: number }>({
    id: respondentGroupId.respondentGroupId,
  });

  const forceLoginOptions = [
    {
      id: "",
      description: t("AlarmReceptions:not_set"),
    },
    ...(receptionData?.respondentGroups || []),
  ];

  useEffect(() => {
    setIsCreateModalOpen(!!selected?.id);
  }, [selected]);

  const fetchTableData = async () => {
    setIsLoading(true);
    try {
      const result = await getRespondentGroupsList(
        receptionData.id,
        {
          page: pageFilter.page,
          pageSize: pageFilter.pageSize,
          external: false,
          search: searchParam.q,
        },
        authenticatedRequest
      );
      setTableData(result);
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    } finally {
      setIsLoading(false);
    }
  };

  const closeCreateModal = (complete = true) => {
    if (complete) {
      setIsCreateModalOpen(false);
      setSelected({ id: undefined });
    }
    setIsFormSubmitting(false);
    resetRespondentGroupId();
  };

  useEffect(() => {
    fetchTableData();
  }, [pageFilter, searchParam.q]);

  return (
    <>
      <CreateRespondentGroupModal
        submitting={isFormSubmitting}
        isCreateModalOpen={isCreateModalOpen}
        onClose={closeCreateModal}
        onSubmit={resetPageFilter}
        groupId={selected?.id}
        forceLoginOptions={forceLoginOptions}
        arcId={receptionData.id!}
        setSubmitButtonState={setIsFormSubmitting}
        isPublic={!!receptionData.public}
      />
      <TableRowSelectionPopup
        ref={selectionPopupRef}
        renderBody={(selectedItems) => (
          <Row>
            <Spacer size={16} />
            <Button
              variant="destructive"
              text={`${t("Common:delete_selection")} (${selectedItems.length})`}
              onClick={() => {
                setUpForDelete(selectedItems.map((i) => i.id as any));
                setDeleteOpen(true);
              }}
            />
          </Row>
        )}
      />
      <DeleteRespondentGroupModal
        arcId={receptionData.id!}
        isOpen={deleteOpen}
        ids={upForDelete}
        onClose={() => setDeleteOpen(false)}
        onDelete={() => {
          setDeleteOpen(false);
          selectionPopupRef.current?.close(true);
          resetPageFilter();
        }}
      />
      <Row style={{ width: "100%", flex: 0 }}>
        <h2>{t("AlarmReceptions:respondent_groups")}</h2>
        <Spacer />

        <Button
          size="small"
          variant="secondary"
          image="user-group"
          text={t("AlarmReceptions:add_respondent_group")}
          onClick={() => setIsCreateModalOpen(true)}
        />
      </Row>
      <Spacer size={16} />
      <SearchInput
        limit={1}
        placeholder={t("AlarmReceptions:respondent_groups_placeholder")}
        value={searchParam.q}
        onChange={(value: string) => {
          if (searchParam.q !== value) {
            setTableData(undefined);
            resetPageFilter();
          }
          setSearchParam({ q: value });
        }}
      />
      <Spacer size={34} />
      <Column className={layoutStyles.tableContainer} type="top">
        {isLoading ? (
          <LoadingSpinner theme="primary" />
        ) : (
          <Table<AlarmReceptionGroup>
            hideEmptyMessage
            showRowHover
            withLazyLoading
            withRowSelection
            rowActionsFixed
            withPagination
            columns={columns(t)}
            items={tableData?.respondentGroups || []}
            paginatedItems={{
              items: tableData?.respondentGroups || [],
              pagination: {
                offset: pageFilter.pageSize * (pageFilter.page - 1),
                limit: pageFilter.pageSize,
                total: tableData?.total || 0,
              },
            }}
            onPageChange={(nextPage) => {
              const page = Math.floor(nextPage.offset / nextPage.limit);

              if (!Number.isNaN(page) && nextPage.limit) {
                setPageFilter({
                  page: page + 1,
                  pageSize: nextPage.limit,
                });
              }
            }}
            rowActions={[
              {
                icon: "pencil-alt",
                text: t("Common:label_edit"),
                onClick: (rowData: AlarmReceptionGroup) => {
                  setSelected({ id: rowData.id });
                  setRespondentGroupId({ respondentGroupId: rowData.id });
                },
              },
              {
                icon: "trash",
                text: t("Common:delete"),
                iconVariant: "secondary",
                onClick: (rowData: AlarmReceptionGroup) => {
                  setUpForDelete([rowData.id]);
                  setDeleteOpen(true);
                },
              },
            ]}
            onRowSelectionChange={(selectedItems) => {
              const filtered = selectedItems.filter(
                (i) =>
                  !!tableData?.respondentGroups.find((row) => row.id === i.id)
              );
              selectionPopupRef.current?.open(filtered);
            }}
          />
        )}
      </Column>
    </>
  );
};
