import { IIdentity } from "../../../../core/api/identities/types";
import { Column, Row, Spacer } from "../../../../components/Layout/Layout";
import useTranslations from "../../../../core/i18n/useTranslations";
import TextInput from "../../../../ui-lib/components/Inputs/TextInput";

export const IdentityGeneral = ({ data }: { data: IIdentity }) => {
  const t = useTranslations();

  return (
    <>
      <Column style={{ width: "784px" }} type="top" align="start">
        <h1>{t("Administrator:general_title")}</h1>
        <Spacer size={8} />
        <p style={{ color: "var(--Grey-600)" }}>
          {t("Administrator:general_description")}
        </p>

        <Spacer size={32} />

        <div style={{ width: "100%" }}>
          <Row align="start">
            <Column style={{ width: "100%" }}>
              <TextInput
                label={t("Common:name")}
                value={`${data.firstName} ${data.lastName}`}
                disabled
                onChange={() => {}}
              />
            </Column>
            <Spacer size={32} />
            <Column style={{ width: "100%" }}>
              <TextInput
                label={t("Common:Telephone")}
                value={data.telephoneNumber}
                onChange={() => {}}
                disabled
              />
            </Column>
          </Row>

          <Spacer size={16} />

          <Row align="start">
            <Column style={{ width: "100%" }}>
              <TextInput
                label={t("Common:email")}
                value={data.upn}
                disabled
                onChange={() => {}}
              />
            </Column>
            <Spacer size={32} />
            <Column style={{ width: "100%" }}>
              <TextInput
                label={t("Common:belongs_to")}
                value={data.userpool}
                onChange={() => {}}
                disabled
              />
            </Column>
          </Row>

          <Spacer size={16} />

          <Row align="start">
            <Column style={{ width: "100%" }}>
              <TextInput
                label={t("Administrator:general_input_initials")}
                value={data.initials}
                disabled
                onChange={() => {}}
              />
            </Column>
          </Row>
        </div>
      </Column>
    </>
  );
};
