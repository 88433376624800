import { observer } from "mobx-react-lite";
import { AddressDetails } from "../../../../core/api/addresses/types";
import useUser from "../../../../core/user/useUser";
import { addAddress } from "../../../../core/api/addresses/addresses";

import { notify } from "../../../../ui-lib/components/Alerts/Toast";

import { AddressForm } from "./AddressForm";
import useTranslations from "../../../../core/i18n/useTranslations";
import { notifyApiErrors } from "../../../../core/helpers/helpers";

const AddAddressModal = ({
  objectId,
  onClose,
  onAdd,
}: {
  objectId: string;
  onClose: () => void;
  onAdd: () => void;
}) => {
  const t = useTranslations();

  const { authenticatedRequest } = useUser();

  const onSubmit = async (addressDetails: AddressDetails) => {
    try {
      addressDetails = {
        ...addressDetails,
        latitude: addressDetails.latitude!
          ? +addressDetails.latitude
          : undefined,
        longitude: addressDetails.longitude!
          ? +addressDetails.longitude
          : undefined,
      };
      await addAddress(objectId, addressDetails, authenticatedRequest);
      notify({
        message: t("Messages:add_address_success"),
      });
      onAdd();
      onClose();
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    }
  };

  return (
    <AddressForm
      labels={{
        title: t("Objects:address_add"),
        description: t("Objects:address_add_description"),
        submit: t("Objects:address_add"),
      }}
      onSubmit={onSubmit}
      onClose={onClose}
    />
  );
};

export default observer(AddAddressModal);
