import BrandingStore from "./branding/BrandingStore";
import LanguageStore from "./i18n/LanguageStore";
import PageStateStore from "./pagestate/PagestateStore";

class RootStore {
  brandingStore: BrandingStore;

  languageStore: LanguageStore;

  pageStateStore: PageStateStore;

  constructor() {
    this.brandingStore = new BrandingStore(this);
    this.languageStore = new LanguageStore(this);
    this.pageStateStore = new PageStateStore(this);
  }
}

export default RootStore;
