import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  AlarmClassifications,
  getClassifications,
  saveClassification,
} from "../../../core/api/alarmDetail";
import useTranslations from "../../../core/i18n/useTranslations";
import LoadingSpinner from "../../../ui-lib/components/Loading/LoadingSpinner";
import { notifyApiErrors } from "../../../core/helpers/helpers";
import useUser from "../../../core/user/useUser";
import { Row, Spacer } from "../../../components/Layout/Layout";
import Dropdown from "../../../ui-lib/components/Dropdown/Dropdown";
import TextArea from "../../../ui-lib/components/Inputs/Textarea";
import Button from "../../../ui-lib/components/Button/Button";
import { notify } from "../../../ui-lib/components/Alerts/Toast";

export const AdditionalClassificationModal = ({
  alarmId,
  onClose,
}: {
  alarmId: string;
  onClose: () => void;
}) => {
  const t = useTranslations();
  const { authenticatedRequest } = useUser();
  const [classificationData, setClassificationData] =
    useState<AlarmClassifications>();
  const [isLoading, setIsLoading] = useState(false);

  const { control, setValue, handleSubmit, reset } =
    useForm<AlarmClassifications>();

  const fetchClassificationData = async () => {
    try {
      setIsLoading(true);
      const result = await getClassifications(alarmId, authenticatedRequest);
      setClassificationData(result.data as AlarmClassifications);
      reset({
        comment: result.data?.comment,
        classification: result.data?.currentClassification
          ? [`${result.data.currentClassification[0]}`]
          : undefined,
      });
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmit = async (values: AlarmClassifications) => {
    try {
      setIsLoading(true);

      const updatedValues = { ...values };
      if (
        !updatedValues.classification ||
        !updatedValues.classification![0].length
      ) {
        updatedValues.classification = [];
      }

      await saveClassification(alarmId, updatedValues, authenticatedRequest);
      onClose();
      notify({
        message: t("AlarmHistory:classification_saved"),
      });
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchClassificationData();
  }, []);

  return isLoading || !classificationData ? (
    <LoadingSpinner theme="primary" />
  ) : (
    <form
      onSubmit={handleSubmit(async (values) => {
        await onSubmit(values);
      })}
    >
      <p>{t("AlarmHistory:classification_modal_subtitle")}</p>
      <Spacer size={16} />
      <p>{t("AlarmHistory:transmitter_identifier")}</p>
      <p>{classificationData.triggerIdentifier}</p>
      <Spacer size={16} />

      <Controller
        name="classification"
        control={control}
        render={({ field }) => {
          const dropdownItems =
            classificationData?.availableClassifications.map((item) => ({
              id: item[0],
              name: item[0]
                ? t(`CommonEnum:AlarmFlagEnum${item[0]}`)
                : t(
                    "Administrator:general_account_security_input_auth_type_none"
                  ),
              // eslint-disable-next-line no-nested-ternary
              isSelected: field?.value
                ? field.value[0].length
                  ? `${item[0]}` === field.value[0]
                  : !item[0]
                : !item[0],
            }));

          return (
            <Dropdown
              title={t("AlarmHistory:additional_classification")}
              width="100%"
              selectedItem={dropdownItems.find((i) => i.isSelected)}
              items={dropdownItems}
              onSelectItem={(item) => {
                setValue(field.name, [item.id ? `${item.id}` : ""]);
              }}
              disabled={dropdownItems.length === 1}
              maxVisible={10}
            />
          );
        }}
      />
      <Spacer size={16} />
      <Controller
        name="comment"
        control={control}
        render={({ field }) => (
          <TextArea
            label={t(
              "AlarmReceptions:advanced_settings_addintegration_comment_label"
            )}
            placeholder={t(
              "AlarmReceptions:advanced_settings_addintegration_comment_placeholder"
            )}
            value={field.value}
            onChange={field.onChange}
          />
        )}
      />
      <Spacer size={32} />
      <Row type="left">
        <Button
          loading={isLoading}
          variant="primary"
          disabled={isLoading}
          text={t("Common:save")}
          type="submit"
        />
        <Spacer size={8} />
        <Button
          variant="secondary"
          text={t("Common:cancel")}
          onClick={onClose}
        />
      </Row>
    </form>
  );
};
