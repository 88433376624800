import classnames from "classnames";
import { CSSProperties } from "react";
import styles from "./Panel.module.css";

export type PanelPadding = number;

export interface ExtendedPadding {
  top: PanelPadding;
  right: PanelPadding;
  bottom: PanelPadding;
  left: PanelPadding;
}
export interface PanelProps {
  children: React.ReactNode;
  className?: string;
  padding?: ExtendedPadding;
  style?: CSSProperties;
}
export default function Panel({
  children,
  className,
  padding,
  style,
}: PanelProps) {
  let paddingDefinition = "24px";
  if (padding !== undefined) {
    paddingDefinition = `${padding.top}px ${padding.right}px ${padding.bottom}px ${padding.left}px `;
  }
  return (
    <div
      className={classnames(styles.container, className)}
      style={{ ...style, padding: paddingDefinition }}
    >
      {children}
    </div>
  );
}
