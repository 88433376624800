import { useRef } from "react";
import classNames from "classnames";
import Tooltip from "../Tooltip/Tooltip";
import Icon from "../Icon";
import inputStyles from "./Input.module.css";

type OnChangeFunction = (value: string) => void;

export interface NumberStepInputProps {
  label?: string;
  placeholder?: string;
  hint?: string;
  required?: boolean;
  disabled?: boolean;
  description?: string;
  validationError?: string;
  value?: string;
  onChange?: OnChangeFunction;
  min?: number;
  max?: number;
  step?: number;
}

/** Text input for numbers with custom step buttons.
 * NB: Input value provided as string. */
export const NumberStepInput = ({
  label = "",
  placeholder = "",
  hint = "",
  required = false,
  disabled = false,
  description = "",
  validationError = undefined,
  value = "",
  onChange = () => {},
  min = undefined,
  max = undefined,
  step = 1,
}: NumberStepInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const isIncrementDisabled =
    value !== undefined && value !== "" && +value === max;
  const isDecrementDisabled =
    value !== undefined && value !== "" && +value === min;

  return (
    <div className={inputStyles.inputContainer}>
      <div className={inputStyles.labelRow}>
        {required && <span className={inputStyles.requiredIcon}>*</span>}
        <label>{label}</label>
        {hint ? (
          <Tooltip
            trigger={() => (
              <span
                className={classNames({
                  [inputStyles.labelHint]: true,
                })}
              >
                <Icon name="information-circle" size={16} />
              </span>
            )}
            position="right center"
            text={hint}
          />
        ) : null}
      </div>
      <div
        className={classNames({
          [inputStyles.inputRow]: true,
          [inputStyles.inputRowStepButtons]: true,
        })}
      >
        <input
          ref={inputRef}
          type="number"
          placeholder={placeholder}
          required={required}
          disabled={disabled}
          value={value}
          onChange={(e) => {
            let rawValue = e.target.value;

            // validate min/max
            if (rawValue !== "" && min !== undefined) {
              rawValue = Math.max(+rawValue, min).toString();
            }
            if (rawValue !== "" && max !== undefined) {
              rawValue = Math.min(+rawValue, max).toString();
            }

            onChange(rawValue);
          }}
          className={classNames({
            [inputStyles.inputValidationError]: !!validationError,
            [inputStyles.disableNumberArrows]: true,
          })}
          min={min}
          max={max}
          step={step}
        />
        <button
          type="button"
          className={classNames({
            [inputStyles.numberInputStepButton]: true,
            [inputStyles.numberInputStepButtonLeft]: true,
            [inputStyles.numberInputStepButtonDisabled]: disabled,
          })}
          aria-label="Lower"
          onClick={() => {
            if (!disabled) {
              let number = +value;
              number -= 1;
              if (min !== undefined) {
                number = Math.max(number, min);
              }
              // inputRef.current?.focus();
              onChange(number.toString());
            }
          }}
          disabled={isDecrementDisabled}
        >
          <Icon
            name="chevron-down"
            size={16}
            color={isDecrementDisabled ? "Grey-300" : "grey"}
          />
        </button>
        <button
          type="button"
          className={classNames({
            [inputStyles.numberInputStepButton]: true,
            [inputStyles.numberInputStepButtonRight]: true,
            [inputStyles.numberInputStepButtonDisabled]: disabled,
          })}
          aria-label="Higher"
          onClick={() => {
            if (!disabled) {
              let number = +value;
              number += 1;
              if (max !== undefined) {
                number = Math.min(number, max);
              }
              // inputRef.current?.focus();
              onChange(number.toString());
            }
          }}
          disabled={isIncrementDisabled}
        >
          <Icon
            name="chevron-up"
            size={16}
            color={isIncrementDisabled ? "Grey-300" : "grey"}
          />
        </button>
      </div>
      <div className={inputStyles.description}>{description}</div>
      {validationError ? (
        <div className={inputStyles.validationError}>
          <Icon name="exclamation-circle" size={14} />
          {validationError}
        </div>
      ) : null}
    </div>
  );
};
export default NumberStepInput;
