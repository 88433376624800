import { useRef, useState } from "react";
import { UserAdminResponse } from "../../../../core/api/useradmins/types";
import useTranslations from "../../../../core/i18n/useTranslations";
import Modal, { ModalActions } from "../../../../ui-lib/components/Popup/Modal";
import Table from "../../../../ui-lib/components/Tables/Table";
import TableCell from "../../../../ui-lib/components/Tables/TableCell";
import DeleteRelatedAdminModal from "./DeleteRelatedAdminModal";

export const RelatedAdminTable = ({
  userAdmins,
  onAdminUpdate,
}: {
  userAdmins: UserAdminResponse[];
  onAdminUpdate: () => void;
}) => {
  const t = useTranslations();

  const deleteModalRef = useRef<ModalActions>(null);
  const [upForDelete, setUpForDelete] = useState<UserAdminResponse>();

  return (
    <>
      <Table<UserAdminResponse>
        columns={[
          {
            fieldTemplate: (rowData) => (
              <TableCell
                value={rowData.userName}
                linkTo={`/adminportal/administrators/${rowData.adminId}`}
              />
            ),
          },
          {
            fieldTemplate: (rowData) => (
              <TableCell value={rowData.adminUserName} />
            ),
          },
        ]}
        items={userAdmins}
        rowActions={[
          {
            text: t("Objects:remove_from"),
            icon: "x",
            iconVariant: "secondary",
            iconSize: 16,
            onClick: (rowData) => {
              setUpForDelete(rowData);
              deleteModalRef?.current?.open();
            },
          },
        ]}
        hideHeader
        hideEmptyMessage
        noRowsMessage={t("Table:no_records_found")}
        showRowHover
        withShowMore
        showScrollAfter={5}
      />

      <Modal modalRef={deleteModalRef}>
        {(close) => (
          <DeleteRelatedAdminModal
            userAdminId={upForDelete?.id}
            onClose={close}
            onDelete={() => {
              close();
              onAdminUpdate();
            }}
          />
        )}
      </Modal>
    </>
  );
};
