import he from "he";
import { BaseTableProps } from "../../core/api/common_table";
import Table, { TableColumn } from "../../ui-lib/components/Tables/Table";
import TableCell from "../../ui-lib/components/Tables/TableCell";
import { colKeyToLanguageKey } from "../../core/helpers/helpers";
import NoItems from "../../ui-lib/components/Tables/NoItems";
import useTranslations from "../../core/i18n/useTranslations";
import DateTimeHelper from "../../core/helpers/dateTimeHelper";
import useUser from "../../core/user/useUser";
import { isOrganizationVisible } from "../../ui-lib/utils/organizationsHelper";

interface RowData {
  [key: string]: string;
}

export const AlarmHistoryTable = ({
  data,
  pageSettings,
  onPageSettingsChange,
  sortParams,
  onSortChange,
  alarmTableAction,
  onClear,
  tableName,
  setOpenedAlarmId,
  isFiltersApplied,
}: BaseTableProps) => {
  const t = useTranslations();
  const user = useUser();

  const getColumnName = (colKey: string): string => {
    const { languageKey, index } = colKeyToLanguageKey(colKey);

    const value = t(`Table:${languageKey}`);

    return index ? `${value} ${index}` : value;
  };

  const processTableClick = ({ id }: RowData) => {
    if (alarmTableAction) {
      setOpenedAlarmId?.(id);
      alarmTableAction(id);
    }
  };

  const columns: TableColumn<RowData>[] = data.columns.map((colKey, i) => ({
    header: getColumnName(colKey),
    fieldTemplate: (rowData, rowIndex) => {
      const value = rowData[colKey];
      const fieldType = data.columnDataTypes[i];
      let link: string | undefined;
      switch (colKey) {
        case "ResponseCenter":
          link = `alarm-receptions/${data.responsecenterIds[rowIndex]}`;
          break;
        case "Customer":
          if (isOrganizationVisible(user)) {
            link = `organizations/${data.organizationIds[rowIndex]}`;
          }
          break;
        default:
          break;
      }

      switch (fieldType) {
        case "DATETIME":
          const valueToUse =
            value && value.length > 0
              ? DateTimeHelper.formatDateTimeString(rowData[colKey])
              : "";
          return <TableCell value={valueToUse} />;
        case "LONG":
        case "STRING":
        default:
          if (colKey === "Name") {
            return (
              <TableCell
                onClick={() => {
                  processTableClick(rowData);
                }}
                value={he.decode(rowData[colKey] || "")}
              />
            );
          }
          if (colKey === "AlarmEvent") {
            const alarmEventValue = rowData.AlarmEvent.includes("AlarmCode")
              ? t(`CommonEnum:${rowData.AlarmEvent}`)
              : rowData.AlarmEvent;
            return <TableCell value={alarmEventValue} />;
          }
          return (
            <TableCell value={he.decode(rowData[colKey] || "")} linkTo={link} />
          );
      }
    },
    sortByField: Object.values(data.sortableColumns).find(
      (value) => value === colKey
    ),
  }));

  const items: RowData[] = Object.values(data.rows).map((values, i) => {
    const rowData: RowData = {
      id: `${data.objectIds[i]}`,
      organizationIds: `${data.organizationIds[i]}`,
      responsecenterIds: `${data.responsecenterIds[i]}`,
    };

    data.columns.forEach((colKey, j) => {
      rowData[colKey] = values[j];
    });

    return rowData;
  });

  return items.length > 0 ? (
    <Table<RowData>
      tableName={tableName || "table-alarms"}
      columns={columns}
      hideEmptyMessage
      withPagination
      withLazyLoading
      paginatedItems={{
        items,
        pagination: {
          offset: pageSettings.pageSize * (pageSettings.page - 1),
          limit: pageSettings.pageSize,
          total: data.hits,
        },
      }}
      onPageChange={(nextPage) => {
        const page = Math.floor(nextPage.offset / nextPage.limit);

        if (!Number.isNaN(page) && nextPage.limit) {
          onPageSettingsChange({
            page: page + 1,
            pageSize: nextPage.limit,
          });
        }
      }}
      sortParams={sortParams}
      onSortChange={(sortData) => {
        onSortChange?.(sortData);
        onPageSettingsChange({
          page: 1,
          pageSize: pageSettings.pageSize,
        });
      }}
      rowActions={[
        {
          icon: "eye",
          text: t("Common:view"),
          onClick: processTableClick,
        },
      ]}
      rowActionsColWidth={48}
      showRowHover
      rowActionsFixed
    />
  ) : (
    <NoItems
      title={
        !isFiltersApplied
          ? t("Table:noresult_title")
          : t("Table:no_results_found")
      }
      icon={!isFiltersApplied ? "eye-off" : undefined}
      subTitle={
        !isFiltersApplied
          ? t("Table:noresult_subtitle")
          : t("Table:adjust_filter_description")
      }
      clear={
        isFiltersApplied && onClear
          ? {
              text: t("Table:clear_filters"),
              onClick: onClear,
            }
          : undefined
      }
    />
  );
};
