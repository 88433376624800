import useTranslations from "../../core/i18n/useTranslations";
import Dropdown from "../../ui-lib/components/Dropdown/Dropdown";
import SearchInput from "../../ui-lib/components/Inputs/SearchInput";
import { Spacer } from "../Layout/Layout";

type SearchFilter = { id?: number; value: string; exact?: "true" | "false" };

const NO_TYPE = -1;

const TableSearch = ({
  placeholder,
  dropdownPlaceholder = "Search filter",
  searchFilter,
  setSearchFilter,
  types = [],
  disabled = false,
  resetPageFilter,
  withDefaultSearch = true,
}: {
  placeholder?: string;
  dropdownPlaceholder?: string;
  types?: {
    id: number;
    name: string;
  }[];
  searchFilter?: SearchFilter;
  setSearchFilter: (filter: SearchFilter) => void;
  disabled?: boolean;
  resetPageFilter: () => void;
  withDefaultSearch?: boolean;
}) => {
  const t = useTranslations();

  const items = [
    ...types.map((i) => ({
      ...i,
      isSelected: i.id === searchFilter?.id,
    })),
  ];

  if (withDefaultSearch) {
    items.unshift({
      id: NO_TYPE,
      name: t("Common:all"),
      isSelected: NO_TYPE === searchFilter?.id,
    });
  }

  const selectedItem = items.find((i) => i.isSelected);

  return (
    <>
      <Dropdown
        items={items}
        selectedItem={selectedItem}
        placeholder={dropdownPlaceholder}
        width={205}
        disabled={disabled}
        onSelectItem={(i) => {
          setSearchFilter({
            id: i.id as number,
            value: searchFilter?.value ?? "",
            exact: searchFilter?.exact,
          });
        }}
        withoutCheckIcon={false}
        maxVisible={items.length}
        dataCy="searchDropDown"
      />
      <Spacer size={8} />
      <SearchInput
        limit={1}
        placeholder={placeholder}
        disabled={disabled}
        value={searchFilter?.value ?? ""}
        onChange={(value) => {
          // Do not reset page filters if value same as previous
          if (searchFilter?.value !== value) {
            resetPageFilter();
          }
          setSearchFilter({
            ...searchFilter,
            value,
          });
        }}
      />
    </>
  );
};

export default TableSearch;
export type { SearchFilter };
