import { AxiosInstance } from "axios";
import API_HOST from "./apiHost";
import useAuthenticatedFetch from "./useAuthenticatedFetch";

export interface Product {
  name: string;
  id: number;
  requireIdentifier: boolean;
  requireImei: boolean;
  requirePin: boolean;
  defaultPin: string;
  reconfigurable: boolean;
  canBeInStock: boolean;
  deletenWhenDisassociate: boolean;
  canRequestLog: boolean;
  canDownloadApp: boolean;
  canFirmwareUpdate: boolean;
  canDownload: boolean;
  imeiFromPhonenumber: boolean;
  supportMultipleTransmitters: boolean;
  usingCoreApi: boolean;
  identifierPrefix: string;
  identifierTranslationKey: string;
  createTransmitter: boolean;
  geoFenceType: number;
  webHook: boolean;
  locationCapable: boolean;
  organizationChangeNotAllowed: boolean;
  requireSipPassword: boolean;
  requireSipUsername: boolean;
  productSpecificIdentifierRegex: string;
  sipCredentialType: number;
}
export interface ProductCategory {
  productCategoryId: number;
  name: string;
  products: Product[];
}

const useProductCategories = () => {
  const { data, isLoading, isError } = useAuthenticatedFetch<ProductCategory[]>(
    `${API_HOST}api/v2/products`
  );

  return { data, isLoading, isError };
};

const getProducts = (authenticatedRequest: AxiosInstance) =>
  authenticatedRequest.get<ProductCategory[]>(`${API_HOST}api/v2/products`);

export { useProductCategories, getProducts };
