import { ChangeEventHandler } from "react";
import classNames from "classnames";
import inputStyles from "./Input.module.css";
import Tooltip from "../Tooltip/Tooltip";
import Icon from "../Icon";
import { getUniqueId } from "../../../core/hooks/getUniqueId";
import { isEmpty } from "../../../core/helpers/helpers";

export interface TextAreaProps {
  label?: string;
  placeholder?: string;
  hint?: string;
  required?: boolean;
  disabled?: boolean;
  description?: string;
  validationError?: string | null;
  value?: string;
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
  maxSymbols?: number;
  css?: any;
}

/** Represents <textarea /> tag */
export const TextArea = ({
  label = "",
  placeholder = "",
  hint = "",
  required = false,
  disabled = false,
  description = "",
  validationError = null,
  value = "",
  onChange = () => {},
  maxSymbols,
  css = {},
}: TextAreaProps) => {
  const internalValue = value === null ? undefined : value;
  const id = getUniqueId("ta_");
  return (
    <div className={inputStyles.inputContainer}>
      <div className={inputStyles.labelRow}>
        {required && <span className={inputStyles.requiredIcon}>*</span>}
        <label htmlFor={id} className={isEmpty(label) ? "visuallyHidden" : ""}>
          {label || placeholder || description || "textarea"}
        </label>
        {hint ? (
          <Tooltip
            trigger={() => (
              <span
                className={classNames({
                  [inputStyles.labelHint]: true,
                })}
              >
                <Icon name="information-circle" size={16} />
              </span>
            )}
            position="right center"
            text={hint}
          />
        ) : null}
      </div>
      <div
        className={classNames({
          [inputStyles.inputRow]: true,
          [inputStyles.filledBorder]: !!value,
        })}
      >
        <textarea
          style={css}
          id={id}
          placeholder={placeholder}
          required={required}
          disabled={disabled}
          value={internalValue}
          onChange={(event) =>
            (!maxSymbols || event.target.value.length <= maxSymbols) &&
            onChange(event)
          }
          className={classNames({
            [inputStyles.inputValidationError]: !!validationError,
          })}
        />
        {!!maxSymbols && <p>{`${internalValue?.length}/${maxSymbols}`}</p>}
      </div>
      <div className={inputStyles.description}>{description}</div>
      {validationError ? (
        <div className={inputStyles.validationError}>
          <Icon name="exclamation-circle" size={14} />
          {validationError}
        </div>
      ) : null}
    </div>
  );
};
export default TextArea;
