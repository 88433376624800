import { Link } from "react-router-dom";
import classNames from "classnames";
import Icon from "../../Icon";
import styles from "../Menu.module.css";

interface linkpath {
  pathname: string;
}
export interface PopupContextMenuLinkProps {
  to: string;
  text: string;
  icon?: string;
}

export const PopupContextMenuLink = ({
  to,
  text,
  icon = undefined,
}: PopupContextMenuLinkProps) => {
  let link: string | linkpath = to;
  let target: any;
  if (to.indexOf("http") > -1) {
    link = { pathname: to };
    target = "_blank";
  }
  return (
    <Link to={link} target={target} className={styles.popupContextMenuLink}>
      {icon ? <Icon name={icon} size={16} color="Grey-400" /> : null}
      <span
        className={classNames({
          [styles.popupContextMenuLinkText]: true,
          [styles.popupContextMenuLinkTextNoIcon]: !icon,
        })}
      >
        {text}
      </span>
    </Link>
  );
};
