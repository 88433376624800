import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import useTranslations from "../../core/i18n/useTranslations";
import { Row, Spacer } from "../Layout/Layout";
import Button from "../../ui-lib/components/Button/Button";
import StyledModal from "../../ui-lib/components/Modal/Modal";
import { RatingContainer } from "../../ui-lib/components/Rating/RatingContainer";
import Checkbox from "../../ui-lib/components/Checkbox/Checkbox";
import TextArea from "../../ui-lib/components/Inputs/Textarea";
import { ISwitchQuestionnaire } from "../../core/api/useradmins/types";
import useUser from "../../core/user/useUser";
import { sendSwitchQuestionnaire } from "../../core/api/useradmins/useradmins";
import { notifyApiErrors } from "../../core/helpers/helpers";

export const SwitchVersionsModal = ({
  isOpen,
  onAfterSubmit,
  onClose,
}: {
  isOpen: boolean;
  onAfterSubmit: () => void;
  onClose: () => void;
}) => {
  const t = useTranslations();
  const { authenticatedRequest } = useUser();
  const {
    control,
    formState: { isDirty },
    handleSubmit,
    watch,
    reset,
  } = useForm<ISwitchQuestionnaire>({
    defaultValues: {
      score: 0,
      toHard: false,
      badLook: false,
      noInfo: false,
      missingFeature: false,
      other: false,
      feedback: "",
    },
  });

  const [isLoading, setIsLoading] = useState(false);

  const isOther = watch("other");

  const saveQuestionnaireDate = () => {
    const today = new Date();
    localStorage.setItem("questionnaireDate", today.toDateString());
  };

  const onSubmit = async (values: ISwitchQuestionnaire) => {
    try {
      setIsLoading(true);
      const dayString = new Date();
      const updatedValues = { ...values, timestamp: dayString.toISOString() };
      await sendSwitchQuestionnaire(updatedValues, authenticatedRequest);
      saveQuestionnaireDate();
      onAfterSubmit();
      reset();
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <StyledModal
      isOpen={isOpen}
      onClose={() => {
        reset();
        onClose();
      }}
      modalTitle={t("Home:help_us_improve_title")}
      closeOnDocumentClick={false}
      width="514px"
      customFooter={() => (
        <Row type="left">
          <Button
            type="submit"
            text={t("Common:submit")}
            loading={isLoading}
            disabled={isLoading || !isDirty}
            form="improve_form"
          />
          <Spacer size={8} />
          <Button
            variant="secondary"
            text={t("Common:skip")}
            onClick={() => {
              saveQuestionnaireDate();
              onAfterSubmit();
            }}
          />
        </Row>
      )}
    >
      <form id="improve_form" onSubmit={handleSubmit(onSubmit)}>
        <p>{t("Home:help_us_improve_description")}</p>
        <Spacer size={16} />
        <Controller
          name="score"
          control={control}
          render={({ field }) => (
            <RatingContainer onChange={field.onChange} value={field.value} />
          )}
        />
        <Spacer size={32} />
        <p style={{ fontWeight: 600, fontSize: "16px" }}>
          {t("Home:why_switching")}
        </p>
        <Spacer size={24} />
        <Controller
          name="toHard"
          control={control}
          render={({ field }) => (
            <Checkbox
              label={t("Home:to_hard")}
              checked={field.value}
              onChange={field.onChange}
              style={{ padding: 0 }}
            />
          )}
        />
        <Spacer size={12} />
        <Controller
          name="badLook"
          control={control}
          render={({ field }) => (
            <Checkbox
              label={t("Home:bad_look")}
              checked={field.value}
              onChange={field.onChange}
              style={{ padding: 0 }}
            />
          )}
        />
        <Spacer size={12} />
        <Controller
          name="noInfo"
          control={control}
          render={({ field }) => (
            <Checkbox
              label={t("Home:no_info")}
              checked={field.value}
              onChange={field.onChange}
              style={{ padding: 0 }}
            />
          )}
        />
        <Spacer size={12} />
        <Controller
          name="missingFeature"
          control={control}
          render={({ field }) => (
            <Checkbox
              label={t("Home:missing_feature")}
              checked={field.value}
              onChange={field.onChange}
              style={{ padding: 0 }}
            />
          )}
        />
        <Spacer size={12} />
        <Controller
          name="other"
          control={control}
          render={({ field }) => (
            <Checkbox
              label={t("Common:other")}
              checked={field.value}
              onChange={field.onChange}
              style={{ padding: 0 }}
            />
          )}
        />
        <Spacer size={12} />
        {isOther && (
          <Controller
            name="feedback"
            control={control}
            render={({ field }) => (
              <TextArea
                value={field.value}
                onChange={field.onChange}
                label={t("Home:describe_problem")}
                placeholder={t("Home:describe_problem_description")}
              />
            )}
          />
        )}
      </form>
    </StyledModal>
  );
};
