import { useCallback, useMemo } from "react";
import { toSafeInteger } from "lodash";
import { useQueryState } from "./useQueryState";
import { FilterData } from "../../api/common_table";
import { searchStringParser } from "../../../ui-lib/utils/urlSearchParser";

export type MoreState = {
  filterType?: string;
  filterValue?: string;
  filterType2?: string;
  filterValue2?: string;
  filterType3?: string;
  filterValue3?: string;
  filterType4?: string;
  filterValue4?: string;
};
export type MoreFilter = {
  filterType?: number;
  filterValue?: string;
  filterType2?: number;
  filterValue2?: string;
  filterType3?: number;
  filterValue3?: string;
  filterType4?: number;
  filterValue4?: string;
};

const DEFAULT: MoreState = {
  filterType: undefined,
  filterValue: undefined,
  filterType2: undefined,
  filterValue2: undefined,
  filterType3: undefined,
  filterValue3: undefined,
  filterType4: undefined,
  filterValue4: undefined,
};

export const getFilterList = (searchState: any) => {
  const filterList: FilterData[] = [];
  Object.keys(searchState).forEach((key) => {
    if (key.includes("filterType")) {
      filterList.push({ id: searchState[key], value: "" });
    } else if (key.includes("filterValue")) {
      filterList[filterList.length - 1].value = searchState[key];
    }
  });
  return filterList;
};

const toMoreFilter = (state: MoreState): MoreFilter => ({
  filterType: state.filterType ? toSafeInteger(state.filterType) : undefined,
  filterValue: state.filterValue || undefined,
  filterType2: state.filterType2 ? toSafeInteger(state.filterType2) : undefined,
  filterValue2: state.filterValue2 || undefined,
  filterType3: state.filterType3 ? toSafeInteger(state.filterType3) : undefined,
  filterValue3: state.filterValue3 || undefined,
  filterType4: state.filterType4 ? toSafeInteger(state.filterType4) : undefined,
  filterValue4: state.filterValue4 || undefined,
});

export const toMoreState = (filter: FilterData[]): MoreState => ({
  filterType: filter[0]?.id?.toString(),
  filterValue: filter[0] && searchStringParser(`${filter[0].value}`),
  filterType2: filter[1]?.id?.toString(),
  filterValue2: filter[1] && searchStringParser(`${filter[1].value}`),
  filterType3: filter[2]?.id?.toString(),
  filterValue3: filter[2] && searchStringParser(`${filter[2]?.value}`),
  filterType4: filter[3]?.id?.toString(),
  filterValue4: filter[3] && searchStringParser(`${filter[3]?.value}`),
});

export const useMoreFilter = (searchObj?: any) => {
  const stateToUse = !searchObj
    ? DEFAULT
    : {
        filterType: searchObj.filterType,
        filterValue:
          searchObj.filterValue && searchStringParser(searchObj.filterValue),
        filterType2: searchObj.filterType2,
        filterValue2:
          searchObj.filterValue2 && searchStringParser(searchObj.filterValue2),
        filterType3: searchObj.filterType3,
        filterValue3:
          searchObj.filterValue3 && searchStringParser(searchObj.filterValue3),
        filterType4: searchObj.filterType4,
        filterValue4:
          searchObj.filterValue4 && searchStringParser(searchObj.filterValue4),
      };

  const [state, setState] = useQueryState<MoreState>(stateToUse);

  const moreFilter = useMemo(() => toMoreFilter(state), [state]);

  const setMoreFilter = useCallback(
    (args: FilterData[]) => setState(toMoreState(args)),
    [setState]
  );

  const resetMoreFilter = useCallback(() => setState(DEFAULT), [setState]);

  return { moreFilter, setMoreFilter, resetMoreFilter };
};
