import { Fragment, useEffect, useState } from "react";
import { BaseColumnsProps } from "../../../core/api/common_table";
import { ColumnType } from "./Table";
import {
  getSelectedTableColumnListInTreeStructure,
  getTableColumnsList,
} from "../../utils/getTableColumnsList";
import useTranslations from "../../../core/i18n/useTranslations";

import PopupContextMenu from "../Menus/PopupContextMenu/PopupContextMenu";
import Divider from "../Divider/Divider";
import Checkbox from "../Checkbox/Checkbox";
import Button from "../Button/Button";
import Icon from "../Icon";

import styles from "./Table.module.css";

const TableDataControlButton = ({
  allTableColumns,
  selectedTableColumns,
  onVisibleColumnsChange = undefined,
}: {
  allTableColumns?: BaseColumnsProps;
  selectedTableColumns?: BaseColumnsProps;
  onVisibleColumnsChange?: (columns: BaseColumnsProps) => void;
}) => {
  const t = useTranslations();

  // States ---------------------------------
  const [columnsMap, columnsMapSet] = useState<Map<string, ColumnType>>(
    getTableColumnsList(allTableColumns, selectedTableColumns)
  );
  const [expandedColumns, setExpandedColumns] = useState<string[]>([]);

  // Helpers ---------------------------------
  const getColumnName = (colKey: string): string => {
    const splited = colKey.split(".");
    const languageKey = splited.length === 1 ? colKey : splited[1];

    return t(`Table:${languageKey}`);
  };

  const isApplyDisabled = () => {
    let isDisabled = true;
    const selectedColumns =
      getSelectedTableColumnListInTreeStructure(columnsMap);
    Object.keys(selectedColumns.columns).forEach((key) => {
      if (!Number.isNaN(parseInt(key, 10))) {
        isDisabled = false;
      } else {
        const subSelected = selectedColumns.columns[key];
        if (Object.keys(subSelected).length) {
          isDisabled = false;
        }
      }
    });
    return isDisabled;
  };

  //  render functions ---------------------------------
  const renderChildColumns = (
    parentName: string,
    childCols: Map<string, ColumnType[]>
  ) =>
    Array.from(childCols).map(([name, col], index) => {
      const selectedChild = col.filter((el) => el.selected);
      const sectionIsOpen = expandedColumns.includes(name);

      const onChange = (itemName?: string, itemValue: boolean = false) => {
        const item = columnsMap.get(parentName);
        if (item && item.subColumns) {
          const childItems = item.subColumns.get(name)?.map((childEl) => {
            if (itemName) {
              if (itemName === childEl.name) childEl.selected = itemValue;
            } else {
              childEl.selected = selectedChild.length !== col.length;
            }
            return childEl;
          });
          item.subColumns =
            childItems &&
            new Map<string, ColumnType[]>(
              item.subColumns.set(name, childItems)
            );
          columnsMap.set(parentName, item);
          columnsMapSet(new Map<string, ColumnType>(columnsMap));
        }
      };

      return (
        <Fragment key={`col_option_${name}`}>
          <Divider />
          <div
            className={styles.checkboxRow}
            data-cy="table_data_expandable_row"
          >
            <Checkbox
              dataCy={`table_data_checkbox_${name}`}
              htmlFor={`${getColumnName(name)} ${parentName} ${index}`}
              label={getColumnName(name)}
              checked={selectedChild.length === col.length}
              onChange={() => onChange()}
            />
            {col.length && (
              <div className={styles.childDetailsRow}>
                <span className={styles.selectedChild}>
                  {selectedChild.length}/{col.length}
                </span>
                <Icon
                  name={sectionIsOpen ? "chevron-down" : "chevron-right"}
                  size={16}
                  onClick={() => {
                    if (expandedColumns.includes(name)) {
                      setExpandedColumns((expandedCols) =>
                        expandedCols.filter((el) => el !== name)
                      );
                    } else {
                      setExpandedColumns((cols) => [...cols, name]);
                    }
                  }}
                />
              </div>
            )}
          </div>
          {sectionIsOpen &&
            col.map((el) => (
              <Checkbox
                dataCy={`table_data_checkbox_${name}`}
                htmlFor={`${getColumnName(el.name)} ${parentName} ${index}`}
                key={`col_option_${el.name}`}
                label={getColumnName(el.name)}
                checked={el.selected}
                onChange={() => onChange(el.name, !el.selected)}
                labelStyles={{ color: "var(--Grey-900)" }}
              />
            ))}
          <Divider />
        </Fragment>
      );
    });

  const renderColumn = (name: string, col: ColumnType, index: number) => (
    <Fragment key={`col_option_${name}`}>
      {!col.subColumns && (
        <div className={styles.checkboxRow}>
          <Checkbox
            dataCy={`table_data_checkbox_${name}`}
            htmlFor={`${getColumnName(name)} ${index}`}
            label={getColumnName(name)}
            checked={col.selected}
            labelStyles={{ color: "var(--Grey-900)" }}
            onChange={() => {
              const item = columnsMap.get(name);
              if (item) {
                item.selected = !col.selected;
                columnsMap.set(name, item);
                columnsMapSet(new Map<string, ColumnType>(columnsMap));
              }
            }}
          />
        </div>
      )}
      {col.subColumns && renderChildColumns(name, col.subColumns)}
    </Fragment>
  );

  useEffect(() => {
    if (selectedTableColumns && allTableColumns) {
      columnsMapSet(getTableColumnsList(allTableColumns, selectedTableColumns));
    }
  }, [selectedTableColumns, allTableColumns]);

  // Markup ---------------------------------
  return (
    <div className="ml-auto">
      <PopupContextMenu
        dataCy="table_data_popup"
        onClose={() => {
          columnsMapSet(
            getTableColumnsList(allTableColumns, selectedTableColumns)
          );
          setExpandedColumns([]);
        }}
        position="bottom right"
        className="table-filter"
        trigger={(isOpen) => (
          <div>
            {isOpen ? (
              <>
                <Button
                  tabIndex={1}
                  dataCy="table_data_button_apply"
                  type="button"
                  variant="primary"
                  text={t("Table:apply")}
                  className={styles.tableColumnsApplyButton}
                  disabled={isApplyDisabled()}
                  onClick={() => {
                    if (onVisibleColumnsChange) {
                      const selectedColumns =
                        getSelectedTableColumnListInTreeStructure(columnsMap);
                      onVisibleColumnsChange(selectedColumns);
                    }
                    setExpandedColumns([]);
                  }}
                />
                <Button
                  tabIndex={1}
                  dataCy="table_data_button_cancel"
                  type="button"
                  variant="secondary"
                  text={t("Table:cancel")}
                  onClick={() => {
                    columnsMapSet(
                      getTableColumnsList(allTableColumns, selectedTableColumns)
                    );
                    setExpandedColumns([]);
                  }}
                />
              </>
            ) : (
              <Button
                dataCy="table_data_button"
                type="button"
                variant="secondary"
                text={t("Table:table_data")}
                image="table"
                className={styles.tableColumnsSelectButton}
              />
            )}
          </div>
        )}
      >
        {Array.from(columnsMap).map(([name, col], index) =>
          renderColumn(name, col, index)
        )}
      </PopupContextMenu>
    </div>
  );
};

export default TableDataControlButton;
