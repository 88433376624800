import { observer } from "mobx-react-lite";
import { AddressDetails } from "../../../../core/api/addresses/types";
import useUser from "../../../../core/user/useUser";
import {
  useAddressDetails,
  editAddress,
} from "../../../../core/api/addresses/addresses";

import { notify } from "../../../../ui-lib/components/Alerts/Toast";
import LoadingSpinner from "../../../../ui-lib/components/Loading/LoadingSpinner";

import { AddressForm } from "./AddressForm";
import useTranslations from "../../../../core/i18n/useTranslations";
import { notifyApiErrors } from "../../../../core/helpers/helpers";

const EditAddressModal = ({
  objectId,
  addressId,
  onClose,
  onEdit,
}: {
  objectId: string;
  addressId: number;
  onClose: () => void;
  onEdit: () => void;
}) => {
  const t = useTranslations();

  const { authenticatedRequest } = useUser();
  const { data, isLoading } = useAddressDetails(objectId, addressId);

  if (!data || isLoading) {
    return <LoadingSpinner theme="primary" />;
  }

  const onSubmit = async (addressDetails: AddressDetails) => {
    try {
      addressDetails = {
        ...addressDetails,
        latitude: addressDetails.latitude!
          ? +addressDetails.latitude
          : undefined,
        longitude: addressDetails.longitude!
          ? +addressDetails.longitude
          : undefined,
      };

      await editAddress(
        objectId,
        addressId,
        addressDetails,
        authenticatedRequest
      );
      notify({
        message: t("Messages:edit_address_success"),
      });
      onEdit();
      onClose();
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    }
  };

  return (
    <AddressForm
      labels={{
        title: t("Objects:address_edit"),
        description: t("Objects:address_edit_description"),
        submit: t("Objects:address_edit"),
      }}
      defaultValues={data}
      onSubmit={onSubmit}
      onClose={onClose}
    />
  );
};

export default observer(EditAddressModal);
