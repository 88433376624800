import he from "he";
import moment from "moment";
import classNames from "classnames";
import { useState } from "react";
import {
  IHistoryObjectAbsence,
  IObjectAbsenceModel,
} from "../../../core/api/objects/types";
import useTranslations from "../../../core/i18n/useTranslations";
import TableCell from "../../../ui-lib/components/Tables/TableCell";
import Table from "../../../ui-lib/components/Tables/Table";
import { Spacer } from "../../../components/Layout/Layout";
import useUser from "../../../core/user/useUser";
import { userDateFormat } from "../../../ui-lib/utils/userDateFormat";
import Tooltip from "../../../ui-lib/components/Tooltip/Tooltip";
import style from "./ObjectActions.module.css";
import { CancelAbsenceModal } from "./CancelAbsenceModal";
import { ScheduleAbsenceModal } from "./ScheduleAbsenceModal";
import useSharedObject from "../SharedObjectContext/useSharedObject";
import PrimeModal from "../../../ui-lib/components/PrimeModal/PrimeModal";

export const AbsenceHistory = ({
  adminId,
  absenceData = {
    ongoing: [],
    previous: [],
    upcoming: [],
  },
  reloadAbsence,
}: {
  adminId: number;
  absenceData: IObjectAbsenceModel;
  reloadAbsence: () => Promise<void>;
}) => {
  const t = useTranslations();
  const { dateFormat } = useUser();
  const { data } = useSharedObject();

  const [editAbsenceId, setEditAbsenceId] = useState<number | undefined>();
  const [deleteAbsenceId, setDeleteAbsenceId] = useState<number | undefined>();
  const [scheduleAbsenceModalOpen, setScheduleAbsenceModalOpen] =
    useState(false);

  return (
    <>
      {!editAbsenceId && (
        <>
          <Spacer size={16} />

          <Table<IHistoryObjectAbsence>
            columns={[
              {
                header: t("Common:reason"),
                fieldTemplate: (rowData) => (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {rowData.absenceType !== "previous" && (
                      <Tooltip
                        trigger={() => (
                          <span
                            className={classNames({
                              [style.absenceIcon]: true,
                              [style[`${rowData.absenceType}Icon`]]: true,
                            })}
                          />
                        )}
                        position="top center"
                        text={
                          rowData.absenceType === "ongoing"
                            ? t("Objects:ongoing")
                            : t("Objects:upcoming")
                        }
                        variant="light"
                      />
                    )}
                    <TableCell
                      className={style.absenceHistoryReason}
                      value={he.decode(rowData.reason)}
                    />
                  </div>
                ),
              },
              {
                header: t("Common:additional_object_information"),
                fieldTemplate: (rowData) => (
                  <TableCell value={rowData.comment} />
                ),
              },
              {
                header: t("Common:start"),
                fieldTemplate: (rowData) => (
                  <TableCell
                    value={moment(
                      `${rowData.startDate} ${rowData.startTime}`
                    ).format(`${userDateFormat(dateFormat!)} HH:mm`)}
                  />
                ),
              },
              {
                header: t("Common:end"),
                fieldTemplate: (rowData) => (
                  <>
                    {!rowData.endDate ? null : (
                      <TableCell
                        value={moment(
                          `${rowData.endDate} ${rowData.endTime}`
                        ).format(`${userDateFormat(dateFormat!)} HH:mm`)}
                      />
                    )}
                  </>
                ),
              },
            ]}
            items={[
              ...absenceData.upcoming.map((absence) => ({
                ...absence,
                absenceType: "upcoming",
              })),
              ...absenceData.ongoing.map((absence) => ({
                ...absence,
                absenceType: "ongoing",
              })),
              ...absenceData.previous.map((absence) => ({
                ...absence,
                absenceType: "previous",
              })),
            ].sort((a, b) => b.startDate.localeCompare(a.startDate))}
            rowActions={[
              {
                text: t("Common:label_edit"),
                icon: "pencil-alt",
                iconVariant: "primary",
                iconSize: 20,
                onClick: (rowData) => {
                  setEditAbsenceId(rowData.id);
                  setScheduleAbsenceModalOpen(true);
                },
                hideForRow: (rowData) =>
                  rowData.absenceType === "previous" || !data?.active,
                alwaysShow: true,
              },
              {
                text: (rowData) =>
                  rowData.absenceType === "ongoing"
                    ? t("Common:end_absence")
                    : t("Common:delete"),
                icon: (rowData) => {
                  if (rowData.absenceType === "ongoing") {
                    return "terminate";
                  }
                  return "x";
                },
                iconVariant: "secondary",
                iconSize: 20,
                onClick: (rowData) => {
                  setDeleteAbsenceId(rowData.id);
                },
                hideForRow: (rowData) => rowData.absenceType === "previous",
                alwaysShow: true,
              },
            ]}
            hideEmptyMessage
            noRowsMessage={t("Objects:no_note")}
            showRowHover
            withShowMore
          />

          <Spacer size={32} />
        </>
      )}

      {editAbsenceId && (
        <PrimeModal
          withHeader
          style={{ width: "848px" }}
          header={
            absenceData.ongoing.length
              ? t("Objects:edit_ongoing_absence")
              : t("Objects:edit_upcoming_absence")
          }
          isOpen={scheduleAbsenceModalOpen}
          onClose={() => {
            setScheduleAbsenceModalOpen(false);
            setEditAbsenceId(undefined);
          }}
        >
          <ScheduleAbsenceModal
            defaultValues={
              absenceData.ongoing.length &&
              absenceData.ongoing[0].id === editAbsenceId
                ? absenceData.ongoing[0]
                : absenceData.upcoming.find(
                    (absence) => absence.id === editAbsenceId
                  )
            }
            reloadAbsence={reloadAbsence}
            onClose={() => {
              setEditAbsenceId(undefined);
              setScheduleAbsenceModalOpen(false);
            }}
            adminId={adminId}
            isOngoing={
              absenceData.ongoing.length
                ? absenceData.ongoing[0].id === editAbsenceId
                : undefined
            }
          />
        </PrimeModal>
      )}
      {deleteAbsenceId && (
        <CancelAbsenceModal
          isEnd={
            !!absenceData.ongoing.find(
              (absence) => absence.id === deleteAbsenceId
            )
          }
          onClose={() => {
            setDeleteAbsenceId(undefined);
          }}
          adminId={adminId}
          reloadAbsence={reloadAbsence}
          absenceId={deleteAbsenceId}
        />
      )}
    </>
  );
};
