import { Dispatch, MutableRefObject, RefObject, SetStateAction } from "react";
import { useHistory } from "react-router-dom";
import Table, {
  RowAction,
  SortOrderParams,
} from "../../ui-lib/components/Tables/Table";
import { KnownLocationListModel } from "../../core/api/locations/types";
import { Column } from "../../components/Layout/Layout";
import { TableRowSelectionPopupActions } from "../../ui-lib/components/Tables/TableRowSelectionPopup";

import NoItems from "../../ui-lib/components/Tables/NoItems";
import TableCell from "../../ui-lib/components/Tables/TableCell";
import useTranslations from "../../core/i18n/useTranslations";
import useUser from "../../core/user/useUser";

import styles from "../Layout.module.css";

const columns = (t: (key: string) => string) => [
  {
    key: "klType",
    header: t("Common:type"),
    fieldTemplate: (rowData: KnownLocationListModel) => {
      const link = `/adminportal/locations/${rowData.id}`;
      return (
        <TableCell
          value={t(`Locations:BeaconType_${rowData.klType}`)}
          linkTo={link}
        />
      );
    },
    sortByField: "KLTYPE",
  },
  {
    key: "organizationName",
    header: t("Organizations:organization"),
    fieldTemplate: (rowData: KnownLocationListModel) => (
      <TableCell
        value={rowData.organizationName}
        linkTo={`/adminportal/organizations/${rowData.organizationId}`}
      />
    ),
    sortByField: "ORGANIZATION",
  },
  {
    key: "marking",
    header: t("Locations:labels_marking"),
    fieldTemplate: (rowData: KnownLocationListModel) => (
      <TableCell value={rowData.marking} />
    ),
    sortByField: "MARKING",
  },
  {
    key: "description",
    header: t("Locations:labels_description"),
    fieldTemplate: (rowData: KnownLocationListModel) => (
      <TableCell value={rowData.description} />
    ),
    sortByField: "DESCRIPTION",
  },
];

const LocationsTable = ({
  clearSelectedFunction,
  selectedItemRows,
  locationsData,
  tableRowSelectionPopupRef,
  searchState,
  totalItems,
  pageSettings,
  sortParams,
  setDeletionModalOpen,
  onPageSettingsChange,
  setSortParams,
  resetPageSettings,
}: {
  clearSelectedFunction: MutableRefObject<Function | undefined>;
  selectedItemRows: MutableRefObject<KnownLocationListModel[]>;
  locationsData: KnownLocationListModel[];
  tableRowSelectionPopupRef: RefObject<
    TableRowSelectionPopupActions<Record<string, any>>
  >;
  searchState: any;
  totalItems: number;
  pageSettings: {
    page: number;
    pageSize: number;
  };
  sortParams?: SortOrderParams;
  setDeletionModalOpen: Dispatch<SetStateAction<boolean>>;
  onPageSettingsChange: (args: { page: number; pageSize: number }) => void;
  setSortParams: (args?: SortOrderParams | undefined) => void;
  resetPageSettings: () => void;
}) => {
  const t = useTranslations();
  const history = useHistory();
  const user = useUser();

  const setClearItemsFunction = (func: Function) => {
    clearSelectedFunction.current = func;
  };

  const rowActions = [
    {
      icon: "pencil-alt",
      text: t("Common:label_edit"),
      onClick: ({ id }: { id: any }) =>
        history.push(`/adminportal/locations/${id}`),
    } as RowAction<Object>,
  ];

  if (user.config?.show.includes("DeleteKnownLocations")) {
    rowActions.push({
      icon: "trash",
      text: t("Common:delete"),
      iconVariant: "secondary",
      onClick: async (rowData: any) => {
        selectedItemRows.current = [rowData as KnownLocationListModel];
        setDeletionModalOpen(true);
      },
    });
  }
  return (
    <Column className={styles.content}>
      {locationsData.length > 0 ? (
        <Column className={styles.tableContainer} type="top">
          <Table<KnownLocationListModel>
            withRowSelection
            withLazyLoading
            rowActionsFixed
            withPagination
            showRowHover
            hideEmptyMessage
            showRowsActionsOnHover
            tableName="table-locations"
            columns={columns(t)}
            setClearItemsFunction={setClearItemsFunction}
            items={locationsData}
            rowActionsColWidth={64}
            rowActions={rowActions}
            sortParams={sortParams}
            onSortChange={(params) => {
              resetPageSettings();
              setSortParams(params);
            }}
            paginatedItems={{
              items: locationsData,
              pagination: {
                offset: pageSettings.pageSize * (pageSettings.page - 1),
                limit: pageSettings.pageSize,
                total: totalItems,
              },
            }}
            onPageChange={(nextPage) => {
              const page = Math.floor(nextPage.offset / nextPage.limit);

              if (!Number.isNaN(page) && nextPage.limit) {
                onPageSettingsChange({
                  page: page + 1,
                  pageSize: nextPage.limit,
                });
              }
            }}
            onRowSelectionChange={(selectedItems) => {
              tableRowSelectionPopupRef.current?.open(selectedItems);
            }}
          />
        </Column>
      ) : (
        <NoItems
          title={
            !searchState
              ? t("Table:noresult_title")
              : t("Table:no_results_found")
          }
          icon={!searchState ? "eye-off" : undefined}
          subTitle={
            !searchState
              ? t("Table:noresult_subtitle")
              : t("Table:adjust_filter_description")
          }
          clear={undefined}
        />
      )}
    </Column>
  );
};

export default LocationsTable;
