import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Row, Spacer } from "../../../components/Layout/Layout";
import { IPFilter, IpWhiteList } from "../../../core/api/organizations/types";
import { getResellerIPWhitList } from "../../../core/api/sellers/sellers";
import { notifyApiErrors } from "../../../core/helpers/helpers";
import { CreateEditResellerIPModal } from "./CreateEditResellerIPModal";
import { ResellerDeleteIPModal } from "./ResellerDeleteIPModal";
import TableRowSelectionPopup, {
  TableRowSelectionPopupActions,
} from "../../../ui-lib/components/Tables/TableRowSelectionPopup";

import Button from "../../../ui-lib/components/Button/Button";
import Table from "../../../ui-lib/components/Tables/Table";
import TableCell from "../../../ui-lib/components/Tables/TableCell";
import Checkbox from "../../../ui-lib/components/Checkbox/Checkbox";
import useUser from "../../../core/user/useUser";
import LoadingSpinner from "../../../ui-lib/components/Loading/LoadingSpinner";

const ResellerIPWhiteList = ({ id }: { id: number }) => {
  const { t } = useTranslation();
  const { authenticatedRequest } = useUser();
  const selectionPopupRef = useRef<TableRowSelectionPopupActions<string>>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [filtersData, setFiltersData] = useState<IpWhiteList>();
  const [toEdit, setToEdit] = useState<IPFilter | undefined>();
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [toDelete, setToDelete] = useState<string[]>([]);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const fetchIPs = async () => {
    setIsLoading(true);
    try {
      const results = await getResellerIPWhitList(id, authenticatedRequest);
      setFiltersData(results.data);
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    } finally {
      setIsLoading(false);
    }
  };

  const closeCreateEditModal = (reset = false) => {
    setIsEditModalVisible(false);
    setToEdit(undefined);
    if (reset) {
      fetchIPs();
    }
  };

  useEffect(() => {
    fetchIPs();
  }, []);

  return (
    <>
      <CreateEditResellerIPModal
        sellerId={id}
        onClose={closeCreateEditModal}
        isOpen={isEditModalVisible}
        defaultValues={toEdit}
        filters={filtersData?.filters}
      />
      <TableRowSelectionPopup
        ref={selectionPopupRef}
        renderBody={(selectedItems) => (
          <Row>
            <Spacer size={16} />
            <Button
              variant="destructive"
              text={`${t("Common:delete_selection")} (${selectedItems.length})`}
              onClick={() => {
                setToDelete(selectedItems);
                setIsDeleteModalVisible(true);
              }}
            />
          </Row>
        )}
      />
      <ResellerDeleteIPModal
        sellerId={id}
        authenticatedRequest={authenticatedRequest}
        isOpen={isDeleteModalVisible}
        filtersToDelete={toDelete}
        filtersData={filtersData}
        onClose={() => {
          setIsDeleteModalVisible(false);
        }}
        onDelete={() => {
          setIsDeleteModalVisible(false);
          selectionPopupRef.current?.close(true);
          setToDelete([]);
          fetchIPs();
        }}
      />
      <h1>{t("AlarmReceptions:technical_settings_whitelist_title")}</h1>
      <Spacer size={32} />
      {isLoading ? (
        <LoadingSpinner theme="primary" />
      ) : (
        <>
          <Row style={{ width: "100%" }} type="space">
            <h2>
              {t("Organizations:current_ip")}{" "}
              <span style={{ color: "var(--Primary-700)" }}>
                {filtersData?.currentIP || ""}
              </span>
            </h2>

            <Button
              variant="secondary"
              text={t("AlarmReceptions:add_new_ipfilter")}
              onClick={() => setIsEditModalVisible(true)}
            />
          </Row>

          <Spacer size={18} />

          <Table<IPFilter>
            columns={[
              {
                header: t("AlarmReceptions:edit_ipfilter_label_startip"),
                fieldTemplate: (rowData) => (
                  <TableCell value={rowData.startIP} />
                ),
              },
              {
                header: t("AlarmReceptions:advanced_settings_ipfilters_endip"),
                fieldTemplate: (rowData) => <TableCell value={rowData.endIP} />,
              },
              {
                header: t(
                  "AlarmReceptions:advanced_settings_addintegration_comment_label"
                ),
                fieldTemplate: (rowData) => (
                  <TableCell value={rowData.comment} />
                ),
              },
              {
                header: t("Common:admin"),
                fieldTemplate: (rowData) => (
                  <Checkbox disabled checked={rowData.adminApi} />
                ),
              },
            ]}
            items={filtersData?.filters}
            hideEmptyMessage
            showRowHover
            withRowSelection
            rowActions={[
              {
                text: t("Objects:edit_details"),
                icon: "pencil-alt",
                onClick: (rowData) => {
                  setToEdit(rowData);
                  setIsEditModalVisible(true);
                },
              },
              {
                text: t("Objects:remove_from"),
                icon: "trash",
                iconVariant: "secondary",
                onClick: (rowData) => {
                  setToDelete([rowData.id.toString()]);
                  setIsDeleteModalVisible(true);
                },
              },
            ]}
            onRowSelectionChange={(selectedItems) => {
              const filtered: string[] = selectedItems
                .filter(
                  (i) => !!filtersData?.filters.find((row) => row.id === i.id)
                )
                .map((item) => item.id.toString());
              selectionPopupRef.current?.open(filtered);
            }}
          />
        </>
      )}
    </>
  );
};

export default ResellerIPWhiteList;
