import React from "react";
import moment from "moment/moment";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { RRule } from "rrule";
import { IScheduleItem } from "../../../core/api/contacts/types";
import useTranslations from "../../../core/i18n/useTranslations";

const localizer = momentLocalizer(moment);

export const Scheduler = ({
  scheduleData,
}: {
  scheduleData: IScheduleItem[];
}) => {
  const t = useTranslations();
  const parseEvent = () => {
    const items = scheduleData.map((item) => {
      const [startHour, startMinute] = item.startTime!.split(":");
      const [endHour, endMinute] = item.endTime!.split(":");
      const startDate = moment(item.startDate).toDate();
      startDate.setMinutes(
        startDate.getMinutes() - startDate.getTimezoneOffset()
      );
      const endDate = item.endDate
        ? moment(item.endDate).toDate()
        : moment().endOf("year").add(1, "year").toDate();
      endDate.setMinutes(endDate.getMinutes() - endDate.getTimezoneOffset());
      if (item.repeatType === "RepeatDays") {
        const rule = new RRule({
          freq: RRule.WEEKLY,
          byweekday: item.repeatDays,
          dtstart: startDate,
          until: endDate,
        });

        return rule.all().map((date) => {
          const start = moment(date)
            .hour(+startHour)
            .minutes(+startMinute)
            .toDate();
          const end = moment(date).hour(+endHour).minutes(+endMinute).toDate();

          return {
            id: item.id,
            title: item.name ?? "",
            start,
            end,
            allDay: item.startTime === "00:00" && item.endTime === "23:59",
            color: item.exceptionTime ? "var(--Error-200)" : "#A7D4ED",
            isException: item.exceptionTime,
          };
        });
      }
      return {
        id: item.id,
        title: item.name ?? "",
        start: moment(startDate)
          .hour(+startHour)
          .minutes(+startMinute)
          .toDate(),
        end: moment(startDate).hour(+endHour).minutes(+endMinute).toDate(),
        allDay: item.startTime === "00:00" && item.endTime === "23:59",
        color: item.exceptionTime ? "var(--Error-200)" : "#A7D4ED",
        isException: item.exceptionTime,
      };
    });

    return items.flat();
  };

  const eventStyleGetter = (event: any) => {
    const { isException } = event;

    return {
      style: {
        backgroundColor: event.color,
        color: "transparent",
        borderRadius: "8px",
        border: "1px solid var(--Grey-200)",
        zIndex: isException ? 1 : 0,
      },
    };
  };

  const eventComponent = ({ event }: { event: any }) => (
    <div style={{ height: "20px", backgroundColor: event.color }} />
  );

  return (
    <Calendar
      style={{ width: "100%", height: "400px", overflow: "auto" }}
      localizer={localizer}
      events={parseEvent()}
      startAccessor="start"
      endAccessor="end"
      views={{
        week: true,
        day: true,
      }}
      defaultView="week"
      eventPropGetter={eventStyleGetter}
      components={{ event: eventComponent }}
      messages={{
        today: t("Common:today"),
        previous: "<",
        next: ">",
        week: t("Filters:week"),
        day: t("Filters:day"),
      }}
    />
  );
};
