import { ReactNode, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import TableLink from "./TableLink";
import Icon, { IconColor } from "../Icon";
import Tooltip from "../Tooltip/Tooltip";
import PrimeTooltip from "../PrimeTooltip/PrimeTooltip";
import usePageState from "../../../core/pagestate/usePageState";

import styles from "./Table.module.css";

export interface TableCellProps {
  value: any;
  leftIcon?: string;
  rightIcon?: string;
  leftIconTooltip?: string;
  rightIconTooltip?: string;
  linkTo?: string;
  className?: string;
  iconColor?: IconColor;
  iconSize?: number;
  fromDashboard?: boolean;
  onClick?: () => void;
  cellStyles?: React.CSSProperties;
}

export const TableCell = ({
  value,
  leftIcon = undefined,
  rightIcon = undefined,
  leftIconTooltip = undefined,
  rightIconTooltip = undefined,
  linkTo = undefined,
  className = undefined,
  iconColor = undefined,
  iconSize = undefined,
  fromDashboard,
  onClick,
  cellStyles,
}: TableCellProps) => {
  const textRef = useRef<HTMLElement>(null);
  const { columnSize } = usePageState();

  const [showTooltip, setShowTooltip] = useState<boolean>(true);

  const cellText = (
    <>
      {showTooltip && <PrimeTooltip target={textRef.current ?? undefined} />}
      <span
        data-pr-tooltip={value}
        ref={textRef}
        className={styles.truncateText}
      >
        {value}
      </span>
    </>
  );

  let leftIconContent: ReactNode | null = null;
  let rightIconContent: ReactNode | null = null;
  if (leftIcon) {
    const leftIconContentTemp = (
      <Icon
        name={leftIcon}
        size={iconSize ?? 24}
        color={iconColor ?? "Primary-700"}
        className="mr-6"
      />
    );
    if (leftIconTooltip) {
      leftIconContent = (
        <Tooltip
          variant="light"
          size="small"
          trigger={() => (
            <span className="line-height-0">{leftIconContentTemp}</span>
          )}
          text={leftIconTooltip}
        />
      );
    } else {
      leftIconContent = leftIconContentTemp;
    }
  }
  if (rightIcon) {
    const rightIconContentTemp = (
      <Icon
        name={rightIcon}
        size={24}
        color={iconColor ?? "Primary-700"}
        className="ml-6"
      />
    );
    if (rightIconTooltip) {
      rightIconContent = (
        <Tooltip
          variant="light"
          size="small"
          trigger={() => (
            <span className="line-height-0">{rightIconContentTemp}</span>
          )}
          text={rightIconTooltip}
        />
      );
    } else {
      rightIconContent = rightIconContentTemp;
    }
  }

  const cellContent = onClick ? (
    <button
      onClick={onClick}
      className={classNames(styles.tableLink, styles.linkHover)}
    >
      {leftIconContent}
      {cellText}
      {rightIconContent}
    </button>
  ) : (
    <>
      {leftIconContent}
      {cellText}
      {rightIconContent}
    </>
  );

  useEffect(() => {
    setShowTooltip(
      textRef?.current?.offsetWidth! < textRef?.current?.scrollWidth!
    );
  }, [columnSize]);

  return (
    <span
      style={cellStyles}
      className={classNames(styles.tableCell, className)}
    >
      {linkTo ? (
        <TableLink to={linkTo} fromDashboard={fromDashboard} onClick={onClick}>
          {cellContent}
        </TableLink>
      ) : (
        cellContent
      )}
    </span>
  );
};

export interface MissingTableCellProps extends TableCellProps {
  missingValueText: string;
}

export const MissingValueTableCell = ({
  value,
  leftIcon = undefined,
  rightIcon = undefined,
  leftIconTooltip = undefined,
  rightIconTooltip = undefined,
  linkTo = undefined,
  className = undefined,
  iconColor = undefined,
  iconSize = undefined,
  missingValueText,
  fromDashboard,
  onClick,
  cellStyles,
}: MissingTableCellProps) => {
  const val = value || missingValueText;
  let combinedClassName = className;

  return (
    <TableCell
      value={val}
      leftIcon={leftIcon}
      leftIconTooltip={leftIconTooltip}
      rightIcon={rightIcon}
      rightIconTooltip={rightIconTooltip}
      linkTo={linkTo}
      iconColor={iconColor}
      className={combinedClassName}
      iconSize={iconSize}
      cellStyles={cellStyles}
      fromDashboard={fromDashboard}
      onClick={onClick}
    />
  );
};
export default observer(TableCell);
