import { observer } from "mobx-react";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import BulkDeleteModal from "../../components/BulkDeleteModal";
import { Column, Row, Spacer } from "../../components/Layout/Layout";
import {
  deleteLocation,
  getKnownLocations,
} from "../../core/api/locations/locations";
import {
  KnownLocationListModel,
  NewLocationResponseData,
} from "../../core/api/locations/types";
import useTranslations from "../../core/i18n/useTranslations";
import usePageState from "../../core/pagestate/usePageState";
import Button from "../../ui-lib/components/Button/Button";
import Divider from "../../ui-lib/components/Divider/Divider";
import PageHeader from "../../ui-lib/components/PageHeader/PageHeader";
import TableRowSelectionPopup, {
  TableRowSelectionPopupActions,
} from "../../ui-lib/components/Tables/TableRowSelectionPopup";
import useUser from "../../core/user/useUser";
import styles from "../Layout.module.css";
import { CreateNewLocationModal } from "../../components/location/CreateNewLocationModal";
import {
  notifyApiErrors,
  sortParamsToString,
} from "../../core/helpers/helpers";
import SearchInput from "../../ui-lib/components/Inputs/SearchInput";
import {
  storeTableState,
  useStoredTableState,
} from "../../core/hooks/filters/useStoredTableState";
import {
  searchStringParser,
  urlSearchParser,
} from "../../ui-lib/utils/urlSearchParser";
import LocationsTable from "./LocationsTable";
import LoadingSpinner from "../../ui-lib/components/Loading/LoadingSpinner";
import { useQueryState } from "../../core/hooks/filters/useQueryState";
import { usePageFilter } from "../../core/hooks/filters/usePageFilter";
import { useSortParams } from "../../core/hooks/filters/useSortParams";

export const PAGE_ID = "adminportal/locations";

const Locations = () => {
  const pageState = usePageState();
  const t = useTranslations();
  const history = useHistory();
  const user = useUser();
  storeTableState(PAGE_ID);
  const tableState = useStoredTableState(PAGE_ID);
  const searchState = urlSearchParser(tableState) || "";

  const [queryParams, setQueryParams] = useQueryState<{ q: string }>({
    q: searchStringParser(searchState.q) || "",
  });
  const { pageFilter, setPageFilter, resetPageFilter } =
    usePageFilter(searchState);
  const { sortParams, setSortParams } = useSortParams(searchState);

  const clearSelectedFunction = useRef<Function>();
  const tableRowSelectionPopupRef =
    useRef<TableRowSelectionPopupActions<Record<string, any>>>(null);
  const selectedItemRows = useRef<KnownLocationListModel[]>([]);

  const [locationsData, setLocationsData] = useState<KnownLocationListModel[]>(
    []
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isNewLocationModalOpen, setIsNewLocationModalOpen] = useState(false);
  const [deletionModalOpen, setDeletionModalOpen] = useState<boolean>(false);
  const [totalItems, setTotalItems] = useState<number>(0);

  const fetchLocations = async () => {
    setIsLoading(true);
    try {
      const { knownLocations, total } = await getKnownLocations(
        {
          page: pageFilter.page,
          pageSize: pageFilter.pageSize,
          q: queryParams.q,
          o: sortParamsToString(sortParams, true),
        },
        user.authenticatedRequest
      );
      setTotalItems(total);
      setLocationsData(knownLocations);
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    } finally {
      setIsLoading(false);
      clearSelectedFunction.current?.();
      tableRowSelectionPopupRef.current?.close;
    }
  };

  useEffect(() => {
    fetchLocations();
  }, [pageFilter, queryParams, sortParams]);

  useEffect(() => {
    sessionStorage.setItem(
      "prevPath",
      JSON.stringify({
        path: history.location.pathname,
        pathName: t("Menu:locations"),
      })
    );
  }, []);

  return (
    <>
      <PageHeader
        title={t("Menu:locations")}
        userName={user.data?.username}
        icon={pageState.pageIcon}
      >
        <Button
          variant="primary"
          text={t("Locations:add_location")}
          onClick={() => {
            setIsNewLocationModalOpen(true);
          }}
        />
      </PageHeader>
      <CreateNewLocationModal
        isOpened={isNewLocationModalOpen}
        onClose={() => {
          setIsNewLocationModalOpen(false);
        }}
        onSubmit={(result?: NewLocationResponseData) => {
          if (result) {
            history.push(`/adminportal/locations/${result.id}`);
          }
        }}
      />
      <BulkDeleteModal
        isOpen={deletionModalOpen}
        onClose={() => setDeletionModalOpen(false)}
        onDelete={() => {
          setDeletionModalOpen(false);
          clearSelectedFunction.current?.();
          tableRowSelectionPopupRef.current?.close(true);
          resetPageFilter();
        }}
        ids={selectedItemRows.current?.map((s) => s.id.toString()) || []}
        labels={{
          single: t("Locations:type"),
          multi: t("Locations:type_multi"),
        }}
        apiFunc={deleteLocation}
      />
      <Divider />
      {user.config?.show.includes("DeleteKnownLocations") && (
        <TableRowSelectionPopup
          ref={tableRowSelectionPopupRef}
          renderBody={(selectedItems) => (
            <Row type="right" align="center">
              <Spacer size={16} />
              <Button
                variant="destructive"
                text={`${t("Transmitters:delete_selection")} (${
                  selectedItems.length
                })`}
                onClick={() => {
                  selectedItemRows.current =
                    selectedItems as KnownLocationListModel[];
                  setDeletionModalOpen(true);
                }}
              />
            </Row>
          )}
        />
      )}
      <Column className={styles.wrapper}>
        <Spacer size={24} />
        <Row className={styles.settingsRow} style={{ padding: "0 24px" }}>
          <SearchInput
            placeholder={t("Locations:locations_search_placeholder")}
            onChange={(val) => {
              resetPageFilter();
              setQueryParams({ q: val });
            }}
            limit={1}
            value={queryParams.q}
          />
        </Row>
        {isLoading ? (
          <LoadingSpinner theme="primary" />
        ) : (
          <LocationsTable
            locationsData={locationsData}
            clearSelectedFunction={clearSelectedFunction}
            selectedItemRows={selectedItemRows}
            tableRowSelectionPopupRef={tableRowSelectionPopupRef}
            searchState={searchState}
            totalItems={totalItems}
            pageSettings={pageFilter}
            sortParams={sortParams}
            setDeletionModalOpen={setDeletionModalOpen}
            onPageSettingsChange={setPageFilter}
            setSortParams={setSortParams}
            resetPageSettings={resetPageFilter}
          />
        )}
      </Column>
    </>
  );
};

export default observer(Locations);
