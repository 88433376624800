import { observer } from "mobx-react";
import { Spacer } from "../../../components/Layout/Layout";
import { IResellerDetails } from "../../../core/api/sellers/types";
import { notifyApiErrors } from "../../../core/helpers/helpers";
import { notify } from "../../../ui-lib/components/Alerts/Toast";
import { ResellerGeneralForm } from "./ResellerGeneralForm";

import useTranslations from "../../../core/i18n/useTranslations";
import useSharedReseller from "../SharedResellerContext/useSharedReseller";

const ResellerGeneral = () => {
  const t = useTranslations();
  const { data, update } = useSharedReseller();

  const onSubmit = async (values: IResellerDetails) => {
    try {
      await update(values);

      notify({ message: t("Messages:reseller_updated") });
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    }
  };

  return (
    <>
      <h1>{t("Menu:general")}</h1>
      <Spacer size={8} />
      <p>{t("Administrator:reseller_general_description")}</p>

      <Spacer size={32} />

      <ResellerGeneralForm defaultValues={data} onSubmit={onSubmit} />
    </>
  );
};

export default observer(ResellerGeneral);
