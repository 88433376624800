import { observer } from "mobx-react-lite";
import { createResponseCenter } from "../../../core/api/responsecenters/responsecenters";
import { NewResponseCenterParams } from "../../../core/api/responsecenters/types";
import useTranslations from "../../../core/i18n/useTranslations";
import useUser from "../../../core/user/useUser";
import { notify } from "../../../ui-lib/components/Alerts/Toast";
import { CreateAlarmReceptionForm } from "./CreateAlarmReceptionForm";
import { ITreeData } from "../../../ui-lib/components/Hierarchy/Tree";
import { notifyApiErrors } from "../../../core/helpers/helpers";

const CreateAlarmReceptionModal = ({
  onClose,
  onAdd,
  organizationId,
  treeData,
  organizationsById,
  isOpen,
}: {
  onClose: () => void;
  onAdd: () => void;
  organizationId?: number;
  treeData: ITreeData[];
  organizationsById?: ITreeData | null;
  isOpen: boolean;
}) => {
  const t = useTranslations();
  const { authenticatedRequest } = useUser();

  const onSubmit = async (params: NewResponseCenterParams) => {
    try {
      await createResponseCenter(params, authenticatedRequest);
      notify({
        message: t("Messages:create_alarm_reception_success"),
      });
      onAdd();
      onClose();
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    }
  };

  return (
    <CreateAlarmReceptionForm
      isOpen={isOpen}
      labels={{
        title: t("AlarmReceptions:add_alarm_reception"),
        description: t("AlarmReceptions:create_alarm_reception_description"),
        submit: t("AlarmReceptions:create"),
      }}
      defaultValues={{
        organizationId,
      }}
      organisations={
        organizationId ? ([organizationsById] as ITreeData[]) : treeData
      }
      onSubmit={onSubmit}
      onClose={onClose}
    />
  );
};

export default observer(CreateAlarmReceptionModal);
