import { colKeyToLanguageKey } from "../../core/helpers/helpers";
import { columnsToString } from "./columnsToString";
import { Columns } from "./types";
import styles from "./ExportSettings.module.css";
import useTranslations from "../../core/i18n/useTranslations";

const ColumnInfo = ({ columnInfo }: { columnInfo: Columns }) => {
  const t = useTranslations();

  return (
    <>
      <p className={styles.label}>{t("Common:columnstoexport")}</p>
      <p className={styles.description}>
        {columnsToString({
          columns: columnInfo,
          getColumnName: (colKey: string): string => {
            const { languageKey, index } = colKeyToLanguageKey(colKey);

            const value = t(`Table:${languageKey}`);

            return index ? `${value} ${index}` : value;
          },
        })}
      </p>
    </>
  );
};

export default ColumnInfo;
