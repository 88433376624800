import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IResellerType } from "../../core/api/sellers/types";
import Table from "../../ui-lib/components/Tables/Table";
import TableCell from "../../ui-lib/components/Tables/TableCell";
import NoItems from "../../ui-lib/components/Tables/NoItems";

const tableColumns = (t: (key: string) => string) => [
  {
    header: t("Common:name"),
    fieldTemplate: (rowData: IResellerType) => (
      <TableCell
        value={rowData.name}
        linkTo={`/adminportal/resellers/${rowData.id}`}
      />
    ),
  },
  {
    header: t("Organizations:organization_number"),
    fieldTemplate: (rowData: IResellerType) => (
      <TableCell value={rowData.organizationNumber} />
    ),
  },
  {
    header: t("Common:labels_city"),
    fieldTemplate: (rowData: IResellerType) => (
      <TableCell value={rowData.city} />
    ),
  },
  {
    header: t("Common:labels_country"),
    fieldTemplate: (rowData: IResellerType) => (
      <TableCell value={rowData.country} />
    ),
  },
];

const ResellersTable = ({ sellers }: { sellers: IResellerType[] }) => {
  const { t } = useTranslation();
  const history = useHistory();

  if (!sellers.length) {
    return (
      <NoItems
        icon="eye-off"
        title={t("Table:noresult_title")}
        subTitle={t("Table:noresult_subtitle")}
      />
    );
  }
  return (
    <Table<IResellerType>
      hideEmptyMessage
      showRowHover
      rowActionsFixed
      rowActionsColWidth={64}
      tableName="table-resellers"
      items={sellers}
      columns={tableColumns(t)}
      rowActions={[
        {
          icon: "pencil-alt",
          text: t("Common:label_edit"),
          onClick: (rowData) => {
            history.push(`/adminportal/resellers/${rowData.id}`);
          },
        },
      ]}
    />
  );
};

export default ResellersTable;
