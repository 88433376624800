import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
// import * as Sentry from "@sentry/react";
// import { BrowserTracing } from "@sentry/tracing";
import "./index.css";
import "./ui-lib/styles/setup";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// if (process.env.NODE_ENV === "production") {
//   Sentry.init({
//     dsn: "https://319112a6b4b24987a8ebcbad8779fd51@sentry.skyresponse.com/3",
//     integrations: [new BrowserTracing()],
//     environment: process.env.REACT_APP_ENVIRONMENT,
//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//     tracesSampleRate: 1.0,
//   });
// }

const domNode = document.getElementById("root");
if (!domNode) throw new Error("Failed to find the root element");
const root = createRoot(domNode);

root.render(
  <StrictMode>
    {/*<Sentry.ErrorBoundary>*/}
    <App />
    {/*</Sentry.ErrorBoundary>*/}
  </StrictMode>
);

// test
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
