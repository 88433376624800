import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { IReceptionSubscription } from "../../../../core/api/responsecenters/types";
import { Row, Spacer } from "../../../../components/Layout/Layout";
import { removeARCSubscription } from "../../../../core/api/responsecenters/responsecenters";

import Table from "../../../../ui-lib/components/Tables/Table";
import TableCell from "../../../../ui-lib/components/Tables/TableCell";
import Button from "../../../../ui-lib/components/Button/Button";
import Modal, { ModalActions } from "../../../../ui-lib/components/Popup/Modal";
import BaseModal from "../../../../ui-lib/components/Modal/Modal";
import CreateSubscriptionModal from "./CreateSubscriptionModal";
import useUser from "../../../../core/user/useUser";
import DeleteModal from "../../../../components/DeleteModal/DeleteModal";
import AlarmSubscriptionDetails from "./AlarmSubscriptionDetails/AlarmSubscriptionDetails";
import { EditSubscriptionActions } from "./AlarmSubscriptionDetails/SubscriptionEditModal";
import { useOrganizationsTree } from "../../../../core/api/organizations/organizations";
import NoItems from "../../../../ui-lib/components/Tables/NoItems";

const AlarmSubscriptionsTable = ({
  rcId,
  subscriptions,
  sellers,
  getAllArcSubscriptions,
}: {
  rcId: number;
  sellers: any;
  subscriptions?: IReceptionSubscription[];
  getAllArcSubscriptions: () => Promise<void>;
}) => {
  const { t } = useTranslation();
  const { authenticatedRequest } = useUser();
  const { data: organisations } = useOrganizationsTree();

  const deleteModalRef = useRef<ModalActions>(null);
  const subscriptionRef = useRef<IReceptionSubscription>();
  const subscriptionEditModalRef = useRef<EditSubscriptionActions>(null);

  const [isAlarmSubscriptionOpen, setIsAlarmSubscriptionOpen] = useState(false);
  const [isAddSubscriptionOpen, setIsAddSubscriptionOpen] = useState(false);

  return (
    <>
      <Row type="space" style={{ width: "100%" }}>
        <h2>{t("AlarmReceptions:subscription_list")}</h2>
        <Button
          size="small"
          variant="secondary"
          text={t("AlarmReceptions:add_new_subscription")}
          onClick={() => setIsAddSubscriptionOpen(true)}
        />
      </Row>
      <Spacer size={16} />
      {subscriptions?.length ? (
        <Table<IReceptionSubscription>
          showRowHover
          hideEmptyMessage
          items={subscriptions}
          columns={[
            {
              key: "name",
              header: t("Common:name"),
              fieldTemplate: (rowData) => (
                <TableCell
                  value={
                    <Button
                      variant="link"
                      key={rowData.description}
                      text={rowData.description}
                      onClick={() => {
                        subscriptionRef.current = rowData;
                        setIsAlarmSubscriptionOpen(true);
                      }}
                      customStyles={{
                        color: "var(--Grey-800)",
                        fontWeight: 600,
                      }}
                    />
                  }
                />
              ),
              style: {
                minWidth: "100px",
              },
            },
            {
              key: "resellers",
              header: t("Common:sellers"),
              fieldTemplate: ({ sellerAccess }) => {
                const outerSeller = sellerAccess
                  .map((seller) => seller.name)
                  .join(", ");
                return <TableCell value={outerSeller} />;
              },
            },
            {
              key: "organisations",
              header: t("Organizations:organizations"),
              fieldTemplate: ({ organizationAccess }) => {
                const orgs = organizationAccess
                  .map((org) => org.name)
                  .join(", ");
                return <TableCell value={orgs} />;
              },
            },
          ]}
          rowActions={[
            {
              text: t("Common:label_edit"),
              icon: "pencil-alt",
              onClick: (rowData) => {
                subscriptionRef.current = rowData;
                setIsAlarmSubscriptionOpen(true);
              },
            },
            {
              text: t("Common:delete"),
              icon: "trash",
              iconVariant: "secondary",
              onClick: (rowData) => {
                subscriptionRef.current = rowData;
                deleteModalRef?.current?.open();
              },
            },
          ]}
          rowActionsColWidth={45}
        />
      ) : (
        <NoItems
          title={t("Table:noresult_title")}
          icon="eye-off"
          subTitle={t("Table:noresult_subtitle")}
          clear={undefined}
          style={{ margin: "auto" }}
        />
      )}

      {/* Add new subscription modal */}
      <BaseModal
        isOpen={isAddSubscriptionOpen}
        onClose={() => {
          setIsAddSubscriptionOpen(false);
        }}
        modalTitle={t("AlarmReceptions:add_new_subscription")}
        hideFooter
      >
        <CreateSubscriptionModal
          arcId={rcId}
          onClose={() => setIsAddSubscriptionOpen(false)}
          onSibmit={getAllArcSubscriptions}
        />
      </BaseModal>

      {/* Delete subscription modal */}
      <Modal modalRef={deleteModalRef}>
        {(close) => (
          <DeleteModal
            title={t("AlarmReceptions:delete_subscription")}
            locizeKey="arc_subscription"
            replaceKey={subscriptionRef.current?.description}
            callDelete={() =>
              removeARCSubscription(
                authenticatedRequest,
                subscriptionRef.current!.subscriptionGroupId,
                rcId
              )
            }
            onClose={close}
            onDelete={getAllArcSubscriptions}
          />
        )}
      </Modal>

      {/* subscription details modal */}
      <AlarmSubscriptionDetails
        arcId={rcId}
        sellers={sellers}
        authenticatedRequest={authenticatedRequest}
        isAlarmSubscriptionOpen={isAlarmSubscriptionOpen}
        subscription={subscriptionRef}
        setIsAlarmSubscriptionOpen={setIsAlarmSubscriptionOpen}
        subscriptionEditModalRef={subscriptionEditModalRef}
        organisations={organisations}
        getAllArcSubscriptions={getAllArcSubscriptions}
      />
    </>
  );
};

export default AlarmSubscriptionsTable;
