import { Suspense, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation,
  useHistory,
} from "react-router-dom";
import { observer } from "mobx-react-lite";
import { parse } from "query-string";
import Login from "./pages/Login/Login";
import AboutPage from "./pages/About/AboutPage";
import { Column } from "./components/Layout/Layout";
import styles from "./App.module.css";
import { UserProvider } from "./core/user/UserContext";
import UserInitializer from "./core/user/UserInitializer";
import LanguageInitializer from "./core/i18n/LanguageInitializer";
import useUser from "./core/user/useUser";
import { RootProvider } from "./core/RootContext";
import Theme from "./components/Theme";
import Dashboard from "./pages/Dashboard/Dashboard";
import DimensionsProvider from "./core/hooks/dimensionProvider";
import { ToastContainer } from "./ui-lib/components/Alerts/Toast";

// Redirect if user not logged in
const PrivateRoute = observer(
  ({ path, children }: { path: string; children: React.ReactNode }) => {
    const { loggedIn } = useUser();

    return (
      <Route
        path={path}
        render={() =>
          loggedIn ? (
            children
          ) : (
            <Redirect to={{ pathname: "/adminportal/login" }} />
          )
        }
      />
    );
  }
);

// Redirect if user logged in
const LogoutRoute = observer(
  ({ path, children }: { path: string; children: React.ReactNode }) => {
    const { loggedIn } = useUser();

    return (
      <Route
        path={path}
        render={() =>
          !loggedIn ? children : <Redirect to={{ pathname: "/adminportal" }} />
        }
      />
    );
  }
);

// login directly by using a valid access token, used by linking new admin old ui and central->admin
const TokenLogin = ({ path }: { path: string }) => {
  const location = useLocation();
  const history = useHistory();
  const user = useUser();
  const authData = parse(location.search);
  const logInFromOldAdmin = async () => {
    if (
      authData.access_token &&
      authData.expires_in &&
      authData.refresh_token
    ) {
      const logoutURL = document.referrer;

      // do not set logout url if the refferer is the current page
      if (logoutURL?.startsWith("https://login.")) {
        window.localStorage.setItem("logoutURL", logoutURL);
      }

      await user.logIn({
        // @ts-ignore
        token: authData.access_token,
        // @ts-ignore
        expiresIn: authData.expires_in,
        // @ts-ignore
        refreshToken: authData.refresh_token,
      });
    }
    const url = authData?.url ? `/adminportal/${authData.url}` : "/adminportal";
    history.replace(url);
  };

  useEffect(() => {
    logInFromOldAdmin();
  }, []);
  return <Route path={path} render={() => <div />} />;
};

const App = () => {
  const { location } = window;
  const { loggedIn } = useUser();
  if (location.href.includes("passwordreset")) {
    localStorage.setItem("userResetToken", location.href.split("=")[1]);
  } else if (
    !loggedIn &&
    !localStorage.getItem("linkToNavigate")?.length &&
    !["login", "adminportal"].includes(location.href.split("/").reverse()[0])
  ) {
    localStorage.setItem("linkToNavigate", location.href);
  }

  return (
    <Suspense fallback={null}>
      <RootProvider>
        <UserProvider>
          <UserInitializer />
          <LanguageInitializer />
          <DimensionsProvider>
            <ToastContainer />
            <Theme>
              <Router>
                <Column className={styles.app}>
                  <Switch>
                    <TokenLogin path="/adminportal/mw" />
                    <LogoutRoute path="/adminportal/login">
                      <Login />
                    </LogoutRoute>
                    <Route path="/adminportal/forgotPassword">
                      <Login />
                    </Route>
                    <Route path="/adminportal/about">
                      <AboutPage />
                    </Route>
                    <PrivateRoute path="/adminportal">
                      <Dashboard />
                    </PrivateRoute>
                    <Route path="/">
                      <Redirect to={{ pathname: "/adminportal" }} />
                    </Route>
                  </Switch>
                </Column>
              </Router>
            </Theme>
          </DimensionsProvider>
        </UserProvider>
      </RootProvider>
    </Suspense>
  );
};

export default App;
