import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Column, Spacer } from "../../components/Layout/Layout";

import {
  downloadAsXlsxFile,
  notifyApiErrors,
} from "../../core/helpers/helpers";
import { reportDownload } from "../../core/api/reports/reports";
import useUser from "../../core/user/useUser";
import Button from "../../ui-lib/components/Button/Button";

import reportImg from "../../ui-lib/assets/icons/reports-illustration.svg";

const ReportDownload = () => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const history = useHistory();
  const { authenticatedRequest } = useUser();

  const fileName: string[] = search.split("/");

  const [loading, setLoading] = useState(false);
  const handleDownload = async () => {
    setLoading(true);
    try {
      const res = await reportDownload(authenticatedRequest, fileName[1]);
      downloadAsXlsxFile(res.data.fileData, res.data.fileName);
      history.push("/");
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Column style={{ margin: "auto" }}>
        <img src={reportImg} alt="report" />
        <Spacer size={37} />
        <h1>{t("Common:report_download_header")}</h1>
        <Spacer size={16} />
        <h2>{t("Common:report_download_description")}</h2>
        <Spacer size={32} />
        <Button
          onClick={handleDownload}
          image="download"
          text={t("Transmitters:configuration_files_table_filename")}
          loading={loading}
        />
      </Column>
    </>
  );
};

export default ReportDownload;
