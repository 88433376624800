import { Controller, useForm } from "react-hook-form";
import ModalContent from "../../ui-lib/components/Popup/ModalContent";
import { Row, Spacer } from "../../components/Layout/Layout";
import Button from "../../ui-lib/components/Button/Button";
import TextInput from "../../ui-lib/components/Inputs/TextInput";
import useTranslations from "../../core/i18n/useTranslations";
import RadioGroup from "../../ui-lib/components/Radio/RadioGroup";
import InputContainer from "../../ui-lib/components/Inputs/InputContainer";
import Tree, { ITreeData } from "../../ui-lib/components/Hierarchy/Tree";
import { IAlternatives } from "../../core/api/alternatives/types";

type linkType = "admin" | "organization";

export const AlternativesForm = ({
  labels,
  onSubmit,
  onClose,
  defaultValues,
  linkFor = "admin",
  organisations,
  bothAppLinksRequired,
}: {
  labels: {
    title: string;
    description: string;
    submit: string;
  };
  onSubmit: (alternativeDetails: any) => Promise<void>;
  onClose: () => void;
  defaultValues?: IAlternatives;
  linkFor?: linkType;
  organisations?: ITreeData[];
  bothAppLinksRequired: boolean;
}) => {
  const t = useTranslations();
  const radioItems =
    linkFor === "admin"
      ? [0, 1, 2].map((i) => ({
          id: i,
          value: `${i}`,
          text: t(`Administrator:link_open_type_${i}`),
        }))
      : [1, 2].map((i) => ({
          id: i,
          value: `${i}`,
          text: t(`Administrator:application_open_type_${i}`),
        }));
  const {
    control,
    formState: { isSubmitting, errors, isDirty },
    handleSubmit,
    getValues,
    setValue,
    trigger,
    watch,
  } = useForm<IAlternatives>({
    defaultValues: defaultValues || { order: 0 },
  });

  const isIosLinkPresent = !!watch("iosLink");
  const isAndroidLinkPresent = !!watch("androidLink");

  return (
    <ModalContent
      onClose={onClose}
      width="848px"
      fixedHeader
      headerContent={
        <>
          <h2 className="subTitle">{labels.title}</h2>
          <Spacer size={8} />
        </>
      }
      footerContent={
        <Row type="left">
          <Button
            form="address-modal-form"
            type="submit"
            variant="primary"
            text={labels.submit}
            loading={isSubmitting}
            disabled={isSubmitting || !isDirty}
          />
          <Spacer size={8} />
          <Button
            variant="secondary"
            text={t("Common:cancel")}
            onClick={onClose}
          />
        </Row>
      }
    >
      <p style={{ color: "var(--Grey-600)" }}>{labels.description}</p>

      <Spacer size={32} />

      <form onSubmit={handleSubmit(onSubmit)} id="address-modal-form">
        <Row type="fill" align="fill">
          <Controller
            name="title"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextInput
                required
                label={t("Common:labels_title")}
                value={field.value}
                onChange={field.onChange}
                validationError={errors.title && t("Common:validation_title")}
              />
            )}
          />
          {organisations && (
            <>
              <Spacer size={32} />
              <Controller
                name="organizationId"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Tree
                    required
                    label={t("Common:belongs_to")}
                    placeholder={t("Common:select_organisation")}
                    items={organisations}
                    selectedTreeItem={field.value}
                    onSelectItem={(selected?: ITreeData) => {
                      setValue(field.name, selected?.key as number, {
                        shouldDirty: true,
                      });
                      trigger(field.name);
                    }}
                    showClearOption={false}
                    validationError={
                      errors?.organizationId && t("Errors:input_field_required")
                    }
                    style={{ width: "376px" }}
                  />
                )}
              />
            </>
          )}
        </Row>

        <Spacer size={16} />

        {linkFor === "admin" && (
          <Row align="start">
            <Controller
              name="url"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextInput
                  required
                  label={t("Common:url")}
                  value={field.value}
                  onChange={field.onChange}
                  validationError={errors.url && t("Common:validation_URL")}
                  placeholder={t("Common:url_placeholder")}
                />
              )}
            />
          </Row>
        )}

        {linkFor === "organization" && (
          <>
            <Row align="start">
              <Controller
                name="iosLink"
                control={control}
                rules={{
                  required: bothAppLinksRequired || !isAndroidLinkPresent,
                }}
                render={({ field }) => (
                  <TextInput
                    required={bothAppLinksRequired || !isAndroidLinkPresent}
                    label={t("Common:ios_url")}
                    value={field.value}
                    onChange={field.onChange}
                    validationError={
                      errors.iosLink &&
                      (bothAppLinksRequired
                        ? t("Common:validation_URL")
                        : t("Errors:android_or_ios_required"))
                    }
                    placeholder={t("Common:url_placeholder")}
                  />
                )}
              />
            </Row>

            <Spacer size={16} />

            <Row align="start">
              <Controller
                name="androidLink"
                control={control}
                rules={{ required: bothAppLinksRequired || !isIosLinkPresent }}
                render={({ field }) => (
                  <TextInput
                    required={bothAppLinksRequired || !isAndroidLinkPresent}
                    label={t("Common:android_url")}
                    value={field.value}
                    onChange={field.onChange}
                    validationError={
                      errors.androidLink &&
                      (bothAppLinksRequired
                        ? t("Common:validation_URL")
                        : t("Errors:android_or_ios_required"))
                    }
                    placeholder={t("Common:url_placeholder")}
                  />
                )}
              />
            </Row>
          </>
        )}

        <Spacer size={16} />

        <Row align="start">
          <Controller
            name="openType"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <InputContainer
                required
                label={
                  <label htmlFor="open-type">
                    {t("AlarmReceptions:edit_menualternative_openin_label")}
                  </label>
                }
                input={
                  <RadioGroup
                    name="open-type"
                    items={radioItems}
                    selectedValue={
                      radioItems.find(
                        (item) => item.id === getValues("openType")
                      )?.value
                    }
                    onChange={(value) => {
                      field.onChange(value);
                      setValue("openType", +value);
                      trigger("openType");
                    }}
                    fullWidth
                  />
                }
                validationError={
                  errors.openType && t("Common:validation_OpenIn")
                }
              />
            )}
          />
        </Row>
      </form>
    </ModalContent>
  );
};
