import { Columns } from "./types";

export const columnsToString = ({
  columns,
  getColumnName,
}: {
  columns: Columns;
  getColumnName: (key: string) => string;
}) =>
  Object.values(columns)
    .filter((value) => {
      if (typeof value === "string") {
        return value.length > 0;
      }

      return Object.keys(value).length > 0;
    })
    .map((value) => {
      if (typeof value === "string") return getColumnName(value);

      return Object.values(value).map(getColumnName).join(", ");
    })
    .join(", ");
