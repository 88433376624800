import useTranslations from "../../../../core/i18n/useTranslations";
import Dropdown from "../../../../ui-lib/components/Dropdown/Dropdown";

export enum Type {
  All,
  GetUrlTrue,
  GetUrlFalse,
}

interface TypePickerProps {
  type: Type;
  setType: (type: Type) => void;
}

const TypePicker = ({ type, setType }: TypePickerProps) => {
  const t = useTranslations();
  const placeholder = t("AlarmReceptions:distribution_filter_get_url");

  const items = [
    {
      id: "all",
      name: placeholder,
      isSelected: type === Type.All,
      onSelectItem: () => setType(Type.All),
    },
    {
      id: "get-auth-true",
      name: t("AlarmReceptions:distribution_filter_get_url_true"),
      isSelected: type === Type.GetUrlTrue,
      onSelectItem: () => setType(Type.GetUrlTrue),
    },
    {
      id: "get-auth-false",
      name: t("AlarmReceptions:distribution_filter_get_url_false"),
      isSelected: type === Type.GetUrlFalse,
      onSelectItem: () => setType(Type.GetUrlFalse),
    },
  ];

  const selectedItem = items.find((i) => i.id !== "all" && i.isSelected);

  return (
    <Dropdown
      width={240}
      placeholder={placeholder}
      selectedItem={selectedItem}
      items={items}
      onSelectItem={(i) => {
        const current = items.find((item) => item.id === i.id);
        current?.onSelectItem();
      }}
    />
  );
};

export default TypePicker;
