import { useState } from "react";
import classNames from "classnames";
import {
  Control,
  Controller,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import useTranslations from "../../../../core/i18n/useTranslations";
import { Row, Spacer } from "../../../../components/Layout/Layout";
import Popup from "../../../../ui-lib/components/Popup/Popup";
import styles from "./ColorPicker.module.css";
import TextInput from "../../../../ui-lib/components/Inputs/TextInput";
import Divider from "../../../../ui-lib/components/Divider/Divider";
import Button from "../../../../ui-lib/components/Button/Button";
import { ObjectDetailsModel } from "../../../../core/api/objects/types";

const colorsPallete: string[] = [
  "",
  "#D83450",
  "#EE722E",
  "#FFFC00",
  "#5ECC8A",
  "#3576CB",
  "#732BF5",
  "#B6BDC8",
  "#E993A7",
  "#F3BA40",
  "#EAE78C",
  "#92D9B7",
  "#9CD0F8",
  "#C6ACF3",
  "#101010",
  "#8A0A00",
  "#744C2B",
  "#9D9C11",
  "#178F47",
  "#0023F5",
  "#421F81",
];

const CentralColorWidget = ({
  defaultColor,
  control,
  watch,
  setValue,
}: {
  defaultColor?: string;
  control: Control<ObjectDetailsModel, any>;
  watch: UseFormWatch<ObjectDetailsModel>;
  setValue: UseFormSetValue<ObjectDetailsModel>;
}) => {
  const t = useTranslations();

  const [inputValue, setInputValue] = useState(defaultColor);

  const currentColor = watch("color");

  return (
    <>
      <Spacer size={32} />
      <Divider />
      <Spacer size={32} />
      <h2 data-cy="widgetTitle">{t("Objects:color_widget_title")}</h2>
      <Spacer size={16} />
      <p className={styles.widgetText} data-cy="widgetDescription">
        {t("Objects:color_widget_description")}
      </p>
      <Spacer size={24} />
      <Controller
        name="color"
        control={control}
        render={({ field }) => (
          <Row type="left">
            <p className={styles.widgetText} data-cy="widgetBtnLable">
              {t("Objects:pick_color")}
            </p>
            <Spacer size={12} />
            <Popup
              data-cy="widgetPopup"
              closeOnDocumentClick={false}
              trigger={() => (
                <span
                  data-cy="widgetPopupBtn"
                  className={classNames({
                    [styles.colorBtn]: true,
                    [styles.switchBtn]: true,
                    [styles.noColorBtn]: !currentColor,
                  })}
                  style={{ backgroundColor: field.value }}
                />
              )}
            >
              {(close) => (
                <div className={styles.colorPickerContainer}>
                  <div className={styles.colorGrid}>
                    {colorsPallete.map((colorItem) => (
                      <span
                        data-cy={`widgetColorBtn-${colorItem}`}
                        key={`color-${colorItem}`}
                        aria-label="Color"
                        role="button"
                        onKeyUp={(event) => {
                          if (event.key === "Enter") {
                            setInputValue(colorItem);
                          }
                        }}
                        className={classNames({
                          [styles.colorBtn]: true,
                          [styles.modalColorButtons]: true,
                          [styles.noColorBtn]: !colorItem,
                        })}
                        onClick={() => {
                          setInputValue(colorItem);
                        }}
                        style={{
                          backgroundColor: colorItem,
                          boxShadow:
                            colorItem === inputValue
                              ? `0 0 6px ${inputValue}`
                              : undefined,
                        }}
                      />
                    ))}
                  </div>
                  <Spacer size={16} />
                  <TextInput
                    disabled
                    dataCy="widgetInput"
                    value={inputValue?.replace("#", "")}
                    renderIcon={() => (
                      <span className={styles.inputIcon}>#</span>
                    )}
                    iconPosition="left"
                  />
                  <Spacer size={16} />
                  <Divider />
                  <Spacer size={16} />
                  <div className={styles.colorWidgetFooterBtns}>
                    <Button
                      data-cy="widgetApply"
                      text={t("Table:apply")}
                      onClick={() => {
                        setValue(field.name, inputValue);
                        close();
                      }}
                    />
                    <Button
                      data-cy="widgetCancel"
                      type="button"
                      variant="secondary"
                      text={t("Common:cancel")}
                      onClick={() => {
                        setInputValue(defaultColor);
                        setValue(field.name, defaultColor);
                        close();
                      }}
                    />
                  </div>
                </div>
              )}
            </Popup>
          </Row>
        )}
      />
      <Spacer size={32} />
    </>
  );
};

export default CentralColorWidget;
