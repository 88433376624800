import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import useTranslations from "../../../../core/i18n/useTranslations";
import { Column, Row, Spacer } from "../../../../components/Layout/Layout";
import Modal, { ModalActions } from "../../../../ui-lib/components/Popup/Modal";
import Button from "../../../../ui-lib/components/Button/Button";
import { CreateRespondentModal } from "./CreateRespondentModal";
import { ResponseCenterDetails } from "../../../../core/api/responsecenters/types";
import layoutStyles from "../../../Layout.module.css";
import styles from "../../../Objects/ObjectGeneral/ObjectGeneral.module.css";
import Table from "../../../../ui-lib/components/Tables/Table";
import NoItems from "../../../../ui-lib/components/Tables/NoItems";
import LoadingSpinner from "../../../../ui-lib/components/Loading/LoadingSpinner";
import { notifyApiErrors } from "../../../../core/helpers/helpers";
import useUser from "../../../../core/user/useUser";
import { AdministratorMeta } from "../../../../core/api/administrators/types";
import TableCell from "../../../../ui-lib/components/Tables/TableCell";
import SearchInput from "../../../../ui-lib/components/Inputs/SearchInput";
import { getAdminRoles } from "../../../../core/api/administrators/administrators";
import Badge from "../../../../ui-lib/components/Badges/Badge";
import DateTimeHelper from "../../../../core/helpers/dateTimeHelper";
import { SubRow } from "../../../../ui-lib/components/Tables/SubRow";
import { RolesCell } from "../../../../ui-lib/components/Tables/RolesCell";
import { DeleteAdministratorModal } from "../../../Administrators/AdministratorDetails/DeleteAdministratorModal";

let openHierarchyTree: Function;
const PAGE_ID = "adminportal/alarm-receptions/respondents";

const columns = (t: (key: string) => string) => [
  {
    key: "username",
    header: t("Common:username"),
    fieldTemplate: (rowData: AdministratorMeta) => {
      const link = rowData.identityId
        ? `/adminportal/identity/${rowData.identityId}` // this page will point to Edit Administrator but as there are some work to do there before we can link to it we use this link for now
        : `/adminportal/administrators/${rowData.roles[0].id.toString()}`;
      return <TableCell value={rowData.userName} linkTo={link} />;
    },
  },
  {
    key: "name",
    header: t("Common:name"),
    fieldTemplate: (rowData: AdministratorMeta) => (
      <TableCell value={rowData.name} />
    ),
  },
  {
    key: "active",
    header: t("Administrator:table_column_active"),
    fieldTemplate: (rowData: AdministratorMeta) => {
      const badgeTitle = rowData.active
        ? t("Administrator:active")
        : t("Administrator:inactive");
      const badgeVariant = rowData.active ? "success" : "secondary";

      return <Badge title={badgeTitle} variant={badgeVariant} />;
    },
  },
  {
    key: "dateAdded",
    header: t("Administrator:table_column_date_added"),
    fieldTemplate: (rowData: AdministratorMeta) => (
      <TableCell
        value={
          rowData.created ? DateTimeHelper.formatDate(rowData.created) : ""
        }
      />
    ),
  },
  {
    key: "role",
    header: t("Common:role_template"),
    style: { padding: "0" },
    fieldTemplate: ({ roles: Roles }: AdministratorMeta) =>
      Roles.map((role, i) => {
        const legacyAdmin = role.templateName === "LegacyAdmin";

        if (legacyAdmin) {
          return (
            <>
              <TableCell
                value={t("Administrator:LegacyAdmin")}
                className="nodata"
                leftIcon={
                  role.type === "respondent" ? "phone-incoming" : "user"
                }
                leftIconTooltip={t(`Common:${role.type}`)}
                iconColor="Grey-300"
              />
            </>
          );
        }
        return (
          <>
            <SubRow
              key={role.id}
              displayDivider={i + 1 !== Roles.length}
              value={role.templateName}
              // linkTo={`/adminportal/administrators/${role.roleId.toString()}`} // use this when the feature for opening up a role exists
              linkTo="/adminportal/administrators"
              icon={role.type === "respondent" ? "phone-incoming" : "user"}
              hierarchyButton={undefined}
            />
          </>
        );
      }),
  },
  {
    key: "belongs",
    style: { padding: "0" },
    header: t("Common:belongs_to"),
    fieldTemplate: ({ roles: Roles }: AdministratorMeta) =>
      Roles.map((role, i) => (
        <RolesCell
          key={role.id}
          role={role}
          index={i}
          rolesLength={Roles.length}
          openHierarchyTree={openHierarchyTree}
        />
      )),
  },
];

export const RespondentsTable = ({
  receptionData,
}: {
  receptionData: ResponseCenterDetails;
}) => {
  const t = useTranslations();
  const history = useHistory();
  const { authenticatedRequest } = useUser();
  const [pageFilter, setPageFilter] = useState({
    page: 1,
    pageSize: 10,
  });
  const searchState = localStorage.getItem(PAGE_ID);
  const [searchFilter, setSearchFilter] = useState(searchState || "");
  const [selected, setSelected] = useState<AdministratorMeta>();
  const deleteModalRef = useRef<ModalActions>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState<AdministratorMeta[]>([]);

  const [items, setItems] = useState<AdministratorMeta[]>([]);

  const fetchParams = {
    responsecenter: receptionData.id,
    page: pageFilter.page,
    pageSize: 2000, // extend endpoint to include total amount of items
    q: searchFilter.length ? searchFilter : undefined,
  };

  const resetPageFilter = () => {
    setPageFilter({
      page: 1,
      pageSize: 10,
    });
  };

  const fetchTableData = async (reset = false) => {
    try {
      setIsLoading(true);
      const result = await getAdminRoles(
        reset ? { ...fetchParams, page: 1 } : fetchParams,
        authenticatedRequest
      );
      setTableData(result.data.admins);
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    } finally {
      setIsLoading(false);
    }
  };

  const onTableClear = () => {
    resetPageFilter();
    setSearchFilter("");
  };

  useEffect(() => {
    fetchTableData();
  }, [searchFilter]);

  useEffect(() => {
    if (tableData.length) {
      setItems(
        tableData.slice(
          (pageFilter.page - 1) * pageFilter.pageSize,
          (pageFilter.page - 1) * pageFilter.pageSize + pageFilter.pageSize
        )
      );
    } else {
      setItems([]);
    }
  }, [tableData, pageFilter]);

  return (
    <>
      <Spacer size={36} />
      <Row style={{ width: "100%", flex: 0 }}>
        <h2>{t("Menu:AlarmReceptions_Respondents")}</h2>
        <Spacer />
        <Modal
          trigger={() => (
            <Button
              size="small"
              variant="secondary"
              image="phone-incoming"
              text={t("AlarmReceptions:add_respondent")}
            />
          )}
        >
          {(close) => (
            <CreateRespondentModal
              onClose={close}
              receptionData={receptionData}
            />
          )}
        </Modal>
      </Row>
      <Spacer size={16} />
      <Row className={layoutStyles.settingsRow}>
        <SearchInput
          placeholder={t("Contacts:common_contacts_search_placeholder")}
          value={searchFilter ?? ""}
          limit={1}
          onChange={(value) => {
            setSearchFilter(value);
          }}
        />
      </Row>
      <Spacer size={8} />
      <hr className={styles.horizontalRule} />
      <Spacer size={8} />
      <Column className={layoutStyles.tableContainer} type="top">
        {isLoading ? (
          <LoadingSpinner theme="primary" />
        ) : (
          <>
            <Modal modalRef={deleteModalRef}>
              {(close) => (
                <DeleteAdministratorModal
                  administratorId={
                    (!!selected?.identityId && selected?.identityId) ||
                    (!!selected?.roles[0].id && selected?.roles[0].id) ||
                    ""
                  }
                  identity={!!selected?.identityId}
                  onClose={close}
                  onDelete={() => {
                    close();
                    fetchTableData(true);
                  }}
                />
              )}
            </Modal>
            {tableData.length > 0 ? (
              <Table<AdministratorMeta>
                columns={columns(t) as any}
                hideEmptyMessage
                items={items}
                showRowHover
                withPagination
                withLazyLoading
                paginatedItems={{
                  items,
                  pagination: {
                    offset: pageFilter.pageSize * (pageFilter.page - 1),
                    limit: pageFilter.pageSize,
                    total: tableData.length,
                  },
                }}
                onPageChange={(nextPage) => {
                  const page = Math.floor(nextPage.offset / nextPage.limit);

                  if (!Number.isNaN(page) && nextPage.limit) {
                    setPageFilter({
                      page: page + 1,
                      pageSize: nextPage.limit,
                    });
                  }
                }}
                rowActions={
                  [
                    {
                      icon: "pencil-alt",
                      text: t("Common:label_edit"),
                      onClick: (rowData: AdministratorMeta) =>
                        history.push(
                          rowData.identityId
                            ? `/adminportal/identity/${rowData.identityId}` // this page will point to Edit Administrator but as there are some work to do there before we can link to it we use this link for now
                            : `/adminportal/administrators/${rowData.roles[0].id.toString()}`
                        ),
                    },
                    {
                      icon: "trash",
                      text: t("Common:delete"),
                      iconVariant: "secondary",
                      onClick: (rowData: any) => {
                        setSelected(rowData);
                        deleteModalRef?.current?.open();
                      },
                    },
                  ] as any
                }
                rowActionsFixed
                rowActionsColWidth={64}
              />
            ) : (
              <NoItems
                title={
                  !searchFilter.length
                    ? t("Table:noresult_title")
                    : t("Table:no_results_found")
                }
                icon={!searchFilter.length ? "eye-off" : undefined}
                subTitle={
                  !searchFilter.length
                    ? t("Table:noresult_subtitle")
                    : t("Table:adjust_filter_description")
                }
                clear={
                  searchFilter.length
                    ? {
                        text: t("Table:clear_filters"),
                        onClick: onTableClear,
                      }
                    : undefined
                }
              />
            )}
          </>
        )}
      </Column>
    </>
  );
};
