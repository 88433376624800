import { Dispatch, SetStateAction } from "react";
import { observer } from "mobx-react-lite";
import moment from "moment";

import useUser from "../../../core/user/useUser";
import useTranslations from "../../../core/i18n/useTranslations";
import useSharedObject from "../SharedObjectContext/useSharedObject";
import DatePicker from "../../../ui-lib/components/DateAndTime/DatePicker";
import LoadingSpinner from "../../../ui-lib/components/Loading/LoadingSpinner";

const DateOfDeathModal = ({
  setDeceasedDate,
  deceasedDate,
}: {
  setDeceasedDate: Dispatch<SetStateAction<Date | undefined>>;
  deceasedDate?: Date;
}) => {
  const t = useTranslations();
  const { data, isLoading } = useSharedObject();
  const { dateFormat, config } = useUser();

  const date = !!data?.dateOfDeath;

  const timezone = config?.timezone.timezoneName!;

  if (isLoading) {
    return <LoadingSpinner theme="primary" />;
  }

  return (
    <>
      {!date ? (
        <DatePicker
          label={t("Common:labels_dod")}
          value={deceasedDate}
          placeholder={dateFormat?.split("/").join("-")}
          onChange={(dateString) => {
            setDeceasedDate(dateString ?? undefined);
          }}
          maxDate={moment().add("1d").tz(timezone).toDate()}
          dateFormat={dateFormat?.split("/").join("-")}
        />
      ) : (
        <p style={{ color: "var(--Grey-600)" }}>
          {t("Messages:date_of_death_description")}
        </p>
      )}
    </>
  );
};

export default observer(DateOfDeathModal);
