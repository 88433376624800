import { AxiosInstance } from "axios";
import { IpWhiteList } from "../../../core/api/organizations/types";
import { putResellerIP } from "../../../core/api/sellers/sellers";
import useTranslations from "../../../core/i18n/useTranslations";
import BulkDeleteModal from "../../../components/BulkDeleteModal";

export const ResellerDeleteIPModal = ({
  sellerId,
  authenticatedRequest,
  isOpen,
  filtersToDelete,
  filtersData,
  onClose,
  onDelete,
}: {
  sellerId: number;
  authenticatedRequest: AxiosInstance;
  isOpen: boolean;
  filtersToDelete: string[];
  filtersData?: IpWhiteList;
  onClose: () => void;
  onDelete: (success: boolean) => void;
}) => {
  const t = useTranslations();

  const deleteData = filtersData?.filters.filter(
    (item) => !filtersToDelete.includes(item.id.toString())
  );

  return (
    <BulkDeleteModal
      isOpen={isOpen}
      onClose={onClose}
      onDelete={onDelete}
      labels={{
        single: t("AlarmReceptions:alarm_ip_list_type"),
        multi: t("AlarmReceptions:alarm_ip_list_type_multi"),
      }}
      ids={[sellerId.toString()]}
      apiFunc={() =>
        putResellerIP(sellerId, authenticatedRequest, {
          filters: deleteData || [],
        })
      }
    />
  );
};
