import { toSafeInteger } from "lodash";
import { useCallback, useMemo } from "react";
import { useQueryState } from "./useQueryState";

type CustomerState = { organizationId?: string };
type Organizationfilter = { organizationId?: number };

const toCustomerFilter = (state: CustomerState): Organizationfilter => ({
  organizationId: state.organizationId
    ? toSafeInteger(state.organizationId)
    : undefined,
});

const toCustomerState = (filter: Organizationfilter): CustomerState => ({
  organizationId: filter.organizationId?.toString(),
});

const DEFAULT: CustomerState = {
  organizationId: undefined,
};

export const useCustomerFilter = (searchObj?: any) => {
  const stateToUse = !searchObj
    ? DEFAULT
    : {
        organizationId: searchObj.organizationId,
      };

  const [state, setState] = useQueryState<CustomerState>(stateToUse);

  const customerFilter = useMemo(() => toCustomerFilter(state), [state]);

  const setCustomerFilter = useCallback(
    (args: Organizationfilter) => setState(toCustomerState(args)),
    [setState]
  );

  const resetCustomerFilter = useCallback(() => setState(DEFAULT), [setState]);

  return { customerFilter, setCustomerFilter, resetCustomerFilter };
};
