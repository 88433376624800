import { useEffect, useState } from "react";
import { Row, Spacer } from "../../../components/Layout/Layout";
import { IntegrationsTable } from "./IntegrationsTable";
import {
  getIntegrationTypes,
  getOrganizationIntegrations,
} from "../../../core/api/organizations/organizations";
import { notifyApiErrors } from "../../../core/helpers/helpers";
import { AddIntegartionsModal } from "./AddIntegrationsModal";
import {
  IAllIntegrationTypes,
  IOrgIntegration,
} from "../../../core/api/organizations/types";
import useTranslations from "../../../core/i18n/useTranslations";
import Button from "../../../ui-lib/components/Button/Button";
import useUser from "../../../core/user/useUser";
import LoadingSpinner from "../../../ui-lib/components/Loading/LoadingSpinner";

import styles from "./Integrations.module.css";

export const Integrations = ({ id }: { id: string }) => {
  const t = useTranslations();
  const { authenticatedRequest } = useUser();

  const [loading, setLoading] = useState(false);
  const [integrations, setIntegrations] = useState<IOrgIntegration[]>([]);
  const [integrationTypes, setIntegrationTypes] =
    useState<IAllIntegrationTypes>();
  const [addIntegrationOpen, setAddIntegrationOpen] = useState(false);

  const fetchIntegrationTypes = async () => {
    setLoading(true);
    try {
      const response = await getIntegrationTypes(authenticatedRequest);
      setIntegrationTypes(response.data);
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    }
  };

  const fetchIntegrations = async () => {
    setLoading(true);
    try {
      const res = await getOrganizationIntegrations(id, authenticatedRequest);
      setIntegrations(res.data);
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchIntegrationTypes();
    fetchIntegrations();
  }, []);

  return (
    <div className={styles.integrationWrapper}>
      <h1>{t("Menu:intergations_instances")}</h1>
      <Spacer size={16} />
      <Row type="space" style={{ width: "100%" }}>
        <p className={styles.integrationDescription}>
          {t("Organizations:intergations_instances_description")}
        </p>
        <Button
          variant="secondary"
          text={t("Organizations:add_instance")}
          image="chip"
          onClick={() => setAddIntegrationOpen(true)}
        />
      </Row>
      <Spacer size={32} />
      {loading ? (
        <LoadingSpinner theme="primary" />
      ) : (
        <IntegrationsTable
          data={integrations}
          types={integrationTypes}
          getOrganizationIntegrations={fetchIntegrations}
        />
      )}
      {addIntegrationOpen && (
        <AddIntegartionsModal
          isNew
          getOrganizationIntegrations={fetchIntegrations}
          open={addIntegrationOpen}
          close={() => setAddIntegrationOpen(false)}
          types={integrationTypes}
          orgId={id}
        />
      )}
    </div>
  );
};
