import TimePicker from "../../../../../ui-lib/components/DateAndTime/TimePicker";

export const decodeTime = (time?: string) =>
  time?.length === 5 ? time.replace(":", "") : undefined;

export const encodeTime = (time?: string) =>
  time?.length === 4
    ? `${time.substring(0, 2)}:${time.substring(2, 4)}`
    : undefined;

export const TimeControl = ({
  value,
  onChange,
  disabled,
}: {
  value?: string;
  onChange: (value?: string) => void;
  disabled?: boolean;
}) => (
  <TimePicker
    value={encodeTime(value)}
    onChange={(time) => onChange(decodeTime(time))}
    disabled={disabled}
  />
);
