import { createContext, useEffect, useRef, useState } from "react";
import { AxiosResponse } from "axios";
import {
  getARCSubscriptions,
  getAdvancedSettings,
  getResponseCenter,
  updateResponseCenter,
} from "../../../../core/api/responsecenters/responsecenters";
import {
  AlarmReceptionAdvancedSettings,
  IReceptionSubscription,
  ResponseCenterDetails,
  ResponseCenterDetailsParams,
} from "../../../../core/api/responsecenters/types";
import { isEmpty, notifyApiErrors } from "../../../../core/helpers/helpers";
import useUser from "../../../../core/user/useUser";
import useTranslations from "../../../../core/i18n/useTranslations";
import { notify } from "../../../../ui-lib/components/Alerts/Toast";

// TODO: Create generic context for this purpose (this one is very similar to SharedObjectContext)
export const SharedAlarmReceptionContext = createContext<{
  data?: ResponseCenterDetails;
  subscriptions?: IReceptionSubscription[];
  isLoading: boolean;
  isError: boolean;
  update: (alarmReceptionDetails: ResponseCenterDetailsParams) => Promise<void>;
  errorMessage: string;
  reload: () => Promise<void>;
  refetchSubscriptions: () => Promise<void>;
}>({
  data: undefined,
  subscriptions: undefined,
  isLoading: false,
  isError: false,
  errorMessage: "",
  update: async () => {},
  reload: async () => {},
  refetchSubscriptions: async () => {},
});

export const SharedAlarmReceptionProvider = ({
  id,
  children,
}: {
  id: string;
  children: React.ReactNode;
}) => {
  const isAlive = useRef(false);
  const { authenticatedRequest, config } = useUser();
  const t = useTranslations();
  const [data, setData] = useState<ResponseCenterDetails>();
  const [subscriptions, setSubscriptions] = useState<IReceptionSubscription[]>(
    []
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>();

  const fetchResponseCenter = async () => {
    setIsError(false);
    setIsLoading(true);

    try {
      let advancedSettings: AxiosResponse<AlarmReceptionAdvancedSettings> =
        {} as AxiosResponse<AlarmReceptionAdvancedSettings>;
      const result = await getResponseCenter(id, authenticatedRequest);

      // Ignore fetching advanced settings if no permission for that.
      // This need for do not block fetching of ARC data.
      if (config?.show.includes("RcAdvancedSettings")) {
        advancedSettings = await getAdvancedSettings(id, authenticatedRequest);
      }

      if (isAlive.current) {
        if (isEmpty(result.data.dtmfSendingDigit)) {
          result.data.dtmfSendingDigit = "";
        }
        const tmp = {
          ...result.data,
          ...advancedSettings.data,
        };
        setData(tmp);
      }
    } catch (error: any) {
      if (isAlive.current) {
        if (error.message.includes("403")) {
          setErrorMessage(
            `${t("Errors:no_object_rights")}
            ${t("Errors:contact_admin")}`
          );
        } else {
          setErrorMessage(t("Errors:open_problem"));
        }
        setIsError(true);
      }
    }

    if (isAlive.current) {
      setIsLoading(false);
    }
  };

  const update = async (responseCenterDetails: ResponseCenterDetailsParams) => {
    setIsLoading(true);
    // This need because ARC update is require advanced settings fetch. So if you havn't
    // such permission you not able to update ARC
    if (!config?.show.includes("RcAdvancedSettings")) {
      notify({
        message:
          "Could not update alarm reception. Please contact your system administrator if you need help.",
        variant: "error",
      });
      throw new Error("Error");
    }
    try {
      const { data: response } = await updateResponseCenter(
        id,
        responseCenterDetails,
        authenticatedRequest
      );
      if (response) {
        const newData = await getResponseCenter(id, authenticatedRequest);
        const advancedSettings = await getAdvancedSettings(
          id,
          authenticatedRequest
        );
        if (isAlive.current) {
          setData({
            ...newData.data,
            ...advancedSettings.data,
          });

          setIsLoading(false);
        }
      } else {
        throw new Error("Could not update alarm reception");
      }
    } catch (error) {
      if (isAlive.current) {
        setIsLoading(false);

        throw error;
      }
    }
  };

  const getAllArcSubscriptions = async () => {
    setIsLoading(true);
    try {
      const response = await getARCSubscriptions(+id, authenticatedRequest);
      setSubscriptions(response.data);
    } catch (e: any) {
      notifyApiErrors(e.response?.data?.errors);
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch alarm reception on mount and when alarm reception is updated
  useEffect(() => {
    getAllArcSubscriptions();
    fetchResponseCenter();
  }, [id]);

  // To make sure we do not try to update state if component did unmount
  useEffect(() => {
    isAlive.current = true;

    return () => {
      isAlive.current = false;
    };
  }, []);

  return (
    <SharedAlarmReceptionContext.Provider
      value={{
        data,
        subscriptions,
        isLoading,
        isError,
        errorMessage,
        update,
        reload: fetchResponseCenter,
        refetchSubscriptions: getAllArcSubscriptions,
      }}
    >
      {children}
    </SharedAlarmReceptionContext.Provider>
  );
};
