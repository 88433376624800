import API_HOST from "./apiHost";
import useAuthenticatedFetch from "./useAuthenticatedFetch";

export interface ExternalIntegration {
  externalIntegrationId?: number;
  identifierLabel?: string;
  identifierRegexp?: string;
  name?: string;
  requireCustomerId?: boolean;
  requireServiceId?: boolean;
}

export const useExternalIntegrations = () => {
  const { data, isLoading, isError } = useAuthenticatedFetch<
    ExternalIntegration[]
  >(`${API_HOST}api/v2/users/externalintegrations`);

  return { data, isLoading, isError };
};
