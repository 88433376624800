import { TFunction } from "i18next";
import { Control, Controller } from "react-hook-form";
import {
  HierarchyUsage,
  NewRoleTemplate,
} from "../../core/api/roleTemplates/types";
import Dropdown from "../../ui-lib/components/Dropdown/Dropdown";
import { Column } from "../Layout/Layout";

export const HierarcyDropdown = ({
  control,
  errors,
  t,
  setValue,
  disable,
}: {
  control: Control<NewRoleTemplate>;
  errors: any;
  t: TFunction;
  setValue: Function;
  disable: boolean;
}) => (
  <>
    <Column>
      <Controller
        name="hierarchyUsage"
        control={control}
        rules={{
          required: true,
        }}
        render={({ field }) => {
          const dropdownItems = [
            {
              id: HierarchyUsage.ThisOrganization,
              name: t("Administrator:this_organization"),
              isSelected: HierarchyUsage.ThisOrganization === field.value,
            },
            {
              id: HierarchyUsage.OneBellow,
              name: t("Administrator:one_below"),
              isSelected: HierarchyUsage.OneBellow === field.value,
            },
            {
              id: HierarchyUsage.AllBellow,
              name: t("Administrator:all_below"),
              isSelected: HierarchyUsage.AllBellow === field.value,
            },
          ];

          return (
            <Dropdown
              required
              title={t("Administrator:usable_by")}
              width="100%"
              selectedItem={dropdownItems.find((i) => i.isSelected)}
              items={dropdownItems}
              onSelectItem={(item) => {
                setValue(field.name, item.id as number);
              }}
              validationError={
                errors.hierarchyUsage && t("Errors:input_field_required")
              }
              disabled={disable}
            />
          );
        }}
      />
    </Column>
  </>
);
