import Dropdown from "../../ui-lib/components/Dropdown/Dropdown";
import useLanguage from "../../core/i18n/useLanguage";
import { Column } from "../Layout/Layout";
import useTranslations from "../../core/i18n/useTranslations";

export const LanguageDropdown = ({
  label,
  selected,
  onSelect,
  width = "100%",
  disabled,
  withClearItem,
}: {
  label?: string;
  selected?: string;
  onSelect: (args?: string) => void;
  width?: string | number;
  disabled?: boolean;
  withClearItem?: boolean;
}) => {
  const { languages } = useLanguage();
  const t = useTranslations();

  const items = languages.map((l) => ({
    id: l.id,
    name: `${t(`Languages:${l.region}`)} (${t(
      `Languages:description-${l.nativeName}`
    )})`,
    flag: l.flagIconKey,
    isSelected: l.id === selected,
  }));

  const selectedItem = items.find((i) => i.isSelected);

  return (
    <Column align="start" style={{ width: "100%" }}>
      {label && <span className="strong">{label}</span>}
      <Dropdown
        selectedItem={selectedItem}
        items={items}
        withClearItem={withClearItem}
        onSelectItem={(item) => {
          onSelect(item.id.toString());
        }}
        width={width}
        disabled={disabled}
      />
    </Column>
  );
};
