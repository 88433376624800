import { AxiosInstance } from "axios";
import API_HOST from "./apiHost";

export interface TransmitterInteraction {
  batteryCharge?: string;
  batteryChargeExternal?: string;
  latitude?: string;
  longitude?: string;
  message?: string;
  reportedTime?: string;
  serverTime: string;
  transmitterId?: number;
  transmitterIdentifier?: string;
  transmitterProduct?: string;
  alarmCode?: number;
  alarmId?: number;
}

const getTransmitterInteractions = (
  authenticatedRequest: AxiosInstance,
  transmitterId: string,
  fromDate: string,
  untilDate: string
) =>
  authenticatedRequest.get<TransmitterInteraction[]>(
    `${API_HOST}api/v2/transmitters/${transmitterId}/clientupdates?from=${fromDate}&to=${untilDate}`
  );

export { getTransmitterInteractions };
