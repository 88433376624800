import classNames from "classnames";
import styles from "./Tabs.module.css";
import Icon from "../Icon";

export interface ITab {
  id: number;
  name: string;
  isSelected: boolean;
  icon?: string;
}

export interface TabsProps {
  items: ITab[];
  onSelect(arg: number): void;
  disabled?: boolean;
}

interface TabProps {
  isSelected: boolean;
  isDisabled?: boolean;
  name: string;
  onSelect(): void;
  icon?: string;
}

function Tab({
  isSelected,
  isDisabled,
  name,
  onSelect,
  icon = undefined,
}: TabProps) {
  const tabClasses = classNames({
    [styles.tab]: true,
    [styles.selectedTab]: isSelected,
    [styles.disabledTab]: isDisabled,
  });
  return (
    <div
      className={tabClasses}
      onClick={onSelect}
      role="button"
      onKeyDown={(e) => {
        if (e.code === "Space" || e.code === "Enter") {
          e.preventDefault();
          onSelect();
        }
      }}
      tabIndex={0}
    >
      {icon && (
        <Icon
          className={styles.tabIcon}
          color={isSelected ? "Primary-700" : "grey"}
          name={icon}
          size={24}
        />
      )}
      {name}
    </div>
  );
}
export default function Tabs({ items, onSelect, disabled }: TabsProps) {
  return (
    <div className={styles.container}>
      {items.map((tab) => (
        <>
          <Tab
            key={tab.id}
            icon={tab.icon}
            isSelected={tab.isSelected}
            isDisabled={disabled}
            name={tab.name}
            onSelect={() => {
              if (disabled) {
                return;
              }
              onSelect(tab.id);
            }}
          />
        </>
      ))}
    </div>
  );
}
