import { observer } from "mobx-react-lite";
import { getErrorKey } from "../../../components/Errors/ErrorAlert";
import { createResponseCenter } from "../../../core/api/responsecenters/responsecenters";
import {
  ResponseCenterResponse,
  NewResponseCenterParams,
} from "../../../core/api/responsecenters/types";
import useTranslations from "../../../core/i18n/useTranslations";
import useUser from "../../../core/user/useUser";
import { notify } from "../../../ui-lib/components/Alerts/Toast";
import { CreateAlarmReceptionForm } from "./CreateAlarmReceptionForm";
import { ITreeData } from "../../../ui-lib/components/Hierarchy/Tree";

const CopyAlarmReceptionModal = ({
  isOpen,
  copyObject,
  onClose,
  onCopy,
  treeData,
  organizationsById,
  organizationId,
}: {
  isOpen: boolean;
  treeData: ITreeData[];
  copyObject?: ResponseCenterResponse;
  onClose: () => void;
  onCopy: () => void;
  organizationsById?: ITreeData | null;
  organizationId?: number;
}) => {
  const t = useTranslations();
  const { authenticatedRequest } = useUser();

  const onSubmit = async (params: NewResponseCenterParams) => {
    if (!copyObject) {
      return;
    }

    try {
      await createResponseCenter(
        { ...params, copyFromResponseCenterId: copyObject.id },
        authenticatedRequest
      );
      notify({
        message: t("Messages:create_alarm_reception_success"),
      });
      onCopy();
      onClose();
    } catch (error) {
      const errorKey = getErrorKey(error);
      notify({
        message: t(`Errors:${errorKey}`),
        variant: "error",
      });
    }
  };

  return (
    <CreateAlarmReceptionForm
      isOpen={isOpen}
      labels={{
        title: t("AlarmReceptions:copy_alarm_reception"),
        submit: t("AlarmReceptions:create"),
      }}
      withCopySettings
      defaultValues={{
        organizationId: copyObject?.organizationId,
      }}
      organisations={
        organizationId ? ([organizationsById] as ITreeData[]) : treeData
      }
      onSubmit={onSubmit}
      onClose={onClose}
    />
  );
};

export default observer(CopyAlarmReceptionModal);
