import { RespondentGroup } from "../responsecenters/types";

export interface AdministratorResponse {
  id: number;
  adminId?: number;
  username: string;
  name?: string;
  initials?: string;
  type: string;
  sellerId: number;
  seller: string;
  organizationId?: number;
  organization?: string;
  responseCenterId?: number;
  responseCenter?: string;
  loginId?: number;
  belongsTo: string;
  telephoneNumber?: string;
  twoFactorAuthenticationData?: string;
  email?: string;
  passwordResetPreference: number;
  restrictLogin: boolean;
  forcePasswordReset: boolean;
}
export interface SupportData {
  emailAdministrative: string;
  emailTechnicalSupport: string;
  phoneAdministrative: string;
  phoneTechnicalSupport: string;
}

export interface AdministratorDetails {
  active: boolean;
  id: number;
  username: string;
  organization?: string;
  organizationId?: number;
  name?: string;
  telephone?: string;
  email?: string;
  belongsTo?: string;
  primaryLanguage?: string;
  secondaryLanguage?: string;
  timezoneId?: number;
  dateTimeFormatId?: number;
  initials?: string;
  twoFactorAuthenticationType?: string;
  twoFactorAuthenticationData?: string;
  roles?: string[];
  type: string;
  password: string;
  respondentGroups?: RespondentGroup[];
  passwordResetPreference?: number;
  responseCenter?: string;
  responseCenterId?: number | string;
  loginId?: string | null;
  passwordConstraints?: PasswordConstraints;
  restrictLogin?: boolean;
  validTo?: string | null;
  mainSettings?: boolean;
  menuAlternatives?: boolean;
  adminRespondentGroups?: boolean;
  adminGUIConfigs?: boolean;
  adminRoles?: boolean;
  sellerId?: number;
  seller?: string;
  forcePasswordReset?: boolean;
  canBeImpersonated?: boolean;
  notifyIfNotLoggedIn?: number;
  readOnly?: boolean;
  displayName?: string;
  isRole: boolean;
  identityId?: number;
  operatorAccess: OperatorAccessType;
}

export interface IAdminCopy {
  username: string;
  name?: string;
  password: string;
  mainSettings: boolean;
  menuAlternatives: boolean;
  adminRespondentGroups: boolean;
  adminGUIConfigs: boolean;
  adminRoles: boolean;
  organizationId?: number;
  emergencyCenterId?: number;
  sellerId?: number;
  forcePasswordReset: boolean;
}

export interface AdministratorView {
  admins: AdministratorMeta[];
  currentRoleAmount: number;
  numPages: number;
  totalRoleAmount: number;
}

export interface AdministratorMeta {
  userName: string;
  name?: string;
  active: boolean;
  created?: Date;
  identityId?: string;
  lastLogin?: Date;
  currentRoleAmount: number;
  roles: Role[];
  totalRoleAmount: number;
}

export interface Role {
  active: boolean;
  created?: Date;
  id: number;
  lastLogin?: Date;
  organization?: string;
  organizationId?: number;
  responseCenter?: string;
  responseCenterId?: number;
  seller?: string;
  sellerId?: number;
  templateName: string;
  type: string;
}

export interface RoleMapping {
  adGroup: string;
  roleTemplateId: number;
  roleTemplateName: string;
  type: string;
  belongsToId: number;
  belongsTo: string;
}

export interface Timezone {
  id: number;
  timezoneNameId: string;
  timezoneName: string;
  timeFormat: string;
  nameTranslationKey: string;
  descriptionTranslationKey: string;
  datetimeFormat: string;
}

export interface DateTimeFormat {
  id: number;
  dateTimeFormat: string;
  nameTranslationKey: string;
  descriptionTranslationKey: string;
}

export interface AvailableRoles {
  [key: string]: { [key: string]: string[] };
}

export interface PasswordConstraints {
  minLength: number;
  minUpperCase: number;
  minLowerCase: number;
  minNumbers: number;
  forbiddenChars: string;
}

export interface IUserSuggest {
  canCreateUser: boolean;
  organizationId?: number | string;
  existingUser: number;
  name?: string;
  subOrganizationId?: number | string;
  subscriptionId?: number | string;
  transmitterId?: number | string;
  transmitterIdentifier?: number | string;
  transmitterProduct?: number | string;
  transmitterProductType?: number | string;
  adminId?: number | string;
}

export interface IAdminTemplate {
  name: string;
  templateId: number;
}

export interface ITwoFactorData {
  adminId: number;
  preSharedSecret: string;
  twoFactorAuthType: string;
}

export interface ISpecialSettings {
  id: number;
  setting: string;
  value: string;
}

export enum OperatorAccessType {
  NoAccess = 0,
  MobileResponse = 1,
  WebCentral = 2,
  MobileResponseAndWebCentral = 3,
  Integrations = 4,
}
