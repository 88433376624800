import useLanguage from "../../core/i18n/useLanguage";
import Tabs from "../../ui-lib/components/Tabs/Tabs";
import Divider from "../../ui-lib/components/Divider/Divider";
import Dropdown from "../../ui-lib/components/Dropdown/Dropdown";
import TextInput from "../../ui-lib/components/Inputs/TextInput";
import TextArea from "../../ui-lib/components/Inputs/Textarea";
import Checkbox from "../../ui-lib/components/Checkbox/Checkbox";
import { Row, Column, Spacer } from "../Layout/Layout";
import { LanguageDropdown } from "../LanguageDropdown/LanguageDropdown";
import { PeriodicReportMode, PeriodicReportOptions } from "./types";
import styles from "./ExportSettings.module.css";
import { PeriodicReportFrequency } from "../../core/api/reports/types";
import useTranslations from "../../core/i18n/useTranslations";

const modes = [PeriodicReportMode.ExportNow, PeriodicReportMode.Periodic];

const frequencies = [
  PeriodicReportFrequency.Daily,
  PeriodicReportFrequency.Monthly,
  PeriodicReportFrequency.EveryMonday,
  PeriodicReportFrequency.EveryTuesday,
  PeriodicReportFrequency.EveryWednesday,
  PeriodicReportFrequency.EveryThursday,
  PeriodicReportFrequency.EveryFriday,
  PeriodicReportFrequency.EverySaturday,
  PeriodicReportFrequency.EverySunday,
];

const PeriodicReportSettings = ({
  options,
  setOptions,
  validationErrors,
  disabled,
}: {
  options: PeriodicReportOptions;
  setOptions: (args: PeriodicReportOptions) => void;
  validationErrors?: { [key: string]: string };
  disabled?: boolean;
}) => {
  const t = useTranslations();
  const { currentLanguage } = useLanguage();

  const frequencyItems = frequencies.map((id) => ({
    id,
    name: t(`Reports:frequency_${id}`),
    isSelected: id === options.frequency,
  }));

  return (
    <>
      <Tabs
        items={modes.map((id) => ({
          id,
          name: t(`Reports:export_mode_${id}`),
          isSelected: id === options.mode,
        }))}
        onSelect={(mode) => {
          setOptions({
            mode,
            ...(mode === PeriodicReportMode.Periodic
              ? {
                  frequency: PeriodicReportFrequency.Daily,
                  dontSendEmptyReports: true,
                  languange: currentLanguage?.region,
                }
              : { dontSendEmptyReports: false }),
          });
        }}
        disabled={disabled}
      />

      <Spacer size={8} />
      <Divider />

      {options.mode === PeriodicReportMode.Periodic && (
        <>
          <Spacer size={16} />
          <Row align="start">
            <Column align="start">
              <p className={styles.label}>{t("Common:report_frequency")}</p>
              <Dropdown
                selectedItem={frequencyItems.find((i) => i.isSelected)}
                width="100%"
                items={frequencyItems}
                onSelectItem={(i) => {
                  setOptions({ ...options, frequency: i.id as number });
                }}
                maxVisible={10}
                disabled={disabled}
              />
              <p className={styles.labelSmall}>
                {t("Common:report_frequency_description")}
              </p>
            </Column>
            <Spacer size={32} />
            <Column align="start">
              <p className={styles.label}>{t("Common:report_send_to")}</p>
              <TextInput
                type="email"
                value={options.email ?? ""}
                validationError={validationErrors?.email}
                onChange={(e) =>
                  setOptions({ ...options, email: e.currentTarget?.value })
                }
                placeholder={t("Common:placeholder_email")}
                disabled={disabled}
              />
            </Column>
          </Row>
          <Spacer size={16} />
          <Row align="start">
            <Column align="start">
              <p className={styles.label}>{t("Common:report_language")}</p>
              <LanguageDropdown
                selected={options.languange}
                onSelect={(languange) => setOptions({ ...options, languange })}
                width="100%"
                disabled={disabled}
              />
            </Column>
            <Spacer size={32} />
            <Column align="start" />
          </Row>
          <Spacer size={16} />
          <Column align="start">
            <p className={styles.label}>{t("Common:comment_to")}</p>
            <TextArea
              value={options.comment}
              onChange={(e) =>
                setOptions({ ...options, comment: e.currentTarget?.value })
              }
              maxSymbols={400}
              placeholder={t("Objects:placeholder_export_comment")}
              disabled={disabled}
            />
          </Column>
          <Column align="start">
            <Checkbox
              label={t("Objects:labels_empty_report")}
              checked={options.dontSendEmptyReports}
              style={{ width: "50%", padding: 0 }}
              onChange={() =>
                setOptions({
                  ...options,
                  dontSendEmptyReports: !options.dontSendEmptyReports,
                })
              }
              disabled={disabled}
            />
          </Column>
        </>
      )}
    </>
  );
};

export default PeriodicReportSettings;
