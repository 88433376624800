import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import Dropdown from "../../ui-lib/components/Dropdown/Dropdown";
import {
  IDropdownItem,
  IItemProp,
} from "../../ui-lib/components/Dropdown/DropdownItem";
import { Spacer } from "../Layout/Layout";
import ContactInfo from "./ContactInfo/ContactInfo";
import useLanguage from "../../core/i18n/useLanguage";
import ExternalLink from "../../ui-lib/components/ExternalLink/ExternalLink";
import useTranslations from "../../core/i18n/useTranslations";
import useBranding from "../../core/branding/useBranding";
import PrimeModal from "../../ui-lib/components/PrimeModal/PrimeModal";
import Button from "../../ui-lib/components/Button/Button";

import styles from "./Navigation.module.css";

const Navigation = () => {
  const brandingStore = useBranding();
  const languageStore = useLanguage();
  const t = useTranslations();
  const usersPreferredLanguage = brandingStore.currentSecondaryLanguages;
  const [languageItems, setLanguageItems] = useState<Array<IItemProp>>([]);
  const [infoModalOpen, setInfoModalOpen] = useState(false);

  useEffect(() => {
    if (usersPreferredLanguage && !languageItems.length) {
      setLanguageItems(
        languageStore.loginLanguages
          .filter((language) =>
            usersPreferredLanguage.includes(language.nativeName)
          )
          .map((l) => ({
            id: l.id,
            name: t(`Languages:LANGUAGE-${l.nativeName.toUpperCase()}`),
            flag: l.flagIconKey,
            isSelected: l.id === languageStore.getStoredLanguage(),
          }))
      );
    }
  }, [usersPreferredLanguage]);

  const changeSelectedLanguage = (item: IDropdownItem) => {
    languageStore.setCurrentLoginLanguageById(item.id as string);
    setLanguageItems(
      languageItems.map((langItem) => ({
        ...langItem,
        isSelected: langItem.id === languageStore.getStoredLanguage(),
      }))
    );
  };

  return (
    <>
      <nav className={styles.navigation}>
        <Link to="/login" className={styles.navigationItem}>
          {t("Menu:Login")}
        </Link>
        <Spacer size={32} />
        {/* hidden in the scope of DEV-1996 till further agreements */}
        {/* <Link to="/about" className={styles.navigationItem}>
          {t("Menu:About")}
        </Link>
        <Spacer size={32} /> */}
        <Button
          className={styles.navigationItem}
          variant="link"
          text={t("Menu:Support")}
          onClick={() => setInfoModalOpen(true)}
        />
        {!!brandingStore.currentPartnerLink && (
          <>
            <Spacer size={32} />
            <ExternalLink
              text={t(`Menu:partner_${brandingStore.customerKey}`)}
              url={brandingStore.currentPartnerLink || ""}
              className={styles.navigationItem}
              iconColor="Grey-500"
              targetBlank
            />
          </>
        )}
        <Spacer size={32} />
        <Dropdown
          className={styles.languageDropdown}
          ariaLable="Language dropdown"
          items={languageItems}
          placeholder={t(
            `Languages:LANGUAGE-${languageStore
              .getStoredLanguage()!
              .toUpperCase()}`
          )}
          onSelectItem={changeSelectedLanguage}
          withoutCheckIcon={false}
          buttonStyle={{
            plate: {
              border: "none",
              boxShadow: "none",
              padding: "0px",
            },
            text: {
              fontSize: "12px",
              lineHeight: "20px",
              color: "var(--Grey-500)",
            },
          }}
          width={100}
        />
      </nav>
      <PrimeModal
        isOpen={infoModalOpen}
        onClose={() => setInfoModalOpen(false)}
      >
        <ContactInfo onClose={() => setInfoModalOpen(false)} />
      </PrimeModal>
    </>
  );
};

export default observer(Navigation);
