import classNames from "classnames";
import { Column, Row, Spacer } from "../../../../../components/Layout/Layout";
import Icon from "../../../../../ui-lib/components/Icon";
import styles from "./CustomTableCell.module.css";

const Label = ({
  text,
  placeholder: _placeholder,
  color,
}: {
  text?: string;
  placeholder?: string;
  color?: string;
}) => {
  const hasValue = text && text.length > 0;
  const placeholder = _placeholder ?? "";

  return (
    <span
      className="text"
      style={{ color: hasValue ? color : "var(--Grey-400)" }}
    >
      {hasValue ? text : placeholder}
    </span>
  );
};

export const CustomTableCell = ({
  label = "",
  description,
  icon,
}: {
  label: string;
  description?: string;
  icon?: string;
}) => (
  <Column align="start" className={styles.customTableCell}>
    <Row className={styles.textWrapper}>
      {icon && (
        <>
          <Icon name={icon} size={16} color="Grey-300" />
          <Spacer size={8} />
        </>
      )}
      <Label text={label} />
    </Row>
    {description && (
      <Row className={styles.textWrapper}>
        <Label text={description} color="var(--Grey-600)" />
      </Row>
    )}
  </Column>
);

export const TimeCell = ({
  timeString,
  responseTime,
}: {
  timeString: string;
  responseTime: string;
}) => (
  <Column align="start" className={styles.customTableCell}>
    <Row className={styles.textWrapper}>
      <Icon name="clock" size={16} color="Grey-300" />
      <Spacer size={8} />
      <Label text={timeString} />
    </Row>
    <Row className={styles.textWrapper}>
      <Icon name="receipt-refund" size={16} color="Grey-300" />
      <Spacer size={8} />
      <Label text={responseTime} />
    </Row>
  </Column>
);

export const IntervalCell = ({
  interval,
  weekdays,
  iconSize = 16,
}: {
  interval?: string;
  iconSize?: number;
  weekdays: { label: string; isSelected: boolean }[];
}) => (
  <Column align="start" className={styles.customTableCell}>
    <Row className={styles.textWrapper}>
      <Icon name="calendar" size={iconSize} color="Grey-300" />
      <Spacer size={8} />
      {weekdays.map((wd, index) => (
        <span
          key={`wd${index}`}
          className={classNames("text", { strong: wd.isSelected })}
          style={{
            color: wd.isSelected ? "var(--Grey-800)" : "var(--Grey-600)",
            whiteSpace: "pre-wrap",
          }}
        >
          {`${wd.label} `}
        </span>
      ))}
    </Row>
    {interval && (
      <Row className={styles.textWrapper}>
        <Icon name="refresh" size={16} color="Grey-300" />
        <Spacer size={8} />
        <Label text={interval} />
      </Row>
    )}
  </Column>
);
