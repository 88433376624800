import { observer } from "mobx-react-lite";
import { Controller, useForm } from "react-hook-form";
import { getErrorKey } from "../../../../components/Errors/ErrorAlert";
import { Column, Row, Spacer } from "../../../../components/Layout/Layout";
import {
  createAlarmHandling,
  useRespondentGroups,
} from "../../../../core/api/responsecenters/responsecenters";
import {
  NewAlarmHandlingParams,
  RespondentGroupResponse,
} from "../../../../core/api/responsecenters/types";
import useTranslations from "../../../../core/i18n/useTranslations";
import useUser from "../../../../core/user/useUser";
import { notify } from "../../../../ui-lib/components/Alerts/Toast";
import Button from "../../../../ui-lib/components/Button/Button";
import Dropdown from "../../../../ui-lib/components/Dropdown/Dropdown";
import TextInput from "../../../../ui-lib/components/Inputs/TextInput";
import LoadingSpinner from "../../../../ui-lib/components/Loading/LoadingSpinner";
import ModalContent from "../../../../ui-lib/components/Popup/ModalContent";

const CreateAlarmHandlingForm = ({
  labels,
  respondentGroups,
  onSubmit,
  onClose,
}: {
  labels: {
    title: string;
    description?: string;
    submit: string;
  };
  respondentGroups: RespondentGroupResponse[];
  onSubmit: (values: NewAlarmHandlingParams) => Promise<void>;
  onClose: () => void;
}) => {
  const t = useTranslations();

  const {
    control,
    formState: { isSubmitting, errors },
    setValue,
    handleSubmit,
  } = useForm<NewAlarmHandlingParams>({
    defaultValues: {
      primaryRespondentGroupId:
        respondentGroups.length > 0 ? respondentGroups[0].id : undefined,
    },
  });

  return (
    <ModalContent onClose={onClose} width="848px">
      <h2 className="subTitle">{labels.title}</h2>
      {labels.description && (
        <>
          <Spacer size={16} />
          <p style={{ color: "var(--Grey-600)" }}>{labels.description}</p>
        </>
      )}

      <Spacer size={16} />

      <form
        onSubmit={(e) => {
          // To stop main form to submit
          e.preventDefault();
          e.stopPropagation();

          handleSubmit(onSubmit)(e);
        }}
      >
        <Row align="start">
          <Column>
            <Controller
              name="name"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextInput
                  required
                  label={t("Common:name")}
                  placeholder={t("AlarmReceptions:handling_name_placeholder")}
                  value={field.value}
                  onChange={field.onChange}
                  validationError={
                    errors.name && t("Errors:input_field_required")
                  }
                />
              )}
            />
          </Column>
          <Spacer size={32} />
          <Column>
            <Controller
              name="primaryRespondentGroupId"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => {
                const dropdownItems = respondentGroups.map(
                  (respondentGroup) => ({
                    id: respondentGroup.id,
                    name: respondentGroup.description,
                    isSelected: respondentGroup.id === field.value,
                  })
                );

                return (
                  <Dropdown
                    required
                    title={t(
                      "AlarmReceptions:alarm_handling_input_respondent_group_primary"
                    )}
                    width="376px"
                    selectedItem={dropdownItems.find((i) => i.isSelected)}
                    items={dropdownItems}
                    onSelectItem={(item) =>
                      setValue(field.name, item.id as number)
                    }
                    validationError={
                      errors.primaryRespondentGroupId &&
                      t("Errors:input_field_required")
                    }
                    showTooltip
                  />
                );
              }}
            />
          </Column>
        </Row>

        <Spacer size={32} />

        <Row type="left">
          <Button
            type="submit"
            variant="primary"
            text={labels.submit}
            loading={isSubmitting}
          />
          <Spacer size={8} />
          <Button
            variant="secondary"
            text={t("Common:cancel")}
            onClick={onClose}
          />
        </Row>
      </form>
    </ModalContent>
  );
};

const CreateAlarmHandlingModal = ({
  responseCenterId,
  onClose,
  onAdd,
}: {
  responseCenterId: number | string;
  onClose: () => void;
  onAdd: () => void;
}) => {
  const t = useTranslations();
  const { authenticatedRequest } = useUser();
  const { data: respondentGroups } = useRespondentGroups(responseCenterId);

  const onSubmit = async (params: NewAlarmHandlingParams) => {
    try {
      await createAlarmHandling(responseCenterId, params, authenticatedRequest);
      notify({
        message: t("Messages:create_alarm_handling_success"),
      });
      onAdd();
      onClose();
    } catch (error) {
      const errorKey = getErrorKey(error);
      notify({
        message: t(`Errors:${errorKey}`),
        variant: "error",
      });
    }
  };

  if (!respondentGroups) {
    return <LoadingSpinner theme="primary" />;
  }

  return (
    <CreateAlarmHandlingForm
      labels={{
        title: t("AlarmReceptions:alarm_handling_add"),
        description: t("AlarmReceptions:alarm_handling_add_description"),
        submit: t("AlarmReceptions:alarm_handling_create"),
      }}
      respondentGroups={respondentGroups}
      onSubmit={onSubmit}
      onClose={onClose}
    />
  );
};

export default observer(CreateAlarmHandlingModal);
