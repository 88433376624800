import { AxiosInstance } from "axios";
import API_HOST from "./apiHost";

export interface SubscriptionPeriod {
  subscriptionId: number;
  period: number;
}
export interface Seller {
  sellerId: number;
  name: string;
}
export interface Subscription {
  description: string;
  editOrganizationAccess: boolean;
  editSellerAccess: boolean;
  organizationAccess?: [];
  responseCenter?: string;
  responseCenterId?: number;
  sellerAccess?: Seller[];
  subscriptionGroupId?: number;
  subscriptionPeriods?: SubscriptionPeriod[];
  alarmHandlings: null;
}
export interface OrganisationSubscription {
  responseCenterId: number;
  subscriptionGroupDescription: string;
  subscriptionGroupId: number;
  subscriptionId: number;
  subscriptionPeriod: number;
}
export const getOrganisationSubscriptions = async (
  organisationId: number,
  authenticatedRequest: AxiosInstance
): Promise<OrganisationSubscription[]> => {
  const data = await authenticatedRequest.get<OrganisationSubscription[]>(
    `${API_HOST}api/v2/organizations/${organisationId}/subscription`
  );
  return data.data;
};
export const getSubscriptions = async (
  responseCenter: number,
  authenticatedRequest: AxiosInstance
): Promise<Subscription[]> => {
  const data = await authenticatedRequest.get<Subscription[]>(
    `${API_HOST}api/v2/responsecenters/${responseCenter}/subscription`
  );
  return data.data;
};
