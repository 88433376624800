const API_HOST = (() => {
  switch (process.env.NODE_ENV) {
    case "production":
      return "/"; // Call API on same host
    case "development":
    case "test":
    default:
      return "/"; // Using proxy in dev
  }
})();

export default API_HOST;
