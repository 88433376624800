import { AxiosInstance } from "axios";
import API_HOST from "../apiHost";
import { IIdentity } from "./types";

const getIdentity = async (
  id: string | number,
  authenticatedRequest: AxiosInstance
) => authenticatedRequest.get<IIdentity>(`${API_HOST}api/v2/identities/${id}`);

export { getIdentity };
