import { useLayoutEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import useTranslations from "../i18n/useTranslations";
import Modal from "../../ui-lib/components/Modal/Modal";
import { getStoredToken } from "../user/token";
import useUser from "../user/useUser";
import { useSaveObject } from "./hooks";
import usePageState from "../pagestate/usePageState";
import { SwitchVersionsModal } from "../../components/SwitchVersionsModal/SwitchVersionsModal";

export const UnsavedChanges = ({
  checkChanges,
}: {
  checkChanges?: boolean;
}) => {
  const t = useTranslations();
  const history = useHistory();
  const token = getStoredToken();
  const user = useUser();
  const { isDirty, onReset } = useSaveObject();
  const [modalOpen, setModalOpen] = useState(false);
  const [goTo, setGoTo] = useState<string>();
  const pageState = usePageState();
  const [showModalQuestionnaire, setShowModalQuestionnaire] = useState(false);

  const url = `/admin/#mw?username=${user.data?.username}&expires_in=${token?.expiresIn}&token_type=bearer&access_token=${token?.token}`;

  useLayoutEffect(() => {
    const unblock = history.block((location) => {
      // Allow navigation if no changes or if modal is open
      if (!isDirty || modalOpen || !checkChanges) {
        return undefined;
      }

      // Show modal and block navigation
      setGoTo(location.pathname);
      setModalOpen(true);
      return false;
    });

    return () => {
      unblock();
    };
  }, [isDirty, modalOpen, checkChanges]);

  return (
    <>
      <SwitchVersionsModal
        onClose={() => setShowModalQuestionnaire(false)}
        onAfterSubmit={() => {
          setShowModalQuestionnaire(false);
          onReset();
          window.location.href = url;
        }}
        isOpen={showModalQuestionnaire}
      />
      <Modal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        modalTitle={t("Common:unsaved_changes_title")}
        approveBtnText={t("Common:unsaved_changes_stay")}
        onApproveBtnClick={() => {
          pageState.setHeaderFlag(false);
          setModalOpen(false);
        }}
        cancelBtnText={t("Common:unsaved_changes_leave")}
        onCancelBtnClick={() => {
          if (pageState.headerFlag) {
            const today = moment(new Date());
            const prevQuestionnaireDate =
              localStorage.getItem("questionnaireDate");
            if (
              !prevQuestionnaireDate ||
              today.diff(moment(prevQuestionnaireDate), "days") > 6
            ) {
              setShowModalQuestionnaire(true);
            } else {
              onReset();
              window.location.href = url;
            }
          }
          if (goTo) {
            onReset();
            history.push(goTo);
          }

          setModalOpen(false);
        }}
      >
        <p style={{ color: "var(--Grey-600)" }}>
          {t("Common:unsaved_changes_description")}
        </p>
      </Modal>
    </>
  );
};
