import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import useTranslations from "../../../../core/i18n/useTranslations";
import { Column, Spacer } from "../../../../components/Layout/Layout";
import { RespondentGroupsTable } from "./RespondentGroupsTable";
import { RespondentsTable } from "./RespondentsTable";
import useSharedAlarmReception from "../SharedAlarmReceptionContext/useSharedAlarmReception";
import LoadingSpinner from "../../../../ui-lib/components/Loading/LoadingSpinner";
import Divider from "../../../../ui-lib/components/Divider/Divider";
import useUser from "../../../../core/user/useUser";

export const RespondentsContainer = () => {
  const location = useLocation();
  const t = useTranslations();
  const { data, isLoading } = useSharedAlarmReception();
  const { config } = useUser();

  useEffect(() => {
    sessionStorage.setItem(
      "prevPath",
      JSON.stringify({
        path: location.pathname,
        prevText: t("Menu:AlarmReceptions_Respondents"),
      })
    );
  }, []);

  return (
    <Column style={{ width: "100%" }} type="top" align="start">
      <h1>{t("Menu:AlarmReceptions_Respondents")}</h1>
      <Spacer size={8} />
      <p style={{ color: "var(--Grey-600)" }}>
        {t("AlarmReceptions:respondents_description")}
      </p>
      {isLoading ? (
        <LoadingSpinner theme="primary" />
      ) : (
        <>
          <Spacer size={32} />
          <RespondentGroupsTable receptionData={data!} />
          {config?.show.includes("M:Configure/Adminstrators") && (
            <>
              <Divider costomStyles={{ marginBlock: "10px" }} />
              <RespondentsTable receptionData={data!} />
            </>
          )}
        </>
      )}
    </Column>
  );
};
