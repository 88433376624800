import classnames from "classnames";
import Icon from "../Icon";
import styles from "./Badge.module.css";

export interface BadgeProps {
  variant?: "secondary" | "info" | "success" | "warning" | "error";
  title: string;
  icon?: string;
  withBorder?: boolean;
  withHighlightedText?: boolean;
  maxWidth?: number;
  className?: string;
}

export const Badge = ({
  variant = "secondary",
  title,
  icon = undefined,
  withBorder = false,
  withHighlightedText = false,
  maxWidth = 200,
  className = undefined,
}: BadgeProps) => (
  <span
    className={classnames({
      [styles.badge]: true,
      [styles.badgeSecondary]: variant === "secondary",
      [styles.badgeInfo]: variant === "info",
      [styles.badgeSuccess]: variant === "success",
      [styles.badgeWarning]: variant === "warning",
      [styles.badgeError]: variant === "error",
      [styles.badgeWithBorder]: withBorder,
      [styles.badgeWithHighlightedText]: withHighlightedText,
      ...(className ? { [className]: true } : {}),
    })}
    style={{ maxWidth: `${maxWidth}px` }}
  >
    {icon ? (
      <Icon name={icon} size={16} className={`mr-6 ${styles.badgeIcon}`} />
    ) : null}
    <span className={styles.badgeTitle}>{title}</span>
  </span>
);
export default Badge;
