import { useEffect, useRef, useState } from "react";
import { Row, Spacer } from "../../../components/Layout/Layout";
import {
  deleteEmergencyCenterSetting,
  editEmergencyCenterSetting,
  getEmergencyCenterSettings,
} from "../../../core/api/responsecenters/responsecenters";
import { EmergencyCenterSetting } from "../../../core/api/responsecenters/types";
import useTranslations from "../../../core/i18n/useTranslations";
import useUser from "../../../core/user/useUser";
import { notify } from "../../../ui-lib/components/Alerts/Toast";
import Button from "../../../ui-lib/components/Button/Button";
import Table from "../../../ui-lib/components/Tables/Table";
import TableCell from "../../../ui-lib/components/Tables/TableCell";
import {
  EditEmergencyCenterSetting,
  EditSettingDialogActions,
} from "./EditEmergencyCenterSetting";

const AlarmReceptionSettings = ({ id }: { id: number }) => {
  const t = useTranslations();
  const { authenticatedRequest } = useUser();
  const isAlive = useRef(false);
  const [isLoadingSettings, setIsLoadingSettings] = useState(false);
  const [settings, setSettings] = useState<EmergencyCenterSetting[]>();
  const fetchSettings = async () => {
    setIsLoadingSettings(true);
    try {
      const result = await getEmergencyCenterSettings(id, authenticatedRequest);
      if (isAlive.current) {
        setSettings(result.data);
      }
    } catch (error) {
      notify({ message: t("Errors:error_fetching_data"), variant: "error" });
    }

    if (isAlive.current) {
      setIsLoadingSettings(false);
    }
  };

  const editSetting = async (setting: EmergencyCenterSetting) => {
    setIsLoadingSettings(true);

    try {
      await editEmergencyCenterSetting(id, setting, authenticatedRequest);

      if (isAlive.current) {
        await fetchSettings();
      }
    } catch (error) {
      if (isAlive.current) {
        setIsLoadingSettings(false);

        throw error;
      }
    }
  };
  const deleteSetting = async (settingId: string | number) => {
    setIsLoadingSettings(true);

    try {
      await deleteEmergencyCenterSetting(id, settingId, authenticatedRequest);

      if (isAlive.current) {
        await fetchSettings();
      }
    } catch (error) {
      if (isAlive.current) {
        setIsLoadingSettings(false);

        throw error;
      }
    }
  };
  useEffect(() => {
    fetchSettings();
  }, [id]);
  useEffect(() => {
    isAlive.current = true;

    return () => {
      isAlive.current = false;
    };
  }, []);
  const editSettingRef = useRef<EditSettingDialogActions>(null);

  return (
    <>
      <Row type="space" align="center">
        <h2>
          {t("AlarmReceptions:advanced_settings_emergencycentersettings_label")}
        </h2>
        <EditEmergencyCenterSetting
          ref={editSettingRef}
          editSetting={editSetting}
          saveButtonTitle={t("AlarmReceptions:save_setting")}
        />
        <Button
          variant="secondary"
          text={t("AlarmReceptions:add_new_setting")}
          onClick={() => {
            editSettingRef.current?.open();
          }}
        />
      </Row>
      <Spacer size={16} />
      <Table<EmergencyCenterSetting>
        columns={[
          {
            header: t(
              "AlarmReceptions:advanced_settings_emergencycentersettings_setting"
            ),
            fieldTemplate: (rowData) => <TableCell value={rowData.setting} />,
          },
          {
            header: t(
              "AlarmReceptions:advanced_settings_emergencycentersettings_value"
            ),
            fieldTemplate: (rowData) => <TableCell value={rowData.value} />,
          },
        ]}
        items={settings}
        rowActions={[
          {
            icon: "pencil-alt",
            text: t("Common:label_edit"),
            onClick: (rowData) => editSettingRef.current?.open(rowData),
          },
          {
            icon: "trash",
            text: t("Common:delete"),
            iconVariant: "secondary",
            onClick: (rowData) => deleteSetting(rowData.id || -1),
          },
        ]}
        hideEmptyMessage
        noRowsMessage={t(
          "AlarmReceptions:advanced_settings_emergencycentersettings_nosettings"
        )}
        showRowsActionsOnHover
        isLoading={isLoadingSettings}
        showRowHover
        withShowMore
      />
    </>
  );
};
export default AlarmReceptionSettings;
