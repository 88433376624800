import { AxiosRequestConfig } from "axios";
import { useEffect, useState } from "react";
import useUser from "../user/useUser";
import { notifyApiErrors } from "../helpers/helpers";

const useAuthenticatedFetch = <T extends unknown>(
  url: string,
  config?: AxiosRequestConfig
) => {
  const [data, setData] = useState<T>(null as T);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const { authenticatedRequest } = useUser();

  useEffect(() => {
    let isAlive = true;

    const fetchData = async () => {
      setIsError(false);
      setIsLoading(true);

      try {
        const result = await authenticatedRequest.get<T>(url, config);
        if (isAlive) {
          setData(result.data);
        }
      } catch (error: any) {
        if (isAlive) {
          setIsError(true);
          notifyApiErrors(error.response?.data?.errors);
        }
      }

      if (isAlive) {
        setIsLoading(false);
      }
    };

    fetchData();

    return () => {
      // To make sure we do not try to update state if component did unmount
      isAlive = false;
    };
  }, [url]);

  return { data, isLoading, isError };
};

export default useAuthenticatedFetch;
