import { Dispatch, useState } from "react";
import { AxiosInstance } from "axios";
import { Row, Spacer } from "../../../../components/Layout/Layout";
import { AddAlarmTransmitter } from "../../../../components/Transmitters/AddAlarmTransmitter";
import { removeTransmitterFromAdmin } from "../../../../core/api/transmitters/transmitters";
import { Transmitter } from "../../../../core/api/transmitters/types";
import {
  formattedDateString,
  notifyApiErrors,
} from "../../../../core/helpers/helpers";
import useTranslations from "../../../../core/i18n/useTranslations";
import Table from "../../../../ui-lib/components/Tables/Table";
import TableCell, {
  MissingValueTableCell,
} from "../../../../ui-lib/components/Tables/TableCell";
import Button from "../../../../ui-lib/components/Button/Button";
import { AdministratorDetails } from "../../../../core/api/administrators/types";

const PositioningTransmitter = ({
  data,
  refetchState,
  transmitterData,
  authRequest,
  refetchRelatedObject,
  fetchTransmitters,
}: {
  data?: AdministratorDetails;
  refetchState: boolean;
  transmitterData?: Transmitter[];
  authRequest: AxiosInstance;
  refetchRelatedObject: Dispatch<boolean>;
  fetchTransmitters: () => Promise<void>;
}) => {
  const t = useTranslations();
  const [loadingTransmitter, setLoadingTransmitter] = useState<boolean>(false);
  const [isAddTransmitterOpen, setIsAddTransmitterOpen] = useState(false);

  const removeTransmitter = async (transmitterId: number) => {
    setLoadingTransmitter(true);
    try {
      await removeTransmitterFromAdmin(data?.id!, transmitterId, authRequest);
      fetchTransmitters();
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
      setLoadingTransmitter(false);
    }
  };

  return (
    <>
      <Row type="space" align="center">
        <h2>{t("Administrator:positioning_transmitter")}</h2>
        {transmitterData?.length === 0 && !loadingTransmitter && (
          <>
            <Button
              type="button"
              variant="secondary"
              image="status-online"
              text={t("Transmitters:add_transmitter")}
              onClick={() => {
                setIsAddTransmitterOpen(true);
              }}
            />

            <AddAlarmTransmitter
              adminPositioningTransmitter
              isAddTransmitterOpen={isAddTransmitterOpen}
              setIsAddTransmitterOpen={setIsAddTransmitterOpen}
              adminId={data?.id}
              onSubmit={() => {
                refetchRelatedObject(!refetchState);
                fetchTransmitters();
              }}
            />
          </>
        )}
      </Row>
      <Spacer size={16} />
      <Table<Transmitter>
        columns={[
          {
            header: t("Table:ProductName"),
            fieldTemplate: (rowData) => (
              <TableCell value={rowData.productName} />
            ),
          },
          {
            header: t("Objects:accessories_table_model"),
            fieldTemplate: (rowData) => (
              <MissingValueTableCell
                iconColor="Grey-300"
                value={rowData.model}
                missingValueText={t("Transmitters:no_model")}
                leftIcon="clipboard-list"
              />
            ),
          },
          {
            header: t("Objects:Labels_identifier"),
            fieldTemplate: (rowData) => (
              <TableCell
                iconColor="Grey-300"
                value={rowData.identifier}
                leftIcon="phone"
              />
            ),
          },
          {
            header: t("Transmitters:table_columns_lastCommunicationTest"),
            fieldTemplate: (rowData) => (
              <MissingValueTableCell
                iconColor="Grey-300"
                value={formattedDateString(rowData.lastCommunicationTest)}
                missingValueText={t("Transmitters:no_last_communication")}
                leftIcon="clock"
              />
            ),
          },
        ]}
        items={transmitterData}
        rowActions={[
          {
            icon: "x",
            text: t("Objects:accessories_table_remove"),
            onClick: (rowData) => removeTransmitter(rowData.id),
            iconVariant: "secondary",
          },
        ]}
        rowActionsFixed
        rowActionsColWidth={45}
        hideEmptyMessage
        noRowsMessage={t("Administrator:no_positioning_transmitter")}
        showRowHover
        isLoading={loadingTransmitter}
        withShowMore
      />
    </>
  );
};

export default PositioningTransmitter;
