import DateTimeHelper from "../../../../core/helpers/dateTimeHelper";
import useTranslations from "../../../../core/i18n/useTranslations";
import useUser from "../../../../core/user/useUser";
import { PeriodicInteractionFormValues } from "./InteractionForm";

// Action type options
export const actionTypeOptions = [
  { id: 0, role: "PUIAreYouOk" },
  { id: 1, role: "PUINightcheck" },
  { id: 100, role: "PUITestalarm" },
  { id: 2, role: "PUIReminder" },
  { id: 3, role: "PUINightWatch" },
];

export const useActionTypeOptions = () => {
  const user = useUser();
  const t = useTranslations();

  const radioGroupItems = actionTypeOptions
    .filter(({ role }) => user.config?.show.includes(role))
    .map(({ id }) => ({
      id,
      value: `interaction-type-${id}`,
      text: t(`Objects:periodic_interactions_type_${id}`),
      description: t(`Objects:periodic_interactions_type_${id}_description`),
      title: t(`Objects:periodic_interactions_title_${id}_description`),
    }));

  return radioGroupItems;
};

// Array to weeks
const weekMap: { [key: string]: number } = {
  OnMonday: 0,
  OnTuesday: 1,
  OnWednesday: 2,
  OnThursday: 3,
  OnFriday: 4,
  OnSaturday: 5,
  OnSunday: 6,
};

export const arrayToWeeks = (array: number[]) =>
  Object.keys(weekMap).reduce<{ [key: string]: boolean }>((prev, key) => {
    const id = weekMap[key];
    const selected = array.includes(id);

    return {
      ...prev,
      [key]: selected,
    };
  }, {});

// Form summary message
export const useSummary = ({
  formValues,
  isValid,
}: {
  formValues: PeriodicInteractionFormValues;
  isValid: boolean;
}) => {
  const t = useTranslations();

  if (!isValid) {
    return undefined;
  }

  const type = t(`Objects:periodic_interactions_type_${formValues.type}`);
  const time = DateTimeHelper.getTimeStringFromTimeComponents(
    formValues.hour,
    formValues.minute
  );
  const message = formValues.continueNextWeek
    ? t("Objects:periodic_interactions_summary_repeat")
    : t("Objects:periodic_interactions_summary_no_repeat");

  return message.replace("{Type}", type).replace("{Time}", time);
};
