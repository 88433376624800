import { Controller, useForm } from "react-hook-form";
import { Dispatch, useEffect, useState } from "react";
import useTranslations from "../../../../core/i18n/useTranslations";
import { Column, Row, Spacer } from "../../../../components/Layout/Layout";
import {
  IReceptionCreatedGroup,
  IReceptionGroup,
} from "../../../../core/api/responsecenters/types";
import TextInput from "../../../../ui-lib/components/Inputs/TextInput";
import { notifyApiErrors } from "../../../../core/helpers/helpers";
import useUser from "../../../../core/user/useUser";
import { notify } from "../../../../ui-lib/components/Alerts/Toast";
import Dropdown from "../../../../ui-lib/components/Dropdown/Dropdown";
import Checkbox from "../../../../ui-lib/components/Checkbox/Checkbox";
import {
  createRespondentGroup,
  getRespondentGroupDetails,
  updateAllRespondents,
  updateRespondentsGroupAccess,
} from "../../../../core/api/responsecenters/responsecenters";
import styles from "../../../Objects/ObjectGeneral/ObjectGeneral.module.css";
import { GroupRespondentsAccessManager } from "./GroupRespondentsAccessManager";
import TextArea from "../../../../ui-lib/components/Inputs/Textarea";
import LoadingSpinner from "../../../../ui-lib/components/Loading/LoadingSpinner";
import { Role, RoleFeature } from "../../../../components/RoleFeature";
import PrimeModal from "../../../../ui-lib/components/PrimeModal/PrimeModal";

export const CreateRespondentGroupModal = ({
  onClose,
  onSubmit,
  groupId,
  arcId,
  forceLoginOptions,
  setSubmitButtonState,
  isPublic,
  isCreateModalOpen,
  submitting,
}: {
  onClose: (completed?: boolean) => void;
  onSubmit: () => void;
  groupId?: number;
  arcId: number;
  forceLoginOptions: any[];
  setSubmitButtonState?: Dispatch<boolean>;
  isPublic: boolean;
  isCreateModalOpen: boolean;
  submitting: boolean;
}) => {
  const t = useTranslations();
  const { authenticatedRequest, config } = useUser();
  const [loading, setLoading] = useState(false);
  const [isUpdateAllRespondents, setIsUpdateAllRespondents] = useState(false);
  const [allRespondentsUpdate, setAllRespondentsUpdate] = useState({
    forced: false,
    optional: false,
  });
  const [allUpdateOptions, setAllUpdateOptions] = useState(
    [0, 1, 2].map((id) => ({
      id,
      name: t(`AlarmReceptions:all_respondents_update_type_${id}`),
      isSelected: false,
    }))
  );
  const [shouldUpdateRespondentsAccess, setShouldUpdateRespondentsAccess] =
    useState(false);
  const [groupDetails, setGroupDetails] = useState<IReceptionCreatedGroup>();
  const defaultAccessOptions = [0, 1, 2];
  const cameraAllowanceOptions = [0, 3];
  const userContactAccessOptions = [0, 1, 2];

  const updateLinkedRespondents = async (
    respondents: { id: number; forced: boolean }[]
  ) => {
    try {
      updateRespondentsGroupAccess(
        arcId,
        groupId!,
        respondents,
        authenticatedRequest
      );
      notify({
        message: t("AlarmReceptions:respondents_access_edit"),
      });
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    }
  };

  const onInternalSubmit = async (values: IReceptionGroup) => {
    try {
      setSubmitButtonState?.(true);
      if (groupId) {
        setShouldUpdateRespondentsAccess(true);
      }
      await createRespondentGroup(arcId, values, authenticatedRequest, groupId);
      notify({
        message: t(
          `AlarmReceptions:${groupId ? "edit" : "add"}_respondent_group_success`
        ),
      });
      if (isUpdateAllRespondents) {
        await updateAllRespondents(
          arcId,
          groupId!,
          allRespondentsUpdate,
          authenticatedRequest
        );
        notify({ message: t("AlarmReceptions:all_respondents_success") });
      }
      onClose();
      onSubmit();
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    } finally {
      onClose(false);
    }
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    watch,
    trigger,
  } = useForm<IReceptionCreatedGroup>({
    defaultValues: {
      defaultSelectable: groupId ? groupDetails?.defaultSelectable : false,
      defaultForced: groupId ? groupDetails?.defaultForced : false,
      cameraViewingAllowance: !groupId ? 3 : undefined,
      forceLoginToRespondentGroupId: !groupId ? null : undefined,
      userContactAccess: !groupId ? 0 : undefined,
    },
  });

  const defaultSelectable = watch("defaultSelectable");
  const defaultForced = watch("defaultForced");

  const fetchGroupDetails = async () => {
    setLoading(true);
    try {
      const result = await getRespondentGroupDetails(
        arcId,
        groupId!,
        authenticatedRequest
      );
      setGroupDetails(result.data);
      reset({ ...result.data });
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    } finally {
      setLoading(false);
    }
  };

  const getSelectedDefaultAccess = (id: number): boolean => {
    if (
      (id === 0 && defaultSelectable && !defaultForced) ||
      (id === 1 && !defaultSelectable && defaultForced) ||
      (id === 2 && !defaultSelectable && !defaultForced)
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (groupId) {
      fetchGroupDetails();
    }
  }, [groupId]);

  return (
    <PrimeModal
      withFooter
      withHeader
      contentStyle={{ width: "848px" }}
      isOpen={isCreateModalOpen}
      onClose={onClose}
      header={t(`AlarmReceptions:${groupId ? "edit" : "add"}_respondent_group`)}
      submitBtn={{
        text: t(
          `AlarmReceptions:${groupId ? "edit" : "create"}_respondent_group`
        ),
        onClick: handleSubmit(async (values) => {
          await onInternalSubmit(values);
          reset(values);
        }),
      }}
      cancelBtn={{
        text: t("Common:cancel"),
        onClick: onClose,
      }}
      loading={submitting}
    >
      <p style={{ color: "var(--Grey-600)" }}>
        {t("AlarmReceptions:add_respondent_group_subtitle")}
      </p>
      <Spacer size={16} />
      {loading ? (
        <LoadingSpinner theme="primary" />
      ) : (
        <form id="respondent_group_create">
          <Column type="top" align="start">
            <Row align="start" type="fill">
              <Controller
                name="description"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <TextInput
                    required
                    placeholder={t(
                      "AlarmReceptions:respondent_description_placeholder"
                    )}
                    label={t("AlarmReceptions:distribution_table_column_name")}
                    value={field.value}
                    onChange={field.onChange}
                    validationError={
                      errors?.description &&
                      t("AlarmReceptions:edit_contact_name_required")
                    }
                    width="376px"
                  />
                )}
              />
              {isPublic && (
                <>
                  <Spacer size={32} />
                  <Controller
                    name="public"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        label={t("Common:public")}
                        checked={field.value}
                        onChange={field.onChange}
                        style={{ padding: "30px 0 0", width: "100%" }}
                      />
                    )}
                  />
                </>
              )}
            </Row>
            <Spacer size={16} />
            <Row align="start" type="fill">
              <Controller
                name="defaultForced"
                control={control}
                render={() => {
                  const dropdownItems = defaultAccessOptions.map((id) => ({
                    id,
                    name: t(`AlarmReceptions:default_access_type_${id}`),
                    isSelected: getSelectedDefaultAccess(id),
                  }));

                  return (
                    <Dropdown
                      title={t("AlarmReceptions:default_access_type")}
                      width="376px"
                      selectedItem={dropdownItems.find((i) => i.isSelected)}
                      items={dropdownItems}
                      onSelectItem={(item) => {
                        switch (item.id) {
                          case 0:
                            setValue("defaultSelectable", true);
                            setValue("defaultForced", false);
                            break;
                          case 1:
                            setValue("defaultSelectable", false);
                            setValue("defaultForced", true);
                            break;
                          case 2:
                            setValue("defaultSelectable", false);
                            setValue("defaultForced", false);
                            break;
                          default:
                            break;
                        }
                        trigger("defaultForced");
                      }}
                    />
                  );
                }}
              />
              <Spacer size={32} />
              <Controller
                name="cameraViewingAllowance"
                control={control}
                render={({ field }) => {
                  const dropdownItems = cameraAllowanceOptions.map((id) => ({
                    id,
                    name: t(`AlarmReceptions:camera_view_type_${id}`),
                    isSelected: id === field.value,
                  }));

                  return (
                    <Dropdown
                      title={t("AlarmReceptions:camera_view_type")}
                      width="376px"
                      selectedItem={dropdownItems.find((i) => i.isSelected)}
                      items={dropdownItems}
                      onSelectItem={(item) => {
                        setValue(field.name, item.id as number);
                      }}
                    />
                  );
                }}
              />
            </Row>
            <Spacer size={24} />
            {groupId && config?.show.includes("PlanningView") && (
              <Row align="start" type="fill">
                <div style={{ width: "376px" }}>
                  <Controller
                    name="usePlanningView"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        label={t("AlarmReceptions:use_planning_view")}
                        checked={field.value}
                        onChange={field.onChange}
                        style={{ padding: 0 }}
                      />
                    )}
                  />
                </div>
                <Spacer size={38} />
                <div style={{ width: "376px" }}>
                  <Controller
                    name="allowMedicalNotes"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        label={t("AlarmReceptions:allow_medical_notes")}
                        checked={field.value}
                        onChange={field.onChange}
                        style={{ padding: 0 }}
                      />
                    )}
                  />
                </div>
              </Row>
            )}
            <Row align="start" type="fill">
              <div style={{ width: "376px" }}>
                <Controller
                  name="allowAlarmsEvenIfEmpty"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      label={t("AlarmReceptions:allow_alarms_even_empty")}
                      checked={field.value}
                      onChange={field.onChange}
                      style={{ padding: 0 }}
                    />
                  )}
                />
              </div>
              <RoleFeature requires={[Role.BetaAccess]}>
                <Spacer size={38} />
                <div style={{ width: "376px" }}>
                  <Controller
                    name="monitorRespondentCount"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        label={t("AlarmReceptions:monitor_respondent_count")}
                        checked={field.value}
                        onChange={field.onChange}
                        style={{ padding: 0 }}
                      />
                    )}
                  />
                </div>
              </RoleFeature>
            </Row>
            <Spacer size={8} />
            <Row>
              <div style={{ width: "376px" }}>
                <Controller
                  name="hideFromRespondentListing"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      label={t("AlarmReceptions:hide_from_respondent_listing")}
                      description={t(
                        "AlarmReceptions:hide_from_respondent_listing_description"
                      )}
                      checked={field.value}
                      onChange={field.onChange}
                      style={{ padding: 0 }}
                    />
                  )}
                />
              </div>
            </Row>
            <Spacer size={24} />
            <Row align="start" type="fill">
              <Controller
                name="forceLoginToRespondentGroupId"
                control={control}
                render={({ field }) => {
                  const dropdownItems = forceLoginOptions.map((option) => ({
                    id: option.id,
                    name: option.description,
                    isSelected: field.value
                      ? option.id === field.value
                      : !`${option.id}`.length,
                  }));

                  return (
                    <Dropdown
                      title={t("AlarmReceptions:force_login_type")}
                      width="376px"
                      selectedItem={dropdownItems.find((i) => i.isSelected)}
                      items={dropdownItems}
                      onSelectItem={(item) => {
                        setValue(
                          "forceLoginToRespondentGroupId",
                          item.id ? (item.id as number) : null
                        );
                      }}
                    />
                  );
                }}
              />
              <Spacer size={32} />
              <Controller
                name="userContactAccess"
                control={control}
                render={({ field }) => {
                  const dropdownItems = userContactAccessOptions.map((id) => ({
                    id,
                    name: t(`AlarmReceptions:user_access_type_${id}`),
                    isSelected: id === field.value,
                  }));

                  return (
                    <Dropdown
                      title={t("AlarmReceptions:user_access_type")}
                      width="376px"
                      selectedItem={dropdownItems.find((i) => i.isSelected)}
                      items={dropdownItems}
                      onSelectItem={(item) => {
                        setValue("userContactAccess", item.id as number);
                      }}
                    />
                  );
                }}
              />
            </Row>
            {groupId && (
              <>
                <Spacer size={24} />
                <Row align="start" type="fill">
                  <Controller
                    name="transferOperatorAlarmsDuringLogout"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        label={t(
                          "AlarmReceptions:transfer_operator_alarms_logout"
                        )}
                        checked={field.value}
                        onChange={field.onChange}
                        style={{ padding: 0, flex: 1 }}
                      />
                    )}
                  />
                  <Spacer size={32} />
                  <Controller
                    name="transferFromOnCallEstablish"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        label={t("AlarmReceptions:transfer_call_establish")}
                        checked={field.value}
                        onChange={field.onChange}
                        style={{ padding: 0, flex: 1 }}
                      />
                    )}
                  />
                </Row>
                <Spacer size={8} />
                <Row align="start" type="fill">
                  <Controller
                    name="disableCallOnResourceGroup"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        label={t("AlarmReceptions:disable_call_on_group")}
                        checked={field.value}
                        onChange={field.onChange}
                        style={{ padding: 0, flex: 1 }}
                      />
                    )}
                  />
                  <Spacer size={32} />
                  <Controller
                    name="mergeRelatedAlarms"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        label={t("AlarmReceptions:group_admins")}
                        onChange={field.onChange}
                        checked={field.value}
                        style={{ padding: 0, flex: 1 }}
                      />
                    )}
                  />
                </Row>
                <Spacer size={20} />
                <Controller
                  name="hideOtherRespondentsHandlingAlarm"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      label={t("AlarmReceptions:hide_respondent_listing")}
                      checked={field.value}
                      onChange={field.onChange}
                      labelStyles={{ marginTop: "-6px" }}
                      innerStyle={{ alignItems: "flex-start" }}
                      style={{
                        padding: 0,
                        width: "48%",
                      }}
                    />
                  )}
                />
                <Spacer size={40} />
                <hr className={styles.horizontalRule} />
                <Spacer size={32} />
                <h2>{t("AlarmReceptions:alarm_log")}</h2>
                <p style={{ color: "var(--Grey-600)" }}>
                  {t("AlarmReceptions:alarm_log_subtitle")}
                </p>
                <Spacer size={16} />
                <Controller
                  name="actionAlternatives"
                  control={control}
                  render={({ field }) => (
                    <TextArea
                      label={t(
                        "AlarmReceptions:alarm_handling_input_action_alternatives"
                      )}
                      placeholder={t(
                        "AlarmReceptions:alarm_handling_input_action_alternatives_placeholder"
                      )}
                      value={field.value}
                      onChange={field.onChange}
                      css={{
                        minHeight: "130px",
                      }}
                    />
                  )}
                />
                <Spacer size={16} />
                <Controller
                  name="reasonAlternatives"
                  control={control}
                  render={({ field }) => (
                    <TextArea
                      label={t(
                        "AlarmReceptions:alarm_handling_input_reason_alternatives"
                      )}
                      placeholder={t(
                        "AlarmReceptions:alarm_handling_input_reason_alternatives_placeholder"
                      )}
                      value={field.value}
                      onChange={field.onChange}
                      css={{
                        minHeight: "130px",
                      }}
                    />
                  )}
                />
                <Spacer size={32} />
                <hr className={styles.horizontalRule} />
                <Spacer size={16} />
                <GroupRespondentsAccessManager
                  arcId={arcId}
                  groupId={groupId}
                  groupName={groupDetails?.description!}
                  shouldUpdateRespondents={shouldUpdateRespondentsAccess}
                  respondentsSubmit={updateLinkedRespondents}
                />
                <Spacer size={16} />
                <hr className={styles.horizontalRule} />
                <Spacer size={32} />
                <Dropdown
                  width="376px"
                  selectedItem={allUpdateOptions.find((i) => i.isSelected)}
                  items={allUpdateOptions}
                  onSelectItem={(item) => {
                    switch (item.id) {
                      case 0:
                        setAllRespondentsUpdate({
                          forced: true,
                          optional: false,
                        });
                        break;
                      case 1:
                        setAllRespondentsUpdate({
                          forced: false,
                          optional: true,
                        });
                        break;
                      case 2:
                        setAllRespondentsUpdate({
                          forced: false,
                          optional: false,
                        });
                        break;
                      default:
                        break;
                    }
                    setAllUpdateOptions(
                      allUpdateOptions.map((option) => ({
                        ...option,
                        isSelected: option.id === item.id,
                      }))
                    );
                  }}
                />
                <Spacer size={24} />
                <Checkbox
                  label={t("AlarmReceptions:apply_to_all_groups")}
                  checked={isUpdateAllRespondents}
                  onChange={() =>
                    setIsUpdateAllRespondents(!isUpdateAllRespondents)
                  }
                  style={{ padding: 0 }}
                />
              </>
            )}
          </Column>
        </form>
      )}
    </PrimeModal>
  );
};
