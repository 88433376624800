import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import BasicPage from "../../components/BasicPage/BasicPage";
import { Column, Row, Spacer } from "../../components/Layout/Layout";
import AboutContent from "./AboutContent";
import styles from "./About.module.css";
import Icon from "../../ui-lib/components/Icon";
import useTranslations from "../../core/i18n/useTranslations";

const AboutPage = () => {
  const t = useTranslations();

  return (
    <BasicPage>
      <Column className={styles.wrapper}>
        <p>
          <Link to="/login" className={styles.backLink}>
            <Row>
              <Icon name="chevron-left" size={16} color="Primary-700" />
              <Spacer size={4} />
              <span>{t("Common:backtologin")}</span>
            </Row>
          </Link>
        </p>
        <Spacer size={32} />
        <AboutContent />
      </Column>
    </BasicPage>
  );
};

export default observer(AboutPage);
