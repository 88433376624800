import { useState } from "react";
import { notifyApiErrors } from "../../../core/helpers/helpers";
import useTranslations from "../../../core/i18n/useTranslations";
import useUser from "../../../core/user/useUser";
import Button from "../../../ui-lib/components/Button/Button";
import { ITreeData } from "../../../ui-lib/components/Hierarchy/Tree";
import StyledModal from "../../../ui-lib/components/Modal/Modal";
import { Spacer } from "../../../components/Layout/Layout";
import { NewRoleTemplate } from "../../../core/api/roleTemplates/types";
import { useObjectForm } from "../../../core/SaveObjectContext/hooks";
import { createRoleTemplate } from "../../../core/api/roleTemplates/roleTemplates";
import { RoleTemplateGeneralForm } from "./RoleTemplateGeneralForm";
import { notify } from "../../../ui-lib/components/Alerts/Toast";
import {
  DateTimeFormat,
  Timezone,
} from "../../../core/api/administrators/types";

export const AddRoleTemplate = ({
  onSubmit,
  onClose,
  organisations,
  timezones,
  dateTimeFormats,
}: {
  onSubmit: () => void;
  onClose?: () => void;
  organisations: ITreeData[];
  timezones: Timezone[];
  dateTimeFormats: DateTimeFormat[];
}) => {
  const t = useTranslations();
  const { authenticatedRequest, config } = useUser();

  const objectForm = useObjectForm<NewRoleTemplate>({
    defaultValues: {
      respondentGroups: [],
      hierarchyUsage: 3,
      primaryLanguage: config?.primaryLanguage ?? "en-GB",
      secondaryLanguage: config?.secondaryLanguage ?? "en-GB",
      dateTimeFormatId: dateTimeFormats
        ? dateTimeFormats.find(
            (timeFormat) =>
              timeFormat.dateTimeFormat.toLowerCase() ===
              config?.timezone.datetimeFormat.toLowerCase()
          )?.id
        : 1,
      timezoneId: timezones
        ? timezones.find(
            (timezone) =>
              timezone.timezoneName === config?.timezone.timezoneName
          )?.id
        : 7,
    },
  });

  const [isAddTemplateOpen, setIsAddTemplateOpen] = useState(false);

  const closeModal = () => {
    objectForm.reset();

    setIsAddTemplateOpen(false);
    if (onClose !== undefined) {
      onClose();
    }
  };

  const onCreateTemplateSubmit = async (formData: NewRoleTemplate) => {
    try {
      if (formData.responseCenterId) formData.organizationId = undefined;
      await createRoleTemplate(formData, authenticatedRequest);
      onSubmit();
      notify({
        message: t("Administrator:role_template_created_success"),
      });
      closeModal();
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    }
  };

  return (
    <>
      <Button
        variant="primary"
        text={t("Administrator:new_role_template")}
        onClick={() => {
          setIsAddTemplateOpen(true);
        }}
      />
      <StyledModal
        onClose={closeModal}
        nested
        isOpen={isAddTemplateOpen}
        cancelBtnText={t("Common:cancel")}
        approveBtnText={t("Administrator:create_role_template")}
        modalTitle={t("Administrator:create_new_role_template")}
        onApproveBtnClick={() =>
          objectForm.handleSubmit(onCreateTemplateSubmit)()
        }
        onCancelBtnClick={closeModal}
        closeOnDocumentClick={false}
        width="1000px"
        setContentDividers
      >
        <p>{t("Administrator:add_role_template_description")}</p>
        <Spacer size={16} />
        <RoleTemplateGeneralForm
          organisations={organisations}
          authenticatedRequest={authenticatedRequest}
          objectForm={objectForm}
          isEditPage={false}
          timezones={timezones}
          dateTimeFormats={dateTimeFormats}
        />
      </StyledModal>
    </>
  );
};
