import { useDimensions, breakpoints } from "../core/hooks/dimensionProvider";

const ResponsiveLayout = ({
  breakPoint = breakpoints.tablet,
  renderMobile,
  renderDesktop,
}: {
  breakPoint: number;
  renderMobile: Function;
  renderDesktop: Function;
}) => {
  const { width } = useDimensions();
  return width > breakPoint ? renderDesktop() : renderMobile();
};

export default ResponsiveLayout;
