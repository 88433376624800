import { useMemo } from "react";
import useTranslations from "../../../core/i18n/useTranslations";
import RadioGroup from "../Radio/RadioGroup";
import { Filter } from "./CustomFilters";

const absenceOptions = [
  { id: 0, value: undefined }, // All
  { id: 1, value: "1" }, // With absences
  { id: 2, value: "2" }, // Only available
];

export const CustomAbsenceFilter = ({
  item,
  onChange,
}: {
  item: Filter;
  onChange: (item: Filter) => void;
}) => {
  const t = useTranslations();

  const radioItems = useMemo(
    () =>
      absenceOptions.map((o) => ({
        id: o.id,
        value: `object-absence-${o.id}`,
        text: t(`Objects:object-absence_${o.id}`),
      })),
    []
  );

  const selectedLinkedOption = absenceOptions.find(
    (o) => o.value === item.value
  );
  const selectedRadioItem = radioItems.find(
    (i) => i.id === (selectedLinkedOption?.id || 0)
  );

  return (
    <RadioGroup
      step={10}
      label={t("Objects:absence_of_object")}
      name="object-absence"
      items={radioItems}
      selectedValue={selectedRadioItem?.value}
      onChange={(value) => {
        const radioItem = radioItems.find((i) => i.value === value);
        const absenceOption = absenceOptions.find(
          (o) => o.id === radioItem?.id
        );

        onChange({
          ...item,
          value: absenceOption?.value,
        });
      }}
    />
  );
};
