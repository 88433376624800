import { BaseColumnsProps } from "../../core/api/common_table";
import { ColumnType } from "../components/Tables/Table";

interface Columns {
  [key: string]: { [key: string]: string } | string;
}

/* Methods for converting column data from back-end. See tests for better understanding of the format. */

const mapColumnData = (
  allColumns: Columns,
  selectedColumns: Columns
): Map<string, ColumnType> => {
  const selectedColumnsValues = Object.values(selectedColumns);
  const selectedColumnsIds = Object.keys(selectedColumns);
  // sorting to keep ordering as in old system and mockups
  // sorting index is the key in the object that comes from back-end
  const sortedArray: [string, { [key: string]: string } | string][] =
    Object.entries(allColumns).sort(([key1, val1], [key2, val2]) => {
      const key1IsInt = Number.isInteger(+key1);
      const key2IsInt = Number.isInteger(+key2);
      // for object with children we take the first index of the first element
      // that is enough to sort items correctly
      const key1Order = key1IsInt ? +key1 : +Object.keys(val1)[0];
      const key2Order = key2IsInt ? +key2 : +Object.keys(val2)[0];
      return key1Order - key2Order;
    });

  return new Map<string, ColumnType>(
    sortedArray.map(([id, value]) => {
      // value can be string for simple columns or object for columns with expanding
      const valueIsString = typeof value === "string";
      const name = (valueIsString ? value : id) as string;
      const column: ColumnType = {
        id: valueIsString ? id : undefined,
        name,
        selected: selectedColumnsValues.includes(value),
      };

      if (valueIsString) {
        column.selected = selectedColumnsValues.includes(value);
      } else {
        column.selected = selectedColumnsIds.includes(id);
        const subColumns = new Map<string, ColumnType[]>();

        Object.entries(value).forEach(([colId, colVal]) => {
          // split value with format as 'Transmitters.ProductName_1'
          const [blockName, instanceWithProperty] = colVal.split(".");
          const [property, instance] = instanceWithProperty.split("_");

          const subColIsSelected = !!(
            selectedColumns[id] &&
            Object.values(selectedColumns[id]).includes(colVal)
          );
          const blockN = `${blockName}_${instance}`;
          const childObject = {
            name: property,
            id: colId,
            selected: subColIsSelected,
          };

          if (subColumns.has(blockN)) {
            const columnValues = subColumns.get(blockN) as ColumnType[];
            subColumns.set(blockN, [...columnValues, childObject]);
          } else {
            subColumns.set(blockN, [childObject]);
          }

          column.subColumns = subColumns;
        });
      }

      return [name, column];
    })
  );
};

export const getTableColumnsList = (
  allColumns?: BaseColumnsProps,
  selectedColumns?: BaseColumnsProps
): Map<string, ColumnType> => {
  if (!allColumns || !selectedColumns) return new Map();

  return mapColumnData(allColumns.columns, selectedColumns.columns);
};

export const getSelectedTableColumnListInTreeStructure = (
  columns: Map<string, ColumnType>
): BaseColumnsProps => {
  let index = 0;
  return Array.from(columns).reduce(
    (result, [name, el]) => {
      if (!el.selected && !el.subColumns) return result;

      if (el.id) {
        result.columns[index] = name;
        index += 1;
      }

      if (el.subColumns) {
        result.columns[name] = Array.from(el.subColumns).reduce(
          (acum: { [key: string]: string }, [childName, childEl]) => {
            childEl.forEach((col) => {
              if (col.selected && col.id) {
                const [, instance] = childName.split("_");
                acum[index] = `${name}.${col.name}_${instance}`;
                index += 1;
              }
            });
            return acum;
          },
          {}
        );
      }
      return result;
    },
    {
      columns: {},
    } as BaseColumnsProps
  );
};
