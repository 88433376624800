import { useEffect, useState } from "react";
import moment from "moment";
import { Spacer } from "../../../components/Layout/Layout";
import useTranslations from "../../../core/i18n/useTranslations";
import DateFilterDropdown, {
  useDateDropdownFilter,
} from "../../../components/DateFilter/DateFilterDropdown";
import {
  getTransmitterInteractions,
  TransmitterInteraction,
} from "../../../core/api/transmitterInteractions";
import useUser from "../../../core/user/useUser";
import { getTransmitterAlarms } from "../../../core/api/alarms";
import TableCell from "../../../ui-lib/components/Tables/TableCell";
import Table from "../../../ui-lib/components/Tables/Table";
import { AlarmHistoryDetails } from "../../AlarmHistory/AlarmHistoryDetails/AlarmHistoryDetails";
import Modal from "../../../ui-lib/components/Modal/Modal";
import { useSortParams } from "../../../core/hooks/filters/useSortParams";
import { notifyApiErrors } from "../../../core/helpers/helpers";
import Divider from "../../../ui-lib/components/Divider/Divider";
import LoadingSpinner from "../../../ui-lib/components/Loading/LoadingSpinner";
import DateTimeHelper from "../../../core/helpers/dateTimeHelper";

export const ObjectTransmitterInteractions = ({
  alarmHistoryParams: { user: userId, transmitter: transmitterId },
}: {
  alarmHistoryParams: {
    user: string;
    transmitter: string;
  };
}) => {
  const { dateType, setDateType, dateFilter, setDateFilter } =
    useDateDropdownFilter({
      searchState: undefined,
      setInitialDateType: 2,
    });
  const t = useTranslations();
  const { authenticatedRequest } = useUser();
  const [isLoading, setIsLoading] = useState(false);
  const [alarmDetailsId, setAlarmDetailsId] = useState("");
  const [alarmMessage, setAlarmMessage] = useState("");
  const [interactionsData, setInteractionsData] = useState<
    TransmitterInteraction[]
  >([]);
  const { sortParams, setSortParams } = useSortParams({
    sortField: "ServerTime",
    sortOrder: "-1",
  });
  const { formatDateTimeString } = DateTimeHelper;

  const uploadTransmitterInteractions = () => {
    setIsLoading(true);
    const startDate = moment(dateFilter.start)
      .subtract(1, "days")
      .format("YYYY-MM-DDTHH:mm:ss");
    const endDate = moment(dateFilter.end)
      .format("YYYY-MM-DDTHH:mm:ss")
      .toString();
    const transmitterAlarms = getTransmitterAlarms(
      authenticatedRequest,
      userId,
      transmitterId,
      startDate,
      endDate
    );
    const transmitterInteractions = getTransmitterInteractions(
      authenticatedRequest,
      transmitterId,
      startDate,
      endDate
    );
    Promise.all([transmitterInteractions, transmitterAlarms])
      .then((res) => {
        const newTransmitterInteractionData = [
          ...res[0].data,
          ...res[1].data.map(
            (alarmData) =>
              ({
                serverTime: `${alarmData.serverAlarmTime}`,
                alarmCode: alarmData.alarmCode,
                alarmId: alarmData.id,
              } as TransmitterInteraction)
          ),
        ];

        setInteractionsData(
          newTransmitterInteractionData.sort(
            (a, b) => Date.parse(b.serverTime) - Date.parse(a.serverTime)
          )
        );
      })
      .catch((error) => {
        notifyApiErrors(error.response?.data?.errors);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (dateFilter && dateFilter.start && dateFilter.end && !isLoading) {
      uploadTransmitterInteractions();
    }
  }, [dateFilter]);

  return (
    <>
      <Spacer size={32} />
      <DateFilterDropdown
        placeholder={`--${t("Objects:date_filter_placeholder")}--`}
        dateType={dateType}
        setDateType={setDateType}
        dateFilter={dateFilter}
        setDateFilter={setDateFilter}
        hideClearItem
        setDefaultEndTime
      />

      <Spacer size={16} />
      <Divider />

      {isLoading ? (
        <>
          <Spacer size={32} />
          <LoadingSpinner theme="primary" />
        </>
      ) : (
        <>
          <Spacer size={16} />
          <Table<TransmitterInteraction>
            columns={[
              {
                header: t("AlarmReceptions:DefaultAlarmListSort_time"),
                fieldTemplate: (rowData) => (
                  <TableCell
                    value={formatDateTimeString(
                      rowData.serverTime,
                      "YYYY-MM-DD HH:mm:ss"
                    )}
                  />
                ),
                sortByField: "serverTime",
              },
              {
                header: t("AlarmReceptions:general_input_type"),
                fieldTemplate: (rowData) => (
                  <TableCell
                    value={
                      rowData.alarmId
                        ? t("Objects:labels_alarm")
                        : t("Objects:labels_heartbeat")
                    }
                  />
                ),
                sortByField: "alarmCode",
              },
              {
                header: t("Objects:labels_battery_level"),
                fieldTemplate: (rowData) => (
                  <TableCell value={rowData.batteryCharge} />
                ),
                sortByField: "batteryCharge",
              },
              {
                header: t("Objects:labels_battery_level_ext"),
                fieldTemplate: (rowData) => (
                  <TableCell value={rowData.batteryChargeExternal} />
                ),
                sortByField: "batteryChargeExternal",
              },
              {
                header: t("AlarmHistory:alarm_query_filter_alarm_code"),
                fieldTemplate: (rowData) => (
                  <TableCell value={rowData.alarmCode} />
                ),
                sortByField: "alarmCode",
              },
            ]}
            items={interactionsData}
            rowActions={[
              {
                icon: "eye",
                onClick: (rowData) => {
                  if (rowData.alarmCode) {
                    setAlarmDetailsId(`${rowData.alarmId}`);
                  } else {
                    setAlarmMessage(
                      rowData.message
                        ? rowData.message.split("&quot;").join("'")
                        : ""
                    );
                  }
                },
              },
            ]}
            hideEmptyMessage
            withShowMore
            showRowHover
            noRowsMessage={t("Objects:no_transmitter_interaction")}
            sortParams={sortParams}
            onSortChange={setSortParams}
          />
        </>
      )}
      <Modal
        isOpen={Boolean(alarmDetailsId.length) || Boolean(alarmMessage.length)}
        onClose={() => {
          setAlarmDetailsId("");
          setAlarmMessage("");
        }}
        modalTitle={
          alarmDetailsId.length
            ? t("AlarmHistory:details_page_title")
            : t("Object:Alarm_message")
        }
        closeOnDocumentClick={false}
        cancelBtnText={t("Common:close")}
        onCancelBtnClick={() => {
          setAlarmDetailsId("");
          setAlarmMessage("");
        }}
        wrapperClass="alarmDetails"
        setContentDividers
      >
        {alarmDetailsId.length ? (
          <AlarmHistoryDetails id={alarmDetailsId} />
        ) : (
          <div style={{ overflowWrap: "break-word" }}>{alarmMessage}</div>
        )}
      </Modal>
    </>
  );
};
