import BulkDeleteModal from "../../../../components/BulkDeleteModal";
import { deleteResponseCenterDistribution } from "../../../../core/api/responsecenters/responsecenters";
import useTranslations from "../../../../core/i18n/useTranslations";

const DeleteDistributorModal = ({
  isOpen,
  responseCenterId,
  responseCenterContactIds,
  onClose,
  onDelete,
}: {
  responseCenterId: string | number;
  responseCenterContactIds: (string | number)[];
  isOpen: boolean;
  onClose: () => void;
  onDelete: (success: boolean) => void;
}) => {
  const t = useTranslations();

  return (
    <BulkDeleteModal
      isOpen={isOpen}
      onClose={onClose}
      onDelete={onDelete}
      labels={{
        single: t("AlarmReceptions:distributor_type"),
        multi: t("AlarmReceptions:distributor_type_multi"),
      }}
      ids={responseCenterContactIds.map((id) => id.toString())}
      apiFunc={(id, authenticatedRequest) =>
        deleteResponseCenterDistribution(
          responseCenterId,
          id,
          authenticatedRequest
        )
      }
    />
  );
};

export default DeleteDistributorModal;
