export enum PrecenseAlarmFlags {
  AlarmFlagEnum7 = 7,
  AlarmFlagEnum8 = 8,
  AlarmFlagEnum33 = 33,
  AlarmFlagEnum34 = 34,
  AlarmFlagEnum35 = 35,
  AlarmFlagEnum39 = 39,
}
export enum UserAlarmFlags {
  AlarmFlagEnum62 = 62,
}

export enum AdminType {
  Respondent = 0,
  Customer = 1,
  Seller = 2,
}

export enum TransmitterLogType {
  TransmitterLogType0 = 0,
  TransmitterLogType1 = 1,
  TransmitterLogType2 = 2,
  TransmitterLogType3 = 3,
  TransmitterLogType4 = 4,
  TransmitterLogType5 = 5,
}

export enum ColumnDataType {
  STRING,
  DATETIME,
  LONG,
}
export enum CameraProvisioning {
  NotNeeded = 0,
  Succeeded = 1,
  InProgress = 2,
}
export enum CameraAllowedViewing {
  AnyTime = 0,
  AlarmsTriggered = 1,
  PlannedWatch = 2,
  Never = 3,
}
export enum ActivityStatus {
  "New",
  "Planned",
  "Ongoing",
  "ExecuteSuccess",
  "ExecuteFailure",
  "ExecuteError",
  "Blocked",
  "Complete",
  "Aborted",
  "FailedAbort",
}
