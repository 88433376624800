import { AxiosInstance } from "axios";
import API_HOST from "../apiHost";
import { IADGroup, IADGroupRequest } from "./types";

export const getGroupMapping = (
  organizationId: string,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest
    .get<IADGroup[]>(
      `${API_HOST}api/v2/organizations/${organizationId}/groupmapping?pageSize=1000`
    )
    .catch((error: any) => Promise.reject(error.response.data.errors));

export const deleteADGroup = (
  organizationId: number | string,
  agGroupId: string,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest
    .delete(
      `${API_HOST}api/v2/organizations/${organizationId}/groupmapping/${agGroupId}`
    )
    .catch((error: any) => Promise.reject(error.response.data.errors));

export const updateADGroup = (
  organizationId: number | string,
  data: IADGroupRequest,
  authenticatedRequest: AxiosInstance,
  updateAD = true
) => {
  const methodToUse = updateAD ? "put" : "post";
  const requestData = JSON.stringify(data);
  return authenticatedRequest[methodToUse](
    `${API_HOST}api/v2/organizations/${organizationId}/groupmapping`,
    requestData,
    { headers: { "Content-Type": "application/json" } }
  ).catch((error: any) => Promise.reject(error.response.data.errors));
};
