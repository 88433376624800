import { Dispatch, SetStateAction, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { getErrorKey } from "../../../../components/Errors/ErrorAlert";
import {
  checkCommonContactExists,
  createCommonContact,
} from "../../../../core/api/contacts/contacts";
import {
  CheckContactExists,
  ContactDetails,
} from "../../../../core/api/contacts/types";
import { useOrganizationsTree } from "../../../../core/api/organizations/organizations";
import { notify } from "../../../../ui-lib/components/Alerts/Toast";
import { ContactForm, ContactFormActions } from "./ContactForm";
import { LoginModal } from "./LoginModal";
import { Spacer } from "../../../../components/Layout/Layout";
import useTranslations from "../../../../core/i18n/useTranslations";
import useUser from "../../../../core/user/useUser";
import PrimeModal from "../../../../ui-lib/components/PrimeModal/PrimeModal";

const CreateCommonContactModal = ({
  createCommonContactModalOpen,
  setCreateCommonContactModalOpen,
}: {
  createCommonContactModalOpen: boolean;
  setCreateCommonContactModalOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const t = useTranslations();
  const history = useHistory();
  const modalRef = useRef<ContactFormActions>(null);

  const { authenticatedRequest, config } = useUser();
  const { data: organisations } = useOrganizationsTree();
  const [createLogin, setCreateLogin] = useState(false);
  const [showCredentialModal, setShowCredentialModal] = useState(false);
  const [showForceCreateModal, setShowForceCreateModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [contactData, setContactData] = useState<number>();
  const forceCreateRef = useRef<boolean>(false);

  const onSubmit = async (values: ContactDetails) => {
    setLoading(true);

    if (!forceCreateRef.current) {
      const contactExistsQuery: CheckContactExists = {
        email: values.email,
        telephone1: values.telephone1,
        telephone2: values.telephone2,
        telephone3: values.telephone3,
      };

      try {
        const contactExists = await checkCommonContactExists(
          contactExistsQuery,
          authenticatedRequest
        );

        // check if any of the responses are filled, if any are we want to open the forceCreateModal
        if (Object.values(contactExists.data).find((field) => !!field)) {
          setShowForceCreateModal(true);

          setLoading(false);
          return;
        }
      } catch (error) {
        const errorKey = getErrorKey(error);
        notify({
          message: t(`Errors:${errorKey}`),
          variant: "error",
        });
        setLoading(false);
        return;
      }
    }

    if (
      values.scheduleItem?.repeatDays.length === 0 &&
      values.scheduleItem?.repeatType === "RepeatDays"
    ) {
      values.scheduleItem.repeatType = "NoRepeat";
      values.scheduleItem.endDate = undefined;
    }

    try {
      const result = await createCommonContact(values, authenticatedRequest);
      notify({
        message: t("Contacts:added_success"),
      });
      if (!createLogin) {
        history.push(`/adminportal/common-contacts/${result.data}`);
      } else {
        setShowCredentialModal(true);
        setContactData(result.data);
      }
    } catch (error) {
      const errorKey = getErrorKey(error);
      notify({
        message: t(`Errors:${errorKey}`),
        variant: "error",
      });
    } finally {
      setLoading(false);
      forceCreateRef.current = false;
    }
  };

  return (
    <>
      <LoginModal
        contactId={contactData}
        showModal={showCredentialModal}
        setShowModal={setShowCredentialModal}
        onClose={() =>
          history.push(`/adminportal/common-contacts/${contactData}`)
        }
      />

      <PrimeModal
        withHeader
        withFooter
        header={t("Contacts:force_create")}
        isOpen={showForceCreateModal}
        onClose={() => setShowForceCreateModal(false)}
        submitBtn={{
          text: t("Contacts:labels_create_contact"),
          variant: "primary",
          onClick: () => {
            forceCreateRef.current = true;
            modalRef?.current?.submitForm();
            setShowForceCreateModal(false);
          },
        }}
        cancelBtn={{
          text: t("Common:cancel"),
          onClick: () => setShowForceCreateModal(false),
        }}
      >
        <p style={{ color: "var(--Grey-600)" }}>
          {t("Contacts:force_create_description")}
        </p>
      </PrimeModal>

      <PrimeModal
        withFooter
        withHeader
        header={t("Contacts:create_private_contact")}
        isOpen={createCommonContactModalOpen}
        onClose={() => setCreateCommonContactModalOpen(false)}
        submitBtn={{
          text: t("Contacts:labels_create_contact"),
          onClick: () => modalRef?.current?.submitForm(),
        }}
        cancelBtn={{
          text: t("Common:cancel"),
          onClick: () => setCreateCommonContactModalOpen(false),
        }}
        loading={loading}
        contentStyle={{ width: "856px" }}
      >
        <p style={{ color: "var(--Grey-600)" }}>
          {t("Contacts:create_private_contact_description")}
        </p>
        <Spacer size={16} />
        <ContactForm
          ref={modalRef}
          organisations={organisations}
          onSubmit={onSubmit}
          setCreateLogin={setCreateLogin}
          withNewSchedule={config?.show.includes("ContactSchedule")}
        />
      </PrimeModal>
    </>
  );
};

export default observer(CreateCommonContactModal);
