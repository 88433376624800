import { useState } from "react";
import { useHistory } from "react-router-dom";
import ModalContent from "../../../ui-lib/components/Popup/ModalContent";
import useTranslations from "../../../core/i18n/useTranslations";
import { AdministratorDetails } from "../../../core/api/administrators/types";
import { Row, Spacer } from "../../../components/Layout/Layout";
import Button from "../../../ui-lib/components/Button/Button";
import { impersonate } from "../../../core/api/administrators/administrators";
import useUser from "../../../core/user/useUser";
import { notifyApiErrors } from "../../../core/helpers/helpers";

export const IMPERSONATE_LOCATION = "impersonate-location";

export const ImpersonateModal = ({
  adminData,
  onClose,
}: {
  adminData: AdministratorDetails;
  onClose: () => void;
}) => {
  const t = useTranslations();
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const user = useUser();

  const impersonateMe = async () => {
    try {
      setIsLoading(true);

      const result = await impersonate(
        adminData.id!,
        user.authenticatedRequest
      );

      user.impersonatedFrom = user.data?.name;

      await user.logIn({
        token: result.data.access_token,
        refreshToken: result.data.refresh_token,
        expiresIn: result.data.expires_in,
      });
      localStorage.setItem(IMPERSONATE_LOCATION, history.location.pathname);
      history.replace("/adminportal");
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ModalContent onClose={onClose} width="576px">
      <h2 className="subTitle">
        {t("Administrator:impersonate_modal_title").replace(
          "{0}",
          adminData.username!
        )}
      </h2>

      <Spacer size={16} />

      <p>{t("Administrator:impersonate_modal_description")}</p>

      <Spacer size={32} />

      <Row align="start" type="left">
        <Button
          variant="primary"
          text={t("Administrator:yes_continue")}
          onClick={impersonateMe}
          disabled={isLoading}
          loading={isLoading}
        />
        <Spacer size={16} />

        <Button
          variant="secondary"
          text={t("Common:cancel")}
          onClick={onClose}
        />
      </Row>
    </ModalContent>
  );
};
