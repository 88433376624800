import moment from "moment";
import "moment/locale/en-gb";
import "moment/locale/sv";
import "moment/locale/da";
import "moment/locale/nb";

export class DateRange {
  startDate: Date | undefined;

  endDate: Date;

  constructor() {
    this.endDate = new Date();
  }

  format(locale: string = "en-gb") {
    if (this.startDate === undefined) {
      return moment(this.endDate).locale(locale).format("dddd DD MMMM, YYYY");
    }

    let format = "DD";
    if (this.startDate?.getFullYear() !== this.endDate.getFullYear()) {
      format = "DD MMMM, YYYY";
    } else if (this.startDate?.getMonth() !== this.endDate.getMonth()) {
      format = "DD MMMM";
    }
    return `${moment(this.startDate).format(format)}-${moment(
      this.endDate
    ).format("DD MMMM, YYYY")}`;
  }
}
