import { useHistory, useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import useUser from "../../core/user/useUser";
import { deleteOrganization } from "../../core/api/organizations/organizations";

import ModalContent from "../../ui-lib/components/Popup/ModalContent";
import Button from "../../ui-lib/components/Button/Button";

import { notify } from "../../ui-lib/components/Alerts/Toast";
import { Row, Spacer } from "../../components/Layout/Layout";
import { getErrorKey } from "../../components/Errors/ErrorAlert";
import useTranslations from "../../core/i18n/useTranslations";

const DeleteOrganizationModal = ({ onClose }: { onClose: () => void }) => {
  const t = useTranslations();
  const { id } = useParams<{ id: string }>();
  const { authenticatedRequest } = useUser();
  const history = useHistory();

  return (
    <ModalContent onClose={onClose} width="576px">
      <h2 className="subTitle">
        {t("Common:delete_modal_title").replace(
          "{type}",
          t("Organizations:type")
        )}
      </h2>
      <Spacer size={16} />
      <p style={{ color: "var(--Grey-600)" }}>
        {t("Common:delete_modal_confirm").replace(
          "{type}",
          t("Organizations:type")
        )}
      </p>
      <Spacer size={32} />
      <Row type="left">
        <Button
          variant="destructive"
          text={t("Organizations:delete_organisation")}
          onClick={async () => {
            try {
              await deleteOrganization(id, authenticatedRequest);
              notify({
                message: t("Common:delete_success").replace(
                  "{type}",
                  t("Common:organisation")
                ),
              });
              history.replace("/adminportal/organizations");
            } catch (error) {
              const errorKey = getErrorKey(error);
              notify({
                message: t(`Errors:${errorKey}`),
                variant: "error",
              });
            }
          }}
        />
        <Spacer size={8} />
        <Button
          variant="secondary"
          text={t("Common:cancel")}
          onClick={onClose}
        />
      </Row>
    </ModalContent>
  );
};

export default observer(DeleteOrganizationModal);
