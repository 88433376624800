import { useState } from "react";
import { IItemProp } from "../../ui-lib/components/Dropdown/DropdownItem";
import { getCurrentDayNumber } from "../../ui-lib/utils/schedulerHelper";
import useTranslations from "../i18n/useTranslations";

export const useRepentanceDropdown = (
  preselectedItem: number,
  hideNoRepeat = false
) => {
  const t = useTranslations();
  const repentanceItems = [
    {
      id: 0,
      name: t("Contacts:daily"),
      isSelected: preselectedItem === 0,
    },
    {
      id: 2,
      name: t(`Contacts:weekly_on_day_${getCurrentDayNumber()}`),
      isSelected: preselectedItem === 2,
    },
    {
      id: 3,
      name: t("Contacts:workday"),
      isSelected: preselectedItem === 3,
    },
    {
      id: 4,
      name: t("Contacts:weekend"),
      isSelected: preselectedItem === 4,
    },
    {
      id: 5,
      name: t("Contacts:custom"),
      isSelected: preselectedItem === 5,
    },
  ];
  if (!hideNoRepeat) {
    repentanceItems.unshift({
      id: 1,
      name: t("Contacts:no_repeat"),
      isSelected: preselectedItem === 1,
    });
  }
  const [repentanceDropdownItems, setRepentanceDropdownItems] =
    useState<IItemProp[]>(repentanceItems);

  const setSelectedDropdownItems = (id: number) => {
    setRepentanceDropdownItems(
      repentanceDropdownItems.map((i) => ({
        ...i,
        isSelected: i.id === id,
      }))
    );
  };

  return {
    repentanceDropdownItems,
    setRepentanceDropdownItems,
    setSelectedDropdownItems,
  };
};
