import { Spacer } from "../../components/Layout/Layout";
import { maskPhoneNumber } from "../../core/helpers/helpers";
import useTranslations from "../../core/i18n/useTranslations";

export const Step2Info = ({
  type,
  phoneNumber,
}: {
  type: string;
  phoneNumber?: string;
}) => {
  const t = useTranslations();

  if (type !== "sms") {
    return (
      <>
        <p className="subTitle" style={{ color: "var(--Grey-600)" }}>
          {t("Messages:totp_insert")}
        </p>
        <Spacer size={8} />
        <p className="subTitleSmall" style={{ color: "var(--Grey-600)" }}>
          {t("Messages:totp_description")}
        </p>
      </>
    );
  }

  if (phoneNumber) {
    return (
      <p className="subTitle" style={{ color: "var(--Grey-600)" }}>
        {t("Messages:security_code_sent")}:{" "}
        <span style={{ color: "var(--Grey-800)" }}>
          {maskPhoneNumber(phoneNumber)}
        </span>
      </p>
    );
  }

  return (
    <p className="subTitle" style={{ color: "var(--Grey-600)" }}>
      {t("Messages:security_code_sent")}.
    </p>
  );
};
