import axios from "axios";
import { MenuAlternative } from "../user/User";
import { Timezone } from "./administrators/types";
import API_HOST from "./apiHost";

interface GetConfigParams {
  token: string;
}

export interface GetConfigResponse {
  account: {
    id: number;
  };
  dateFormat: string;
  timezone: Timezone;
  primaryLanguage: string;
  secondaryLanguage: string;
  show: string[];
  menuAlternatives: MenuAlternative[];
}

const api = {
  getConfig: async (params: GetConfigParams) => {
    const response = await axios.get<GetConfigResponse>(
      `${API_HOST}api/v2/guiconfig`,
      {
        headers: { Authorization: `Bearer ${params.token}` },
      }
    );

    return response.data;
  },
};

export default api;
