import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";

export interface Breakpoints<T> {
  mobile?(): T;
  tablet?(): T;
  desktop(): T;
}
export const breakpoints = { mobile: 640, tablet: 960, desktop: 1280 };
export enum DeviceSize {
  Mobile = 0,
  Tablet = 1,
  Desktop = 2,
}
const windowDimensions = () => ({
  height: window.innerHeight,
  width: window.innerWidth,
});
export const DimensionsCtx = createContext(windowDimensions());
export const isDesktop = (customBreakpoints?: any): boolean => {
  const internalBreakpoints = { ...breakpoints, ...customBreakpoints };
  return windowDimensions().width >= internalBreakpoints.tablet;
};
export const isTablet = (customBreakpoints?: any): boolean => {
  const internalBreakpoints = { ...breakpoints, ...customBreakpoints };
  return (
    windowDimensions().width < internalBreakpoints.tablet &&
    windowDimensions().width >= internalBreakpoints.mobile
  );
};
export const isMobile = (customBreakpoints?: any): boolean => {
  const internalBreakpoints = { ...breakpoints, ...customBreakpoints };
  return windowDimensions().width < internalBreakpoints.mobile;
};
export const getResponsiveStyle = <T extends unknown>(
  breakpointStyles: Breakpoints<T>,
  customBreakpoints?: any
): T | undefined => {
  const internalBreakpoints = { ...breakpoints, ...customBreakpoints };
  if (
    breakpointStyles.mobile &&
    windowDimensions().width < internalBreakpoints.mobile
  ) {
    return breakpointStyles.mobile();
  }
  if (
    breakpointStyles.tablet &&
    windowDimensions().width < internalBreakpoints.tablet
  ) {
    return breakpointStyles.tablet();
  }
  if (
    breakpointStyles.desktop &&
    windowDimensions().width >= internalBreakpoints.tablet
  ) {
    return breakpointStyles.desktop();
  }
  return undefined;
};
const DimensionsProvider = ({ children }: { children: ReactNode }) => {
  const [dimensions, setDimensions] = useState(windowDimensions());
  useEffect(() => {
    const resize = () => {
      setDimensions(windowDimensions());
    };
    window.addEventListener("resize", resize);
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);
  useEffect(() => {}, [dimensions]);
  return (
    <DimensionsCtx.Provider value={dimensions}>
      {children}
    </DimensionsCtx.Provider>
  );
};

export default DimensionsProvider;

export const useDimensions = () => useContext(DimensionsCtx);
