import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import useTranslations from "../../core/i18n/useTranslations";
import { Row, Spacer } from "../../components/Layout/Layout";
import TextInput from "../../ui-lib/components/Inputs/TextInput";
import Button from "../../ui-lib/components/Button/Button";
import authApi from "../../core/api/auth";
import { notify } from "../../ui-lib/components/Alerts/Toast";

export const ForgotPassword = () => {
  const t = useTranslations();
  const history = useHistory();

  const [userName, setUserName] = useState("");
  const [isError, setIsError] = useState(false);

  const onForgotPasswordRequestSubmit = async () => {
    try {
      await authApi.resetPasswordRequest(userName);
      localStorage.removeItem("userResetToken");
    } finally {
      notify({
        message: t("Common:reset_password_success"),
        autoCloseTime: 10000,
      });
      history.push("/adminportal/login");
    }
  };

  const resetPassword = () => {
    if (!userName.length) {
      setIsError(true);
      return;
    }
    setIsError(false);
    onForgotPasswordRequestSubmit();
  };

  return (
    <>
      <h1>{t("Common:reset_password_title")}</h1>
      <Spacer size={32} />
      <p>{t("Common:reset_password_subtitle")}</p>
      <Spacer size={32} />
      <TextInput
        required
        label={t("Administrator:general_input_username")}
        onChange={(e) => setUserName(e.target.value)}
        value={userName}
        validationError={isError ? t("Errors:input_field_required") : ""}
        placeholder={t("Common:enter_your_username")}
      />
      <Spacer size={40} />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Row type="space">
          <Button text={t("Common:reset_password")} onClick={resetPassword} />
          <Link
            to="/adminportal/login"
            style={{ fontWeight: 600, fontSize: "14px" }}
          >
            {t("Common:return_to_login")}
          </Link>
        </Row>
      </div>
    </>
  );
};
