import { omit } from "lodash";
import { Controller, useForm } from "react-hook-form";
import moment from "moment-timezone";
import { useState } from "react";
import { Column, Row, Spacer } from "../../../../components/Layout/Layout";
import { PeriodicInteractionDetails } from "../../../../core/api/interactions/types";
import useTranslations from "../../../../core/i18n/useTranslations";
import Button from "../../../../ui-lib/components/Button/Button";
import Checkbox from "../../../../ui-lib/components/Checkbox/Checkbox";
import Dropdown from "../../../../ui-lib/components/Dropdown/Dropdown";
import NumberInput from "../../../../ui-lib/components/Inputs/NumberInput";
import RadioGroup from "../../../../ui-lib/components/Radio/RadioGroup";
import DaySelector from "../../../../ui-lib/components/Tags/DaySelector";
import { arrayToWeeks, useActionTypeOptions, useSummary } from "./helpers";
import { InteractionTimeControl } from "./InteractionTimeControl";
import useUser from "../../../../core/user/useUser";

export type PeriodicInteractionFormValues = Omit<
  PeriodicInteractionDetails,
  | "onMonday"
  | "onTuesday"
  | "onWednesday"
  | "onThursday"
  | "onFriday"
  | "onSaturday"
  | "onSunday"
> & { days: number[] }; // Days are easier to handle as one form value, instead of 7

export const InteractionForm = ({
  submitText,
  timezones,
  onSubmit,
  onClose,
}: {
  submitText: string;
  timezones: string[];
  onSubmit: (values: PeriodicInteractionDetails) => Promise<void>;
  onClose: () => void;
}) => {
  const t = useTranslations();
  const { config } = useUser();
  const actionTypeOptions = useActionTypeOptions();
  const [formTitle, setFormTitle] = useState(actionTypeOptions[0].title);

  const {
    control,
    formState: { isSubmitting, errors, isValid },
    watch,
    setValue,
    getValues,
    trigger,
    handleSubmit,
  } = useForm<PeriodicInteractionFormValues>({
    defaultValues: {
      type: actionTypeOptions.length > 0 ? actionTypeOptions[0].id : undefined,
      days: [],
      hour: undefined,
      minute: undefined,
      timezone: config?.timezone.timezoneName ?? undefined,
    },
  });

  const summary = useSummary({ formValues: watch(), isValid });
  const interactionType = watch("type");

  return (
    <>
      <p style={{ color: "var(--Grey-600)" }}>{formTitle}</p>

      <Spacer size={32} />

      <form
        onSubmit={handleSubmit(async (formValues) => {
          const values = omit(
            {
              ...arrayToWeeks(formValues.days),
              ...formValues,
            },
            "days"
          );

          await onSubmit(values as any);
        })}
      >
        <Controller
          name="type"
          control={control}
          render={({ field }) => {
            const selectedItem = actionTypeOptions.find(
              (i) => i.id === field.value
            );

            return (
              <RadioGroup
                label={t("Objects:periodic_interactions_type_label")}
                name={field.name}
                items={actionTypeOptions}
                selectedValue={selectedItem?.value}
                onChange={(value) => {
                  const item = actionTypeOptions.find((o) => o.value === value);

                  if (item) {
                    setValue(field.name, item.id);
                    trigger(field.name);
                    setFormTitle(item.title);
                    if (item.id === 2 || item.id === 100) {
                      setValue("maxResponseTime", 1);
                    }
                  }
                }}
              />
            );
          }}
        />

        <Spacer size={16} />

        <Row align="center">
          <Column
            align="start"
            style={{
              width: "100%",
              flex: "auto",
            }}
          >
            <Controller
              name="days"
              control={control}
              rules={{ validate: (value) => !!value.length }}
              render={({ field }) => (
                <DaySelector
                  label={t("Common:days")}
                  values={field.value}
                  setValues={(updated) => {
                    setValue(field.name, updated);
                    trigger("days");
                  }}
                  validationError={errors.days && t("Errors:input_format")}
                />
              )}
            />
          </Column>
          <Spacer size={32} />
          <Column
            align="start"
            style={{
              width: "100%",
              flex: "auto",
            }}
          >
            <Controller
              name="continueNextWeek"
              control={control}
              render={({ field }) => (
                <Checkbox
                  label={t("Objects:periodic_interactions_repeat_label")}
                  checked={field.value}
                  onChange={field.onChange}
                  style={{ padding: 0 }}
                />
              )}
            />
          </Column>
        </Row>

        <Spacer size={16} />

        <Row>
          <InteractionTimeControl watch={watch} setValue={setValue} />
          {interactionType !== 2 && interactionType !== 100 && (
            <>
              <Spacer size={32} />
              <Controller
                name="maxResponseTime"
                control={control}
                rules={{ required: true, min: 1, max: 240 }}
                render={({ field }) => (
                  <NumberInput
                    required
                    label={t(
                      `Objects:${
                        getValues("type") === 1
                          ? "camera_be_view"
                          : "periodic_interactions_max_response"
                      }`
                    )}
                    placeholder="1...240"
                    pinnedText="min"
                    pinnedTextPosition="right"
                    value={`${field.value}`}
                    onChange={(e, value) => {
                      setValue("maxResponseTime", +value);
                      trigger("maxResponseTime");
                    }}
                    validationError={
                      errors.maxResponseTime && t("Errors:input_format")
                    }
                  />
                )}
              />
            </>
          )}
        </Row>

        <Spacer size={16} />

        <Row align="start">
          <Column style={{ width: "100%" }}>
            <Controller
              name="timezone"
              control={control}
              rules={{ required: true }}
              render={({ field }) => {
                const dropdownItems = timezones.map((timezone) => {
                  const formattedTimezone = moment()
                    .tz(timezone)
                    .format()
                    .split("+");
                  return {
                    id: timezone,
                    name: `${timezone} ${
                      formattedTimezone.length > 1
                        ? `+${formattedTimezone[1]}`
                        : ""
                    }`,
                    isSelected: timezone === field.value,
                  };
                });

                return (
                  <Dropdown
                    required
                    title={t("Objects:periodic_interactions_timezone")}
                    width="100%"
                    selectedItem={dropdownItems.find((i) => i.isSelected)}
                    items={dropdownItems}
                    onSelectItem={(item) => {
                      setValue(field.name, item.id as string);
                      trigger(field.name);
                    }}
                    validationError={
                      errors.timezone && t("Errors:input_field_required")
                    }
                  />
                );
              }}
            />
          </Column>
          <Spacer size={32} />
          <Column style={{ width: "100%" }} />
        </Row>

        {summary && (
          <>
            <Spacer size={16} />
            <span className="small" style={{ color: "var(--Grey-600)" }}>
              {summary}
            </span>
          </>
        )}

        <Spacer size={32} />

        <Row type="left">
          <Button
            type="submit"
            variant="primary"
            text={submitText}
            loading={isSubmitting}
          />
          <Spacer size={8} />
          <Button
            variant="secondary"
            text={t("Common:cancel")}
            onClick={onClose}
          />
        </Row>
      </form>
    </>
  );
};
