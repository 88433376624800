import classnames from "classnames";
import { PopupContextMenu } from "../Menus/PopupContextMenu/PopupContextMenu";
import { Button } from "../Button/Button";
import inputStyles from "../Inputs/Input.module.css";
import styles from "./TagSelector.module.css";

export interface TagSelectorOption {
  title: string;
  value: string;
}

export interface TagSelectorProps {
  options: TagSelectorOption[];
  selectedOptions: TagSelectorOption[];
  onSelect?: (selectedOptions: TagSelectorOption[]) => void;
  selectLimit?: number;
  selectedOptionVariant?: "primary" | "success";
  maxPreviewOptions?: number;
  className?: string;
  validationError?: string;
  style: React.CSSProperties;
}

export const TagSelector = ({
  options,
  selectedOptions = [],
  onSelect = undefined,
  selectLimit = undefined,
  selectedOptionVariant = "primary",
  maxPreviewOptions = 3,
  className = undefined,
  validationError,
  style,
}: TagSelectorProps) => {
  const renderOptions = (startIndex: number, endIndex: number) =>
    options.slice(startIndex, endIndex).map((option) => (
      <button
        key={`option_${option.value}`}
        type="button"
        className={classnames({
          [styles.tagOption]: true,
          [styles.tagOptionPrimary]:
            selectedOptionVariant === "primary" &&
            selectedOptions.some((x) => x.value === option.value),
          [styles.tagOptionSuccess]:
            selectedOptionVariant === "success" &&
            selectedOptions.some((x) => x.value === option.value),
        })}
        onClick={() => {
          if (
            selectLimit !== undefined &&
            selectedOptions.length > selectLimit
          ) {
            return;
          }
          if (onSelect) {
            const nextSelectedOptions = [...selectedOptions];
            const existingIndex = nextSelectedOptions.findIndex(
              (x) => x.value === option.value
            );
            if (existingIndex !== -1) {
              nextSelectedOptions.splice(existingIndex, 1);
            } else {
              nextSelectedOptions.push(option);
            }
            onSelect(nextSelectedOptions);
          }
        }}
      >
        {option.title}
      </button>
    ));

  return (
    <>
      <span
        className={classnames({
          [styles.tagSelector]: true,
          [styles.inputValidationError]: validationError !== undefined,
          ...(className ? { [className]: true } : {}),
        })}
        style={style}
      >
        {/* Visible options */}
        <span>{renderOptions(0, maxPreviewOptions)}</span>

        {/* Show more button with context menu */}
        {options.length > maxPreviewOptions ? (
          <span>
            <PopupContextMenu
              position="bottom right"
              trigger={() => (
                <div>
                  <Button
                    type="button"
                    variant="secondary"
                    size="extraSmall"
                    image="dots-horizontal"
                    className={classnames(
                      styles.tagContextMenuButton,
                      styles.tagOption
                    )}
                  />
                </div>
              )}
            >
              <div className={styles.tagContextMenuContainer}>
                {renderOptions(maxPreviewOptions, options.length)}
              </div>
            </PopupContextMenu>
          </span>
        ) : null}
      </span>
      {validationError ? (
        <div className={inputStyles.validationError}>{validationError}</div>
      ) : null}
    </>
  );
};
export default TagSelector;
