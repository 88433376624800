import classNames from "classnames";
import styles from "./Information.module.css";
import { Icon } from "../Icon";

export interface InformationProps {
  title: string;
  description: string;
  fullWidth?: boolean;
}

export default function Information({
  title,
  description,
  fullWidth = false,
}: InformationProps) {
  return (
    <div
      className={classNames({
        [styles.container]: true,
        [styles.fullWidth]: fullWidth,
      })}
    >
      <div className={styles.titleContainer}>
        <Icon name="chat-alt" color="Grey-400" size={18} />
        <span className={styles.title}>{title}</span>
      </div>
      <div className={styles.description}>{description}</div>
    </div>
  );
}
