import { createContext } from "react";
import User from "./User";

const user = new User();

export const UserContext = createContext(user);

export const UserProvider = ({ children }: { children: React.ReactNode }) => (
  <UserContext.Provider value={user}>{children}</UserContext.Provider>
);
