import { observer } from "mobx-react";
import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Spacer } from "../../../../components/Layout/Layout";
import { ResponseCenterDetails } from "../../../../core/api/responsecenters/types";
import useTranslations from "../../../../core/i18n/useTranslations";
import Accordion from "../../../../ui-lib/components/Accordion/Accordion";
import Checkbox from "../../../../ui-lib/components/Checkbox/Checkbox";
import TextArea from "../../../../ui-lib/components/Inputs/Textarea";

const AlarmLogForm = ({
  defaultValues,
}: {
  defaultValues: ResponseCenterDetails;
}) => {
  const t = useTranslations();

  const { control, setValue, watch } = useFormContext<ResponseCenterDetails>();

  const allowReasonInput = watch("allowReasonInput");

  useEffect(() => {
    setValue("allowReasonInput", defaultValues.allowReasonInput);
  }, [defaultValues]);

  return (
    <Accordion
      title={t("AlarmReceptions:alarm_handling_alarm_log")}
      description={t("AlarmReceptions:alarm_handling_alarm_log_description")}
    >
      <Controller
        name="requireOperatorLog"
        control={control}
        render={({ field }) => (
          <Checkbox
            label={t(
              "AlarmReceptions:alarm_handling_input_require_operator_log"
            )}
            checked={!!field.value}
            onChange={field.onChange}
            style={{ padding: 0 }}
          />
        )}
      />
      <Spacer size={16} />
      <Controller
        name="operatorLogDefaultValue"
        control={control}
        render={({ field }) => (
          <TextArea
            label={t(
              "AlarmReceptions:alarm_handling_input_operator_log_default"
            )}
            placeholder={t("Common:enter_value")}
            value={field.value}
            onChange={field.onChange}
          />
        )}
      />
      <Spacer size={16} />
      <Controller
        name="requireOperatorLogAction"
        control={control}
        render={({ field }) => (
          <Checkbox
            label={t(
              "AlarmReceptions:alarm_handling_input_require_operator_log_action"
            )}
            checked={!!field.value}
            onChange={field.onChange}
            style={{ padding: 0 }}
          />
        )}
      />
      <Controller
        name="sortReasonActions"
        control={control}
        render={({ field }) => (
          <Checkbox
            label={t(
              "AlarmReceptions:alarm_handling_input_sort_reason_actions"
            )}
            checked={!!field.value}
            onChange={field.onChange}
            style={{ padding: 0 }}
          />
        )}
      />
      <Controller
        name="actionFreetext"
        control={control}
        render={({ field }) => (
          <Checkbox
            label={t("AlarmReceptions:alarm_handling_input_action_free_text")}
            checked={!!field.value}
            onChange={field.onChange}
            style={{ padding: 0 }}
          />
        )}
      />
      <Spacer size={16} />
      <Controller
        name="actionAlternatives"
        control={control}
        render={({ field }) => (
          <TextArea
            label={t(
              "AlarmReceptions:alarm_handling_input_action_alternatives"
            )}
            placeholder={t(
              "AlarmReceptions:alarm_handling_input_action_alternatives_placeholder"
            )}
            value={field.value}
            onChange={field.onChange}
            css={{
              minHeight: "130px",
            }}
          />
        )}
      />
      <Spacer size={16} />
      <Controller
        name="allowReasonInput"
        control={control}
        render={({ field }) => (
          <Checkbox
            label={t("AlarmReceptions:alarm_handling_input_allow_reason_input")}
            checked={!!field.value}
            onChange={field.onChange}
            style={{ padding: 0 }}
          />
        )}
      />
      {allowReasonInput && (
        <>
          <Controller
            name="requireOperatorLogReason"
            control={control}
            render={({ field }) => (
              <Checkbox
                label={t(
                  "AlarmReceptions:alarm_handling_input_require_operator_log_reason"
                )}
                checked={!!field.value}
                onChange={field.onChange}
                style={{ padding: 0 }}
              />
            )}
          />
          <Controller
            name="reasonFreetext"
            control={control}
            render={({ field }) => (
              <Checkbox
                label={t(
                  "AlarmReceptions:alarm_handling_input_reason_freetext"
                )}
                checked={!!field.value}
                onChange={field.onChange}
                style={{ padding: 0 }}
              />
            )}
          />
          <Spacer size={16} />
          <Controller
            name="reasonAlternatives"
            control={control}
            render={({ field }) => (
              <TextArea
                label={t(
                  "AlarmReceptions:alarm_handling_input_reason_alternatives"
                )}
                placeholder={t(
                  "AlarmReceptions:alarm_handling_input_reason_alternatives_placeholder"
                )}
                value={field.value}
                onChange={field.onChange}
                css={{
                  minHeight: "130px",
                }}
              />
            )}
          />
        </>
      )}
    </Accordion>
  );
};

export default observer(AlarmLogForm);
