import { createContext, useEffect, useRef, useState } from "react";
import useUser from "../../../../core/user/useUser";
import { getIdentity } from "../../../../core/api/identities/identities";
import { IIdentity } from "../../../../core/api/identities/types";

// TODO: Create generic context for this purpose (this one is very similar to SharedObjectContext)
export const SharedIdentityContext = createContext<{
  data?: IIdentity;
  isLoading: boolean;
  isError: boolean;
  reload: () => Promise<void>;
}>({
  data: undefined,
  isLoading: false,
  isError: false,
  reload: async () => {},
});

export const SharedIdentityProvider = ({
  id,
  children,
}: {
  id: string;
  children: React.ReactNode;
}) => {
  const isAlive = useRef(false);
  const { authenticatedRequest } = useUser();

  const [data, setData] = useState<IIdentity>();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const fetchIdentity = async () => {
    setIsError(false);
    setIsLoading(true);

    try {
      const result = await getIdentity(id, authenticatedRequest);
      setData(result.data);
    } catch (error) {
      if (isAlive.current) {
        setIsError(true);
      }
    }

    if (isAlive.current) {
      setIsLoading(false);
    }
  };

  // Fetch admin on mount and when admin id is updated
  useEffect(() => {
    fetchIdentity();
  }, [id]);

  // To make sure we do not try to update state if component did unmount
  useEffect(() => {
    isAlive.current = true;

    return () => {
      isAlive.current = false;
    };
  }, []);

  return (
    <SharedIdentityContext.Provider
      value={{
        data,
        isLoading,
        isError,
        reload: fetchIdentity,
      }}
    >
      {children}
    </SharedIdentityContext.Provider>
  );
};
