import { ReactNode } from "react";
import classNames from "classnames";
import Popup from "reactjs-popup";
import styles from "./IconWithInfo.module.css";
import Icon, { IconColor } from "../Icon";

export type IconWithInfoProps = {
  iconName?: string;
  iconColor: IconColor;
  addIconBg?: boolean;
  headerText?: string;
  bodyComponents: ReactNode;
  additionalStyles?: any;
  withHeaderIcon?: boolean;
  headerIconTextHeader?: string;
  headerIconText?: string;
};

export const IconWithInfo = ({
  iconName,
  iconColor,
  addIconBg = false,
  headerText = undefined,
  bodyComponents,
  additionalStyles,
  withHeaderIcon,
  headerIconTextHeader,
  headerIconText,
}: IconWithInfoProps) => {
  const questionIcon = (
    <span style={{ cursor: "pointer" }}>
      <Icon
        className={styles.iconHeader}
        name="question-mark-circle"
        size={18}
        color="Grey-300"
        hoverEffect
      />
    </span>
  );

  return (
    <div className={styles.iconWithInfoContainer} style={additionalStyles}>
      {iconName && (
        <div
          className={classNames(styles.iconWrapper, {
            [styles.iconBackground]: addIconBg,
          })}
        >
          <Icon name={iconName} color={iconColor} />
        </div>
      )}

      <div>
        {headerText && (
          <>
            <p className={styles.infoHeaderWrapper}>
              {headerText}{" "}
              {withHeaderIcon && headerIconText && (
                <Popup
                  contentStyle={{ width: "480px", padding: "4px 8px" }}
                  trigger={() => questionIcon}
                  position={["bottom left", "top left"]}
                  arrow={false}
                  modal={false}
                  repositionOnResize
                  keepTooltipInside
                >
                  {headerIconTextHeader && (
                    <p className={styles.iconTooltipHeader}>
                      {headerIconTextHeader}
                    </p>
                  )}
                  <p className={styles.iconTooltipText}>{headerIconText}</p>
                </Popup>
              )}
            </p>
          </>
        )}
        <span className={styles.infoBodyWrapper}>{bodyComponents}</span>
      </div>
    </div>
  );
};
