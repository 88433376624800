import { ReactElement } from "react";
import { CollapsibleSelfcontainedBlock } from "../CollapsibleBlock/CollapsibleBlock";
import styles from "./ExpandableList.module.css";

interface ExpandableListProps {
  title: string;
  children: ReactElement;
  noScroll?: boolean;
}

const ExpandableList = ({ title, children, noScroll }: ExpandableListProps) => (
  <div className={styles.expandableWrapper}>
    <CollapsibleSelfcontainedBlock noScroll={noScroll} title={title}>
      {children}
    </CollapsibleSelfcontainedBlock>
  </div>
);

export default ExpandableList;
