import { useTranslation } from "react-i18next";
import { SubRow } from "./SubRow";
import { RoleMapping } from "../../../core/api/administrators/types";
import useUser from "../../../core/user/useUser";
import { isOrganizationVisible } from "../../utils/organizationsHelper";

export const RoleMappingCell = ({
  role,
  index,
  rolesLength,
  openHierarchyTree = () => {},
}: {
  role: RoleMapping;
  index: number;
  rolesLength: number;
  openHierarchyTree?: Function;
}) => {
  const { t } = useTranslation();
  const respondent = role.type === "respondent";
  const organization = role.type === "customer";
  const user = useUser();
  const organizationVisible = isOrganizationVisible(user);

  let linkTo: string | undefined; // if it is a reseller don't link it to anywhere as we dont do any reseller stuff in new admin
  let icon = "";
  let iconTooltip = "";
  if (respondent) {
    linkTo = `/adminportal/alarm-receptions/${role.belongsToId}`;
    icon = "alarm-reception";
    iconTooltip = t("Administrator:general_input_alarm_reception");
  } else if (organization) {
    if (organizationVisible) {
      linkTo = `/adminportal/organizations/${role.belongsToId}`;
    }
    icon = "office-building";
    iconTooltip = t("Administrator:customer");
  }

  if (role.belongsToId === 1) {
    return null;
  }

  return (
    <>
      <SubRow
        key={role.roleTemplateId}
        displayDivider={index + 1 !== rolesLength}
        value={role.belongsTo}
        linkTo={linkTo}
        icon={icon}
        leftIconTooltip={iconTooltip}
        hierarchyTooltip
        hierarchyButton={
          organization && organizationVisible
            ? (event: any) => {
                if (event.type === "click") {
                  event.stopPropagation();
                  openHierarchyTree?.(role, event);
                }
              }
            : undefined
        }
      />
    </>
  );
};
