import { observer } from "mobx-react";
import { ObjectDetailsParams } from "../../../core/api/objects/types";

import { notify } from "../../../ui-lib/components/Alerts/Toast";

import { getErrorKey } from "../../../components/Errors/ErrorAlert";

import { ObjectResponseForm } from "./ObjectResponseForm";
import useSharedObject from "../SharedObjectContext/useSharedObject";
import useTranslations from "../../../core/i18n/useTranslations";

const ObjectResponseDetails = () => {
  const t = useTranslations();

  const { data, update } = useSharedObject();

  const onSubmit = async (values: ObjectDetailsParams) => {
    try {
      await update(values);
      notify({
        message: t("Objects:edited_success"),
      });
    } catch (error) {
      const errorKey = getErrorKey(error);
      notify({
        message: t(`Errors:${errorKey}`),
        variant: "error",
      });
    }
  };

  return <ObjectResponseForm defaultValues={data} onSubmit={onSubmit} />;
};

export default observer(ObjectResponseDetails);
