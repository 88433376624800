import { useState } from "react";
import { deleteTransmitterCredentials } from "../../core/api/transmitters/transmitters";
import { notifyApiErrors } from "../../core/helpers/helpers";
import useTranslations from "../../core/i18n/useTranslations";
import useUser from "../../core/user/useUser";
import { notify } from "../../ui-lib/components/Alerts/Toast";
import PrimeModal from "../../ui-lib/components/PrimeModal/PrimeModal";

export const DeleteTransmitteCredentialsModal = ({
  onClose,
  credentialsId,
  transmitterId,
  isOpen,
  uploadTransmitterData,
  cleanCredentialsHolder,
}: {
  onClose: () => void;
  credentialsId: number | string;
  transmitterId: number | string;
  isOpen: boolean;
  uploadTransmitterData?: () => void;
  cleanCredentialsHolder?: () => void;
}) => {
  const t = useTranslations();
  const { authenticatedRequest } = useUser();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <PrimeModal
      withHeader
      withFooter
      header={t("Transmitters:delete_credentials")}
      onClose={onClose}
      isOpen={isOpen}
      submitBtn={{
        text: t("Common:delete"),
        onClick: async () => {
          setIsLoading(true);
          try {
            await deleteTransmitterCredentials(
              transmitterId,
              credentialsId,
              authenticatedRequest
            );
            uploadTransmitterData?.();
            cleanCredentialsHolder?.();
            notify({
              variant: "info",
              message: t("Messages:credentials_deleted"),
            });
            onClose();
          } catch (error: any) {
            notifyApiErrors(error.response?.data?.errors);
          }
          setIsLoading(false);
        },
      }}
      cancelBtn={{ text: t("Common:cancel"), onClick: onClose }}
      loading={isLoading}
      contentStyle={{ width: "960px" }}
    >
      <p style={{ color: "var(--Grey-600)" }}>
        {t("Transmitters:want_to_delete_credentials")}
      </p>
    </PrimeModal>
  );
};
