import moment from "moment/moment";

export const toCronString = (
  startTime: string,
  daysOfRepeat: number[],
  endDate?: any
) => {
  const [hours, minutes] = startTime.split(":");
  let cronString = `0 ${minutes} ${hours} ? * ${daysOfRepeat.join(",")}`;
  if (endDate) {
    const endYear = moment(endDate).get("year");
    const currentYear = moment().get("year");

    cronString = `${cronString} ${currentYear}-${endYear}`;
  }
  return cronString;
};
