import { createContext, useEffect, useMemo, useRef, useState } from "react";
import useUser from "../../../core/user/useUser";
import {
  getObject,
  getObjectAbsence,
  updateObject,
} from "../../../core/api/objects/objects";
import {
  IObjectAbsenceModel,
  ObjectDetailsModel,
  ObjectDetailsParams,
} from "../../../core/api/objects/types";
import { notifyApiErrors } from "../../../core/helpers/helpers";

export const SharedObjectContext = createContext<{
  data?: ObjectDetailsModel;
  absenceData?: IObjectAbsenceModel;
  isLoading: boolean;
  isError: boolean;
  update: (objectDetails: ObjectDetailsParams) => Promise<void>;
  reloadAbsence: () => Promise<void>;
  fetchObject: () => Promise<void>;
}>({
  data: undefined,
  absenceData: undefined,
  isLoading: false,
  isError: false,
  update: async () => {},
  fetchObject: async () => {},
  reloadAbsence: async () => {},
});

export const SharedObjectProvider = ({
  objectId,
  children,
}: {
  objectId: string;
  children: React.ReactNode;
}) => {
  const isAlive = useRef(false);
  const { authenticatedRequest } = useUser();

  const [data, setData] = useState<ObjectDetailsModel>();
  const [absenceData, setAbsenceData] = useState<IObjectAbsenceModel>();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const fetchObject = async () => {
    setIsError(false);
    setIsLoading(true);

    try {
      const result = await getObject(objectId, authenticatedRequest);
      const absenceResult = await getObjectAbsence(
        objectId,
        authenticatedRequest
      );
      if (isAlive.current) {
        setData(result.data);
        setAbsenceData(absenceResult.data);
      }
    } catch (error: any) {
      if (isAlive.current) {
        setIsError(true);
        notifyApiErrors(error.response?.data?.errors);
      }
    }

    if (isAlive.current) {
      setIsLoading(false);
    }
  };

  const reloadAbsence = async () => {
    setIsError(false);
    setIsLoading(true);

    try {
      const absenceResult = await getObjectAbsence(
        objectId,
        authenticatedRequest
      );
      if (isAlive.current) {
        setAbsenceData(absenceResult.data);
      }
    } catch (error: any) {
      if (isAlive.current) {
        setIsError(true);
        notifyApiErrors(error.response?.data?.errors);
      }
    }

    if (isAlive.current) {
      setIsLoading(false);
    }
  };

  const update = async (objectDetails: ObjectDetailsParams) => {
    setIsLoading(true);

    try {
      if (objectDetails?.alarmHandlingId === 0) {
        objectDetails.alarmHandlingId = undefined;
      }

      const updated = {
        ...data,
        ...objectDetails,
      };
      const { data: response } = await updateObject(
        objectId,
        updated,
        authenticatedRequest
      );

      if (isAlive.current) {
        if (response) {
          fetchObject();
          setIsLoading(false);
        } else {
          throw new Error("Could not update object");
        }
      }
    } catch (error) {
      if (isAlive.current) {
        setIsLoading(false);

        throw error;
      }
    }
  };

  // Fetch object on mount and when object is updated
  useEffect(() => {
    fetchObject();
  }, [objectId]);

  // To make sure we do not try to update state if component did unmount
  useEffect(() => {
    isAlive.current = true;

    return () => {
      isAlive.current = false;
    };
  }, []);

  const contextData = useMemo(
    () => ({
      data,
      absenceData,
      isLoading,
      isError,
      update,
      fetchObject,
      reloadAbsence,
    }),
    [isLoading, data, absenceData, isError]
  );

  return (
    <SharedObjectContext.Provider value={contextData}>
      {children}
    </SharedObjectContext.Provider>
  );
};
