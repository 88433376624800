import React from "react";
import { TransmitterSetting } from "../../core/api/transmitters/types";
import InputContainer from "../../ui-lib/components/Inputs/InputContainer";
import Panel from "../../ui-lib/components/Panel/Panel";
import Radio from "../../ui-lib/components/Radio/Radio";
import { Column, Spacer } from "../Layout/Layout";
import Dropdown from "../../ui-lib/components/Dropdown/Dropdown";
import { IDropdownItem } from "../../ui-lib/components/Dropdown/DropdownItem";
import NumberInput from "../../ui-lib/components/Inputs/NumberInput";
import TextInput from "../../ui-lib/components/Inputs/TextInput";
import Checkbox from "../../ui-lib/components/Checkbox/Checkbox";
import useTranslations from "../../core/i18n/useTranslations";
import Divider from "../../ui-lib/components/Divider/Divider";

export interface SettingsProps {
  idPrefix: string;
  setting: TransmitterSetting;
  onChangeValue(changedItem: TransmitterSetting): void;
}
export default function Setting({
  idPrefix,
  setting,
  onChangeValue,
}: SettingsProps) {
  const t = useTranslations();
  const settingState = setting;
  const disabled = settingState.level !== "U";
  const useNumberLimits = settingState.max !== settingState.min;
  const alternativeItems: IDropdownItem[] = settingState.alternatives
    ? settingState.alternatives.map((alternative) => ({
        id: alternative.value,
        name: alternative.key,
        isSelected: alternative.value === settingState.defaultValue,
        disabled: false,
      }))
    : [];
  const changeLevel = (level: string) => {
    let newVal: TransmitterSetting;
    if (level !== "U") {
      newVal = {
        ...settingState,
        level,
        defaultValue: settingState.originalValue || "",
      };
    } else {
      newVal = { ...settingState, level };
    }
    onChangeValue(newVal);
  };
  const setValue = (val: string) => {
    const newVal = { ...settingState, defaultValue: val };
    onChangeValue(newVal);
  };
  return (
    <InputContainer
      label={
        <p className="labelRowLabel">
          {t(`Settings:${settingState.identifier}`)}
        </p>
      }
      input={
        <Panel
          padding={{
            bottom: 16,
            top: 16,
            left: 16,
            right: 16,
          }}
          style={{
            display: "flex",
            alignSelf: "stretch",
            border: "1px solid var(--Grey-200)",
            boxShadow: "none",
            minHeight: "185px",
          }}
        >
          <Column type="top" align="fill">
            <div role="radiogroup" aria-labelledby={`${idPrefix}_legend`}>
              <div id={`${idPrefix}_legend`} className="visuallyHidden">
                {t(`Settings:${settingState.identifier}`)}
              </div>
              <Radio
                innerStyle={{ color: "var(--Grey-600)" }}
                inputId={`${idPrefix}_useParent`}
                name={`${idPrefix}_group`}
                checked={settingState.level !== "U"}
                value="G"
                onChange={() => {
                  changeLevel("G");
                }}
                text={t("Settings:use_parent_value")}
              />
              <Spacer size={8} />
              <Radio
                inputId={`${idPrefix}_custom`}
                name={`${idPrefix}_group`}
                value="U"
                onChange={() => {
                  changeLevel("U");
                }}
                checked={settingState.level === "U"}
                text={t("Settings:use_custom_value")}
              />
            </div>
            <Spacer size={16} />
            <Divider />
            <Spacer size={16} />
            {alternativeItems.length > 0 && (
              <Dropdown
                id={`${idPrefix}_dd`}
                disabled={disabled}
                selectedItem={alternativeItems.find(
                  (alternative) => alternative.isSelected
                )}
                items={alternativeItems}
                onSelectItem={(item) => {
                  alternativeItems.forEach((alt) => {
                    alt.isSelected = false;
                  });
                  item.isSelected = true;
                  setValue(item.id as string);
                }}
              />
            )}
            {alternativeItems.length === 0 && settingState.type === "I" && (
              <NumberInput
                id={`${idPrefix}_nb`}
                disabled={disabled}
                value={settingState.defaultValue}
                onChange={(e, val) => {
                  setValue(val);
                }}
                min={useNumberLimits ? settingState.min : undefined}
                max={useNumberLimits ? settingState.max : undefined}
              />
            )}
            {alternativeItems.length === 0 && settingState.type === "B" && (
              <Checkbox
                htmlFor={`${idPrefix}_cb`}
                disabled={disabled}
                checked={settingState.defaultValue === "True"}
                label={t(`Settings:enabled`)}
                onChange={() => {
                  setValue(
                    settingState.defaultValue === "False" ? "True" : "False"
                  );
                }}
              />
            )}
            {alternativeItems.length === 0 && settingState.type === "S" && (
              <TextInput
                id={`${idPrefix}_alteredvalue`}
                disabled={disabled}
                label={t(`Settings:alteredvalue`)}
                hideLabel
                value={settingState.defaultValue}
                onChange={(e, val) => {
                  setValue(val);
                }}
              />
            )}
          </Column>
        </Panel>
      }
    />
  );
}
