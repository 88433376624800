import classNames from "classnames";
import { Link } from "react-router-dom";

import { Column, Row } from "../../../../components/Layout/Layout";
import { formattedDateString } from "../../../../core/helpers/helpers";
import Button from "../../../../ui-lib/components/Button/Button";
import Icon from "../../../../ui-lib/components/Icon";
import useTranslations from "../../../../core/i18n/useTranslations";
import TableCell, {
  MissingValueTableCell,
} from "../../../../ui-lib/components/Tables/TableCell";

import styles from "./RelatedItems.module.css";
import Badge from "../../../../ui-lib/components/Badges/Badge";

export const RelatedItem = ({
  itemId,
  itemType,
  itemName,
  relationId,
  objectName,
  identifier,
  subscription,
  hideRemoveBtn = false,
  lastCommunicationTest,
  onClick,
}: {
  itemId: number;
  itemType: "transmitter" | "object";
  itemName: string;
  relationId?: number;
  objectName?: string;
  identifier?: string;
  subscription?: boolean;
  hideRemoveBtn?: boolean;
  lastCommunicationTest?: Date;
  onClick?: () => void;
}) => {
  const t = useTranslations();
  const isObjectType = itemType === "object";
  return (
    <>
      <Row className={classNames(styles.wrapper)}>
        <div className={classNames(styles.relatedItemType)}>
          <Icon
            name={isObjectType ? "cube" : "rss"}
            size={18}
            color="Grey-300"
          />
          <span>
            {isObjectType
              ? t("Administrator:related_object")
              : t("Administrator:positioning_transmitter")}
          </span>
        </div>
        {isObjectType ? (
          <Link
            className={classNames(styles.relatedName)}
            to={`/adminportal/objects/${itemId}`}
          >
            {itemName}
          </Link>
        ) : (
          <span>{itemName}</span>
        )}
        <Column>
          <MissingValueTableCell
            className={classNames({
              [styles.objectName]: !!objectName,
            })}
            value={objectName}
            linkTo={
              isObjectType
                ? `/adminportal/organizations/${relationId}`
                : undefined
            }
            iconColor="Grey-300"
            iconSize={18}
            missingValueText={t("Transmitters:no_model")}
            leftIcon={isObjectType ? "office-building" : "clipboard-list"}
          />
        </Column>
        <Column>
          <TableCell
            value={identifier}
            leftIcon="phone"
            iconSize={18}
            iconColor="Grey-300"
            className={styles.shortDateCell}
          />
        </Column>
        <Column>
          {isObjectType ? (
            <TableCell
              value={
                <Badge
                  variant={subscription ? "success" : "error"}
                  title={t(
                    `Administrator:${subscription ? "active" : "inactive"}`
                  )}
                  withHighlightedText
                />
              }
            />
          ) : (
            <MissingValueTableCell
              leftIcon="clock"
              iconColor="Grey-300"
              iconSize={16}
              value={
                lastCommunicationTest &&
                formattedDateString(new Date(lastCommunicationTest))
              }
              missingValueText={t("Transmitters:no_last_communication")}
            />
          )}
        </Column>
        {!hideRemoveBtn && (
          <Column>
            <Button
              className={styles.removeBtn}
              size="small"
              image="x"
              variant="secondary"
              onClick={onClick}
            />
          </Column>
        )}
      </Row>
    </>
  );
};
