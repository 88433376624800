import { useEffect, useRef, useState } from "react";
import useTranslations from "../../../../core/i18n/useTranslations";
import Accordion from "../../../../ui-lib/components/Accordion/Accordion";
import { getOrganizationsIPList } from "../../../../core/api/organizations/organizations";
import useUser from "../../../../core/user/useUser";
import { notifyApiErrors } from "../../../../core/helpers/helpers";
import LoadingSpinner from "../../../../ui-lib/components/Loading/LoadingSpinner";
import {
  IPFilter,
  IpWhiteList,
} from "../../../../core/api/organizations/types";
import { Row, Spacer } from "../../../../components/Layout/Layout";
import Button from "../../../../ui-lib/components/Button/Button";
import Table from "../../../../ui-lib/components/Tables/Table";
import TableCell from "../../../../ui-lib/components/Tables/TableCell";
import Checkbox from "../../../../ui-lib/components/Checkbox/Checkbox";
import TableRowSelectionPopup, {
  TableRowSelectionPopupActions,
} from "../../../../ui-lib/components/Tables/TableRowSelectionPopup";
import { DeleteIPModal } from "./DeleteIPModal";
import { CreateEditIPModal } from "./CreateEditIPModal";

export const IPWhiteList = ({ orgId }: { orgId: string }) => {
  const t = useTranslations();
  const { authenticatedRequest } = useUser();

  const [isLoading, setIsLoading] = useState(false);
  const [filtersData, setFiltersData] = useState<IpWhiteList | undefined>();
  const [toEdit, setToEdit] = useState<IPFilter | undefined>();
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [toDelete, setToDelete] = useState<IPFilter[]>([]);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const selectionPopupRef =
    useRef<TableRowSelectionPopupActions<IPFilter>>(null);

  const fetchIPs = async () => {
    try {
      setIsLoading(true);
      const results = await getOrganizationsIPList(orgId, authenticatedRequest);
      setFiltersData(results.data);
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchIPs();
  }, []);

  const closeCreateEditModal = (reset = false) => {
    setIsEditModalVisible(false);
    setToEdit(undefined);
    if (reset) {
      fetchIPs();
    }
  };

  return (
    <>
      <CreateEditIPModal
        defaultValues={toEdit}
        parentId={orgId}
        onClose={closeCreateEditModal}
        isOpen={isEditModalVisible}
      />
      <TableRowSelectionPopup
        ref={selectionPopupRef}
        renderBody={(selectedItems) => (
          <Row>
            <Spacer size={16} />
            <Button
              variant="destructive"
              text={`${t("Common:delete_selection")} (${selectedItems.length})`}
              onClick={() => {
                setToDelete(selectedItems);
                setIsDeleteModalVisible(true);
              }}
            />
          </Row>
        )}
      />
      <DeleteIPModal
        isOpen={isDeleteModalVisible}
        filters={toDelete}
        onClose={() => {
          setIsDeleteModalVisible(false);
        }}
        onDelete={() => {
          setIsDeleteModalVisible(false);
          selectionPopupRef.current?.close(true);
          setToDelete([]);
          fetchIPs();
        }}
      />
      <Accordion
        title={t("AlarmReceptions:technical_settings_whitelist_title")}
        description={t("Organizations:ip_description")}
      >
        {isLoading ? (
          <LoadingSpinner theme="primary" />
        ) : (
          <>
            <Row type="space">
              <h2>
                {t("Organizations:current_ip")}{" "}
                <span style={{ color: "var(--Primary-700)" }}>
                  {filtersData?.currentIP || ""}
                </span>
              </h2>

              <Button
                variant="secondary"
                text={t("AlarmReceptions:add_new_ipfilter")}
                onClick={() => {
                  setIsEditModalVisible(true);
                }}
              />
            </Row>

            <Spacer size={18} />

            <Table<IPFilter>
              columns={[
                {
                  header: t("AlarmReceptions:edit_ipfilter_label_startip"),
                  fieldTemplate: (rowData) => (
                    <TableCell value={rowData.startIP} />
                  ),
                },
                {
                  header: t(
                    "AlarmReceptions:advanced_settings_ipfilters_endip"
                  ),
                  fieldTemplate: (rowData) => (
                    <TableCell value={rowData.endIP} />
                  ),
                },
                {
                  header: t("Common:belongs_to"),
                  fieldTemplate: (rowData) => (
                    <TableCell value={rowData.belongsTo} />
                  ),
                },
                {
                  header: t(
                    "AlarmReceptions:advanced_settings_addintegration_comment_label"
                  ),
                  fieldTemplate: (rowData) => (
                    <TableCell value={rowData.comment} />
                  ),
                },
                {
                  header: t("Common:admin"),
                  fieldTemplate: (rowData) => (
                    <Checkbox disabled checked={rowData.adminApi} />
                  ),
                },
              ]}
              items={filtersData?.filters}
              hideEmptyMessage
              noRowsMessage={t("Table:no_records_found")}
              showRowHover
              withRowSelection
              withShowMore
              rowActions={[
                {
                  text: t("Objects:edit_details"),
                  icon: "pencil-alt",
                  onClick: (rowData) => {
                    setToEdit(rowData);
                    setIsEditModalVisible(true);
                  },
                },
                {
                  text: t("Objects:remove_from"),
                  icon: "trash",
                  iconVariant: "secondary",
                  onClick: (rowData) => {
                    setToDelete([rowData]);
                    setIsDeleteModalVisible(true);
                  },
                },
              ]}
              onRowSelectionChange={(selectedItems) => {
                const filtered = selectedItems.filter(
                  (i) => !!filtersData?.filters.find((row) => row.id === i.id)
                );
                selectionPopupRef.current?.open(filtered);
              }}
            />
          </>
        )}
      </Accordion>
    </>
  );
};
