import { observer } from "mobx-react";

import { notify } from "../../../ui-lib/components/Alerts/Toast";
import useTranslations from "../../../core/i18n/useTranslations";
import ObjectSubscriptionForm from "./ObjectSubscriptionForm";
import {
  ObjectActivationData,
  ObjectDetailsModel,
} from "../../../core/api/objects/types";
import { notifyApiErrors } from "../../../core/helpers/helpers";
import { useEffect, useState } from "react";
import {
  OrganisationSubscription,
  getOrganisationSubscriptions,
} from "../../../core/api/subscriptions";
import useUser from "../../../core/user/useUser";

const ObjectSubscriptionDetails = ({
  subscriptionData,
  objectId,
  fetchObjectSubscription,
  subscriptionUpdateInProgress,
  objectData,
}: {
  subscriptionData: ObjectActivationData;
  objectId: string;
  fetchObjectSubscription: () => Promise<void>;
  subscriptionUpdateInProgress: any;
  objectData: {
    data: ObjectDetailsModel;
    update: (objectDetails: ObjectDetailsModel) => Promise<void>;
  };
}) => {
  const { authenticatedRequest } = useUser();
  const t = useTranslations();

  const [arcs, setArcs] = useState<OrganisationSubscription[]>([]);

  const onSubmit = async (values: ObjectDetailsModel) => {
    try {
      await objectData.update(values);

      notify({
        message: t("Objects:edited_success"),
      });
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
      throw error;
    }
  };

  const fetchARCs = async () => {
    try {
      const response = await getOrganisationSubscriptions(
        objectData.data.organizationId,
        authenticatedRequest
      );
      setArcs(response);
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    }
  };

  useEffect(() => {
    if (!objectData.data.responseCenterName) {
      fetchARCs();
    }
  }, [objectData.data.responseCenterId]);

  return (
    <ObjectSubscriptionForm
      objectData={objectData.data}
      subscriptionData={subscriptionData}
      objectId={objectId}
      fetchObjectSubscription={fetchObjectSubscription}
      onSubmit={onSubmit}
      subscriptionUpdateInProgress={subscriptionUpdateInProgress}
      ARCs={arcs}
    />
  );
};

export default observer(ObjectSubscriptionDetails);
