import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import useTranslations from "../../../core/i18n/useTranslations";
import useUser from "../../../core/user/useUser";
import styles from "./Impersonation.module.css";
import Icon from "../../../ui-lib/components/Icon";
import Button from "../../../ui-lib/components/Button/Button";
import { Spacer } from "../../Layout/Layout";
import { unImpersonate } from "../../../core/api/administrators/administrators";
import { notifyApiErrors } from "../../../core/helpers/helpers";
import { boldTextInserter } from "../../../ui-lib/utils/boldTextInserter";
import { IMPERSONATE_LOCATION } from "../../../pages/Administrators/AdministratorDetails/ImpersonateModal";

export const ImpersonationHeader = () => {
  const t = useTranslations();
  const user = useUser();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const unImpersonateMe = async () => {
    try {
      setIsLoading(true);

      const result = await unImpersonate(user.authenticatedRequest);

      user.impersonatedFrom = undefined;

      await user.logIn({
        token: result.data.access_token,
        refreshToken: result.data.refresh_token,
        expiresIn: result.data.expires_in,
      });
      const placeToReturn = localStorage.getItem(IMPERSONATE_LOCATION);
      localStorage.setItem(IMPERSONATE_LOCATION, "");
      history.replace(placeToReturn!);
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.wrapper}>
      <Icon
        name="exclamation-circle"
        size={24}
        className={`mr-12 ${styles.alertIcon}`}
      />
      <p className={styles.text}>
        {boldTextInserter(t("Administrator:impersonation_header"), [
          user.data?.name!,
        ])}
      </p>
      <Spacer size={8} />
      <Button
        onClick={unImpersonateMe}
        text={t("Administrator:exit_impersonate_mode")}
        variant="secondary"
        disabled={isLoading}
        loading={isLoading}
        className={styles.button}
      />
    </div>
  );
};
