/* eslint-disable no-shadow */
import { BaseTableDataParams } from "../../core/api/common_table";
import { PeriodicReportFrequency } from "../../core/api/reports/types";
import { IDropdownItem } from "../../ui-lib/components/Dropdown/DropdownItem";
import { TimeSpan } from "../DateFilter/types";

// Export types
export interface ExportTypesSettings {
  description: string;
  items: Array<IDropdownItem>;
  onSelectItem: (item: IDropdownItem) => void;
}

// Periodic report
export enum PeriodicReportMode {
  ExportNow,
  Periodic,
}

export interface PeriodicReportOptions {
  mode: PeriodicReportMode;
  frequency?: PeriodicReportFrequency;
  email?: string;
  languange?: string;
  comment?: string;
  dontSendEmptyReports: boolean;
}

interface PeriodicReportSettings {
  options: PeriodicReportOptions;
  setOptions: (options: PeriodicReportOptions) => void;
}

// Generic
export interface Columns {
  [key: string]: { [key: string]: string } | string;
}

export interface ExportProps {
  labels: {
    title: string;
    description: string;
    submit?: string;
  };
  tableParams?: BaseTableDataParams;
  settings: {
    columnInfo?: Columns;
    supportDateRange: boolean;
    exportTypes?: ExportTypesSettings;
    periodicReports?: PeriodicReportSettings;
  };
  state?: {
    loading?: boolean;
    validationErrors?: {
      [key: string]: string;
    };
  };
  onClose: () => void;
  onExport: (args: { timeSpan?: TimeSpan }) => void;
  isLoading?: boolean;
  dataCy?: string;
}
