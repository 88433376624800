import useTranslations from "../../../core/i18n/useTranslations";
import BulkDeleteModal from "../../../components/BulkDeleteModal";
import { editAlarmReceptionIPWhiteList } from "../../../core/api/responsecenters/responsecenters";
import { IPFilter, IpWhiteList } from "../../../core/api/organizations/types";

export const DeleteIPWhiteListModal = ({
  isOpen,
  itemsToDelete,
  onClose,
  onDelete,
  objectId,
  ipWhiteList,
}: {
  isOpen: boolean;
  itemsToDelete: IPFilter[];
  onClose: () => void;
  onDelete: (success: boolean) => void;
  objectId: number;
  ipWhiteList: IpWhiteList;
}) => {
  const t = useTranslations();
  const labelToUse =
    itemsToDelete.length > 1
      ? t("AlarmReceptions:alarm_ip_list_type_multi")
      : t("AlarmReceptions:alarm_ip_list_type");

  return (
    <BulkDeleteModal
      isOpen={isOpen}
      onClose={onClose}
      onDelete={onDelete}
      // need this to be able to call an update function once as we don't send the delete request
      // but the put request with data that must be saved
      ids={["0"]}
      labels={{
        single: labelToUse,
        multi: labelToUse,
      }}
      apiFunc={(rowId, authReq) => {
        const updatedIPsList = ipWhiteList;
        itemsToDelete.forEach((toDelete) => {
          const indexInArr = updatedIPsList.filters?.findIndex(
            (row) =>
              row.startIP === toDelete.startIP && row.endIP === toDelete.endIP
          );

          if (indexInArr === -1) {
            return;
          }

          // @ts-ignore
          updatedIPsList.filters?.splice(indexInArr, 1);
        });
        return editAlarmReceptionIPWhiteList(objectId, updatedIPsList, authReq);
      }}
    />
  );
};
