import { AxiosInstance, AxiosResponse } from "axios";
import { useTranslation } from "react-i18next";
import { IDeleteObjectRef } from "./AlarmSubscriptionDetails";
import Modal from "../../../../../ui-lib/components/Modal/Modal";
import { Spacer } from "../../../../../components/Layout/Layout";

const ARCSubscriptionDeleteModal = ({
  isOpen,
  deleteData,
  isLoading,
  setDeleteModalOpen,
  loadData,
  removeHandler,
}: {
  isOpen: boolean;
  deleteData: React.MutableRefObject<IDeleteObjectRef | null>;
  isLoading: boolean;
  setDeleteModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  loadData: () => Promise<void>;
  removeHandler: (
    apiFunc: (
      authenticatedRequest: AxiosInstance,
      rcId: number,
      subscriptionId: number,
      removeId: number
    ) => Promise<AxiosResponse<any>>,
    id: number
  ) => Promise<void>;
}) => {
  const { t } = useTranslation();
  const { apiFunc, id, type } = deleteData.current!;

  return (
    <Modal
      closeOnDocumentClick={false}
      modalTitle={t("AlarmReceptions:delete_from_subscriptions").replace(
        "{type}",
        t(`Common:${type}`).toLocaleLowerCase()
      )}
      isOpen={isOpen}
      isLoading={isLoading}
      onClose={async () => {
        await loadData();
        setDeleteModalOpen(false);
      }}
      deleteBtnText={`${t("Common:delete")} ${t(
        `Common:${type}`
      ).toLocaleLowerCase()}`}
      onDeleBtnClick={async () => {
        await removeHandler(apiFunc, id);
        setDeleteModalOpen(false);
      }}
      cancelBtnText="Cancel"
      onCancelBtnClick={() => setDeleteModalOpen(false)}
      css={{ minWidth: "auto", maxWidth: "576px" }}
    >
      <Spacer size={16} />
      <p>
        {t("Common:delete_modal_confirm").replace(
          "{type}",
          t(`Common:${type}`).toLocaleLowerCase()
        )}
      </p>
    </Modal>
  );
};

export default ARCSubscriptionDeleteModal;
