import { useState } from "react";
import { deleteTransmitterInStock } from "../../core/api/transmitters/transmitters";
import { notifyApiErrors } from "../../core/helpers/helpers";
import useTranslations from "../../core/i18n/useTranslations";
import useUser from "../../core/user/useUser";
import { notify } from "../../ui-lib/components/Alerts/Toast";
import PrimeModal from "../../ui-lib/components/PrimeModal/PrimeModal";

const DeleteTransmitterModal = ({
  onClose,
  onDelete,
  name,
  modalTitle,
  text,
  transmitterIds,
  isOpen,
}: {
  onClose?: () => void;
  onDelete?: (status: boolean) => void;
  name: string;
  modalTitle: string;
  text: string;
  transmitterIds: string[];
  isOpen: boolean;
}) => {
  const t = useTranslations();
  const { authenticatedRequest } = useUser();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <PrimeModal
      withFooter
      withHeader
      onClose={() => {
        onClose?.();
      }}
      isOpen={isOpen}
      header={modalTitle}
      contentStyle={{ maxWidth: 576 }}
      loading={isLoading}
      submitBtn={{
        text:
          transmitterIds.length > 1
            ? t("Transmitters:button_delete_transmitters")
            : t("Transmitters:button_delete_transmitter"),
        variant: "destructive",
        onClick: async () => {
          let success = true;
          setIsLoading(true);
          for (const id of transmitterIds) {
            try {
              await deleteTransmitterInStock(id, authenticatedRequest);
            } catch (error: any) {
              success = false;
              notifyApiErrors(
                error.response?.data?.errors,
                error.response?.status
              );
            }
          }
          if (success) {
            notify({
              message: t("Transmitters:delete_transmitter_success"),
            });
          }
          onDelete?.(success);

          setIsLoading(false);
        },
      }}
      cancelBtn={{ text: t("Common:cancel"), onClick: () => onClose?.() }}
    >
      <p style={{ color: "var(--Grey-600)" }}>
        {text.replace("{transmitter}", name)}
      </p>
    </PrimeModal>
  );
};

export default DeleteTransmitterModal;
