import { useEffect, useRef, useState } from "react";
import { AxiosResponse } from "axios";
import {
  IIdentity,
  IIdentityRole,
} from "../../../../core/api/identities/types";
import useTranslations from "../../../../core/i18n/useTranslations";
import { Row, Spacer } from "../../../../components/Layout/Layout";
import { RowAction } from "../../../../ui-lib/components/Tables/Table";
import TableCell from "../../../../ui-lib/components/Tables/TableCell";
import { IdentityPermissionCell } from "../../../Objects/ObjectResponse/CustomTableCell/CustomTableCell";
import { EditRoleTemplate } from "../../RoleTemplates/EditRoleTemplate";
import { useOrganizationsTree } from "../../../../core/api/organizations/organizations";
import { notify } from "../../../../ui-lib/components/Alerts/Toast";
import {
  getTransmitters,
  getUserSuggest,
  useDateTimeFormats,
  useTimezones,
} from "../../../../core/api/administrators/administrators";
import LoadingSpinner from "../../../../ui-lib/components/Loading/LoadingSpinner";
import { AddAlarmTransmitter } from "../../../../components/Transmitters/AddAlarmTransmitter";
import {
  AddObject,
  ConfigurationAddObjectActions,
} from "../../../Objects/AddObject";
import Icon from "../../../../ui-lib/components/Icon";
import PopupContextMenu from "../../../../ui-lib/components/Menus/PopupContextMenu/PopupContextMenu";
import { PopupContextMenuLinkButton } from "../../../../ui-lib/components/Menus/PopupContextMenu/PopupContextMenuLinkButton";
import { RelatedItem } from "./RelatedItem";
import { notifyError } from "../../../../components/Errors/ErrorAlert";
import useUser from "../../../../core/user/useUser";
import { Transmitter } from "../../../../core/api/transmitters/types";
import { deleteObject, getObject } from "../../../../core/api/objects/objects";
import {
  NewObjectData,
  ObjectDetailsModel,
} from "../../../../core/api/objects/types";
import { IUserSuggest } from "../../../../core/api/administrators/types";
import BulkDeleteModal from "../../../../components/BulkDeleteModal";
import { removeTransmitterFromAdmin } from "../../../../core/api/transmitters/transmitters";
import { notifyApiErrors } from "../../../../core/helpers/helpers";
import styles from "./RolesPermissions.module.css";

interface IRoleAndSuggest {
  suggest: IUserSuggest;
  roleType: string;
  roleId: string | number;
  object?: ObjectDetailsModel;
}

export const RolesPermissions = ({ data }: { data: IIdentity }) => {
  const { data: organisationTree = [] } = useOrganizationsTree();
  const t = useTranslations();
  const { authenticatedRequest } = useUser();

  const addObjectModalRef = useRef<ConfigurationAddObjectActions>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isTemplateModalOpen, setIsTemplateModalOpen] = useState(false);
  const [deletionModalOpen, setDeletionModalOpen] = useState<boolean>(false);
  const [selectedTemplate, setSelectedTemplate] = useState<number>();
  const [isAddTransmitterOpen, setIsAddTransmitterOpen] = useState(false);
  const [objectData, setObjectData] = useState<ObjectDetailsModel[]>();
  const [transmitterData, setTransmitterData] = useState<Transmitter[]>();
  const [extendedSuggest, setExtendedSuggest] = useState<IRoleAndSuggest[]>([]);

  const { data: timezones, isLoading: isTimezonesLoading } = useTimezones();
  const { data: dateTimeFormats, isLoading: isDateFormatsLoading } =
    useDateTimeFormats();

  // This component is to big!!
  // Need data composition on the backend side to avoid a lot of cycles on the frontend side
  const fetchRoleSuggest = async () => {
    setIsLoading(true);
    // collection of role suggest promises
    const suggestPromises = data.roles.map((role) =>
      getUserSuggest(role.id, authenticatedRequest)
    );

    // collection of object promises
    const objectPromises: Promise<AxiosResponse<ObjectDetailsModel>>[] = [];
    // collection of transmitter promises
    const transmitterPromises: Promise<AxiosResponse<Transmitter[]>>[] = [];
    // since data from suggest or role not enough we need this mixed data
    const mixedData: IRoleAndSuggest[] = [];

    try {
      // receive and set all suggests
      await Promise.all(suggestPromises)
        .then((res) => {
          res.forEach((item, index) => {
            mixedData.push({
              suggest: item.data,
              roleType: data.roles[index].type,
              roleId: data.roles[index].id,
            });
          });
        })
        .then(async () => {
          // get availabel object promises
          mixedData.forEach((item, index) => {
            if (!item.suggest?.canCreateUser && item.suggest?.existingUser) {
              objectPromises.push(
                getObject(item.suggest.existingUser, authenticatedRequest)
              );
            }
            // get availabel transmitter promises
            if (item.suggest.transmitterId) {
              transmitterPromises.push(
                getTransmitters(data.roles[index].id, authenticatedRequest)
              );
            }
          });
          // get all objects
          if (objectPromises.length) {
            await Promise.all(objectPromises).then((res) => {
              const allObjects = res.map((item) => item.data);
              setObjectData(allObjects);
            });
          } else {
            setObjectData(undefined);
          }
          // get all transmitters
          if (transmitterPromises.length) {
            await Promise.all(transmitterPromises).then((res) => {
              const allTransmitters = res.map(
                (item) => !!item.data[0] && item.data[0]
              );
              setTransmitterData(allTransmitters);
            });
          } else {
            setTransmitterData(undefined);
          }
        });
      setExtendedSuggest(mixedData);
    } catch (error: any) {
      notifyError({ error, t });
    } finally {
      setIsLoading(false);
    }
  };

  const removeTransmitter = async (roleId: number, transmitterId: number) => {
    setIsLoading(true);
    try {
      await removeTransmitterFromAdmin(
        roleId,
        transmitterId,
        authenticatedRequest
      ).then(() => fetchRoleSuggest());
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchRoleSuggest();
  }, []);

  const roleRef = useRef<IIdentityRole | undefined>();
  const suggestRef = useRef<IUserSuggest | undefined>();
  const organisationIdRef = useRef<number | undefined>();

  const actions: RowAction<any>[] = [
    {
      text: t("Administrator:view_permissions"),
      icon: "search",
      iconVariant: "secondary",
      iconSize: 16,
      onClick: (role: IIdentityRole) => {
        if (role.templateId) {
          setSelectedTemplate(role.templateId);
          setIsTemplateModalOpen(true);
        } else {
          notify({
            variant: "error",
            message: t("Administrator:template_missing"),
          });
        }
      },
    },
    {
      text: t("Administrator:add_related_object"),
      icon: "plus",
      iconVariant: "secondary",
      iconSize: 16,
      onClick: (role, i, currentSuggest) => {
        const currentTransmitter = transmitterData?.find(
          (transmitter) => currentSuggest?.transmitterId === transmitter.id
        );
        const defaultValues = {
          name: data.firstName ? `${data.firstName} ${data.lastName}` : null,
          telephoneNumber: data.telephoneNumber,
          identifier: currentTransmitter?.identifier,
          responseCenterId: roleRef.current?.responseCenterId,
          adminId: roleRef.current?.id,
          productCategoryId: suggestRef?.current?.transmitterProductType,
          organizationId:
            suggestRef?.current?.subOrganizationId ??
            suggestRef?.current?.organizationId,
          nextSubscriptionId: suggestRef?.current?.subscriptionId,
        };
        addObjectModalRef.current?.open(
          currentTransmitter,
          defaultValues as NewObjectData
        );
      },
      hideForRow(identityData: IRoleAndSuggest) {
        if (identityData) {
          return !identityData.suggest.canCreateUser;
        }
        return false;
      },
    },
    {
      text: t("Administrator:add_positioning_transmitter"),
      icon: "plus",
      iconVariant: "secondary",
      iconSize: 16,
      onClick: () => {
        setIsAddTransmitterOpen(true);
      },
      hideForRow(identityData: IRoleAndSuggest) {
        if (identityData) {
          return (
            identityData.roleType === "admin" ||
            !!identityData.suggest.transmitterId
          );
        }
        return false;
      },
    },
  ];

  if (isTimezonesLoading || isDateFormatsLoading || isLoading) {
    return <LoadingSpinner theme="primary" />;
  }

  return (
    <>
      <AddAlarmTransmitter
        adminPositioningTransmitter
        isAddTransmitterOpen={isAddTransmitterOpen}
        setIsAddTransmitterOpen={setIsAddTransmitterOpen}
        adminId={roleRef.current?.id}
        onSubmit={() => {
          fetchRoleSuggest();
        }}
      />
      <BulkDeleteModal
        isOpen={deletionModalOpen}
        onClose={() => {
          setDeletionModalOpen(false);
        }}
        onDelete={() => {
          setDeletionModalOpen(false);
          fetchRoleSuggest();
        }}
        ids={
          organisationIdRef.current
            ? [organisationIdRef.current.toString()]
            : []
        }
        labels={{
          single: t("Objects:type"),
          multi: t("Objects:type_multi"),
        }}
        apiFunc={deleteObject}
      />
      <AddObject
        relatedObject
        ref={addObjectModalRef}
        organisations={organisationTree}
        onSubmit={() => {
          fetchRoleSuggest();
        }}
      />
      {!!selectedTemplate && (
        <EditRoleTemplate
          roleTemplateId={selectedTemplate}
          organisations={organisationTree}
          onClose={() => {
            setSelectedTemplate(undefined);
            setIsTemplateModalOpen(false);
          }}
          onSubmit={() => {}}
          isEditTemplateOpen={isTemplateModalOpen}
          setIsEditTemplateOpen={setIsTemplateModalOpen}
          editable={false}
          timezones={timezones}
          dateTimeFormats={dateTimeFormats}
        />
      )}
      <h1>{t("Menu:Roles_Permissions")}</h1>
      <Spacer size={8} />
      <p style={{ color: "var(--Grey-600)" }}>
        {t("Administrator:identity_permissions_description")}
      </p>

      <Spacer size={32} />

      <div className={styles.roleWrapper}>
        {data.roles.map((role, index) => (
          <>
            <Row key={role.id} className={styles.relatedItemWrapper}>
              <TableCell
                leftIcon={
                  role.type === "respondent" ? "phone-incoming" : "user"
                }
                iconColor="Grey-300"
                value={role.templateName}
              />
              <div style={{ width: "100%", marginInline: "5px" }}>
                <IdentityPermissionCell
                  value={
                    role.type === "respondent"
                      ? role.responseCenter
                      : role.organization
                  }
                  isRespondent={role.type === "respondent"}
                />
              </div>
              <div style={{ paddingLeft: "5px" }}>
                <PopupContextMenu
                  className="autoWidth"
                  position="bottom left"
                  onClose={() => {
                    roleRef.current = undefined;
                    suggestRef.current = undefined;
                  }}
                  trigger={() => (
                    <span
                      role="button"
                      onKeyUp={() => {}}
                      className={styles.contextBtn}
                    >
                      <Icon name="dots-horizontal" size={24} color="Grey-400" />
                    </span>
                  )}
                >
                  {actions.map((action) => {
                    if (action.hideForRow?.(extendedSuggest[index])) {
                      return null;
                    }
                    return (
                      <PopupContextMenuLinkButton
                        key={action.text as string}
                        icon={action.icon as string}
                        text={action.text || ""}
                        onClick={() => {
                          roleRef.current = role;
                          suggestRef.current = extendedSuggest[index].suggest;
                          if (action.onClick) {
                            action.onClick(role, index, suggestRef.current);
                          }
                        }}
                      />
                    );
                  })}
                </PopupContextMenu>
              </div>
            </Row>
            {objectData?.map((object: ObjectDetailsModel) => {
              if (Number(object.adminId) === role.id) {
                return (
                  <RelatedItem
                    key={object?.adminId}
                    itemId={object?.id}
                    relationId={object?.organizationId}
                    itemType="object"
                    itemName={object?.name}
                    objectName={object?.organizationName}
                    identifier={object?.identifier}
                    subscription={object.active}
                    onClick={() => {
                      organisationIdRef.current =
                        extendedSuggest[index].suggest.existingUser;
                      setDeletionModalOpen(true);
                    }}
                  />
                );
              }
              return null;
            })}

            {transmitterData?.length &&
              transmitterData.map((transmitter: Transmitter) => {
                if (
                  Number(transmitter.id) ===
                  extendedSuggest[index].suggest.transmitterId
                ) {
                  return (
                    <RelatedItem
                      key={transmitter.id}
                      itemId={transmitter.id}
                      itemType="transmitter"
                      itemName={transmitter.productName}
                      objectName={transmitter.model}
                      identifier={transmitter.identifier}
                      lastCommunicationTest={transmitter.lastCommunicationTest}
                      hideRemoveBtn={
                        !!extendedSuggest[index].suggest.existingUser
                      }
                      onClick={() => {
                        removeTransmitter(role.id, transmitter.id);
                      }}
                    />
                  );
                }
                return null;
              })}
          </>
        ))}
      </div>
    </>
  );
};
