import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { getErrorKey } from "../../../components/Errors/ErrorAlert";
import { Column, Row, Spacer } from "../../../components/Layout/Layout";
import { Role } from "../../../components/RoleFeature";
import { getInteractions } from "../../../core/api/interactions/interactions";
import { PeriodicInteractionResponse } from "../../../core/api/interactions/types";
import { UserAdminResponse } from "../../../core/api/useradmins/types";
import { getUserAdmins } from "../../../core/api/useradmins/useradmins";
import useTranslations from "../../../core/i18n/useTranslations";
import useUser from "../../../core/user/useUser";
import { notify } from "../../../ui-lib/components/Alerts/Toast";
import Button from "../../../ui-lib/components/Button/Button";
import Divider from "../../../ui-lib/components/Divider/Divider";
import LoadingSpinner from "../../../ui-lib/components/Loading/LoadingSpinner";
import StyledModal from "../../../ui-lib/components/Modal/Modal";
import CreateInteractionModal from "./Interactions/CreateInteractionModal";
import { InteractionTable } from "./Interactions/InteractionTable";
import AddRelatedAdminModal from "./RelatedAdmins/AddRelatedAdminModal";
import { RelatedAdminTable } from "./RelatedAdmins/RelatedAdminTable";
import { actionTypeOptions } from "./Interactions/helpers";
import ObjectResponseDetails from "../ObjectResponse/ObjectResponseDetails";

const ObjectAdvanced = ({
  objectId,
  interactions,
  onInteractionUpdate,
  userAdmins,
  onAdminUpdate,
  settings,
}: {
  objectId: string;
  interactions: PeriodicInteractionResponse[];
  onInteractionUpdate: () => void;
  userAdmins: UserAdminResponse[];
  onAdminUpdate: () => void;
  settings: {
    showInteractions: boolean;
    showAdmins: boolean;
  };
}) => {
  const t = useTranslations();
  const [interactionModalOpen, setInteractionModalOpen] = useState(false);
  const [respondentRelationModalOpen, setRespondentRelationModalOpen] =
    useState(false);

  return (
    <>
      <StyledModal
        hideFooter
        width="848px"
        closeOnDocumentClick={false}
        isOpen={interactionModalOpen}
        onClose={() => setInteractionModalOpen(false)}
        modalTitle={t("Objects:interaction_create")}
      >
        <CreateInteractionModal
          objectId={objectId}
          onClose={() => setInteractionModalOpen(false)}
          onAdd={onInteractionUpdate}
        />
      </StyledModal>
      <Column style={{ width: "784px" }} type="top" align="start">
        <h1>{t("Objects:advanced_settings")}</h1>
        <Spacer size={8} />
        <p style={{ color: "var(--Grey-600)" }}>
          {t("Objects:advanced_settings_description")}
        </p>
        <Spacer size={36} />
        {settings.showInteractions && (
          <>
            <Row style={{ width: "100%", flex: 0 }}>
              <h2>{t("Objects:periodic_interactions")}</h2>
              <Spacer />
              <Button
                type="button"
                variant="secondary"
                image="calendar"
                text={t("Objects:periodic_interactions_add")}
                onClick={() => setInteractionModalOpen(true)}
              />
            </Row>
            <Spacer size={16} />
            <InteractionTable
              objectId={objectId}
              interactions={interactions}
              onInteractionUpdate={onInteractionUpdate}
            />
            <Divider />
          </>
        )}
        {settings.showAdmins && (
          <>
            <Spacer size={32} />
            <Row style={{ width: "100%", flex: 0 }}>
              <h2>{t("Objects:related_admins")}</h2>
              <Spacer />
              <Button
                type="button"
                variant="secondary"
                image="users"
                text={t("Objects:add_related_admin")}
                onClick={() => setRespondentRelationModalOpen(true)}
              />
              <AddRelatedAdminModal
                isOpen={respondentRelationModalOpen}
                onClose={() => setRespondentRelationModalOpen(false)}
                objectId={objectId}
                added={userAdmins.map((ua) => ua.adminId)}
                onAdd={() => {
                  onAdminUpdate();
                  setRespondentRelationModalOpen(false);
                }}
              />
            </Row>
            <Spacer size={16} />
            <RelatedAdminTable
              userAdmins={userAdmins}
              onAdminUpdate={onAdminUpdate}
            />
          </>
        )}

        <Spacer size={32} />
        <h2>{t("Objects:response_details")}</h2>
        <p style={{ color: "var(--Grey-600)" }}>
          {t("Objects:response_details_description")}
        </p>
        <Spacer size={16} />
        <ObjectResponseDetails />
      </Column>
    </>
  );
};

const ObjectAdvancedContainer = ({ objectId }: { objectId: string }) => {
  const { authenticatedRequest, config } = useUser();

  const t = useTranslations();
  const filteredInteractionsType = actionTypeOptions.filter(({ role }) =>
    config?.show.includes(role)
  );
  const settings = {
    showInteractions:
      !!config?.show.includes(Role.PeriodicInteractions) &&
      !!filteredInteractionsType.length,
    showAdmins: !!config?.show.includes(Role.UserAdminsAll),
  };

  const [interactions, setInteractions] =
    useState<PeriodicInteractionResponse[]>();
  const [userAdmins, setUserAdmins] = useState<UserAdminResponse[]>();

  const fetchInteractions = async () => {
    if (!settings.showInteractions) {
      setInteractions([]);
      return;
    }

    try {
      const { data } = await getInteractions(objectId, authenticatedRequest);

      setInteractions(data);
    } catch (error) {
      setInteractions([]);

      notify({
        message: t(`Errors:${getErrorKey(error)}`),
        variant: "error",
      });
    }
  };

  const fetchUserAdmins = async () => {
    if (!settings.showAdmins) {
      setUserAdmins([]);
      return;
    }

    try {
      const { data } = await getUserAdmins(objectId, authenticatedRequest);

      setUserAdmins(data);
    } catch (error) {
      setUserAdmins([]);

      notify({
        message: t(`Errors:${getErrorKey(error)}`),
        variant: "error",
      });
    }
  };

  useEffect(() => {
    fetchInteractions();
    fetchUserAdmins();
  }, []);

  if (!interactions || !userAdmins) {
    return <LoadingSpinner theme="primary" />;
  }

  return (
    <ObjectAdvanced
      objectId={objectId}
      interactions={interactions}
      onInteractionUpdate={fetchInteractions}
      userAdmins={userAdmins}
      onAdminUpdate={fetchUserAdmins}
      settings={settings}
    />
  );
};

export default observer(ObjectAdvancedContainer);
