export interface ReportResponse {
  id: number;
  reportName: string;
  timespan: boolean;
}

export interface PeriodicReportResponse {
  id: number;
  email: string;
  periodicity: PeriodicReportFrequency;
  lastSent?: Date;
  comment?: string;
  dontSendEmptyReports: boolean;
}

export enum PeriodicReportFrequency {
  Daily = 365,
  Monthly = 12,
  EveryMonday = 1,
  EveryTuesday = 2,
  EveryWednesday = 3,
  EveryThursday = 4,
  EveryFriday = 5,
  EverySaturday = 6,
  EverySunday = 7,
}

export interface TryGetReportParams {
  Language: string;
  OutputFormat: string;
  ReportArguments: string;
  ReportId: number;
}

export interface FileResult {
  fileData: string;
  fileName: string;
}
