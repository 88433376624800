import useTranslations from "../core/i18n/useTranslations";
import ToggleSwitch from "../ui-lib/components/Checkbox/ToggleSwitch";
import Icon from "../ui-lib/components/Icon";
import PrimeTooltip from "../ui-lib/components/PrimeTooltip/PrimeTooltip";
import { SearchFilter } from "./FilterSearch/TableSearch";
import { Spacer } from "./Layout/Layout";

export const ExactMatchTogller = ({
  searchFilter,
  setSearchFilter,
}: {
  searchFilter: SearchFilter;
  setSearchFilter: (args: any) => void;
}) => {
  const t = useTranslations();
  return (
    <>
      <ToggleSwitch
        noWrapLabel
        name="exactMatch"
        label={t("Filters:exact_match_search")}
        checked={searchFilter.exact === "true"}
        onChange={({ target }) =>
          setSearchFilter({
            ...searchFilter,
            exact: target.value.toString(),
          })
        }
      />
      <Spacer size={6} />
      <PrimeTooltip target="#exactMatch" />
      <span
        style={{ display: "flex", alignItems: "center" }}
        id="exactMatch"
        data-pr-tooltip={t("Filters:exact_match_description")}
        data-pr-my="right bottom"
      >
        <Icon name="information-circle" size={20} color="Grey-800" />
      </span>
      <Spacer size={16} />
    </>
  );
};
