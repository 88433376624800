import {
  toast,
  ToastContainer as ToastifyToastContainer,
} from "react-toastify";
import "./Toast.css";
import Alert from "./Alert";

interface NotifyArgs {
  message: string;
  variant?: "info" | "error";
  autoCloseTime?: number;
}

export const notify = ({
  message,
  variant = "info",
  autoCloseTime = 5000,
}: NotifyArgs) => {
  const icon = {
    info: "check-circle",
    error: "exclamation-circle",
  }[variant];

  toast(<Alert title={message} variant={variant} icon={icon} />, {
    autoClose: autoCloseTime,
  });
};

export const ToastContainer = () => (
  <ToastifyToastContainer
    position="bottom-center"
    hideProgressBar
    newestOnTop
    autoClose={5000}
    closeOnClick
    closeButton={false}
    rtl={false}
    pauseOnFocusLoss={false}
    draggable={false}
    pauseOnHover={false}
  />
);
