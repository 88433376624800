import { RefObject, useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { Chart } from "primereact/chart";
import { Row } from "../Layout/Layout";
import Divider from "../../ui-lib/components/Divider/Divider";
import styles from "./AlarmChart.module.css";
import Dropdown from "../../ui-lib/components/Dropdown/Dropdown";
import { DateRange } from "./DateRange";
import { useFilterTypes } from "./useFilterTypes";
import { useChartOptions } from "./useChartOptions";
import {
  FormattedDateRange,
  getAlarmChart,
  Histogram,
} from "../../core/api/alarms";
import { getXAxis, hexToRGB } from "../../core/helpers/helpers";
import useUser from "../../core/user/useUser";
import useTranslations from "../../core/i18n/useTranslations";
import {
  homeDataRetriever,
  homeDataSaver,
} from "../../ui-lib/utils/homeDataHelper";

interface ChartDataset {
  data: number[];
  dateRange: FormattedDateRange[];
  fill: boolean;
  tension: number;
  axisType: string;
  currentYear: number;
  borderColor?: string;
  backgroundColor?: string;
}

interface ChartData {
  labels: string[];
  datasets: ChartDataset[];
}

const AlarmChart = ({
  chartPanel,
}: {
  chartPanel: RefObject<HTMLDivElement>;
}) => {
  const user = useUser();
  const t = useTranslations();
  const filterTypes = useFilterTypes();
  const chartOptions = useChartOptions();

  const lastUsedChart = homeDataRetriever(user).alarmActivity;
  const selectedFilter = useRef<number>(lastUsedChart ?? 3);
  const xAxis = useRef<string[]>([]);
  const selectedRange = useRef<DateRange>(new DateRange());

  const [chartHeight, setChartHeight] = useState<string>();
  const [chartData, setChartData] = useState<ChartData>();
  const [chartLoading, setChartLoading] = useState(false);

  const filterTypeItems = filterTypes.map((filter) => ({
    id: filter.id,
    name: filter.name,
    isSelected: filter.id === selectedFilter.current,
  }));
  const { authenticatedRequest } = useUser();

  const chartMainColor = hexToRGB(
    getComputedStyle(document.body).getPropertyValue("--Primary-500")
  );

  const getAxisType = () => {
    if (selectedFilter.current === 3 || selectedFilter.current === 4) {
      return "day";
    }
    if (selectedFilter.current === 6) {
      return "month";
    }
    return "time";
  };

  const loadChart = () => {
    xAxis.current = getXAxis(selectedFilter.current);
    if (xAxis.current.length > 0) {
      setChartLoading(true);
      getAlarmChart(authenticatedRequest, selectedFilter.current)
        .then((alarmHistogram) => {
          const parameterRange = alarmHistogram.histogram.map(
            (h: Histogram) =>
              ({
                start: h.timestamp,
                end: moment(h.timestamp).add(1, "hours").toDate(),
              } as FormattedDateRange)
          );
          setChartData({
            labels: xAxis.current,
            datasets: [
              {
                data: alarmHistogram.histogram.map((h: Histogram) => h.count),
                dateRange: parameterRange,
                fill: true,
                tension: 0.4,
                axisType: getAxisType(),
                currentYear: selectedRange.current.endDate.getFullYear(),
                borderColor: `rgba(${chartMainColor!.r},${chartMainColor!.g},${
                  chartMainColor!.b
                },1)`,
                backgroundColor: `rgba(${chartMainColor!.r},${
                  chartMainColor!.g
                },${chartMainColor!.b},0.2)`,
              },
            ],
          });
        })
        .catch(() => {})
        .finally(() => {
          setChartLoading(false);
        });
    }
  };
  const debounce = (func: () => void, wait = 200) => {
    let h: any;
    return () => {
      clearTimeout(h);
      h = setTimeout(() => func(), wait);
    };
  };
  const updateSize = () => {
    const rect = chartPanel.current!.getBoundingClientRect();
    const chHeight = rect.bottom - rect.top - 200;
    setChartHeight(`${chHeight}px`);
  };
  const debounceResize = debounce(updateSize);
  useEffect(() => {
    if (chartPanel.current !== null) {
      window.addEventListener("resize", debounceResize);
      updateSize();
    }
    loadChart();
    return () => {
      window.removeEventListener("resize", debounceResize);
    };
  }, []);

  return (
    <>
      <Row type="fill" className={styles.filterRow}>
        <p className="subTitleSemiBold">{t("Home:chartTitle")}</p>
        <Dropdown
          placeholder={t("Filters:timePeriodFilter")}
          selectedItem={filterTypeItems.find((i) => i.isSelected)}
          width={250}
          onSelectItem={(item) => {
            selectedFilter.current = item.id as number;
            homeDataSaver(user, "alarmActivity", +item.id);
            const newRange = new DateRange();
            const currentFilter = filterTypes.find((f) => f.id === item.id);
            newRange.startDate = currentFilter?.getRangeStart(
              selectedRange.current.endDate
            );
            newRange.endDate = selectedRange.current.endDate;
            selectedRange.current = newRange;
            loadChart();
          }}
          items={filterTypeItems}
          disabled={chartLoading}
          isLoading={chartLoading}
        />
      </Row>
      <Divider />
      <Chart
        style={{
          marginTop: "8px",
          position: "relative",
          height: chartHeight,
        }}
        type="line"
        data={chartData}
        options={chartOptions}
      />
    </>
  );
};

export default observer(AlarmChart);
