import useTranslations from "../../core/i18n/useTranslations";
import useUser from "../../core/user/useUser";
import { notify } from "../../ui-lib/components/Alerts/Toast";
import ModalContent from "../../ui-lib/components/Popup/ModalContent";
import { Row, Spacer } from "../../components/Layout/Layout";
import Button from "../../ui-lib/components/Button/Button";
import { updateGuiConfig } from "../../core/user/token";
import { notifyApiErrors } from "../../core/helpers/helpers";
import { deleteAlternatives } from "../../core/api/alternatives/alternatives";

export const DeleteAlternativesModal = ({
  alternativeId,
  adminId,
  onClose,
  onDelete,
}: {
  alternativeId?: string | number;
  adminId: string | number;
  onClose: () => void;
  onDelete: () => void;
}) => {
  const t = useTranslations();
  const user = useUser();

  const onSubmit = async () => {
    if (!alternativeId) {
      return;
    }

    try {
      await deleteAlternatives(alternativeId, user.authenticatedRequest);
      notify({
        message: t("Messages:delete_alternative_success"),
      });
      if (user.accountId === adminId) {
        await updateGuiConfig(user);
      }
      onDelete();
      onClose();
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    }
  };

  return (
    <ModalContent onClose={onClose} width="576px">
      <h2 className="subTitle">{t("Administrator:delete_alternative")}</h2>
      <Spacer size={16} />
      <p style={{ color: "var(--Grey-600)" }}>
        {t("Administrator:delete_alternative_confirm")}
      </p>

      <Spacer size={32} />

      <Row type="left">
        <Button
          variant="destructive"
          text={t("Administrator:remove_alternative")}
          onClick={onSubmit}
        />
        <Spacer size={8} />
        <Button
          variant="secondary"
          text={t("Common:cancel")}
          onClick={onClose}
        />
      </Row>
    </ModalContent>
  );
};
