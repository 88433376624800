import { Controller, useFormContext } from "react-hook-form";
import { Column } from "../../../../../components/Layout/Layout";
import Checkbox from "../../../../../ui-lib/components/Checkbox/Checkbox";

export const CheckboxControl = ({
  name,
  label,
  description,
}: {
  name: string;
  label: string;
  description?: string;
}) => {
  const { control } = useFormContext();

  return (
    <Column align="start" style={{ width: "100%" }}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Checkbox
            label={label}
            checked={field.value}
            description={description}
            onChange={field.onChange}
            style={{ padding: 0 }}
          />
        )}
      />
    </Column>
  );
};
