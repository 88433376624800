import classnames from "classnames";
import { Spacer } from "../../../components/Layout/Layout";
import Icon from "../Icon";
import styles from "./ModalContent.module.css";

const ModalContent = ({
  width,
  onClose,
  children,
  headerContent,
  footerContent,
  fixedHeader = false,
}: {
  width?: string | number;
  onClose: () => void;
  children: React.ReactNode;
  headerContent?: React.ReactNode;
  footerContent?: React.ReactNode;
  fixedHeader?: boolean;
}) => (
  <div
    className={classnames({
      [styles.container]: !fixedHeader,
      [styles.containerFixed]: fixedHeader,
    })}
    style={{ width }}
  >
    {fixedHeader && (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "top",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
            justifyContent: "top",
            marginLeft: "32px",
            marginTop: "32px",
          }}
        >
          {headerContent || <Spacer size={0} />}
        </div>
        <div
          role="button"
          onClick={onClose}
          onKeyUp={onClose}
          className={styles.closeButtonFixed}
          aria-label="Close"
        >
          <Icon name="x" />
        </div>
      </div>
    )}
    {!fixedHeader && (
      <div
        role="button"
        aria-label="Close"
        onClick={onClose}
        onKeyUp={onClose}
        className={styles.closeButton}
      >
        <Icon name="x" />
      </div>
    )}
    <div
      className={classnames({
        [styles.content]: fixedHeader,
      })}
    >
      {children}
    </div>
    {footerContent && <div className={styles.footer}>{footerContent}</div>}
  </div>
);

export default ModalContent;
