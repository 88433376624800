import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { ScheduleItem } from "./ScheduleItem";
import useUser from "../../../core/user/useUser";
import { IScheduleItem } from "../../../core/api/contacts/types";
import {
  deleteScheduleItem,
  updateScheduleItem,
} from "../../../core/api/contacts/contacts";
import { notify } from "../Alerts/Toast";
import { notifyApiErrors } from "../../../core/helpers/helpers";
import useTranslations from "../../../core/i18n/useTranslations";
import LoadingSpinner from "../Loading/LoadingSpinner";

export const ScheduleItemController = ({
  defaultValues,
  availabilities,
  setAvailabilities,
  skipViewMode = false,
  onCreated,
  isException = false,
}: {
  defaultValues?: any;
  availabilities: IScheduleItem[];
  setAvailabilities: (availabilities: IScheduleItem[]) => void;
  skipViewMode?: boolean;
  onCreated?: () => void;
  isException?: boolean;
}) => {
  const t = useTranslations();
  const { id: contactId } = useParams<{ id: string }>();
  const { dateFormat, authenticatedRequest } = useUser();
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
    trigger,
    watch,
    reset,
    clearErrors,
  } = useForm<any>({
    defaultValues: {
      repeatDays: [0, 1, 2, 3, 4, 5, 6],
      repeatType: "RepeatDays",
      exceptionTime: isException,
      ...defaultValues,
    },
  });
  const schedulerRef = useRef<any>();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setValue("exceptionTime", isException);
  }, [isException]);

  const onSubmit = async (values: IScheduleItem) => {
    try {
      setIsLoading(true);
      if (defaultValues?.exceptionTime) {
        values.exceptionTime = defaultValues.exceptionTime;
      }
      if (
        values.repeatDays.length === 0 &&
        values.repeatType === "RepeatDays"
      ) {
        values.repeatType = "NoRepeat";
        values.endDate = undefined;
      }
      const result = await updateScheduleItem(
        +contactId,
        values,
        authenticatedRequest
      );
      notify({
        message: values.id
          ? t("Contacts:edit_schedule_success")
          : t("Contacts:create_schedule_success"),
      });
      // edit
      if (values.id) {
        const newAvailabilities = availabilities.map((item) =>
          item.id === values.id ? values : item
        );
        setAvailabilities(newAvailabilities);
      } else {
        // create
        setAvailabilities([...availabilities, { ...values, id: result.data }]);
        onCreated?.();
      }
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    } finally {
      setIsLoading(false);
    }
  };

  const onDelete = async (scheduleId: number) => {
    try {
      setIsLoading(true);
      await deleteScheduleItem(+contactId, scheduleId, authenticatedRequest);
      notify({ message: t("Contacts:delete_schedule_success") });
      const newAvailabilities = availabilities.filter(
        (item) => item.id !== scheduleId
      );
      setAvailabilities(newAvailabilities);
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    } finally {
      setIsLoading(false);
    }
  };

  return isLoading ? (
    <LoadingSpinner theme="primary" />
  ) : (
    <ScheduleItem
      ref={schedulerRef}
      dateFormat={dateFormat!}
      control={control}
      errors={errors}
      watch={watch}
      handleSubmit={handleSubmit}
      setValue={setValue}
      getValues={getValues}
      trigger={trigger}
      defaultValues={defaultValues}
      onSubmit={onSubmit}
      onDelete={onDelete}
      skipViewMode={skipViewMode}
      onItemRemove={onCreated}
      reset={reset}
      clearErrors={clearErrors}
    />
  );
};
