import styles from "./ListWithDot.module.css";
import { Icon } from "../Icon";

export interface ListWithDotProps {
  items: string[];
}
export default function ListWithDot({ items }: ListWithDotProps) {
  return (
    <div className={styles.list}>
      <div className={styles.markedList}>
        {items.map((item) => (
          <div className={styles.listItem} key={item}>
            <Icon name="alarm-classification" color="fillPrimary700" size={6} />

            <span className={styles.itemText}>{item}</span>
          </div>
        ))}
      </div>
    </div>
  );
}
