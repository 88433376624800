/* eslint-disable react/jsx-props-no-spreading */
import { FormProvider } from "react-hook-form";
import { AdministratorDetails } from "../../../../core/api/administrators/types";
import {
  useObjectForm,
  useObjectFormRef,
} from "../../../../core/SaveObjectContext/hooks";
import RespondentGroupList from "../../../../components/Administrator/RespondentGroupList";

export const RespondentGroupForm = ({
  defaultValues,
  onSubmit,
}: {
  defaultValues: AdministratorDetails;
  onSubmit: (values: AdministratorDetails) => Promise<void>;
}) => {
  const formRef = useObjectFormRef();
  const methods = useObjectForm<AdministratorDetails>({
    defaultValues,
    shouldUnregister: false,
  });
  const { control, setValue, handleSubmit, reset, watch } = methods;

  return (
    <FormProvider {...methods}>
      <form
        ref={formRef}
        onSubmit={handleSubmit(async (values) => {
          await onSubmit(values);
          reset(values);
        })}
        style={{ width: "100%" }}
      >
        <RespondentGroupList
          respondentGroups={defaultValues.respondentGroups || []}
          control={control}
          setValue={setValue}
          isLoading={false}
          watch={watch}
        />
      </form>
    </FormProvider>
  );
};
