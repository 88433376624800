import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import useUser from "../../../core/user/useUser";
import { getAddresses } from "../../../core/api/addresses/addresses";
import { AddressResponse } from "../../../core/api/addresses/types";
import {
  getUserContacts,
  reorderContactsPriotiry,
} from "../../../core/api/contacts/contacts";
import {
  ContactResponse,
  IContactsReorder,
} from "../../../core/api/contacts/types";

import { Column, Row, Spacer } from "../../../components/Layout/Layout";
import { getErrorKey } from "../../../components/Errors/ErrorAlert";

import Divider from "../../../ui-lib/components/Divider/Divider";
import LoadingSpinner from "../../../ui-lib/components/Loading/LoadingSpinner";
import Button from "../../../ui-lib/components/Button/Button";
import Modal from "../../../ui-lib/components/Popup/Modal";
import { notify } from "../../../ui-lib/components/Alerts/Toast";

import { AddressTable } from "./Addresses/AddressTable";
import AddAddressModal from "./Addresses/AddAddressModal";
import { ContactTable } from "./Contacts/ContactTable";
import AddContactActions from "./Contacts/AddContactActions";
import useTranslations from "../../../core/i18n/useTranslations";

const ObjectResponse = ({
  objectId,
  addresses,
  contacts,
  onAddressUpdate,
  onContactUpdate,
  onReorderUpdate,
}: {
  objectId: string;
  addresses: AddressResponse[];
  contacts: ContactResponse[];
  onAddressUpdate: () => void;
  onContactUpdate: (page?: number) => void;
  onReorderUpdate: (contactsPriority: IContactsReorder) => void;
}) => {
  const t = useTranslations();
  const [page, setPage] = useState(1);

  return (
    <Column style={{ width: "784px" }} align="start">
      <h1>{t("Menu:Objects_Response")}</h1>
      <Spacer size={8} />
      <p style={{ color: "var(--Grey-600)" }}>
        {t("Objects:response_description")}
      </p>

      <Spacer size={36} />

      <Row style={{ width: "100%" }}>
        <h2>{t("Common:addresses_label")}</h2>
        <Spacer />
        <Modal
          trigger={() => (
            <Button
              type="button"
              variant="secondary"
              image="map"
              text={t("Objects:address_add")}
            />
          )}
        >
          {(close) => (
            <AddAddressModal
              objectId={objectId}
              onClose={close}
              onAdd={onAddressUpdate}
            />
          )}
        </Modal>
      </Row>
      <Spacer size={16} />
      <AddressTable
        objectId={objectId}
        addresses={addresses}
        onAddressUpdate={onAddressUpdate}
      />

      <Spacer size={32} />
      <Divider />
      <Spacer size={36} />

      <Row style={{ width: "100%" }}>
        <h2>{t("Common:contacts_label")}</h2>
        <Spacer />
        <AddContactActions
          objectId={objectId}
          contacts={contacts}
          onContactUpdate={() => {
            setPage(1);
            onContactUpdate();
          }}
        />
      </Row>
      <p style={{ color: "var(--Grey-600)" }}>
        {t("AlarmReceptions:advanced_settings_menualternatives_label")}
      </p>
      <ContactTable
        objectId={objectId}
        contacts={contacts}
        onContactUpdate={onContactUpdate}
        onContactsReorder={onReorderUpdate}
        page={page}
        setPage={setPage}
      />
    </Column>
  );
};

const ObjectResponseContainer = ({ objectId }: { objectId: string }) => {
  const { authenticatedRequest } = useUser();
  const location = useLocation();

  const t = useTranslations();

  const [addresses, setAddresses] = useState<AddressResponse[]>();
  const [contacts, setContacts] = useState<ContactResponse[]>();

  const fetchAddresses = async () => {
    try {
      const { data } = await getAddresses(objectId, authenticatedRequest);

      setAddresses(data);
    } catch (error) {
      setAddresses([]);

      notify({
        message: t(`Errors:${getErrorKey(error)}`),
        variant: "error",
      });
    }
  };

  const fetchContacts = async (page?: number) => {
    try {
      const { data } = await getUserContacts(
        objectId,
        authenticatedRequest,
        page
      );

      setContacts(page && page !== 1 ? [...contacts!, ...data] : data);
    } catch (error) {
      setAddresses([]);

      notify({
        message: t(`Errors:${getErrorKey(error)}`),
        variant: "error",
      });
    }
  };

  const reorderContacts = async (contactsPriority: IContactsReorder) => {
    try {
      await reorderContactsPriotiry(
        objectId,
        contactsPriority,
        authenticatedRequest
      );

      await fetchContacts();
    } catch (error) {
      setAddresses([]);

      notify({
        message: t(`Errors:${getErrorKey(error)}`),
        variant: "error",
      });
    }
  };

  useEffect(() => {
    fetchAddresses();
    fetchContacts();
    sessionStorage.setItem(
      "prevPath",
      JSON.stringify({
        path: location.pathname,
        prevText: t("Menu:Objects_Response"),
      })
    );
  }, []);

  if (!contacts || !addresses) {
    return <LoadingSpinner theme="primary" />;
  }

  return (
    <ObjectResponse
      objectId={objectId}
      addresses={addresses}
      contacts={contacts}
      onAddressUpdate={fetchAddresses}
      onContactUpdate={fetchContacts}
      onReorderUpdate={reorderContacts}
    />
  );
};

export default observer(ObjectResponseContainer);
