import { useEffect } from "react";
import { useHistory } from "react-router-dom";

const KEY = "table-state";

export const useStoredTableState = (id: string) => {
  const { location } = useHistory();
  const state = decodeURI(localStorage.getItem(`${KEY}/${id}`) || "");

  if (location.search) {
    return location.search;
  }

  return state && state.length > 0 ? state : undefined;
};

export const storeTableState = (id?: string) => {
  const history = useHistory();

  useEffect(() => {
    if (!id) {
      return;
    }

    localStorage.setItem(`${KEY}/${id}`, history.location.search);
  }, [history.location.search]);
};
