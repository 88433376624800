import classnames from "classnames";
import { PopupContextMenu } from "../Menus/PopupContextMenu/PopupContextMenu";
import { Button } from "../Button/Button";
import styles from "./TagSelector.module.css";

export interface Tag {
  title: string;
  value: string;
}

export interface TagListProps {
  tags: Tag[];
  maxPreviewOptions?: number;
  className?: string;
  style?: React.CSSProperties;
}

export const TagList = ({
  tags,
  maxPreviewOptions = 3,
  className,
  style,
}: TagListProps) => {
  const renderOptions = (startIndex: number, endIndex: number) =>
    tags.slice(startIndex, endIndex).map((option) => (
      <div
        key={`option_${option.value}`}
        className={classnames({
          [styles.tagOption]: true,
        })}
      >
        {option.title}
      </div>
    ));

  return (
    <>
      <span
        className={classnames({
          [styles.tagSelector]: true,
          ...(className ? { [className]: true } : {}),
        })}
        style={style}
      >
        {/* Visible options */}
        <span>{renderOptions(0, maxPreviewOptions)}</span>

        {/* Show more button with context menu */}
        {tags.length > maxPreviewOptions ? (
          <span>
            <PopupContextMenu
              position="bottom right"
              trigger={() => (
                <div>
                  <Button
                    type="button"
                    variant="secondary"
                    size="extraSmall"
                    image="dots-horizontal"
                    className={classnames(
                      styles.tagContextMenuButton,
                      styles.tagOption
                    )}
                  />
                </div>
              )}
            >
              <div className={styles.tagContextMenuContainer}>
                {renderOptions(maxPreviewOptions, tags.length)}
              </div>
            </PopupContextMenu>
          </span>
        ) : null}
      </span>
    </>
  );
};
export default TagList;
