import { AxiosInstance } from "axios";
import API_HOST from "../apiHost";
import useAuthenticatedFetch from "../useAuthenticatedFetch";
import {
  PeriodicInteractionDetails,
  PeriodicInteractionResponse,
} from "./types";

const getInteractions = async (
  objectId: string,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.get<PeriodicInteractionResponse[]>(
    `${API_HOST}api/v2/users/${objectId}/periodicinteractions`
  );

const createInteraction = async (
  objectId: string,
  interactionDetails: PeriodicInteractionDetails,
  authenticatedRequest: AxiosInstance
) => {
  const requestData = JSON.stringify(interactionDetails);

  return authenticatedRequest.post<{}>(
    `${API_HOST}api/v2/users/${objectId}/periodicinteractions`,
    requestData,
    { headers: { "Content-Type": "application/json" } }
  );
};

const deleteInteraction = async (
  objectId: string,
  interactionId: number,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.delete<{}>(
    `${API_HOST}api/v2/users/${objectId}/periodicinteractions/${interactionId}`
  );

const useInteractionTimezones = () =>
  useAuthenticatedFetch<string[]>(
    `${API_HOST}api/users/periodicinteractions/timezones`
  );

export {
  getInteractions,
  createInteraction,
  deleteInteraction,
  useInteractionTimezones,
};
