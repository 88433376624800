import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import useUser from "./useUser";

const REFRESH_CHECK_INTERVAL = 10000; // Check every 10 seconds

const setIntervalAndExecute = (fn: () => void) => {
  fn();

  return setInterval(fn, REFRESH_CHECK_INTERVAL);
};

const UserInitializer = () => {
  const user = useUser();

  // Initialize logged in user when component mounts
  useEffect(() => {
    if (user.loggedIn && !user.initialized) {
      user.initialize().catch(() => {
        user.logOut();
      });
    }
  }, []);

  // Setup token refresh handling
  useEffect(() => {
    const refreshTimer = setIntervalAndExecute(async () => {
      try {
        if (user.needsTokenRefresh()) {
          await user.refreshToken();
        }
      } catch {
        // Logout user if refresh token fails
        user.logOut();
      }
    });

    return () => {
      // Stop checking when component unmounts
      clearTimeout(refreshTimer);
    };
  }, []);

  return <></>;
};

export default observer(UserInitializer);
