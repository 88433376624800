import moment from "moment";
import { IItemProp } from "../../ui-lib/components/Dropdown/DropdownItem";
import useTranslations from "../../core/i18n/useTranslations";

interface AlarmFilterType extends IItemProp {
  getRangeStart: Function;
}

export const useFilterTypes = (): AlarmFilterType[] => {
  const t = useTranslations();

  return [
    {
      id: 0,
      name: t("Filters:today"),
      isSelected: false,
      getRangeStart: () => undefined,
    },
    {
      id: 1,
      name: t("Filters:yesterday"),
      isSelected: false,
      getRangeStart: () => undefined,
    },
    {
      id: 2,
      name: t("Filters:lastday"),
      isSelected: false,
      getRangeStart: () => undefined,
    },
    {
      id: 3,
      name: t("Filters:lastweek"),
      isSelected: false,
      getRangeStart: (endDate: Date) =>
        moment(endDate).subtract(1, "weeks").toDate(),
    },
    {
      id: 4,
      name: t("Filters:lastmonth"),
      isSelected: false,
      getRangeStart: (endDate: Date) =>
        moment(endDate).subtract(1, "months").toDate(),
    },
    {
      id: 6,
      name: t("Filters:lastyear"),
      isSelected: false,
      getRangeStart: (endDate: Date) =>
        moment(endDate).subtract(1, "years").toDate(),
    },
  ];
};
