import { useEffect, useState } from "react";
import moment from "moment";
import useUser from "../../core/user/useUser";
import Button from "../../ui-lib/components/Button/Button";
import { Row, Spacer } from "../../components/Layout/Layout";
import useTranslations from "../../core/i18n/useTranslations";
import StyledModal from "../../ui-lib/components/Modal/Modal";
import InputContainer from "../../ui-lib/components/Inputs/InputContainer";
import { patchUpdateObject } from "../../core/api/objects/objects";
import { ObjectPatch } from "../../core/api/objects/types";
import { notify } from "../../ui-lib/components/Alerts/Toast";
import { RowData } from "../../pages/Objects/ObjectsTable";
import { IDropdownItem } from "../../ui-lib/components/Dropdown/DropdownItem";
import { getSubscriptions, Subscription } from "../../core/api/subscriptions";
import Dropdown from "../../ui-lib/components/Dropdown/Dropdown";
import DatePicker from "../../ui-lib/components/DateAndTime/DatePicker";
import { notifyApiErrors } from "../../core/helpers/helpers";

const ChangeSubscriptionModal = ({
  onClose,
  onSuccess,
  modalTitle,
  text,
  objects,
  isOpen,
  responseCenterId,
}: {
  onClose?: () => void;
  onSuccess?: () => void;
  modalTitle: string;
  text: string;
  objects: RowData[];
  isOpen: boolean;
  responseCenterId: number;
}) => {
  const t = useTranslations();
  const { authenticatedRequest, dateFormat } = useUser();
  const [isLoading, setIsLoading] = useState(false);
  const [subscriptions, setSubscriptions] = useState<IDropdownItem[]>([]);
  const [selectedSubscription, setSelectedSubscription] =
    useState<IDropdownItem>();
  const [selectedEndDate, setSelectedEndDate] = useState<Date>();
  useEffect(() => {
    getSubscriptions(responseCenterId, authenticatedRequest)
      .then((subs: Subscription[]) => {
        if (subs !== undefined) {
          const subscriptionDropdownItems: IDropdownItem[] = [];
          subs.forEach((sub) => {
            const subName = subs.length === 1 ? "" : ` (${sub.description})`;
            sub.subscriptionPeriods?.forEach((period) => {
              subscriptionDropdownItems.push({
                id: period.subscriptionId,
                name: `${t("Objects:subscription_activatefor")} ${
                  period.period
                } ${t("Objects:subscription_months")}${subName}`,
                isSelected: false,
              });
            });
          });
          setSubscriptions(subscriptionDropdownItems);
        }
      })
      .catch(() => {});
  }, []);

  const close = () => {
    setSelectedSubscription(undefined);
    setSelectedEndDate(undefined);
    onClose?.();
  };

  return (
    <StyledModal
      onClose={() => {
        close();
      }}
      isOpen={isOpen}
      modalTitle={modalTitle}
      closeOnDocumentClick={false}
      css={{ maxWidth: 960 }}
      customFooter={() => (
        <Row type="left">
          <Button
            loading={isLoading}
            variant="primary"
            disabled={
              selectedSubscription === undefined ||
              selectedEndDate === undefined
            }
            text={`${t("Objects:button_change_subscription")} (${
              objects.length
            })`}
            onClick={async () => {
              let success = true;
              if (
                selectedSubscription !== undefined &&
                selectedEndDate !== undefined
              ) {
                setIsLoading(true);
                const pairs = objects.map((sr) => ({
                  ObjectId: sr.id!,
                  ObjectName: sr.Identifier!,
                }));
                const object: ObjectPatch = {
                  nextSubscriptionId: +selectedSubscription.id,
                  responseCenterValidTo: moment(selectedEndDate)
                    .format("YYYY-MM-DD")
                    .toString(),
                };
                // ToDo: Remake this on Promise.all
                // eslint-disable-next-line no-restricted-syntax
                for (const pair of pairs) {
                  try {
                    // eslint-disable-next-line no-await-in-loop
                    await patchUpdateObject(
                      pair.ObjectId,
                      object,
                      authenticatedRequest
                    );
                  } catch (error: any) {
                    success = false;
                    if (error.response.data.Message) {
                      notify({
                        message: t(error.response.data.Message),
                      });
                    } else {
                      notifyApiErrors(
                        error.response?.data?.errors,
                        error.response?.status
                      );
                    }
                  }
                }
              }
              setIsLoading(false);
              if (success) {
                notify({
                  message: t("Objects:change_subscription_success"),
                });
                onSuccess?.();
                close();
              }
            }}
          />
          <Spacer size={8} />
          <Button
            variant="secondary"
            text={t("Common:cancel")}
            onClick={close}
          />
        </Row>
      )}
    >
      <p style={{ color: "var(--Grey-600)" }}>
        {text.replace("{objects}", objects.length.toString())}
      </p>
      <Spacer size={16} />
      {subscriptions && (
        <>
          <InputContainer
            label={
              <label htmlFor="change_subscription">
                {t("Objects:label_change_subscription")}
              </label>
            }
            input={
              <Dropdown
                id="change_subscription"
                items={subscriptions}
                disabled={
                  subscriptions === undefined || subscriptions.length === 0
                }
                selectedItem={subscriptions.find((r) => r.isSelected)}
                onSelectItem={(item) => {
                  subscriptions.forEach((r) => {
                    r.isSelected = false;
                  });
                  item.isSelected = true;
                }}
              />
            }
          />
          <InputContainer
            label={t("Objects:label_subscription_ends")}
            input={
              <div style={{ width: "150px" }}>
                <DatePicker
                  dateFormat={dateFormat?.split("/").join("-")!}
                  placeholder={t("Objects:placeholder_subscription_ends")}
                  value={selectedEndDate}
                  onChange={(newDate) => {
                    setSelectedEndDate(newDate || undefined);
                  }}
                />
              </div>
            }
          />
        </>
      )}
    </StyledModal>
  );
};

export default ChangeSubscriptionModal;
