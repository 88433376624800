import Flag from "react-country-flag";
import Icon from "../Icon";
import styles from "./Dropdown.module.css";
import Tooltip from "../Tooltip/Tooltip";

const classNames = require("classnames");

export interface IBaseDropDownItems {
  id: number | string;
  name: string;
  isSelected: boolean;
  disabled?: boolean;
  extraData?: any;
}

export interface IDropdownItemsWithIcons extends IBaseDropDownItems {
  icon?: "check" | "minus" | "x" | "user" | "users";
  color?: "Primary-700" | "Grey-500" | "Error-500";
  onlySelected?: boolean;
}

export interface IDropdownItemsWithFlag extends IBaseDropDownItems {
  flag?: string;
}

export interface IDropdownItem extends IBaseDropDownItems {}

export interface IItemProp
  extends IDropdownItemsWithIcons,
    IDropdownItemsWithFlag,
    IDropdownItem {}

interface DropdownItemProps {
  item: IItemProp;
  withoutCheckIcon?: boolean;
  tabIndex?: number;
  focused: boolean;
  onSelectItem(item: IDropdownItem, e: any): void;
  onFocusElement(): void;
  showTooltip?: boolean;
}

export default function DropdownItem({
  item,
  withoutCheckIcon = false,
  tabIndex,
  focused,
  onSelectItem,
  onFocusElement,
  showTooltip,
}: DropdownItemProps) {
  const itemClasses = classNames({
    [styles.dropdownItemContainer]: true,
    [styles.selectedItem]: item.isSelected,
    [styles.focused]: focused,
    [styles.disabled]: item.disabled,
  });

  const textClasses = classNames({
    [styles.dropdownItemName]: true,
    [styles.dropdownItemSelectedName]: item.isSelected,
    [styles.dropdownItemDisabledName]: item.disabled,
  });

  return (
    <div
      className={`${itemClasses} option`}
      onClick={(e) => onSelectItem(item, e)}
      onKeyDown={(e) => onSelectItem(item, e)}
      onMouseEnter={onFocusElement}
      role="button"
      tabIndex={tabIndex}
      aria-label="Dropdown option"
      data-cy="dropDownOption"
    >
      {/* onlySelected field prevents showing icons in the dropdown but shows 
          it on the currently selected one in the dropdown button */}
      {item.icon && !item.onlySelected && (
        <Icon name={item.icon} color={item.color} />
      )}

      {item.flag && (
        <Flag svg countryCode={item.flag} className={styles.dropdownFlag} />
      )}
      {showTooltip ? (
        <Tooltip
          variant="light"
          size="small"
          trigger={() => <span className={textClasses}>{item.name}</span>}
          text={item.name}
        />
      ) : (
        <span className={textClasses}>{item.name}</span>
      )}
      {item.isSelected && !withoutCheckIcon && (
        <Icon name="check" color="Primary-700" />
      )}
    </div>
  );
}
