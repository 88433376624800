import classNames from "classnames";
import Icon from "../../Icon";
import { Tooltip } from "../../Tooltip/Tooltip";
import styles from "../Menu.module.css";

export interface SideBarLinkButtonProps {
  text: string;
  hideText?: boolean;
  icon: string;
  fillColor?: string;
  showTooltip?: boolean;
  truncateText?: boolean;
  onClick?: () => void;
}

export const SideBarLinkButton = ({
  text,
  hideText = false,
  icon,
  fillColor,
  showTooltip = false,
  truncateText = false,
  onClick = undefined,
}: SideBarLinkButtonProps) => {
  const element = (
    <button
      type="button"
      tabIndex={0}
      className={styles.sideBarLinkButton}
      onClick={() => onClick && onClick()}
      aria-label={text || "link"}
    >
      {fillColor ? (
        <div
          style={
            fillColor
              ? {
                  backgroundColor: fillColor || "transparent",
                  width: 24,
                  height: 24,
                  borderRadius: 24,
                }
              : {}
          }
        />
      ) : (
        <Icon
          name={icon}
          size={24}
          color="Grey-500"
          className={styles.sideBarLinkIcon}
        />
      )}
      {!hideText ? (
        <span
          className={classNames({
            [styles.sideBarLinkButtonText]: true,
            [styles.truncateText]: truncateText,
          })}
        >
          {text}
        </span>
      ) : null}
    </button>
  );

  return showTooltip || truncateText ? (
    <Tooltip
      trigger={() => element}
      position="right center"
      text={text}
      disabled={false}
    />
  ) : (
    element
  );
};
