import styles from "./Divider.module.css";

export interface DividerProps {
  vertical?: boolean;
  costomStyles?: React.CSSProperties;
}
const Divider = ({ vertical = false, costomStyles }: DividerProps) => (
  <div
    className={vertical ? styles.verticalDivider : styles.divider}
    style={costomStyles}
  />
);
export default Divider;
