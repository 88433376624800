import { useHistory } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { Column, Row, Spacer } from "../../components/Layout/Layout";
import { getAdministrators } from "../../core/api/administrators/administrators";
import { getSellers } from "../../core/api/sellers/sellers";
import { AdministratorMeta } from "../../core/api/administrators/types";
import { useOrganizationsTree } from "../../core/api/organizations/organizations";
import { useQueryState } from "../../core/hooks/filters/useQueryState";
import {
  useStoredTableState,
  storeTableState,
} from "../../core/hooks/filters/useStoredTableState";
import useTranslations from "../../core/i18n/useTranslations";
import usePageState from "../../core/pagestate/usePageState";
import Button from "../../ui-lib/components/Button/Button";
import Divider from "../../ui-lib/components/Divider/Divider";
import Tree, { ITreeData } from "../../ui-lib/components/Hierarchy/Tree";
import SearchInput from "../../ui-lib/components/Inputs/SearchInput";
import Modal, { ModalActions } from "../../ui-lib/components/Popup/Modal";
import PageHeader from "../../ui-lib/components/PageHeader/PageHeader";
import { ClearFilter } from "../../ui-lib/components/Tables/CleatFilters";
import styles from "../Layout.module.css";
import { DeleteAdministratorModal } from "./AdministratorDetails/DeleteAdministratorModal";
import { AdministratorsTable } from "./AdministratorsTable";
import useUser from "../../core/user/useUser";
import {
  searchStringParser,
  urlSearchParser,
} from "../../ui-lib/utils/urlSearchParser";
import { AddAdministrator } from "./AddAdministrator/AddAdministrator";
import { useResponseCenters } from "../../core/api/responsecenters/responsecenters";
import LoadingSpinner from "../../ui-lib/components/Loading/LoadingSpinner";
import { ResponseCenterParams } from "../../core/api/responsecenters/types";
import StyledModal from "../../ui-lib/components/Modal/Modal";
import { notifyApiErrors } from "../../core/helpers/helpers";
import { api as reportApi } from "../../core/api/reports/reports";
import { getErrorKey } from "../../components/Errors/ErrorAlert";
import { notify } from "../../ui-lib/components/Alerts/Toast";
import Dropdown from "../../ui-lib/components/Dropdown/Dropdown";
import { IItemProp } from "../../ui-lib/components/Dropdown/DropdownItem";
import { useFiltersByAdminType } from "../../core/hooks/filters/useFiltersByAdminType";
import { usePageFilter } from "../../core/hooks/filters/usePageFilter";

export const PAGE_ID = "adminportal/administrators";

const Administrators = () => {
  const t = useTranslations();
  const pageState = usePageState();
  const user = useUser();
  storeTableState(PAGE_ID);
  const history = useHistory();
  const tableState = useStoredTableState(PAGE_ID);
  const searchState = urlSearchParser(tableState);
  const deleteModalRef = useRef<ModalActions>(null);

  const isSuperAdmin = user.config?.show.includes("SkygdSuperAdmin");
  const isUserResondent = user.data?.type === "respondent";
  const isUserReseller = user.data?.type === "seller";

  const [tableSettings, setTableSettings] = useQueryState<{ q: string }>({
    q: searchStringParser(searchState?.q) ?? "",
  });
  const { pageFilter, setPageFilter, resetPageFilter } =
    usePageFilter(searchState);
  const { FiltersByAdminType, setFiltersByAdminType, resetFiltersByAdminType } =
    useFiltersByAdminType(searchState);

  const [tableData, setTableData] = useState<AdministratorMeta[]>([]);
  const [sellers, setSellers] = useState<ITreeData[]>([]);
  const [selected, setSelected] = useState<AdministratorMeta>();
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [arcList, setArcList] = useState<IItemProp[]>([]);
  const [searchParam, setSearchParam] = useState<string>(
    searchStringParser(searchState?.q) ?? ""
  );

  const resetSettingsData = () => {
    resetPageFilter();
    setTableData([]);
  };

  const alarmReceptions = useResponseCenters({
    privateOnly: "true",
    page: 1,
    pageSize: 10000,
  } as ResponseCenterParams);

  const { data: adminView, isLoading } = getAdministrators({
    page: pageFilter.page,
    pageSize: pageFilter.pageSize,
    q: tableSettings.q,
    organization: FiltersByAdminType?.orgId,
    adminType: FiltersByAdminType.adminType,
    seller: FiltersByAdminType.sellerId,
    responsecenter: FiltersByAdminType.responsecenter,
  });

  const adminTypeOptions: IItemProp[] = [
    {
      id: "Respondent",
      name: t("Common:respondent"),
      isSelected: FiltersByAdminType.adminType === "Respondent",
    },
  ];
  if (!isUserResondent) {
    adminTypeOptions.unshift({
      id: "Admin",
      name: t("Common:admin"),
      isSelected: FiltersByAdminType.adminType === "Admin",
    });
  }
  if (isSuperAdmin) {
    adminTypeOptions.unshift({
      id: "Seller",
      name: t("Common:seller"),
      isSelected: FiltersByAdminType.adminType === "Seller",
    });
  }

  useEffect(() => {
    const arcsOptions: IItemProp[] = alarmReceptions?.data?.map((arc) => ({
      id: arc.id!,
      name: arc.name!,
      // This calculation is to heavy. Refactor is needed
      isSelected: arc.id === Number(FiltersByAdminType.responsecenter),
    }));
    setArcList(arcsOptions);
  }, [alarmReceptions.data, FiltersByAdminType.responsecenter]);

  const onDone = () => {
    setIsExportLoading(false);
    setIsExportModalOpen(false);
  };

  const onError = (error: any) => {
    setIsExportLoading(false);

    const errorKey = getErrorKey(error);
    notify({
      message: t(`Errors:${errorKey}`),
      variant: "error",
    });
  };

  const { downloadReport } = reportApi(
    user.authenticatedRequest,
    onDone,
    onError
  );

  const { data: organisations } = useOrganizationsTree();

  const onClear = () => {
    setSearchParam("");
    setTableSettings({ q: "" });
    resetFiltersByAdminType();
    resetPageFilter();
    setTableData([]);
  };

  const fetchSellers = async () => {
    try {
      const res = await getSellers(user.authenticatedRequest);
      if (res.data) {
        const sellersItems = res.data.map((seller: any) => ({
          key: seller.id,
          label: seller.name,
          data: seller.name,
        }));
        setSellers(sellersItems);
      }
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    }
  };

  useEffect(() => {
    setTableData(adminView?.admins);
  }, [adminView?.admins]);

  useEffect(() => {
    if (isSuperAdmin || isUserReseller) {
      fetchSellers();
    }
  }, [user.data?.type, user.config]);

  const tryDownloadReport = async () => {
    setIsExportLoading(true);
    await downloadReport({
      reportId: 7,
      reportArguments: {
        runMode: "BySearchCriteria",
        entityIds: [],
        organizationIds: Number(FiltersByAdminType?.orgId)
          ? [Number(FiltersByAdminType?.orgId)]
          : [],
        searchText: tableSettings?.q ?? "",
      },
    });
  };

  useEffect(() => {
    sessionStorage.setItem(
      "prevPath",
      JSON.stringify({
        path: history.location.pathname,
        pathName: t("Menu:administrators"),
      })
    );
  }, []);

  const selectedAdminType = adminTypeOptions.find((type) => type.isSelected);

  const isFiltersApplied =
    !!FiltersByAdminType.orgId ||
    !!FiltersByAdminType.adminType ||
    !!FiltersByAdminType.sellerId ||
    !!FiltersByAdminType.responsecenter ||
    !!tableSettings.q.length;

  const renderAdminTypeFilter = () => (
    <>
      <Dropdown
        withClearItem
        placeholder={`--${t("Administrator:type")}--`}
        width={250}
        selectedItem={selectedAdminType}
        items={adminTypeOptions}
        isLoading={!user.data?.type}
        disabled={!user.data?.type}
        onSelectItem={(item) => {
          if (item.id.toString() === FiltersByAdminType.adminType) return;
          if (item.id === -1) {
            if (FiltersByAdminType.adminType) {
              resetSettingsData();
            }
            resetFiltersByAdminType();
            return;
          }
          // Clear all previosly selected filters
          resetFiltersByAdminType();
          resetSettingsData();
          // Set new filter value
          setFiltersByAdminType({ adminType: item.id.toString() });
        }}
      />
      <Spacer size={8} />
      {FiltersByAdminType.adminType === "Seller" && (
        <Tree
          showClearOption
          className={styles.treeFilter}
          placeholder={`--${t("Common:seller")}--`}
          items={sellers}
          selectedTreeItem={Number(FiltersByAdminType.sellerId)}
          onSelectItem={(treeItem) => {
            resetSettingsData();
            setFiltersByAdminType({
              sellerId: treeItem?.key.toString(),
            });
          }}
          disabled={!sellers.length}
        />
      )}
      {FiltersByAdminType.adminType == "Admin" && (
        <Tree
          showClearOption
          className={styles.treeFilter}
          placeholder={`--${t("Common:organisation")}--`}
          items={organisations}
          selectedTreeItem={Number(FiltersByAdminType.orgId)}
          onSelectItem={(treeItem) => {
            resetSettingsData();
            setFiltersByAdminType({
              orgId: treeItem?.key.toString(),
            });
          }}
          disabled={!organisations.length}
        />
      )}
      {FiltersByAdminType.adminType === "Respondent" && (
        <Dropdown
          withClearItem
          withFilter
          maxVisible={9}
          bodyWidth={350}
          width={250}
          placeholder={`--${t("Common:labels_alarm_reception")}--`}
          items={arcList || []}
          disabled={alarmReceptions.isLoading || !arcList?.length}
          isLoading={alarmReceptions.isLoading}
          selectedItem={arcList?.find((arc) => arc?.isSelected)}
          onSelectItem={(item) => {
            if (item.id.toString() === FiltersByAdminType.responsecenter)
              return;
            if (+item.id === -1) {
              if (FiltersByAdminType.responsecenter) {
                resetSettingsData();
              }
              setFiltersByAdminType({
                ...FiltersByAdminType,
                responsecenter: undefined,
              });
              return;
            }
            // Clear all previosly selected filters
            resetFiltersByAdminType();
            resetSettingsData();
            // Set new filter value
            setFiltersByAdminType({
              ...FiltersByAdminType,
              responsecenter: item.id.toString(),
            });
          }}
        />
      )}
    </>
  );

  return (
    <>
      <StyledModal
        isOpen={isExportModalOpen}
        onClose={() => setIsExportModalOpen(false)}
        modalTitle={t("Common:export")}
        customFooter={() => (
          <Row type="left">
            <Button
              loading={isExportLoading}
              disabled={isExportLoading}
              text={t("Common:export")}
              onClick={tryDownloadReport}
            />
            <Spacer size={8} />
            <Button
              variant="secondary"
              text={t("Common:cancel")}
              onClick={() => setIsExportModalOpen(false)}
            />
          </Row>
        )}
      >
        <p>{t("Administrator:export_modal_description")}</p>
      </StyledModal>
      <PageHeader
        title={t("Menu:administrators")}
        icon={pageState.pageIcon}
        userName={user.data?.username}
      >
        {!isUserResondent && (
          <AddAdministrator
            variant="primary"
            organisations={organisations}
            sellers={sellers}
            alarmReceptions={alarmReceptions}
            onSubmit={(adminId: number) => {
              history.push(`/${PAGE_ID}/${adminId}`);
            }}
          />
        )}
        <>
          {!!user.config?.show.find(
            (key) =>
              key === "ListRoleTemplates" || key === "ListAllRoleTemplates"
          ) && (
            <Button
              loading={alarmReceptions.isLoading}
              disabled={alarmReceptions.isLoading}
              variant="secondary"
              text={t("Administrator:manage_role_templates")}
              onClick={() => history.push("/adminportal/roletemplates")}
            />
          )}
        </>
        {user.config?.show?.includes("Reports") && (
          <Button
            variant="secondary"
            text={t("Common:export")}
            onClick={() => setIsExportModalOpen(true)}
          />
        )}
      </PageHeader>
      <Divider />

      <Column type="top" className={styles.content}>
        <Row className={styles.settingsRow}>
          <SearchInput
            placeholder={t("Administrator:administrators_search_placeholder")}
            limit={1}
            value={searchParam}
            onChange={(value) => {
              if (searchParam !== value) {
                resetSettingsData();
              }
              setSearchParam(value);
              setTableSettings({ q: value });
            }}
          />
        </Row>

        <Spacer size={16} />
        <Divider />
        <Spacer size={16} />
        <Row type="left" align="start" style={{ width: "100%", flex: "unset" }}>
          {renderAdminTypeFilter()}
          <Spacer size={8} />

          <ClearFilter
            text={t("Common:labels_clear_all_filters")}
            onClearClick={onClear}
            filtersToWatch={[searchParam, FiltersByAdminType.adminType]}
          />
        </Row>
        <Spacer size={16} />

        {isLoading ? (
          <LoadingSpinner theme="primary" />
        ) : (
          <AdministratorsTable
            data={tableData}
            adminFiltersApplied={isFiltersApplied}
            onClear={onClear}
            totalRoleAmount={adminView?.totalRoleAmount}
            alarmReceptions={alarmReceptions}
            sellers={sellers}
            pageSettings={pageFilter}
            onPageSettingsChange={setPageFilter}
            organisations={organisations}
            onStartDelete={(admin: AdministratorMeta) => {
              setSelected(admin);
              deleteModalRef?.current?.open();
            }}
          />
        )}
      </Column>

      <Modal modalRef={deleteModalRef}>
        {(close) => (
          <DeleteAdministratorModal
            administratorId={
              (!!selected?.identityId && selected?.identityId) ||
              (!!selected?.roles[0].id && selected?.roles[0].id) ||
              ""
            }
            identity={!!selected?.identityId}
            onClose={close}
            onDelete={() => {
              close();
              setTableData((prev) => {
                if (selected?.identityId) {
                  return prev.filter(
                    (c) => c.identityId !== selected?.identityId
                  );
                }
                return prev.filter(
                  (c) => c.roles[0].id !== selected?.roles[0].id
                );
              });
            }}
          />
        )}
      </Modal>
    </>
  );
};

export default Administrators;
