import { AxiosInstance, CancelToken } from "axios";
import { downloadAsXlsxFile } from "../../helpers/helpers";
import API_HOST from "../apiHost";
import useAuthenticatedFetch from "../useAuthenticatedFetch";
import { PeriodicReportResponse, ReportResponse } from "./types";

interface reportArguments {
  registrationTimeStart?: string;
  registrationTimeEnd?: string;
  start?: string;
  end?: string;
  runMode?: "BySearchCriteria" | "ByChosenUsers";
  entityIds: number[];
  organizationIds?: number[];
  inStock?: number;
  organizations?: number[];
  searchText: string;
  filterType?: number;
  filterValue?: string;
  filterType2?: number;
  filterValue2?: string;
  filterType3?: number;
  filterValue3?: string;
  filterType4?: number;
  filterValue4?: string;
}

const useReports = () =>
  useAuthenticatedFetch<ReportResponse[]>(`${API_HOST}api/v2/reports`);

const usePeriodicReports = () =>
  useAuthenticatedFetch<PeriodicReportResponse[]>(
    `${API_HOST}api/v2/reports/periodic`
  );

const getPeriodicReports = async (authenticatedRequest: AxiosInstance) =>
  authenticatedRequest.get<PeriodicReportResponse[]>(
    `${API_HOST}api/v2/reports/periodic`
  );

const api = (
  service: AxiosInstance,
  onDone?: () => void,
  onError?: (error: any) => void
) => ({
  downloadReport: async ({
    reportId,
    language,
    reportArguments: _reportArguments = {
      entityIds: [],
      organizationIds: [],
      searchText: "",
    },
    cancelToken,
  }: {
    reportId: number;
    language?: string;
    reportArguments?: reportArguments;
    cancelToken?: CancelToken;
  }) => {
    const reportArguments = {
      runMode: "BySearchCriteria",
      exactMatch: false,
      nameOnly: false,
      ..._reportArguments,
    };

    const tableParams = {
      language,
      reportArguments,
      outputFormat: "EXCEL",
      reportId,
    };

    const { data } = await service.post(
      `${API_HOST}api/v2/reports/generate`,
      tableParams,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
        cancelToken,
      }
    );

    const downloadInterval = setInterval(async () => {
      try {
        const reqParams = {
          language,
        };

        const fileResponse = await service.get(
          `${API_HOST}api/v2/reports/download/${data}/`,
          {
            params: reqParams,
          }
        );

        if (onDone) {
          onDone();
        }
        clearInterval(downloadInterval);
        downloadAsXlsxFile(
          fileResponse.data.fileData,
          fileResponse.data.fileName
        );
      } catch (error: any) {
        // 404 - report is not ready. Therefore, need to make another try in 10 seconds.
        if (error.response.status === 404) {
          return;
        }

        if (onError) {
          onError(error);
        }

        clearInterval(downloadInterval);
      }
    }, 10000);

    return downloadInterval;
  },
  setupPeriodicReport: async ({
    reportId,
    email,
    comment,
    periodicity,
    language,
    dontSendEmptyReports,
    reportArguments: _reportArguments = {} as reportArguments,
    cancelToken,
  }: {
    reportId: number;
    email: string;
    comment?: string;
    periodicity: number;
    language?: string;
    dontSendEmptyReports?: boolean;
    reportArguments?: reportArguments;
    cancelToken?: CancelToken;
  }) => {
    const reportArguments = {
      exactMatch: false,
      nameOnly: false,
      ..._reportArguments,
    };

    const requestParams = {
      reportId,
      email,
      comment,
      periodicity,
      dontSendEmptyReports,
      reportArguments,
      language: language ?? "en-GB",
      outputFormat: "EXCEL",
    };

    await service.post(`${API_HOST}api/v2/reports/periodic`, requestParams, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
      cancelToken,
    });
  },
  deletePeriodicReport: ({ reportId }: { reportId: number | string }) =>
    service.delete(`${API_HOST}api/v2/reports/periodic/${reportId}`),
});

const reportDownload = async (
  authenticatedRequest: AxiosInstance,
  fileName: string
) =>
  authenticatedRequest.get<any>(
    `${API_HOST}api/v2/reports/download/${fileName}/`
  );

export {
  useReports,
  usePeriodicReports,
  getPeriodicReports,
  api,
  reportDownload,
};
