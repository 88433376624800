import DeleteModal from "../../../components/DeleteModal/DeleteModal";
import { deleteRoleTemplate } from "../../../core/api/roleTemplates/roleTemplates";
import useTranslations from "../../../core/i18n/useTranslations";

export const DeleteRoleTemplateModal = ({
  templateId,
  onClose,
  onDelete,
}: {
  templateId?: number | string;
  onClose: () => void;
  onDelete: () => void;
}) => {
  const t = useTranslations();

  if (!templateId) {
    return null;
  }

  return (
    <DeleteModal
      locizeKey="role_template"
      title={t("Administrator:delete_role_template")}
      callDelete={(authReq) => deleteRoleTemplate(templateId, authReq)}
      onClose={onClose}
      onDelete={onDelete}
    />
  );
};
