import { useEffect } from "react";
import { Controller } from "react-hook-form";
import {
  useObjectForm,
  useObjectFormRef,
} from "../../../core/SaveObjectContext/hooks";
import useTranslations from "../../../core/i18n/useTranslations";
import Divider from "../../../ui-lib/components/Divider/Divider";
import { Row, Spacer } from "../../../components/Layout/Layout";
import Dropdown from "../../../ui-lib/components/Dropdown/Dropdown";
import styles from "./RetentionDetails.module.css";
import { IRetentionDetails } from "../../../core/api/organizations/types";
import { IDropdownItem } from "../../../ui-lib/components/Dropdown/DropdownItem";

export const RetentionDetailsForm = ({
  defaultValues,
  retentionOptions,
  onSubmit,
}: {
  defaultValues?: IRetentionDetails;
  retentionOptions?: any;
  onSubmit: (values: IRetentionDetails) => Promise<void>;
}) => {
  const t = useTranslations();
  const formRef = useObjectFormRef();
  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
    reset,
    trigger,
  } = useObjectForm<IRetentionDetails>({
    defaultValues,
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  const retentionValidate = (
    data: number,
    watcher: number,
    message: string,
    fieldName: string
  ) => {
    // validate data retention fields
    if (fieldName === "alarmDataRetention") {
      // if retention is Unlimited or Not defined - fail validation
      if (data === 0 || data === -1)
        return t("Organizations:not_valid_for_field");
      // if data retention set "Unlimited" and watched field is not - refuse validation
      if (data !== 0 && watcher === 0) return message;
      // data retention have to be more than media retention
      if (data >= watcher) return true;
    }
    if (fieldName === "objectDataRetention") {
      // if set "Unlimited" - pass validation
      if (data === 0) return true;
      // if data retention set "Unlimited" and watched field is not - refuse validation
      if (data !== 0 && watcher === 0) return message;
      // data retention have to be more than media retention
      if (data >= watcher) return true;
    }
    // validate media retention fields
    if (
      fieldName === "alarmMediaRetention" ||
      fieldName === "objectMediaRetention"
    ) {
      // if watched field has value 0 - pass validation
      if (watcher === 0) return true;
      // if media retention set "Unlimited" and watched field is not - refuse validation
      if (data === 0 && watcher !== 0) return message;
      // media retention have to be less than data retention
      if (data <= watcher) return true;
    }
    // return error message
    return message;
  };

  return (
    <form
      ref={formRef}
      onSubmit={handleSubmit(async (values) => {
        await onSubmit(values);
        reset();
      })}
    >
      <Row type="left" align="start">
        <Controller
          name="alarmDataRetention"
          control={control}
          rules={{
            validate: {
              organisationData: (value: number) =>
                retentionValidate(
                  value,
                  watch("alarmMediaRetention"),
                  `${t("Organizations:should_be_more_or_equal")} ${t(
                    "Organizations:media_retention"
                  )}`,
                  "alarmDataRetention"
                ),
            },
          }}
          render={({ field }) => {
            const alarmRetentionData = retentionOptions.map(
              (i: IDropdownItem) => ({
                ...i,
                isSelected: +i.id === field.value,
              })
            );
            return (
              <Dropdown
                className={styles.dropdownItem}
                selectedItem={alarmRetentionData.find(
                  (i: IDropdownItem) => i.isSelected
                )}
                title={t("Organizations:alarm_data_retention")}
                onSelectItem={(item) => {
                  setValue(field.name, +item.id);
                  trigger(["alarmMediaRetention", "alarmDataRetention"]);
                }}
                items={alarmRetentionData}
                validationError={errors?.alarmDataRetention?.message}
              />
            );
          }}
        />
        <Spacer size={32} />
        <Controller
          name="alarmMediaRetention"
          control={control}
          rules={{
            validate: {
              organisationMedia: (value) =>
                retentionValidate(
                  value,
                  watch("alarmDataRetention"),
                  `${t("Organizations:should_be_less_or_equal")} ${t(
                    "Organizations:alarm_data_retention"
                  )}`,
                  "alarmMediaRetention"
                ),
            },
          }}
          render={({ field }) => {
            const alarmMediaRetention = retentionOptions.map(
              (i: IDropdownItem) => ({
                ...i,
                isSelected: +i.id === field.value,
              })
            );
            return (
              <Dropdown
                className={styles.dropdownItem}
                title={t("Organizations:media_retention")}
                selectedItem={alarmMediaRetention.find(
                  (i: IDropdownItem) => i.isSelected
                )}
                onSelectItem={(item: IDropdownItem) => {
                  setValue(field.name, +item.id);
                  trigger(["alarmMediaRetention", "alarmDataRetention"]);
                }}
                items={alarmMediaRetention}
                validationError={errors?.alarmMediaRetention?.message}
              />
            );
          }}
        />
      </Row>
      <Spacer size={16} />
      <Divider />
      <Spacer size={16} />
      <h2>{t("Organizations:object_retention")}</h2>
      <p>{t("Organizations:months_after_subscription_cancelled")}</p>
      <Spacer size={16} />
      <Row type="left" align="start">
        <Controller
          name="objectDataRetention"
          control={control}
          rules={{
            validate: {
              objectData: (value) =>
                retentionValidate(
                  value,
                  watch("objectMediaRetention"),
                  `${t("Organizations:should_be_more_or_equal")} ${t(
                    "Organizations:media_data_associated_with_object"
                  )}`,
                  "objectDataRetention"
                ),
            },
          }}
          render={({ field }) => {
            const objectDataRetention = retentionOptions.map(
              (i: IDropdownItem) => ({
                ...i,
                isSelected: +i.id === field.value,
              })
            );
            return (
              <Dropdown
                className={styles.dropdownItem}
                title={t("Organizations:alarm_data_associated_with_object")}
                selectedItem={objectDataRetention.find(
                  (i: IDropdownItem) => i.isSelected
                )}
                onSelectItem={(item: IDropdownItem) => {
                  setValue(field.name, +item.id);
                  trigger(["objectDataRetention", "objectMediaRetention"]);
                }}
                items={objectDataRetention}
                validationError={errors?.objectDataRetention?.message}
              />
            );
          }}
        />
        <Spacer size={32} />
        <Controller
          name="objectMediaRetention"
          control={control}
          rules={{
            validate: {
              objectMedia: (value) =>
                retentionValidate(
                  value,
                  watch("objectDataRetention"),
                  `${t("Organizations:should_be_more_or_equal")} ${t(
                    "Organizations:alarm_data_associated_with_object"
                  )}`,
                  "objectMediaRetention"
                ),
            },
          }}
          render={({ field }) => {
            const objectMediaRetention = retentionOptions.map(
              (i: IDropdownItem) => ({
                ...i,
                isSelected: +i.id === field.value,
              })
            );
            return (
              <Dropdown
                className={styles.dropdownItem}
                title={t("Organizations:media_data_associated_with_object")}
                selectedItem={objectMediaRetention.find(
                  (i: IDropdownItem) => i.isSelected
                )}
                onSelectItem={(item: IDropdownItem) => {
                  setValue(field.name, +item.id);
                  trigger(["objectDataRetention", "objectMediaRetention"]);
                }}
                items={objectMediaRetention}
                validationError={errors?.objectMediaRetention?.message}
              />
            );
          }}
        />
      </Row>
      <Spacer size={16} />
      <Row type="left" align="start">
        <Controller
          name="alarmAfterObjectDeleteRetention"
          control={control}
          render={({ field }) => {
            const alarmAfterObjectDeleteRetention = retentionOptions.map(
              (i: IDropdownItem) => ({
                ...i,
                isSelected: +i.id === field.value,
              })
            );
            return (
              <Dropdown
                className={styles.dropdownItem}
                title={t("Organizations:static_data")}
                selectedItem={alarmAfterObjectDeleteRetention.find(
                  (i: IDropdownItem) => i.isSelected
                )}
                onSelectItem={(item: IDropdownItem) => {
                  setValue(field.name, +item.id);
                }}
                items={alarmAfterObjectDeleteRetention}
              />
            );
          }}
        />
      </Row>
    </form>
  );
};
