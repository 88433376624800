import {
  HierarchyUsage,
  RoleTemplate,
} from "../../../core/api/roleTemplates/types";
import useTranslations from "../../../core/i18n/useTranslations";
import Table from "../../../ui-lib/components/Tables/Table";
import styles from "../Administrator.module.css";
import TableCell from "../../../ui-lib/components/Tables/TableCell";
import useUser from "../../../core/user/useUser";
import LoadingSpinner from "../../../ui-lib/components/Loading/LoadingSpinner";
import { Column } from "../../../components/Layout/Layout";

// as we dont get the total amount of role templates from the backend we use
// a ugly way to continue showing the "Show More" button when deleeting rows
let showMoreOnDelete = false;

const columns = (
  t: (key: string) => string,
  onEdit: (role: RoleTemplate) => void
) => [
  {
    key: "name",
    header: t("Common:role_template"),
    fieldTemplate: (role: RoleTemplate) => {
      return (
        <TableCell
          value={role.name}
          leftIcon={role.type === "respondent" ? "phone-incoming" : "user"}
          leftIconTooltip={
            role.type === "respondent"
              ? t(`Common:respondent`)
              : t("Common:admin")
          }
          iconColor="Grey-300"
          className={styles.bold}
          onClick={() => onEdit(role)}
        />
      );
    },
  },
  {
    key: "type",
    header: t("Administrator:type"),
    fieldTemplate: (role: RoleTemplate) => (
      <TableCell
        value={t(
          role.type === "respondent" ? "Common:respondent" : "Common:admin"
        )}
      />
    ),
  },
  {
    key: "belongsTo",
    header: t("Common:belongs_to"),
    fieldTemplate: (role: RoleTemplate) => {
      const respondent = role.type === "respondent";
      const organization = role.type === "customer";

      let linkTo = ""; // if it is a reseller dont link it to anywhere as we dont do any reseller stuff in new admin
      let icon = "";
      let iconTooltip = "";
      if (respondent) {
        linkTo = `/adminportal/alarm-receptions/${role.responseCenterId}`;
        icon = "alarm-reception";
        iconTooltip = t("Administrator:general_input_alarm_reception");
      } else if (organization) {
        linkTo = `/adminportal/organizations/${role.organizationId}`;
        icon = "office-building";
        iconTooltip = t("Administrator:customer");
      }

      return (
        <TableCell
          value={role.belongsTo}
          leftIcon={icon}
          leftIconTooltip={iconTooltip}
          iconColor="Grey-300"
          className="cell"
          linkTo={linkTo}
        />
      );
    },
  },
  {
    key: "usableBy",
    header: t("Administrator:usable_by"),
    fieldTemplate: (role: RoleTemplate) => {
      let usableBy = t("Common:none");

      if (role.hierarchyUsage === HierarchyUsage.AllBellow)
        usableBy = t("Administrator:all_below");
      if (role.hierarchyUsage === HierarchyUsage.OneBellow)
        usableBy = t("Administrator:one_below");
      if (role.hierarchyUsage === HierarchyUsage.ThisOrganization)
        usableBy = t("Administrator:this_organization");
      return <TableCell value={usableBy} />;
    },
  },
];

const RoleTemplateTable = ({
  roleTemplates = [],
  isLoading = false,
  pageSettings,
  onPageSettingsChange,
  onStartDelete,
  onStartEdit,
}: {
  roleTemplates: RoleTemplate[];
  isLoading?: boolean;
  pageSettings: {
    page: number;
    pageSize: number;
    total: number;
  };
  onPageSettingsChange: (args: {
    page: number;
    pageSize: number;
    total: number;
  }) => void;
  onStartDelete: (roleTemplate: RoleTemplate) => void;
  onStartEdit: (roleTemplate: RoleTemplate) => void;
}) => {
  const t = useTranslations();
  const user = useUser();
  const showMore =
    roleTemplates.length === pageSettings.pageSize * pageSettings.page ||
    showMoreOnDelete;
  const isEditEnabled = user.config?.show.includes("UpdateRoleTemplates");

  return (
    <>
      {isLoading ? (
        <Column>
          <LoadingSpinner theme="primary" />
        </Column>
      ) : (
        <Table<RoleTemplate>
          items={roleTemplates}
          columns={columns(t, onStartEdit)}
          hideEmptyMessage
          onRowClick={(role) => onStartEdit(role)}
          rowActions={
            isEditEnabled
              ? [
                  {
                    icon: "pencil-alt",
                    text: t("Common:label_edit"),
                    onClick: (role) => onStartEdit(role),
                  },
                  {
                    icon: "trash",
                    text: t("Common:delete"),
                    iconVariant: "secondary",
                    onClick: (role) => {
                      if (showMore) showMoreOnDelete = true;
                      onStartDelete(role);
                    },
                  },
                ]
              : []
          }
          rowActionsColWidth={80}
          rowActionsFixed
          showRowHover
          withShowMore
          withLazyLoading
          paginatedItems={{
            items: roleTemplates,
            pagination: {
              offset: 0,
              limit: roleTemplates.length,
              total: roleTemplates.length + (showMore ? 1 : 0),
            },
          }}
          onShowMoreClick={() => {
            showMoreOnDelete = false;
            onPageSettingsChange({
              page: pageSettings.page + 1,
              pageSize: pageSettings.pageSize,
              total: pageSettings.total,
            });
          }}
        />
      )}
    </>
  );
};

export default RoleTemplateTable;
