import { useState } from "react";
import { capitalize } from "lodash";
import useTranslations from "../../../../core/i18n/useTranslations";
import { deleteRespondentsFromGroup } from "../../../../core/api/responsecenters/responsecenters";
import StyledModal from "../../../../ui-lib/components/Modal/Modal";
import { Row, Spacer } from "../../../../components/Layout/Layout";
import Button from "../../../../ui-lib/components/Button/Button";
import { notifyApiErrors } from "../../../../core/helpers/helpers";
import { notify } from "../../../../ui-lib/components/Alerts/Toast";
import useUser from "../../../../core/user/useUser";

export const DeleteRespondentsFromGroupModal = ({
  arcId,
  groupId,
  isOpen,
  ids,
  onClose,
  onDelete,
}: {
  arcId: number;
  groupId: number;
  isOpen: boolean;
  ids: number[];
  onClose: () => void;
  onDelete: (success: boolean) => void;
}) => {
  const t = useTranslations();
  const { authenticatedRequest } = useUser();
  const [isLoading, setIsLoading] = useState(false);

  const type =
    ids.length > 1
      ? t("AlarmReceptions:respondent_from_group_type_multi")
      : t("AlarmReceptions:respondent_from_group_type");
  const title = t("Common:delete_modal_title").replace("{type}", type);
  const confirmMessage =
    ids.length > 1
      ? t("Common:delete_modal_confirm_batch")
          .replace("{count}", `${ids.length}`)
          .replace("{type}", type)
      : t("Common:delete_modal_confirm").replace("{type}", type);
  const buttonLabel = t("Common:delete");
  const successMessage = capitalize(
    t("Common:delete_success").replace("{type}", type)
  );
  return (
    <StyledModal
      isOpen={isOpen}
      onClose={onClose}
      modalTitle={title}
      closeOnDocumentClick={false}
      css={{ maxWidth: "960px" }}
      customFooter={() => (
        <Row type="left">
          <Button
            variant="destructive"
            text={buttonLabel}
            disabled={isLoading}
            loading={isLoading}
            onClick={async () => {
              setIsLoading(true);
              let success = true;
              await deleteRespondentsFromGroup(
                arcId,
                groupId,
                ids,
                authenticatedRequest
              ).catch((error) => {
                success = false;
                notifyApiErrors(
                  error.response?.data?.errors,
                  error.response?.status
                );
              });
              setIsLoading(false);

              if (success) {
                notify({
                  message: successMessage,
                });
              }
              onDelete(success);
            }}
          />
          <Spacer size={8} />
          <Button
            variant="secondary"
            text={t("Common:cancel")}
            onClick={onClose}
          />
        </Row>
      )}
    >
      <p style={{ color: "var(--Grey-600)" }}>{confirmMessage}</p>
    </StyledModal>
  );
};
