import styles from "./ExternalLink.module.css";
import { Icon } from "../Icon";

export interface ExternalLinkProps {
  text: string;
  url: string;
  icon?: string;
  iconSize?: number;
  iconColor?: "Primary-700" | "Grey-500";
  iconPosition?: "right" | "left";
  className?: string;
  targetBlank?: boolean;
}
export default function ExternalLink({
  text,
  url,
  icon,
  iconSize,
  iconColor = "Primary-700",
  iconPosition = "right",
  className,
  targetBlank,
}: ExternalLinkProps) {
  return (
    <a
      target={targetBlank ? "_blank" : undefined}
      rel="noopener noreferrer"
      href={url}
      className={className}
    >
      <div className={styles.container}>
        {iconPosition === "left" && (
          <>
            <Icon
              name={icon || "external-link"}
              size={iconSize || 16}
              color={iconColor}
            />
            <span>{text}</span>
          </>
        )}
        {iconPosition === "right" && (
          <>
            <span style={{ marginRight: "4px" }}>{text}</span>
            <Icon
              name={icon || "external-link"}
              size={iconSize || 16}
              color={iconColor}
            />
          </>
        )}
      </div>
    </a>
  );
}
