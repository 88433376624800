import { uniqBy } from "lodash";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { getErrorKey } from "../../../../components/Errors/ErrorAlert";
import { Spacer } from "../../../../components/Layout/Layout";
import {
  addCommonContact,
  useCommonContactsForUser,
} from "../../../../core/api/contacts/contacts";
import {
  ContactResponse,
  IContactsRelation,
} from "../../../../core/api/contacts/types";
import useTranslations from "../../../../core/i18n/useTranslations";
import useUser from "../../../../core/user/useUser";
import { notify } from "../../../../ui-lib/components/Alerts/Toast";
import SearchInput from "../../../../ui-lib/components/Inputs/SearchInput";
import LoadingSpinner from "../../../../ui-lib/components/Loading/LoadingSpinner";
import { SelectCommonContactsTable } from "./SelectCommonContactsTable";
import { ContactsRelationModal } from "./ContactsRelationModal";
import PrimeModal from "../../../../ui-lib/components/PrimeModal/PrimeModal";

const INITIAL_PAGE_SETTINGS = {
  page: 1,
  pageSize: 5,
};

const AddCommonContactModal = ({
  addContactModalOpen,
  objectId,
  added,
  onClose,
  onAdd,
}: {
  addContactModalOpen: boolean;
  objectId: string;
  added: number[];
  onClose: () => void;
  onAdd: () => void;
}) => {
  const t = useTranslations();

  const [searchString, setSearchString] = useState("");
  const [pageSettings, setPageSettings] = useState(INITIAL_PAGE_SETTINGS);
  const [total, setTotal] = useState<ContactResponse[]>([]);
  const [selectedContact, setSelectedContact] = useState<number | null>();
  const [isAdding, setIsAdding] = useState<string | undefined>();
  const [selectedContactId, setSelectedContactId] = useState<
    number | undefined
  >();

  const { authenticatedRequest } = useUser();
  const { data, isLoading, isError } = useCommonContactsForUser(objectId, {
    ...pageSettings,
    search: searchString,
  });

  // Append new data to list once updated
  useEffect(() => {
    if (data) {
      setTotal((prev) => {
        const updated =
          pageSettings.page === 1 ? [...data] : [...prev, ...data];

        return uniqBy(updated, "id");
      });
    }
  }, [data]);

  // This is called when table settings are updated
  const resetPageSettings = () => {
    setPageSettings(INITIAL_PAGE_SETTINGS);
  };

  const onSubmit = async (
    values: IContactsRelation,
    closeList = false,
    resourceId: number | null = null
  ) => {
    if (!selectedContact && !resourceId) {
      return;
    }

    const visibleInList = !!total.find(
      (i) => i.id === (selectedContact || resourceId)
    );
    if (!visibleInList) {
      return;
    }

    try {
      setIsAdding(closeList ? "close" : "save");
      await addCommonContact(
        objectId,
        resourceId || selectedContact!,
        values,
        authenticatedRequest
      );
      notify({
        message: t("Objects:edited_success"),
      });
      setSelectedContact(null);
      onAdd();
      if (closeList) {
        onClose();
      }
      setSelectedContactId(undefined);
    } catch (error) {
      const errorKey = getErrorKey(error);
      notify({
        message: t(`Errors:${errorKey}`),
        variant: "error",
      });
    } finally {
      setIsAdding(undefined);
    }
  };

  return (
    <>
      <PrimeModal
        withHeader
        header={t("Contacts:add")}
        isOpen={addContactModalOpen}
        onClose={() => {
          setSearchString("");
          setTotal([]);
          resetPageSettings();
          onClose();
        }}
      >
        <p style={{ color: "var(--Grey-600)" }}>
          {t("Contacts:add_common_contact_description")}
        </p>
        <Spacer size={16} />
        <SearchInput
          value={searchString}
          limit={1}
          onChange={(value) => {
            setSearchString(value);
            resetPageSettings();
          }}
          placeholder={t("Contacts:search_contact_placeholder")}
        />
        <Spacer size={20} />
        <h2 className="subTitle">{t("Contacts:common_contacts")}</h2>
        <Spacer size={16} />
        {!data ? (
          <LoadingSpinner theme="primary" />
        ) : (
          <SelectCommonContactsTable
            contacts={total}
            isLoading={(!data || isLoading) && !isError}
            added={added}
            pageSettings={pageSettings}
            onPageSettingsChange={setPageSettings}
            onContactSelect={setSelectedContact}
            onResourceSubmit={onSubmit}
            setSelectedContactId={setSelectedContactId}
            selectedContactId={selectedContactId}
          />
        )}
        <Spacer size={16} />
      </PrimeModal>
      <ContactsRelationModal
        isOpen={!!selectedContact}
        isAdding={isAdding}
        onClose={() => setSelectedContact(null)}
        onSubmit={onSubmit}
        setSelectedContactId={setSelectedContactId}
      />
    </>
  );
};

export default observer(AddCommonContactModal);
