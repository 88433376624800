import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ObjectTransmitterInteractions } from "../../pages/Objects/ObjectTransmitterInteractions/ObjectTransmitterInteractions";
import { Transmitter } from "../../core/api/transmitters/types";
import PrimeModal from "../../ui-lib/components/PrimeModal/PrimeModal";

export declare type TransmitterInteractionsEventsActions = {
  open: (transmitter: Transmitter) => void;
};

export interface TransmitterInteractionsEventsProps {
  objectId: string;
  onClose?: () => void;
}

export const ObjectTransmitterInteractionsModal = forwardRef<
  TransmitterInteractionsEventsActions,
  TransmitterInteractionsEventsProps
>((props: TransmitterInteractionsEventsProps, ref) => {
  const { onClose, objectId } = props;
  const { t } = useTranslation();

  const currentTransmitter = useRef<Transmitter>();

  const [isTransmitterEventsOpen, setIsTransmitterEventsOpen] = useState(false);

  const closeModal = () => {
    setIsTransmitterEventsOpen(false);
    currentTransmitter.current = undefined;
    if (onClose !== undefined) {
      onClose();
    }
  };

  useImperativeHandle<
    TransmitterInteractionsEventsActions,
    TransmitterInteractionsEventsActions
  >(ref, () => {
    const actions: TransmitterInteractionsEventsActions = {
      open: function open(transmitter: Transmitter) {
        setIsTransmitterEventsOpen(true);
        currentTransmitter.current = transmitter;
      },
    };
    return actions;
  });

  return (
    <PrimeModal
      withHeader
      withFooter
      onClose={closeModal}
      isOpen={isTransmitterEventsOpen}
      header={currentTransmitter.current?.productName}
      submitBtn={{
        text: t("Common:close"),
        variant: "secondary",
        onClick: closeModal,
      }}
      contentStyle={{ width: "848px" }}
    >
      <p style={{ color: "var(--Grey-600)", fontSize: "14px" }}>
        {t("Transmitters:transmitter_interactions_description")}
      </p>
      <ObjectTransmitterInteractions
        alarmHistoryParams={{
          user: objectId,
          transmitter: currentTransmitter.current
            ? currentTransmitter.current.id.toString()
            : "",
        }}
      />
    </PrimeModal>
  );
});
