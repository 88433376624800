import { observer } from "mobx-react-lite";
import { notifyError } from "../../../../components/Errors/ErrorAlert";
import { createAlarmCodeRule } from "../../../../core/api/responsecenters/alarmcoderules";
import { ResponseCenterAlarmCodeRuleDetails } from "../../../../core/api/responsecenters/types";
import useTranslations from "../../../../core/i18n/useTranslations";
import useUser from "../../../../core/user/useUser";
import { notify } from "../../../../ui-lib/components/Alerts/Toast";
import { AlarmCodeRuleForm } from "./AlarmCodeRuleForm";

const CreateAlarmCodeRuleModal = ({
  responseCenterId,
  onClose,
  onAdd,
}: {
  responseCenterId: string | number;
  onClose: () => void;
  onAdd: () => void;
}) => {
  const t = useTranslations();
  const { authenticatedRequest } = useUser();

  const labels = {
    title: t("AlarmReceptions:alarm_handling_alarm_code_rule_add"),
    description: t(
      "AlarmReceptions:alarm_handling_alarm_code_rule_add_description"
    ),
    submit: t("AlarmReceptions:alarm_handling_alarm_code_rule_add"),
  };

  const onSubmit = async (values: ResponseCenterAlarmCodeRuleDetails) => {
    try {
      await createAlarmCodeRule(responseCenterId, values, authenticatedRequest);

      notify({
        message: t("AlarmReceptions:alarm_handling_alarm_codes_add_success"),
      });
      onAdd();
    } catch (error: any) {
      notifyError({
        error,
        t,
      });
    }
  };

  return (
    <AlarmCodeRuleForm labels={labels} onSubmit={onSubmit} onClose={onClose} />
  );
};

export default observer(CreateAlarmCodeRuleModal);
