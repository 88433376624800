import { useEffect, useRef, useState } from "react";
import { AxiosInstance, AxiosResponse } from "axios";
import { useTranslation } from "react-i18next";

import { IReceptionSubscription } from "../../../../../core/api/responsecenters/types";
import { Spacer } from "../../../../../components/Layout/Layout";
import {
  EditSubscriptionActions,
  SubscriptionEditModal,
} from "./SubscriptionEditModal";
import {
  getARCSubscriptions,
  removeARCSubscriptionOrg,
  removeARCSubscriptionSeller,
} from "../../../../../core/api/responsecenters/responsecenters";
import { notifyApiErrors } from "../../../../../core/helpers/helpers";
import { notify } from "../../../../../ui-lib/components/Alerts/Toast";
import { notifyError } from "../../../../../components/Errors/ErrorAlert";
import { ITreeData } from "../../../../../ui-lib/components/Hierarchy/Tree";

import BaseModal from "../../../../../ui-lib/components/Modal/Modal";
import Divider from "../../../../../ui-lib/components/Divider/Divider";
import SubscriptionInfoItem from "./SubscriptionInfoItem";
import Button from "../../../../../ui-lib/components/Button/Button";

import styles from "./AlarmSubscriptionDetails.module.css";
import ARCSubscriptionDeleteModal from "./ARCSubscriptionDeleteModal";

export interface IDeleteObjectRef {
  apiFunc: (
    authenticatedRequest: AxiosInstance,
    rcId: number,
    subscriptionId: number,
    removeId: number
  ) => Promise<AxiosResponse<any>>;
  id: number;
  type: "period" | "seller" | "organisation";
}

const AlarmSubscriptionDetails = ({
  arcId,
  authenticatedRequest,
  isAlarmSubscriptionOpen,
  subscription,
  sellers,
  subscriptionEditModalRef,
  organisations,
  setIsAlarmSubscriptionOpen,
  getAllArcSubscriptions,
}: {
  arcId: number;
  authenticatedRequest: AxiosInstance;
  isAlarmSubscriptionOpen: boolean;
  subscription?: React.MutableRefObject<IReceptionSubscription | undefined>;
  sellers?: any;
  subscriptionEditModalRef: React.RefObject<EditSubscriptionActions>;
  organisations?: ITreeData[];
  setIsAlarmSubscriptionOpen: (value: React.SetStateAction<boolean>) => void;
  getAllArcSubscriptions: () => Promise<void>;
}) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [isEditSubscriptionOpen, setIsEditSubscriptionOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [currentSubscription, setCurrentSubscription] = useState<
    IReceptionSubscription | undefined
  >();

  const deleteObj = useRef<IDeleteObjectRef | null>(null);

  const loadData = async () => {
    try {
      const result = await getARCSubscriptions(arcId, authenticatedRequest);
      const updatedSubscription = result.data.find(
        (item) =>
          item.subscriptionGroupId ===
          subscription?.current?.subscriptionGroupId
      );
      setCurrentSubscription(updatedSubscription);
    } catch (e: any) {
      notifyApiErrors(e.response?.data?.errors);
    }
  };

  const removeHandler = async (
    apiFunc: (
      authenticatedRequest: AxiosInstance,
      rcId: number,
      subscriptionId: number,
      removeId: number
    ) => Promise<AxiosResponse<any>>,
    id: number
  ) => {
    setLoading(true);
    try {
      await apiFunc(
        authenticatedRequest,
        currentSubscription!.subscriptionGroupId,
        arcId,
        id
      );
      notify({ message: t("AlarmReceptions:subscriptions_updated") });
      await loadData();
    } catch (error: any) {
      notifyError({ error, t });
    } finally {
      setIsDirty(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!currentSubscription) {
      setCurrentSubscription(subscription?.current);
    }
  }, [isAlarmSubscriptionOpen]);

  return (
    <>
      <BaseModal
        modalTitle={t("AlarmReceptions:edit_subscriptions")}
        cancelBtnText={t("Common:close")}
        isOpen={isAlarmSubscriptionOpen}
        onCancelBtnClick={() => {
          setIsAlarmSubscriptionOpen(false);
        }}
        onClose={() => {
          setIsAlarmSubscriptionOpen(false);
          setCurrentSubscription(undefined);
          deleteObj.current = null;
          if (isDirty) getAllArcSubscriptions();
        }}
        closeOnDocumentClick={false}
        width="784px"
      >
        <p>{t("AlarmReceptions:subscription_details_description")}</p>
        <Spacer size={32} />

        <h3>{t("Common:name")}</h3>
        <Spacer size={16} />
        <SubscriptionInfoItem
          text={currentSubscription?.description}
          iconName="pencil"
          onClick={() =>
            subscriptionEditModalRef.current?.open(arcId, "description")
          }
        />

        <Spacer size={32} />
        <Divider />
        <Spacer size={32} />

        <div className={styles.subHeader}>
          <h3>{t("Common:seller")}</h3>
          {sellers.length !== currentSubscription?.sellerAccess.length && (
            <Button
              image="star"
              text={t("Common:add_seller")}
              variant="secondary"
              onClick={() => {
                subscriptionEditModalRef.current?.open(arcId, "sellerId");
              }}
            />
          )}
        </div>
        {currentSubscription?.sellerAccess.map((seller) => (
          <>
            <Spacer size={16} />
            <SubscriptionInfoItem
              key={seller.sellerId}
              text={seller.name}
              iconName="trash"
              maxWidth="100%"
              onClick={() => {
                deleteObj.current = {
                  apiFunc: removeARCSubscriptionSeller,
                  id: seller.sellerId,
                  type: "seller",
                };
                setDeleteModalOpen(true);
              }}
            />
          </>
        ))}

        <Spacer size={32} />
        <Divider />
        <Spacer size={32} />

        <div className={styles.subHeader}>
          <h3>{t("Menu:organizations")}</h3>
          <Button
            image="office-building"
            text={t("Common:add_organization")}
            variant="secondary"
            onClick={() =>
              subscriptionEditModalRef.current?.open(arcId, "orgId")
            }
          />
        </div>
        {currentSubscription?.organizationAccess.map((org) => (
          <>
            <Spacer key={`${org.organizationId}_spacer`} size={16} />
            <SubscriptionInfoItem
              key={org.organizationId}
              text={org.name}
              iconName="trash"
              maxWidth="100%"
              onClick={() => {
                deleteObj.current = {
                  apiFunc: removeARCSubscriptionOrg,
                  id: org.organizationId,
                  type: "organisation",
                };
                setDeleteModalOpen(true);
              }}
            />
          </>
        ))}
      </BaseModal>

      <SubscriptionEditModal
        ref={subscriptionEditModalRef}
        subscription={currentSubscription}
        sellers={sellers}
        isEditSubscriptionOpen={isEditSubscriptionOpen}
        setIsEditSubscriptionOpen={setIsEditSubscriptionOpen}
        onSibmit={() => loadData()}
        organisations={organisations}
        setIsDirty={setIsDirty}
      />
      {deleteObj.current && (
        <ARCSubscriptionDeleteModal
          isOpen={deleteModalOpen}
          setDeleteModalOpen={setDeleteModalOpen}
          removeHandler={removeHandler}
          deleteData={deleteObj}
          loadData={loadData}
          isLoading={loading}
        />
      )}
    </>
  );
};

export default AlarmSubscriptionDetails;
