import { FilterData } from "../../core/api/common_table";
import { Filter } from "../components/Tables/CustomFilters";
import { searchStringParser } from "./urlSearchParser";

const conditions = new Map([
  [
    "DATETIME",
    [
      { id: 11, name: "Between", isSelected: false },
      { id: 12, name: "At Date", isSelected: false },
    ],
  ],
  [
    "LONG",
    [
      { id: 21, name: "Greater than", isSelected: false },
      { id: 22, name: "Less than", isSelected: false },
      { id: 23, name: "Equals to", isSelected: false },
    ],
  ],
  [
    "STRING",
    [
      { id: 31, name: "Is", isSelected: false },
      { id: 32, name: "Contains", isSelected: false },
    ],
  ],
]);

export const filterListToMap = (filters: FilterData[]) => {
  const resultMap = new Map();
  filters.forEach((el) => {
    const newId = Math.random().toString(36).substr(2, 9);
    const conditionsByType = conditions.get("STRING");

    resultMap.set(newId, {
      id: newId,
      columnId: el.id,
      filterConditions: conditionsByType && conditionsByType[0],
      value:
        typeof el.value === "string" ? searchStringParser(el.value) : el.value,
    });
  });

  return resultMap;
};

export const mapToFilterList = (data: Map<string, Filter>) => {
  const filtersResult: FilterData[] = [];
  data.forEach((el) => {
    if (el.value && el.columnId) {
      filtersResult.push({
        id: el.columnId,
        value: el.value,
      });
    }
  });

  return filtersResult;
};

export const searchStringToFilterMap = (urlString: string) => {
  if (urlString?.includes("filterType") && urlString?.includes("filterValue")) {
    const filterData = [] as FilterData[];
    urlString
      ?.split("?")[1]
      .split("&")
      .forEach((dataString) => {
        const [name, value] = dataString.split("=");
        if (name === "filterType") {
          filterData.push({
            id: parseInt(value, 10),
            value: "",
          });
        } else if (name === "filterValue") {
          filterData[filterData.length - 1].value = value;
        }
      });
    return filterData;
  }
  return [];
};

export const validateFilterList = (data: Map<string, Filter>) => {
  const updatedMap = new Map();
  let hasErrors = false;

  data.forEach((el) => {
    el.errors = [];
    if (!el.value) el.errors.push("value");
    if (!el.columnId) el.errors.push("columnName");
    if (el.errors.length) hasErrors = true;

    updatedMap.set(el.id, el);
  });

  return { updatedFilters: updatedMap, hasErrors };
};
