import { RespondentGroup } from "../responsecenters/types";

export interface RoleTemplate {
  id: number;
  name: string;
  type: string;
  sellerId: number;
  seller: string;
  organizationId?: number;
  organization: string;
  responseCenterId?: number;
  responseCenter: string;
  belongsTo: string;
  hierarchyUsage: HierarchyUsage;
}

export interface NewRoleTemplate {
  name: string;
  hierarchyUsage: HierarchyUsage;
  organizationId?: number;
  responseCenterId?: number;
  respondentGroups: RespondentGroup[];
  roles: string[];
  primaryLanguage?: string;
  secondaryLanguage?: string;
  timezoneId?: number;
  dateTimeFormatId?: number;
  notifyIfNotLoggedIn?: number;
  readOnly?: boolean;
  type: string;
}

export enum HierarchyUsage {
  None = 0,
  ThisOrganization = 1,
  OneBellow = 2,
  AllBellow = 3,
}
