import {
  Control,
  Controller,
  FieldError,
  UseFormGetValues,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Row, Spacer } from "../../../../components/Layout/Layout";
import {
  validateEmail,
  validateEmailIfExists,
  validateEmergencyPhoneNumber,
  validateEmergencyPhoneNumberIfExists,
  validatePhoneNumber,
  validatePhoneNumberIfExists,
} from "../../../../core/helpers/validation";
import { ContactDetails } from "../../../../core/api/contacts/types";

import TextInput from "../../../../ui-lib/components/Inputs/TextInput";
import Checkbox from "../../../../ui-lib/components/Checkbox/Checkbox";
import useUser from "../../../../core/user/useUser";

const ContactInfoForm = ({
  control,
  errors,
  hasShowOnlyBasicContactInfoPermission,
  watch,
  getValues,
  setValue,
  trigger,
}: {
  control: Control<ContactDetails, any>;
  errors: any;
  hasShowOnlyBasicContactInfoPermission?: boolean;
  watch: UseFormWatch<ContactDetails>;
  getValues: UseFormGetValues<ContactDetails>;
  setValue: UseFormSetValue<ContactDetails>;
  trigger: UseFormTrigger<ContactDetails>;
}) => {
  const { t } = useTranslation();
  const { config } = useUser();
  const curenaProducts = config?.show.includes("CurenaProducts");

  const validateOptionalTelephone = (number: string) =>
    validatePhoneNumberIfExists(number) ||
    validateEmergencyPhoneNumberIfExists(number);

  const getErrorMessage = (error?: FieldError) => {
    if (error === undefined) return "";

    if (error.type === "required" && error.ref?.name === "email") {
      return t("AlarmReceptions:edit_contact_email_required");
    }

    if (error.type === "hasEmailOrTelephone")
      return t("Errors:input_contacts_required_fields");

    if (error.type === "validateEmailIfExists")
      return t("Errors:input_email_format");

    if (
      error.type === "validateTelephone" ||
      error.type === "validateOptionalTelephone"
    )
      return t("Errors:response_invalid_telephone_number_format");

    return "Undefined Error";
  };

  const hasEmailOrTelephone = () => {
    const values = watch(["email", "telephone1"]);

    return !!values.find((value: string, index: number) =>
      index === 0
        ? value && validateEmail(value)
        : value &&
          (validatePhoneNumber(value) || validateEmergencyPhoneNumber(value))
    );
  };

  const renderPhones = () => {
    if (curenaProducts) {
      return (
        <>
          <Row align="start">
            <Controller
              name="telephone1"
              control={control}
              rules={{
                validate: {
                  validateOptionalTelephone,
                  hasEmailOrTelephone,
                },
              }}
              render={({ field }) => (
                <TextInput
                  label={t("Administrator:telephone")}
                  placeholder={t("Common:input_phone_description")}
                  value={field.value}
                  onChange={(e) => {
                    if (!e.currentTarget.value.length) {
                      setValue("excludeTelephone1", false);
                    }
                    field.onChange(e);
                    trigger("email");
                  }}
                  validationError={getErrorMessage(errors.telephone1)}
                />
              )}
            />
            <Spacer size={32} />
            <Controller
              name="excludeTelephone1"
              control={control}
              render={({ field }) => (
                <Checkbox
                  label={`${t("Contacts:exclude_phone_number")} 1`}
                  checked={!!field.value}
                  onChange={field.onChange}
                  style={{ padding: "32px 0 0 0", width: "100%" }}
                  disabled={!watch("telephone1")}
                />
              )}
            />
          </Row>

          <Spacer size={16} />

          <Row align="start">
            <Controller
              name="telephone2"
              control={control}
              rules={{ validate: { validateOptionalTelephone } }}
              render={({ field }) => (
                <TextInput
                  label={`${t("Administrator:telephone")} 2`}
                  placeholder={t("Common:input_phone_description")}
                  value={field.value}
                  onChange={(e) => {
                    if (!e.currentTarget.value.length) {
                      setValue("excludeTelephone2", false);
                    }
                    field.onChange(e);
                  }}
                  validationError={getErrorMessage(errors.telephone2)}
                />
              )}
            />
            <Spacer size={32} />
            <Controller
              name="excludeTelephone2"
              control={control}
              render={({ field }) => (
                <Checkbox
                  label={`${t("Contacts:exclude_phone_number")} 2`}
                  checked={!!field.value}
                  onChange={field.onChange}
                  style={{ padding: "32px 0 0 0", width: "100%" }}
                  disabled={!watch("telephone2")}
                />
              )}
            />
          </Row>

          <Spacer size={16} />

          <Row align="start">
            <Controller
              name="telephone3"
              control={control}
              rules={{ validate: { validateOptionalTelephone } }}
              render={({ field }) => (
                <TextInput
                  label={`${t("Administrator:telephone")} 3`}
                  placeholder={t("Common:input_phone_description")}
                  value={field.value}
                  onChange={(e) => {
                    if (!e.currentTarget.value.length) {
                      setValue("excludeTelephone3", false);
                    }
                    field.onChange(e);
                  }}
                  validationError={getErrorMessage(errors.telephone3)}
                />
              )}
            />
            <Spacer size={32} />
            <Controller
              name="excludeTelephone3"
              control={control}
              render={({ field }) => (
                <Checkbox
                  label={`${t("Contacts:exclude_phone_number")} 3`}
                  checked={!!field.value}
                  onChange={field.onChange}
                  style={{ padding: "32px 0 0 0", width: "100%" }}
                  disabled={!watch("telephone3")}
                />
              )}
            />
          </Row>
          {!hasShowOnlyBasicContactInfoPermission && (
            <>
              <Spacer size={16} />
              <Controller
                name="pin"
                control={control}
                render={({ field }) => (
                  <TextInput
                    label={t("Common:pin")}
                    description={t("Contacts:pin_input_description")}
                    placeholder={t("Common:placeholder_pin")}
                    value={field.value}
                    onChange={field.onChange}
                    css={{ width: "48%" }}
                  />
                )}
              />
            </>
          )}
        </>
      );
    }
    return (
      <>
        <Row align="start">
          <Controller
            name="telephone1"
            control={control}
            rules={{
              validate: {
                validateOptionalTelephone,
                hasEmailOrTelephone,
              },
            }}
            render={({ field }) => (
              <TextInput
                label={t("Administrator:telephone")}
                placeholder={t("Common:input_phone_description")}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e);
                  trigger("email");
                }}
                validationError={getErrorMessage(errors.telephone1)}
              />
            )}
          />
          <Spacer size={32} />
          <Controller
            name="telephone2"
            control={control}
            rules={{ validate: { validateOptionalTelephone } }}
            render={({ field }) => (
              <TextInput
                label={`${t("Administrator:telephone")} 2`}
                placeholder={t("Common:input_phone_description")}
                value={field.value}
                onChange={field.onChange}
                validationError={getErrorMessage(errors.telephone2)}
              />
            )}
          />
        </Row>

        <Spacer size={16} />

        <Row align="start">
          <Controller
            name="telephone3"
            control={control}
            rules={{ validate: { validateOptionalTelephone } }}
            render={({ field }) => (
              <TextInput
                label={`${t("Administrator:telephone")} 3`}
                placeholder={t("Common:input_phone_description")}
                value={field.value}
                onChange={field.onChange}
                validationError={getErrorMessage(errors.telephone3)}
              />
            )}
          />
          {!hasShowOnlyBasicContactInfoPermission && (
            <>
              <Spacer size={32} />
              <Controller
                name="pin"
                control={control}
                render={({ field }) => (
                  <TextInput
                    label={t("Common:pin")}
                    description={t("Contacts:pin_input_description")}
                    placeholder={t("Common:placeholder_pin")}
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
            </>
          )}
        </Row>
      </>
    );
  };

  return (
    <>
      <Controller
        name="email"
        control={control}
        rules={{
          validate: { validateEmailIfExists, hasEmailOrTelephone },
          required: getValues("callMethod") === 1,
        }}
        render={({ field }) => (
          <TextInput
            required={getValues("callMethod") === 1}
            label={t("Common:email")}
            placeholder={t("Common:placeholder_email")}
            value={field.value}
            onChange={(e) => {
              field.onChange(e);
              trigger("telephone1");
            }}
            validationError={getErrorMessage(errors.email)}
          />
        )}
      />

      <Spacer size={16} />

      {renderPhones()}
    </>
  );
};

export default ContactInfoForm;
