import { AxiosInstance } from "axios";
import API_HOST from "./apiHost";
import useAuthenticatedFetch from "./useAuthenticatedFetch";

export interface AlarmHandling {
  id: number;
  name: string;
}

const useAlarmHandlings = (objectId: string) => {
  const { data, isLoading, isError } = useAuthenticatedFetch<AlarmHandling[]>(
    `${API_HOST}api/v2/users/${objectId}/alarmhandlings`
  );

  return { data, isLoading, isError };
};

const getAlarmHandlings = (
  authenticatedRequest: AxiosInstance,
  objectId: string
) =>
  authenticatedRequest.get<AlarmHandling[]>(
    `${API_HOST}api/v2/users/${objectId}/alarmhandlings`
  );

const saveTemporaryHandling = (
  authenticatedRequest: AxiosInstance,
  objectId: string,
  data: {
    id?: number;
    end?: Date;
  }
) =>
  authenticatedRequest.post(
    `${API_HOST}api/v2/users/${objectId}/temporaryalarmhandling`,
    JSON.stringify(data),
    { headers: { "Content-Type": "application/json" } }
  );

export { useAlarmHandlings, getAlarmHandlings, saveTemporaryHandling };
