import { observer } from "mobx-react-lite";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { Row, Spacer } from "../../components/Layout/Layout";
import { getSellers } from "../../core/api/sellers/sellers";
import { notifyApiErrors } from "../../core/helpers/helpers";
import { IOrganizationProps } from "../../core/api/organizations/types";
import { useQueryState } from "../../core/hooks/filters/useQueryState";
import { AddOrganization } from "./AddOrganization/AddOrganization";
import { OrganizationsTable } from "./OrganizationsTable";
import { getOrganizationsTable } from "../../core/api/organizations/organizations";
import { ITreeData } from "../../ui-lib/components/Hierarchy/Tree";
import { usePageFilter } from "../../core/hooks/filters/usePageFilter";
import { useFiltersByAdminType } from "../../core/hooks/filters/useFiltersByAdminType";
import { IItemProp } from "../../ui-lib/components/Dropdown/DropdownItem";
import { IResellerType } from "../../core/api/sellers/types";
import {
  useStoredTableState,
  storeTableState,
} from "../../core/hooks/filters/useStoredTableState";
import LoadingSpinner from "../../ui-lib/components/Loading/LoadingSpinner";
import {
  searchStringParser,
  urlSearchParser,
} from "../../ui-lib/utils/urlSearchParser";
import useTranslations from "../../core/i18n/useTranslations";
import useUser from "../../core/user/useUser";
import usePageState from "../../core/pagestate/usePageState";
import PageHeader from "../../ui-lib/components/PageHeader/PageHeader";
import SearchInput from "../../ui-lib/components/Inputs/SearchInput";
import Divider from "../../ui-lib/components/Divider/Divider";
import Dropdown from "../../ui-lib/components/Dropdown/Dropdown";

import styles from "../Layout.module.css";

export const PAGE_ID = "adminportal/organizations";

export const billingArray = [0, 1, 2, 3, 6, 9, 12, 18, 24, 36, 48];

export interface RowData {
  id: string;
  value: string;
  children?: ITreeData[];
  root?: ITreeData | null;
  key?: number;
  label?: string;
}

export type SearchData = {
  q: string;
};

const Organizations = () => {
  storeTableState(PAGE_ID);
  const pageState = usePageState();
  const { authenticatedRequest, data: userData, config } = useUser();
  const history = useHistory();
  const t = useTranslations();
  const tableState = useStoredTableState(PAGE_ID);
  const searchState = urlSearchParser(tableState);
  const { pageFilter, setPageFilter, resetPageFilter } =
    usePageFilter(searchState);
  const [searchParam, setSearchParam] = useQueryState<{ q: string }>({
    q: searchStringParser(searchState?.q) || "",
  });
  const { FiltersByAdminType, setFiltersByAdminType } =
    useFiltersByAdminType(searchState);

  const isSuperAdmin = config?.show.includes("SkygdSuperAdmin");

  const [organizationsData, setOrganizationsData] = useState<
    IOrganizationProps[]
  >([]);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [sellers, setSellers] = useState<IItemProp[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchParams = {
    search: searchParam.q,
    includeParent: true,
    page: pageFilter.page,
    pageSize: pageFilter.pageSize,
    seller: FiltersByAdminType?.sellerId,
  };

  const fetchSellers = async () => {
    try {
      const res = await getSellers(authenticatedRequest);
      if (res.data) {
        const sellersItems: IItemProp[] = res.data.map(
          ({ id, name }: IResellerType) => ({
            id,
            name,
            isSelected: false,
          })
        );
        setSellers(sellersItems);
      }
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    }
  };

  const fetchOrganizations = async () => {
    setIsLoading(true);
    try {
      const { data } = await getOrganizationsTable(
        fetchParams,
        authenticatedRequest
      );
      setOrganizationsData(data.organizations);
      setTotalItems(data.total);
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchOrganizations();
    pageState.setInitialIds([] as any);
  }, [
    pageFilter.page,
    pageFilter.pageSize,
    searchParam.q,
    FiltersByAdminType?.sellerId,
  ]);

  useEffect(() => {
    if (isSuperAdmin) {
      fetchSellers();
    }
  }, []);

  return (
    <>
      <PageHeader
        title={t("Menu:organizations")}
        userName={userData?.username}
        icon={pageState.pageIcon}
      >
        <AddOrganization
          variant="primary"
          onSubmit={(organizationId: number) =>
            history.push(`/${PAGE_ID}/${organizationId}`)
          }
          sellers={sellers}
        />
      </PageHeader>
      <>
        <Divider />
        <div className={styles.content}>
          <Row>
            <SearchInput
              limit={1}
              value={searchParam.q}
              placeholder={t("Common:search_placeholder")}
              onChange={(val) => {
                if (searchParam.q !== val) {
                  resetPageFilter();
                }
                setSearchParam({ q: val });
              }}
            />
          </Row>
          <Spacer size={16} />
          <Divider />
          {isSuperAdmin && (
            <>
              <Spacer size={16} />
              <Dropdown
                withClearItem
                width={176}
                bodyWidth={380}
                maxVisible={10}
                placeholder={`--${t("Common:seller")}--`}
                items={sellers}
                selectedItem={sellers.find(
                  (seller) =>
                    seller.id.toString() === FiltersByAdminType.sellerId
                )}
                onSelectItem={(item) => {
                  setSellers(
                    sellers.map(({ id, name }) => ({
                      id,
                      name,
                      isSelected: id === item.id,
                    }))
                  );
                  setFiltersByAdminType({
                    sellerId: item.id === -1 ? undefined : item.id.toString(),
                  });
                }}
              />
            </>
          )}
        </div>

        {isLoading ? (
          <LoadingSpinner theme="primary" />
        ) : (
          <OrganizationsTable
            tableName="table-organisations"
            organizationsData={organizationsData}
            uploadNewOrganizations={fetchOrganizations}
            searchParam={searchParam.q}
            pageFilter={pageFilter}
            setPageFilter={setPageFilter}
            items={organizationsData}
            totalItems={totalItems}
          />
        )}
      </>
    </>
  );
};

export default observer(Organizations);
