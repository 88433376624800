import { Controller } from "react-hook-form";
import { Timezone } from "../../core/api/administrators/types";
import Dropdown from "../../ui-lib/components/Dropdown/Dropdown";

export const TimezoneDropdown = ({
  timezones,
  control,
  setValue,
  t,
  disabled = false,
}: {
  timezones: Timezone[];
  control: any;
  setValue: any;
  t: any;
  disabled?: boolean;
}) => (
  <Controller
    name="timezoneId"
    control={control}
    render={({ field }) => {
      const dropdownItems = timezones?.map((timezone) => ({
        id: timezone.id,
        name: t(`Administrator:${timezone.nameTranslationKey}`),
        isSelected: timezone.id === field.value,
      }));

      return (
        <Dropdown
          title={t("Administrator:general_input_timezone")}
          width="100%"
          selectedItem={dropdownItems?.find((i) => i.isSelected)}
          items={dropdownItems}
          onSelectItem={(item) => setValue(field.name, item.id as number)}
          disabled={disabled}
        />
      );
    }}
  />
);
