import { useState, useEffect } from "react";
import { Row, Spacer } from "../../components/Layout/Layout";
import Table from "../../ui-lib/components/Tables/Table";
import TableCell, {
  MissingValueTableCell,
} from "../../ui-lib/components/Tables/TableCell";
import useUser from "../../core/user/useUser";
import { Camera } from "../../core/api/cameras/types";
import { AddCameraModal } from "../Object/AddCameraModal";
import { notifyApiErrors } from "../../core/helpers/helpers";
import useTranslations from "../../core/i18n/useTranslations";
import {
  getCameras,
  removeCameraFromLocation,
  addCameraToLocation,
} from "../../core/api/locations/locations";
import { ITreeData } from "../../ui-lib/components/Hierarchy/Tree";

const LocationCameras = ({
  locationId,
  organisationTree,
}: // customerId,
{
  locationId: string;
  organisationTree?: ITreeData[];
  // customerId: number;
}) => {
  const t = useTranslations();
  const { authenticatedRequest } = useUser();
  const [camerasLoading, setCamerasLoading] = useState<boolean>(false);
  const [cameras, setCameras] = useState<Camera[]>();

  const loadCameras = async (setLoading: boolean = true) => {
    if (setLoading) setCamerasLoading(true);
    try {
      const data = await getCameras(locationId, authenticatedRequest);
      setCameras(data.data);
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    }
    if (setLoading) setCamerasLoading(false);
  };

  const removeCamera = async (cameraId: number) => {
    try {
      setCamerasLoading(true);
      await removeCameraFromLocation(
        locationId,
        cameraId,
        authenticatedRequest
      );
      loadCameras();
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    }
  };
  const loadInitialData = async () => {
    loadCameras(false);
  };
  useEffect(() => {
    loadInitialData();
  }, []);
  return (
    <>
      <Row type="space" align="center">
        <h2>{t("Objects:accessories_cameras_title")}</h2>
        {!cameras?.length && (
          <AddCameraModal
            id={locationId}
            onSubmit={() => {
              loadCameras();
            }}
            apiSaveMethod={addCameraToLocation}
            alreadySelectedCameras={cameras?.map((c) => c.id!) || []}
            addOnSelect
            showAddCamera
            organisationTree={organisationTree}
            fromObjects={false}
          />
        )}
      </Row>
      <Spacer size={16} />
      <Table<Camera>
        columns={[
          {
            header: t("Common:name"),
            fieldTemplate: (rowData) => <TableCell value={rowData.title} />,
          },
          {
            header: t("Cameras:table_columns_description"),
            fieldTemplate: (rowData) => (
              <MissingValueTableCell
                value={rowData.description}
                missingValueText=""
              />
            ),
          },
          {
            header: t("Common:organisation"),
            fieldTemplate: (rowData) => (
              <TableCell
                value={rowData.organizationName}
                iconColor="Grey-300"
                leftIcon="office-building"
              />
            ),
          },
          {
            header: t("Common:type"),
            fieldTemplate: (rowData) => (
              <TableCell
                value={t(`Common:CameraType_${rowData.cameraType}`)}
                iconColor="Grey-300"
                leftIcon="video-camera"
              />
            ),
          },
        ]}
        items={cameras}
        rowActions={[
          {
            icon: "x",
            text: t("Objects:remove_from"),
            onClick: (rowData) => {
              if (!camerasLoading) {
                removeCamera(rowData.id!);
              }
            },
          },
        ]}
        rowActionsFixed
        rowActionsColWidth={45}
        // hideHeader
        showRowHover
        hideEmptyMessage
        noRowsMessage={t("Objects:accessories_no_cameras")}
        isLoading={camerasLoading}
      />
    </>
  );
};
export default LocationCameras;
