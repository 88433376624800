import { Column, Spacer } from "../../../../components/Layout/Layout";
import { AdministratorDetails } from "../../../../core/api/administrators/types";
import { notifyApiErrors } from "../../../../core/helpers/helpers";
import useTranslations from "../../../../core/i18n/useTranslations";
import { notify } from "../../../../ui-lib/components/Alerts/Toast";
import useSharedAdministrator from "../SharedAdministratorContext/useSharedAdministrator";
import { RespondentGroupForm } from "./RespondentGroupForm";

const RespondentGroups = () => {
  const t = useTranslations();
  const { data, update, reload } = useSharedAdministrator();

  const onSubmit = async (values: AdministratorDetails) => {
    try {
      await update(
        values,
        data?.twoFactorAuthenticationType,
        data?.twoFactorAuthenticationData
      );

      notify({
        message: t("Administrator:edited_success"),
      });
      await reload();
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    }
  };

  return (
    <Column style={{ width: "784px" }} type="top" align="start">
      <h1>{t("Administrator:groups_title")}</h1>
      <Spacer size={8} />
      <p style={{ color: "var(--Grey-600)" }}>
        {t("Administrator:permissions_respondent_group_description")}
      </p>

      <Spacer size={32} />

      <RespondentGroupForm
        defaultValues={{ ...(data as AdministratorDetails) }}
        onSubmit={onSubmit}
      />
    </Column>
  );
};

export default RespondentGroups;
