import { useMemo, useRef, useState } from "react";
import classnames from "classnames";
import { useHistory } from "react-router-dom";
import { Row } from "../../components/Layout/Layout";
import { ResponseCenterResponse } from "../../core/api/responsecenters/types";
import useTranslations from "../../core/i18n/useTranslations";
import Checkbox from "../../ui-lib/components/Checkbox/Checkbox";
import Table, { SortOrderParams } from "../../ui-lib/components/Tables/Table";
import TableCell from "../../ui-lib/components/Tables/TableCell";
import useUser from "../../core/user/useUser";
import { isOrganizationVisible } from "../../ui-lib/utils/organizationsHelper";
import Icon from "../../ui-lib/components/Icon";
import { getOrganizationTrees } from "../../core/api/organizations/organizations";
import { transformOrganizationsToTreeData } from "../../core/utils/organizations";
import { RowData } from "../Organizations/Organizations";
import ChildrenTreeModal from "../../ui-lib/components/ChildrenTreePopup/childrenTreeModal";
import Tooltip from "../../ui-lib/components/Tooltip/Tooltip";

import styles from "../../ui-lib/components/Tables/Table.module.css";
import { notifyApiErrors } from "../../core/helpers/helpers";

export const AlarmReceptionsTable = ({
  data,
  isLoading,
  pageSettings,
  onPageSettingsChange,
  order,
  setOrder,
  onStartCopy,
  onStartDelete,
  organizationId: customerId,
}: {
  data: ResponseCenterResponse[];
  isLoading: boolean;
  pageSettings: {
    page: number;
    pageSize: number;
  };
  onPageSettingsChange: (args: { page: number; pageSize: number }) => void;
  order: string;
  setOrder: (order: string) => void;
  onStartCopy: (responseCenter: ResponseCenterResponse) => void;
  onStartDelete: (responseCenter: ResponseCenterResponse) => void;
  organizationId?: number;
}) => {
  const t = useTranslations();
  const history = useHistory();
  const user = useUser();
  const childrenData = useRef<RowData>();
  const [childrenTreeModalOpen, setChildrenTreeModalOpen] =
    useState<boolean>(false);

  const sortParams: SortOrderParams | undefined = useMemo(() => {
    switch (order) {
      case "name_desc":
        return { field: "name", sortOrder: -1 };
      case "name":
      default:
        return { field: "name", sortOrder: 1 };
    }
  }, [order]);

  const onSortChange = (args?: SortOrderParams) => {
    switch (args?.sortOrder) {
      case -1:
        setOrder("name_desc");
        break;
      case 1:
      default:
        setOrder("name");
        break;
    }
  };

  const showMore = data.length === pageSettings.pageSize * pageSettings.page;

  const rowActions: any[] = [
    {
      icon: "pencil-alt",
      text: t("Common:label_edit"),
      onClick: ({ id }: { id: number | undefined }) => {
        sessionStorage.removeItem("prevPath");
        history.push(`/adminportal/alarm-receptions/${id}`);
      },
    },
    {
      icon: "document-add",
      text: t("AlarmReceptions:input_copy"),
      onClick: (rowData: ResponseCenterResponse) => onStartCopy(rowData),
    },
  ];
  if (user.config?.show.includes("RcDelete")) {
    rowActions.push({
      icon: "trash",
      text: t("Common:delete"),
      iconVariant: "secondary",
      onClick: (rowData: ResponseCenterResponse) => onStartDelete(rowData),
    });
  }

  return (
    <>
      <ChildrenTreeModal
        organizations={childrenData.current as RowData}
        isOpen={childrenTreeModalOpen}
        onClose={() => {
          setChildrenTreeModalOpen(false);
        }}
      />
      <Table<ResponseCenterResponse>
        // UI settings
        tableName={customerId ? undefined : "table-alarmReceptions"}
        tableTexts={{
          apply: t("Table:apply"),
          cancel: t("Table:cancel"),
          tableData: t("Table:table_data"),
          rowsPerPage: t("Table:rows_per_page"),
          showing: t("Table:showing"),
          of: t("Table:of"),
          results: t("Table:results"),
          showMore: t("Table:show_more"),
        }}
        items={data}
        isLoading={isLoading}
        hideEmptyMessage
        columns={[
          {
            key: "name",
            header: t("Common:name"),
            fieldTemplate: (rowData) => (
              <TableCell
                value={rowData.name}
                linkTo={`alarm-receptions/${rowData.id}`}
                onClick={() => {
                  sessionStorage.removeItem("prevPath");
                }}
                fromDashboard
              />
            ),
            sortByField: "name",
          },
          {
            key: "organisation",
            header: t("AlarmReceptions:table_column_organisation"),
            fieldTemplate: (rowData) => (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                {isOrganizationVisible(user) ? (
                  <>
                    <TableCell
                      value={rowData.organization}
                      linkTo={`organizations/${rowData.organizationId}`}
                    />
                    <Tooltip
                      position="top center"
                      variant="light"
                      text={t("Common:view_hierarchy")}
                      trigger={() => (
                        <button
                          type="button"
                          aria-label="View hierarchy"
                          className={classnames({
                            [styles.tableRowActionButton]: true,
                          })}
                          onClick={async () => {
                            try {
                              const res = await getOrganizationTrees(
                                {
                                  parentId: rowData.organizationId,
                                  getRoot: true,
                                },
                                user.authenticatedRequest
                              );
                              const tree = transformOrganizationsToTreeData(
                                res.data
                              )[0];
                              const tmp: RowData = {
                                ...rowData,
                                children: tree.children || [],
                                root: tree,
                              } as any;
                              childrenData.current = tmp;
                              setChildrenTreeModalOpen(true);
                            } catch (error: any) {
                              notifyApiErrors(error.response?.data?.errors);
                            }
                          }}
                        >
                          <Icon color="Primary-700" name="hierarchy" />
                        </button>
                      )}
                    />
                  </>
                ) : (
                  <p>{rowData.organization}</p>
                )}
              </div>
            ),
          },
          {
            key: "outgoing-calls",
            header: t("AlarmReceptions:table_column_outgoing_calls"),
            fieldTemplate: (rowData) => (
              <Row type="left">
                <Checkbox checked={!rowData.prefersIncommingVoice} disabled />
              </Row>
            ),
          },
          {
            key: "telephone",
            header: t("AlarmReceptions:table_column_telephone"),
            fieldTemplate: (rowData) => (
              <TableCell value={rowData.telephoneNumber ?? ""} />
            ),
          },
          {
            header: t("AlarmReceptions:alarm_handling_table_column_rule_type"),
            fieldTemplate: (rowData) => (
              <TableCell
                value={
                  rowData.public
                    ? t("AlarmReceptions:general_input_type_public")
                    : t("AlarmReceptions:general_input_type_private")
                }
                leftIcon={rowData.public ? "users" : "lock-closed"}
                iconColor="Grey-300"
              />
            ),
          },
        ]}
        rowActionsFixed
        rowActionsColWidth={110}
        rowActions={rowActions}
        sortParams={sortParams}
        onSortChange={onSortChange}
        showRowHover
        withShowMore
        withLazyLoading
        paginatedItems={{
          items: data,
          pagination: {
            offset: 0,
            limit: data.length,
            total: data.length + (showMore ? 1 : 0), // To control show more button visibility
          },
        }}
        onShowMoreClick={() =>
          onPageSettingsChange({
            page: pageSettings.page + 1,
            pageSize: pageSettings.pageSize,
          })
        }
      />
    </>
  );
};
