import { ReactNode } from "react";
import styles from "./Input.module.css";
import Icon from "../Icon";

export const InputContainer = ({
  label,
  input,
  style,
  required,
  validationError,
}: {
  label: ReactNode;
  input: ReactNode;
  style?: React.CSSProperties;
  required?: boolean;
  validationError?: string;
}) => (
  <div
    className={styles.inputContainer}
    style={{ alignSelf: "stretch", ...style }}
  >
    <div className={styles.labelRow}>
      {required && <span className={styles.requiredIcon}>*</span>}
      {label}
    </div>
    {input}
    {validationError ? (
      <div className={styles.validationError}>
        <Icon name="exclamation-circle" size={14} />
        {validationError}
      </div>
    ) : null}
  </div>
);
export default InputContainer;
