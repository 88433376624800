import { AxiosInstance } from "axios";
import queryString from "query-string";
import _ from "lodash";
import querystring from "query-string";
import { ApiResponse } from "../../interfaces/Api";
import API_HOST from "../apiHost";
import useAuthenticatedFetch from "../useAuthenticatedFetch";
import {
  EditAlarmReceptionContactData,
  AlarmHandlingDetails,
  AlarmHandlingDetailsParams,
  NewAlarmHandlingParams,
  NewResponseCenterParams,
  RespondentGroupResponse,
  ResponseCenterDetails,
  ResponseCenterDetailsParams,
  ResponseCenterDistributionResponse,
  ResponseCenterDistributionParams,
  ResponseCenterDistributionDetails,
  EmergencyCenterSetting,
  EditEmergencyCenterSettingData,
  AlarmReceptionIntegration,
  EditAlarmReceptionIntegrationData,
  ResponseCenterParams,
  ResponseCenterResponse,
  IReceptionGroup,
  IReceptionCreatedGroup,
  ILinkedRespondent,
  AlarmReceptionAdvancedSettings,
  IAlarmReceptionContact,
  IReceptionSubscription,
  INewReceptionSubscription,
  IArcGroups,
} from "./types";
import { IpWhiteList } from "../organizations/types";

const useResponseCenters = (
  params: ResponseCenterParams = { order: "name" }
) => {
  const settings = queryString.stringify({
    includeSubCustomers: true,
    publicOnly: "false",
    privateOnly: "false",
    ...params,
  });

  return useAuthenticatedFetch<ResponseCenterResponse[]>(
    `${API_HOST}api/v2/responsecenters?${settings}`
  );
};

const getResponseCenters = (
  params: ResponseCenterParams = { order: "name" },
  authenticatedRequest: AxiosInstance
) => {
  const settings = queryString.stringify({
    includeSubCustomers: true,
    publicOnly: false,
    privateOnly: false,
    ...params,
  });

  return authenticatedRequest.get<ResponseCenterResponse[]>(
    `${API_HOST}api/v2/responsecenters?${settings}`
  );
};

const createResponseCenter = async (
  params: NewResponseCenterParams,
  authenticatedRequest: AxiosInstance
) => {
  const requestData = JSON.stringify(params);

  return authenticatedRequest.post<{}>(
    `${API_HOST}api/v2/responsecenters`,
    requestData,
    { headers: { "Content-Type": "application/json" } }
  );
};

const deleteResponseCenter = async (
  responseCenterId: number,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.delete<{}>(
    `${API_HOST}api/v2/responsecenters/${responseCenterId}`
  );

const getResponseCenter = async (
  id: string | number,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.get<ResponseCenterDetails>(
    `${API_HOST}api/v2/responsecenters/${id}`
  );

const getAdvancedSettings = async (
  id: string,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.get<AlarmReceptionAdvancedSettings>(
    `${API_HOST}api/v2/responsecenters/${id}/advancedsettings`
  );

const updateResponseCenter = async (
  id: string,
  responseCenterDetails: ResponseCenterDetailsParams,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.put(
    `${API_HOST}api/v2/responsecenters/${id}`,
    JSON.stringify(responseCenterDetails),
    {
      headers: { "Content-Type": "application/json" },
    }
  );

// Alarm handlings
const useRespondentGroups = (id: string | number) =>
  useAuthenticatedFetch<RespondentGroupResponse[]>(
    `${API_HOST}api/v2/responsecenters/${id}/respondentgroups?external=true&pageSize=10000`
  );

const getRespondentGroups = (
  id: string | number,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest
    .get<RespondentGroupResponse[]>(
      `${API_HOST}api/v2/responsecenters/${id}/respondentgroups?external=false&pageSize=1000`
    )
    .then((response) =>
      response.data?.map((r) => ({
        id: r.id,
        description: r.description,
        allowed: r.allowed,
        forced: r.forced,
      }))
    );

const useAlarmHandling = (
  responseCenterId: string | number,
  alarmHandlingId: string | number
) =>
  useAuthenticatedFetch<AlarmHandlingDetails>(
    `${API_HOST}api/v2/responsecenters/${responseCenterId}/alarmhandling/${alarmHandlingId}`
  );

const createAlarmHandling = async (
  id: string | number,
  params: NewAlarmHandlingParams,
  authenticatedRequest: AxiosInstance
) => {
  const requestData = JSON.stringify(params);

  return authenticatedRequest.post<{}>(
    `${API_HOST}api/v2/responsecenters/${id}/alarmhandling`,
    requestData,
    { headers: { "Content-Type": "application/json" } }
  );
};

const updateAlarmHandling = async (
  responseCenterId: string | number,
  alarmHandlingId: string | number,
  params: AlarmHandlingDetailsParams,
  authenticatedRequest: AxiosInstance
) => {
  const requestData = JSON.stringify(params);

  return authenticatedRequest.put<{}>(
    `${API_HOST}api/v2/responsecenters/${responseCenterId}/alarmhandling/${alarmHandlingId}`,
    requestData,
    { headers: { "Content-Type": "application/json" } }
  );
};

const deleteAlarmHandling = async (
  responseCenterId: string | number,
  alarmHandlingId: string | number,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.delete(
    `${API_HOST}api/v2/responsecenters/${responseCenterId}/alarmhandling/${alarmHandlingId}`
  );

// Response center contacts
const useResponseCenterDistributions = (id: string | number) =>
  useAuthenticatedFetch<ResponseCenterDistributionResponse[]>(
    `${API_HOST}api/v2/responsecenters/${id}/distributions`
  );

const getResponseCenterDistributions = async (
  id: string | number,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.get<ResponseCenterDistributionResponse[]>(
    `${API_HOST}api/v2/responsecenters/${id}/distributions`
  );

const useResponseCenterDistribution = (
  responseCenterId: string | number,
  responseCenterDistributionId: string | number
) =>
  useAuthenticatedFetch<ResponseCenterDistributionDetails>(
    `${API_HOST}api/v2/responsecenters/${responseCenterId}/distributions/${responseCenterDistributionId}`
  );

const createResponseCenterDistribution = async (
  id: string | number,
  params: ResponseCenterDistributionParams,
  authenticatedRequest: AxiosInstance
) => {
  const requestData = JSON.stringify(params);

  return authenticatedRequest.post<{}>(
    `${API_HOST}api/v2/responsecenters/${id}/distributions`,
    requestData,
    { headers: { "Content-Type": "application/json" } }
  );
};

const updateResponseCenterDistribution = async (
  responseCenterId: string | number,
  responseCenterDistributionId: string | number,
  params: ResponseCenterDistributionParams,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.put<{
    data: {
      success: boolean;
      changedAttributes: ResponseCenterDetailsParams;
    };
  }>(
    `${API_HOST}api/v2/responsecenters/${responseCenterId}/distributions/${responseCenterDistributionId}`,
    JSON.stringify(params),
    {
      headers: { "Content-Type": "application/json" },
    }
  );

const deleteResponseCenterDistribution = async (
  responseCenterId: string | number,
  responseCenterDistributionId: string | number,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.delete(
    `${API_HOST}api/v2/responsecenters/${responseCenterId}/distributions/${responseCenterDistributionId}`
  );
const getEmergencyCenterSettings = async (
  id: string | number,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.get<EmergencyCenterSetting[]>(
    `${API_HOST}api/v2/responsecenter/${id}/settings`
  );
const editEmergencyCenterSetting = async (
  alarmReceptionId: string | number,
  saveData: EmergencyCenterSetting,
  authenticatedRequest: AxiosInstance
) => {
  const parsedData = queryString.stringify(_.omit(saveData, "id"));
  const method = saveData.id !== undefined ? "put" : "post";
  const path = saveData.id !== undefined ? `/${saveData.id}` : "";
  const result = await authenticatedRequest[method]<
    ApiResponse<EditEmergencyCenterSettingData>
  >(
    `${API_HOST}api/v2/responsecenter/${alarmReceptionId}/settings${path}?${parsedData}`,
    {
      headers: { "Content-Type": "application/json" },
    }
  );
  return result;
};
const deleteEmergencyCenterSetting = async (
  alarmReceptionId: string | number,
  id: string | number,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.delete<ApiResponse<EditEmergencyCenterSettingData>>(
    `${API_HOST}api/v2/responsecenter/${alarmReceptionId}/settings/${id}`
  );
const getAlarmReceptionIntegrations = async (
  alarmReceptionId: string | number,
  authenticatedRequest: AxiosInstance,
  settings: {
    page: number;
    pageSize: number;
  }
) => {
  const params = queryString.stringify({ ...settings });
  return authenticatedRequest.get<AlarmReceptionIntegration[]>(
    `${API_HOST}api/v2/responseCenters/${alarmReceptionId}/interactions?${params}`
  );
};
const getAlarmReceptionIntegration = async (
  alarmReceptionId: string | number,
  integrationId: string | number,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.get<AlarmReceptionIntegration>(
    `${API_HOST}api/v2/responseCenters/${alarmReceptionId}/interactions/${integrationId}`
  );
const editAlarmReceptionIntegration = async (
  alarmReceptionId: string | number,
  saveData: AlarmReceptionIntegration,
  authenticatedRequest: AxiosInstance
) => {
  const parsedData = JSON.stringify({
    ...saveData,
  });
  const method = saveData.id !== undefined ? "put" : "post";
  const path = saveData.id !== undefined ? `/${saveData.id}` : "";
  const result = await authenticatedRequest[method]<
    ApiResponse<EditAlarmReceptionIntegrationData>
  >(
    `${API_HOST}api/v2/responsecenters/${alarmReceptionId}/interactions${path}`,
    parsedData,
    {
      headers: { "Content-Type": "application/json" },
    }
  );
  return result;
};
const deleteAlarmReceptionIntegration = async (
  alarmReceptionId: string | number,
  id: string | number,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.delete<ApiResponse<EditAlarmReceptionIntegrationData>>(
    `${API_HOST}api/v2/responsecenters/${alarmReceptionId}/interactions/${id}`
  );

const getAlarmReceptionIPWhiteList = async (
  alarmReceptionId: string | number,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.get<IpWhiteList>(
    `${API_HOST}api/v2/responseCenters/${alarmReceptionId}/ipfilter`
  );

const editAlarmReceptionIPWhiteList = async (
  alarmReceptionId: string | number,
  saveData: IpWhiteList,
  authenticatedRequest: AxiosInstance
) => {
  const parsedData = JSON.stringify({
    ...saveData,
  });
  const result = await authenticatedRequest.put<
    ApiResponse<EditAlarmReceptionIntegrationData>
  >(
    `${API_HOST}api/v2/responsecenters/${alarmReceptionId}/ipfilter`,
    parsedData,
    {
      headers: { "Content-Type": "application/json" },
    }
  );
  return result;
};

const getAlarmReceptionContacts = async (
  alarmReceptionId: string | number,
  authenticatedRequest: AxiosInstance,
  settings: { page: number; PageSize: number }
) =>
  authenticatedRequest.get<IAlarmReceptionContact[]>(
    `${API_HOST}api/v2/responsecenters/${alarmReceptionId}/contacts?${querystring.stringify(
      { ...settings }
    )}`
  );
const getAlarmReceptionContact = async (
  alarmReceptionId: string | number,
  contactId: string | number,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.get<IAlarmReceptionContact>(
    `${API_HOST}api/v2/responsecenters/${alarmReceptionId}/contacts/${contactId}`
  );

const editAlarmReceptionContact = async (
  alarmReceptionId: string | number,
  saveData: IAlarmReceptionContact,
  authenticatedRequest: AxiosInstance
) => {
  const parsedData = JSON.stringify({
    ...saveData,
    responseCenterId: alarmReceptionId,
  });
  const method = saveData.id !== undefined ? "put" : "post";
  const path = saveData.id !== undefined ? `/${saveData.id}` : "";
  const result = await authenticatedRequest[method]<
    ApiResponse<EditAlarmReceptionContactData>
  >(`${API_HOST}api/v2/contacts${path}`, parsedData, {
    headers: { "Content-Type": "application/json" },
  });
  return result;
};

const reorderARCContacts = async (
  arcId: number | string,
  data: {
    contactId: number;
    priority: number;
  }[],
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.put(
    `${API_HOST}api/v2/responsecenters/${arcId}/contacts/order`,
    JSON.stringify({ contactPriorities: data }),
    {
      headers: { "Content-Type": "application/json" },
    }
  );

const deleteAlarmReceptionContact = async (
  alarmReceptionId: string | number,
  id: string | number,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.delete<ApiResponse<EditAlarmReceptionIntegrationData>>(
    `${API_HOST}api/v2/responsecenters/${alarmReceptionId}/contacts/${id}`
  );

const getRespondentGroupsList = async (
  arcId: number | string,
  query: {
    page?: number;
    pageSize?: number;
    external?: boolean;
    search?: string;
  },
  authenticatedRequest: AxiosInstance
) => {
  const parsedQuery = querystring.stringify({ ...query });
  const result = await authenticatedRequest.get<IArcGroups>(
    `${API_HOST}api/v2/responsecenters/${arcId}/respondentgroups/table?${parsedQuery}`
  );
  return result.data;
};

const getRespondentGroupDetails = async (
  arcId: number,
  groupId: number,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.get<IReceptionCreatedGroup>(
    `${API_HOST}api/v2/responsecenters/${arcId}/respondentgroups/${groupId}`
  );

const createRespondentGroup = async (
  arcId: number | string,
  data: IReceptionGroup,
  authenticatedRequest: AxiosInstance,
  groupId?: number
) =>
  authenticatedRequest[groupId ? "put" : "post"](
    `${API_HOST}api/v2/responsecenters/${arcId}/respondentgroups${
      groupId ? `/${groupId}` : ""
    }`,
    JSON.stringify(data),
    { headers: { "Content-Type": "application/json" } }
  );

const deleteRespondentGroup = async (
  arcId: number,
  groupId: number,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.delete(
    `${API_HOST}api/v2/responsecenters/${arcId}/respondentgroups/${groupId}`
  );

const updateAllRespondents = async (
  arcId: number,
  groupId: number,
  data: { forced: boolean; optional: boolean },
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.post(
    `${API_HOST}api/v2/responsecenters/${arcId}/respondentgroups/${groupId}/access`,
    JSON.stringify(data),
    { headers: { "Content-Type": "application/json" } }
  );

const getGroupRespondentsList = async (
  arcId: number,
  groupId: number,
  query: { page: number; pageSize: number; searchText?: string },
  authenticatedRequest: AxiosInstance
) => {
  const parsedQuery = querystring.stringify({ ...query });
  return authenticatedRequest.get<ILinkedRespondent[]>(
    `${API_HOST}api/v2/responsecenters/${arcId}/respondentgroups/${groupId}/respondents?${parsedQuery}`
  );
};

const updateRespondentsGroupAccess = async (
  arcId: number,
  groupId: number,
  data: { id: number; forced: boolean }[],
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.put(
    `${API_HOST}api/v2/responsecenters/${arcId}/respondentgroups/${groupId}/respondents`,
    JSON.stringify(data),
    { headers: { "Content-Type": "application/json" } }
  );

const deleteRespondentsFromGroup = async (
  arcId: number,
  groupId: number,
  ids: number[],
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.delete(
    `${API_HOST}api/v2/responsecenters/${arcId}/respondentgroups/${groupId}/respondents`,
    {
      data: ids,
    }
  );

const getARCSubscriptions = async (
  arcId: number,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.get<IReceptionSubscription[]>(
    `${API_HOST}api/v2/responsecenters/${arcId}/subscription`
  );

const createARCSubscription = async (
  authenticatedRequest: AxiosInstance,
  arcId: number,
  data: INewReceptionSubscription
) =>
  authenticatedRequest.post(
    `${API_HOST}api/v2/responsecenters/${arcId}/subscription`,
    JSON.stringify(data),
    { headers: { "Content-Type": "application/json" } }
  );

const removeARCSubscription = (
  authenticatedRequest: AxiosInstance,
  ARCSubscriptions: number,
  rcId: number
) =>
  authenticatedRequest.delete<ApiResponse<any>>(
    `${API_HOST}api/v2/responsecenters/${rcId}/subscription/${ARCSubscriptions}`
  );

const updateARCSubscriptionName = (
  authenticatedRequest: AxiosInstance,
  arcId: number,
  groupId: number,
  data: { description: string }
) =>
  authenticatedRequest.put(
    `${API_HOST}api/v2/responsecenters/${arcId}/subscription/${groupId}`,
    JSON.stringify(data),
    { headers: { "Content-Type": "application/json" } }
  );

const addARCSubscriptionSeller = (
  authenticatedRequest: AxiosInstance,
  rcId: number,
  subscriptionId: number,
  data: { sellerId: number }
) =>
  authenticatedRequest.put(
    `${API_HOST}api/v2/responsecenters/${rcId}/subscription/${subscriptionId}/sellers/${data.sellerId}`
  );

const removeARCSubscriptionSeller = (
  authenticatedRequest: AxiosInstance,
  subscriptionId: number,
  rcId: number,
  sellerId: number
) =>
  authenticatedRequest.delete(
    `${API_HOST}api/v2/responsecenters/${rcId}/subscription/${subscriptionId}/sellers/${sellerId}`
  );

const addARCSubscriptionOrganisation = (
  authenticatedRequest: AxiosInstance,
  rcId: number,
  subscriptionId: number,
  data: { orgId: number }
) =>
  authenticatedRequest.put(
    `${API_HOST}api/v2/responsecenters/${rcId}/subscription/${subscriptionId}/organizations/${data.orgId}`
  );

const removeARCSubscriptionOrg = (
  authenticatedRequest: AxiosInstance,
  subscriptionId: number,
  rcId: number,
  orgId: number
) =>
  authenticatedRequest.delete(
    `${API_HOST}api/v2/responsecenters/${rcId}/subscription/${subscriptionId}/organizations/${orgId}`
  );

export {
  useResponseCenters,
  getResponseCenters,
  createResponseCenter,
  deleteResponseCenter,
  getResponseCenter,
  updateResponseCenter,
  useRespondentGroups,
  getRespondentGroups,
  useAlarmHandling,
  createAlarmHandling,
  updateAlarmHandling,
  deleteAlarmHandling,
  useResponseCenterDistributions,
  getResponseCenterDistributions,
  useResponseCenterDistribution,
  createResponseCenterDistribution,
  updateResponseCenterDistribution,
  deleteResponseCenterDistribution,
  getEmergencyCenterSettings,
  editEmergencyCenterSetting,
  deleteEmergencyCenterSetting,
  getAlarmReceptionIntegrations,
  editAlarmReceptionIntegration,
  deleteAlarmReceptionIntegration,
  getAlarmReceptionIntegration,
  getAlarmReceptionIPWhiteList,
  editAlarmReceptionIPWhiteList,
  getAlarmReceptionContacts,
  getAlarmReceptionContact,
  deleteAlarmReceptionContact,
  editAlarmReceptionContact,
  getRespondentGroupsList,
  getRespondentGroupDetails,
  createRespondentGroup,
  deleteRespondentGroup,
  updateAllRespondents,
  getGroupRespondentsList,
  updateRespondentsGroupAccess,
  deleteRespondentsFromGroup,
  getAdvancedSettings,
  reorderARCContacts,
  getARCSubscriptions,
  createARCSubscription,
  removeARCSubscription,
  updateARCSubscriptionName,
  addARCSubscriptionSeller,
  addARCSubscriptionOrganisation,
  removeARCSubscriptionSeller,
  removeARCSubscriptionOrg,
};
