import { Dispatch, SetStateAction, useRef, useState } from "react";
import classNames from "classnames";
import { Column, Row, Spacer } from "../../../../components/Layout/Layout";
import useTranslations from "../../../../core/i18n/useTranslations";
import Button from "../../../../ui-lib/components/Button/Button";
import Table, { TableColumn } from "../../../../ui-lib/components/Tables/Table";
import TableCell from "../../../../ui-lib/components/Tables/TableCell";
import TableRowSelectionPopup, {
  TableRowSelectionPopupActions,
} from "../../../../ui-lib/components/Tables/TableRowSelectionPopup";
import { DeleteAlarmHandlingModal } from "./DeleteAlarmHandlingModal";

import tableStyles from "../../../../ui-lib/components/Tables/Table.module.css";

interface RowData {
  id: number;
  name: string;
  primaryRespondentGroup: string;
}

const columns = (
  t: (key: string) => string,
  onStartEdit?: (rowData: RowData) => void
): TableColumn<RowData>[] => [
  {
    key: "name",
    header: t("Common:name"),
    fieldTemplate: (rowData) => (
      <button
        type="button"
        onClick={() => onStartEdit?.(rowData)}
        className={classNames([tableStyles.tableLink, tableStyles.linkHover])}
      >
        {rowData.name}
      </button>
    ),
  },
  {
    key: "respondent-group",
    header: t("AlarmReceptions:alarm_handling_table_column_respondent_group"),
    fieldTemplate: (rowData) => (
      <TableCell value={rowData.primaryRespondentGroup} />
    ),
  },
];

export const AlarmHandlingTable = ({
  responseCenterId,
  data = [],
  onStartEdit,
  onUpdate,
  setHandlingId,
}: {
  responseCenterId: number;
  data?: RowData[];
  onStartEdit: (alarmHandling: RowData) => void;
  onUpdate: () => void;
  setHandlingId: Dispatch<SetStateAction<number | undefined>>;
}) => {
  const t = useTranslations();

  const selectionPopupRef =
    useRef<TableRowSelectionPopupActions<RowData>>(null);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [upForDelete, setUpForDelete] = useState<number[]>([]);

  return (
    <>
      <Column type="fill" style={{ maxHeight: "320px", overflowY: "auto" }}>
        <Table<RowData>
          items={data}
          columns={columns(t, onStartEdit)}
          hideEmptyMessage
          withShowMore
          rowActionsFixed
          rowActions={[
            {
              icon: "pencil-alt",
              text: t("Common:label_edit"),
              onClick: (rowData) => {
                onStartEdit(rowData);
                setHandlingId(rowData.id);
              },
            },
            {
              icon: "trash",
              text: t("Common:delete"),
              iconVariant: "secondary",
              onClick: (rowData) => {
                setUpForDelete([rowData.id]);
                setDeleteOpen(true);
              },
            },
          ]}
          withRowSelection
          showRowHover
          onRowSelectionChange={(selectedItems) => {
            const filtered = selectedItems.filter(
              (i) => !!data.find((row) => row.id === i.id)
            );
            selectionPopupRef.current?.open(filtered);
          }}
        />
      </Column>

      <TableRowSelectionPopup
        ref={selectionPopupRef}
        renderBody={(selectedItems) => (
          <Row>
            <Spacer size={16} />
            <Button
              variant="destructive"
              text={`${t("Common:delete_selection")} (${selectedItems.length})`}
              onClick={() => {
                setUpForDelete(selectedItems.map((i) => i.id));
                setDeleteOpen(true);
              }}
            />
          </Row>
        )}
      />

      <DeleteAlarmHandlingModal
        responseCenterId={responseCenterId}
        isOpen={deleteOpen}
        ids={upForDelete}
        onClose={() => setDeleteOpen(false)}
        onDelete={() => {
          setDeleteOpen(false);
          selectionPopupRef.current?.close(true);
          onUpdate();
        }}
      />
    </>
  );
};
