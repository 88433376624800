import useTranslations from "../../../core/i18n/useTranslations";

export const NoScheduleItems = () => {
  const t = useTranslations();

  return (
    <div
      style={{
        textAlign: "center",
        width: "100%",
        border: "1px solid var(--Grey-200)",
        borderRadius: "4px",
      }}
    >
      <p
        style={{
          padding: "60px 0",
          color: "var(--Grey-400)",
        }}
      >
        {t("Contacts:no_availability_items")}
      </p>
    </div>
  );
};
