import { observer } from "mobx-react-lite";
import {
  updateAlarmCode,
  useResponseCenterAlarmCode,
} from "../../../../core/api/responsecenters/alarmcodes";
import { ResponseCenterAlarmCodeDetails } from "../../../../core/api/responsecenters/types";
import useTranslations from "../../../../core/i18n/useTranslations";
import useUser from "../../../../core/user/useUser";
import { notify } from "../../../../ui-lib/components/Alerts/Toast";
import LoadingSpinner from "../../../../ui-lib/components/Loading/LoadingSpinner";
import { AlarmCodeForm } from "./AlarmCodeForm/AlarmCodeForm";
import { notifyApiErrors } from "../../../../core/helpers/helpers";

const EditAlarmCodeModal = ({
  responseCenterId,
  alarmCodeId,
  onClose,
  onEdit,
}: {
  responseCenterId: string | number;
  alarmCodeId: string | number;
  onClose: () => void;
  onEdit: () => void;
}) => {
  const t = useTranslations();
  const { authenticatedRequest } = useUser();
  const { data, isLoading } = useResponseCenterAlarmCode(
    responseCenterId,
    alarmCodeId
  );

  if (!data || isLoading) {
    return <LoadingSpinner theme="primary" />;
  }

  const labels = {
    title: t("AlarmReceptions:alarm_handling_alarm_codes_edit"),
    description: t("AlarmReceptions:alarm_handling_alarm_codes_description"),
    submit: t("Common:save_changes"),
  };

  const onSubmit = async (values: ResponseCenterAlarmCodeDetails) => {
    try {
      await updateAlarmCode(
        responseCenterId,
        alarmCodeId,
        values,
        authenticatedRequest
      );

      notify({
        message: t("AlarmReceptions:alarm_handling_alarm_codes_edit_success"),
      });
      onEdit();
    } catch (error) {
      notifyApiErrors(error);
    }
  };

  return (
    <AlarmCodeForm
      labels={labels}
      defaultValues={{ ...data }}
      onSubmit={onSubmit}
      onClose={onClose}
    />
  );
};

export default observer(EditAlarmCodeModal);
