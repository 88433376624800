import { ReactNode, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import Popup from "reactjs-popup";
import styles from "./Modal.module.css";
import { Icon } from "../Icon";
import Button from "../Button/Button";
import { Spacer } from "../../../components/Layout/Layout";

interface ModalFooterProps {
  approveBtnText?: string;
  onApproveBtnClick?(): void;
  cancelBtnText?: string;
  onCancelBtnClick?(): void;
  deleteBtnText?: string;
  onDeleBtnClick?(): void;
  customFooter?(): ReactNode;
  isOverflow?: boolean;
  isLoading?: boolean;
  modalError?: string;
  approveEnabled?: boolean;
  footerDivider?: boolean;
}
interface ModalHeaderProps {
  modalTitle?: string;
  onClose(): void;
  isOverflow?: boolean;
  titleExtraText?: string;
  hideCloseButton?: boolean;
}
export interface ModalProps extends ModalHeaderProps, ModalFooterProps {
  isOpen: boolean;
  children?: ReactNode;
  closeOnDocumentClick?: boolean;
  wrapperClass?: string;
  setContentDividers?: boolean;
  nested?: boolean;
  width?: string;
  customContainerClass?: string;
  hideFooter?: boolean;
  hideXButton?: boolean;
  css?: React.CSSProperties;
  contentCss?: React.CSSProperties;
  dataCy?: string;
}

function ModalHeader({
  modalTitle,
  titleExtraText,
  onClose,
  isOverflow,
  hideCloseButton = false,
}: Readonly<ModalHeaderProps>) {
  const headerClasses = classNames({
    [styles.modalHeader]: true,
    [styles.modalHeaderWithShadow]: isOverflow,
  });
  return (
    <div className={headerClasses}>
      <div className={styles.title}>
        {modalTitle}
        {titleExtraText && (
          <span className={styles.extraTitleText}>{` ${titleExtraText}`}</span>
        )}
      </div>
      {!hideCloseButton && (
        <div
          className={styles.closeIconWrapper}
          onClick={onClose}
          onKeyDown={onClose}
          role="button"
          aria-label="Close"
        >
          <Icon name="x" color="Grey-400" />
        </div>
      )}
    </div>
  );
}

function ModalFooter({
  approveBtnText,
  onApproveBtnClick,
  cancelBtnText,
  onCancelBtnClick,
  deleteBtnText,
  onDeleBtnClick,
  customFooter,
  isLoading,
  isOverflow,
  approveEnabled,
  footerDivider,
}: Readonly<ModalFooterProps>) {
  const footerClasses = classNames({
    [styles.modalFooter]: true,
    [styles.modalFooterWithShadow]: isOverflow || footerDivider,
  });
  useEffect(() => {}, [approveEnabled]);
  return (
    <div className={footerClasses}>
      {customFooter ? (
        customFooter()
      ) : (
        <div className={styles.mainBtns}>
          {approveBtnText && (
            <div className={styles.approveBtn}>
              <Button
                type="button"
                variant="primary"
                text={approveBtnText}
                loading={isLoading}
                disabled={!approveEnabled || isLoading}
                onClick={() => onApproveBtnClick && onApproveBtnClick()}
              />
            </div>
          )}

          {deleteBtnText && (
            <>
              <div className={styles.deleteBtn}>
                <Button
                  type="button"
                  variant="destructive"
                  text={deleteBtnText}
                  loading={isLoading}
                  disabled={isLoading}
                  onClick={() => onDeleBtnClick && onDeleBtnClick()}
                />
              </div>
              <Spacer size={16} />
            </>
          )}
          {cancelBtnText && (
            <Button
              type="button"
              variant="secondary"
              text={cancelBtnText}
              disabled={isLoading}
              onClick={() => onCancelBtnClick && onCancelBtnClick()}
            />
          )}
        </div>
      )}
    </div>
  );
}
export default function Modal({
  isOpen,
  children,
  onClose,
  modalTitle,
  approveBtnText,
  onApproveBtnClick,
  cancelBtnText,
  onCancelBtnClick,
  deleteBtnText,
  onDeleBtnClick,
  customFooter,
  isLoading,
  closeOnDocumentClick,
  wrapperClass,
  modalError,
  approveEnabled = true,
  nested = false,
  setContentDividers,
  width,
  customContainerClass,
  titleExtraText,
  hideFooter = false,
  hideXButton = false,
  css,
  contentCss,
  footerDivider,
  dataCy,
}: Readonly<ModalProps>) {
  const body = useRef<HTMLDivElement>(null);
  const clickProxy = useRef<HTMLDivElement>(null);
  const [isOverflow, setIsOverflow] = useState(setContentDividers || false);
  if (closeOnDocumentClick === undefined) {
    closeOnDocumentClick = true;
  }
  useEffect(() => {
    if (isOpen) {
      if (setContentDividers) {
        return;
      }

      const sH = body?.current?.scrollHeight ?? 0;
      const cH = body?.current?.clientHeight ?? 0;
      const overflow = sH > cH;
      setIsOverflow(overflow);
    }
  }, [isOpen]);

  return (
    <div
      ref={clickProxy}
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: isOpen ? "block" : "none",
      }}
    >
      <Popup
        open={isOpen}
        nested={nested}
        closeOnDocumentClick={closeOnDocumentClick}
        onClose={onClose}
        modal
        className={wrapperClass ?? "popupModalCustom"}
        contentStyle={contentCss}
      >
        <div
          className={customContainerClass || styles.container}
          style={{ width, ...css }}
          onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            // this fix is in place because of nested portals when using treeselect.
            // the nested portal issue might arise for uther components when used inside modal
            // and must the be handled, or another fix must be found.
            const el = e.target as Element;
            const classes = el ? el.className?.toString() : "";
            if (
              classes.indexOf("p-treeselect-filter") !== -1 ||
              classes.indexOf("p-tree-toggler-icon") !== -1
            ) {
              e.stopPropagation();
            } else {
              clickProxy.current?.click();
            }
          }}
          data-cy={dataCy}
        >
          <ModalHeader
            modalTitle={modalTitle}
            onClose={onClose}
            isOverflow={isOverflow}
            titleExtraText={titleExtraText}
            hideCloseButton={hideXButton}
          />
          <div
            id="ModalInner"
            className={classNames({
              [styles.inner]: true,
              [`${customContainerClass}-inner`]: !!customContainerClass,
            })}
            ref={body}
          >
            {children}
          </div>
          {!hideFooter && (
            <ModalFooter
              isOverflow={isOverflow}
              isLoading={isLoading}
              approveBtnText={approveBtnText}
              onApproveBtnClick={onApproveBtnClick}
              cancelBtnText={cancelBtnText}
              onCancelBtnClick={onCancelBtnClick}
              deleteBtnText={deleteBtnText}
              onDeleBtnClick={onDeleBtnClick}
              customFooter={customFooter}
              modalError={modalError}
              approveEnabled={approveEnabled}
              footerDivider={footerDivider}
            />
          )}
        </div>
      </Popup>
    </div>
  );
}
