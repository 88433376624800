import { MouseEventHandler } from "react";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import styles from "./Table.module.css";
import TableCell from "./TableCell";
import Icon from "../Icon";
import Divider from "../Divider/Divider";
import Tooltip from "../Tooltip/Tooltip";

export const SubRow = ({
  value,
  icon,
  displayDivider,
  linkTo,
  hierarchyTooltip,
  leftIconTooltip,
  hierarchyButton,
  changeLogButton,
  style = {},
}: {
  value: string | JSX.Element;
  icon?: string;
  displayDivider: boolean;
  linkTo?: string;
  hierarchyTooltip?: boolean;
  leftIconTooltip?: string;
  hierarchyButton?: MouseEventHandler;
  changeLogButton?: MouseEventHandler;
  style?: any;
}) => {
  const { t } = useTranslation();
  const hierarchyBtn = (
    <button
      type="button"
      aria-label="View hierarchy"
      onClick={hierarchyButton}
      className={classnames({
        [styles.tableRowActionButton]: true,
      })}
    >
      <Icon name="hierarchy" size={24} color="Primary-700" />
    </button>
  );

  const changeLogBtn = (
    <button
      type="button"
      aria-label="View changes"
      onClick={changeLogButton}
      className={classnames({
        [styles.tableRowActionButton]: true,
      })}
    >
      <Icon name="external-link" size={24} color="Primary-700" />
    </button>
  );
  return (
    <>
      <span className={`${styles.tableCell} subrow`} style={style}>
        <TableCell
          value={value}
          leftIcon={icon}
          leftIconTooltip={leftIconTooltip}
          iconColor="Grey-300"
          className="cell"
          linkTo={linkTo}
        />
        {hierarchyButton &&
          (hierarchyTooltip ? (
            <Tooltip
              trigger={() => hierarchyBtn}
              position="top center"
              variant="light"
              text={t("Common:view_hierarchy")}
              disabled={false}
            />
          ) : (
            hierarchyBtn
          ))}
        {changeLogButton && (
          <Tooltip
            trigger={() => changeLogBtn}
            position="top center"
            variant="light"
            text={t("Objects:ChangeLogTooltip")}
            disabled={false}
          />
        )}
      </span>
      {displayDivider ? <Divider /> : <></>}
    </>
  );
};
