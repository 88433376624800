import Checkbox from "../Checkbox/Checkbox";
import useTranslations from "../../../core/i18n/useTranslations";
import { AutoDeactivatedFilter } from "../../../core/hooks/filters/useAutoDeactivatedFilter";

export const AutoDeactivatedAlarmsCheck = ({
  autoDeactivatedFilter,
  setAutoDeactivatedFilter,
}: {
  autoDeactivatedFilter: AutoDeactivatedFilter;
  setAutoDeactivatedFilter: (args: AutoDeactivatedFilter) => void;
}) => {
  const t = useTranslations();

  return (
    <Checkbox
      checked={!!autoDeactivatedFilter.hideautodeactivated}
      onChange={() => {
        const newValue = autoDeactivatedFilter.hideautodeactivated
          ? undefined
          : true;
        setAutoDeactivatedFilter({
          hideautodeactivated: newValue,
        });
      }}
      label={t("AlarmHistory:labels_autodeactivated")}
    />
  );
};
