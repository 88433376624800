import useTranslations from "../../../../core/i18n/useTranslations";
import TableCell from "../../../../ui-lib/components/Tables/TableCell";
import DateTimeHelper from "../../../../core/helpers/dateTimeHelper";
import Table from "../../../../ui-lib/components/Tables/Table";
import { Row, Spacer } from "../../../../components/Layout/Layout";
import Icon from "../../../../ui-lib/components/Icon";
import Badge from "../../../../ui-lib/components/Badges/Badge";

interface ManualSyncTableProps {
  data: {
    syncStatus: string;
    latestIntegrationSyncAttempt?: string;
    lastIntegrationSync?: string;
  };
}

export const ManualSyncTable = (props: ManualSyncTableProps) => {
  const t = useTranslations();
  const isDataPresent =
    props.data.latestIntegrationSyncAttempt || props.data.lastIntegrationSync;

  return (
    <Table
      columns={[
        {
          header: t("Objects:last_sync_started"),
          fieldTemplate: (rowData) => (
            <TableCell
              value={
                <>
                  <Row>
                    <Icon name="clock" />
                    <Spacer size={8} />
                    {DateTimeHelper.formatDateTimeString(
                      rowData.latestIntegrationSyncAttempt!
                    )}
                    <Spacer size={8} />
                    {rowData.syncStatus !== "ONGOING" ? (
                      <Badge
                        title={t(
                          `Common:${
                            rowData.syncStatus === "FAILED"
                              ? "failed"
                              : "success"
                          }`
                        )}
                        variant={
                          rowData.syncStatus === "FAILED" ? "error" : "success"
                        }
                        withHighlightedText
                      />
                    ) : (
                      <Badge
                        title={t("Common:sync_ongoing")}
                        variant="secondary"
                      />
                    )}
                  </Row>
                </>
              }
            />
          ),
        },
        {
          header: t("Objects:last_sync"),
          fieldTemplate: (rowData) =>
            rowData.lastIntegrationSync ? (
              <TableCell
                value={
                  <>
                    <Row>
                      <Icon name="clock" />
                      <Spacer size={8} />
                      {DateTimeHelper.formatDateTimeString(
                        rowData.lastIntegrationSync
                      )}
                    </Row>
                  </>
                }
              />
            ) : null,
        },
      ]}
      items={isDataPresent ? [props.data] : []}
      hideEmptyMessage
      noRowsMessage={t("Objects:no_sync_before")}
      hideHeader={!isDataPresent}
      showRowHover
      withShowMore
    />
  );
};
