import classNames from "classnames";
import { Icon } from "../Icon";
import { Column, Row } from "../../../components/Layout/Layout";
import TableCell from "../Tables/TableCell";

import styles from "./ExtraInfoDotList.module.css";

export interface ExtraInfoDotListProps {
  whichToHighlight?: number;
  dotsBetweenItems?: boolean;
  items: Array<{
    leftData?: string;
    centralData: string;
    rightData?: string;
  }>;
}

export const ExtraInfoDotList = ({
  whichToHighlight = undefined,
  dotsBetweenItems = false,
  items,
}: ExtraInfoDotListProps) => (
  <Column align="start">
    {items.map((item, index) => {
      const dateText = item.leftData?.split(",");
      return (
        <Row key={`log${index}`} className={styles.logRowWrapper} align="start">
          {dateText && (
            <p className={styles.extraData}>
              <span className={styles.time}>{`${dateText[0]},`}</span>
              <span>{dateText[1]}</span>
            </p>
          )}

          <div className={styles.centralData}>
            {index !== 0 && dotsBetweenItems ? (
              <div className={styles.alarmDetailsVDots}>
                <Icon name="dots-vertical" color="Grey-100" />
              </div>
            ) : null}
            <div className={styles.logDot}>
              <Icon
                name="alarm-classification"
                color={
                  index === whichToHighlight ? "fill-Primary-700" : "fill-grey"
                }
                size={6}
              />
            </div>
            <TableCell className={styles.itemText} value={item.centralData} />
          </div>

          {item.rightData && (
            <TableCell
              className={classNames(styles.extraData, styles.rightData)}
              value={item.rightData}
            />
          )}
        </Row>
      );
    })}
  </Column>
);
