import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  deleteAlarmReceptionContact,
  editAlarmReceptionContact,
  getAlarmReceptionContacts,
} from "../../../../core/api/responsecenters/responsecenters";
import { IAlarmReceptionContact } from "../../../../core/api/responsecenters/types";
import useUser from "../../../../core/user/useUser";

export const SharedContactsContext = createContext<{
  contacts?: IAlarmReceptionContact[];
  isLoading: boolean;
  isError: boolean;
  id: string | number;
  editContact: (contact: IAlarmReceptionContact) => Promise<void>;
  deleteContact: (contactId: number) => Promise<void>;
  reload: () => Promise<void>;
}>({
  contacts: undefined,
  isLoading: false,
  isError: false,
  id: "",
  editContact: async () => {},
  deleteContact: async () => {},
  reload: async () => {},
});

export const SharedContactsProvider = ({
  id,
  children,
}: {
  id: string | number;
  children: React.ReactNode;
}) => {
  const isAlive = useRef(false);
  const { authenticatedRequest } = useUser();

  const [contacts, setContacts] = useState<IAlarmReceptionContact[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const fetchContacts = async () => {
    setIsError(false);
    setIsLoading(true);

    try {
      const result = await getAlarmReceptionContacts(id, authenticatedRequest, {
        // Stub while backend change endpoint
        page: 1,
        PageSize: 10000,
      });
      if (isAlive.current) {
        setContacts(result.data);
      }
    } catch (error) {
      if (isAlive.current) {
        setIsError(true);
      }
    }

    if (isAlive.current) {
      setIsLoading(false);
    }
  };
  const editContact = async (contact: IAlarmReceptionContact) => {
    setIsLoading(true);

    try {
      await editAlarmReceptionContact(id, contact, authenticatedRequest);

      if (isAlive.current) {
        await fetchContacts();
      }
    } catch (error) {
      if (isAlive.current) {
        setIsLoading(false);

        throw error;
      }
    }
  };
  const deleteContact = async (contactId: string | number) => {
    setIsLoading(true);

    try {
      await deleteAlarmReceptionContact(id, contactId, authenticatedRequest);

      if (isAlive.current) {
        await fetchContacts();
      }
    } catch (error) {
      if (isAlive.current) {
        setIsLoading(false);

        throw error;
      }
    }
  };

  useEffect(() => {
    fetchContacts();
  }, [id]);

  useEffect(() => {
    isAlive.current = true;

    return () => {
      isAlive.current = false;
    };
  }, []);

  const contextData = useMemo(
    () => ({
      id,
      isLoading,
      isError,
      editContact,
      contacts,
      deleteContact,
      reload: fetchContacts,
    }),
    [id, isLoading]
  );

  return (
    <SharedContactsContext.Provider value={contextData}>
      {children}
    </SharedContactsContext.Provider>
  );
};

export const useSharedContactsContext = () => useContext(SharedContactsContext);

export default useSharedContactsContext;
