import { Column, Row, Spacer } from "../../../../components/Layout/Layout";
import useTranslations from "../../../../core/i18n/useTranslations";
import Icon from "../../../../ui-lib/components/Icon";
import styles from "./CustomTableCell.module.css";
import TextInput from "../../../../ui-lib/components/Inputs/TextInput";

const Label = ({
  text,
  placeholder: _placeholder,
  color,
}: {
  text?: string;
  placeholder?: string;
  color?: string;
}) => {
  const hasValue = text && text.length > 0;
  const placeholder = _placeholder ?? "";

  return (
    <span
      className="text"
      style={{ color: hasValue ? color : "var(--Grey-400)" }}
    >
      {hasValue ? text : placeholder}
    </span>
  );
};

export const CustomTableCell = ({
  label = "",
  description,
  descriptionColor = "var(--Grey-600)",
  icon,
  placeholder,
}: {
  label: string;
  description?: string;
  descriptionColor?: string;
  icon?: string;
  placeholder?: string;
}) => (
  <Column align="start" className={styles.customTableCell}>
    <Row className={styles.textWrapper}>
      {icon && (
        <>
          <Icon name={icon} size={16} color="Grey-300" />
          <Spacer size={8} />
        </>
      )}
      <Label text={label} placeholder={placeholder} />
    </Row>
    {description && (
      <Row className={styles.textWrapper}>
        <Label
          text={description}
          color={descriptionColor}
          placeholder={placeholder}
        />
      </Row>
    )}
  </Column>
);

export const ContactCell = ({
  phone,
  email,
}: {
  phone: string;
  email?: string;
}) => {
  const t = useTranslations();

  return (
    <Column align="start" className={styles.customTableCell}>
      <Row className={styles.textWrapper}>
        <Icon name="phone" size={16} color="Grey-300" />
        <Spacer size={8} />
        <Label text={phone} placeholder={t("Messages:no_phone_added")} />
      </Row>
      <Row className={styles.textWrapper}>
        <Icon name="mail" size={16} color="Grey-300" />
        <Spacer size={8} />
        <Label text={email} placeholder={t("Messages:no_email_added")} />
      </Row>
    </Column>
  );
};

export const IdentityPermissionCell = ({
  value,
  isRespondent,
}: {
  value: string;
  isRespondent: boolean;
}) => (
  <Column align="start" className={styles.customTableCell}>
    <Row className={styles.textWrapper}>
      <Icon
        name={isRespondent ? "alarm-reception" : "building"}
        size={24}
        color="Grey-300"
      />
      <Spacer size={8} />
      <TextInput value={value} onChange={() => {}} disabled />
    </Row>
  </Column>
);
