import { useRef, useState } from "react";
import { Row, Spacer } from "../../components/Layout/Layout";
import { PeriodicReportResponse } from "../../core/api/reports/types";
import useTranslations from "../../core/i18n/useTranslations";
import Button from "../../ui-lib/components/Button/Button";
import Table from "../../ui-lib/components/Tables/Table";
import TableCell from "../../ui-lib/components/Tables/TableCell";
import TableDateCell from "../../ui-lib/components/Tables/TableDateCell";
import TableRowSelectionPopup, {
  TableRowSelectionPopupActions,
} from "../../ui-lib/components/Tables/TableRowSelectionPopup";
import { DeleteReportsModal } from "./DeleteReportModal";
import NoItems from "../../ui-lib/components/Tables/NoItems";
import LoadingSpinner from "../../ui-lib/components/Loading/LoadingSpinner";

const columns = (t: (key: string) => string) => [
  {
    header: t("Common:email"),
    fieldTemplate: (rowData: PeriodicReportResponse) => (
      <TableCell value={rowData.email} />
    ),
  },
  {
    header: t("Reports:table_column_comment"),
    fieldTemplate: (rowData: PeriodicReportResponse) => (
      <TableCell value={rowData.comment ?? ""} />
    ),
  },
  {
    header: t("Reports:table_column_send_empty"),
    fieldTemplate: (rowData: PeriodicReportResponse) => (
      <TableCell
        value={
          rowData.dontSendEmptyReports
            ? t("Reports:table_value_no_empty")
            : t("Reports:table_value_send_empty")
        }
      />
    ),
  },
  {
    header: t("Reports:table_column_repeats"),
    fieldTemplate: (rowData: PeriodicReportResponse) => (
      <TableCell value={t(`Reports:frequency_${rowData.periodicity}`)} />
    ),
  },
  {
    header: t("Reports:table_column_last_sent"),
    fieldTemplate: (rowData: PeriodicReportResponse) => {
      if (rowData.lastSent) {
        return <TableDateCell value={rowData.lastSent} />;
      }

      return <TableCell value={t("Reports:table_value_not_sent")} />;
    },
  },
];

export const ReportsTable = ({
  data = [],
  isLoading,
  onUpdate,
}: {
  data?: PeriodicReportResponse[];
  isLoading: boolean;
  onUpdate: () => void;
}) => {
  const t = useTranslations();

  const selectionPopupRef =
    useRef<TableRowSelectionPopupActions<PeriodicReportResponse>>(null);

  const [deleteOpen, setDeleteOpen] = useState(false);
  const [upForDelete, setUpForDelete] = useState<number[]>([]);

  if (isLoading) {
    return <LoadingSpinner theme="primary" />;
  }

  return (
    <>
      {data.length > 0 ? (
        <Table<PeriodicReportResponse>
          hideEmptyMessage
          showRowHover
          showTooltip
          rowActionsFixed
          withRowSelection
          withShowMore
          rowActionsColWidth={64}
          tableName="table-reports"
          items={data}
          isLoading={isLoading}
          columns={columns(t)}
          rowActions={[
            {
              icon: "trash",
              text: t("Common:delete"),
              iconVariant: "secondary",
              onClick: (rowData) => {
                setUpForDelete([rowData.id]);
                setDeleteOpen(true);
              },
            },
          ]}
          onRowSelectionChange={(selectedItems) => {
            const filtered = selectedItems.filter(
              (i) => !!data.find((row) => row.id === i.id)
            );
            selectionPopupRef.current?.open(filtered);
          }}
        />
      ) : (
        <NoItems
          title={t("Table:noresult_title")}
          icon="eye-off"
          subTitle={t("Table:noresult_subtitle")}
          clear={undefined}
        />
      )}

      <DeleteReportsModal
        ids={upForDelete}
        isOpen={deleteOpen}
        onClose={() => setDeleteOpen(false)}
        onDelete={() => {
          setDeleteOpen(false);
          selectionPopupRef.current?.close(true);
          onUpdate();
        }}
      />

      <TableRowSelectionPopup
        ref={selectionPopupRef}
        renderBody={(selectedItems) => (
          <Row>
            <Spacer size={16} />
            <Button
              variant="destructive"
              text={`${t("Common:delete_selection")} (${selectedItems.length})`}
              onClick={() => {
                setUpForDelete(selectedItems.map((i) => i.id));
                setDeleteOpen(true);
              }}
            />
          </Row>
        )}
      />
    </>
  );
};
