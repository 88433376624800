import {
  createContext,
  Dispatch,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  getAdministrator,
  updateAdministrator,
  update2FA,
  getAdminPasswordConstraints,
} from "../../../../core/api/administrators/administrators";
import {
  AdministratorDetails,
  ITwoFactorData,
} from "../../../../core/api/administrators/types";
import useUser from "../../../../core/user/useUser";

export const SharedAdministratorContext = createContext<{
  data?: AdministratorDetails;
  isLoading: boolean;
  isError: boolean;
  update: (
    adminDetails: AdministratorDetails,
    defaultAuthType?: string,
    default2faTelephone?: string
  ) => Promise<void>;
  reload: () => Promise<void>;
  twoFAData?: ITwoFactorData;
  setTwoFAData: Dispatch<ITwoFactorData | undefined>;
}>({
  data: undefined,
  isLoading: false,
  isError: false,
  update: async () => {},
  reload: async () => {},
  twoFAData: undefined,
  setTwoFAData: () => {},
});

export const SharedAdministratorProvider = ({
  id,
  children,
}: {
  id: string;
  children: React.ReactNode;
}) => {
  const isAlive = useRef(false);
  const { authenticatedRequest, config } = useUser();

  const [data, setData] = useState<AdministratorDetails>();
  const [twoFAData, setTwoFAData] = useState<ITwoFactorData>();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const twoFAUser = config?.show.some((item) =>
    ["Admin2FAEdit", "Admin2FAEditRespondent"].includes(item)
  );

  const fetchAdministrator = async () => {
    setIsError(false);
    setIsLoading(true);

    try {
      const result = await getAdministrator(id, authenticatedRequest);
      if (isAlive.current) {
        setData(result.data);

        const constrainResult = await getAdminPasswordConstraints(
          id,
          authenticatedRequest
        );
        const updated = {
          ...result.data,
          passwordConstraints: constrainResult.data,
        };
        setData(updated);
      }
    } catch (error) {
      if (isAlive.current) {
        setIsError(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const update = async (
    adminDetails: AdministratorDetails,
    defaultAuthType?: string,
    default2faTelephone?: string
  ) => {
    setIsLoading(true);

    const updated = {
      ...data,
      ...adminDetails,
    };

    delete updated.passwordConstraints;

    const promisses = [updateAdministrator(id, updated, authenticatedRequest)];
    if (
      twoFAUser &&
      (defaultAuthType !== adminDetails.twoFactorAuthenticationType ||
        default2faTelephone !== adminDetails.twoFactorAuthenticationData)
    ) {
      promisses.push(
        update2FA(
          id,
          adminDetails.twoFactorAuthenticationType ?? "none",
          adminDetails.twoFactorAuthenticationData ?? "",
          authenticatedRequest
        )
      );
    }

    return Promise.all(promisses)
      .then((datas: any) => {
        if (isAlive.current && datas.length > 1) {
          if (datas[1]?.data.twoFactorAuthType === "2") {
            setTwoFAData(datas[1].data);
          }
        }
        setData(updated);
        return Promise.resolve();
      })
      .catch((error) => Promise.reject(new Error(error)))
      .finally(() => {
        setIsLoading(false);
      });
  };

  // Fetch admin on mount and when admin id is updated
  useEffect(() => {
    fetchAdministrator();
  }, [id]);

  // To make sure we do not try to update state if component did unmount
  useEffect(() => {
    isAlive.current = true;

    return () => {
      isAlive.current = false;
    };
  }, []);

  const contextData = useMemo(
    () => ({
      data,
      twoFAData,
      setTwoFAData,
      isLoading,
      isError,
      update,
      reload: fetchAdministrator,
    }),
    [data, isLoading, twoFAData]
  );

  return (
    <SharedAdministratorContext.Provider value={contextData}>
      {children}
    </SharedAdministratorContext.Provider>
  );
};
