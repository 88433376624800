import moment from "moment";
import DateTimeHelper from "../../core/helpers/dateTimeHelper";
import { hexToRgba } from "../../core/helpers/helpers";
import useTranslations from "../../core/i18n/useTranslations";

export const useChartOptions = () => {
  const t = useTranslations();

  let width: number;
  let height: number;
  let gradient: CanvasGradient;

  return {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: false,
      tooltip: {
        backgroundColor: "#ffffff",
        borderColor: "#606B77",
        borderWidth: 1,
        displayColors: false,
        padding: 8,
        cornerRadius: 4,
        caretSize: 0,
        bodyFont: { family: "Poppins-Regular", size: 14, lineHeight: 1.4 },
        bodyColor: "#606B77",
        callbacks: {
          label: (context: any) => {
            const label = `${
              context.formattedValue ?? t("AlarmHistory:no")
            } ${t("AlarmHistory:alarmstriggered")} `;
            return label;
          },
          afterLabel: (context: any) => {
            let label;
            if (context.dataset.axisType === "time") {
              const currentDate = moment(
                context.dataset.dateRange[context.dataIndex].start
              );

              label = `${DateTimeHelper.formatTime(
                currentDate.toDate()
              )}-${DateTimeHelper.formatTime(
                currentDate.add("59", "minute").toDate()
              )}`;
            } else if (context.dataset.axisType === "day") {
              const currentDate = moment(
                context.dataset.dateRange[context.dataIndex].start
              );
              label = `${DateTimeHelper.formatDate(currentDate.toDate())}`;
            } else {
              label = context.label;
            }
            return label;
          },
        },
      },
    },
    elements: {
      line: {
        fill: false,
        backgroundColor: (context: { chart: any }): CanvasGradient | null => {
          const { chart } = context;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            return null;
          }
          const chartWidth = chartArea.right - chartArea.left;
          const chartHeight = chartArea.bottom - chartArea.top;
          if (
            gradient === null ||
            width !== chartWidth ||
            height !== chartHeight
          ) {
            width = chartWidth;
            height = chartHeight;
            gradient = ctx.createLinearGradient(
              0,
              chartArea.bottom,
              0,
              chartArea.top
            );
            gradient.addColorStop(1, hexToRgba("var(--Primary-700)", 0.4));
            gradient.addColorStop(0, hexToRgba("#ffffff", 0));
          }
          return gradient;
        },
        borderColor: "var(--Primary-700)",
      },
      point: {
        backgroundColor: "#ffffff",
        borderColor: "var(--Primary-700)",
        hoverBackgroundColor: "var(--Primary-700)",
        radius: 4,
        pointStyle: "circle",
        hoverRadius: 6,
        borderWidth: 2,
      },
    },
    scales: {
      x: {
        grid: {
          drawOnChartArea: false,
        },
      },
      y: {
        type: "linear",
        display: true,
        position: "left",
        suggestedMin: 0,
      },
    },
  };
};
