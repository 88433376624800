import { useEffect, useRef, useState } from "react";
import { Column, Row, Spacer } from "../../../../components/Layout/Layout";
import useTranslations from "../../../../core/i18n/useTranslations";
import Button from "../../../../ui-lib/components/Button/Button";
import Modal, { ModalActions } from "../../../../ui-lib/components/Popup/Modal";
import useSharedAlarmReception from "../SharedAlarmReceptionContext/useSharedAlarmReception";
import { AlarmHandlingForm } from "./AlarmHandlingForm";
import { AlarmHandlingTable } from "./AlarmHandlingTable";
import AlarmLogForm from "./AlarmLogForm";
import { CallHandlingForm } from "./CallHandlingForm";
import CreateAlarmHandlingModal from "./CreateAlarmHandlingModal";
import EditAlarmHandlingModal from "./EditAlarmHandlingModal";
import { useModalIdHook } from "../../../../core/hooks/modalIdsHandlers/useIdModalHook";
import { useHistory } from "react-router-dom";
import { urlSearchParser } from "../../../../ui-lib/utils/urlSearchParser";

const AlarmHandling = () => {
  const t = useTranslations();

  const { data, reload } = useSharedAlarmReception();
  const history = useHistory();
  const tableState = history.location.search;
  const searchState = urlSearchParser(tableState);

  const responseCenterId = data?.id as number;
  const [alarmHandlingId, setAlarmHandlingId] = useState<number>();
  const [editAlarmHandlingOpen, setEditAlarmHandlingOpen] = useState(false);
  const editModalRef = useRef<ModalActions>(null);
  const [handlingId, setHandlingId, resetHandlingId] = useModalIdHook(
    searchState,
    "handlingId"
  );

  useEffect(() => {
    if (handlingId.id) {
      setAlarmHandlingId(+handlingId.id);
      setEditAlarmHandlingOpen(true);
      editModalRef?.current?.open();
    }
  }, [handlingId]);

  return (
    <AlarmHandlingForm>
      <Column style={{ width: "784px" }} type="top" align="start">
        <h1>{t("AlarmReceptions:alarm_handling_title")}</h1>
        <Spacer size={8} />
        <p style={{ color: "var(--Grey-600)" }}>
          {t("AlarmReceptions:alarm_handling_description")}
        </p>

        <Spacer size={32} />

        <CallHandlingForm />

        <Spacer size={32} />

        <Row style={{ width: "100%" }}>
          <h2>{t("AlarmReceptions:alarm_handling_title")}</h2>
          <Spacer />
          <Modal
            trigger={() => (
              <Button
                size="small"
                variant="secondary"
                text={t("AlarmReceptions:alarm_handling_add")}
              />
            )}
          >
            {(close) => (
              <CreateAlarmHandlingModal
                responseCenterId={data!.id as string | number}
                onClose={close}
                onAdd={reload}
              />
            )}
          </Modal>
        </Row>
        <Spacer size={16} />
        <AlarmHandlingTable
          responseCenterId={responseCenterId}
          data={data!.alarmHandlings}
          onStartEdit={(alarmHandling) => {
            setAlarmHandlingId(alarmHandling.id);
            setEditAlarmHandlingOpen(true);
          }}
          onUpdate={reload}
          setHandlingId={setHandlingId}
        />

        {editAlarmHandlingOpen && (
          <EditAlarmHandlingModal
            modalOpen={editAlarmHandlingOpen}
            responseCenter={data!}
            alarmHandlingId={alarmHandlingId as number}
            onClose={() => {
              setEditAlarmHandlingOpen(false);
              resetHandlingId();
            }}
            onEdit={(id, handlingParams) => {
              setEditAlarmHandlingOpen(false);
              resetHandlingId();
              data?.alarmHandlings?.find((alarmHandling) => {
                if (alarmHandling.id === id) {
                  alarmHandling.name = handlingParams.name;
                  alarmHandling.primaryRespondentGroup =
                    handlingParams.primaryRespondentGroup;
                }
              });
            }}
          />
        )}

        <Spacer size={32} />

        <AlarmLogForm defaultValues={data!} />

        <Spacer size={64} />
      </Column>
    </AlarmHandlingForm>
  );
};

export default AlarmHandling;
