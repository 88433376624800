import { Controller, FormProvider } from "react-hook-form";
import { useEffect, useState } from "react";
import PermissionList from "../../../../components/Administrator/PermissionList";
import {
  AdministratorDetails,
  AvailableRoles,
} from "../../../../core/api/administrators/types";
import {
  useObjectForm,
  useObjectFormRef,
} from "../../../../core/SaveObjectContext/hooks";
import useUser from "../../../../core/user/useUser";

export const AdminPermissionsForm = ({
  availableRoles,
  defaultValues,
  onSubmit,
  adminId,
}: {
  availableRoles?: AvailableRoles;
  defaultValues?: AdministratorDetails;
  onSubmit: (values: AdministratorDetails) => Promise<void>;
  adminId: number;
}) => {
  const { accountId, config } = useUser();
  const formRef = useObjectFormRef();
  const methods = useObjectForm<AdministratorDetails>({
    defaultValues,
    shouldUnregister: false,
  });
  const [isPermissionsEditable, setIsPermissionsEditable] = useState(true);
  const { control, setValue, handleSubmit, reset } = methods;

  useEffect(() => {
    if (adminId) {
      setIsPermissionsEditable(
        adminId !== accountId && !!config?.show.includes("AdminEditPermissions")
      );
    }
  }, [adminId]);

  return (
    <FormProvider {...methods}>
      <form
        ref={formRef}
        onSubmit={handleSubmit(async (values) => {
          await onSubmit(values);
          reset({ ...values });
        })}
        style={{ width: "100%" }}
      >
        <Controller
          control={control}
          name="roles"
          render={({ field }) => {
            return (
              <PermissionList
                availableRoles={availableRoles || {}}
                field={field}
                setValue={setValue}
                isEditable={isPermissionsEditable}
              />
            );
          }}
        />
      </form>
    </FormProvider>
  );
};
