import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Column, Spacer } from "../../../../components/Layout/Layout";
import { notifyApiErrors } from "../../../../core/helpers/helpers";
import { getSellers } from "../../../../core/api/sellers/sellers";
import { IItemProp } from "../../../../ui-lib/components/Dropdown/DropdownItem";

import useUser from "../../../../core/user/useUser";
import AlarmSubscriptionsTable from "./AlarmSubscriptionsTable";
import LoadingSpinner from "../../../../ui-lib/components/Loading/LoadingSpinner";
import useSharedAlarmReception from "../SharedAlarmReceptionContext/useSharedAlarmReception";

const AlarmSubscriptions = ({ id }: { id: number }) => {
  const { t } = useTranslation();
  const { authenticatedRequest } = useUser();
  const { subscriptions, isLoading, refetchSubscriptions } =
    useSharedAlarmReception();

  const [sellers, setSellers] = useState<IItemProp[]>([]);

  const fetchSellers = async () => {
    try {
      const result = await getSellers(authenticatedRequest);
      setSellers(
        result.data.map((seller: any) => ({
          id: seller.id,
          name: seller.name,
          isSelected: false,
        }))
      );
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    }
  };

  useEffect(() => {
    fetchSellers();
  }, []);

  return (
    <Column style={{ width: "100%", flex: 0 }} type="top" align="start">
      <h1>{t("Menu:AlarmReceptions_Subscriptions")}</h1>
      <Spacer size={8} />
      <p style={{ color: "var(--Grey-600)" }}>
        {t("AlarmReceptions:subscriptions_page_description")}
      </p>

      <Spacer size={32} />
      {isLoading ? (
        <LoadingSpinner theme="primary" />
      ) : (
        <AlarmSubscriptionsTable
          subscriptions={subscriptions}
          rcId={id}
          sellers={sellers}
          getAllArcSubscriptions={refetchSubscriptions}
        />
      )}
    </Column>
  );
};

export default AlarmSubscriptions;
