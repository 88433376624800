import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Column, Row, Spacer } from "../../../../components/Layout/Layout";
import { AdministratorDetails } from "../../../../core/api/administrators/types";
import { validatePhoneNumber } from "../../../../core/helpers/validation";
import useTranslations from "../../../../core/i18n/useTranslations";
import Button from "../../../../ui-lib/components/Button/Button";
import Dropdown from "../../../../ui-lib/components/Dropdown/Dropdown";
import TextInput from "../../../../ui-lib/components/Inputs/TextInput";
import ChangePasswordModal from "./ChangePasswordModal";
import Alert from "../../../../ui-lib/components/Alerts/Alert";

export enum AuthType {
  None = "none",
  Sms = "sms",
  SmsSeparate = "sms_seperate",
  Google = "google",
}

const authTypeOptions = [
  AuthType.None,
  AuthType.Sms,
  AuthType.SmsSeparate,
  AuthType.Google,
];

const AccountSecuritySection = ({
  forcePasswordRequest,
  forceRequestSended,
  userName,
  userType,
  actions,
}: {
  forcePasswordRequest: () => void;
  forceRequestSended: boolean;
  userName: string;
  userType: string;
  actions?: string[];
}) => {
  const t = useTranslations();

  const canEdit2FA = actions?.find((key) =>
    userType === "respondent"
      ? key === "Admin2FAEditRespondent"
      : key === "Admin2FAEdit"
  );

  const {
    control,
    watch,
    setValue,
    trigger,
    formState: { errors },
  } = useFormContext<AdministratorDetails>();

  const [resetPasswordModalOpen, setResetPasswordModalOpen] = useState(false);

  const requireSeparateData =
    watch("twoFactorAuthenticationType") === AuthType.SmsSeparate;

  return (
    <>
      <h2 className="subTitle">
        {t("Administrator:general_account_security")}
      </h2>
      <p style={{ color: "var(--Grey-600)" }}>
        {t("Administrator:general_account_security_description")}
      </p>
      <Spacer size={32} />
      <Row align="start">
        <Column style={{ width: "100%" }}>
          <Controller
            name="twoFactorAuthenticationType"
            control={control}
            render={({ field }) => {
              const dropdownItems = authTypeOptions.map((id) => ({
                id,
                name: t(
                  `Administrator:general_account_security_input_auth_type_${id}`
                ),
                isSelected: id === field.value,
              }));

              return (
                <Dropdown
                  title={t(
                    "Administrator:general_account_security_input_auth_type"
                  )}
                  width="100%"
                  selectedItem={dropdownItems.find((i) => i.isSelected)}
                  items={dropdownItems}
                  onSelectItem={(item) => {
                    setValue(field.name, item.id as string);
                    trigger(["telephone"]);
                  }}
                  disabled={!canEdit2FA}
                />
              );
            }}
          />
        </Column>
        <Spacer size={32} />
        <Column style={{ width: "100%" }}>
          {requireSeparateData ? (
            <Controller
              name="twoFactorAuthenticationData"
              control={control}
              rules={{
                required: true,
                validate: validatePhoneNumber,
              }}
              render={({ field }) => (
                <TextInput
                  required
                  label={t("Common:labels_phone_number")}
                  placeholder={t("Common:phone_number_placeholder")}
                  value={field.value ?? ""}
                  onChange={field.onChange}
                  validationError={
                    errors.twoFactorAuthenticationData &&
                    ((errors.twoFactorAuthenticationData.type === "required" &&
                      t("Errors:input_field_required")) ||
                      t("Objects:Validation_identifier_format"))
                  }
                />
              )}
            />
          ) : null}
        </Column>
      </Row>
      <Spacer size={32} />
      <Button
        variant="secondary"
        image="key"
        text={t("Administrator:change_password")}
        disabled={!actions?.includes("SetPasswordAdmin")}
        onClick={() => setResetPasswordModalOpen(true)}
      />
      <ChangePasswordModal
        onClose={() => setResetPasswordModalOpen(false)}
        isOpen={resetPasswordModalOpen}
      />
      <Spacer size={32} />
      {actions?.find((key) => key === "ConfigureForcePasswordReset") && (
        <>
          <Row type="left">
            <Button
              variant="secondary"
              image="reset"
              text={t("Administrator:force_password_change_button")}
              onClick={forcePasswordRequest}
              disabled={forceRequestSended}
            />
            <Spacer size={16} />
            <p style={{ color: "var(--Grey-600)" }}>
              {t("Administrator:force_password_change_description")}
            </p>
          </Row>
          {forceRequestSended && (
            <>
              <Spacer size={16} />
              <Alert
                title={t(
                  "Administrator:force_password_change_requested"
                ).replace("{user}", userName)}
                variant="info"
                withDefaultIcon
              />
            </>
          )}
          <Spacer size={32} />
        </>
      )}
    </>
  );
};

export default AccountSecuritySection;
