export const boldTextInserter = (
  text: string,
  textsToInsert: string[]
): any => {
  const splitedText = text.replace("{0}", ",,").split(",");
  return splitedText.map((textPart, index) => {
    if (textPart) {
      return textPart;
    }
    const itemToReturn = textsToInsert[0];
    textsToInsert.shift();
    return <b key={`${textPart}${index}`}>{itemToReturn}</b>;
  });
};

export const removeLeadingWhitespaces = (str: string) =>
  str.replace(/^\s+/, "");
