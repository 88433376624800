import { useRef } from "react";

let uniqueId = 0;
const generateUniqueId = () => {
  uniqueId += 1;
  return uniqueId;
};

export function getUniqueId(prefix: string | undefined) {
  const idRef = useRef<number>();
  if (idRef.current === undefined) {
    idRef.current = generateUniqueId();
  }
  return `${prefix || "uniqueid"}_${idRef.current}`;
}
