import axios, { AxiosInstance, CancelToken } from "axios";
import queryString from "query-string";
import { Dispatch, MutableRefObject, SetStateAction } from "react";
import { SearchFilter } from "../../components/FilterSearch/FilterSearch";
import { SortOrderParams } from "../../ui-lib/components/Tables/Table";
import { downloadAsXlsxFile } from "../helpers/helpers";
import { getStoredToken } from "../user/token";
import API_HOST from "./apiHost";
import useAuthenticatedFetch from "./useAuthenticatedFetch";
import { TimeSpanType } from "../../components/DateFilter/types";

type ColumnType = "STRING" | "LONG" | "DATETIME";

export interface BatchAllTableDataParams {
  selected?: boolean;
  customers?: string;
  start?: string;
  end?: string;
  exact?: boolean;
  nameonly?: boolean;
  filterType?: number;
  filterType2?: number;
  filterType3?: number;
  filterType4?: number;
  filterValue?: string;
  filterValue2?: string;
  filterValue3?: string;
  filterValue4?: string;
}

export interface FilterData {
  id: number;
  value: string | Date | number;
}

export interface BaseTableDataParams {
  filter?: FilterData[];
  customer?: FilterData;
  customers?: string;
  userIds?: number[];
  transmitter?: string;
  start?: string;
  end?: string;
  page?: number;
  pageSize?: number;
  sort?: string;
  user?: string;
  filterType?: string;
  filterValue?: string;
  hideautodeactivated?: boolean;
  alarmCodes?: string;
  preselectedDateType?: TimeSpanType;
  language?: string;
  searchFilter?: SearchFilter;
  exact?: string;
}

interface BaseTableDataResponse {
  hits: number;

  objectIds: number[];
  responsecenterIds: number[];
  organizationIds: number[];

  numRows: number;
  rows: { [index: string]: string[] };

  numColumns: number;
  columnDataTypes: ColumnType[];
  columns: string[];
  sortableColumns: string[];
}

interface BaseColumnsProps {
  columns: {
    [key: string]: { [key: string]: string } | string;
  };
}

interface BaseTableProps {
  pageSettings: {
    page: number;
    pageSize: number;
  };
  onPageSettingsChange: (args: { page: number; pageSize: number }) => void;
  sortParams?: SortOrderParams;
  onSortChange?: (sortOrderParams?: SortOrderParams) => void;
  data: BaseTableDataResponse;
  allTableColumns?: BaseColumnsProps;
  selectedTableColumns?: BaseColumnsProps;
  onColumnChange?: (columns: BaseColumnsProps) => void;
  alarmTableAction?: Dispatch<SetStateAction<string>>;
  onClear?: () => void;
  enableColumnSelect?: boolean;
  onDataChanged?: () => void;
  tableName?: string;
  setOpenedAlarmId?: (id: any) => void;
  isFiltersApplied: boolean;
  parentClearSelection?: MutableRefObject<Function | undefined>;
  tableParams?: BaseTableDataParams;
}

interface BaseFiltersResponse
  extends Array<{
    id: number;
    keyName: string;
    name: string;
  }> {}

// Table And Filters Block

const getTableFetchSettings = (
  params: BaseTableDataParams,
  searchFilter?: SearchFilter,
  skipPageSettings = false
) => {
  const customSettings: any = {
    start: params.start,
    end: params.end,
  };
  let filters = params.filter || [];

  if (searchFilter?.id! > 0 && searchFilter?.value) {
    filters = [searchFilter as FilterData, ...filters];
  } else if (searchFilter?.value) {
    switch (searchFilter.id) {
      case -2:
        customSettings.alarmtext = searchFilter.value;
        break;
      default:
        customSettings.q = searchFilter.value;
        break;
    }
  }

  if (params.user) {
    customSettings.user = params.user;
  }
  if (params.transmitter) {
    customSettings.transmitter = params.transmitter;
  }
  if (params.filterType) {
    customSettings.filterType = params.filterType;
  }
  if (params.filterValue) {
    customSettings.filterValue = params.filterValue;
  }
  if (params.customer) {
    if (params.customer.id === 0) {
      customSettings.customers = params.customer.value;
    } else {
      filters = [params.customer, ...filters];
    }
  }
  if (params.alarmCodes) {
    customSettings.alarmCodes = params.alarmCodes;
  }
  if (params.hideautodeactivated) {
    customSettings.hideautodeactivated = params.hideautodeactivated;
  }

  filters.forEach((filter, index) => {
    if (index === 0) {
      customSettings.filterType = filter?.id;
      customSettings.filterValue = filter?.value;
    } else {
      customSettings[`filterType${index + 1}`] = filter?.id;
      customSettings[`filterValue${index + 1}`] = filter?.value;
    }
  });

  const settings = {
    page: !skipPageSettings ? params.page ?? 1 : undefined,
    pageSize: !skipPageSettings ? params.pageSize ?? 200 : undefined,
    o: params.sort ?? "date_desc",
    exact: params.exact,
    nameonly: false,
    hideautodeactivated: params.hideautodeactivated,
    alarmtext: "",
    selected: true,
    customers: params.customers,
    userIds: params.userIds,
    language: params.language,
    ...customSettings,
  };

  return settings;
};

const useTableData = (
  params: BaseTableDataParams,
  pageUrl: string,
  searchFilter?: SearchFilter
) =>
  useAuthenticatedFetch<BaseTableDataResponse>(
    `${API_HOST}${pageUrl}/datatable?${queryString.stringify(
      getTableFetchSettings(params, searchFilter)
    )}`
  );

const getTableDataWithoutHook = (
  params: BaseTableDataParams,
  pageUrl: string,
  searchFilter?: SearchFilter
) => {
  const tokenData = getStoredToken();

  return axios.get<BaseTableDataResponse>(
    `${API_HOST}${pageUrl}/datatable?${queryString.stringify(
      getTableFetchSettings(params, searchFilter)
    )}`,
    {
      headers: { Authorization: `Bearer ${tokenData?.token}` },
      withCredentials: true,
    }
  );
};

const exportApi = (
  authenticatedRequest: AxiosInstance,
  pageUrl: string,
  onDone?: () => void,
  onError?: (error?: any) => void
) => ({
  exportAsExcel: async ({
    tableParams = {},
    searchFilter,
    cancelToken,
  }: {
    tableParams?: BaseTableDataParams;
    searchFilter?: SearchFilter;
    cancelToken?: CancelToken;
  }) => {
    const requestParams = getTableFetchSettings(
      tableParams,
      searchFilter,
      true
    );

    const { data } = await authenticatedRequest.post(
      `${API_HOST}${pageUrl}/datatable/export`,
      requestParams
    );

    const downloadInterval = setInterval(async () => {
      try {
        const fileresponse = await authenticatedRequest.get(
          `${API_HOST}api/v2/reports/download/${data}`,
          { cancelToken }
        );

        if (onDone) {
          onDone();
        }
        clearInterval(downloadInterval);
        downloadAsXlsxFile(
          fileresponse.data.fileData,
          fileresponse.data.fileName
        );
      } catch (error: any) {
        // 404 - export is not ready. Therefore, need to make another try in 10 seconds.
        if (error.response.status === 404) {
          return;
        }

        if (onError) {
          onError(error);
        }

        clearInterval(downloadInterval);
      }
    }, 10000);

    return downloadInterval;
  },
});
const useBaseFilters = (pageUrl: string) =>
  useAuthenticatedFetch<BaseFiltersResponse>(`${API_HOST}${pageUrl}/filters`);

// End Table And Filters Block

// Columns Block

const useTableColumns = (
  pageUrl: string,
  getSelectedColumns = false,
  hideColumns?: string
) =>
  useAuthenticatedFetch<BaseColumnsProps>(
    `${API_HOST}${pageUrl}/columns?${
      getSelectedColumns ? "getSelected=true" : "getDefault=true"
    }${hideColumns ? `&hideColumns=${hideColumns}` : ""}`
  );

const postSelectedTableColumns = async (
  pageUrl: string,
  columns: BaseColumnsProps
) => {
  const tokenData = getStoredToken();
  return axios.post<BaseColumnsProps>(
    `${API_HOST}${pageUrl}/columns`,
    columns,
    {
      headers: { Authorization: `Bearer ${tokenData?.token}` },
      withCredentials: true,
    }
  );
};

// End Columns Block

export {
  useTableData,
  useBaseFilters,
  exportApi,
  useTableColumns,
  postSelectedTableColumns,
  getTableDataWithoutHook,
};
export type {
  BaseTableDataResponse,
  BaseFiltersResponse,
  BaseTableProps,
  BaseColumnsProps,
};
