import { SyntheticEvent, useEffect, useRef, useState } from "react";
import { AxiosInstance } from "axios";
import saveAs from "file-saver";
import AudioPlayer from "react-h5-audio-player";
import {
  AlarmAttachmentResponse,
  getAlarmAttachment,
} from "../../../core/api/alarmDetail";
import { b64toBlob, notifyApiErrors } from "../../../core/helpers/helpers";

import Table from "../../../ui-lib/components/Tables/Table";
import useTranslations from "../../../core/i18n/useTranslations";
import TableCell from "../../../ui-lib/components/Tables/TableCell";
import DateTimeHelper from "../../../core/helpers/dateTimeHelper";
import Player, { PlayerActions } from "../../../components/Player/Player";

const attachmentTypes = [
  "TwilioLink",
  "TwilioTextLog",
  "TwilioSoundRecording",
  "PapertrailLog",
  "PapertrailLink",
  "TransmitterCommunicationLog",
  "IntegrationLog",
];
export type PlayingRowType = {
  id: number;
  playing: boolean;
  index?: number;
};

const AttachmentsTable = ({
  tableData,
  authReq,
  id,
  playerPermission,
  downloadAttachmentPermission,
}: {
  tableData: AlarmAttachmentResponse[];
  authReq: AxiosInstance;
  id: string;
  playerPermission?: boolean;
  downloadAttachmentPermission?: boolean;
}) => {
  const t = useTranslations();
  const tableElement = useRef<any>(null);
  const playerRef = useRef<PlayerActions>(null);
  const playerActionsRef = useRef<AudioPlayer>(null);

  const { formatDateTimeString } = DateTimeHelper;

  const [rowIDToPlay, setRowIDToPlay] = useState<PlayingRowType>();
  const [rows, setRows] = useState<NodeList>();
  const [src, setSrc] = useState<{ name: string; src: string }>();
  const [isLoading, setIsLoading] = useState(false);

  const isCurrentRowPlaying = (rID: number) =>
    rID === rowIDToPlay?.id && rowIDToPlay.playing;

  const allAttachmentsIDs = tableData?.map((item) => item.id);

  const currentPlayingIndex = allAttachmentsIDs?.findIndex(
    (item) => item === rowIDToPlay?.id
  );

  const handleClickNextPrev = (side: "next" | "prev") => {
    setSrc(undefined);
    setRowIDToPlay(() => ({
      id: allAttachmentsIDs[
        side === "next" ? currentPlayingIndex + 1 : currentPlayingIndex - 1
      ],
      playing: true,
      index:
        side === "next" ? currentPlayingIndex + 1 : currentPlayingIndex - 1,
    }));
  };

  const handlePlayPauseState = (action: string) => {
    setRowIDToPlay((prev) => ({
      ...prev!,
      playing: action === "play" ? true : false,
    }));
  };

  const togglePlayingRowStyles = () => {
    if (rows) {
      Array.from(rows).forEach((row: any, i: number) => {
        if (rowIDToPlay?.index === i) {
          row.classList.add("activeRow");
          row.querySelectorAll("button").forEach((button: any) => {
            button.classList.remove("customDatatableRowActionsOnlyOnHover");
          });
        } else {
          row.classList.remove("activeRow");
          row
            .querySelectorAll("button")
            .forEach((button: HTMLButtonElement) => {
              button.classList.add("customDatatableRowActionsOnlyOnHover");
            });
        }
      });
    }
  };

  const getAttacmentBlob = async (rowID: number, actionType: string) => {
    setIsLoading(true);
    try {
      const fetchData = await getAlarmAttachment(authReq, id, rowID);
      const blob = b64toBlob(
        fetchData.data.fileData,
        "application/octet-stream"
      );
      if (actionType === "play") {
        const url = URL.createObjectURL(blob);
        setSrc({ name: fetchData.data.fileName, src: url });
      }
      if (actionType === "download") {
        saveAs(blob, fetchData.data.fileName);
      }
    } catch (error: any) {
      notifyApiErrors(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const allRows: NodeList = tableElement?.current
      ?.getElement()
      .querySelector("tbody")
      .querySelectorAll("tr");
    setRows(allRows);
  }, [tableElement]);

  useEffect(() => {
    if (rowIDToPlay) {
      getAttacmentBlob(rowIDToPlay.id, "play");
    }
    togglePlayingRowStyles();
  }, [rowIDToPlay?.index]);

  return (
    <>
      <Table<AlarmAttachmentResponse>
        tableRef={tableElement}
        columns={[
          {
            header: t("Common:type"),
            fieldTemplate: (rowData) => (
              <TableCell value={attachmentTypes[rowData.attachmentType]} />
            ),
          },
          {
            header: t("Objects:Identifiertranslations_Identifier"),
            fieldTemplate: (rowData) => (
              <TableCell value={rowData.attachmentIdentifier} />
            ),
          },
          {
            header: t("Common:time"),
            fieldTemplate: (rowData) => (
              <TableCell value={formatDateTimeString(rowData.created)} />
            ),
          },
        ]}
        items={tableData}
        hideEmptyMessage
        noRowsMessage={t("Objects:no_note")}
        showRowHover
        rowActionsColWidth={64}
        rowActions={[
          ...(playerPermission
            ? [
                {
                  hideForRow: (rowData: AlarmAttachmentResponse) =>
                    rowData.attachmentType !== 2,
                  text: (rowData: AlarmAttachmentResponse) =>
                    isCurrentRowPlaying(rowData.id) && !isLoading
                      ? t("Common:pause")
                      : t("Common:play"),
                  icon: (rowData: AlarmAttachmentResponse) =>
                    isCurrentRowPlaying(rowData.id) && !isLoading
                      ? "pause"
                      : "play",
                  onClick: (
                    rowData: AlarmAttachmentResponse,
                    index?: number,
                    e?: SyntheticEvent
                  ) => {
                    // Set row ID and playing state
                    setRowIDToPlay((prev) =>
                      prev?.id === rowData.id
                        ? { ...prev, playing: !prev.playing }
                        : {
                            id: rowData.id,
                            playing: true,
                            index,
                          }
                    );
                    // Open player if it not visible
                    if (!playerRef.current?.isOpened())
                      playerRef.current?.show();

                    // Toggle play/pause
                    playerActionsRef.current?.togglePlay(e!);
                  },
                },
              ]
            : []),
          ...(downloadAttachmentPermission
            ? [
                {
                  text: t("Transmitters:configuration_files_table_filename"),
                  icon: "download",
                  onClick: (rowData: AlarmAttachmentResponse) => {
                    getAttacmentBlob(rowData.id, "download");
                  },
                },
              ]
            : []),
        ]}
      />
      {playerPermission && (
        <Player
          ref={playerRef}
          playerActionsRef={playerActionsRef}
          isLoading={isLoading}
          allAttachmentsIDs={allAttachmentsIDs}
          currentTrack={src}
          setRowIDToPlay={setRowIDToPlay}
          handlePlayPauseState={handlePlayPauseState}
          nextPrevClick={handleClickNextPrev}
          currentPlayingIndex={currentPlayingIndex}
        />
      )}
    </>
  );
};

export default AttachmentsTable;
