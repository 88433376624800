import { useEffect, useState } from "react";
import he from "he";
import { Row } from "../../../../components/Layout/Layout";
import {
  ContactResponse,
  IContactsRelation,
} from "../../../../core/api/contacts/types";
import useTranslations from "../../../../core/i18n/useTranslations";
import Checkbox from "../../../../ui-lib/components/Checkbox/Checkbox";
import Table from "../../../../ui-lib/components/Tables/Table";
import {
  ContactCell,
  CustomTableCell,
} from "../CustomTableCell/CustomTableCell";
import Icon from "../../../../ui-lib/components/Icon";

export const SelectCommonContactsTable = ({
  added = [],
  contacts,
  isLoading,
  pageSettings,
  onPageSettingsChange,
  onContactSelect,
  onResourceSubmit,
  setSelectedContactId,
  selectedContactId,
}: {
  added: number[];
  contacts: ContactResponse[];
  isLoading: boolean;
  pageSettings: {
    page: number;
    pageSize: number;
  };
  onPageSettingsChange: (args: { page: number; pageSize: number }) => void;
  onContactSelect: (contactId: number) => void;
  onResourceSubmit: (
    values: IContactsRelation,
    closeList: boolean,
    resourceId: number
  ) => void;
  setSelectedContactId: (contactId: number | undefined) => void;
  selectedContactId?: number;
}) => {
  const t = useTranslations();
  const [addedContacts, setAddedContacts] = useState<number[]>([...added]);

  useEffect(() => {
    setAddedContacts([...added]);
  }, [added]);

  const showMore =
    contacts.length === pageSettings.pageSize * pageSettings.page;

  return (
    <>
      <Table<ContactResponse>
        columns={[
          {
            fieldTemplate: (rowData) => (
              <CustomTableCell
                label={rowData.name}
                description={he.decode(rowData.description || "")}
              />
            ),
          },
          {
            fieldTemplate: (rowData) => (
              <ContactCell phone={rowData.telephone1} email={rowData.email} />
            ),
          },
          {
            fieldTemplate: (rowData) => {
              let isAdded = !!addedContacts.find((id) => rowData.id === id);
              if (!isAdded) {
                isAdded = rowData.id === selectedContactId;
              }

              return (
                <Row>
                  <CustomTableCell
                    label={
                      rowData.userId
                        ? t("Contacts:private")
                        : t("Contacts:common")
                    }
                    description={
                      rowData.isResource
                        ? t("Contacts:input_resource")
                        : t("Common:contact_person")
                    }
                  />
                  {!isAdded ? (
                    <Checkbox
                      checked={false}
                      onChange={() => {
                        setSelectedContactId(rowData.id);
                        if (!rowData.userId) {
                          onContactSelect(rowData.id);
                        } else {
                          setAddedContacts([...addedContacts, rowData.id]);
                          onResourceSubmit(
                            {} as IContactsRelation,
                            false,
                            rowData.id
                          );
                        }
                      }}
                    />
                  ) : (
                    <Icon name="check" color="blue" />
                  )}
                </Row>
              );
            },
          },
        ]}
        totalResultsStyles={{ marginLeft: "0", paddingLeft: "0" }}
        isLoading={isLoading}
        items={contacts}
        hideHeader
        hideEmptyMessage
        noRowsMessage={t("Table:no_records_found")}
        showRowHover
        withShowMore
        withLazyLoading
        paginatedItems={{
          items: contacts,
          pagination: {
            offset: 0,
            limit: contacts.length,
            total: contacts.length + (showMore ? 1 : 0), // To control show more button visibility
          },
        }}
        onShowMoreClick={() => {
          onPageSettingsChange({
            page: pageSettings.page + 1,
            pageSize: pageSettings.pageSize,
          });
        }}
      />
    </>
  );
};
