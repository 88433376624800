import axios from "axios";
import { BrandingContent, BrandingResponse } from "./types";

class BrandingService {
  static async GetBranding(): Promise<BrandingResponse> {
    const { location } = window;
    const content = await axios.request<BrandingContent>({
      url: "/adminportal/branding/brandingcontent.json",
    });
    return { domain: location.host, content: content.data };
  }
}

export default BrandingService;
