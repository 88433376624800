import { Controller, useFormContext } from "react-hook-form";
import { Column, Row, Spacer } from "../../../components/Layout/Layout";
import useTranslations from "../../../core/i18n/useTranslations";
import Checkbox from "../../../ui-lib/components/Checkbox/Checkbox";

const CheckboxControl = ({ name, label }: { name: string; label: string }) => {
  const { control } = useFormContext();

  return (
    <Column align="start" style={{ width: "100%" }}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Checkbox
            label={label}
            checked={field.value}
            onChange={field.onChange}
            style={{ padding: 0 }}
          />
        )}
      />
    </Column>
  );
};

export const CopySettings = () => {
  const t = useTranslations();

  return (
    <>
      <span className="strong">{t("AlarmReceptions:input_copy")}</span>
      <Spacer size={8} />
      <Row>
        <CheckboxControl
          name="copyAlarmCodeRules"
          label={t("AlarmReceptions:input_alarm_code_rules")}
        />
        <Spacer size={32} />
        <CheckboxControl
          name="copyAlarmCodes"
          label={t("AlarmReceptions:input_alarm_codes")}
        />
      </Row>
      <Spacer size={8} />
      <Row>
        <CheckboxControl
          name="copyIntegrationRules"
          label={t("AlarmReceptions:input_integration_rules")}
        />
        <Spacer size={32} />
        <CheckboxControl
          name="copySettings"
          label={t("AlarmReceptions:input_settings")}
        />
      </Row>
      <Spacer size={8} />
      <Row>
        <CheckboxControl
          name="copyRespondentGroupsAndAlarmHandlings"
          label={t("AlarmReceptions:input_groups_and_alarm_handlings")}
        />
        <Spacer size={32} />
        <CheckboxControl
          name="copyIPWhitelists"
          label={t("AlarmReceptions:input_ip_whitelists")}
        />
      </Row>
      <Spacer size={8} />
      <Row>
        <CheckboxControl
          name="copyMenuAlternatives"
          label={t("AlarmReceptions:input_menu_alternatives")}
        />
        <Spacer size={32} />
        <CheckboxControl
          name="copyAlarmCommands"
          label={t("AlarmReceptions:input_alarm_commands")}
        />
      </Row>
    </>
  );
};
