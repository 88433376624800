import { toSafeInteger } from "lodash";
import { useCallback, useMemo } from "react";
import { useQueryState } from "./useQueryState";
import { searchStringParser } from "../../../ui-lib/utils/urlSearchParser";
import usePageState from "../../pagestate/usePageState";

type SearchState = {
  searchType?: string;
  search?: string;
  exact?: "true" | "false";
};
type SearchFilter = { id?: number; value: string; exact?: "true" | "false" };

const toSearchFilter = (state: SearchState): SearchFilter => ({
  id: state.searchType ? toSafeInteger(state.searchType) : undefined,
  value: state.search ?? "",
  exact: state.exact,
});

const toSearchState = (filter: SearchFilter): SearchState => ({
  searchType: filter.id?.toString(),
  search: filter.value,
  exact: filter.exact,
});

export const useSearchFilter = (searchObj?: any) => {
  const { isSpecialSearch } = usePageState();

  const DEFAULT: SearchState = {
    searchType: isSpecialSearch ? "110" : "-1",
    search: undefined,
    exact: "false",
  };

  const stateToUse = !searchObj
    ? DEFAULT
    : {
        searchType: searchObj.searchType || DEFAULT.searchType,
        search: searchStringParser(searchObj.search),
      };

  const [state, setState] = useQueryState<SearchState>(stateToUse);

  const searchFilter = useMemo(() => toSearchFilter(state), [state]);

  const setSearchFilter = useCallback(
    (args: SearchFilter) => setState(toSearchState(args)),
    [setState]
  );

  const resetSearchFilter = () => setState(DEFAULT);

  return { searchFilter, setSearchFilter, resetSearchFilter };
};
