import BulkDeleteModal from "../../../../components/BulkDeleteModal";
import { deleteAlarmCodeRule } from "../../../../core/api/responsecenters/alarmcoderules";
import useTranslations from "../../../../core/i18n/useTranslations";

export const DeleteAlarmCodeRuleModal = ({
  isOpen,
  responseCenterId,
  ids,
  onClose,
  onDelete,
}: {
  isOpen: boolean;
  responseCenterId: number | string;
  ids: number[];
  onClose: () => void;
  onDelete: (success: boolean) => void;
}) => {
  const t = useTranslations();

  return (
    <BulkDeleteModal
      isOpen={isOpen}
      onClose={onClose}
      onDelete={onDelete}
      ids={ids.map((id) => id.toString())}
      labels={{
        single: t("AlarmReceptions:alarm_code_rules_type"),
        multi: t("AlarmReceptions:alarm_code_rules_type_multi"),
      }}
      apiFunc={(id, authReq) =>
        deleteAlarmCodeRule(id, responseCenterId, authReq)
      }
    />
  );
};
