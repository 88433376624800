import { useEffect, useState } from "react";
import { getErrorKey } from "../../../../components/Errors/ErrorAlert";
import { Column, Spacer } from "../../../../components/Layout/Layout";
import { getAvailableRoles } from "../../../../core/api/administrators/administrators";
import {
  AdministratorDetails,
  AvailableRoles,
} from "../../../../core/api/administrators/types";
import useTranslations from "../../../../core/i18n/useTranslations";
import { notify } from "../../../../ui-lib/components/Alerts/Toast";
import LoadingSpinner from "../../../../ui-lib/components/Loading/LoadingSpinner";
import useSharedAdministrator from "../SharedAdministratorContext/useSharedAdministrator";
import { AdminPermissionsForm } from "./AdminPermissionsForm";
import useUser from "../../../../core/user/useUser";
import { notifyApiErrors } from "../../../../core/helpers/helpers";

const Permissions = () => {
  const t = useTranslations();
  const { data, update, reload } = useSharedAdministrator();
  const { authenticatedRequest } = useUser();

  const [availableRoles, setAvailableRoles] = useState<AvailableRoles>();
  const [isLoading, setIsLoading] = useState(true);

  const fetchRoles = async () => {
    try {
      if (data) {
        const result = await getAvailableRoles(data.id, authenticatedRequest);
        setAvailableRoles(result.data);
      }
    } catch (error: any) {
      if (!data) {
        return notify({
          variant: "error",
          message: "Cannot find user ID. Please create a support ticket",
        });
      }
      notifyApiErrors(error.response?.data?.errors);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchRoles();
  }, []);

  if (isLoading) {
    return <LoadingSpinner theme="primary" />;
  }

  const onSubmit = async (values: AdministratorDetails) => {
    try {
      await update(
        values,
        data?.twoFactorAuthenticationType,
        data?.twoFactorAuthenticationData
      );

      notify({
        message: t("Administrator:edited_success"),
      });
      await reload();
    } catch (error) {
      const errorKey = getErrorKey(error);
      notify({
        message: t(`Errors:${errorKey}`),
        variant: "error",
      });

      throw error;
    }
  };

  return (
    <Column style={{ width: "784px" }} type="top" align="start">
      <h1>{t("Administrator:permissions_title")}</h1>
      <Spacer size={8} />
      <p style={{ color: "var(--Grey-600)" }}>
        {t("Administrator:permissions_description")}
      </p>

      <Spacer size={32} />

      <AdminPermissionsForm
        availableRoles={availableRoles || ({} as AvailableRoles)}
        defaultValues={data}
        onSubmit={onSubmit}
        adminId={data?.id!}
      />
    </Column>
  );
};

export default Permissions;
