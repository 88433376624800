import { ReactNode } from "react";
import { Link } from "react-router-dom";
import styles from "./Table.module.css";

export interface TableLinkProps {
  to: string;
  children: ReactNode;
  fromDashboard?: boolean;
  onClick?: () => void;
}

export const TableLink = ({
  to,
  children,
  fromDashboard,
  onClick,
}: TableLinkProps) => (
  <Link
    to={
      !fromDashboard
        ? to
        : (location) => ({
            ...location,
            pathname: `/adminportal/${to}`,
            search: "",
          })
    }
    onClick={onClick}
    className={styles.tableLink}
  >
    {children}
  </Link>
);
export default TableLink;
