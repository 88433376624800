import { useEffect, useState } from "react";
import { IAdvancedData } from "./AdvancedSettings";
import { Spacer } from "../../../components/Layout/Layout";
import useTranslations from "../../../core/i18n/useTranslations";
import RadioGroup from "../../../ui-lib/components/Radio/RadioGroup";
import Dropdown from "../../../ui-lib/components/Dropdown/Dropdown";
import TextInput from "../../../ui-lib/components/Inputs/TextInput";
import usePageState from "../../../core/pagestate/usePageState";
import i18ninternal from "../../../core/i18n/config";
import Divider from "../../../ui-lib/components/Divider/Divider";
import Checkbox from "../../../ui-lib/components/Checkbox/Checkbox";

export const SettingBoxRender = ({
  setting,
  id,
}: {
  setting: IAdvancedData;
  id: number;
}) => {
  const t = useTranslations();
  const pageState = usePageState();

  const [level, setLevel] = useState<string>(setting.level);
  const [error, setError] = useState<{ [key: string]: string }>();
  const [valueData, setValueData] = useState<string | undefined>(
    setting.defaultValue
  );
  const dropdownOptions = setting.alternatives.map(
    (item: { key: string; value: string }) => ({
      id: item.value,
      name: i18ninternal.exists([`Settings:${setting.identifier}_${item.key}`])
        ? t(`Settings:${setting.identifier}_${item.key}`)
        : item.key,
      isSelected: item.key === valueData,
    })
  );

  useEffect(() => () => pageState.setAdvancedSettingDataEmpty(), []);

  const radioButtons = [
    {
      id: 0,
      value: "G",
      text: t("Settings:UseDefault"),
      idForText: `${setting.identifier}_default`,
    },
    {
      id: 1,
      value: "C",
      text: t("Settings:use_custom_value"),
      idForText: `${setting.identifier}_custom`,
    },
  ];

  const validateInput = (type: string, value: string) => {
    switch (type) {
      case "I":
        if (value.match(/^([0-9]+)?$/) === null) {
          setError({ [setting.identifier]: t("Common:enter_integer_number") });
        } else {
          setError(undefined);
        }
        break;
      case "S":
        if (
          setting.verification &&
          setting.verification !== "none" &&
          value.match(setting.verification) === null
        ) {
          setError({ [setting.identifier]: t("Errors:InvalidAttributeValue") });
        } else {
          setError(undefined);
        }
        break;
      default:
        break;
    }
  };

  const handleChangeRadioGroup = (value: string) => {
    setLevel(value);
    if (value === "G") {
      setError(undefined);
      pageState.setAdvancedSettingData({
        [setting.identifier]: {
          customerId: id,
        },
      });
    }
    if (value === "C") {
      pageState.setAdvancedSettingData({
        [setting.identifier]: {
          customerId: id,
          value: valueData,
        },
      });
      validateInput(setting.type, valueData ?? "");
    }
    if (
      (setting.level === value && value === "G") ||
      (setting.level === value && valueData === setting.defaultValue)
    ) {
      delete pageState.advancedSettingData[setting.identifier];
    }
  };

  // change global state to handle save button in page header
  useEffect(() => {
    pageState.setAdvancedSettingError(!!error?.[setting.identifier]);
  }, [error?.[setting.identifier]]);

  const typeRender = () => {
    switch (setting.type) {
      case "B":
        return (
          <>
            <Checkbox
              style={{ padding: 0 }}
              checked={valueData === "True"}
              onChange={(e) => {
                setValueData(e.checked ? "True" : "False");
                pageState.setAdvancedSettingData({
                  [setting.identifier]: {
                    customerId: id,
                    value: e.checked ? "True" : "False",
                  },
                });
              }}
              label={t("Settings:enabled")}
              disabled={level === "G"}
            />
          </>
        );
      default:
        return (
          <>
            {setting.alternatives && setting.alternatives.length ? (
              <Dropdown
                selectedItem={dropdownOptions.find((e) => e.id === valueData)}
                onSelectItem={(option) => {
                  setValueData(option.id as string);
                  if (
                    (setting.level === level && level === "G") ||
                    (setting.level === level &&
                      setting.defaultValue === option.id)
                  ) {
                    delete pageState.advancedSettingData[setting.identifier];
                  } else {
                    pageState.setAdvancedSettingData({
                      [setting.identifier]: {
                        customerId: id,
                        value: option.id,
                      },
                    });
                  }
                }}
                items={dropdownOptions}
                disabled={level === "G"}
                width="334px"
              />
            ) : (
              <TextInput
                type={setting.type === "I" ? "number" : "text"}
                placeholder={t(`Organizations:PlaceholderType_${setting.type}`)}
                value={valueData}
                onChange={(e) => {
                  setValueData(e.currentTarget.value);
                  validateInput(setting.type, e.currentTarget.value);
                  if (
                    setting.level === level &&
                    setting.defaultValue === e.currentTarget.value
                  ) {
                    delete pageState.advancedSettingData[setting.identifier];
                  } else {
                    pageState.setAdvancedSettingData({
                      [setting.identifier]: {
                        customerId: id,
                        value: e.currentTarget.value,
                      },
                    });
                  }
                }}
                disabled={level === "G"}
                validationError={error?.[setting.identifier]}
              />
            )}
          </>
        );
    }
  };
  return (
    <>
      <RadioGroup
        wrapperStyle={{ padding: "16px 0" }}
        name={setting.identifier}
        items={radioButtons}
        selectedValue={radioButtons.find((item) => item.value === level)?.value}
        onChange={(value) => handleChangeRadioGroup(value)}
        noBorder
      />
      <Divider />
      <Spacer size={16} />
      {typeRender()}
    </>
  );
};
