import { useRef, useState } from "react";
import { AxiosInstance } from "axios";
import {
  IAllIntegrationTypes,
  IOrgIntegration,
} from "../../../core/api/organizations/types";
import { AddIntegartionsModal } from "./AddIntegrationsModal";
import { DeleteIntegrationModal } from "./DeleteIntegrationModal";
import useTranslations from "../../../core/i18n/useTranslations";
import Icon from "../../../ui-lib/components/Icon";
import Table from "../../../ui-lib/components/Tables/Table";
import TableCell from "../../../ui-lib/components/Tables/TableCell";

import styles from "./Integrations.module.css";

/**
 * Add new icons according back-end types of integrations
 */
const integrationIcons = new Map();
integrationIcons.set("general", "skyresponse_color");
integrationIcons.set("bitbucket", "bitbucket");

const columns = [
  {
    key: "integrationIcon",
    fieldTemplate: (rowData: IOrgIntegration) => {
      return (
        <TableCell
          value={
            <Icon
              /** Set skyresponce logo if no icon founded */
              name={
                integrationIcons.get(rowData.integrationType) ??
                "skyresponse_color"
              }
              size={32}
            />
          }
        />
      );
    },
    style: { width: "64px" },
  },
  {
    key: "integrationData",
    fieldTemplate: (rowData: IOrgIntegration) => {
      return (
        <TableCell
          value={
            <>
              <p className={styles.integrationTitle}>{rowData.name}</p>
              <span className={styles.integrationDescription}>
                {rowData.description}
              </span>
            </>
          }
        />
      );
    },
  },
];

export const IntegrationsTable = ({
  data,
  types,
  getOrganizationIntegrations,
}: {
  data: IOrgIntegration[];
  types?: IAllIntegrationTypes;
  getOrganizationIntegrations: (
    orgId: string,
    authenticatedRequest: AxiosInstance
  ) => Promise<void>;
}) => {
  const t = useTranslations();

  const currentIntegrationData = useRef<IOrgIntegration>();

  const [editIntegrationOpen, setEditIntegrationOpen] = useState(false);
  const [deleteIntegrationOpen, setDeleteIntegrationOpen] = useState(false);

  return (
    <>
      <Table<IOrgIntegration>
        hideEmptyMessage
        hideHeader
        rowActionContextMenu
        items={data}
        columns={columns}
        showRowsActionsOnHover={false}
        rowActions={[
          {
            icon: "pencil",
            text: t("Common:label_edit"),
            onClick: (rowData) => {
              currentIntegrationData.current = rowData;
              setEditIntegrationOpen(true);
            },
          },
          {
            icon: "trash",
            text: t("Common:delete"),
            onClick: (rowData) => {
              currentIntegrationData.current = rowData;
              setDeleteIntegrationOpen(true);
            },
          },
        ]}
      />
      {editIntegrationOpen && (
        <AddIntegartionsModal
          isNew={false}
          open={editIntegrationOpen}
          close={() => setEditIntegrationOpen(false)}
          integrationData={currentIntegrationData.current}
          types={types}
          orgId={currentIntegrationData.current!.organizationId.toString()}
          getOrganizationIntegrations={getOrganizationIntegrations}
        />
      )}
      {deleteIntegrationOpen && (
        <DeleteIntegrationModal
          open={deleteIntegrationOpen}
          close={() => setDeleteIntegrationOpen(false)}
          integration={currentIntegrationData.current}
          fetchIntegrations={getOrganizationIntegrations}
        />
      )}
    </>
  );
};
