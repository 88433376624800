/* eslint-disable react/jsx-props-no-spreading */
import { Controller, FormProvider, useForm } from "react-hook-form";
import { Column, Row, Spacer } from "../../../../components/Layout/Layout";
import {
  ResponseCenterDistributionDetails,
  ResponseCenterDistributionParams,
} from "../../../../core/api/responsecenters/types";
import {
  validateEmail,
  validatePhoneNumber,
} from "../../../../core/helpers/validation";
import useTranslations from "../../../../core/i18n/useTranslations";
import Button from "../../../../ui-lib/components/Button/Button";
import Checkbox from "../../../../ui-lib/components/Checkbox/Checkbox";
import TextInput from "../../../../ui-lib/components/Inputs/TextInput";
import ModalContent from "../../../../ui-lib/components/Popup/ModalContent";
import { DistributorSettings } from "./DistributorSettings";

export const DistributorForm = ({
  labels,
  defaultValues,
  onSubmit,
  onClose,
}: {
  labels: {
    title: string;
    description?: string;
    submit: string;
  };
  defaultValues?: ResponseCenterDistributionDetails;
  onSubmit: (values: ResponseCenterDistributionParams) => Promise<void>;
  onClose: () => void;
}) => {
  const t = useTranslations();

  const methods = useForm<ResponseCenterDistributionParams>({
    defaultValues: defaultValues ?? {
      contactGetAuthorizedUrl: false,
      sendResponseAlarm: true,
      onResponse: true,
      onProperty: true,
      onCare: true,
      onService: false,
    },
  });
  const {
    control,
    formState: { isSubmitting, errors },
    handleSubmit,
  } = methods;

  return (
    <ModalContent onClose={onClose} width="848px">
      <h2 className="subTitle">{labels.title}</h2>
      {labels.description && (
        <>
          <Spacer size={16} />
          <p style={{ color: "var(--Grey-600)" }}>{labels.description}</p>
        </>
      )}

      <Spacer size={16} />

      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit(async (formValues) => {
            await onSubmit(formValues);
          })}
        >
          <Row align="start">
            <Controller
              name="name"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextInput
                  required
                  label={t("Common:name")}
                  placeholder={t(
                    "AlarmReceptions:distributor_input_name_placeholder"
                  )}
                  value={field.value}
                  onChange={field.onChange}
                  validationError={
                    errors.name && t("Errors:input_field_required")
                  }
                />
              )}
            />
            <Spacer size={32} />
            <Controller
              name="email"
              control={control}
              rules={{
                maxLength: 60,
                validate: validateEmail,
              }}
              render={({ field }) => (
                <TextInput
                  label={t("Common:email")}
                  placeholder={t("Common:placeholder_email")}
                  value={field.value}
                  onChange={field.onChange}
                  validationError={
                    errors.email && t("Errors:input_email_format")
                  }
                />
              )}
            />
          </Row>

          <Spacer size={16} />

          <Row align="start">
            <Controller
              name="telephonenumber"
              control={control}
              rules={{
                validate: (value) => {
                  if (!value || value.length === 0) {
                    return true;
                  }

                  return validatePhoneNumber(value);
                },
              }}
              render={({ field }) => (
                <TextInput
                  label={t("Common:labels_phone_number")}
                  placeholder={t("Common:input_phone_description")}
                  value={field.value}
                  onChange={field.onChange}
                  validationError={
                    errors?.telephonenumber &&
                    t("Errors:response_invalid_telephone_number_format")
                  }
                />
              )}
            />
            <Spacer size={32} />
            <Column style={{ width: "100%" }} />
          </Row>

          <Spacer size={16} />

          <Row type="left" style={{ width: "100%" }}>
            <Controller
              name="contactGetAuthorizedUrl"
              control={control}
              render={({ field }) => (
                <Checkbox
                  label={t("AlarmReceptions:distributor_input_get_auth_url")}
                  checked={field.value}
                  onChange={field.onChange}
                  style={{ padding: 0 }}
                />
              )}
            />
          </Row>

          <Spacer size={16} />

          <DistributorSettings />

          <Spacer size={32} />

          <Row type="left">
            <Button
              type="submit"
              variant="primary"
              text={labels.submit}
              loading={isSubmitting}
            />
            <Spacer size={8} />
            <Button
              variant="secondary"
              text={t("Common:cancel")}
              onClick={onClose}
            />
          </Row>
        </form>
      </FormProvider>
    </ModalContent>
  );
};
