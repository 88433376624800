import { useState } from "react";
import { ObjectDetailsModel } from "../../../../core/api/objects/types";
import { Row, Spacer } from "../../../../components/Layout/Layout";
import useTranslations from "../../../../core/i18n/useTranslations";
import Button from "../../../../ui-lib/components/Button/Button";
import { ManualSyncTable } from "./ManualSyncTable";
import { manualSyncObjects } from "../../../../core/api/objects/objects";
import useUser from "../../../../core/user/useUser";
import { notify } from "../../../../ui-lib/components/Alerts/Toast";
import { notifyApiErrors } from "../../../../core/helpers/helpers";
import { ManualSyncInfoModal } from "./ManualSyncInfoModal";

export const ManualSync = ({
  objectData,
  fetchObject,
}: {
  objectData: ObjectDetailsModel;
  fetchObject: () => void;
}) => {
  const t = useTranslations();
  const { authenticatedRequest } = useUser();
  const [syncInfoModalOpen, setSyncInfoModalOpen] = useState(false);

  const syncObject = async () => {
    setSyncInfoModalOpen(true);
    try {
      await manualSyncObjects(objectData.id, authenticatedRequest);
      notify({ message: t("Objects:sync_in_queue_send") });
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    }
  };

  return (
    <>
      <ManualSyncInfoModal
        isOpen={syncInfoModalOpen}
        setIsOpen={setSyncInfoModalOpen}
        fetchObject={fetchObject}
      />

      <h2>{t("Objects:manual_sync_header")}</h2>
      <Spacer size={8} />
      <p>{t("Objects:manual_sync_description")}</p>

      <Spacer size={8} />

      <Row style={{ width: "100%", flex: 0 }}>
        <h2>{t("Objects:sync_with_external_system")}</h2>
        <Spacer />
        <Button
          disabled={!objectData.triggeringSyncAllowed}
          type="button"
          variant="secondary"
          image="refresh"
          text={t("Objects:synchronize_object")}
          onClick={syncObject}
        />
      </Row>

      <Spacer size={8} />

      <ManualSyncTable
        data={{
          syncStatus: objectData.syncStatus,
          latestIntegrationSyncAttempt: objectData.latestIntegrationSyncAttempt,
          lastIntegrationSync: objectData.lastIntegrationSync,
        }}
      />
    </>
  );
};
