import axios from "axios";
import { useState } from "react";
import { exportApi } from "../../core/api/common_table";
import useTranslations from "../../core/i18n/useTranslations";
import useLanguage from "../../core/i18n/useLanguage";
import useUser from "../../core/user/useUser";
import { notify } from "../../ui-lib/components/Alerts/Toast";
import { getErrorKey } from "../Errors/ErrorAlert";
import { SearchFilter } from "../FilterSearch/TableSearch";
import ExportSettings from "./ExportSettings";
import { Columns, ExportProps } from "./types";

type ExportObjectsProps = Omit<ExportProps, "settings" | "onExport"> & {
  searchFilter?: SearchFilter;
  columnInfo?: Columns;
};

interface ExportParams {
  start?: string;
  end?: string;
  languageSelect?: string;
}

const ExportAlarmHistorySettings = ({
  labels,
  tableParams,
  searchFilter,
  onClose,
  columnInfo,
}: ExportObjectsProps) => {
  const t = useTranslations();
  const languageStore = useLanguage();
  const { authenticatedRequest } = useUser();

  const [loading, setLoading] = useState(false);
  const [cancelSource, setCancelSource] = useState(axios.CancelToken.source());
  const [downloadInterval, setDownloadInterval] =
    useState<NodeJS.Timeout | null>();

  const onDone = () => {
    setLoading(false);
    onClose();
  };

  const onError = (error: any) => {
    setLoading(false);

    const errorKey = getErrorKey(error);
    notify({
      message: t(`Errors:${errorKey}`),
      variant: "error",
    });
  };

  const { exportAsExcel } = exportApi(
    authenticatedRequest,
    "api/v2/alarms",
    onDone,
    onError
  );

  const startDownloadExport = async (params: ExportParams) => {
    const { languageSelect, start, end } = params;

    const newDownloadInterval = await exportAsExcel({
      tableParams: {
        start,
        end,
        sort: tableParams?.sort,
        filter: tableParams?.filter,
        customers: tableParams?.customers,
        alarmCodes: tableParams?.alarmCodes,
        language: languageSelect,
        hideautodeactivated: tableParams?.hideautodeactivated,
      },
      searchFilter,
      cancelToken: cancelSource.token,
    });

    setDownloadInterval(newDownloadInterval);
  };

  return (
    <ExportSettings
      labels={labels}
      settings={{
        supportDateRange: true,
        columnInfo,
      }}
      tableParams={tableParams}
      state={{
        loading,
      }}
      onClose={() => {
        if (downloadInterval) {
          clearInterval(downloadInterval);
          setDownloadInterval(null);
        }
        cancelSource.cancel();
        setCancelSource(axios.CancelToken.source());
        setLoading(false);
        onClose();
      }}
      onExport={async (args) => {
        try {
          setLoading(true);

          const start = args.timeSpan?.start?.toISOString();
          const end = args.timeSpan?.end?.toISOString();
          const languageSelect = languageStore.getStoredLanguage() ?? undefined;

          await startDownloadExport({ start, end, languageSelect });
        } catch (error) {
          if (axios.isCancel(error)) {
            return;
          }

          const errorKey = getErrorKey(error);
          notify({ message: t(`Errors:${errorKey}`), variant: "error" });
        }
      }}
      isLoading={loading}
    />
  );
};

export default ExportAlarmHistorySettings;
