import { useState, KeyboardEvent, useRef } from "react";
import { Spacer } from "../Layout/Layout";
import Dropdown from "../../ui-lib/components/Dropdown/Dropdown";
import TextInput from "../../ui-lib/components/Inputs/TextInput";
import Icon from "../../ui-lib/components/Icon";
import Button from "../../ui-lib/components/Button/Button";
import useUser from "../../core/user/useUser";
import {
  homeDataRetriever,
  homeDataSaver,
} from "../../ui-lib/utils/homeDataHelper";

export type SearchFilter = { id?: number; value: string };

const FilterSearch = ({
  placeholder,
  types = [],
  onSubmit,
  initialSearch,
  disabled = false,
  searchText = "Search",
  minSearchLength,
}: {
  placeholder?: string;
  searchText?: string;
  types?: {
    id: number;
    name: string;
    placeholder?: string;
  }[];
  onSubmit: (filter: SearchFilter) => void;
  initialSearch?: SearchFilter;
  disabled?: boolean;
  minSearchLength?: number;
}) => {
  const user = useUser();
  const lastUsedFilter = homeDataRetriever(user).searchBar || 1;
  const inputRef = useRef<HTMLInputElement>(null);
  const [filter, setFilter] = useState<SearchFilter>(
    initialSearch || { id: 1, value: "" }
  );
  const items = types.map((i) => ({
    ...i,
    isSelected: i.id === lastUsedFilter || i.id === filter.id,
  }));
  const selectedItem = items.find((i) => i.isSelected);

  return (
    <>
      <Dropdown
        items={items}
        selectedItem={selectedItem}
        placeholder="Search filter"
        width={205}
        disabled={disabled}
        onSelectItem={(i) => {
          homeDataSaver(user, "searchBar", +i.id);
          setFilter({
            ...filter,
            id: i.id as number,
          });
        }}
        withoutCheckIcon={false}
        dataCy="home_search_dropdown"
      />
      <Spacer size={8} />
      <TextInput
        inputRef={inputRef}
        placeholder={
          types.find((type) => type.id === filter.id)?.placeholder ||
          placeholder ||
          ""
        }
        disabled={disabled}
        value={filter.value}
        onChange={(e) => {
          setFilter({
            ...filter,
            value: e.currentTarget.value,
          });
        }}
        renderIcon={() => <Icon name="search" size={24} color="White" />}
        iconPosition="right"
        onKeyDown={(event: KeyboardEvent<HTMLElement>) => {
          if (
            minSearchLength &&
            (inputRef.current?.value === undefined ||
              inputRef.current?.value?.length < minSearchLength)
          ) {
            return;
          }
          if (event.key === "Enter") {
            inputRef.current?.blur();
            onSubmit(filter);
          }
        }}
        dataCy="home_search_input"
      />
      <Spacer size={8} />
      <Button
        text={searchText}
        image="search"
        variant="primary"
        disabled={
          disabled ||
          (minSearchLength !== undefined &&
            filter.value.length < minSearchLength)
        }
        onClick={() => onSubmit(filter)}
        dataCy="home_search_btn"
      />
    </>
  );
};

export default FilterSearch;
