import {
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { ReactNode, useEffect } from "react";
import he from "he";
import { observer } from "mobx-react";
import useTranslations from "../../core/i18n/useTranslations";
import useUser from "../../core/user/useUser";
import { Column, Row } from "../../components/Layout/Layout";
import PageHeader from "../../ui-lib/components/PageHeader/PageHeader";
import Divider from "../../ui-lib/components/Divider/Divider";
import styles from "../Layout.module.css";
import { useSaveObject } from "../../core/SaveObjectContext/hooks";
import Button from "../../ui-lib/components/Button/Button";
import PopupContextMenu from "../../ui-lib/components/Menus/PopupContextMenu/PopupContextMenu";
import { PopupContextMenuLinkButton } from "../../ui-lib/components/Menus/PopupContextMenu/PopupContextMenuLinkButton";
import Modal from "../../ui-lib/components/Popup/Modal";
import useSharedContact from "./SharedContactContext/useSharedContact";
import { notify } from "../../ui-lib/components/Alerts/Toast";
import LoadingSpinner from "../../ui-lib/components/Loading/LoadingSpinner";
import { useSubNav } from "../../components/LoggedinPage/SubNavContext";
import { SaveObjectProvider } from "../../core/SaveObjectContext/SaveObjectContext";
import { SharedContactProvider } from "./SharedContactContext/SharedContactContext";
import { ContactDetails } from "../../core/api/contacts/types";
import { ContactDetailsGeneral } from "./General/ContactDetailsGeneral";
import DeleteContactModal from "../Objects/ObjectResponse/Contacts/DeleteContactModal";
import { Availability } from "./Accessibility/Availability";
import { getPrivateContactFromStore } from "../../ui-lib/utils/privateContactHelper";

const parentPath = "/adminportal/common-contacts";

export const subMenuItems = (url: string, baseUrl?: string) => [
  {
    key: "Menu:general",
    to: baseUrl || url,
  },
  {
    key: "Menu:availability",
    to: `${baseUrl || url}/availability`,
  },
];

const ContactDetailsWrapper = ({
  data,
  children,
}: {
  data: ContactDetails;
  children: ReactNode;
}) => {
  const user = useUser();
  const history = useHistory();
  const t = useTranslations();
  const { canSave, isDirty, isSaving, onSave } = useSaveObject();

  return (
    <Row align="start" style={{ width: "100%" }}>
      <Column
        style={{ width: "100%", height: "100vh", overflow: "hidden" }}
        type="top"
      >
        <PageHeader
          title={he.decode(data.name || "")}
          userName={user.data?.name}
          icon="phone"
        >
          {canSave && (
            <Button
              text={t("Common:save")}
              disabled={!isDirty || isSaving}
              loading={isSaving}
              onClick={onSave}
            />
          )}
          <PopupContextMenu
            className="mt-8"
            key="object_details_actions"
            position="right bottom"
            nested
            trigger={() => (
              <div>
                <Button
                  type="button"
                  variant="secondary"
                  image="dots-horizontal"
                  text={t("Common:other")}
                />
              </div>
            )}
          >
            <Modal
              trigger={() => (
                <PopupContextMenuLinkButton
                  icon="trash"
                  text={t("Contacts:delete")}
                />
              )}
            >
              {(close) => (
                <DeleteContactModal
                  contact={data}
                  onClose={close}
                  onDelete={() => {
                    close();
                    history.push(parentPath);
                  }}
                />
              )}
            </Modal>
          </PopupContextMenu>
        </PageHeader>
        <Divider />
        <div
          className={styles.wrapper}
          style={{
            height: "auto",
            overflowY: "auto",
          }}
        >
          <Column
            className={styles.content}
            type="top"
            align="start"
            style={{ padding: "32px" }}
          >
            {children}
          </Column>
        </div>
      </Column>
    </Row>
  );
};

const CommonContactsDetails = () => {
  const { path } = useRouteMatch();
  const { data } = useSharedContact();

  return (
    <Switch>
      <Route path={`${path}/availability`}>
        <ContactDetailsWrapper data={data!}>
          <Availability />
        </ContactDetailsWrapper>
      </Route>
      <Route path={path}>
        <ContactDetailsWrapper data={data!}>
          <ContactDetailsGeneral />
        </ContactDetailsWrapper>
      </Route>
    </Switch>
  );
};

const ContactDetailsLoader = () => {
  const history = useHistory();
  const { data, isError, errorMessage } = useSharedContact();

  useEffect(() => {
    if (isError) {
      notify({
        variant: "error",
        message: errorMessage,
      });
      history.replace(parentPath);
    }
  }, [isError]);

  if (!data) {
    return <LoadingSpinner theme="primary" />;
  }

  return <CommonContactsDetails />;
};

const ContactDetailsContainer = () => {
  const t = useTranslations();
  const { setSubNav } = useSubNav();
  const { id } = useParams<{ id: string }>();
  const { url } = useRouteMatch();

  const sessionPrevPath = sessionStorage.getItem("prevPath");
  const prevPath = sessionPrevPath ? JSON.parse(sessionPrevPath) : undefined;

  const pathToObject = getPrivateContactFromStore(+id);
  const backUrl = pathToObject || parentPath;
  const backUrlText = pathToObject
    ? t("Contacts:object_contacts")
    : t("Common:contacts_label");

  useEffect(() => {
    const nav = {
      menu: subMenuItems(url),
      backUrl: prevPath?.path || backUrl,
      backText: prevPath?.prevText || backUrlText,
    };
    setSubNav(nav);
    return () => {
      setSubNav(undefined);
    };
  }, []);

  return (
    <SharedContactProvider id={id}>
      <SaveObjectProvider>
        <ContactDetailsLoader />
      </SaveObjectProvider>
    </SharedContactProvider>
  );
};

export default observer(ContactDetailsContainer);
