import { useForm, Controller } from "react-hook-form";
import { useState } from "react";
import useTranslations from "../../../core/i18n/useTranslations";
import ModalContent from "../../../ui-lib/components/Popup/ModalContent";
import { MedicalTechnicalNotesCreate } from "../../../core/api/objects/types";
import { Row, Spacer } from "../../../components/Layout/Layout";
import TextArea from "../../../ui-lib/components/Inputs/Textarea";
import Button from "../../../ui-lib/components/Button/Button";
import { saveMedicalTechnicalNote } from "../../../core/api/objects/objects";
import useUser from "../../../core/user/useUser";
import { getErrorKey } from "../../../components/Errors/ErrorAlert";
import { notify } from "../../../ui-lib/components/Alerts/Toast";

export const MedicalTechnicalModal = ({
  onClose,
  onAdd,
  title,
  type,
  objectId,
}: {
  onClose: () => void;
  onAdd: () => void;
  title: string;
  type: string;
  objectId: string;
}) => {
  const t = useTranslations();
  const { authenticatedRequest } = useUser();
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [availableCharacters, setAvailableCharacters] = useState(800);

  const {
    control,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm<MedicalTechnicalNotesCreate>();

  const onSubmit = async (data: MedicalTechnicalNotesCreate) => {
    try {
      setIsFormLoading(true);
      await saveMedicalTechnicalNote(
        objectId,
        type,
        data,
        authenticatedRequest
      );
      onAdd();
      onClose();
    } catch (error) {
      const errorKey = getErrorKey(error);
      notify({ message: t(`Errors:${errorKey}`), variant: "error" });
    } finally {
      setIsFormLoading(false);
    }
  };

  return (
    <ModalContent onClose={onClose} width="576px">
      <h2>{title}</h2>

      <Spacer size={16} />

      <form>
        <Controller
          name="note"
          control={control}
          rules={{ required: true, maxLength: 800 }}
          render={({ field }) => (
            <TextArea
              required
              label={t("Objects:note_text")}
              placeholder={t("Objects:enter_note")}
              onChange={(e) => {
                if (e.target.value.length > 800) {
                  setValue(field.name, e.target.value.substring(0, 800));
                  setAvailableCharacters(0);
                } else {
                  field.onChange(e);
                  setAvailableCharacters(800 - e.target.value.length);
                }
              }}
              value={field.value}
              validationError={
                errors.note &&
                (errors.note?.type === "maxLength"
                  ? t("Errors:length_to_long").replace("{0}", "800")
                  : t(
                      "AlarmReceptions:edit_emergencycenter_setting_validation_value_required"
                    ))
              }
            />
          )}
        />
        <p style={{ color: "var(--Grey-600)" }}>
          <span
            style={{
              color:
                availableCharacters < 800
                  ? "var(--Primary-700)"
                  : "var(--Grey-600)",
            }}
          >
            {availableCharacters}
          </span>{" "}
          {t("Common:characters_left")}
        </p>

        <Spacer size={32} />

        <Row type="left">
          <Button
            loading={isFormLoading}
            variant="primary"
            disabled={isFormLoading || availableCharacters === 800}
            text={t("Common:save")}
            onClick={() => {
              handleSubmit(onSubmit)();
            }}
          />
          <Spacer size={8} />
          <Button
            variant="secondary"
            text={t("Common:cancel")}
            onClick={onClose}
          />
        </Row>
      </form>
    </ModalContent>
  );
};
