import { observer } from "mobx-react";
import { getErrorKey } from "../../../../components/Errors/ErrorAlert";
import { Row, Spacer } from "../../../../components/Layout/Layout";
import { removeUserAdminRelation } from "../../../../core/api/useradmins/useradmins";
import useTranslations from "../../../../core/i18n/useTranslations";
import useUser from "../../../../core/user/useUser";
import { notify } from "../../../../ui-lib/components/Alerts/Toast";
import Button from "../../../../ui-lib/components/Button/Button";
import ModalContent from "../../../../ui-lib/components/Popup/ModalContent";

const DeleteRelatedAdminModal = ({
  userAdminId,
  onClose,
  onDelete,
}: {
  userAdminId?: number;
  onClose: () => void;
  onDelete: () => void;
}) => {
  const t = useTranslations();

  const { authenticatedRequest } = useUser();

  const onSubmit = async () => {
    if (!userAdminId) {
      return;
    }

    try {
      await removeUserAdminRelation(userAdminId, authenticatedRequest);

      notify({
        message: t("Messages:delete_related_admin_success"),
      });
      onDelete();
    } catch (error) {
      const errorKey = getErrorKey(error);
      notify({
        message: t(`Errors:${errorKey}`),
        variant: "error",
      });
    }
  };

  return (
    <ModalContent onClose={onClose} width="576px">
      <h2 className="subTitle">{t("Objects:delete_related_admin")}</h2>
      <Spacer size={16} />
      <p style={{ color: "var(--Grey-600)" }}>
        {t("Objects:delete_related_admin_confirm")}
      </p>

      <Spacer size={32} />

      <Row type="left">
        <Button
          variant="destructive"
          text={t("Objects:delete_related_admin")}
          onClick={onSubmit}
        />
        <Spacer size={8} />
        <Button
          variant="secondary"
          text={t("Common:cancel")}
          onClick={onClose}
        />
      </Row>
    </ModalContent>
  );
};

export default observer(DeleteRelatedAdminModal);
