import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { Controller, FieldError, useForm } from "react-hook-form";
import {
  Column,
  Row,
  Spacer,
  TwoColGrid,
} from "../../../../components/Layout/Layout";
import { getAlarmReceptionContact } from "../../../../core/api/responsecenters/responsecenters";
import { IAlarmReceptionContact } from "../../../../core/api/responsecenters/types";
import {
  getColonFormattedTimeString,
  isEmpty,
  notifyApiErrors,
} from "../../../../core/helpers/helpers";
import {
  validateEmail,
  validateEmergencyPhoneNumber,
  validatePhoneNumber,
} from "../../../../core/helpers/validation";
import useTranslations from "../../../../core/i18n/useTranslations";
import useUser from "../../../../core/user/useUser";
import TimePicker from "../../../../ui-lib/components/DateAndTime/TimePicker";
import TextInput from "../../../../ui-lib/components/Inputs/TextInput";
import StyledModal from "../../../../ui-lib/components/Modal/Modal";
import useSharedContactsContext from "./SharedContactsContext";

type openfunc = (contactId?: number) => Promise<void>;

export declare type EditContactDialogActions = {
  open: openfunc;
};
export interface EditContactDialogProps {
  onSubmit?: () => void;
  onClose?: () => void;
}
export const EditAlarmReceptionContact = forwardRef<
  EditContactDialogActions,
  EditContactDialogProps
>((props: EditContactDialogProps, ref) => {
  const { onSubmit, onClose } = props;
  const t = useTranslations();
  const [isEditContactOpen, setIsEditContactOpen] = useState(false);
  const isNew = useRef<boolean>(false);
  const { id, editContact } = useSharedContactsContext();
  const { authenticatedRequest } = useUser();
  const {
    control,
    formState: { isSubmitting, errors },
    handleSubmit,
    getValues,
    reset,
    watch,
    trigger,
  } = useForm<IAlarmReceptionContact>();

  const closeModal = () => {
    setIsEditContactOpen(false);
    reset({});
    if (onClose !== undefined) {
      onClose();
    }
  };

  const getErrorMessage = (error?: FieldError) => {
    if (error === undefined) return "";

    if (error.type === "alternately") {
      return t("Errors:alternately");
    }

    if (error.type === "required" && error.ref?.name === "Email") {
      return t("AlarmReceptions:edit_contact_email_required");
    }

    if (error.type === "hasEmailOrTelephone")
      return t("Errors:input_contacts_required_fields");

    if (error.type === "validateEmailIfExists")
      return t("Errors:input_email_format");

    if (
      error.type === "validateTelephone" ||
      error.type === "validPhone" ||
      error.type === "validateOptionalTelephone"
    )
      return t("Errors:response_invalid_telephone_number_format");

    if (error.type === "phoneOrEmail") {
      return t("AlarmReceptions:edit_contact_emailorphone_required");
    }

    return "Undefined Error";
  };

  const submit = async (data: IAlarmReceptionContact) => {
    try {
      const postData = {
        ...data,
        startTime: data.startTime ? data.startTime.replace(":", "") : undefined,
        endTime: data.endTime ? data.endTime.replace(":", "") : undefined,
      };
      await editContact(postData);
      onSubmit?.();
      closeModal();
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    }
  };
  const loadData = async (contactId?: number) => {
    try {
      if (!isEmpty(contactId)) {
        const result = await getAlarmReceptionContact(
          id,
          contactId!,
          authenticatedRequest
        );
        reset({
          ...result.data,
          startTime: getColonFormattedTimeString(result.data.startTime),
          endTime: getColonFormattedTimeString(result.data.endTime),
        });
      } else {
        reset({});
      }
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors, error.response?.status);
    }
  };
  const validatePhoneNumberOrEmpty = (value: any) =>
    isEmpty(value) ||
    validatePhoneNumber(value) ||
    validateEmergencyPhoneNumber(value);

  const validateEmailOrEmpty = (value: any) =>
    isEmpty(value) || validateEmail(value);

  const validatePhoneOrMail = () =>
    !isEmpty(getValues("email")) || !isEmpty(getValues("telephone1"));

  const validateAlternatelyTelephone1 = () => {
    if ((watch("telephone2") || watch("telephone3")) && !watch("telephone1")) {
      return false;
    }
    return true;
  };

  const validateAlternatelyTelephone2 = () => {
    if (watch("telephone3") && !watch("telephone2")) {
      return false;
    }
    return true;
  };

  useImperativeHandle<EditContactDialogActions, EditContactDialogActions>(
    ref,
    () => {
      const actions: EditContactDialogActions = {
        open: async function open(contactId?: number) {
          isNew.current = isEmpty(contactId);
          await loadData(contactId);
          setIsEditContactOpen(true);
        },
      };
      return actions;
    }
  );

  return (
    <StyledModal
      onClose={closeModal}
      isOpen={isEditContactOpen}
      cancelBtnText={t("Common:cancel")}
      approveBtnText={
        isNew.current
          ? t("AlarmReceptions:save_contact_new")
          : t("AlarmReceptions:save_contact")
      }
      modalTitle={
        isNew.current
          ? t("AlarmReceptions:edit_contact_title_new")
          : t("AlarmReceptions:edit_contact_title")
      }
      onApproveBtnClick={handleSubmit(submit)}
      onCancelBtnClick={closeModal}
      closeOnDocumentClick={false}
      isLoading={isSubmitting}
    >
      <p>{t("Common:create_description")}</p>
      <Spacer size={16} />
      <form>
        <Column type="top">
          <TwoColGrid>
            <Controller
              name="name"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <TextInput
                  required
                  css={{ flex: 1 }}
                  placeholder={t(
                    "AlarmReceptions:edit_contact_placeholder_name"
                  )}
                  label={t("Common:name")}
                  value={field.value || ""}
                  onChange={field.onChange}
                  validationError={
                    errors.name &&
                    t("AlarmReceptions:edit_contact_name_required")
                  }
                />
              )}
            />
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <TextInput
                  css={{ flex: 1 }}
                  placeholder={t(
                    "AlarmReceptions:edit_contact_placeholder_description"
                  )}
                  label={t("AlarmReceptions:edit_contact_label_description")}
                  value={field.value || ""}
                  onChange={field.onChange}
                />
              )}
            />
            <Controller
              name="email"
              control={control}
              rules={{
                validate: {
                  phoneOrEmail: validatePhoneOrMail,
                  validEmail: validateEmailOrEmpty,
                },
              }}
              render={({ field }) => (
                <TextInput
                  required={!getValues("telephone1")}
                  css={{ flex: 1 }}
                  placeholder={t("Common:placeholder_email")}
                  label={t("Common:email")}
                  value={field.value || ""}
                  onChange={(e) => {
                    field.onChange(e.currentTarget.value);
                    trigger("telephone1");
                  }}
                  validationError={
                    (errors.email &&
                      errors.email.type === "phoneOrEmail" &&
                      t(
                        "AlarmReceptions:edit_contact_emailorphone_required"
                      )) ||
                    (errors.email &&
                      errors.email.type === "validEmail" &&
                      t("Errors:input_email_format")) ||
                    undefined
                  }
                />
              )}
            />
            <Spacer size={0} />
            <Controller
              name="telephone1"
              control={control}
              rules={{
                validate: {
                  validPhone: validatePhoneNumberOrEmpty,
                  phoneOrEmail: validatePhoneOrMail,
                  alternately: validateAlternatelyTelephone1,
                },
              }}
              render={({ field }) => (
                <TextInput
                  required={!getValues("email")}
                  css={{ flex: 1 }}
                  label={t("Filters:Telephone")}
                  placeholder={t("Common:input_phone_description")}
                  value={field.value || ""}
                  onChange={(e) => {
                    field.onChange(e.currentTarget.value);
                    trigger(["email", field.name]);
                  }}
                  validationError={getErrorMessage(errors.telephone1)}
                />
              )}
            />
            <Controller
              name="telephone2"
              control={control}
              rules={{
                validate: {
                  validPhone: validatePhoneNumberOrEmpty,
                  alternately: validateAlternatelyTelephone2,
                },
              }}
              render={({ field }) => (
                <TextInput
                  css={{ flex: 1 }}
                  label={`${t("Filters:Telephone")} 2`}
                  placeholder={t("Common:input_phone_description")}
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e.currentTarget.value);
                    trigger(field.name);
                  }}
                  validationError={getErrorMessage(errors.telephone2)}
                />
              )}
            />
            <Controller
              name="telephone3"
              control={control}
              rules={{
                validate: {
                  validPhone: validatePhoneNumberOrEmpty,
                },
              }}
              render={({ field }) => (
                <TextInput
                  css={{ flex: 1 }}
                  label={`${t("Filters:Telephone")} 3`}
                  placeholder={t("Common:input_phone_description")}
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e.currentTarget.value);
                    trigger(field.name);
                  }}
                  validationError={getErrorMessage(errors.telephone3)}
                />
              )}
            />
            <Row type="fill" align="start">
              <Controller
                name="startTime"
                control={control}
                render={({ field }) => (
                  <TimePicker
                    label={t(
                      "AlarmReceptions:edit_contact_label_starttime_label"
                    )}
                    value={field.value}
                    placeholder={t(
                      "AlarmReceptions:edit_contact_label_starttime_placeholder"
                    )}
                    onChange={field.onChange}
                  />
                )}
              />
              <Spacer size={16} />
              <Controller
                name="endTime"
                control={control}
                render={({ field }) => (
                  <TimePicker
                    label={t(
                      "AlarmReceptions:edit_contact_label_endtime_label"
                    )}
                    value={field.value}
                    placeholder={t(
                      "AlarmReceptions:edit_contact_label_endtime_placeholder"
                    )}
                    onChange={field.onChange}
                  />
                )}
              />
            </Row>
          </TwoColGrid>
        </Column>
      </form>
    </StyledModal>
  );
});

EditAlarmReceptionContact.defaultProps = {
  onSubmit: () => {},
  onClose: () => {},
};
