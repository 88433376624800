import { observer } from "mobx-react";
import useTranslations from "../../../core/i18n/useTranslations";
import useSharedOrganization from "../SharedOrganizationContext/useSharedOrganization";
import { notify } from "../../../ui-lib/components/Alerts/Toast";
import { notifyApiErrors } from "../../../core/helpers/helpers";
import { IOrganizationDetails } from "../../../core/api/organizations/types";
import { Spacer } from "../../../components/Layout/Layout";
import { OrganizationGeneralForm } from "./OrganizationGeneralForm";

const OrganizationGeneralContainer = () => {
  const t = useTranslations();
  const { data, update, getOrganizationData } = useSharedOrganization();

  const onSubmit = async (values: IOrganizationDetails) => {
    try {
      await update(values);
      getOrganizationData();

      notify({
        message: t("Organizations:edited_success"),
      });
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
      throw error;
    }
  };

  return (
    <>
      <h1>{t("Organizations:general_page_title")}</h1>
      <Spacer size={8} />
      <p>{t("Organizations:general_page_description")}</p>

      <Spacer size={32} />

      <h2>{t("Organizations:general_basic_details_title")}</h2>
      <Spacer size={8} />
      <p>{t("Organizations:general_basic_details_description")}</p>

      <Spacer size={16} />

      <OrganizationGeneralForm defaultValues={data} onSubmit={onSubmit} />
    </>
  );
};

export default observer(OrganizationGeneralContainer);
