import { ResponseCenterParams } from "../../core/api/responsecenters/types";
import useTranslations from "../../core/i18n/useTranslations";
import Tree, { ITreeData } from "../../ui-lib/components/Hierarchy/Tree";

type OrganizationSettings = Pick<ResponseCenterParams, "organizationId">;

const CustomerPicker = ({
  settings,
  setSettings,
  organizationId,
  treeData,
  organizationsById,
}: {
  treeData: ITreeData[];
  settings: OrganizationSettings;
  setSettings: (settings: OrganizationSettings) => void;
  organizationId?: number;
  organizationsById?: ITreeData | null;
}) => {
  const t = useTranslations();

  if (!treeData) {
    return null;
  }

  const orgTree: ITreeData[] = organizationsById?.children || [];

  return (
    <Tree
      label={t("AlarmReceptions:organisation_placeholder")}
      hideLabel
      placeholder={t("AlarmReceptions:organisation_placeholder")}
      style={{ width: "205px" }}
      items={organizationId ? orgTree : treeData}
      selectedTreeItem={settings.organizationId}
      showClearOption={false}
      onSelectItem={(data?: ITreeData) => {
        if (!data) {
          setSettings({
            organizationId: undefined,
          });

          return;
        }
        setSettings({
          organizationId: data.key as number,
        });
      }}
      disabled={!!organizationId && !orgTree.length}
    />
  );
};

export default CustomerPicker;
