import { useState } from "react";
import useTranslations from "../../../core/i18n/useTranslations";
import Icon from "../Icon";
import styles from "./CopyToClipboard.module.css";
import Tooltip from "../Tooltip/Tooltip";
import { Spacer } from "../../../components/Layout/Layout";

export const CopyToClipboard = ({
  prefilledText,
  text,
}: {
  text: string;
  prefilledText?: string;
}) => {
  const t = useTranslations();

  const [isCopied, setIsCopied] = useState(false);

  const delay = (time: number) =>
    new Promise((resolve) => setTimeout(resolve, time));

  const copyIcon = (
    <span>
      <Icon
        className={styles.copyIcon}
        name="document-duplicate"
        onClick={async () => {
          setIsCopied(true);
          if ("clipboard" in navigator) {
            await navigator.clipboard.writeText(
              prefilledText ? `${prefilledText}${text}` : text
            );
          } else {
            document.execCommand(
              "copy",
              true,
              prefilledText ? `${prefilledText}${text}` : text
            );
          }
          await delay(1000);
          setIsCopied(false);
        }}
        size={18}
        color="Primary-700"
        hoverEffect
      />
    </span>
  );

  return (
    <>
      <Tooltip
        variant={isCopied ? "dark" : "light"}
        size="small"
        trigger={() => copyIcon}
      >
        {isCopied ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Icon name="check-circle" size={16} />
            <Spacer size={6} />
            {t("Common:copied")}
          </div>
        ) : (
          t("Common:copy_to_clipboard")
        )}
      </Tooltip>
    </>
  );
};
