import { ReactNode } from "react";
import { Column, Row, Spacer } from "../Layout/Layout";
import Navigation from "../Navigation/Navigation";
import styles from "./BasicPage.module.css";
import useTranslations from "../../core/i18n/useTranslations";
import useBranding from "../../core/branding/useBranding";

const BasicPage = ({ children }: { children: ReactNode }) => {
  const t = useTranslations();
  const brandingStore = useBranding();
  const imageSrc = `./assets/images/welcome${
    brandingStore.currentHomeImage ? `_${brandingStore.currentHomeImage}` : ""
  }.png`;

  return (
    <Column className={styles.wrapper}>
      <Row className={styles.main}>
        <Column className={styles.leftColumn}>
          <Spacer size={100} />
          <div className={styles.welcomeWrapper}>
            <div>
              <img alt="" role="presentation" src={imageSrc} width="100%" />
            </div>
          </div>
          <Spacer size={50} />
          <Column className={styles.info}>
            <p className="subTitle">
              {t(`Home:login_subtitle_${brandingStore.customerKey}`)}
            </p>
          </Column>
        </Column>
        <Column className={styles.rightColumn}>{children}</Column>
      </Row>
      <Row className={styles.footer}>
        <Navigation />
      </Row>
    </Column>
  );
};

export default BasicPage;
