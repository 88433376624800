import useTranslations from "../../../../../core/i18n/useTranslations";
import Dropdown from "../../../../../ui-lib/components/Dropdown/Dropdown";

const types = [1, 2, 3, 4, 13];

export const TypeControl = ({
  selected,
  onSelect,
}: {
  selected: number;
  onSelect: (id: number) => void;
}) => {
  const t = useTranslations();

  const dropdownItems = types.map((id) => ({
    id,
    name: t(`AlarmReceptions:alarm_handling_rule_type_${id}`),
    isSelected: selected === id,
  }));

  return (
    <Dropdown
      bodyWidth={300}
      selectedItem={dropdownItems.find((i) => i.isSelected)}
      items={dropdownItems}
      onSelectItem={(item) => onSelect(item.id as number)}
    />
  );
};
