import { Dispatch, SetStateAction, useEffect, useState } from "react";
import useUser from "../../core/user/useUser";
import Button from "../../ui-lib/components/Button/Button";
import { Row, Spacer } from "../../components/Layout/Layout";
import useTranslations from "../../core/i18n/useTranslations";
import { moveTransmitterToOrganisation } from "../../core/api/transmitters/transmitters";
import StyledModal from "../../ui-lib/components/Modal/Modal";
import Tree, { ITreeData } from "../../ui-lib/components/Hierarchy/Tree";
import InputContainer from "../../ui-lib/components/Inputs/InputContainer";
import { useOrganizationsTree } from "../../core/api/organizations/organizations";
import Alert from "../../ui-lib/components/Alerts/Alert";
import { Transmitter } from "../../core/api/transmitters/types";
import { notify } from "../../ui-lib/components/Alerts/Toast";

const MoveTransmitterModal = ({
  onClose,
  name,
  modalTitle,
  text,
  transmitters,
  isOpen,
  closeModal,
}: {
  onClose?: (success: boolean) => void;
  name: string;
  modalTitle: string;
  text: string;
  transmitters: Transmitter[];
  isOpen: boolean;
  closeModal: Dispatch<SetStateAction<boolean>>;
}) => {
  const t = useTranslations();
  const { authenticatedRequest } = useUser();
  const [isLoading, setIsLoading] = useState(false);
  const { data: organisations } = useOrganizationsTree();
  const [selectedOrg, setSelectedOrg] = useState<number>();
  const [errors, setErrors] = useState<string[]>();
  const [isSaveSuccess, setIsSaveSuccess] = useState(true);
  const [transmittersInternal, setTransmittersInternal] =
    useState<Transmitter[]>(transmitters);
  const close = (success: boolean = false) => {
    setSelectedOrg(undefined);
    setErrors(undefined);
    closeModal(false);
    onClose?.(success);
  };
  useEffect(() => {
    setTransmittersInternal(transmitters);
  }, [transmitters]);
  useEffect(() => {
    if (isOpen && !isSaveSuccess) {
      setIsSaveSuccess(true);
    }
  }, [isOpen]);
  return (
    <StyledModal
      onClose={() => {
        closeModal(false);
      }}
      isOpen={isOpen}
      modalTitle={modalTitle}
      closeOnDocumentClick={false}
      css={{ maxWidth: 960 }}
      isLoading={isLoading}
      customFooter={() => (
        <Row type="left">
          <Button
            variant="primary"
            disabled={selectedOrg === undefined || !isSaveSuccess || isLoading}
            loading={isLoading}
            text={`${t("Transmitters:button_move_transmitters")} ${
              isSaveSuccess ? `(${transmittersInternal.length})` : ""
            }`}
            onClick={async () => {
              let success = true;
              const newErrors: string[] = [];
              const transmittersToMove: Transmitter[] = [];
              const failedTransmitters: Transmitter[] = [];
              if (selectedOrg !== undefined) {
                setIsLoading(true);
                try {
                  transmittersInternal.forEach((transmitter) => {
                    if (transmitter.customerId === selectedOrg) {
                      newErrors.push(transmitter.identifier);
                      failedTransmitters.push(transmitter);
                      success = false;
                    } else {
                      transmittersToMove.push(transmitter);
                    }
                  });

                  if (transmittersToMove.length) {
                    const result = await moveTransmitterToOrganisation(
                      transmittersToMove.map((tr) => tr.id),
                      selectedOrg,
                      authenticatedRequest
                    );
                    if (
                      result.data.validationErrors &&
                      result.data.validationErrors.length > 0
                    ) {
                      success = false;
                      result.data.validationErrors.forEach(
                        (validationError) => {
                          const errorId =
                            validationError.parameters.TransmitterId;
                          if (errorId) {
                            const errorTransmitter = transmittersInternal.find(
                              (tr) => tr.id === errorId
                            );
                            if (errorTransmitter) {
                              newErrors.push(errorTransmitter.identifier);
                            }
                          }
                        }
                      );
                    }
                  }

                  if (newErrors.length > 0) setErrors(newErrors);
                  else {
                    setErrors(undefined);
                    notify({
                      message: t("Transmitters:move_success"),
                    });
                  }
                } catch (error: any) {
                  notify({ message: error.message, variant: "error" });
                  success = false;
                }
              }
              setIsLoading(false);
              if (success) {
                close(true);
              } else {
                setIsSaveSuccess(false);
              }
            }}
          />
          <Spacer size={8} />
          <Button
            variant="secondary"
            text={t("Common:cancel")}
            onClick={() => {
              closeModal(false);
            }}
          />
        </Row>
      )}
    >
      <p style={{ color: "var(--Grey-600)" }}>
        {text.replace("{transmitter}", name)}
      </p>
      <Spacer size={16} />
      <InputContainer
        label={
          <label htmlFor="transmitter_move_organisation">
            {t("Transmitters:new_organisation")}
          </label>
        }
        input={
          <Tree
            id="transmitter_move_organisation"
            placeholder={t("Common:select_organisation")}
            items={organisations}
            selectedTreeItem={selectedOrg}
            onSelectItem={(data: ITreeData) => {
              if (data && data.key) {
                setSelectedOrg(+data.key);
              } else {
                setSelectedOrg(undefined);
              }
            }}
          />
        }
      />
      {errors && (
        <>
          <Spacer size={32} />
          <Alert
            items={errors.map((err) => ({ title: err }))}
            variant="error"
            icon="exclamation-circle"
            title={t("Transmitters:move_failed").replace(
              "{count}",
              errors.length.toString()
            )}
            text={t("Transmitters:move_failed_explanation")}
          />
        </>
      )}
    </StyledModal>
  );
};

export default MoveTransmitterModal;
