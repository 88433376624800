import { Link } from "react-router-dom";
import classNames from "classnames";
import { SideBarLink } from "./SideBarLink";
import { NavigationItem } from "./SideBar";
import { CollapsibleSubItem } from "./CollapsibleSubItem/CollapsibleSubItem";
import Icon from "../../Icon";
import usePageState from "../../../../core/pagestate/usePageState";

import styles from "../Menu.module.css";

type SubMenuItem = Omit<NavigationItem, "type">;

interface SideBarProps {
  back: {
    text: string;
    to: string;
  };
  closeAction?: () => void;
  items: Array<SubMenuItem>;
}

export const SideBarSecondary = ({
  back,
  items,
  closeAction,
}: SideBarProps) => {
  const pageState = usePageState();
  return (
    <nav className={styles.sideBarSecondary}>
      <div
        className={styles.sideBarSecondaryTopContainer}
        onClick={() => pageState.removeInitialId()}
        role="none"
      >
        <Link
          data-cy="subnav_back"
          to={back.to}
          className={classNames(styles.sideBarLink, styles.sideBarLinkBack)}
        >
          <Icon name="chevron-left" size={16} color="Grey-500" />
          <span className={styles.sideBarLinkText}>{back.text}</span>
        </Link>
      </div>
      <div>
        {items.map((item) => {
          if (item.subItems) {
            return (
              <CollapsibleSubItem
                key={item.id}
                title={item.text ?? ""}
                subItems={item.subItems}
              />
            );
          }
          return (
            <SideBarLink
              dataCy={`secondarySideBarLink_${item.text}`}
              key={`nav_item_${item.id}`}
              text={item.text ?? ""}
              to={item.to ?? ""}
              onClick={() => {
                if (closeAction) closeAction();
              }}
              matchProps={item.matchProps}
              importantIcon={item.icon ?? ""}
            />
          );
        })}
      </div>
    </nav>
  );
};

export default SideBarSecondary;
