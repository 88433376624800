import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Spacer } from "../../components/Layout/Layout";
import useBranding from "../../core/branding/useBranding";
import Button from "../../ui-lib/components/Button/Button";
import Img404 from "../../ui-lib/assets/icons/404.svg";

import styles from "./Page404.module.css";

const Page404 = () => {
  const branding = useBranding();
  const { t } = useTranslation();

  return (
    <div className={styles.container404}>
      <div className={styles.wrapper404}>
        <div>
          {branding.currentLogo && (
            <>
              <Link to="/">
                <img
                  alt="Logo"
                  src={branding.currentLogo}
                  height={
                    branding.currentLogoSize?.height
                      ? branding.currentLogoSize?.height
                      : 32
                  }
                  width={
                    branding.currentLogoSize?.width
                      ? branding.currentLogoSize?.width
                      : "auto"
                  }
                />
              </Link>
            </>
          )}
          <div className={styles.description404}>
            <h1>{t("Errors:page_not_found")}</h1>
            <Spacer size={32} />
            <h2>{t("Errors:404_description")}</h2>
            <Spacer size={32} />
            <Link to="/">
              <Button variant="primary" text="Go back Home" />
            </Link>
          </div>
        </div>
        <img src={Img404} alt="404" />
      </div>
    </div>
  );
};

export default Page404;
