import { Dispatch, SetStateAction, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { getErrorKey } from "../../../../components/Errors/ErrorAlert";
import { createPrivateContact } from "../../../../core/api/contacts/contacts";
import { ContactDetails } from "../../../../core/api/contacts/types";
import { notify } from "../../../../ui-lib/components/Alerts/Toast";
import { ContactForm, ContactFormActions } from "./ContactForm";
import { LoginModal } from "./LoginModal";
import { Spacer } from "../../../../components/Layout/Layout";
import useTranslations from "../../../../core/i18n/useTranslations";
import useUser from "../../../../core/user/useUser";
import PrimeModal from "../../../../ui-lib/components/PrimeModal/PrimeModal";

const CreatePrivateContactModal = ({
  objectId,
  createCommonContactModalOpen,
  setCreateCommonContactModalOpen,
  onAdd,
}: {
  objectId: string;
  createCommonContactModalOpen: boolean;
  setCreateCommonContactModalOpen: Dispatch<SetStateAction<boolean>>;
  onAdd: () => void;
}) => {
  const t = useTranslations();
  const history = useHistory();
  const modalRef = useRef<ContactFormActions>(null);
  const [submitFormDisabled, setSubmitFormDisabled] = useState(false);
  const [createLogin, setCreateLogin] = useState(false);
  const [showCredentialModal, setShowCredentialModal] = useState(false);
  const [contactData, setContactData] = useState<number>();

  const { authenticatedRequest, config } = useUser();

  const onSubmit = async (values: ContactDetails) => {
    setSubmitFormDisabled(true);
    values.userId = +objectId;
    try {
      const result = await createPrivateContact(values, authenticatedRequest);
      notify({
        message: t("Contacts:added_success"),
      });
      if (!createLogin) {
        history.push(`/adminportal/common-contacts/${result.data}`);
      } else {
        setContactData(result.data);
        setShowCredentialModal(true);
      }
    } catch (error) {
      const errorKey = getErrorKey(error);
      notify({
        message: t(`Errors:${errorKey}`),
        variant: "error",
      });
    } finally {
      setSubmitFormDisabled(false);
    }
  };

  return (
    <>
      <LoginModal
        contactId={contactData}
        showModal={showCredentialModal}
        setShowModal={setShowCredentialModal}
        onClose={() => {
          onAdd();
          setShowCredentialModal(false);
        }}
        userId={objectId}
        isPrivate
      />
      <PrimeModal
        contentStyle={{ width: "856px" }}
        header={t("Contacts:create_private_contact")}
        isOpen={createCommonContactModalOpen}
        onClose={() => setCreateCommonContactModalOpen(false)}
        cancelBtn={{
          text: t("Common:cancel"),
          onClick: () => setCreateCommonContactModalOpen(false),
        }}
        submitBtn={{
          text: t("Contacts:labels_create_contact"),
          onClick: () => modalRef?.current?.submitForm(),
        }}
        loading={submitFormDisabled}
      >
        <p style={{ color: "var(--Grey-600)" }}>
          {t("Contacts:create_private_contact_description")}
        </p>
        <Spacer size={16} />
        <ContactForm
          isModal
          ref={modalRef}
          onSubmit={onSubmit}
          setCreateLogin={setCreateLogin}
          withNewSchedule={config?.show.includes("ContactSchedule")}
          privateContact
        />
      </PrimeModal>
    </>
  );
};

export default observer(CreatePrivateContactModal);
