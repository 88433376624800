/* eslint-disable no-shadow */

export interface TimeSpan {
  start?: Date;
  end?: Date;
}

export enum TimeSpanType {
  Empty = -1,
  Today,
  Last7Days,
  Last10Days,
  Last30Days,
  LastMonth,
  PreviousMonth,
  AllTime,
  Custom,
}
