export const alarmReceptionprotocols = [1, 2, 3, 4, 5, 6, 7, 8];

export const alarmSorts = ["priority", "time", "alphabetically"];

export const dtmpDigits = [
  "",
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "*",
  "#",
];

export const userSearchTypes = [0, 1, 2, 3];

export const periodicity = [0, 1, 2, 5, 10, 30, 60, 120, 180, 720, 1440];

export const eventTriggers = [
  "onNew",
  "onAssign",
  "onChangeRespondentGroup",
  "onEscalatedAlarm",
  "onVoiceCall",
  "onRelarm",
  "onCoordinate",
  "onMedia",
  "onLocation",
  "onDeactivate",
  "onOperatorLog",
  "onClientUpdate",
];
