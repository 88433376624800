import { deleteAddress } from "../../../../core/api/addresses/addresses";
import useUser from "../../../../core/user/useUser";

import ModalContent from "../../../../ui-lib/components/Popup/ModalContent";
import Button from "../../../../ui-lib/components/Button/Button";
import { notify } from "../../../../ui-lib/components/Alerts/Toast";

import { Row, Spacer } from "../../../../components/Layout/Layout";
import { getErrorKey } from "../../../../components/Errors/ErrorAlert";
import useTranslations from "../../../../core/i18n/useTranslations";

export const DeleteAddressModal = ({
  objectId,
  addressId,
  onClose,
  onDelete,
}: {
  objectId: string;
  addressId?: number;
  onClose: () => void;
  onDelete: () => void;
}) => {
  const t = useTranslations();

  const { authenticatedRequest } = useUser();

  const onSubmit = async () => {
    if (!addressId) {
      return;
    }

    try {
      await deleteAddress(objectId, addressId, authenticatedRequest);
      notify({
        message: t("Messages:delete_address_success"),
      });
      onDelete();
    } catch (error) {
      const errorKey = getErrorKey(error);
      notify({
        message: t(`Errors:${errorKey}`),
        variant: "error",
      });
    }
  };

  return (
    <ModalContent onClose={onClose} width="576px">
      <h2 className="subTitle">{t("Objects:delete_address")}</h2>
      <Spacer size={16} />
      <p style={{ color: "var(--Grey-600)" }}>
        {t("Messages:delete_address_confirm")}
      </p>

      <Spacer size={32} />

      <Row type="left">
        <Button
          variant="destructive"
          text={t("Objects:delete_address")}
          onClick={onSubmit}
        />
        <Spacer size={8} />
        <Button
          variant="secondary"
          text={t("Common:cancel")}
          onClick={onClose}
        />
      </Row>
    </ModalContent>
  );
};
