import LanguageStore from "../../core/i18n/LanguageStore";
import User from "../../core/user/User";

interface ILangUserData {
  username: string;
  language: string;
}

const langData = (): ILangUserData[] =>
  JSON.parse(localStorage.getItem("userLang") || "[]");

export const getUserStoredLang = (user: User): ILangUserData | undefined => {
  const currentUserLangData = langData().find(
    (data) => data.username === user.data?.username
  );
  return currentUserLangData;
};

export const setUserLangData = (user: User, lang: string) => {
  const curentData = langData();
  const currentUserIndex = curentData.findIndex(
    (data) => data.username === user.data?.username
  );
  if (currentUserIndex !== -1) {
    curentData[currentUserIndex].language = lang;
  } else {
    curentData.push({
      username: user.data?.username!,
      language: lang,
    });
  }
  localStorage.setItem("userLang", JSON.stringify(curentData));
};

export const getLocale = (lang: LanguageStore) => {
  const storedLang = lang.getStoredLanguage()!;
  const splitedLang = storedLang.split("-");
  if (splitedLang.length > 2) {
    return `${splitedLang[0]}-${splitedLang[1]}`;
  }
  return storedLang;
};
