import { Row, Spacer } from "../../../components/Layout/Layout";
import { SettingBoxRender } from "./SettingBoxRender";
import useTranslations from "../../../core/i18n/useTranslations";
import Accordion from "../../../ui-lib/components/Accordion/Accordion";
import Divider from "../../../ui-lib/components/Divider/Divider";
import styles from "./AdvancedSettings.module.css";
import LoadingSpinner from "../../../ui-lib/components/Loading/LoadingSpinner";
import useSharedOrganization from "../SharedOrganizationContext/useSharedOrganization";

export interface IAdvancedData {
  alternatives: [];
  changeable: boolean;
  clientType: number;
  defaultValue: string;
  identifier: string;
  level: string;
  max?: string | number | null;
  min?: string | number | null;
  tableColumns?: number | null;
  type: string;
  useUserPermissions: boolean;
  verification: string;
  visible: boolean;
}

export const AdvancedSettings = ({ id }: { id: number }) => {
  const t = useTranslations();
  const { allSettings } = useSharedOrganization();

  const types = allSettings?.map((item) => item.clientType);
  const uniqTypes = Array.from(new Set(types));

  return (
    <>
      <h1>{t("Menu:advanced_settings")}</h1>
      <Spacer size={8} />
      <p>{t("Organizations:advanced_settings_description")}</p>
      <Spacer size={32} />
      {uniqTypes && uniqTypes.length ? (
        uniqTypes.map((item) => {
          const index: number = Number((Math.random() * 10).toFixed(3));
          return (
            <Row
              style={{
                maxWidth: "784px",
                width: "100%",
                flexDirection: "column",
              }}
              key={`item_${index}`}
            >
              <Accordion
                title={t(`Settings:ClientType_${item}`)}
                description="description"
                bodyClassName={styles.settingsAccordion}
              >
                {allSettings
                  .filter(
                    (setting: IAdvancedData) => setting.clientType === item
                  )
                  .map((setting: IAdvancedData, i: number) => (
                    <div
                      className={styles.settingsBox}
                      key={`box_${index + i}`}
                    >
                      <p className="labelRowLabel">
                        {t(`Settings:${setting.identifier}`)}
                      </p>
                      <div className={styles.settingsBoxForm}>
                        <SettingBoxRender setting={setting} id={id} />
                      </div>
                    </div>
                  ))}
              </Accordion>
              <Spacer size={16} />
              <Divider />
              <Spacer size={32} />
            </Row>
          );
        })
      ) : (
        <LoadingSpinner theme="primary" />
      )}
    </>
  );
};
