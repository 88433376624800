/* eslint-disable react/jsx-props-no-spreading */
import { ReactNode } from "react";
import ReactJSPopup from "reactjs-popup";
import { defaultPopupProps } from "./defaultPopupProps";

interface PopupProps {
  /* PopupPosition from reactjs-popup */
  position?:
    | "top left"
    | "top center"
    | "top right"
    | "right top"
    | "right center"
    | "right bottom"
    | "bottom left"
    | "bottom center"
    | "bottom right"
    | "left top"
    | "left center"
    | "left bottom"
    | "center center";

  /* Function that returns element that triggers menu. Note: if custom component generates ref issues, try to wrap in div, for instance. */
  trigger: () => JSX.Element;

  /* When set to true, component is rendered as usual but menu is disabled for it */
  disabled?: boolean;
  children: (close: () => void) => ReactNode;
  closeOnDocumentClick?: boolean;
}

const Popup = ({
  position = "top left",
  trigger,
  disabled = undefined,
  children,
  closeOnDocumentClick,
}: PopupProps) => (
  <ReactJSPopup
    {...defaultPopupProps}
    on={["click"]}
    trigger={trigger}
    position={position}
    disabled={disabled}
    closeOnDocumentClick={closeOnDocumentClick}
  >
    {/* @ts-ignore */}
    {(close: CloseFunction) => children(close)}
  </ReactJSPopup>
);

export default Popup;
