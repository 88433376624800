import classnames from "classnames";
import { CSSProperties } from "react";
import styles from "./Button.module.css";
import Icon from "../Icon";
import LoadingSpinner from "../Loading/LoadingSpinner";

export type ButtonType = "button" | "reset" | "submit" | undefined;
export type ButtonVariant =
  | "primary"
  | "secondary"
  | "borderless-secondary"
  | "active"
  | "destructive"
  | "popup"
  | "link"
  | "border-link";
export type ButtonSize = "extraSmall" | "small";

export interface ButtonProps {
  type?: ButtonType;
  form?: string;
  variant?: ButtonVariant;
  size?: ButtonSize;
  text?: string;
  image?: string;
  title?: string;
  className?: string;
  loading?: boolean;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  iconVariant?: ButtonVariant;
  customStyles?: CSSProperties;
  dataCy?: string;
  ariaLabel?: string;
  tabIndex?: number;
}

function getIconColor(buttonVariant: string, iconVariant: string) {
  switch (buttonVariant) {
    case "primary":
      return iconVariant === "primary" ? "White" : "Primary-700";
    case "secondary":
      return iconVariant === "primary" ? "buttonSecondary" : "Primary-700";
    case "active":
      return "buttonActive";
    case "destructive":
      return "buttonDestructive";
    case "popup":
    case "link":
      return "buttonPopup";
    case "border-link":
      return "Primary-700";
    default:
      return "buttonPrimary";
  }
}

function getLoadingTheme(variant: string) {
  switch (variant) {
    case "primary":
      return "light";
    case "destructive":
      return "error";
    default:
      return "primary";
  }
}

export const Button = ({
  type = "button",
  form,
  variant = "primary",
  size = "small",
  text,
  image,
  loading = false,
  title,
  className = "",
  disabled = false,
  onClick = undefined,
  iconVariant,
  customStyles,
  dataCy,
  ariaLabel,
  tabIndex = 0,
}: ButtonProps) => {
  const iconSize = {
    extraSmall: 20,
    small: 24,
  }[size];

  return (
    <button
      data-cy={dataCy}
      type={type}
      form={form}
      className={classnames([styles.button], {
        [styles.buttonPrimary]: variant === "primary",
        [styles.buttonSecondary]: variant === "secondary",
        [styles.buttonActive]: variant === "active",
        [styles.buttonDestructive]: variant === "destructive",
        [styles.buttonPopup]: variant === "popup",
        [styles.buttonLink]: variant === "link",
        [styles.buttonBorderLink]: variant === "border-link",
        [styles.buttonExtraSmall]: size === "extraSmall",
        [styles.buttonSmall]: size === "small",
        [styles.buttonWithIconAndText]: !!image && !!text,
        [styles.buttonWithIcon]: !!image && !text,
        [className]: !!className,
      })}
      title={title}
      aria-label={ariaLabel ?? text ?? title ?? image}
      tabIndex={tabIndex}
      disabled={disabled}
      onClick={(e) => !loading && onClick?.(e)}
      style={customStyles || {}}
    >
      {loading && (
        <div style={{ marginRight: "8px" }}>
          <LoadingSpinner size={20} theme={getLoadingTheme(variant)} />
        </div>
      )}
      {image ? (
        <Icon
          name={image}
          size={iconSize}
          color={getIconColor(variant, iconVariant ?? "primary")}
          className={classnames({
            "m-0": !text,
          })}
        />
      ) : (
        <span />
      )}
      {text ? <span>{`${text}${loading ? "..." : ""}`}</span> : <span />}
    </button>
    /* eslint-enable react/button-has-type */
  );
};

export default Button;
