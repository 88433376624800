import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import _ from "lodash";
import { Column, Row, Spacer } from "../Layout/Layout";
import StyledModal from "../../ui-lib/components/Modal/Modal";
import {
  getTransmitterPeriodicDisables,
  removeTransmitterPeriodicDisables,
} from "../../core/api/transmitters/transmitters";
import useUser from "../../core/user/useUser";
import Table from "../../ui-lib/components/Tables/Table";
import TableCell from "../../ui-lib/components/Tables/TableCell";
import { PeriodicDisable } from "../../core/api/transmitters/types";

import { notifyApiErrors } from "../../core/helpers/helpers";

import { WeekScheduleCell } from "./WeekScheduleCell";
import { CreateTransmitterScheduleModal } from "./CreateTransmitterScheduleModal";
import useTranslations from "../../core/i18n/useTranslations";

export declare type TransmitterScheduleActions = {
  open: (transmitterId: number) => void;
};
export interface TransmitterScheduleProps {
  onClose?: () => void;
}
export const TransmittersScheduleModal = forwardRef<
  TransmitterScheduleActions,
  TransmitterScheduleProps
>((props: TransmitterScheduleProps, ref) => {
  const { onClose } = props;
  const t = useTranslations();
  const { authenticatedRequest } = useUser();
  const [isTransmitterScheduleOpen, setIsTransmitterScheduleOpen] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const active = useRef(false);
  const transmitterId = useRef<number | undefined>(undefined);
  const [periodicDisables, setPeriodicDisables] = useState<PeriodicDisable[]>();

  const closeModal = () => {
    setIsTransmitterScheduleOpen(false);
    setIsLoading(false);
    onClose?.();
  };

  async function loadData() {
    setIsLoading(true);
    try {
      if (transmitterId.current) {
        const result = await getTransmitterPeriodicDisables(
          transmitterId.current,
          authenticatedRequest
        );
        if (active.current) {
          setPeriodicDisables(result.data);
        }
      }
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    }
    setIsLoading(false);
  }
  const removeSchedule = async (scheduleId: number) => {
    try {
      await removeTransmitterPeriodicDisables(
        transmitterId.current!,
        scheduleId,
        authenticatedRequest
      );
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    }
    loadData();
  };
  useImperativeHandle<TransmitterScheduleActions, TransmitterScheduleActions>(
    ref,
    () => {
      const actions: TransmitterScheduleActions = {
        open: function open(id?: number) {
          setIsTransmitterScheduleOpen(true);
          transmitterId.current = id;
          loadData();
        },
      };
      return actions;
    }
  );
  useEffect(() => {
    active.current = true;
    return () => {
      active.current = false;
    };
  }, []);
  useEffect(() => {
    if (isTransmitterScheduleOpen) loadData();
  }, [isTransmitterScheduleOpen]);

  return (
    <>
      <StyledModal
        onClose={closeModal}
        isOpen={isTransmitterScheduleOpen}
        modalTitle={t("Transmitters:schedule_modal_title")}
        closeOnDocumentClick={false}
        css={{ maxWidth: 960 }}
        isLoading={isLoading}
      >
        <Column type="top" align="start">
          <p>{t("Transmitters:schedule_table_description")}</p>
          <Spacer size={16} />
          <Row type="space" style={{ alignSelf: "stretch" }}>
            <h2>{t("Transmitters:schedule_table_title")}</h2>

            <CreateTransmitterScheduleModal
              transmitterId={transmitterId.current!}
              onSubmit={loadData}
            />
          </Row>
          <Spacer size={16} />
          <Table<PeriodicDisable>
            columns={[
              {
                header: t("Common:time"),
                fieldTemplate: (rowData) => (
                  <TableCell
                    value={`${_.padStart(
                      rowData.beginHour?.toString(),
                      2,
                      "0"
                    )}:${_.padStart(
                      rowData.beginMinute?.toString(),
                      2,
                      "0"
                    )} - ${_.padStart(
                      rowData.endHour?.toString(),
                      2,
                      "0"
                    )}:${_.padStart(rowData.endMinute?.toString(), 2, "0")}`}
                  />
                ),
              },
              {
                header: t("Common:days"),
                fieldTemplate: (rowData) => <WeekScheduleCell data={rowData} />,
              },
              {
                header: t("Table:AlarmCode"),
                fieldTemplate: (rowData) => (
                  <TableCell
                    value={rowData.alarmCode ? `# ${rowData.alarmCode}` : ""}
                  />
                ),
              },
            ]}
            items={periodicDisables}
            hideEmptyMessage
            noRowsMessage={t("Transmitters:schedule_table_nomatch")}
            showRowHover
            isLoading={isLoading}
            rowActions={[
              {
                icon: "x",
                text: t("Transmitters:schedule_table_remove"),
                onClick: (rowData) => removeSchedule(rowData.id!),
              },
            ]}
            rowActionsFixed
            rowActionsColWidth={45}
          />
        </Column>
      </StyledModal>
    </>
  );
});
