import { AxiosInstance } from "axios";
import API_HOST from "../apiHost";
import useAuthenticatedFetch from "../useAuthenticatedFetch";
import {
  ResponseCenterAlarmCodeResponse,
  ResponseCenterAlarmCodeDetails,
} from "./types";

const useResponseCenterAlarmCodes = (id: string | number) =>
  useAuthenticatedFetch<ResponseCenterAlarmCodeResponse[]>(
    `${API_HOST}api/v2/responsecenters/${id}/alarmcodes`
  );

const getResponseCenterAlarmCodes = async (
  id: string | number,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.get<ResponseCenterAlarmCodeResponse[]>(
    `${API_HOST}api/v2/responsecenters/${id}/alarmcodes`
  );

const useResponseCenterAlarmCode = (
  responseCenterId: string | number,
  alarmCodeId: string | number
) =>
  useAuthenticatedFetch<ResponseCenterAlarmCodeDetails>(
    `${API_HOST}api/v2/responsecenters/${responseCenterId}/alarmcodes/${alarmCodeId}`
  );

const createAlarmCode = async (
  id: string | number,
  params: ResponseCenterAlarmCodeDetails,
  authenticatedRequest: AxiosInstance
) => {
  const requestData = JSON.stringify(params);

  return authenticatedRequest.post<{}>(
    `${API_HOST}api/v2/responsecenters/${id}/alarmcodes`,
    requestData,
    { headers: { "Content-Type": "application/json" } }
  );
};

const updateAlarmCode = async (
  responseCenterId: string | number,
  alarmCodeId: string | number,
  params: ResponseCenterAlarmCodeDetails,
  authenticatedRequest: AxiosInstance
) => {
  const requestData = JSON.stringify(params);

  return authenticatedRequest.put<{}>(
    `${API_HOST}api/v2/responsecenters/${responseCenterId}/alarmcodes/${alarmCodeId}`,
    requestData,
    { headers: { "Content-Type": "application/json" } }
  );
};

const deleteAlarmCode = async (
  responseCenterId: string | number,
  alarmCodeId: string | number,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.delete(
    `${API_HOST}api/v2/responsecenters/${responseCenterId}/alarmcodes/${alarmCodeId}`
  );

export {
  useResponseCenterAlarmCodes,
  getResponseCenterAlarmCodes,
  useResponseCenterAlarmCode,
  createAlarmCode,
  updateAlarmCode,
  deleteAlarmCode,
};
