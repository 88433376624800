import moment from "moment";
import Icon from "../../ui-lib/components/Icon";
import { Column, Row, Spacer } from "../../components/Layout/Layout";
import styles from "./WeekScheduleCell.module.css";
import { PeriodicDisable } from "../../core/api/transmitters/types";

export const WeekScheduleCell = ({ data }: { data: PeriodicDisable }) => {
  const weekDays = moment.weekdaysShort(false);
  return (
    <Column align="start" style={{ overflow: "hidden" }}>
      <Row style={{ width: "100%", justifyContent: "start" }}>
        <Icon name="calendar" size={16} color="Grey-300" />
        <Spacer size={8} />
        <span className={data.monday ? styles.selectedDay : styles.day}>
          {weekDays[1]}
        </span>
        <span className={data.tuesday ? styles.selectedDay : styles.day}>
          {weekDays[2]}
        </span>
        <span className={data.wednesday ? styles.selectedDay : styles.day}>
          {weekDays[3]}
        </span>
        <span className={data.thursday ? styles.selectedDay : styles.day}>
          {weekDays[4]}
        </span>
        <span className={data.friday ? styles.selectedDay : styles.day}>
          {weekDays[5]}
        </span>
        <span className={data.saturday ? styles.selectedDay : styles.day}>
          {weekDays[6]}
        </span>
        <span className={data.sunday ? styles.selectedDay : styles.day}>
          {weekDays[0]}
        </span>
      </Row>
    </Column>
  );
};
