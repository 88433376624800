import { createContext } from "react";
import RootStore from "./RootStore";

const root = new RootStore();

export const RootContext = createContext(root);

export const RootProvider = ({ children }: { children: React.ReactNode }) => (
  <RootContext.Provider value={root}>{children}</RootContext.Provider>
);
