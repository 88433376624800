import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import {
  sendTestAlarm,
  useTestAlarms,
} from "../../../core/api/objects/objects";
import useUser from "../../../core/user/useUser";
import Button from "../../../ui-lib/components/Button/Button";
import LoadingSpinner from "../../../ui-lib/components/Loading/LoadingSpinner";
import Radio from "../../../ui-lib/components/Radio/Radio";
import TextInput from "../../../ui-lib/components/Inputs/TextInput";
import { notify } from "../../../ui-lib/components/Alerts/Toast";

import { Row, Spacer } from "../../../components/Layout/Layout";
import { getErrorKey } from "../../../components/Errors/ErrorAlert";

import styles from "./ObjectActions.module.css";
import inputStyles from "../../../ui-lib/components/Inputs/Input.module.css";
import useTranslations from "../../../core/i18n/useTranslations";

export interface TestAlarmFormData {
  textMessage: string;
  flags: string;
}

const TestAlarmModal = ({ onClose }: { onClose: () => void }) => {
  const t = useTranslations();
  const { id } = useParams<{ id: string }>();
  const { authenticatedRequest } = useUser();
  const { data: testAlarms, isLoading } = useTestAlarms(id);

  const {
    control,
    formState: { isSubmitting, errors },
    handleSubmit,
  } = useForm<TestAlarmFormData>();

  const onSubmit = async (data: TestAlarmFormData) => {
    try {
      await sendTestAlarm(id, data, authenticatedRequest);
      notify({
        message: t("Messages:test_alarm_success"),
      });
      onClose();
    } catch (error) {
      const errorKey = getErrorKey(error);
      notify({
        message: t(`Errors:${errorKey}`),
        variant: "error",
      });
    }
  };

  return (
    <>
      <p style={{ color: "var(--Grey-600)" }}>
        {t("Messages:test_alarm_select")}
      </p>

      <Spacer size={16} />
      <Row type="left">
        <span className={inputStyles.requiredIcon}>*</span>
        <p className={styles.label}>{t("Objects:test_alarm_alarm_type")}</p>
      </Row>
      <Spacer size={4} />
      {isLoading || !testAlarms ? (
        <LoadingSpinner theme="primary" />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="flags"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <>
                <div className={styles.radioGroupWrapper}>
                  {testAlarms.map((testAlarm) => (
                    <Radio
                      inputId={testAlarm.flags}
                      key={testAlarm.flags}
                      name="test_alarm"
                      text={t(`CommonEnum:AlarmFlagEnum${testAlarm.flags}`)}
                      value={testAlarm.flags}
                      checked={field.value === testAlarm.flags}
                      onChange={(e) => field.onChange(e.currentTarget.value)}
                    />
                  ))}
                </div>
                {errors.flags && (
                  <>
                    <Spacer size={8} />
                    <p className={styles.validationError}>
                      {t("Messages:test_alarm_type_required")}
                    </p>
                  </>
                )}
              </>
            )}
          />

          <Spacer size={16} />

          <Controller
            name="textMessage"
            control={control}
            render={({ field }) => (
              <TextInput
                label={t("Objects:test_alarm_message_label")}
                value={field.value}
                onChange={field.onChange}
                validationError={
                  errors.textMessage &&
                  t("Messages:test_alarm_message_required")
                }
              />
            )}
          />

          <Spacer size={32} />
          <Row type="left">
            <Button
              type="submit"
              loading={isSubmitting}
              text={t("Objects:test_alarm_trigger")}
            />
            <Spacer size={8} />
            <Button
              variant="secondary"
              text={t("Common:cancel")}
              onClick={onClose}
            />
          </Row>
        </form>
      )}
      <Spacer size={32} />
    </>
  );
};

export default observer(TestAlarmModal);
