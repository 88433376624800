import { Controller } from "react-hook-form";
import { DateTimeFormat } from "../../core/api/administrators/types";
import Dropdown from "../../ui-lib/components/Dropdown/Dropdown";

export const DatetimeDropdown = ({
  dateTimeFormats,
  control,
  setValue,
  t,
  disabled = false,
}: {
  dateTimeFormats: DateTimeFormat[];
  control: any;
  setValue: any;
  t: any;
  disabled?: boolean;
}) => (
  <Controller
    name="dateTimeFormatId"
    control={control}
    render={({ field }) => {
      const dropdownItems = dateTimeFormats?.map((dateTimeFormat) => ({
        id: dateTimeFormat.id,
        name: t(`Administrator:${dateTimeFormat.nameTranslationKey}`),
        isSelected: dateTimeFormat.id === field.value,
      }));

      return (
        <Dropdown
          title={t("Administrator:general_input_date_time_format")}
          width="100%"
          selectedItem={dropdownItems?.find((i) => i.isSelected)}
          items={dropdownItems}
          onSelectItem={(item) => setValue(field.name, item.id as number)}
          disabled={disabled}
        />
      );
    }}
  />
);
