import { observer } from "mobx-react-lite";
import { getErrorKey } from "../../../../components/Errors/ErrorAlert";
import { createInteraction } from "../../../../core/api/interactions/interactions";
import { PeriodicInteractionDetails } from "../../../../core/api/interactions/types";
import useTranslations from "../../../../core/i18n/useTranslations";
import useUser from "../../../../core/user/useUser";
import { notify } from "../../../../ui-lib/components/Alerts/Toast";
import { InteractionForm } from "./InteractionForm";
import LoadingSpinner from "../../../../ui-lib/components/Loading/LoadingSpinner";
import { useTimezones } from "../../../../core/api/administrators/administrators";

const CreateInteractionModal = ({
  objectId,
  onClose,
  onAdd,
}: {
  objectId: string;
  onClose: () => void;
  onAdd: () => void;
}) => {
  const t = useTranslations();
  const { authenticatedRequest } = useUser();
  const { data: timezones, isLoading: isTimezoesLoading } = useTimezones();

  const onSubmit = async (interactionDetails: PeriodicInteractionDetails) => {
    try {
      await createInteraction(
        objectId,
        interactionDetails,
        authenticatedRequest
      );
      notify({
        message: t("Messages:create_interaction_success"),
      });
      onAdd();
      onClose();
    } catch (error) {
      const errorKey = getErrorKey(error);
      notify({
        message: t(`Errors:${errorKey}`),
        variant: "error",
      });
    }
  };

  if (isTimezoesLoading) {
    return <LoadingSpinner theme="primary" />;
  }

  return (
    <InteractionForm
      submitText={t("Objects:interaction_create")}
      timezones={
        timezones ? timezones.map((timezone) => timezone.timezoneName) : []
      }
      onSubmit={onSubmit}
      onClose={onClose}
    />
  );
};

export default observer(CreateInteractionModal);
