import DeleteModal from "../../components/DeleteModal/DeleteModal";
import { deleteResponseCenter } from "../../core/api/responsecenters/responsecenters";

export const DeleteAlarmReceptionModal = ({
  responseCenterId,
  onClose,
  onDelete,
}: {
  responseCenterId?: number;
  onClose: () => void;
  onDelete: () => void;
}) => {
  if (!responseCenterId) {
    return null;
  }

  return (
    <DeleteModal
      locizeKey="alarm_reception"
      callDelete={(authReq) => deleteResponseCenter(responseCenterId, authReq)}
      onClose={onClose}
      onDelete={onDelete}
    />
  );
};
