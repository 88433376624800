import { useTranslation } from "react-i18next";
import i18ninternal from "../../core/i18n/config";

const useTranslations = () => {
  const [t] = useTranslation(undefined, {
    i18n: i18ninternal,
  });
  return t;
};

export default useTranslations;
