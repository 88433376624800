import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { notifyError } from "../../../../components/Errors/ErrorAlert";
import {
  updateAlarmCodeRule,
  useAlarmCodeRule,
} from "../../../../core/api/responsecenters/alarmcoderules";
import { ResponseCenterAlarmCodeRuleDetails } from "../../../../core/api/responsecenters/types";
import useTranslations from "../../../../core/i18n/useTranslations";
import useUser from "../../../../core/user/useUser";
import { notify } from "../../../../ui-lib/components/Alerts/Toast";
import LoadingSpinner from "../../../../ui-lib/components/Loading/LoadingSpinner";
import { AlarmCodeRuleForm } from "./AlarmCodeRuleForm";

const EditAlarmCodeRuleModal = ({
  responseCenterId,
  alarmCodeRuleId,
  onClose,
  onEdit,
}: {
  responseCenterId: string | number;
  alarmCodeRuleId: string | number;
  onClose: () => void;
  onEdit: () => void;
}) => {
  const t = useTranslations();
  const { authenticatedRequest } = useUser();
  const { data, isLoading, isError } = useAlarmCodeRule(
    responseCenterId,
    alarmCodeRuleId
  );

  useEffect(() => {
    if (isError) {
      notify({ message: t("Errors:unknown"), variant: "error" });
      onClose();
    }
  }, [isError]);

  if (!data || isLoading) {
    return <LoadingSpinner theme="primary" />;
  }

  const labels = {
    title: t("AlarmReceptions:alarm_handling_alarm_code_rule_edit"),
    description: t(
      "AlarmReceptions:alarm_handling_alarm_code_rule_edit_description"
    ),
    submit: t("Common:save_changes"),
  };

  const onSubmit = async (values: ResponseCenterAlarmCodeRuleDetails) => {
    try {
      await updateAlarmCodeRule(
        responseCenterId,
        alarmCodeRuleId,
        values,
        authenticatedRequest
      );

      notify({
        message: t(
          "AlarmReceptions:alarm_handling_alarm_code_rule_edit_success"
        ),
      });
      onEdit();
    } catch (error: any) {
      notifyError({
        error,
        t,
      });
    }
  };

  return (
    <AlarmCodeRuleForm
      labels={labels}
      defaultValues={{ ...data }}
      onSubmit={onSubmit}
      onClose={onClose}
    />
  );
};

export default observer(EditAlarmCodeRuleModal);
