import classNames from "classnames";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import styles from "./Icon.module.css";

export type IconColor =
  | "none" // no color, use default svg colors
  | "grey"
  | "black"
  | "red"
  | "blue"
  | "buttonPrimary"
  | "buttonActive"
  | "buttonSecondary"
  | "buttonDestructive"
  | "buttonPopup"
  | "fillPrimary700"
  | "Primary-700"
  | "fill-Primary-700"
  | "fill-grey"
  | "Grey-100"
  | "Grey-300"
  | "Grey-400"
  | "Grey-500"
  | "Grey-600"
  | "Grey-800"
  | "Warning-700"
  | "Error-500"
  | "White";

export type IconProps = {
  name: string;
  size?: number;
  margin?: string;
  color?: IconColor;
  className?: string;
  onClick?: () => void;
  hoverEffect?: boolean;
};

/**
 * Icon component that is backed by svg icons.
 * SVG is colored by using predefined CSS classes.
 * Based on:
 * https://medium.com/@erickhoury/react-dynamically-importing-svgs-and-render-as-react-component-b764b6475896
 * https://stackoverflow.com/questions/61339259/how-to-dynamically-import-svg-and-render-it-inline/61472427#61472427
 */
export const Icon = ({
  name,
  size = 24,
  color = "grey",
  margin,
  className = undefined,
  onClick = undefined,
  hoverEffect = false,
}: IconProps) => {
  const [ImportedIconRef, setImportedIconRef] =
    useState<React.FC<React.SVGProps<SVGSVGElement>>>();
  const [loading, setLoading] = useState(true);

  const importIcon = async () => {
    try {
      // !!@svgr/webpack?-svgo,+titleProp,+ref! is workaround for undefined for ReactComponent for imported SVG
      const iconImport = await import(
        `!!@svgr/webpack?-svgo,+titleProp,+ref!../assets/icons/${name}.svg`
      );
      setImportedIconRef(iconImport.default);
    } catch (err) {
      console.error("Icon. Error:", err);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    importIcon();
  }, [name]);

  if (loading) {
    return null;
  }

  if (ImportedIconRef) {
    const icon = (
      <ImportedIconRef
        width={size}
        height={size}
        style={{ minWidth: size, minHeight: size, margin }}
        className={classNames(styles[_.camelCase(color)], className, {
          [styles.clickable]: !!onClick,
          [styles[_.camelCase(`${color}Hover`)]]: hoverEffect,
        })}
        onClick={onClick}
      />
    );
    return icon;
  }

  return null;
};
export default Icon;
