import { Fragment, useState } from "react";
import classNames from "classnames";
import { Row, Spacer } from "../../../components/Layout/Layout";
import styles from "./Switch.module.css";

export const Switch = ({
  tabs,
  currentId = 0,
  onTabChange,
}: {
  tabs: { id: number; isSelected: boolean; name: string }[];
  currentId?: number;
  onTabChange?: (id: number) => void;
}) => {
  const [currentTabId, setCurrentTabId] = useState(
    tabs.find((tab) => tab.isSelected)?.id || currentId
  );

  return (
    <Row className={styles.tabsContainer}>
      {tabs.map((item) => (
        <Fragment key={item.id}>
          {item.id !== 0 && <Spacer size={8} />}
          <button
            key={`button${item.id}`}
            type="button"
            className={classNames({
              [styles.switchTab]: true,
              [styles.active]: currentTabId === item.id,
            })}
            onClick={() => {
              setCurrentTabId(item.id);
              onTabChange?.(item.id);
            }}
          >
            {item.name}
          </button>
        </Fragment>
      ))}
    </Row>
  );
};
