import { observer } from "mobx-react";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Column, Row, Spacer } from "../../../../components/Layout/Layout";
import { updateAdministratorPassword } from "../../../../core/api/administrators/administrators";
import {
  validateForbiddenChars,
  validateLowerCase,
  validateNumberCount,
  validateUpperCase,
} from "../../../../core/helpers/validation";
import useTranslations from "../../../../core/i18n/useTranslations";
import useUser from "../../../../core/user/useUser";
import Alert from "../../../../ui-lib/components/Alerts/Alert";
import { AlertItemProps } from "../../../../ui-lib/components/Alerts/AlertItem";
import { notify } from "../../../../ui-lib/components/Alerts/Toast";
import PasswordInput from "../../../../ui-lib/components/Inputs/PasswordInput";
import useSharedAdministrator from "../SharedAdministratorContext/useSharedAdministrator";
import { format } from "../../../../core/utils/strings";
import {
  getPasswordErrorMessage,
  notifyApiErrors,
} from "../../../../core/helpers/helpers";
import PrimeModal from "../../../../ui-lib/components/PrimeModal/PrimeModal";

export interface PasswordFormValues {
  currentPassword: string;
  password: string;
  passwordRepeat: string;
}

const ChangePasswordModal = ({
  onClose,
  isOpen,
}: {
  onClose: () => void;
  isOpen: boolean;
}) => {
  const t = useTranslations();
  const { authenticatedRequest, accountId } = useUser();
  const { data } = useSharedAdministrator();
  const id = data?.id as number;

  const isCurrentUser = accountId === id;

  const [loading, setLoading] = useState(false);
  const [currentPasswordModalOpen, setCurrentPasswordModalOpen] =
    useState(false);
  const [validationError, setValidationError] = useState<{ type?: string }>({});

  const {
    control,
    handleSubmit,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm<PasswordFormValues>();

  const onSubmit = async (values: PasswordFormValues) => {
    if (validationError.type) return;
    setLoading(true);
    try {
      await updateAdministratorPassword(
        id,
        values.currentPassword,
        values.password,
        authenticatedRequest
      );

      notify({
        message: t("Administrator:change_password_success"),
      });
      onClose();
    } catch (error: any) {
      if (error.response?.data?.errors[0].errorMessage === "InvalidPassword") {
        setValidationError({ type: "InvalidPassword" });
      }
      notifyApiErrors(error.response?.data?.errors);
    }
    setLoading(false);
  };

  const passwordConstrains: AlertItemProps[] = [];
  if (data?.passwordConstraints) {
    if (data.passwordConstraints.minLength !== 0)
      passwordConstrains.push({
        title: format(
          t("Administrator:password_constraints_minimum_length"),
          data.passwordConstraints.minLength
        ),
        key: "min-length",
      });
    if (data.passwordConstraints.minNumbers !== 0)
      passwordConstrains.push({
        title: format(
          t("Administrator:password_constraints_minimum_digits"),
          data.passwordConstraints.minNumbers
        ),
        key: "min-number",
      });
    if (data.passwordConstraints.minUpperCase !== 0)
      passwordConstrains.push({
        title: format(
          t("Administrator:password_constraints_minimum_uppercase"),
          data.passwordConstraints.minUpperCase
        ),
        key: "min-uppercase",
      });

    if (data.passwordConstraints.minLowerCase !== 0)
      passwordConstrains.push({
        title: format(
          t("Administrator:password_constraints_minimum_lowercase"),
          data.passwordConstraints.minLowerCase
        ),
        key: "min-lowercase",
      });

    if (data.passwordConstraints.forbiddenChars !== "")
      passwordConstrains.push({
        title: format(
          t("Administrator:password_constraints_forbidden_chars"),
          data.passwordConstraints.forbiddenChars
        ),
        key: "forbidden-chars",
      });
  }

  const getError = (type?: string) => {
    if (type === "required") {
      return t("Errors:input_field_required");
    }
    if (type === "validate") {
      return t("Errors:password_mismatch");
    }
    if (type === "InvalidPassword") {
      return t("Errors:InvalidPassword");
    }
  };

  return (
    <PrimeModal
      withHeader
      withFooter
      header={t("Administrator:change_password")}
      loading={loading}
      isOpen={isOpen}
      onClose={onClose}
      submitBtn={{
        variant: "primary",
        text: t("Administrator:change_password"),
        onClick: () => {
          handleSubmit((e) => {
            if (!isCurrentUser && !errors.password && !errors.passwordRepeat) {
              clearErrors("currentPassword");
              setCurrentPasswordModalOpen(true);
            } else {
              onSubmit(e);
            }
          })();
        },
      }}
      cancelBtn={{
        text: t("Common:cancel"),
        onClick: onClose,
      }}
    >
      <form style={{ width: "100%", paddingBottom: "4px" }}>
        <p style={{ color: "var(--Grey-600)" }}>
          {t("Administrator:change_password_description")}
        </p>
        <Spacer size={16} />
        {passwordConstrains.map((pwdCtr: AlertItemProps) => (
          <>
            <Alert
              withDefaultIcon
              variant="info"
              key={pwdCtr.key}
              title={pwdCtr.title}
            />
            {passwordConstrains.length > 1 && <Spacer size={8} />}
          </>
        ))}

        <Spacer size={16} />

        {isCurrentUser && (
          <Column style={{ width: "48%" }}>
            <Controller
              name="currentPassword"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <PasswordInput
                  required
                  label={t("Administrator:current_password_lable")}
                  placeholder={t("Administrator:current_password_placeholder")}
                  value={field.value}
                  onChange={({ currentTarget }) => {
                    field.onChange(currentTarget.value);
                    setValidationError({});
                  }}
                  validationError={getError(
                    errors.currentPassword?.type ?? validationError.type
                  )}
                />
              )}
            />
          </Column>
        )}

        <Spacer size={16} />

        <Row align="start">
          <Column style={{ width: "100%" }}>
            <Controller
              name="password"
              control={control}
              rules={{
                required: true,
                minLength: data?.passwordConstraints?.minLength,
                validate: {
                  lowerCase: (v) =>
                    validateLowerCase(v, data?.passwordConstraints!),
                  upperCase: (v) =>
                    validateUpperCase(v, data?.passwordConstraints!),
                  countNumbers: (v) =>
                    validateNumberCount(v, data?.passwordConstraints!),
                  forbiddenChars: (v) =>
                    validateForbiddenChars(v, data?.passwordConstraints!),
                },
              }}
              render={({ field }) => (
                <PasswordInput
                  required
                  label={t("Administrator:change_password_input_new")}
                  placeholder={t(
                    "Administrator:change_password_input_new_placeholder"
                  )}
                  value={field.value}
                  onChange={field.onChange}
                  validationError={
                    errors.password &&
                    getPasswordErrorMessage(
                      errors.password.type,
                      data?.passwordConstraints!
                    )
                  }
                />
              )}
            />
          </Column>
          <Spacer size={32} />
          <Column style={{ width: "100%" }}>
            <Controller
              name="passwordRepeat"
              control={control}
              rules={{
                required: true,
                validate: (value) => watch("password") === value,
              }}
              render={({ field }) => (
                <PasswordInput
                  required
                  label={t("Administrator:change_password_input_new_repeat")}
                  placeholder={t(
                    "Administrator:change_password_input_new_placeholder"
                  )}
                  value={field.value}
                  onChange={field.onChange}
                  validationError={getError(errors.passwordRepeat?.type)}
                />
              )}
            />
          </Column>
        </Row>

        {!isCurrentUser && (
          <PrimeModal
            withHeader
            withFooter
            loading={loading}
            isOpen={currentPasswordModalOpen}
            onClose={() => setCurrentPasswordModalOpen(false)}
            header={t("Administrator:confirm_password_title")}
            submitBtn={{
              text: t("Common:confirm_change"),
              variant: "primary",
              onClick: () => handleSubmit(onSubmit)(),
            }}
            cancelBtn={{
              text: t("Common:cancel"),
              onClick: () => setCurrentPasswordModalOpen(false),
            }}
          >
            <div style={{ width: "848px" }}>
              <p>{t("Administrator:current_password_modal")}</p>
              <Spacer size={16} />
              <Column style={{ width: "48%" }}>
                <Controller
                  name="currentPassword"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <PasswordInput
                      required
                      label={t("Administrator:current_password_lable")}
                      placeholder={t(
                        "Administrator:current_password_placeholder"
                      )}
                      value={field.value}
                      onChange={field.onChange}
                      validationError={
                        errors.currentPassword &&
                        t("Errors:input_field_required")
                      }
                    />
                  )}
                />
              </Column>
            </div>
          </PrimeModal>
        )}
      </form>
    </PrimeModal>
  );
};

export default observer(ChangePasswordModal);
