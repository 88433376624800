export const defaultPopupProps = {
  closeOnDocumentClick: false,
  closeOnEscape: false,
  repositionOnResize: true,
  keepTooltipInside: true,
  arrow: false,
  contentStyle: {
    width: "auto",
    padding: 0,
    boxShadow: "none",
    borderRadius: 0,
    border: "none",
    backgroundColor: "transparent",
  },
};
