import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { Column, Row, Spacer } from "../../components/Layout/Layout";
import { Camera } from "../../core/api/cameras/types";
import {
  Transmitter,
  getCameras,
  getLocations,
  getMedicalTechnicalNotes,
  getObjectTransmitters,
} from "../../core/api/objects/objects";
import {
  KnownLocation,
  MedicalTechnicalNotes,
} from "../../core/api/objects/types";
import { notifyApiErrors } from "../../core/helpers/helpers";
import useTranslations from "../../core/i18n/useTranslations";
import useUser from "../../core/user/useUser";
import useSharedObject from "../../pages/Objects/SharedObjectContext/useSharedObject";
import Divider from "../../ui-lib/components/Divider/Divider";
import LoadingSpinner from "../../ui-lib/components/Loading/LoadingSpinner";
import ObjectCameras from "./ObjectCameras";
import ObjectLocations from "./ObjectLocations";
import ObjectTransmitters from "./ObjectTransmitters";
import { getErrorKey } from "../Errors/ErrorAlert";
import { notify } from "../../ui-lib/components/Alerts/Toast";
import Modal from "../../ui-lib/components/Popup/Modal";
import Button from "../../ui-lib/components/Button/Button";
import { MedicalTechnicalModal } from "../../pages/Objects/MedicalTechnicalNotes/MedicalTechnicalModal";
import { MedicalTechnicalTable } from "../../pages/Objects/MedicalTechnicalNotes/MedicalTechnicalTable";

const ObjectAccessories = ({
  objectId,
  customerId,
  cameras,
  locations,
  transmitters,
  loadTransmitters,
  loadLocations,
  loadCameras,
  setCheckChanges,
}: {
  objectId: string;
  customerId: number;
  cameras: Camera[];
  locations: KnownLocation[];
  transmitters: Transmitter[];
  loadTransmitters(): Promise<void>;
  loadLocations(): Promise<void>;
  loadCameras(): Promise<void>;
  setCheckChanges: (state: boolean) => void;
}) => {
  const t = useTranslations();
  const { data } = useSharedObject();
  const { authenticatedRequest, config } = useUser();
  const isTechnicalNotesAvailable = !!config?.show.find(
    (key) => key === "AllowTechnicalNotes"
  );
  const [isLoading, setIsLoading] = useState(false);
  const [technicalData, setTechnicalData] = useState<MedicalTechnicalNotes[]>();
  const fetchNotes = async () => {
    try {
      setIsLoading(true);
      const result = await getMedicalTechnicalNotes(
        objectId,
        "technicalnotes",
        authenticatedRequest
      );
      setTechnicalData(result.data);
    } catch (error) {
      const errorKey = getErrorKey(error);
      notify({ message: t(`Errors:${errorKey}`), variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isTechnicalNotesAvailable) {
      fetchNotes();
    }
  }, []);

  if (!data) {
    return <LoadingSpinner theme="primary" />;
  }

  return (
    <Column align="start" style={{ width: "100%" }}>
      <h1>{t("Objects:accessories_page_title")}</h1>
      <p>{t("Objects:accessories_page_description")}</p>
      <Column
        type="top"
        align="fill"
        style={{
          alignSelf: "start",
          flex: 0,
          marginTop: "24px",
          width: "100%",
        }}
      >
        <ObjectTransmitters
          objectId={objectId}
          customerId={customerId}
          transmitters={transmitters}
          loadTransmitters={loadTransmitters}
          setCheckChanges={setCheckChanges}
        />

        {isTechnicalNotesAvailable && (
          <Column type="top" align="start">
            {isLoading ? (
              <LoadingSpinner theme="primary" />
            ) : (
              <>
                <Row style={{ width: "100%", flex: 0 }}>
                  <h2>{t("Objects:technical_notes")}</h2>
                  <Spacer />
                  <Modal
                    trigger={() => (
                      <div>
                        <Button
                          type="button"
                          variant="secondary"
                          image="document-text"
                          text={t("Objects:add_note")}
                        />
                      </div>
                    )}
                  >
                    {(close) => (
                      <MedicalTechnicalModal
                        onClose={close}
                        onAdd={fetchNotes}
                        title={t("Objects:add_technical_notes")}
                        type="technicalnotes"
                        objectId={objectId}
                      />
                    )}
                  </Modal>
                </Row>

                <Spacer size={16} />
                <MedicalTechnicalTable
                  notes={technicalData || []}
                  isMedical={false}
                />
              </>
            )}
          </Column>
        )}

        <Spacer size={16} />

        <Divider vertical={false} />
        <Spacer size={16} />
        <ObjectLocations
          objectId={objectId}
          locations={locations}
          loadLocations={loadLocations}
        />
        <Spacer size={16} />
        <Divider vertical={false} />
        <Spacer size={16} />
        <ObjectCameras
          objectId={objectId}
          cameras={cameras}
          loadCameras={loadCameras}
          objectData={data}
        />
      </Column>
    </Column>
  );
};
const ObjectAccessoriesContainer = ({
  objectId,
  customerId,
  setCheckChanges,
}: {
  objectId: string;
  customerId: number;
  setCheckChanges: (state: boolean) => void;
}) => {
  const [cameras, setCameras] = useState<Camera[]>([]);
  const [locations, setLocations] = useState<KnownLocation[]>();
  const [transmitters, setTransmitters] = useState<Transmitter[]>();
  const { config, authenticatedRequest } = useUser();
  const loadCameras = async () => {
    try {
      const data = await getCameras(objectId, authenticatedRequest);
      setCameras(data.data);
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    }
  };

  const loadLocations = async () => {
    try {
      const data = await getLocations(objectId, authenticatedRequest);
      setLocations(data.data);
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    }
  };

  const loadObjectTransmitters = async () => {
    try {
      const data = await getObjectTransmitters(objectId, authenticatedRequest);
      setTransmitters(data.data);
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    }
  };

  useEffect(() => {
    loadObjectTransmitters();
    loadLocations();
    if (config?.show.includes("Cameras")) loadCameras();
  }, []);

  if (!transmitters || !locations || !cameras) {
    return <LoadingSpinner theme="primary" />;
  }

  return (
    <ObjectAccessories
      objectId={objectId}
      customerId={customerId}
      cameras={cameras}
      locations={locations}
      transmitters={transmitters}
      loadTransmitters={loadObjectTransmitters}
      loadLocations={loadLocations}
      loadCameras={loadCameras}
      setCheckChanges={setCheckChanges}
    />
  );
};
export default observer(ObjectAccessoriesContainer);
