import React, { useEffect, useState } from "react";
import { Column, Spacer } from "../../../components/Layout/Layout";
import useSharedContact from "../SharedContactContext/useSharedContact";
import LoadingSpinner from "../../../ui-lib/components/Loading/LoadingSpinner";
import { OldScheduleHandler } from "./OldScheduleHandler";
import { NewSchedulerHandler } from "./NewSchedulerHandler";
import useUser from "../../../core/user/useUser";
import { IScheduleItem } from "../../../core/api/contacts/types";
import { getScheduleItems } from "../../../core/api/contacts/contacts";
import { notifyApiErrors } from "../../../core/helpers/helpers";
import Alert from "../../../ui-lib/components/Alerts/Alert";
import useTranslations from "../../../core/i18n/useTranslations";

export const Availability = () => {
  const t = useTranslations();
  const { config, authenticatedRequest } = useUser();
  const {
    data: contactData,
    isLoading: isContactLoading,
    update,
  } = useSharedContact();

  const [isLoading, setIsLoading] = useState(false);
  const [availabilityData, setAvailabilityData] = useState<IScheduleItem[]>([]);

  const fetchAvailability = async () => {
    try {
      setIsLoading(true);
      const result = await getScheduleItems(
        contactData?.id!,
        authenticatedRequest
      );
      setAvailabilityData(result.data);
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    } finally {
      setIsLoading(false);
    }
  };

  const innerUpdate = async (data: any) => {
    await fetchAvailability();
    update(data);
  };

  useEffect(() => {
    fetchAvailability();
  }, []);

  const renderScheduler = () => {
    const hasAccess = config?.show.includes("ContactSchedule");
    if (!hasAccess && !!availabilityData.length) {
      return (
        <>
          <h1>{t("Menu:availability")}</h1>
          <Spacer size={8} />
          <p>{t("Contacts:availability_description")}</p>
          <Spacer size={32} />
          <Alert
            title={t("Contacts:new_schedule_exist")}
            variant="info"
            icon="information-circle"
          />
        </>
      );
    }

    if (
      ((contactData?.startTime || contactData?.endTime) &&
        !availabilityData.length) ||
      !hasAccess
    ) {
      return (
        <OldScheduleHandler contactData={contactData!} update={innerUpdate} />
      );
    }

    return <NewSchedulerHandler scheduleData={availabilityData} />;
  };

  return (
    <Column style={{ width: "100%" }} type="top" align="start">
      {isContactLoading || isLoading ? (
        <LoadingSpinner theme="primary" />
      ) : (
        <Column style={{ width: "784px" }} type="top" align="start">
          {renderScheduler()}
        </Column>
      )}
    </Column>
  );
};
