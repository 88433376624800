import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";
import AlarmChart from "../../components/AlarmChart/AlarmChart";
import HomeSearchbar from "../../components/HomeSearchbar/HomeSearchbar";
import { Column } from "../../components/Layout/Layout";
import usePageState from "../../core/pagestate/usePageState";
import Divider from "../../ui-lib/components/Divider/Divider";
import PageHeader from "../../ui-lib/components/PageHeader/PageHeader";
import Panel from "../../ui-lib/components/Panel/Panel";
import styles from "./Home.module.css";
import useUser from "../../core/user/useUser";
import { WelcomeModal } from "../../components/WelcomeModal/WelcomeModal";
import LoadingSpinner from "../../ui-lib/components/Loading/LoadingSpinner";
import useTranslations from "../../core/i18n/useTranslations";

const Home = () => {
  const [showWelcomeModal, setShowWelcomeModal] = useState<boolean>(true);
  const pageState = usePageState();
  const chartPanel = useRef<HTMLDivElement>(null);
  const user = useUser();
  const t = useTranslations();

  useEffect(() => {
    if (
      localStorage.getItem("welcomeModal") ||
      sessionStorage.getItem("welcomeModalSession")
    ) {
      setShowWelcomeModal(false);
    }
  }, []);
  return (
    <>
      {showWelcomeModal && <WelcomeModal />}
      <PageHeader
        title={t("Menu:home")}
        userName={user.data?.username}
        icon={pageState.pageIcon}
      />
      <Divider />
      <Column className={styles.wrapper}>
        <Column className={styles.content} type="top">
          {!user.initialized ? (
            <LoadingSpinner theme="primary" />
          ) : (
            <>
              <HomeSearchbar />
              <div
                ref={chartPanel}
                style={{
                  flex: "1 1",
                  alignSelf: "stretch",
                  justifyContent: "stretch",
                  alignItems: "stretch",
                  overflow: "hidden",
                  display: "flex",
                }}
              >
                {user.config?.show.find((key) => key === "Alarms") && (
                  <Panel
                    className={styles.chartPanel}
                    padding={{ top: 12, right: 24, bottom: 12, left: 24 }}
                  >
                    <AlarmChart chartPanel={chartPanel} />
                  </Panel>
                )}
              </div>
            </>
          )}
        </Column>
      </Column>
    </>
  );
};

export default observer(Home);
