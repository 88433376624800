import { Transmitter } from "../../core/api/transmitters/types";
import Table, {
  RowAction,
  SortOrderParams,
  TableColumn,
} from "../../ui-lib/components/Tables/Table";
import useTranslations from "../../core/i18n/useTranslations";
import TableCell from "../../ui-lib/components/Tables/TableCell";
import LoadingSpinner from "../../ui-lib/components/Loading/LoadingSpinner";
import NoItems from "../../ui-lib/components/Tables/NoItems";
import { RefObject } from "react";
import { TableRowSelectionPopupActions } from "../../ui-lib/components/Tables/TableRowSelectionPopup";
import { Row } from "../../components/Layout/Layout";
import Icon from "../../ui-lib/components/Icon";

interface TransmittersTableProps {
  transmitters: Transmitter[];
  rowActions: RowAction<Object>[];
  loading: boolean;
  pageSettings: {
    page: number;
    pageSize: number;
  };
  totalAmount: number;
  tableRowSelectionPopupRef: RefObject<
    TableRowSelectionPopupActions<Record<string, any>>
  >;
  withFilters: boolean;
  sortParams?: SortOrderParams;
  setClearItemsFunction: (func: Function) => void;
  onPageSettingsChange: (args: { page: number; pageSize: number }) => void;
  onSortChange?: (sortOrderParams?: SortOrderParams) => void;
}

const columns = (t: (key: string) => string): TableColumn<Transmitter>[] => [
  {
    key: "identifier",
    header: t("Transmitters:table_columns_identifier"),
    fieldTemplate: (rowData: Transmitter) => (
      <TableCell
        value={rowData.identifier}
        linkTo={`transmitters/${rowData.id}`}
      />
    ),
    sortByField: "phone",
  },
  {
    key: "productName",
    header: t("Table:Transmitters_ProductName"),
    sortByField: "product",
    fieldTemplate: (rowData: Transmitter) => (
      <TableCell value={rowData.productName} />
    ),
  },
  {
    key: "customer",
    header: t("Common:organisation"),
    sortByField: "customer",
    fieldTemplate: (rowData: Transmitter) => (
      <TableCell value={rowData.customerName} />
    ),
  },
  {
    key: "connectedTo",
    header: t("Common:connected_to"),
    sortByField: "objectName",
    fieldTemplate: (rowData: Transmitter) => (
      <TableCell
        value={
          rowData.objectName && (
            <Row type="fill">
              <Icon name={rowData.adminId !== -1 ? "phone-incoming" : "cube"} />
              <span>{rowData.objectName}</span>
            </Row>
          )
        }
        linkTo={
          rowData.adminId !== -1
            ? `administrators/${rowData.adminId}`
            : `objects/${rowData.userId}`
        }
      />
    ),
  },
  {
    key: "manufacturer",
    header: t("Transmitters:table_columns_manufacturer"),
    sortByField: "manufacturer",
    fieldTemplate: (rowData: Transmitter) => (
      <TableCell value={rowData.manufacturer} />
    ),
  },
  {
    key: "model",
    header: t("Transmitters:table_columns_model"),
    sortByField: "model",
    fieldTemplate: (rowData: Transmitter) => (
      <TableCell value={rowData.model} />
    ),
  },
  {
    key: "firmware",
    header: t("Transmitters:table_columns_firmware"),
    sortByField: "firmware",
    fieldTemplate: (rowData: Transmitter) => (
      <TableCell value={rowData.firmware} />
    ),
  },
  {
    key: "version",
    header: t("Transmitters:version"),
    sortByField: "version",
    fieldTemplate: (rowData: Transmitter) => (
      <TableCell value={rowData.version} />
    ),
  },
];

const TransmittersTable = ({
  transmitters,
  rowActions,
  loading,
  pageSettings,
  totalAmount,
  tableRowSelectionPopupRef,
  withFilters,
  sortParams,
  onPageSettingsChange,
  setClearItemsFunction,
  onSortChange,
}: TransmittersTableProps) => {
  const t = useTranslations();

  if (loading) {
    return <LoadingSpinner theme="primary" />;
  }

  return (
    <>
      {transmitters.length > 0 ? (
        <Table<Transmitter>
          rowActionsFixed
          showRowHover
          hideEmptyMessage
          withRowSelection
          withPagination
          withLazyLoading
          tableName="table-transmitters"
          items={transmitters}
          columns={columns(t)}
          rowActions={rowActions}
          rowActionsColWidth={64}
          sortParams={sortParams}
          onSortChange={onSortChange}
          setClearItemsFunction={setClearItemsFunction}
          onRowSelectionChange={(selectedItems) => {
            tableRowSelectionPopupRef.current?.open(selectedItems);
          }}
          paginatedItems={{
            items: transmitters,
            pagination: {
              offset: pageSettings.pageSize * (pageSettings.page - 1),
              limit: pageSettings.pageSize,
              total: totalAmount,
            },
          }}
          onPageChange={(nextPage) => {
            const page = Math.floor(nextPage.offset / nextPage.limit);

            if (!Number.isNaN(page) && nextPage.limit) {
              onPageSettingsChange({
                page: page + 1,
                pageSize: nextPage.limit,
              });
            }
          }}
        />
      ) : (
        <NoItems
          title={
            !withFilters
              ? t("Table:noresult_title")
              : t("Table:no_results_found")
          }
          icon={!withFilters ? "eye-off" : undefined}
          subTitle={
            !withFilters
              ? t("Table:noresult_subtitle")
              : t("Table:adjust_filter_description")
          }
          clear={undefined}
        />
      )}
    </>
  );
};

export default TransmittersTable;
