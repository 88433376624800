import { useHistory } from "react-router-dom";
import {
  AdministratorDetails,
  IAdminCopy,
} from "../../../../core/api/administrators/types";
import useTranslations from "../../../../core/i18n/useTranslations";
import useUser from "../../../../core/user/useUser";
import { useResponseCenters } from "../../../../core/api/responsecenters/responsecenters";
import { ResponseCenterParams } from "../../../../core/api/responsecenters/types";
import { useOrganizationsTree } from "../../../../core/api/organizations/organizations";
import { AddAdministrator } from "../../AddAdministrator/AddAdministrator";
import { createCopyByAdmin } from "../../../../core/api/administrators/administrators";
import { getErrorKey } from "../../../../components/Errors/ErrorAlert";
import { notify } from "../../../../ui-lib/components/Alerts/Toast";
import { ITreeData } from "../../../../ui-lib/components/Hierarchy/Tree";

export const CopyAdministratorModal = ({
  copyModalOpen,
  adminData,
  onClose,
  sellers,
}: {
  copyModalOpen: boolean;
  adminData: AdministratorDetails;
  onClose: () => void;
  sellers: ITreeData[];
}) => {
  const t = useTranslations();
  const history = useHistory();
  const { authenticatedRequest } = useUser();
  const alarmReceptions = useResponseCenters({
    privateOnly: "true",
    page: 1,
    pageSize: 10000,
  } as ResponseCenterParams);
  const organizations = useOrganizationsTree();

  const onSubmit = async (data: AdministratorDetails) => {
    try {
      const dataToUpload = { ...data, adminId: adminData.id } as IAdminCopy;
      if (data.responseCenterId) {
        delete dataToUpload.organizationId;
      }
      const result = await createCopyByAdmin(
        adminData.id,
        dataToUpload,
        authenticatedRequest
      );
      onClose();
      history.push(`/adminportal/administrators/${result.data}`);
    } catch (error: any) {
      const errorKey = getErrorKey(error);
      notify({
        message: t(`Errors:${errorKey}`),
        variant: "error",
      });
    }
  };

  const defaultValues = {
    type: adminData.type === "customer" ? "admin" : adminData.type,
    mainSettings: true,
    menuAlternatives: true,
    forcePasswordReset: true,
    passwordConstraints: adminData.passwordConstraints,
    organizationId: adminData.organizationId,
  } as any;
  if (adminData.type === "seller") {
    defaultValues.sellerId = adminData.sellerId;
  }
  if (adminData.type === "respondent") {
    defaultValues.adminRespondentGroups = true;
    defaultValues.responseCenterId = adminData.responseCenterId;
  } else {
    defaultValues.adminGUIConfigs = true;
    defaultValues.adminRoles = true;
  }

  return (
    <AddAdministrator
      isOpen={copyModalOpen}
      onClose={onClose}
      organisations={organizations.data}
      alarmReceptions={alarmReceptions}
      creationType="copy"
      selectedType={defaultValues.type}
      defaultValues={defaultValues}
      onCopySubmit={onSubmit}
      onCancel={onClose}
      sellers={sellers}
      extraHeaderText={`${t("Common:based_on")} ${adminData.username}`}
      isLoading={organizations.isLoading || alarmReceptions.isLoading}
    />
  );
};
