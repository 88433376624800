import { useEffect, useRef, useState } from "react";
import { AddressResponse } from "../../../../core/api/addresses/types";

import Table from "../../../../ui-lib/components/Tables/Table";
import Modal, { ModalActions } from "../../../../ui-lib/components/Popup/Modal";

import { CustomTableCell } from "../CustomTableCell/CustomTableCell";
import EditAddressModal from "./EditAddressModal";
import { DeleteAddressModal } from "./DeleteAddressModal";
import useTranslations from "../../../../core/i18n/useTranslations";
import { useModalIdHook } from "../../../../core/hooks/modalIdsHandlers/useIdModalHook";
import { useHistory } from "react-router";
import { urlSearchParser } from "../../../../ui-lib/utils/urlSearchParser";

export const AddressTable = ({
  objectId,
  addresses,
  onAddressUpdate,
}: {
  objectId: string;
  addresses: AddressResponse[];
  onAddressUpdate: () => void;
}) => {
  const t = useTranslations();

  const history = useHistory();
  const tableState = history.location.search;
  const searchState = urlSearchParser(tableState);

  const [addressId, setAddressId, resetAddressId] = useModalIdHook(
    searchState?.addressId || undefined,
    "addressId"
  );

  const editModalRef = useRef<ModalActions>(null);
  const [upForEdit, setUpForEdit] = useState<number | undefined>();

  const deleteModalRef = useRef<ModalActions>(null);
  const [upForDelete, setUpForDelete] = useState<number | undefined>();

  useEffect(() => {
    if (addressId.id) {
      setUpForEdit(+addressId.id);
      editModalRef?.current?.open();
    }
  }, [addressId]);

  return (
    <>
      <Table<AddressResponse>
        columns={[
          {
            header: t("Table:Addresses_Description"),
            fieldTemplate: (rowData) => (
              <CustomTableCell
                label={rowData.description}
                placeholder={t("Objects:placeholder_address_no_description")}
              />
            ),
          },
          {
            header: t("Table:Street"),
            fieldTemplate: (rowData) => {
              const allDataPresent = rowData.streetAddress && rowData.city;
              const label = `${rowData.streetAddress || ""}${
                allDataPresent ? ", " : ""
              }${rowData.city ? rowData.city : ""}`;

              return <CustomTableCell label={label} icon="location-marker" />;
            },
          },
          {
            header: t("Common:address_main_number"),
            fieldTemplate: (rowData) => (
              <CustomTableCell
                label={rowData.telephone1}
                icon="phone"
                placeholder={t("Objects:placeholder_address_no_phone")}
              />
            ),
          },
        ]}
        items={addresses}
        rowActions={[
          {
            text: t("Objects:edit_details"),
            icon: "pencil-alt",
            iconVariant: "secondary",
            iconSize: 16,
            onClick: (rowData) => {
              setAddressId(rowData.id);
            },
          },
          {
            text: t("Objects:remove_from"),
            icon: "x",
            iconVariant: "secondary",
            iconSize: 16,
            onClick: (rowData) => {
              setUpForDelete(rowData.id);
              deleteModalRef?.current?.open();
            },
          },
        ]}
        rowActionsColWidth={45}
        rowActionsFixed
        hideEmptyMessage
        noRowsMessage={t("Table:no_records_found")}
        showRowHover
        rowActionContextMenu
        withShowMore
      />

      <Modal modalRef={editModalRef}>
        {(close) =>
          upForEdit ? (
            <EditAddressModal
              objectId={objectId}
              addressId={upForEdit}
              onClose={() => {
                close();
                resetAddressId();
              }}
              onEdit={() => {
                close();
                onAddressUpdate();
              }}
            />
          ) : null
        }
      </Modal>

      <Modal modalRef={deleteModalRef}>
        {(close) => (
          <DeleteAddressModal
            objectId={objectId}
            addressId={upForDelete}
            onClose={close}
            onDelete={() => {
              close();
              onAddressUpdate();
            }}
          />
        )}
      </Modal>
    </>
  );
};
