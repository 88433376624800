import { observer } from "mobx-react";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import BulkDeleteModal from "../../components/BulkDeleteModal";
import { CreateCameraModal } from "../../components/Cameras/CreateCameraModal";
import { Column, Row, Spacer } from "../../components/Layout/Layout";
import {
  deleteCamera,
  getCamerasInStock,
} from "../../core/api/cameras/cameras";
import { useOrganizationsTree } from "../../core/api/organizations/organizations";
import useTranslations from "../../core/i18n/useTranslations";
import usePageState from "../../core/pagestate/usePageState";
import Button from "../../ui-lib/components/Button/Button";
import Divider from "../../ui-lib/components/Divider/Divider";
import PageHeader from "../../ui-lib/components/PageHeader/PageHeader";
import TableRowSelectionPopup, {
  TableRowSelectionPopupActions,
} from "../../ui-lib/components/Tables/TableRowSelectionPopup";
import useUser from "../../core/user/useUser";
import { RowAction } from "../../ui-lib/components/Tables/Table";
import CamerasTable from "./CamerasTable";
import {
  notifyApiErrors,
  sortParamsToString,
} from "../../core/helpers/helpers";
import { Camera, ICameras } from "../../core/api/cameras/types";
import { useSortParams } from "../../core/hooks/filters/useSortParams";
import {
  storeTableState,
  useStoredTableState,
} from "../../core/hooks/filters/useStoredTableState";
import {
  searchStringParser,
  urlSearchParser,
} from "../../ui-lib/utils/urlSearchParser";
import { usePageFilter } from "../../core/hooks/filters/usePageFilter";
import { useQueryState } from "../../core/hooks/filters/useQueryState";

import styles from "../Layout.module.css";
import SearchInput from "../../ui-lib/components/Inputs/SearchInput";

export const PAGE_ID = "adminportal/cameras";

const Cameras = () => {
  storeTableState(PAGE_ID);
  const pageState = usePageState();
  const history = useHistory();
  const t = useTranslations();
  const user = useUser();
  const tableState = useStoredTableState(PAGE_ID);
  const searchState = urlSearchParser(tableState);

  const clearSelectedFunction = useRef<Function>();
  const selectedItemRows = useRef<Camera[]>([]);
  const tableRowSelectionPopupRef =
    useRef<TableRowSelectionPopupActions<Record<string, any>>>(null);

  const { sortParams, setSortParams } = useSortParams(searchState);
  const { pageFilter, setPageFilter, resetPageFilter } =
    usePageFilter(searchState);
  const [searchParam, setSearchParam] = useQueryState<{ q: string }>({
    q: searchStringParser(searchState?.q) ?? "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [deletionModalOpen, setDeletionModalOpen] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);
  const [camerasData, setCamerasData] = useState<Camera[]>([]);

  const { data: organisationTree } = useOrganizationsTree();

  const fetchCameras = async () => {
    setIsLoading(true);
    try {
      const result: ICameras = await getCamerasInStock(
        {
          page: pageFilter.page,
          pageSize: pageFilter.pageSize,
          order: sortParamsToString(sortParams),
          q: searchParam.q,
        },
        user.authenticatedRequest
      );
      setCamerasData(result.cameras);
      setTotal(result.total);
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    sessionStorage.setItem(
      "prevPath",
      JSON.stringify({
        path: history.location.pathname,
        pathName: t("Menu:cameras"),
      })
    );
  }, []);

  useEffect(() => {
    fetchCameras();
  }, [pageFilter, searchParam, sortParams]);

  const rowActions = [
    {
      icon: "pencil-alt",
      text: t("Common:label_edit"),
      onClick: ({ id }: { id: any }) =>
        history.push(`/adminportal/cameras/${id}`),
    } as RowAction<Object>,
  ];

  if (user.config?.show.includes("DeleteCameras")) {
    rowActions.push({
      icon: "trash",
      iconVariant: "secondary",
      text: t("Common:delete"),
      onClick: async (rowData: any) => {
        selectedItemRows.current = [rowData as Camera];
        setDeletionModalOpen(true);
      },
    });
  }

  const isFilterApplied = !!searchParam.q.length;

  return (
    <>
      <PageHeader
        title={t("Menu:cameras")}
        userName={user.data?.username}
        icon={pageState.pageIcon}
      >
        <CreateCameraModal
          organisationTree={organisationTree}
          onSubmit={(cameraId?: string | number) => {
            if (cameraId) {
              history.push(`/adminportal/cameras/${cameraId}`);
            } else {
              fetchCameras();
            }
          }}
          modalButtonTitle={t("Cameras:add_camera")}
          modalTitle={t("Cameras:add_camera_modal_title")}
          saveButtonTitle={t("Cameras:add_camera_modal_save")}
          variant="primary"
        />
      </PageHeader>
      <BulkDeleteModal
        isOpen={deletionModalOpen}
        onClose={() => setDeletionModalOpen(false)}
        labels={{
          single: t("Cameras:type"),
          multi: t("Cameras:type_multi"),
        }}
        onDelete={() => {
          setDeletionModalOpen(false);
          clearSelectedFunction.current?.();
          tableRowSelectionPopupRef.current?.close(true);
          setTimeout(() => {
            fetchCameras();
          }, 500);
        }}
        ids={selectedItemRows.current?.map((s) => s.id.toString()) || []}
        apiFunc={deleteCamera}
      />
      <Divider />
      {user.config?.show.includes("DeleteCameras") && (
        <TableRowSelectionPopup
          ref={tableRowSelectionPopupRef}
          renderBody={(selectedItems) => (
            <Row type="right" align="center">
              <Spacer size={16} />
              <Button
                variant="destructive"
                text={`${t("Cameras:delete_selection")} (${
                  selectedItems.length
                })`}
                onClick={() => {
                  selectedItemRows.current = selectedItems as Camera[];
                  setDeletionModalOpen(true);
                }}
              />
            </Row>
          )}
        />
      )}

      <Column type="top" className={styles.content}>
        <SearchInput
          limit={1}
          placeholder={t("Cameras:cameras_search_placeholder")}
          value={searchParam.q}
          onChange={(value: string) => {
            if (searchParam.q !== value) {
              setCamerasData([]);
              resetPageFilter();
            }
            setSearchParam({ q: value });
          }}
        />
        <Spacer size={16} />
        <Divider />
        <Spacer size={16} />
        <CamerasTable
          camerasData={camerasData}
          totalAmount={total}
          rowActions={rowActions}
          sortParams={sortParams}
          pageSettings={pageFilter}
          tableRowSelectionPopupRef={tableRowSelectionPopupRef}
          loading={isLoading}
          isFilterApplied={isFilterApplied}
          setClearItemsFunction={(func) =>
            (clearSelectedFunction.current = func)
          }
          onPageSettingsChange={setPageFilter}
          onSortChange={setSortParams}
          resetPageFilter={resetPageFilter}
        />
      </Column>
    </>
  );
};

export default observer(Cameras);
