import { useRef } from "react";
import DateTimeRangePickerPopup from "../../ui-lib/components/DateAndTime/DateTimeRangePickerPopup";
import {
  getTimeSpan,
  timeSpanToString,
  customTimeSpanToString,
} from "./timeSpan";
import { TimeSpanType, TimeSpan } from "./types";
import Radio from "../../ui-lib/components/Radio/Radio";
import { Spacer } from "../Layout/Layout";
import Button from "../../ui-lib/components/Button/Button";
import styles from "./DateFilter.module.css";
import useLanguage from "../../core/i18n/useLanguage";
import useTranslations from "../../core/i18n/useTranslations";
import { OverlayPanel } from "primereact/overlaypanel";

const timeSpans: { type: TimeSpanType; key: string }[] = [
  {
    type: TimeSpanType.Today,
    key: "today",
  },
  {
    type: TimeSpanType.Last7Days,
    key: "last7days",
  },
  {
    type: TimeSpanType.LastMonth,
    key: "current_month",
  },
  {
    type: TimeSpanType.PreviousMonth,
    key: "previous_month",
  },
  {
    type: TimeSpanType.Custom,
    key: "custom",
  },
];

const DateFilterRadioGroup = ({
  value,
  onChange,
  disabled,
}: {
  value: {
    type: TimeSpanType;
    timeSpan: TimeSpan;
  };
  onChange: (args: { type: TimeSpanType; timeSpan: TimeSpan }) => void;
  disabled?: boolean;
}) => {
  const t = useTranslations();
  const languageStore = useLanguage();
  const locale = languageStore.currentLanguage?.region || "sv-SE";

  const popupRef = useRef<OverlayPanel>(null);

  return (
    <>
      <div className={styles.radioGroupWrapper}>
        {timeSpans.map(({ type, key }) => {
          const isSelected = type === value.type;
          const dateString = timeSpanToString({ type, locale });

          return (
            <div key={type}>
              <Radio
                name="exportTimeSpan"
                inputId={`exportTimeSpan-${type}`}
                value={type}
                checked={isSelected}
                text={t(`Filters:${key}`)}
                description={dateString}
                onChange={() => {
                  onChange({
                    type,
                    timeSpan: getTimeSpan({ type }),
                  });
                }}
                disabled={disabled}
              />
              {type === TimeSpanType.Custom && isSelected && (
                <>
                  <Spacer size={8} />
                  <Button
                    variant="secondary"
                    image="calendar"
                    text={
                      value.timeSpan.start && value.timeSpan.end
                        ? customTimeSpanToString({
                            timeSpan: value.timeSpan,
                            locale,
                          })
                        : t("Filters:set_custom_range")
                    }
                    onClick={(e) => popupRef?.current?.toggle(e)}
                    disabled={disabled}
                  />
                </>
              )}
            </div>
          );
        })}
      </div>
      <DateTimeRangePickerPopup
        popupRef={popupRef}
        initialRange={
          value.timeSpan.start && value.timeSpan.end
            ? [value.timeSpan.start, value.timeSpan.end]
            : undefined
        }
        onSubmit={(range) => {
          if (range) {
            onChange({
              ...value,
              timeSpan: { start: range[0], end: range[1] },
            });
          }
        }}
      />
    </>
  );
};

export default DateFilterRadioGroup;
