import classNames from "classnames";
import { ReactNode } from "react";
import Popup from "reactjs-popup";
import styles from "../Menu.module.css";

export type PopupContextPosition =
  | "top left"
  | "top center"
  | "top right"
  | "right top"
  | "right center"
  | "right bottom"
  | "bottom left"
  | "bottom center"
  | "bottom right"
  | "left top"
  | "left center"
  | "left bottom"
  | "center center";

export interface PopupContextMenuProps {
  /** PopupPosition from reactjs-popup */
  position?: PopupContextPosition;

  /* Function that returns element that triggers menu. Note: if custom component generates ref issues, try to wrap in div, for instance. */
  trigger: (isOpen: boolean) => JSX.Element;

  /* When set to true, component is rendered as usual but menu is disabled for it */
  disabled?: boolean;

  /* Starting from v2 you should add nested props if you are going to use nested popups ( to make sure onClickOutside behavior will work as expected) */
  nested?: boolean;

  className?: string;

  children: ReactNode;

  onClose?: () => void;

  dataCy?: string;
}

/** Context menu that is shown when trigger element clicked */
export const PopupContextMenu = ({
  position = "top left",
  trigger,
  disabled,
  nested,
  children,
  className,
  onClose = () => {},
  dataCy,
}: PopupContextMenuProps) => (
  <Popup
    trigger={(isOpen) => trigger(isOpen)}
    position={position}
    on={["click"]}
    closeOnDocumentClick
    closeOnEscape
    onClose={onClose}
    repositionOnResize
    keepTooltipInside
    arrow={false}
    disabled={disabled}
    nested={nested}
    className={classNames(className, styles.popupContextMenu)}
  >
    <div
      data-cy={dataCy}
      style={{ width: "100%" }}
      className={styles.popupContextMenuItems}
    >
      {children}
    </div>
  </Popup>
);
export default PopupContextMenu;
