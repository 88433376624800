/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { Story, Meta } from "@storybook/react";

import Divider, { DividerProps } from "./Divider";

export default {
  title: "Library/Divider",
  component: Divider,
} as Meta;

const Template: Story<DividerProps> = (args) => <Divider {...args} />;

export const Horizontal = Template.bind({});
Horizontal.args = {
  vertical: true,
};
