import useTranslations from "../../../../../core/i18n/useTranslations";
import NumberInput from "../../../../../ui-lib/components/Inputs/NumberInput";

export const TimeoutControl = ({
  label,
  min = 1,
  max = 3600,
  value,
  onChange,
  hasError,
  disabled,
  required,
}: {
  label?: string;
  min?: number;
  max?: number;
  value?: number | null;
  onChange: (value?: number | null) => void;
  hasError?: boolean;
  disabled?: boolean;
  required?: boolean;
}) => {
  const t = useTranslations();

  const placeholder = `${min}...${max}`;

  return (
    <NumberInput
      required={required}
      label={label}
      placeholder={placeholder}
      pinnedText="sec"
      pinnedTextPosition="right"
      width="100%"
      value={`${value}`}
      disabled={disabled}
      onChange={(e) => {
        onChange(+e.currentTarget.value || null);
      }}
      validationError={
        hasError ? `${t("Errors:input_format")}: ${placeholder}` : undefined
      }
    />
  );
};
