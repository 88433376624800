import React, { createContext, useEffect, useRef, useState } from "react";
import useUser from "../../../core/user/useUser";
import {
  getOrganization,
  getRetentionData,
  getRetentionOptions,
  getSettings,
  updateOrganization,
  updateRetentionData,
} from "../../../core/api/organizations/organizations";
import {
  IOrganizationDetails,
  IRetentionDetails,
} from "../../../core/api/organizations/types";
import { IAdvancedData } from "../AdvancedSettings/AdvancedSettings";
import { notifyApiErrors } from "../../../core/helpers/helpers";
import { getSellers } from "../../../core/api/sellers/sellers";
import { IItemProp } from "../../../ui-lib/components/Dropdown/DropdownItem";
import { IResellerType } from "../../../core/api/sellers/types";

export const SharedOrganizationContext = createContext<{
  data?: IOrganizationDetails;
  retentionData?: any;
  retentionOptions?: any;
  isLoading: boolean;
  isError: boolean;
  update: (organizationDetails: IOrganizationDetails) => Promise<void>;
  getOrganizationData: () => void;
  updateRetention: (retentionData: IRetentionDetails) => Promise<void>;
  fetchOrganizationRetentionData: () => Promise<void>;
  allSettings: IAdvancedData[];
  getSettingsData: () => Promise<void>;
  sellers: IItemProp[];
}>({
  data: undefined,
  isLoading: false,
  isError: false,
  retentionData: undefined,
  retentionOptions: undefined,
  allSettings: [],
  sellers: [],
  update: async () => {},
  getOrganizationData: async () => {},
  updateRetention: async () => {},
  fetchOrganizationRetentionData: async () => {},
  getSettingsData: async () => {},
});

export const SharedOrganizationProvider = ({
  organizationId,
  children,
  fetchOrganizationData = true,
  preOrganizationDetails,
  getOrganizationData,
}: {
  organizationId: string;
  children: React.ReactNode;
  fetchOrganizationData: boolean;
  preOrganizationDetails?: IOrganizationDetails;
  getOrganizationData: () => void;
}) => {
  const isAlive = useRef(false);
  const { authenticatedRequest, config } = useUser();

  const [data, setData] = useState<IOrganizationDetails>();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [retentionData, setRetentionData] = useState<any>();
  const [retentionOptions, setRetentionOptions] = useState<any>();
  const [allSettings, setAllSettings] = useState<IAdvancedData[]>([]);
  const [sellers, setSellers] = useState<IItemProp[]>([]);

  useEffect(() => {
    setData(preOrganizationDetails);
  }, [preOrganizationDetails]);

  const fetchOrganization = async () => {
    setData(undefined);
    setIsError(false);
    setIsLoading(true);

    try {
      const result = await getOrganization(
        organizationId,
        authenticatedRequest
      );
      if (isAlive.current) {
        setData(result.data);
      }
    } catch (error) {
      if (isAlive.current) {
        setIsError(true);
      }
    }

    if (isAlive.current) {
      setIsLoading(false);
    }
  };

  const update = async (organizationDetails: IOrganizationDetails) => {
    setIsLoading(true);

    try {
      await updateOrganization(
        organizationId,
        organizationDetails,
        authenticatedRequest
      );
      setData({
        ...data,
        ...organizationDetails,
      });
    } catch (error: any) {
      if (isAlive.current) {
        setIsLoading(false);
        notifyApiErrors(error.response?.data?.errors);
      }
    }
  };

  const fetchOrganizationRetentionData = async () => {
    setRetentionData(undefined);
    setIsError(false);
    setIsLoading(true);

    try {
      const resData = await getRetentionData(
        organizationId,
        authenticatedRequest
      );
      const resOption = await getRetentionOptions(authenticatedRequest);

      if (isAlive.current) {
        setRetentionData(resData.data);
        setRetentionOptions(resOption.data);
      }
    } catch (error) {
      if (isAlive.current) {
        setIsError(true);
      }
    }
    if (isAlive.current) {
      setIsLoading(false);
    }
  };

  const updateRetention = async (retentionValues: IRetentionDetails) => {
    setIsLoading(true);

    try {
      await updateRetentionData(
        organizationId,
        authenticatedRequest,
        retentionValues
      );
      setRetentionData({ ...retentionData, ...retentionValues });
    } catch (error: any) {
      if (isAlive.current) {
        setIsLoading(false);
        notifyApiErrors(error.response?.data?.errors);
      }
    }
    if (isAlive.current) {
      setIsLoading(false);
    }
  };

  const getSettingsData = async () => {
    try {
      const { data: settings } = await getSettings(
        organizationId,
        authenticatedRequest
      );
      setAllSettings(settings);
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    }
  };

  const fetchSellers = async () => {
    try {
      const res = await getSellers(authenticatedRequest);
      if (res.data) {
        const sellersItems = res.data.map((seller: IResellerType) => ({
          id: seller.id,
          name: seller.name,
          isSelected: false,
        }));
        setSellers(sellersItems);
      }
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    }
  };

  // Fetch object on mount and when object is updated
  useEffect(() => {
    if (fetchOrganizationData) fetchOrganization();
    if (config?.show.includes("OrganizationRetentions")) {
      fetchOrganizationRetentionData();
    }
    if (config?.show.includes("SkygdSuperAdmin")) {
      fetchSellers();
    }
    getSettingsData();
  }, [organizationId]);

  // To make sure we do not try to update state if component did unmount
  useEffect(() => {
    isAlive.current = true;

    return () => {
      isAlive.current = false;
    };
  }, []);

  return (
    <SharedOrganizationContext.Provider
      value={{
        data,
        isLoading,
        isError,
        update,
        getOrganizationData,
        retentionData,
        retentionOptions,
        updateRetention,
        fetchOrganizationRetentionData,
        getSettingsData,
        allSettings,
        sellers,
      }}
    >
      {children}
    </SharedOrganizationContext.Provider>
  );
};
