import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { saveTransmitterPeriodicDisables } from "../../core/api/transmitters/transmitters";
import {
  EditPeriodicDisable,
  PeriodicDisable,
  WeekDaySelection,
} from "../../core/api/transmitters/types";
import { notifyApiErrors } from "../../core/helpers/helpers";
import useTranslations from "../../core/i18n/useTranslations";
import useUser from "../../core/user/useUser";
import Button from "../../ui-lib/components/Button/Button";
import TimePicker from "../../ui-lib/components/DateAndTime/TimePicker";
import Dropdown from "../../ui-lib/components/Dropdown/Dropdown";
import { IDropdownItem } from "../../ui-lib/components/Dropdown/DropdownItem";
import InputContainer from "../../ui-lib/components/Inputs/InputContainer";
import StyledModal from "../../ui-lib/components/Modal/Modal";
import TagSelector, {
  TagSelectorOption,
} from "../../ui-lib/components/Tags/TagSelector";
import { Column, Row, Spacer } from "../Layout/Layout";
import NumberInput from "../../ui-lib/components/Inputs/NumberInput";
import styles from "./Transmitters.module.css";
import { useTimezones } from "../../core/api/administrators/administrators";

export const CreateTransmitterScheduleModal = ({
  onSubmit,
  onClose,
  transmitterId,
}: {
  onSubmit?: () => void;
  onClose?: () => void;
  transmitterId: number;
}) => {
  const t = useTranslations();
  const { authenticatedRequest, config } = useUser();
  const {
    control,
    formState: { isSubmitting, errors },
    handleSubmit,
    reset,
    setValue,
    getValues,
  } = useForm<EditPeriodicDisable>();

  const [isCreateScheduleOpen, setIsCreateScheduleOpen] = useState(false);
  const timezoneItems = useRef<IDropdownItem[]>([]);
  const active = useRef(false);
  const weekDays = moment.weekdaysShort(false);
  weekDays.push(weekDays.shift() || "");
  const { data: timezones } = useTimezones();

  const closeModal = () => {
    setIsCreateScheduleOpen(false);
    reset();
    onClose?.();
  };

  useEffect(() => {
    if (isCreateScheduleOpen && timezoneItems.current) {
      setValue(
        "Timezone",
        timezoneItems.current.find((tz) => tz.isSelected)!.id as string
      );
    }
  }, [isCreateScheduleOpen]);

  const onInternalSubmit = async (formData: EditPeriodicDisable) => {
    const beginParts = (formData.Begin ? formData.Begin : "00:00").split(":");
    const endParts = (formData.End ? formData.End : "23:59").split(":");
    let beginHours = 0;
    let endHours = 23;
    let beginMinutes = 0;
    let endMinutes = 59;
    if (beginParts?.length === 2) {
      beginHours = parseInt(beginParts[0], 10);
      beginMinutes = parseInt(beginParts[1], 10);
    }
    if (endParts?.length === 2) {
      endHours = parseInt(endParts[0], 10);
      endMinutes = parseInt(endParts[1], 10);
    }
    let timeZone = timezoneItems.current?.find(
      (item) => item.id === formData.Timezone
    );
    if (timeZone === undefined) {
      // eslint-disable-next-line prefer-destructuring
      timeZone = timezoneItems.current[0];
    }
    const postData: PeriodicDisable = {
      alarmCode: formData.AlarmCode,
      timezone: timeZone.name,
      beginHour: beginHours,
      endHour: endHours,
      beginMinute: beginMinutes,
      endMinute: endMinutes,
      monday: formData.Days[0].Selected,
      tuesday: formData.Days[1].Selected,
      wednesday: formData.Days[2].Selected,
      thursday: formData.Days[3].Selected,
      friday: formData.Days[4].Selected,
      saturday: formData.Days[5].Selected,
      sunday: formData.Days[6].Selected,
    };
    try {
      await saveTransmitterPeriodicDisables(
        transmitterId,
        postData,
        authenticatedRequest
      );
      onSubmit?.();
      closeModal();
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    }
  };
  const getSelectedOptions = (selectedDays: WeekDaySelection[] | undefined) => {
    if (!selectedDays) {
      return [];
    }
    return selectedDays
      .filter((day) => day.Selected)
      .map((day) => ({
        title: weekDays[day.DayIndex],
        value: day.DayIndex.toString(),
      }));
  };
  const hasDaySelected = () => {
    const val = getValues("Days");
    return val?.find((v) => v.Selected) !== undefined;
  };
  useEffect(() => {
    active.current = true;
    return () => {
      active.current = false;
    };
  }, []);
  useEffect(() => {
    if (timezones) {
      const userTimeZone =
        timezones.find(
          (zone) => zone.timezoneNameId === config?.timezone.timezoneNameId
        ) || moment.tz.guess();
      const preSelectedIndex = timezones.indexOf(userTimeZone as any);
      const ddItems: IDropdownItem[] = timezones.map((timezone, index) => ({
        id: index,
        name: timezone.timezoneName,
        isSelected:
          preSelectedIndex !== -1 ? preSelectedIndex === index : index === 0,
      }));
      setValue("Timezone", ddItems.find((tz) => tz.isSelected)!.id as string);
      timezoneItems.current = ddItems;
    }
  }, [timezones]);

  return (
    <>
      <Button
        variant="secondary"
        image="calendar"
        text={t("Transmitters:schedule_table_add_new")}
        onClick={() => {
          setIsCreateScheduleOpen(true);
        }}
      />

      <StyledModal
        onClose={closeModal}
        isOpen={isCreateScheduleOpen}
        approveBtnText={t("Transmitters:create_schedule_modal_save")}
        cancelBtnText={t("Common:cancel")}
        modalTitle={t("Transmitters:create_schedule_modal_title")}
        onApproveBtnClick={() => handleSubmit(onInternalSubmit)()}
        onCancelBtnClick={closeModal}
        closeOnDocumentClick={false}
        css={{ maxWidth: 960 }}
        isLoading={isSubmitting}
      >
        <Spacer size={16} />
        <Column type="top" align="start">
          <p>{t("Transmitters:create_schedule_modal_description")}</p>
          <Spacer size={16} />
          <Row type="space" align="start">
            <Column type="top">
              <Controller
                name="Days"
                control={control}
                rules={{ validate: hasDaySelected }}
                render={({ field }) => (
                  <InputContainer
                    required
                    label={
                      <span className={styles.inputHeader}>
                        {t("Transmitters:create_schedule_modal_days")}
                      </span>
                    }
                    input={
                      <TagSelector
                        style={{ height: "41px", alignItems: "center" }}
                        maxPreviewOptions={7}
                        selectedOptions={getSelectedOptions(
                          getValues(field.name)
                        )}
                        options={weekDays.map((day, index) => ({
                          title: day.slice(0, -1),
                          value: index.toString(),
                        }))}
                        onSelect={(selectedOptions: TagSelectorOption[]) => {
                          const selectedDays = selectedOptions.map(
                            (day) => +day.value
                          );
                          setValue(
                            "Days",
                            weekDays.map((day, index) => ({
                              DayIndex: index,
                              Selected: selectedDays.indexOf(index) > -1,
                            }))
                          );
                        }}
                        validationError={
                          errors.Days &&
                          t("Transmitters:create_schedule_validation_days")
                        }
                      />
                    }
                  />
                )}
              />
              <Spacer size={16} />
              <Controller
                name="AlarmCode"
                control={control}
                render={({ field }) => (
                  <NumberInput
                    placeholder={t(
                      "Transmitters:create_schedule_placeholder_alarmcode"
                    )}
                    label={t("Transmitters:create_schedule_labels_alarmcode")}
                    value={field.value}
                    onChange={field.onChange}
                    validationError={
                      errors.AlarmCode &&
                      ((errors.AlarmCode.type === "min" &&
                        t(
                          "Transmitters:create_schedule_validation_alarmcode_min"
                        )) ||
                        t("Transmitters:create_schedule_validation_alarmcode"))
                    }
                  />
                )}
              />
            </Column>
            <Spacer size={16} />
            <Column type="top">
              <InputContainer
                label={
                  <span className={styles.inputHeader}>
                    {t("Transmitters:create_schedule_modal_times")}
                  </span>
                }
                input={
                  <Row>
                    <Controller
                      name="Begin"
                      control={control}
                      render={({ field }) => (
                        <TimePicker
                          value={getValues(field.name)}
                          onChange={(time) => {
                            setValue(field.name, time);
                          }}
                          invalid={errors.Begin !== undefined}
                          validationError={
                            errors.Begin &&
                            t("Transmitters:create_schedule_validation_begin")
                          }
                        />
                      )}
                    />
                    <span>-</span>
                    <Controller
                      name="End"
                      control={control}
                      render={({ field }) => (
                        <TimePicker
                          value={getValues(field.name)}
                          onChange={(time) => {
                            setValue(field.name, time);
                          }}
                          invalid={errors.End !== undefined}
                          validationError={
                            errors.End &&
                            t("Transmitters:create_schedule_validation_end")
                          }
                        />
                      )}
                    />
                  </Row>
                }
              />
              <Spacer size={16} />
              <Controller
                name="Timezone"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <InputContainer
                    required
                    label={
                      <label htmlFor="createscheduletimezone">
                        {t("Transmitters:create_schedule_modal_timezone")}
                      </label>
                    }
                    input={
                      <Dropdown
                        id="createscheduletimezone"
                        items={timezoneItems.current}
                        placeholder={t("Transmitters:select_time_zone")}
                        selectedItem={timezoneItems.current?.find(
                          (r) => getValues(field.name) === r.id
                        )}
                        onSelectItem={(item) => {
                          setValue(field.name, item.id as string);
                          timezoneItems.current.forEach((r) => {
                            r.isSelected = false;
                          });
                          item.isSelected = true;
                        }}
                        invalid={errors.Timezone !== undefined}
                        validationError={
                          errors.Timezone &&
                          t(
                            "Transmitters:create_schedule_modal_validation_timezone"
                          )
                        }
                      />
                    }
                  />
                )}
              />
            </Column>
          </Row>
          <Spacer size={16} />
        </Column>
      </StyledModal>
    </>
  );
};
