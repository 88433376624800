import { SyntheticEvent } from "react";
import styles from "./Chips.module.css";
import { Icon } from "../Icon";

export interface IChips {
  id: string | number;
  label: string;
}

export interface ChipsProps {
  item: IChips;
  width?: number | string;
  onRemove(arg: IChips, arg1: SyntheticEvent): void;
}

export default function Chips({ item, width, onRemove }: ChipsProps) {
  return (
    <div className={styles.chipsContainer} style={{ width }}>
      <div className={styles.text}>{item.label}</div>
      <div
        className={styles.imageWrapper}
        onClick={(e) => onRemove(item, e)}
        onKeyDown={(e) => onRemove(item, e)}
        role="button"
        tabIndex={0}
        aria-label="Close"
      >
        <Icon name="x" color="Grey-400" size={16} />
      </div>
    </div>
  );
}
