import classnames from "classnames";
import styles from "./LoadingSpinner.module.css";

export interface LoadingSpinnerProps {
  theme?: "light" | "dark" | "primary" | "error";
  size?: number;
  margin?: string;
}

const LoadingSpinner = ({
  theme = "primary",
  size = 64,
  margin,
}: LoadingSpinnerProps) => (
  <div
    style={{ width: `${size}px`, height: `${size}px`, margin }}
    className={classnames(styles["sk-fading-circle"], {
      [styles["theme-light"]]: theme === "light",
      [styles["theme-dark"]]: theme === "dark",
      [styles["theme-primary"]]: theme === "primary",
      [styles["theme-error"]]: theme === "error",
    })}
  >
    {[...Array(12)].map((_, i) => (
      <div
        key={`circle-${i + 1}`}
        className={classnames(
          styles["sk-circle"],
          styles[`sk-circle-${i + 1}`]
        )}
      />
    ))}
  </div>
);

export default LoadingSpinner;
