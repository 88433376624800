import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

type State = { [key: string]: string | undefined };

// Hook for calculating initial state from query
const useInitialState = <T extends State>(initial: T): T => {
  const { location } = useHistory();

  const searchParams = new URLSearchParams(location.search);

  const result: any = { ...initial };
  Object.keys(initial).forEach((key) => {
    const searchParamValue = searchParams.get(key);

    if (searchParamValue) {
      result[key] = searchParamValue;
    }
  });

  return result;
};

export const useQueryState = <T extends State>(
  initial: T
): [T, Dispatch<SetStateAction<T>>] => {
  const history = useHistory();
  const initialState = useInitialState(initial);
  const [state, setState] = useState<T>(initialState);

  // Update query params when state is changed
  useEffect(() => {
    const { pathname, search } = history.location;
    const searchParams = new URLSearchParams(search);

    Object.entries(state).forEach(([key, value]) => {
      if (value && value.length > 0) {
        searchParams.set(key, value);
      } else {
        searchParams.delete(key);
      }
    });

    history.replace(`${pathname}?${searchParams.toString()}`);
  }, [state]);

  return [state, setState];
};
