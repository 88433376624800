import useTranslations from "../../core/i18n/useTranslations";
import useUser from "../../core/user/useUser";
import { notifyApiErrors } from "../../core/helpers/helpers";
import { ITreeData } from "../../ui-lib/components/Hierarchy/Tree";
import { notify } from "../../ui-lib/components/Alerts/Toast";
import { IAlternatives } from "../../core/api/alternatives/types";
import { updateAlternative } from "../../core/api/alternatives/alternatives";
import { AlternativesForm } from "./AlternativesForm";

export const EditAlternativeModal = ({
  alternativeData,
  alternativesType,
  ownerId,
  onClose,
  onSubmitEnd,
  organisations,
  isMobile = false,
  withOrganizationsSelection,
  bothAppLinksRequired,
}: {
  alternativeData: IAlternatives;
  ownerId: number;
  alternativesType:
    | "adminId"
    | "organizationId"
    | "responseCenterId"
    | "sellerId";
  onClose: () => void;
  onSubmitEnd: () => void;
  organisations: ITreeData[];
  isMobile?: boolean;
  withOrganizationsSelection: boolean;
  bothAppLinksRequired: boolean;
}) => {
  const t = useTranslations();
  const { authenticatedRequest } = useUser();

  const labels = {
    title: t(`Administrator:alternative_${alternativeData ? "edit" : "add"}`),
    description: t(
      `Administrator:alternative_${
        alternativeData ? "edit" : "add"
      }_description`
    ),
    submit: t(alternativeData ? "Common:save" : "Administrator:create_link"),
  };

  const prepareDataToUpdate = (values: IAlternatives): any => {
    const updatedValues = { ...values };
    if (!updatedValues[alternativesType]) {
      updatedValues[alternativesType] = ownerId;
    }
    if (updatedValues.url) {
      updatedValues.showInAdmin = true;
      updatedValues.showInAlarmApp = false;
      updatedValues.iosLink = "";
      updatedValues.androidLink = "";

      if (updatedValues.url.split("//").length === 1) {
        updatedValues.url = `http://${updatedValues.url}`;
      }
    } else {
      updatedValues.showInAdmin = false;
      if (alternativesType === "responseCenterId") {
        updatedValues.showInResponseApp = true;
      } else {
        updatedValues.showInAlarmApp = true;
      }
      updatedValues.url = "";
    }
    if (alternativesType === "organizationId") {
      updatedValues.requireAction = "Users";
    }

    return updatedValues;
  };

  const onSubmit = async (values: IAlternatives) => {
    try {
      if (alternativeData) {
        await updateAlternative(values, authenticatedRequest);
      } else {
        const updatedValues = prepareDataToUpdate(values);
        await updateAlternative(updatedValues, authenticatedRequest);
      }
      notify({
        message: t(
          `Messages:${alternativeData ? "edit" : "add"}_alternative_success`
        ),
      });
      onClose();
      onSubmitEnd();
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    }
  };

  return (
    <AlternativesForm
      labels={labels}
      onSubmit={onSubmit}
      onClose={onClose}
      linkFor={isMobile ? "organization" : "admin"}
      defaultValues={alternativeData}
      organisations={withOrganizationsSelection ? organisations : undefined}
      bothAppLinksRequired={bothAppLinksRequired}
    />
  );
};
