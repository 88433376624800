import useTranslations from "../../../../core/i18n/useTranslations";
import BulkDeleteModal from "../../../../components/BulkDeleteModal";
import { deleteIpAddress } from "../../../../core/api/organizations/organizations";
import { IPFilter } from "../../../../core/api/organizations/types";

export const DeleteIPModal = ({
  isOpen,
  filters,
  onClose,
  onDelete,
}: {
  isOpen: boolean;
  filters: IPFilter[];
  onClose: () => void;
  onDelete: (success: boolean) => void;
}) => {
  const t = useTranslations();

  return (
    <BulkDeleteModal
      isOpen={isOpen}
      onClose={onClose}
      onDelete={onDelete}
      labels={{
        single: t("AlarmReceptions:alarm_ip_list_type"),
        multi: t("AlarmReceptions:alarm_ip_list_type_multi"),
      }}
      ids={filters as any}
      apiFunc={(filter: any, authRequest) =>
        deleteIpAddress(filter.belongsToId, filter.id, authRequest)
      }
    />
  );
};
