import { useEffect } from "react";
import he from "he";
import { useHistory, useLocation } from "react-router-dom";
import { CommonContactResponse } from "../../core/api/contacts/types";
import useTranslations from "../../core/i18n/useTranslations";
import Table from "../../ui-lib/components/Tables/Table";
import TableCell from "../../ui-lib/components/Tables/TableCell";
import LoadingSpinner from "../../ui-lib/components/Loading/LoadingSpinner";
import NoItems from "../../ui-lib/components/Tables/NoItems";

const columns = (t: (key: string) => string) => [
  {
    key: "name",
    header: t("Common:name"),
    fieldTemplate: (rowData: CommonContactResponse) => (
      <TableCell
        value={rowData.name}
        linkTo={`/adminportal/common-contacts/${rowData.id}`}
      />
    ),
  },
  {
    key: "telephone1",
    header: t("Administrator:telephone"),
    fieldTemplate: (rowData: CommonContactResponse) => (
      <TableCell value={rowData.telephone1} />
    ),
  },
  {
    key: "address",
    header: t("Common:label_address"),
    fieldTemplate: (rowData: CommonContactResponse) => (
      <TableCell value={rowData.address} />
    ),
  },
  {
    key: "description",
    header: t("Contacts:table_column_description"),
    fieldTemplate: (rowData: CommonContactResponse) => (
      <TableCell value={he.decode(rowData.description || "")} />
    ),
  },
  {
    key: "organisation",
    header: t("Contacts:table_column_organisation"),
    fieldTemplate: (rowData: CommonContactResponse) => (
      <TableCell
        value={rowData.organization}
        linkTo={`/adminportal/organizations/${rowData.organizationId}`}
      />
    ),
  },
  {
    key: "contact-type",
    header: t("Contacts:table_column_contact_type"),
    fieldTemplate: (rowData: CommonContactResponse) => (
      <TableCell
        value={
          rowData.isResource
            ? t("Contacts:input_resource")
            : t("Common:contact_person")
        }
      />
    ),
  },
  {
    key: "availability",
    header: t("Contacts:table_column_availability"),
    fieldTemplate: (rowData: CommonContactResponse) => (
      <TableCell
        value={t(
          `Contacts:input_display_in_central_${
            rowData.isResource ? "resource" : rowData.globalType
          }`
        )}
      />
    ),
  },
];

export const CommonContactsTable = ({
  data = [],
  isLoading,
  pageSettings,
  onPageSettingsChange,
  onStartDelete,
  isFiltersApplied,
  onClear,
  totalItems,
}: {
  data?: CommonContactResponse[];
  isLoading: boolean;
  pageSettings: {
    page: number;
    pageSize: number;
  };
  onPageSettingsChange: (args: { page: number; pageSize: number }) => void;
  onStartDelete: (contact: CommonContactResponse) => void;
  isFiltersApplied: boolean;
  onClear: () => void;
  totalItems: number;
}) => {
  const t = useTranslations();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    sessionStorage.setItem(
      "prevPath",
      JSON.stringify({
        path: location.pathname,
        pathName: t("Menu:commoncontacts"),
      })
    );
  }, []);

  if (isLoading) {
    return <LoadingSpinner theme="primary" />;
  }

  return (
    <>
      {data.length > 0 ? (
        <Table<CommonContactResponse>
          rowActionsFixed
          showRowsActionsOnHover
          showRowHover
          withLazyLoading
          withPagination
          hideEmptyMessage
          tableName="table-commonContacts"
          items={data}
          isLoading={isLoading}
          rowActionsColWidth={64}
          columns={columns(t)}
          rowActions={[
            {
              icon: "pencil-alt",
              text: t("Common:label_edit"),
              onClick: (rowData) =>
                history.push(`/adminportal/common-contacts/${rowData.id}`),
            },
            {
              icon: "trash",
              text: t("Common:delete"),
              iconVariant: "secondary",
              onClick: (rowData) => onStartDelete(rowData),
            },
          ]}
          paginatedItems={{
            items: data,
            pagination: {
              offset: pageSettings.pageSize * (pageSettings.page - 1),
              limit: pageSettings.pageSize,
              total: totalItems,
            },
          }}
          onPageChange={(nextPage) => {
            const page = Math.floor(nextPage.offset / nextPage.limit);

            if (!Number.isNaN(page) && nextPage.limit) {
              onPageSettingsChange({
                page: page + 1,
                pageSize: nextPage.limit,
              });
            }
          }}
        />
      ) : (
        <NoItems
          title={
            !isFiltersApplied
              ? t("Table:noresult_title")
              : t("Table:no_results_found")
          }
          icon={!isFiltersApplied ? "eye-off" : undefined}
          subTitle={
            !isFiltersApplied
              ? t("Table:noresult_subtitle")
              : t("Table:adjust_filter_description")
          }
          clear={
            isFiltersApplied && onClear
              ? {
                  text: t("Table:clear_filters"),
                  onClick: onClear,
                }
              : undefined
          }
        />
      )}
    </>
  );
};
