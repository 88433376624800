import { AxiosInstance } from "axios";
import queryString from "query-string";
import API_HOST from "../apiHost";
import useAuthenticatedFetch from "../useAuthenticatedFetch";
import {
  AdministratorResponse,
  AdministratorDetails,
  Timezone,
  DateTimeFormat,
  AvailableRoles,
  PasswordConstraints,
  AdministratorView,
  IUserSuggest,
  IAdminCopy,
  IAdminTemplate,
  ISpecialSettings,
  SupportData,
} from "./types";

import { Transmitter } from "../transmitters/types";

const getSupportInfo = (authenticatedRequest: AxiosInstance, brand: string) =>
  authenticatedRequest.get<SupportData>(
    `${API_HOST}api/v2/supportdetails?brand=${brand}`
  );

const getLegacyAdmins = (
  authenticatedRequest: AxiosInstance,
  params: {
    page?: number;
    pageSize?: number;
    q?: string;
    customer?: number;
  }
) => {
  const settings = queryString.stringify(params);

  return authenticatedRequest.get<AdministratorResponse[]>(
    `${API_HOST}api/v2/admins?${settings}`
  );
};

const getAdminRoles = (
  params: {
    page?: number;
    pageSize?: number;
    q?: string;
    organization?: number;
    responsecenter?: string | number;
  },
  authenticatedRequest: AxiosInstance
) => {
  const settings = queryString.stringify(params);

  return authenticatedRequest.get<AdministratorView>(
    `${API_HOST}api/v2/roles?${settings}`
  );
};

const getAdministrators = (params: {
  page?: number;
  pageSize?: number;
  q?: string;
  organization?: string;
  adminType?: string;
  seller?: string;
  responsecenter?: string;
}) => {
  const settings = queryString.stringify(params);

  return useAuthenticatedFetch<AdministratorView>(
    `${API_HOST}api/v2/roles?${settings}`
  );
};

const useAdministrator = (id: string | number) =>
  useAuthenticatedFetch<AdministratorDetails>(`${API_HOST}api/v2/admins/${id}`);

const getAdministrator = async (
  id: string | number,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.get<AdministratorDetails>(
    `${API_HOST}api/v2/admins/${id}`
  );

const updateAdministrator = async (
  id: string | number,
  adminDetails: AdministratorDetails,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.put<{}>(
    `${API_HOST}api/v2/admins/${id}`,
    JSON.stringify(adminDetails),
    {
      headers: { "Content-Type": "application/json" },
    }
  );

const createAdministrator = async (
  adminDetails: AdministratorDetails,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.post<number>(
    `${API_HOST}api/v2/admins`,
    JSON.stringify(adminDetails),
    {
      headers: { "Content-Type": "application/json" },
    }
  );

const deleteAdministrator = async (
  id: string | number,
  authenticatedRequest: AxiosInstance
) => authenticatedRequest.delete<{}>(`${API_HOST}api/v2/admins/${id}`);

const deleteIdentity = async (
  id: string | number,
  authenticatedRequest: AxiosInstance
) => authenticatedRequest.delete<{}>(`${API_HOST}api/v2/identities/${id}`);

const updateAdministratorPassword = async (
  id: string | number,
  currentPassword: string,
  newPassword: string,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.post<{}>(
    `${API_HOST}api/v2/admins/${id}/password`,
    JSON.stringify({ currentPassword, password: newPassword }),
    {
      headers: { "Content-Type": "application/json" },
    }
  );

const update2FA = (
  id: string | number,
  type: string,
  telephoneNumber: string,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.post(
    `${API_HOST}api/v2/admins/${id}/2fa`,
    JSON.stringify({
      twoFactorType: type,
      telephonenumber: telephoneNumber,
    }),
    {
      headers: { "Content-Type": "application/json" },
    }
  );

const getTimezones = (authenticatedRequest: AxiosInstance) =>
  authenticatedRequest.get(`${API_HOST}api/v2/timezones`);

const useTimezones = () =>
  useAuthenticatedFetch<Timezone[]>(`${API_HOST}api/v2/timezones`);

const useDateTimeFormats = () =>
  useAuthenticatedFetch<DateTimeFormat[]>(
    `${API_HOST}api/v2/admins/datetimeformats`
  );

const useAvailableRoles = (id: string | number) =>
  useAuthenticatedFetch<AvailableRoles>(
    `${API_HOST}api/v2/admins/${id}/availablepermissions`
  );

const getAvailableRoles = (
  id: string | number,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.get<AvailableRoles>(
    `${API_HOST}api/v2/admins/${id}/availablepermissions`,
    {
      headers: { "Content-Type": "application/json" },
    }
  );

const getPasswordConstraints = (
  id: string | number,
  authenticatedRequest: AxiosInstance,
  dataType: string
) =>
  authenticatedRequest.get<PasswordConstraints>(
    `${API_HOST}api/v2/password/complexity?${dataType}=${id}`,
    {
      headers: { "Content-Type": "application/json" },
    }
  );

const getAdminPasswordConstraints = (
  id: string | number,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.get<PasswordConstraints>(
    `${API_HOST}api/v2/admins/${id}/passwordcomplexity`,
    {
      headers: { "Content-Type": "application/json" },
    }
  );

const getTransmitters = (
  id: string | number,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.get<Transmitter[]>(
    `${API_HOST}api/v2/admins/${id}/transmitters`,
    {
      headers: { "Content-Type": "application/json" },
    }
  );

const getUserSuggest = (
  id: string | number,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.get<IUserSuggest>(
    `${API_HOST}api/v2/admins/${id}/usersuggest`,
    {
      headers: { "Content-Type": "application/json" },
    }
  );

const requestDeviceLog = async (
  adminId: string,
  authenticatedRequest: AxiosInstance
) => {
  const result = await authenticatedRequest.post(
    `${API_HOST}api/v2/admins/${adminId}/requestlog`
  );
  return result;
};

const createCopyByAdmin = async (
  id: number,
  data: IAdminCopy,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.post<number>(
    `${API_HOST}api/v2/admins/${id}/copy`,
    data
  );

const getAdminTemplates = async (
  id: string | number,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.get<IAdminTemplate[]>(
    `${API_HOST}api/v2/admins/${id}/templates`,
    {
      headers: { "Content-Type": "application/json" },
    }
  );

const updateAdminTemplate = async (
  adminId: string | number,
  templateId: string | number,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.post<any>(
    `${API_HOST}api/v2/admins/${adminId}/templates`,
    JSON.stringify({ templateId }),
    {
      headers: { "Content-Type": "application/json" },
    }
  );

const impersonateAlarmReception = (
  adminId: number,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.post<any>(
    `${API_HOST}api/admins/${adminId}/impersonatewebrespondent`,
    undefined,
    {
      headers: { "Content-Type": "application/json" },
    }
  );

const impersonate = (adminId: number, authenticatedRequest: AxiosInstance) =>
  authenticatedRequest.post<any>(
    `${API_HOST}api/v2/admins/${adminId}/impersonate`,
    undefined,
    {
      headers: { "Content-Type": "application/json" },
    }
  );

const unImpersonate = (authenticatedRequest: AxiosInstance) =>
  authenticatedRequest.post<any>(
    `${API_HOST}api/v2/admins/unimpersonate`,
    undefined,
    {
      headers: { "Content-Type": "application/json" },
    }
  );

const makeOperator = (
  adminId: string | number,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.post(`${API_HOST}api/v2/admins/${adminId}/makeoperator`);

const makeCentralAdmin = (
  adminId: string | number,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.post(
    `${API_HOST}api/v2/admins/${adminId}/makecenteradmin`
  );

const getAdminSettings = (id: number, authenticatedRequest: AxiosInstance) =>
  authenticatedRequest.get<ISpecialSettings[]>(
    `${API_HOST}api/v2/admins/${id}/settings`
  );

const createAdminSetting = (
  adminId: number,
  data: ISpecialSettings,
  authenticatedRequest: AxiosInstance
) => {
  const string = queryString.stringify(data);
  return authenticatedRequest.post(
    `${API_HOST}api/v2/admins/${adminId}/settings?${string}`,
    undefined,
    {
      headers: { "Content-Type": "application/json" },
    }
  );
};

const editAdminSetting = (
  adminId: number,
  settingId: number,
  data: ISpecialSettings,
  authenticatedRequest: AxiosInstance
) => {
  const string = queryString.stringify(data);
  return authenticatedRequest.put(
    `${API_HOST}api/v2/admins/${adminId}/settings/${settingId}?${string}`,
    undefined,
    {
      headers: { "Content-Type": "application/json" },
    }
  );
};

const deleteAdminSetting = (
  adminId: number,
  settingId: number,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.delete(
    `${API_HOST}api/v2/admins/${adminId}/settings/${settingId}`
  );

export {
  getSupportInfo,
  getLegacyAdmins,
  getAdministrators,
  getAdminRoles,
  useAdministrator,
  createAdministrator,
  getAdministrator,
  getTransmitters,
  updateAdministrator,
  deleteAdministrator,
  deleteIdentity,
  updateAdministratorPassword,
  useTimezones,
  useDateTimeFormats,
  useAvailableRoles,
  getAvailableRoles,
  getPasswordConstraints,
  update2FA,
  getUserSuggest,
  requestDeviceLog,
  createCopyByAdmin,
  getAdminTemplates,
  updateAdminTemplate,
  impersonateAlarmReception,
  impersonate,
  unImpersonate,
  makeOperator,
  makeCentralAdmin,
  getAdminSettings,
  createAdminSetting,
  editAdminSetting,
  deleteAdminSetting,
  getTimezones,
  getAdminPasswordConstraints,
};
