import { useEffect, useState } from "react";
import { Row, Spacer } from "../Layout/Layout";
import useTranslations from "../../core/i18n/useTranslations";
import Checkbox from "../../ui-lib/components/Checkbox/Checkbox";
import Button from "../../ui-lib/components/Button/Button";
import PrimeModal from "../../ui-lib/components/PrimeModal/PrimeModal";
import Icon from "../../ui-lib/components/Icon";

import styles from "./WelcomeModal.module.css";

export const WelcomeModal = () => {
  const t = useTranslations();
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [dontShow, setDontShow] = useState<boolean>(false);

  useEffect(() => {
    if (dontShow) {
      localStorage.setItem("welcomeModal", "hide");
    }
  }, [dontShow]);

  const handleCloseModal = () => {
    sessionStorage.setItem("welcomeModalSession", "hide");
    setIsOpen(false);
  };

  return (
    <PrimeModal
      withHeader
      header={t("Common:welcome_to_admin")}
      isOpen={isOpen}
      onClose={handleCloseModal}
      className={styles.welcomeContainer}
      customFooter={
        <div className={styles.welcomeFooter}>
          <Row align="center">
            <Button
              variant="primary"
              text={t("Home:lets_start")}
              onClick={handleCloseModal}
            />
          </Row>
          <Spacer size={16} />
          <div>
            <p className={styles.footerText}>
              {t("Common:go_to_previous_version")}
            </p>
          </div>
        </div>
      }
    >
      <div className={styles.welcomeModalBody}>
        <p className={styles.welcomeDescription}>
          {t("Common:welcome_to_admin_description")}
        </p>
        <Spacer size={32} />
        <div className={styles.welcomeNavigation}>
          <Icon name="compass" size={24} />
          <Spacer size={8} />
          <div>
            <h3>{t("Common:better_navigation")}</h3>
            <p>{t("Common:new_navigation")}</p>
          </div>
        </div>
        <Spacer size={16} />
        <div className={styles.welcomeStructure}>
          <Icon name="structure" size={24} />
          <Spacer size={8} />
          <div>
            <h3>{t("Common:structured_information")}</h3>
            <p>{t("Common:all_related_system")}</p>
          </div>
        </div>
        <Spacer size={16} />
        <div className={styles.welcomeStatistic}>
          <Icon name="chart-bar" size={24} />
          <Spacer size={8} />
          <div>
            <h3>{t("Common:new_statistic")}</h3>
            <p>{t("Common:chart_to_view_activity")}</p>
          </div>
        </div>
        <Spacer size={24} />
        <Checkbox
          label={t("Common:do_not_show_again")}
          checked={dontShow}
          onChange={() => {
            setDontShow(!dontShow);
          }}
          innerStyle={{ justifyContent: "center" }}
        />
      </div>
    </PrimeModal>
  );
};
