import { IStatusEvent } from "../../../core/api/objects/types";
import useTranslations from "../../../core/i18n/useTranslations";
import Table from "../../../ui-lib/components/Tables/Table";
import TableCell from "../../../ui-lib/components/Tables/TableCell";
import moment from "moment";
import useUser from "../../../core/user/useUser";
import Badge, { BadgeProps } from "../../../ui-lib/components/Badges/Badge";
import { errorStatuses } from "./PlanningOverview/PlanningOverview";
import { Spacer } from "../../../components/Layout/Layout";
import Divider from "../../../ui-lib/components/Divider/Divider";
import { GetConfigResponse } from "../../../core/api/config";
import { TFunction } from "i18next";

const columns = (
  t: TFunction<"translation", undefined>,
  config: GetConfigResponse
) => [
  {
    key: "created_when",
    header: t("Common:when"),
    fieldTemplate: (rowData: IStatusEvent) => (
      <TableCell
        value={moment(rowData.timestamp).format(`${config?.dateFormat} HH:mm`)}
      />
    ),
  },
  {
    key: "cerated_by",
    header: t("Common:created_by"),
    fieldTemplate: (rowData: IStatusEvent) => (
      <TableCell value={rowData.admin} />
    ),
  },
  {
    key: "status",
    header: t("Common:status"),
    fieldTemplate: (rowData: IStatusEvent) => {
      let statusVariant = "success";
      if (errorStatuses.includes(rowData.status)) statusVariant = "error";
      if (
        !errorStatuses.includes(rowData.status) &&
        rowData.status !== "Complete"
      )
        statusVariant = "info";
      return (
        <Badge
          withHighlightedText
          variant={statusVariant as BadgeProps["variant"]}
          title={t(`Objects:${rowData.status}`)}
        />
      );
    },
  },
];

export const CurrentActivityHistory = ({
  statuses = [],
}: {
  statuses: IStatusEvent[];
}) => {
  const t = useTranslations();
  const { config } = useUser();

  return (
    <>
      <Spacer size={8} />
      <Divider />
      <Spacer size={16} />
      <h2>{t("Objects:history_of_current_activity")}</h2>
      <Spacer size={8} />
      <Table<IStatusEvent> columns={columns(t, config!)} items={statuses} />
    </>
  );
};
