import { ChangeEventHandler, CSSProperties } from "react";
import classNames from "classnames";
import styles from "./Radio.module.css";
import Icon from "../Icon";

export interface IRadioProps {
  inputId?: string;
  name: string;
  value: string | number;
  checked?: boolean;
  text?: string;
  image?: string;
  description?: string;
  disabled?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  idForText?: number | string;
  style?: CSSProperties;
  innerStyle?: CSSProperties;
}

export const Radio = ({
  inputId,
  name,
  value,
  checked,
  text,
  image,
  description = "",
  disabled = false,
  onChange,
  idForText,
  style,
  innerStyle,
}: IRadioProps) => (
  <div className={styles.radioContainer} style={{ ...innerStyle }}>
    <input
      type="radio"
      id={(idForText ?? inputId) as string}
      className={styles.radio}
      name={name}
      value={value}
      checked={checked}
      disabled={disabled}
      onChange={onChange}
      style={style}
    />
    {image ? (
      <label
        htmlFor={(idForText ?? inputId) as string}
        className={styles.radioIcon}
      >
        <Icon name={image} size={24} color={disabled ? "Grey-300" : "grey"} />
      </label>
    ) : (
      <span />
    )}
    {text ? (
      <label
        htmlFor={(idForText ?? inputId) as string}
        className={styles.radioText}
      >
        {text}
      </label>
    ) : (
      <span className={styles.radioText} />
    )}
    {description ? (
      <label
        htmlFor={inputId}
        className={classNames("description", styles.radioDescription)}
      >
        {description}
      </label>
    ) : (
      <span />
    )}
  </div>
);

export default Radio;
