import { useEffect, useState } from "react";
import {
  APIProvider,
  Map,
  MapCameraProps,
  Marker,
} from "@vis.gl/react-google-maps";

import modulestyles from "./GoogleMap.module.css";
import MarkerUrl from "../../ui-lib/assets/icons/marker.svg";

const mapOptions = {
  styles: [
    {
      elementType: "geometry",
      stylers: [
        {
          color: "#f5f5f5",
        },
      ],
    },
    {
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#616161",
        },
      ],
    },
    {
      elementType: "labels.text.stroke",
      stylers: [
        {
          color: "#f5f5f5",
        },
      ],
    },
    {
      featureType: "administrative.land_parcel",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#bdbdbd",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "geometry",
      stylers: [
        {
          color: "#eeeeee",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#757575",
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [
        {
          color: "#e5e5e5",
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#9e9e9e",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [
        {
          color: "#ffffff",
        },
      ],
    },
    {
      featureType: "road.arterial",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#757575",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [
        {
          color: "#dadada",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#616161",
        },
      ],
    },
    {
      featureType: "road.local",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#9e9e9e",
        },
      ],
    },
    {
      featureType: "transit.line",
      elementType: "geometry",
      stylers: [
        {
          color: "#e5e5e5",
        },
      ],
    },
    {
      featureType: "transit.station",
      elementType: "geometry",
      stylers: [
        {
          color: "#eeeeee",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [
        {
          color: "#c9c9c9",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#cfe5fc",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#9e9e9e",
        },
      ],
    },
  ],
  center: {
    lat: 56.0589736,
    lng: 10.7680202,
  },
  zoom: 5,
};

export const GoogleMap = ({
  onSetPosition,
  position,
}: {
  onSetPosition: (position: google.maps.LatLngLiteral | null) => void;
  position: google.maps.LatLngLiteral | null;
}) => {
  const [cameraProps, setCameraProps] = useState<MapCameraProps>({
    center: position ?? mapOptions.center,
    zoom: mapOptions.zoom,
  });

  useEffect(() => {
    if (position) {
      setCameraProps({ ...cameraProps, center: position });
    }
  }, [position]);

  return (
    <div className={modulestyles.panelContainer}>
      <APIProvider apiKey="AIzaSyAOhQYmEXHT3jNB8dOHsKT1W26B5llY_UI">
        <Map
          disableDefaultUI
          fullscreenControl
          zoomControl
          {...cameraProps}
          onCameraChanged={(ev) => setCameraProps(ev.detail)}
          styles={mapOptions.styles}
          onClick={(e) => {
            onSetPosition(e.detail.latLng);
          }}
        >
          <Marker position={position} icon={MarkerUrl} clickable={false} />
        </Map>
      </APIProvider>
    </div>
  );
};
