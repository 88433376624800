import { Calendar } from "../../../../../core/api/responsecenters/types";
import useTranslations from "../../../../../core/i18n/useTranslations";
import Dropdown from "../../../../../ui-lib/components/Dropdown/Dropdown";

export const RepeatControl = ({
  calendars,
  selected,
  onSelect,
}: {
  calendars: Calendar[];
  selected?: number;
  onSelect: (id: number) => void;
}) => {
  const t = useTranslations();

  const dropdownItems = calendars.map((calendar) => ({
    id: calendar.id,
    name: t(`AlarmReceptions:alarm_handling_rule_calendar_${calendar.id}`),
    isSelected: selected === calendar.id,
  }));

  return (
    <Dropdown
      width="100%"
      selectedItem={dropdownItems.find((i) => i.isSelected)}
      items={dropdownItems}
      onSelectItem={(item) => onSelect(item.id as number)}
    />
  );
};
