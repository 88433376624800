import { AxiosInstance } from "axios";
import API_HOST from "./apiHost";

interface AdminParams {
  id: number;
  token?: string;
}

interface AdminResponse {
  Type: string;
  Name?: string;
  Username: string;
  Roles: string[];
  Seller: string;
  Telephone?: string;
  TwoFactorAuthenticationType?: string;
  TwoFactorAuthenticationData?: "none" | "sms" | "sms_seperate" | "google";
  PrimaryLanguage: string;
  SecondaryLanguage?: string;
}

const api = (service: AxiosInstance) => ({
  getAdmin: async ({ id, token }: AdminParams) => {
    const response = await service.get<AdminResponse>(
      `${API_HOST}api/v2/admins/${id}`,
      token
        ? {
            headers: { Authorization: `Bearer ${token}` },
          }
        : {}
    );

    return response?.data;
  },
});

export default api;
