import { useMemo } from "react";
import useTranslations from "../../../core/i18n/useTranslations";
import RadioGroup from "../Radio/RadioGroup";
import { Filter } from "./CustomFilters";

const linkedOptions = [
  { id: 0, value: undefined }, // All
  { id: 1, value: "True" }, // Linked to respondents
  { id: 2, value: "False" }, // Not linked to respondents
];

export const CustomFilterLinkedObjects = ({
  item,
  onChange,
}: {
  item: Filter;
  onChange: (item: Filter) => void;
}) => {
  const t = useTranslations();

  const radioItems = useMemo(
    () =>
      linkedOptions.map((o) => ({
        id: o.id,
        value: `linked-objects-${o.id}`,
        text: t(`Objects:linked_objects_${o.id}`),
      })),
    []
  );

  const selectedLinkedOption = linkedOptions.find(
    (o) => o.value === item.value
  );
  const selectedRadioItem = radioItems.find(
    (i) => i.id === selectedLinkedOption?.id
  );

  return (
    <RadioGroup
      label={t("Objects:linked_objects")}
      name="linked-objects"
      items={radioItems}
      selectedValue={selectedRadioItem?.value}
      onChange={(value) => {
        const radioItem = radioItems.find((i) => i.value === value);
        const linkedOption = linkedOptions.find((o) => o.id === radioItem?.id);

        onChange({
          ...item,
          value: linkedOption?.value,
        });
      }}
    />
  );
};
