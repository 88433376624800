import useTranslations from "../../../core/i18n/useTranslations";
import BulkDeleteModal from "../../../components/BulkDeleteModal";
import { deleteAlarmReceptionIntegration } from "../../../core/api/responsecenters/responsecenters";

export const DeleteReceptionIntegrationModal = ({
  isOpen,
  ids,
  onClose,
  onDelete,
  objectId,
}: {
  isOpen: boolean;
  objectId: number;
  ids: number[];
  onClose: () => void;
  onDelete: (success: boolean) => void;
}) => {
  const t = useTranslations();

  return (
    <BulkDeleteModal
      isOpen={isOpen}
      onClose={onClose}
      onDelete={onDelete}
      ids={ids.map((id) => id.toString())}
      labels={{
        single: t("AlarmReceptions:alarm_reception_integration_type"),
        multi: t("AlarmReceptions:alarm_reception_integration_type_multi"),
      }}
      apiFunc={(id, authReq) =>
        deleteAlarmReceptionIntegration(objectId, id, authReq)
      }
    />
  );
};
