/* eslint-disable react/jsx-props-no-spreading */
import { Controller, FormProvider, useForm } from "react-hook-form";
import { Column, Row, Spacer } from "../../../components/Layout/Layout";
import { NewResponseCenterParams } from "../../../core/api/responsecenters/types";
import useTranslations from "../../../core/i18n/useTranslations";
import Tree, { ITreeData } from "../../../ui-lib/components/Hierarchy/Tree";
import TextInput from "../../../ui-lib/components/Inputs/TextInput";
import { CopySettings } from "./CopySettings";
import PrimeModal from "../../../ui-lib/components/PrimeModal/PrimeModal";

export const CreateAlarmReceptionForm = ({
  isOpen,
  labels,
  withCopySettings,
  defaultValues,
  organisations,
  onSubmit,
  onClose,
  disabled,
}: {
  isOpen: boolean;
  labels: {
    title: string;
    description?: string;
    submit: string;
  };
  withCopySettings?: boolean;
  defaultValues?: Omit<NewResponseCenterParams, "name" | "subscriptionPeriod">;
  organisations: ITreeData[];
  onSubmit: (values: NewResponseCenterParams) => Promise<void>;
  onClose: () => void;
  disabled?: boolean;
}) => {
  const t = useTranslations();

  const methods = useForm<NewResponseCenterParams>({ defaultValues });
  const {
    control,
    formState: { isSubmitting, errors },
    setValue,
    trigger,
    handleSubmit,
  } = methods;

  return (
    <PrimeModal
      withFooter
      withHeader
      onClose={onClose}
      isOpen={isOpen}
      contentStyle={{ width: "848px" }}
      header={labels.title}
      loading={isSubmitting}
      submitBtn={{
        text: labels.submit,
        onClick: handleSubmit(async (formValues) => {
          await onSubmit(formValues);
        }),
      }}
      cancelBtn={{ text: t("Common:cancel"), onClick: onClose }}
    >
      {labels.description && (
        <p style={{ color: "var(--Grey-600)" }}>{labels.description}</p>
      )}

      <Spacer size={16} />

      <FormProvider {...methods}>
        <form>
          <Row align="start">
            <Column style={{ width: "100%" }}>
              <Controller
                name="name"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextInput
                    required
                    label={t("Common:name")}
                    description={t("AlarmReceptions:input_name_description")}
                    placeholder={t("AlarmReceptions:input_name_placeholder")}
                    value={field.value}
                    onChange={field.onChange}
                    validationError={
                      errors.name && t("Errors:input_field_required")
                    }
                  />
                )}
              />
            </Column>
            <Spacer size={32} />
            <Column style={{ width: "100%" }}>
              <Controller
                name="organizationId"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <Tree
                    required
                    label={t("Common:belongs_to")}
                    placeholder={t("Common:organisation")}
                    items={organisations}
                    selectedTreeItem={field.value}
                    onSelectItem={(data?: ITreeData) => {
                      const customerId = data?.key as number | undefined;
                      setValue(field.name, customerId);
                      trigger(field.name);
                    }}
                    disabled={disabled}
                    showClearOption={false}
                    validationError={
                      errors.organizationId && t("Errors:input_field_required")
                    }
                  />
                )}
              />
            </Column>
          </Row>

          <Spacer size={16} />

          {withCopySettings && <CopySettings />}

          <Spacer size={32} />
        </form>
      </FormProvider>
    </PrimeModal>
  );
};
