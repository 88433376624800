import BulkDeleteModal from "../../components/BulkDeleteModal";
import { api } from "../../core/api/reports/reports";
import useTranslations from "../../core/i18n/useTranslations";

export const DeleteReportsModal = ({
  ids,
  isOpen,
  onClose,
  onDelete,
}: {
  ids: number[];
  isOpen: boolean;
  onClose: () => void;
  onDelete: (status: boolean) => void;
}) => {
  const t = useTranslations();

  return (
    <BulkDeleteModal
      isOpen={isOpen}
      onClose={onClose}
      onDelete={onDelete}
      labels={{
        single: t("Reports:type"),
        multi: t("Reports:type_multi"),
      }}
      ids={ids.map((id) => id.toString())}
      apiFunc={(id, authenticatedRequest) =>
        api(authenticatedRequest).deletePeriodicReport({ reportId: id })
      }
    />
  );
};
