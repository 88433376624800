import DeleteModal from "../../../components/DeleteModal/DeleteModal";
import {
  deleteAdministrator,
  deleteIdentity,
} from "../../../core/api/administrators/administrators";
import { deleteRoleTemplate } from "../../../core/api/roleTemplates/roleTemplates";

export const DeleteAdministratorModal = ({
  administratorId,
  identity,
  roleTemplate,
  onClose,
  onDelete,
}: {
  administratorId?: number | string;
  identity?: boolean;
  roleTemplate?: boolean;
  onClose: () => void;
  onDelete: () => void;
}) => {
  if (!administratorId) {
    return null;
  }

  return (
    <DeleteModal
      locizeKey="administrator"
      callDelete={(authReq) => {
        if (identity) {
          return deleteIdentity(administratorId, authReq);
        }
        if (roleTemplate) {
          return deleteRoleTemplate(administratorId, authReq);
        }
        return deleteAdministrator(administratorId, authReq);
      }}
      onClose={onClose}
      onDelete={onDelete}
    />
  );
};
