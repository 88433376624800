import { AxiosInstance, AxiosResponse } from "axios";
import { useEffect, useRef, useState } from "react";
import { getCamerasInStock } from "../../core/api/cameras/cameras";
import { Camera } from "../../core/api/cameras/types";
import { notifyApiErrors } from "../../core/helpers/helpers";
import useTranslations from "../../core/i18n/useTranslations";
import { ApiResponse } from "../../core/interfaces/Api";
import useUser from "../../core/user/useUser";
import Button from "../../ui-lib/components/Button/Button";
import Checkbox from "../../ui-lib/components/Checkbox/Checkbox";
import SearchInput from "../../ui-lib/components/Inputs/SearchInput";
import StyledModal from "../../ui-lib/components/Modal/Modal";
import Table from "../../ui-lib/components/Tables/Table";
import TableCell from "../../ui-lib/components/Tables/TableCell";
import { Column, Spacer } from "../Layout/Layout";
import { CreateCameraModal } from "../Cameras/CreateCameraModal";
import { ITreeData } from "../../ui-lib/components/Hierarchy/Tree";
import { getErrorKey } from "../Errors/ErrorAlert";
import { notify } from "../../ui-lib/components/Alerts/Toast";

export const AddCameraModal = ({
  onSubmit,
  onClose,
  id,
  alreadySelectedCameras,
  apiSaveMethod,
  addOnSelect,
  showAddCamera,
  organisationTree,
  fromObjects = true,
}: {
  onSubmit?: () => void;
  onClose?: () => void;
  id?: string;
  alreadySelectedCameras: number[];
  apiSaveMethod(
    id: string,
    cameraId: number | undefined,
    authenticatedRequest: AxiosInstance
  ): Promise<AxiosResponse<ApiResponse<any>>>;
  addOnSelect?: boolean;
  showAddCamera?: boolean;
  organisationTree?: ITreeData[];
  fromObjects?: boolean;
}) => {
  const t = useTranslations();
  const { authenticatedRequest } = useUser();
  const [isAddCameraOpen, setIsAddCameraOpen] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const active = useRef(false);
  const [cameras, setCameras] = useState<Camera[]>();
  const search = useRef("");
  const [selectedCameraId, setSelectedCameraId] = useState<
    number | undefined
  >();
  const closeModal = () => {
    setIsAddCameraOpen(false);
    setIsSubmitting(false);
    setSelectedCameraId(undefined);
    onClose?.();
  };

  async function loadData() {
    try {
      const query = {
        q: search.current || "",
        order: "title",
        page: 1,
        pageSize: 200,
      };
      const result = await getCamerasInStock(query, authenticatedRequest);
      setCameras(result.cameras);
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    } finally {
      setIsSearching(false);
    }
  }
  const onSave = async (directIdToSave?: number) => {
    setIsSubmitting(true);
    if (
      (id !== undefined && selectedCameraId !== undefined) ||
      (id !== undefined && directIdToSave)
    ) {
      try {
        await apiSaveMethod(
          id,
          selectedCameraId || directIdToSave,
          authenticatedRequest
        );
        onSubmit?.();
        closeModal();
      } catch (error: any) {
        const errorKey = getErrorKey(error);
        notify({ message: t(`Errors:${errorKey}`), variant: "error" });
      }
    }
    setIsSubmitting(false);
  };

  useEffect(() => {
    active.current = true;
    loadData();
    return () => {
      active.current = false;
    };
  }, []);
  return (
    <>
      <Button
        customStyles={{ marginLeft: "auto" }}
        type="button"
        variant="secondary"
        image="video-camera"
        text={t("Objects:accessories_add_camera")}
        onClick={() => {
          setIsAddCameraOpen(true);
        }}
      />
      <StyledModal
        onClose={closeModal}
        isOpen={isAddCameraOpen}
        approveBtnText={
          addOnSelect ? undefined : t("Objects:accessories_add_camera_save")
        }
        cancelBtnText={t("Common:label_back")}
        onCancelBtnClick={closeModal}
        modalTitle={t("Objects:accessories_add_camera_title")}
        onApproveBtnClick={() => {
          onSave();
        }}
        closeOnDocumentClick={false}
        css={{ maxWidth: 1000 }}
        approveEnabled={selectedCameraId !== undefined}
        isLoading={isSubmitting}
      >
        <Column type="top" align="start">
          <p>
            {fromObjects
              ? t("Objects:accessories_add_camera_description")
              : t("Locations:accessories_add_camera_description")}
          </p>
          <Spacer size={16} />
          <SearchInput
            limit={1}
            placeholder={t("Objects:accessories_add_camera_search_placeholder")}
            value={search.current ?? ""}
            onChange={(value) => {
              search.current = value;
              setIsSearching(true);
              loadData();
            }}
          />
          <Spacer size={16} />
          {showAddCamera && organisationTree ? (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <h2
                style={{
                  margin: 0,
                  marginTop: "auto",
                }}
              >
                {t("Cameras:cameras_in_stock")}
              </h2>
              <CreateCameraModal
                onSubmit={() => loadData()}
                organisationTree={organisationTree}
                modalButtonTitle={t("Cameras:add_new_camera")}
                modalTitle={t("Cameras:add_camera_modal_title")}
                saveButtonTitle={t("Cameras:add_camera_modal_save")}
                variant="secondary"
                fromStock
              />
            </div>
          ) : null}
          <Spacer size={16} />
          <Table<Camera>
            columns={[
              {
                header: t("Common:name"),
                fieldTemplate: (rowData) => <TableCell value={rowData.title} />,
              },
              {
                header: t("Cameras:table_columns_description"),
                fieldTemplate: (rowData) => (
                  <TableCell value={rowData.description} />
                ),
              },
              {
                header: t("Cameras:table_columns_organizationName"),
                fieldTemplate: (rowData) => (
                  <TableCell
                    value={rowData.organizationName}
                    iconColor="Grey-300"
                    leftIcon="office-building"
                  />
                ),
              },
              {
                header: t("Cameras:table_columns_cameraType"),
                style: { width: "250px" },
                fieldTemplate: (rowData) => (
                  <TableCell
                    value={t(`Common:CameraType_${rowData.cameraType}`)}
                    iconColor="Grey-300"
                    leftIcon="video-camera"
                  />
                ),
              },
              {
                editable: true,
                style: { width: "40px" },
                fieldTemplate: (rowData) =>
                  addOnSelect ? (
                    <Button
                      image="plus"
                      variant="link"
                      onClick={() => onSave(rowData.id)}
                      disabled={rowData.id === selectedCameraId || isSubmitting}
                      customStyles={{ float: "right" }}
                    />
                  ) : (
                    <Checkbox
                      checked={
                        rowData.id === selectedCameraId ||
                        alreadySelectedCameras.indexOf(rowData.id!) > -1
                      }
                      disabled={
                        alreadySelectedCameras.indexOf(rowData.id!) > -1
                      }
                      label=""
                      innerStyle={{ justifyContent: "flex-end" }}
                      onChange={() => {
                        setSelectedCameraId(
                          selectedCameraId === rowData.id
                            ? undefined
                            : rowData.id
                        );
                      }}
                    />
                  ),
              },
            ]}
            items={cameras}
            hideEmptyMessage
            showRowHover
            noRowsMessage={
              search
                ? t("Objects:accessories_cameras_no_match")
                : t("Objects:accessories_cameras_not_instock")
            }
            isLoading={isSearching}
            withShowMore
          />
        </Column>
      </StyledModal>
    </>
  );
};
