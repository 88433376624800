import { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";
import moment from "moment";
import Icon from "../Icon";
import { Row, Spacer } from "../../../components/Layout/Layout";
import styles from "./PageHeader.module.css";
import { breakpoints } from "../../../core/hooks/dimensionProvider";
import ResponsiveLayout from "../../../components/ResponsiveLayout";
import usePageState from "../../../core/pagestate/usePageState";
import useTranslations from "../../../core/i18n/useTranslations";
import ExternalLink from "../ExternalLink/ExternalLink";
import { getStoredToken } from "../../../core/user/token";
import { useSaveObject } from "../../../core/SaveObjectContext/hooks";
import vector from "../../assets/icons/vector.svg";
import useUser from "../../../core/user/useUser";
import { SwitchVersionsModal } from "../../../components/SwitchVersionsModal/SwitchVersionsModal";

export interface PageHeaderProps {
  title?: any;
  subTitle?: string;
  icon?: string;
  children?: React.ReactNode;
  pageTitle?: boolean;
  userName?: string | undefined;
  status?: string;
}
const PageHeader = ({
  title,
  subTitle,
  icon,
  children,
  pageTitle = false,
  userName,
  status,
}: PageHeaderProps) => {
  const isMounted = useRef(false);
  const t = useTranslations();
  const token = getStoredToken();
  const location = useLocation();
  const { isDirty } = useSaveObject();
  const pageState = usePageState();
  const user = useUser();
  const userWithAccessToLink = user.hasAccess("ShowNewAdminLink");
  const [showModalQuestionnaire, setShowModalQuestionnaire] = useState(false);

  const questionnaireDate = localStorage.getItem("questionnaireDate");
  const today = moment(new Date());
  const url = `/admin/#mw?username=${userName}&expires_in=${token?.expiresIn}&token_type=bearer&access_token=${token?.token}`;

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <>
      <SwitchVersionsModal
        onClose={() => setShowModalQuestionnaire(false)}
        onAfterSubmit={() => {
          setShowModalQuestionnaire(false);
          window.location.href = url;
        }}
        isOpen={showModalQuestionnaire}
      />
      <Row type="fill" className={styles.pageHeader}>
        <ResponsiveLayout
          breakPoint={breakpoints.tablet}
          renderMobile={() => (
            <>
              <Spacer size={10} />
              <Icon
                name="bars"
                size={24}
                color="Grey-500"
                className="clickable"
                onClick={() => {
                  if (isMounted.current)
                    pageState.setShowNavigation(!pageState.showNavigation);
                }}
              />
            </>
          )}
          renderDesktop={() => null}
        />
        {icon && (
          <Icon
            name={icon}
            size={24}
            color="Primary-700"
            className={styles.pageIcon}
          />
        )}
        {title && pageTitle && (
          <h1
            className={styles.pageTitle}
            style={!userWithAccessToLink ? { width: "100%" } : undefined}
          >
            {title}
          </h1>
        )}
        {title && !pageTitle && (
          <div
            style={{
              display: "flex",
              width: !userWithAccessToLink ? "100%" : "",
            }}
          >
            <h2 className={styles.pageTitle}>{title}</h2>
            {status && (
              <h2
                style={
                  {
                    color: "#cc0000",
                    marginLeft: "8px",
                  } ?? {}
                }
              >
                ({t(status)})
              </h2>
            )}
          </div>
        )}

        {userWithAccessToLink && (
          <Row type="right">
            {isDirty ? (
              <div role="none" onClick={() => pageState.setHeaderFlag(true)}>
                <Link to={location.pathname} className={styles.switchLink}>
                  <img src={vector} alt="vector" />
                  <span>{t("Common:switch_version")}</span>
                </Link>
              </div>
            ) : (
              <>
                {!questionnaireDate ||
                today.diff(moment(questionnaireDate), "days") > 6 ? (
                  <div
                    role="none"
                    onClick={() => setShowModalQuestionnaire(true)}
                    className={styles.switchLink}
                  >
                    <img src={vector} alt="vector" />
                    <span>{t("Common:switch_version")}</span>
                  </div>
                ) : (
                  <ExternalLink
                    icon="vector"
                    iconSize={12}
                    iconColor="Grey-500"
                    iconPosition="left"
                    text={t("Common:switch_version")}
                    url={url}
                    targetBlank={false}
                    className={styles.switchLink}
                  />
                )}
              </>
            )}
          </Row>
        )}
        <Row
          type="right"
          className={styles.actionContainer}
          style={!userWithAccessToLink ? { width: "100%" } : undefined}
        >
          {children && children}
        </Row>
      </Row>
      {subTitle && (
        <>
          <Row
            type="fill"
            className={classNames({
              [styles.pageHeader]: true,
              [styles.pageSubtitle]: true,
            })}
          >
            <p>{subTitle}</p>
          </Row>
          <Spacer size={10} />
        </>
      )}
    </>
  );
};
export default PageHeader;
