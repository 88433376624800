import Popup from "reactjs-popup";
import classnames from "classnames";
import "reactjs-popup/dist/index.css";
import "./Tooltip.module.css";
import { ReactNode } from "react";

export interface TooltipProps {
  variant?: "dark" | "light";
  size?: "small" | "medium";

  /** PopupPosition from reactjs-popup */
  position?:
    | "top left"
    | "top center"
    | "top right"
    | "right top"
    | "right center"
    | "right bottom"
    | "bottom left"
    | "bottom center"
    | "bottom right"
    | "left top"
    | "left center"
    | "left bottom"
    | "center center";

  /** Function that returns element that triggers tooltip.
   * Note: if doesn't work for custom component, then try to wrap in div, for instance
   */
  trigger: () => JSX.Element;

  text?: string;

  /* When set to true, component is rendered as usual but tooltip is disabled for it */
  disabled?: boolean;

  /** Pass custom content here instead of text */
  children?: ReactNode;
  constentStyle?: React.CSSProperties;
}

export const Tooltip = ({
  variant = "dark",
  size = "small",
  position = "top left",
  trigger,
  text = "",
  disabled = undefined,
  children = undefined,
  constentStyle = {},
}: TooltipProps) => (
  <Popup
    trigger={() => trigger()}
    position={position}
    on={["hover", "focus"]}
    contentStyle={constentStyle}
    closeOnDocumentClick
    closeOnEscape
    repositionOnResize
    keepTooltipInside
    arrow={false}
    className={classnames({
      popupTooltipCustom: true,
      popupTooltipCustomDark: variant === "dark",
      popupTooltipCustomLight: variant === "light",
      popupTooltipCustomSmall: size === "small",
      popupTooltipCustomMedium: size === "medium",
    })}
    disabled={disabled}
  >
    <span>{children || text}</span>
  </Popup>
);
export default Tooltip;
