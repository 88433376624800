import { useState } from "react";
import Icon from "../Icon";
import styles from "./Alert.module.css";

export interface AlertItemProps {
  title: string;
  note?: string;
  canBeClosed?: boolean;
  onClosed?: () => void;
  key?: string;
}

export const AlertItem = ({
  title,
  note = undefined,
  canBeClosed = false,
  onClosed = undefined,
  key,
}: AlertItemProps) => {
  const [isVisible, setIsVisible] = useState(true);

  // show either note or close
  const isNoteEnabled = !!note;
  const isCloseEnabled = !isNoteEnabled && canBeClosed;

  return isVisible ? (
    <div className={styles.alertItem} key={key}>
      <span className={styles.alertItemTitle}>{title}</span>
      {isNoteEnabled ? (
        <span className="ml-auto line-height-0">
          <span className={styles.alertItemNote}>{note}</span>
        </span>
      ) : null}
      {isCloseEnabled ? (
        <span className="ml-auto line-height-0">
          <Icon
            name="x"
            size={24}
            className={`${styles.alertIcon} ${styles.closeIcon}`}
            onClick={() => {
              setIsVisible(false);
              if (onClosed) {
                onClosed();
              }
            }}
          />
        </span>
      ) : null}
    </div>
  ) : null;
};
export default AlertItem;
