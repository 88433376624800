import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";

import useUser from "../../../core/user/useUser";
import { sendDownloadLink } from "../../../core/api/objects/objects";

import ModalContent from "../../../ui-lib/components/Popup/ModalContent";
import Button from "../../../ui-lib/components/Button/Button";
import { notify } from "../../../ui-lib/components/Alerts/Toast";

import { Row, Spacer } from "../../../components/Layout/Layout";
import { getErrorKey } from "../../../components/Errors/ErrorAlert";
import useTranslations from "../../../core/i18n/useTranslations";

const DownloadLinkModal = ({ onClose }: { onClose: () => void }) => {
  const t = useTranslations();
  const { id } = useParams<{ id: string }>();
  const { authenticatedRequest } = useUser();

  return (
    <ModalContent onClose={onClose} width="576px">
      <h2 className="subTitle">{t("Objects:download_link_send")}</h2>
      <Spacer size={16} />
      <p style={{ color: "var(--Grey-600)" }}>
        {t("Messages:download_link_send")}
      </p>
      <Spacer size={32} />
      <Row type="left">
        <Button
          text={t("Objects:download_link_send_short")}
          onClick={async () => {
            try {
              await sendDownloadLink(id, authenticatedRequest);
              notify({
                message: t("Messages:download_link_send_success"),
              });
              onClose();
            } catch (error) {
              const errorKey = getErrorKey(error);
              notify({
                message: t(`Errors:${errorKey}`),
                variant: "error",
              });
            }
          }}
        />
        <Spacer size={8} />
        <Button
          variant="secondary"
          text={t("Common:cancel")}
          onClick={onClose}
        />
      </Row>
    </ModalContent>
  );
};

export default observer(DownloadLinkModal);
