import { useEffect, useState } from "react";
import { Controller, FieldError, useForm } from "react-hook-form";
import { Row, Spacer } from "../../../components/Layout/Layout";
import { CustomRepentanceModal } from "../../CommonContacts/Accessibility/CustomRepentanceModal";
import PrimeModal from "../../../ui-lib/components/PrimeModal/PrimeModal";
import DatePicker from "../../../ui-lib/components/DateAndTime/DatePicker";
import TimePicker from "../../../ui-lib/components/DateAndTime/TimePicker";
import TextInput from "../../../ui-lib/components/Inputs/TextInput";
import Dropdown from "../../../ui-lib/components/Dropdown/Dropdown";
import useTranslations from "../../../core/i18n/useTranslations";
import { CustomSelectedDays } from "../../../ui-lib/components/Scheduler/CustomSelectedDays";
import { IDropdownItem } from "../../../ui-lib/components/Dropdown/DropdownItem";
import {
  IActivityCreate,
  IActivityData,
  IActivityForm,
  IStatusEvent,
} from "../../../core/api/objects/types";
import { getPreselectedRepeatSchedule } from "../../../ui-lib/utils/schedulerHelper";
import { useRepentanceDropdown } from "../../../core/hooks/useRepentanceDropdown";
import {
  IExpectedUserSettings,
  IOrgIntegration,
} from "../../../core/api/organizations/types";
import { uniqBy } from "lodash";
import { getStoredToken } from "../../../core/user/token";
import NumberInput from "../../../ui-lib/components/Inputs/NumberInput";
import moment from "moment/moment";
import useSharedObject from "../SharedObjectContext/useSharedObject";
import useUser from "../../../core/user/useUser";
import { notify } from "../../../ui-lib/components/Alerts/Toast";
import { getErrorKey } from "../../../components/Errors/ErrorAlert";
import { toCronString } from "../../../ui-lib/utils/cronParser";
import {
  createActivity,
  getActivity,
  updateActivity,
} from "../../../core/api/objects/objects";
import { notifyApiErrors } from "../../../core/helpers/helpers";
import LoadingSpinner from "../../../ui-lib/components/Loading/LoadingSpinner";
import { CurrentActivityHistory } from "./CurrentActivityHistory";

export const NewActivityModal = ({
  isOpen,
  integrations = [],
  onClose,
  userId,
  activityId,
  canEdit,
}: {
  userId: number;
  activityId?: string;
  isOpen: boolean;
  canEdit: boolean;
  integrations?: IOrgIntegration[];
  onClose: (refetch?: boolean) => void;
}) => {
  const t = useTranslations();
  const { data: objectData } = useSharedObject();
  const { authenticatedRequest } = useUser();

  const {
    repentanceDropdownItems,
    setRepentanceDropdownItems,
    setSelectedDropdownItems,
  } = useRepentanceDropdown(0, true);

  const [isCustomModalOpened, setIsCustomModalOpened] = useState(false);
  const [showCustomSelectedDays, setShowCustomSelectedDays] = useState(false);
  const [integrationSectionItems, setIntegrationSectionItems] = useState<
    IDropdownItem[]
  >([]);
  const [integrationItems, setIntegrationItems] = useState<IDropdownItem[]>([]);
  const [timingItems, setTimingItems] = useState<IDropdownItem[]>([]);
  const [customSettings, setCustomSettings] = useState<IExpectedUserSettings[]>(
    []
  );
  const [isLoading, setIsLoading] = useState(false);
  const [currentActivityHistory, setCurrentActivityHistory] =
    useState<IStatusEvent[]>();
  const [defaultSelectedDays, setDefaultSelectedDays] = useState<number[]>([]);

  const selectedTimingType = timingItems?.find((i) => i.isSelected)?.name;

  const {
    formState: { errors, isDirty },
    control,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    clearErrors,
    reset,
    watch,
  } = useForm<IActivityForm>();

  const fillFormData = (data: IActivityData) => {
    const resetObject = {
      integrationType: data.integrationType,
      integration: data.integration,
      description: data.description,
      timingType: data.timingType,
    } as any;
    if (data.timingType === "Reoccuring") {
      const timingData = data.timingInformation?.split(" ");
      const repeatDaysData = timingData[5].split(",").map((i) => +i);
      resetObject.startTime = `${timingData[2]}:${timingData[1]}`;
      resetObject.startDate = moment(data.startDate).toDate();
      const selectedRepeatSchedule = getPreselectedRepeatSchedule(
        repeatDaysData,
        data.endDate,
        true
      );
      setSelectedDropdownItems(selectedRepeatSchedule);
      setShowCustomSelectedDays(selectedRepeatSchedule === 5);
      setDefaultSelectedDays(repeatDaysData);
    }
    if (data.timingType === "Date") {
      resetObject.startTime = moment(data.timingInformation).format("HH:mm");
      resetObject.startDate = moment(data.timingInformation).toDate();
    }
    setIntegrationSectionItems(
      integrationSectionItems.map((i) => ({
        ...i,
        isSelected: i.name === data.integrationType,
      }))
    );
    setIntegrationItems(
      (integrations ?? [])
        .filter((i) => i.integrationType === data.integrationType)
        .map((i: IOrgIntegration) => ({
          id: i.integrationId,
          name: i.name,
          isSelected: i.integrationId === data.integration,
        }))
    );
    setTimingItems(
      integrations
        .find((i) => i.integrationId === data.integration)
        ?.supportedTimingTypes.map((i: string) => ({
          id: i,
          name: t(`Objects:${i}`),
          isSelected: i === data.timingType,
        })) ?? []
    );
    const activityUserSettings =
      integrations.find((i) => i.integrationId === data.integration)
        ?.expectedUserSettings ?? [];
    activityUserSettings.forEach((setting) => {
      resetObject[`userSettings.${setting.name}`] =
        data.userSettings[setting.name];
    });
    setCustomSettings(activityUserSettings);
    activityUserSettings.forEach((setting) => {
      resetObject[`userSettings.${setting.name}`] =
        data.userSettings[setting.name];
    });
    reset(resetObject);
  };

  const fetchActivityData = async () => {
    setIsLoading(true);
    try {
      const data = await getActivity(userId, activityId!, authenticatedRequest);
      fillFormData(data.data);
      setCurrentActivityHistory(data.data.statusEvents);
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (activityId) {
      fetchActivityData();
    }
  }, [activityId]);

  const repeatDays = watch("repeatDays");

  const onCloseWrapper = (refetch = false) => {
    setRepentanceDropdownItems(
      repentanceDropdownItems.map((item) => ({
        ...item,
        isSelected: item.id === 0,
      }))
    );
    setShowCustomSelectedDays(false);
    setIntegrationSectionItems(
      integrationSectionItems.map((i) => ({ ...i, isSelected: false }))
    );
    setIntegrationItems([]);
    setTimingItems([]);
    setDefaultSelectedDays([]);
    setCurrentActivityHistory(undefined);
    reset({ description: "" });
    onClose(refetch);
  };

  const onSubmit = async (data: IActivityForm) => {
    setIsLoading(true);
    const timezoneId = getStoredToken()?.guiConfig.timezone.timezoneName;
    const organizationId = integrations.find(
      (i) => i.integrationId === data.integration
    )?.organizationId;
    let cronString;
    let startDate;

    if (selectedTimingType !== "Now") {
      startDate = moment(data.startDate);
      startDate.set("hours", +data.startTime.split(":")[0]);
      startDate.set("minutes", +data.startTime.split(":")[1]);
    }
    if (selectedTimingType === "Reoccuring") {
      const defaultDaysToUse = defaultSelectedDays.length
        ? defaultSelectedDays
        : [1, 2, 3, 4, 5, 6, 7];
      cronString = toCronString(
        data.startTime,
        data.repeatDays?.length ? data.repeatDays : defaultDaysToUse,
        data.endDate ?? undefined
      );
    }
    const dataToSubmit: IActivityCreate = {
      integration: data.integration,
      organizationId: organizationId!,
      description: data.description,
      timezoneId: timezoneId!,
      timingType: data.timingType,
      endDate: data.endDate ? moment(data.endDate).toISOString() : undefined,
      ...(data.userSettings && { userSettings: data.userSettings }),
      ...(startDate && { startDate: startDate.toISOString() }),
      ...((cronString || startDate) && {
        timingInformation: cronString ?? startDate?.toISOString(),
      }),
    };
    try {
      if (activityId) {
        await updateActivity(
          objectData?.id!,
          activityId,
          dataToSubmit,
          authenticatedRequest
        );
      } else {
        await createActivity(
          objectData?.id!,
          dataToSubmit,
          authenticatedRequest
        );
      }
      onCloseWrapper(true);
    } catch (error: any) {
      notify({
        message: t(`Errors:${getErrorKey(error)}`),
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (integrations.length && !integrationSectionItems.length) {
      setIntegrationSectionItems(
        uniqBy(integrations, "integrationType").map(
          (integration: IOrgIntegration, index: number) => ({
            id: index,
            name: integration.integrationType,
            isSelected: false,
          })
        )
      );
    }
  }, [integrations]);

  const validateCustomSettingField = (field: FieldError): string => {
    const setting = customSettings.find(
      (s) => s.name === field.ref?.name.split(".")[1]
    );
    if (setting) {
      if (field.type === "required") {
        return t("Errors:input_field_required");
      }
      if (field.type === "pattern") {
        return t("Errors:input_field_invalid").replace(
          "{0}",
          setting.regex ?? ""
        );
      }
      if (field.type === "maxLength") {
        return t("Errors:input_field_max_length").replace(
          "{0}",
          setting.maximum?.toString() ?? ""
        );
      }
      if (field.type === "minLength") {
        return t("Errors:input_field_min_length").replace(
          "{0}",
          setting.minimum?.toString() ?? ""
        );
      }
    }
    return t("Errors:wrong_format");
  };

  const getModalHeader = () => {
    if (!activityId) {
      return t("Objects:plan_new_activity");
    }

    return canEdit ? t("Objects:edit_activity") : t("Objects:view_activity");
  };

  const getModalDescription = () => {
    if (!activityId) {
      return t("Objects:new_activity_description");
    }

    return canEdit
      ? t("Objects:edit_activity_description")
      : t("Objects:view_activity_description");
  };

  return (
    <>
      <PrimeModal
        withHeader
        withFooter
        style={{ width: "848px" }}
        header={getModalHeader()}
        isOpen={isOpen}
        onClose={onCloseWrapper}
        submitBtn={{
          text: activityId
            ? t("Common:save_changes")
            : t("Objects:plan_new_activity"),
          variant: "primary",
          onClick: handleSubmit(onSubmit),
          disabled: isLoading || !isDirty,
          hidden: !canEdit,
        }}
        cancelBtn={{
          text: t("Common:cancel"),
          onClick: () => {
            reset();
            onCloseWrapper();
          },
        }}
      >
        {isLoading ? (
          <LoadingSpinner theme="primary" />
        ) : (
          <>
            <form>
              <p>{getModalDescription()}</p>
              <Spacer size={16} />
              <Row>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="integrationType"
                  render={({ field }) => (
                    <Dropdown
                      title={t("Common:integration")}
                      selectedItem={integrationSectionItems.find(
                        (i) => i.isSelected
                      )}
                      items={integrationSectionItems}
                      onSelectItem={(e) => {
                        const prevSelectedItem = integrationSectionItems.find(
                          (i) => i.isSelected
                        );
                        setValue(field.name, e.name);
                        setIntegrationSectionItems(
                          integrationSectionItems.map((i) => ({
                            ...i,
                            isSelected: i.id === e.id,
                          }))
                        );
                        if (
                          !integrationItems.length ||
                          prevSelectedItem?.name !== e.name
                        ) {
                          setIntegrationItems(
                            (integrations ?? [])
                              .filter((i) => i.integrationType === e.name)
                              .map((i: IOrgIntegration) => ({
                                id: i.integrationId,
                                name: i.name,
                                isSelected: false,
                              }))
                          );
                        }
                      }}
                      disabled={!canEdit}
                      validationError={
                        errors.integrationType &&
                        t("Errors:input_field_required")
                      }
                    />
                  )}
                />
                <Spacer size={32} />
                <Controller
                  name="integration"
                  rules={{ required: true }}
                  control={control}
                  render={({ field }) => (
                    <Dropdown
                      disabled={!integrationItems.length || !canEdit}
                      items={integrationItems}
                      onSelectItem={(e) => {
                        const selectedIntegration = integrations.find(
                          (i) => i.integrationId === e.id
                        );
                        setValue(field.name, e.id as string);
                        setIntegrationItems(
                          integrationItems.map((i) => ({
                            ...i,
                            isSelected: i.id === e.id,
                          }))
                        );
                        setTimingItems(
                          selectedIntegration?.supportedTimingTypes.map(
                            (i: string) => ({
                              id: i,
                              name: t(`Objects:${i}`),
                              isSelected: false,
                            })
                          ) ?? []
                        );
                        setCustomSettings(
                          selectedIntegration?.expectedUserSettings ?? []
                        );
                      }}
                      selectedItem={integrationItems.find((i) => i.isSelected)}
                      title={t("Objects:activity_type")}
                      validationError={
                        errors.integration && t("Errors:input_field_required")
                      }
                    />
                  )}
                />
              </Row>
              <Spacer size={16} />
              <Controller
                name="timingType"
                rules={{ required: true }}
                control={control}
                render={({ field }) => (
                  <Dropdown
                    disabled={!timingItems.length || !canEdit}
                    items={timingItems}
                    onSelectItem={(e) => {
                      setValue(field.name, e.id as string);
                      setTimingItems(
                        timingItems.map((i) => ({
                          ...i,
                          isSelected: i.id === e.id,
                        }))
                      );
                    }}
                    selectedItem={timingItems.find((i) => i.isSelected)}
                    title={t("Objects:timing_type")}
                    width={376}
                    validationError={
                      errors.timingType && t("Errors:input_field_required")
                    }
                  />
                )}
              />
              <Spacer size={16} />
              {(selectedTimingType === "Date" ||
                selectedTimingType === "Reoccuring") && (
                <>
                  <Row>
                    <Controller
                      name="startDate"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <DatePicker
                          disabled={!canEdit}
                          minDate={new Date()}
                          value={field.value}
                          onChange={field.onChange}
                          label={t("Common:select_start_date")}
                          validationError={
                            errors.startDate && t("Errors:input_field_required")
                          }
                        />
                      )}
                    />
                    <Spacer size={32} />
                    <Controller
                      name="startTime"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <TimePicker
                          disabled={!canEdit}
                          value={field.value}
                          onChange={field.onChange}
                          label={t("Contacts:input_start_time")}
                          validationError={
                            errors.startTime && t("Errors:input_field_required")
                          }
                        />
                      )}
                    />
                  </Row>
                  <Spacer size={16} />
                </>
              )}

              {selectedTimingType === "Reoccuring" && (
                <>
                  <Row type="left">
                    <Controller
                      name="repeatDays"
                      control={control}
                      render={() => (
                        <Dropdown
                          disabled={!canEdit}
                          width={376}
                          onSelectItem={(item) => {
                            if (item.id !== 5) {
                              setValue("repeatDays", undefined);
                              setShowCustomSelectedDays(false);
                            }
                            switch (item.id) {
                              case 0:
                                setValue("repeatDays", [1, 2, 3, 4, 5, 6, 7]);
                                break;
                              case 1:
                                setValue("repeatDays", []);
                                break;
                              case 2:
                                setValue("repeatDays", [
                                  new Date().getDay() + 1,
                                ]);
                                break;
                              case 3:
                                setValue("repeatDays", [2, 3, 4, 5, 6]);
                                break;
                              case 4:
                                setValue("repeatDays", [1, 7]);
                                break;
                              case 5:
                                setIsCustomModalOpened(true);
                                if (
                                  repentanceDropdownItems.find(
                                    (i) => i.isSelected
                                  )!.id !== 5
                                ) {
                                  setValue("repeatDays", []);
                                }
                                setShowCustomSelectedDays(true);
                                break;
                              default:
                                setShowCustomSelectedDays(false);
                                break;
                            }
                            setSelectedDropdownItems(+item.id);
                          }}
                          selectedItem={repentanceDropdownItems.find(
                            (item) => item.isSelected
                          )}
                          items={repentanceDropdownItems}
                          title={t("Contacts:repeat")}
                          maxVisible={6}
                        />
                      )}
                    />
                    <Spacer size={16} />
                    {showCustomSelectedDays && (
                      <CustomSelectedDays
                        repeatDays={repeatDays ?? defaultSelectedDays}
                        isFormEditable={true}
                        endDateValue={getValues("endDate") as any}
                        startFromSunday
                      />
                    )}
                  </Row>
                  <Spacer size={16} />
                </>
              )}

              <Controller
                name="description"
                control={control}
                render={({ field }) => (
                  <TextInput
                    disabled={!canEdit}
                    label={t("Common:labels_additional_info")}
                    placeholder={t("Objects:activity_details_placeholder")}
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
              {customSettings.map((setting) => {
                switch (setting.settingType) {
                  case "String":
                    return (
                      <>
                        <Spacer key={`spacer-${setting.name}`} size={16} />

                        <Controller
                          rules={{
                            required: true,
                            pattern: new RegExp(setting.regex!),
                            maxLength: setting.maximum,
                            minLength: setting.minimum,
                          }}
                          name={`userSettings.${setting.name}`}
                          control={control}
                          render={({ field }) => (
                            <TextInput
                              key={`input-${setting.name}`}
                              label={setting.name}
                              value={field.value}
                              onChange={field.onChange}
                              validationError={
                                errors.userSettings?.[setting.name] &&
                                validateCustomSettingField(
                                  errors.userSettings?.[
                                    setting.name
                                  ] as FieldError
                                )
                              }
                            />
                          )}
                        />
                      </>
                    );
                  case "Int":
                    return (
                      <>
                        <Spacer key={`spacer-${setting.name}`} size={16} />

                        <Controller
                          control={control}
                          rules={{
                            required: true,
                            maxLength: setting.maximum,
                            minLength: setting.minimum,
                          }}
                          name={`userSettings.${setting.name}`}
                          render={({ field }) => (
                            <NumberInput
                              key={`input-${setting.name}`}
                              required
                              label={setting.name}
                              value={field.value?.toString()}
                              onChange={(val) => {
                                field.onChange(val);
                                trigger(field.name);
                              }}
                              validationError={
                                errors.userSettings?.[setting.name] &&
                                validateCustomSettingField(
                                  errors.userSettings?.[
                                    setting.name
                                  ] as FieldError
                                )
                              }
                            />
                          )}
                        />
                      </>
                    );
                  case "Enum":
                    return (
                      <>
                        <Spacer key={`spacer-${setting.name}`} size={16} />

                        <Controller
                          control={control}
                          rules={{ required: true }}
                          name={`userSettings.${setting.name}`}
                          render={({ field }) => {
                            const items = setting.selectableEnums!.map((i) => ({
                              id: i,
                              name: i,
                              isSelected: i === field.value,
                            }));
                            return (
                              <Dropdown
                                key={`input-${setting.name}`}
                                required
                                title={setting.name}
                                selectedItem={items.find((i) => i.isSelected)}
                                items={items}
                                onSelectItem={(item) => {
                                  setValue(field.name, item.id as string);
                                }}
                                validationError={
                                  errors.userSettings?.[setting.name] &&
                                  validateCustomSettingField(
                                    errors.userSettings?.[
                                      setting.name
                                    ] as FieldError
                                  )
                                }
                              />
                            );
                          }}
                        />
                      </>
                    );
                  case "Dynamic":
                    return (
                      <>
                        <Spacer key={`spacer-${setting.name}`} size={16} />

                        <Controller
                          name={`userSettings.${setting.name}`}
                          control={control}
                          render={({ field }) => (
                            <TextInput
                              key={`input-${setting.name}`}
                              label={setting.name}
                              value={field.value}
                              onChange={field.onChange}
                            />
                          )}
                        />
                      </>
                    );
                }
              })}
              <Spacer size={2} />
            </form>
            {activityId && (
              <CurrentActivityHistory statuses={currentActivityHistory ?? []} />
            )}
          </>
        )}
      </PrimeModal>
      <PrimeModal
        withHeader
        withFooter
        header={t("Contacts:custom_recurrence")}
        isOpen={isCustomModalOpened}
        onClose={() => setIsCustomModalOpened(false)}
        submitBtn={{
          disabled: isLoading,
          text: t("Common:save"),
          variant: "primary",
          onClick: () => {
            trigger("endDate").then(() => {
              if (!errors.endDate) {
                setIsCustomModalOpened(false);
              }
            });
          },
        }}
        cancelBtn={{
          text: t("Common:cancel"),
          onClick: () => {
            reset({
              ["endDate"]: undefined,
              ["repeatDays"]: [],
            });
            setIsCustomModalOpened(false);
          },
        }}
      >
        <CustomRepentanceModal
          control={control}
          errors={errors}
          namePrefix=""
          setValue={setValue}
          getValues={getValues}
          trigger={trigger}
          clearErrors={clearErrors}
          startFromSunday
          alignWithCron
        />
      </PrimeModal>
    </>
  );
};
