/* eslint-disable react/no-danger */
import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import useBranding from "../../core/branding/useBranding";
import useLanguage from "../../core/i18n/useLanguage";
import styles from "./About.module.css";
import { Spacer } from "../../components/Layout/Layout";
import ExternalLink from "../../ui-lib/components/ExternalLink/ExternalLink";
import useTranslations from "../../core/i18n/useTranslations";

const AboutContent = () => {
  const t = useTranslations();
  const brandingStore = useBranding();
  const languageStore = useLanguage();
  const [aboutContent, setAboutContent] = useState<string>();

  useEffect(() => {
    setAboutContent(brandingStore?.currentAboutContent?.[languageStore.lang]);
  }, [languageStore.lang, brandingStore.currentAboutContent]);

  return (
    <div className={styles.aboutContent}>
      {aboutContent ? (
        <div dangerouslySetInnerHTML={{ __html: aboutContent }} />
      ) : (
        <p>{t("Common:missingtranslation")}</p>
      )}
      <Spacer size={32} />
      <p>
        <ExternalLink
          text={`${t("Common:home")} - Skyresponse`}
          url="https://skyresponse.com"
        />
      </p>
    </div>
  );
};

export default observer(AboutContent);
