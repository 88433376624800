import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { ReactElement, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { breakpoints, useDimensions } from "../../core/hooks/dimensionProvider";
import { useOutsideClick } from "../../core/hooks/useOutsideClick";
import useLanguage from "../../core/i18n/useLanguage";
import useTranslations from "../../core/i18n/useTranslations";
import { SubNav } from "../../core/interfaces/SubNav";
import usePageState from "../../core/pagestate/usePageState";
import useUser from "../../core/user/useUser";
import Divider from "../../ui-lib/components/Divider/Divider";
import Icon from "../../ui-lib/components/Icon";
import SideBar from "../../ui-lib/components/Menus/SideBar/SideBar";
import SideBarSecondary from "../../ui-lib/components/Menus/SideBar/SideBarSecondary";
import { Column } from "../Layout/Layout";
import { SubNavContext } from "../LoggedinPage/SubNavContext";
import styles from "./LoggedinPage.module.css";
import useMenu from "./useMenu";
import { ImpersonationHeader } from "../Administrator/ImpersonationHeader/ImpersonationHeader";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "../../ui-lib/components/Loading/LoadingSpinner";

const LoggedInPage = ({ children }: { children: ReactElement }) => {
  const user = useUser();
  const menu = useMenu(user);
  const t = useTranslations();
  const pageState = usePageState();
  const location = useLocation();
  const lang = useLanguage();
  const { i18n } = useTranslation();

  // Navigation setup
  const menuRef = useRef(null);
  const [subPageNav, setSubPageNav] = useState<SubNav>();
  const mainNavigation = Object.values(menu).filter((m) => m.menu === "main");
  const footerNavigation = Object.values(menu).filter(
    (m) => m.menu === "footer"
  );
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (lang.isLanguagesLoading && !isLoading) {
      setIsLoading(true);
    }
  }, [lang.isLanguagesLoading]);

  useEffect(() => {
    const onLanguageChanged = () => {
      setIsLoading(false);
      lang.isLanguagesLoading = false;
    };

    // Subscribe to language change events
    i18n.on("languageChanged", onLanguageChanged);

    // Cleanup the event listeners when the component unmounts
    return () => {
      i18n.off("languageChanged", onLanguageChanged);
    };
  }, [i18n]);

  // Update page state on location change
  useEffect(() => {
    if (Object.keys(menu).length > 0) {
      const menuItem = menu[location.pathname];
      if (menuItem) {
        pageState.setPageSubTitle(t(menuItem.subtitle ?? t("Menu:empty")));
        pageState.setPageIcon(menuItem.icon);
        pageState.setPath(menuItem.to ?? "");
      }
    }
  }, [location.pathname, lang.currentLanguage]);

  // Adjust navigation based on screen size
  const { width } = useDimensions();
  const closeNav = () => pageState.setShowNavigation(false);
  useOutsideClick(menuRef, () => {
    if (width < breakpoints.tablet && pageState.showNavigation) {
      pageState.setShowNavigation(false);
    }
  });

  const contextData = useMemo(
    () => ({ setSubNav: setSubPageNav }),
    [setSubPageNav]
  );

  return (
    <main
      id={styles.siteContent}
      className={classNames({
        [styles.hasSecondary]: subPageNav && width < breakpoints.tablet,
        [styles.showNavigation]: pageState.showNavigation,
      })}
    >
      <div className={styles.overlay} />
      <div
        ref={menuRef}
        id={styles.sidebarMenu}
        className={classNames({
          hasSecondary: subPageNav && width < breakpoints.tablet,
        })}
      >
        {isLoading ? (
          <LoadingSpinner theme="primary" />
        ) : (
          <>
            <SideBar
              mainNavigation={mainNavigation}
              footerNavigation={footerNavigation}
              closeNavigation={closeNav}
              expandText={t("Menu:expand")}
              minimizeText={t("Menu:minimize")}
              minimized={subPageNav && width < breakpoints.tablet}
            />
            {subPageNav && (
              <>
                <Divider vertical />
                <SideBarSecondary
                  back={{
                    to: subPageNav.backUrl,
                    text: subPageNav.backText,
                  }}
                  closeAction={closeNav}
                  items={subPageNav.menu.map((item, i) => ({
                    id: i,
                    text: t(`${item.key}`),
                    to: item.to,
                    icon: item.icon ?? "",
                    subItems: item.subItems,
                  }))}
                />
              </>
            )}
            {width < breakpoints.tablet && (
              <button
                onClick={closeNav}
                type="button"
                className={styles.closeMenuButton}
                aria-label="Close"
              >
                <Icon name="x" color="Grey-400" />
              </button>
            )}
          </>
        )}
      </div>
      <Divider vertical />
      <Column type="top" className={styles.main}>
        {user.impersonatedFrom && <ImpersonationHeader />}
        <Column align="start" className={styles.content}>
          <SubNavContext.Provider value={contextData}>
            {isLoading ? <LoadingSpinner theme="primary" /> : children}
          </SubNavContext.Provider>
        </Column>
      </Column>
    </main>
  );
};

export default observer(LoggedInPage);
