import { useCallback, useMemo } from "react";
import { useQueryState } from "./useQueryState";

type InStock = { inStock?: string };

const toInStock = (state: InStock): InStock => ({
  inStock: state.inStock,
});

const DEFAULT: InStock = {
  inStock: "1",
};

export const useInStockFilter = (searchObj?: any) => {
  const stateToUse = !searchObj
    ? DEFAULT
    : {
        inStock: searchObj.inStock,
      };

  const [state, setState] = useQueryState<InStock>(stateToUse);

  const inStockFilter = useMemo(() => toInStock(state), [state]);

  const setInStockFilter = useCallback(
    (args: InStock) => setState(args),
    [setState]
  );

  const resetInStockFilter = useCallback(() => setState(DEFAULT), [setState]);

  return { inStockFilter, setInStockFilter, resetInStockFilter };
};
