import { Controller, useFormContext } from "react-hook-form";
import { Column, Row, Spacer } from "../../../../components/Layout/Layout";
import useTranslations from "../../../../core/i18n/useTranslations";
import Checkbox from "../../../../ui-lib/components/Checkbox/Checkbox";

const CheckboxControl = ({ name, label }: { name: string; label: string }) => {
  const { control } = useFormContext();

  return (
    <Column align="start" style={{ width: "100%" }}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Checkbox
            label={label}
            checked={field.value}
            onChange={field.onChange}
            style={{ padding: 0 }}
          />
        )}
      />
    </Column>
  );
};

export const DistributorSettings = () => {
  const t = useTranslations();

  return (
    <>
      <span className="strong">
        {t("AlarmReceptions:distributor_settings_title")}
      </span>
      <Spacer size={8} />
      <Row>
        <CheckboxControl
          name="sendResponseAlarm"
          label={t("AlarmReceptions:distributor_input_new_alarms")}
        />
        <Spacer size={32} />
        <CheckboxControl
          name="onCare"
          label={t("AlarmReceptions:distributor_input_care_alarms")}
        />
      </Row>
      <Spacer size={8} />
      <Row>
        <CheckboxControl
          name="onResponse"
          label={t("AlarmReceptions:distributor_input_response_alarms")}
        />
        <Spacer size={32} />
        <CheckboxControl
          name="onService"
          label={t("AlarmReceptions:distributor_input_service_alarms")}
        />
      </Row>
      <Spacer size={8} />
      <Row>
        <CheckboxControl
          name="onProperty"
          label={t("AlarmReceptions:distributor_property_alarms")}
        />
        <Spacer size={32} />
        <Column style={{ width: "100%" }} />
      </Row>
    </>
  );
};
