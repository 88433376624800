import { AxiosInstance, AxiosResponse } from "axios";
import { observer } from "mobx-react";
import { useState } from "react";
import useTranslations from "../../core/i18n/useTranslations";
import useUser from "../../core/user/useUser";
import { notify } from "../../ui-lib/components/Alerts/Toast";
import Button from "../../ui-lib/components/Button/Button";
import ModalContent from "../../ui-lib/components/Popup/ModalContent";
import { notifyError } from "../Errors/ErrorAlert";
import { Row, Spacer } from "../Layout/Layout";

const DeleteModal = ({
  locizeKey,
  title,
  replaceKey,
  callDelete,
  onClose,
  onDelete,
}: {
  locizeKey:
    | "alarm_reception"
    | "alarm_handling"
    | "alarm_code"
    | "alarm_code_rule"
    | "administrator"
    | "role_template"
    | "arc_subscription";
  title?: string;
  replaceKey?: string;
  callDelete: (authReq: AxiosInstance) => Promise<AxiosResponse<{}>>;
  onClose: () => void;
  onDelete: () => void;
}) => {
  const t = useTranslations();
  const { authenticatedRequest } = useUser();

  const [loading, setLoading] = useState(false);

  const getMessageText = () => {
    const splitedMessage = t(`Messages:delete_${locizeKey}_confirm`).split(
      "{replaceKey}"
    );
    return (
      <p>
        {splitedMessage[0]}
        <strong style={{ color: "var(--Grey-900)" }}>{replaceKey}</strong>
        {splitedMessage[1]}
      </p>
    );
  };

  const messages = {
    confirm: t(`Messages:delete_${locizeKey}_confirm`),
    success: t(`Messages:delete_${locizeKey}_success`),
  };

  const onSubmit = async () => {
    setLoading(true);
    try {
      await callDelete(authenticatedRequest);

      notify({
        message: messages.success,
      });
      onDelete();
    } catch (error: any) {
      notifyError({ error, t });
      onClose();
    }
    setLoading(false);
  };

  return (
    <ModalContent onClose={onClose} width="576px">
      <h2 className="subTitle">{title || t("Common:delete")}</h2>
      <Spacer size={16} />
      {replaceKey ? (
        getMessageText()
      ) : (
        <p style={{ color: "var(--Grey-600)" }}>{messages.confirm}</p>
      )}

      <Spacer size={32} />

      <Row type="left">
        <Button
          loading={loading}
          variant="destructive"
          text={title || t("Common:delete")}
          onClick={onSubmit}
        />
        <Spacer size={8} />
        <Button
          variant="secondary"
          text={t("Common:cancel")}
          onClick={onClose}
        />
      </Row>
    </ModalContent>
  );
};

export default observer(DeleteModal);
