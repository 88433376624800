import moment from "moment";
import { useState } from "react";
import { IPlanningHistory } from "../../../../core/api/objects/types";
import { NewActivityModal } from "../NewActivityModal";
import { IOrgIntegration } from "../../../../core/api/organizations/types";
import useTranslations from "../../../../core/i18n/useTranslations";
import Table from "../../../../ui-lib/components/Tables/Table";
import TableCell from "../../../../ui-lib/components/Tables/TableCell";
import Badge, { BadgeProps } from "../../../../ui-lib/components/Badges/Badge";
import NoItems from "../../../../ui-lib/components/Tables/NoItems";

export const activityStatus: Record<string, BadgeProps["variant"]> = {
  New: "secondary",
  Blocked: "secondary",
  Aborted: "secondary",
  Planned: "info",
  Ongoing: "warning",
  ExecuteSuccess: "success",
  Complete: "success",
  ExecuteFailure: "error",
  ExecuteError: "error",
  FailedAbort: "error",
};

const columns = (t: (key: string) => string) => [
  {
    header: t("Objects:activity_type"),
    fieldTemplate: (rowData: IPlanningHistory) => (
      <TableCell value={rowData.integrationName} />
    ),
  },
  {
    header: t("Common:when"),
    fieldTemplate: (rowData: IPlanningHistory) => (
      <TableCell value={moment(rowData.timestamp).format("DD-MM-YYYY hh:mm")} />
    ),
  },
  {
    header: t("Common:cerated_by"),
    fieldTemplate: (rowData: IPlanningHistory) => (
      <TableCell
        value={rowData.admin}
        linkTo={`/adminportal/administrators/${rowData.adminId}`}
      />
    ),
  },
  {
    header: t("Common:repetition"),
    fieldTemplate: (rowData: IPlanningHistory) => (
      <TableCell value={rowData.newTimingType} />
    ),
  },
  {
    header: t("Common:status"),
    fieldTemplate: (rowData: IPlanningHistory) => (
      <TableCell
        value={
          <Badge
            withHighlightedText
            title={rowData.status}
            variant={activityStatus[rowData.status]}
          />
        }
      />
    ),
  },
];

export const PlanningHistoryTable = ({
  data,
  objectId,
  integrations,
  isFiltersApplied,
  pageSettings,
  showMore,
  onClear,
  onPageSettingsChange,
}: {
  data?: IPlanningHistory[];
  objectId: string;
  integrations?: IOrgIntegration[];
  isFiltersApplied: boolean;
  pageSettings: {
    page: number;
    pageSize: number;
    total: number;
  };
  showMore: boolean;
  onClear: () => void;
  onPageSettingsChange: (args: {
    page: number;
    pageSize: number;
    total: number;
  }) => void;
}) => {
  const t = useTranslations();

  const [activityModalOpen, setActivityModalOpen] = useState(false);
  const [activityId, setActivityId] = useState<string>();

  return (
    <>
      {data?.length ? (
        <Table<IPlanningHistory>
          hideEmptyMessage
          showRowHover
          withLazyLoading={showMore}
          withShowMore
          items={data}
          columns={columns(t)}
          rowActions={[
            {
              icon: "eye",
              text: t("Common:view"),
              onClick: (rowData) => {
                setActivityId(rowData.activityId);
                setActivityModalOpen(true);
              },
            },
          ]}
          paginatedItems={{
            items: data,
            pagination: {
              offset: 0,
              limit: data.length,
              total: data.length + 1,
            },
          }}
          onShowMoreClick={() => {
            onPageSettingsChange({
              page: pageSettings.page,
              pageSize: pageSettings.pageSize + 10,
              total: pageSettings.total,
            });
          }}
        />
      ) : (
        <NoItems
          style={{ margin: "auto" }}
          title={
            !isFiltersApplied
              ? t("Table:noresult_title")
              : t("Table:no_results_found")
          }
          icon={!isFiltersApplied ? "eye-off" : undefined}
          subTitle={
            !isFiltersApplied
              ? t("Table:noresult_subtitle")
              : t("Table:adjust_filter_description")
          }
          clear={
            isFiltersApplied && onClear
              ? {
                  text: t("Table:clear_filters"),
                  onClick: onClear,
                }
              : undefined
          }
        />
      )}

      <NewActivityModal
        userId={+objectId}
        isOpen={activityModalOpen}
        activityId={activityId}
        canEdit={false}
        onClose={() => setActivityModalOpen(false)}
        integrations={integrations}
      />
    </>
  );
};
