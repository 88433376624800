export const getCurrentDayNumber = () => {
  const day = new Date().getDay();
  return day - 1 >= 0 ? day - 1 : 6;
};

export const getPreselectedRepeatSchedule = (
  repeatDays?: number[],
  endDate?: string,
  sundayFirst = false
): number => {
  // custom repeat schedule
  if (endDate) {
    return 5;
  }

  if (!repeatDays || repeatDays.length === 7) {
    // new schedule item
    return 0;
  }

  if (repeatDays.length === 0) {
    // no repeat
    return 1;
  }
  if (repeatDays.length === 1) {
    // one day selected
    return 2;
  }
  if (
    repeatDays.length === 5 &&
    repeatDays.every((item) =>
      (sundayFirst ? [2, 3, 4, 5, 6] : [0, 1, 2, 3, 4]).includes(item)
    )
  ) {
    // workdays
    return 3;
  }
  if (
    repeatDays.length === 2 &&
    repeatDays.every((item) => (sundayFirst ? [1, 7] : [5, 6]).includes(item))
  ) {
    // weekends
    return 4;
  }

  // custom repeat schedule
  return 5;
};
