import classnames from "classnames";
import { weekdaysShort } from "moment";
import { useMemo } from "react";
import inputStyles from "../Inputs/Input.module.css";
import styles from "./TagSelector.module.css";

const getWeekDays = () => {
  const weekdays = weekdaysShort(false);

  return [...weekdays.slice(1), weekdays[0]];
};

export interface DaySelectorProps {
  label?: string;
  values: number[];
  setValues?: (selectedOptions: number[]) => void;
  validationError?: string;
  startFromSunday?: boolean;
  alignWithCron?: boolean;
}

export const DaySelector = ({
  label,
  values,
  setValues,
  validationError,
  startFromSunday = false,
  alignWithCron = false,
}: DaySelectorProps) => {
  let weekdays = getWeekDays();
  if (startFromSunday) {
    weekdays = [weekdays[6], ...weekdays.slice(0, 6)];
  }

  const options = useMemo(
    () =>
      weekdays.map((day, index) => ({
        id: alignWithCron ? index + 1 : index,
        title: day.slice(0, 2),
        value: `day-${index}`,
      })),
    [weekdays]
  );

  const selectedOptions = options.filter((o) => values?.includes(o.id));

  const clickHandler = (option: any) => {
    if (setValues) {
      const nextSelectedOptions = [...selectedOptions];
      const existingIndex = nextSelectedOptions.findIndex(
        (x) => x.value === option.value
      );
      if (existingIndex !== -1) {
        nextSelectedOptions.splice(existingIndex, 1);
      } else {
        nextSelectedOptions.push(option);
      }
      setValues(nextSelectedOptions.map((o) => o.id));
    }
  };

  return (
    <>
      {label && <label>{label}</label>}
      <span
        className={classnames({
          [styles.tagSelector]: true,
          [styles.inputValidationError]: validationError !== undefined,
        })}
      >
        <span>
          {options.map((option) => (
            <button
              key={`option_${option.value}`}
              type="button"
              className={classnames({
                [styles.tagOption]: true,
                [styles.dayOption]: true,
                [styles.dayOptionSelected]: selectedOptions.some(
                  (x) => x.value === option.value
                ),
              })}
              onClick={() => clickHandler(option)}
            >
              {option.title}
            </button>
          ))}
        </span>
      </span>
      {validationError ? (
        <div className={inputStyles.validationError}>{validationError}</div>
      ) : null}
    </>
  );
};
export default DaySelector;
