import useTranslations from "../../../core/i18n/useTranslations";
import { Column, Spacer } from "../../../components/Layout/Layout";
import { ContactForm } from "../../Objects/ObjectResponse/Contacts/ContactForm";
import { useOrganizationsTree } from "../../../core/api/organizations/organizations";
import useSharedContact from "../SharedContactContext/useSharedContact";

export const ContactDetailsGeneral = () => {
  const t = useTranslations();
  const { data: organisations } = useOrganizationsTree();
  const { data, update } = useSharedContact();

  return (
    <Column style={{ width: "784px" }} type="top" align="start">
      <h1>{t("Contacts:edit")}</h1>
      <Spacer size={8} />
      <p>{t("Contacts:edit_description")}</p>

      <Spacer size={32} />

      <ContactForm
        organisations={organisations}
        onSubmit={update}
        defaultValues={data}
        isModal={false}
        hideTime
        privateContact={!!data?.userId}
      />
    </Column>
  );
};
