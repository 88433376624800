import { createContext, useEffect, useMemo, useRef, useState } from "react";
import {
  getTransmitter,
  getTransmitterCredentials,
  saveTransmitter,
} from "../../../core/api/transmitters/transmitters";
import {
  EditTransmitterData,
  TransmitterCredentials,
  TransmitterModel,
} from "../../../core/api/transmitters/types";
import useUser from "../../../core/user/useUser";
import { notifyApiErrors } from "../../../core/helpers/helpers";

export const SharedTransmitterContext = createContext<{
  transmitterData?: TransmitterModel;
  credentialsData?: TransmitterCredentials[];
  isLoading: boolean;
  isError: boolean;
  update: (transmitterData: any) => Promise<void>;
  fetchTransmitter: () => Promise<void>;
}>({
  transmitterData: undefined,
  credentialsData: undefined,
  isLoading: false,
  isError: false,
  update: async () => {},
  fetchTransmitter: async () => {},
});

export const SharedTransmitterProvider = ({
  transmitterId,
  children,
}: {
  transmitterId: string;
  children: React.ReactNode;
}) => {
  const isAlive = useRef(false);
  const { authenticatedRequest } = useUser();

  const [transmitterData, setTransmitterData] = useState<TransmitterModel>();
  const [credentialsData, setCredentialsData] =
    useState<TransmitterCredentials[]>();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const fetchTransmitter = async () => {
    setIsError(false);
    setIsLoading(true);

    try {
      const trans = await getTransmitter(transmitterId, authenticatedRequest);
      let credentails;
      if (trans.data.sipCredentialType) {
        credentails = await getTransmitterCredentials(
          transmitterId,
          authenticatedRequest
        );
      }
      if (isAlive.current) {
        setTransmitterData(trans.data);
        if (credentails) {
          setCredentialsData(credentails.data);
        }
      }
    } catch (error: any) {
      if (isAlive.current) {
        setIsError(true);
        notifyApiErrors(error.response?.data?.errors);
      }
    }

    if (isAlive.current) {
      setIsLoading(false);
    }
  };

  const update = async (transmitterDetails: EditTransmitterData) => {
    setIsLoading(true);

    try {
      const { data: response } = await saveTransmitter(
        transmitterDetails,
        authenticatedRequest
      );

      if (isAlive.current) {
        if (response) {
          fetchTransmitter();
          setIsLoading(false);
        }
      }
    } catch (error: any) {
      if (isAlive.current) {
        setIsLoading(false);

        notifyApiErrors(error.response?.data?.errors);
      }
    }
  };

  // Fetch object on mount and when object is updated
  useEffect(() => {
    fetchTransmitter();
  }, [transmitterId]);

  // To make sure we do not try to update state if component did unmount
  useEffect(() => {
    isAlive.current = true;

    return () => {
      isAlive.current = false;
    };
  }, []);

  const providerValue = useMemo(
    () => ({
      transmitterData,
      credentialsData,
      isLoading,
      isError,
      update,
      fetchTransmitter,
    }),
    [transmitterData, credentialsData, isLoading]
  );

  return (
    <SharedTransmitterContext.Provider value={providerValue}>
      {children}
    </SharedTransmitterContext.Provider>
  );
};
