import { CSSProperties } from "react";
import Checkbox, { ICheckbox } from "../Checkbox/Checkbox";
import AccordionBase from "./Accordion";
import styles from "./Accordion.module.css";

export interface AccordionProps {
  title: string;
  description: string;
  icon: string;
  items: ICheckbox[];
  onCheck(arg: ICheckbox): void;
  selectedItemsNum: number;
  containerStyle?: CSSProperties;
  isEditable?: boolean;
  accordionKey?: string;
}

export default function CheckboxListAccordion({
  title,
  description,
  icon,
  items,
  onCheck,
  selectedItemsNum,
  containerStyle,
  isEditable = true,
  accordionKey,
}: Readonly<AccordionProps>) {
  const details = `${selectedItemsNum}/${items.length}`;

  return (
    <AccordionBase
      title={title}
      description={description}
      icon={icon}
      details={details}
      bodyClassName={styles.tintedBody}
      containerStyle={containerStyle}
      accordionKey={accordionKey}
    >
      {items.map((item) => (
        <Checkbox
          label={item.label}
          description={item.description}
          checked={item.checked}
          onChange={() => onCheck(item)}
          fullWidth
          style={{ marginBottom: "15px" }}
          key={item.id}
          disabled={!isEditable}
        />
      ))}
    </AccordionBase>
  );
}
