import { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Spacer } from "../../components/Layout/Layout";
import Button, { ButtonVariant } from "../../ui-lib/components/Button/Button";
import { ITreeData } from "../../ui-lib/components/Hierarchy/Tree";
import { notifyApiErrors } from "../../core/helpers/helpers";
import { CameraEditForm, EditCameraActions } from "./CameraEditForm";
import useTranslations from "../../core/i18n/useTranslations";
import PrimeModal from "../../ui-lib/components/PrimeModal/PrimeModal";

export const CreateCameraModal = ({
  onSubmit,
  onClose,
  modalTitle,
  variant = "primary",
  modalButtonIcon,
  saveButtonTitle,
  modalButtonTitle,
  organisationTree,
  fromStock = false,
}: {
  onSubmit?: (result?: number) => void;
  onClose?: () => void;
  modalTitle: string;
  variant: ButtonVariant;
  modalButtonIcon?: string;
  saveButtonTitle: string;
  modalButtonTitle: string;
  organisationTree: ITreeData[];
  fromStock?: boolean;
}) => {
  const t = useTranslations();
  const editCameraRef = useRef<EditCameraActions>(null);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isNewCameraOpen, setIsNewCameraOpen] = useState(false);
  const history = useHistory();
  const closeModal = () => {
    editCameraRef.current?.reset();
    setIsNewCameraOpen(false);
    onClose?.();
  };

  const submit = async () => {
    setIsSubmitting(true);
    try {
      const result = await editCameraRef.current?.submitForm();

      if (result?.status) {
        if (!fromStock) {
          history.push(`/adminportal/cameras/${result.data}`);
        }
        onSubmit?.(result.data);
        closeModal();
      }
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    }
    setIsSubmitting(false);
  };
  return (
    <>
      <Button
        variant={variant || "primary"}
        text={modalButtonTitle}
        image={modalButtonIcon}
        onClick={() => {
          setIsNewCameraOpen(true);
        }}
      />
      <PrimeModal
        withHeader
        withFooter
        header={modalTitle}
        onClose={closeModal}
        isOpen={isNewCameraOpen}
        submitBtn={{ text: saveButtonTitle, onClick: submit }}
        cancelBtn={{ text: t("Common:cancel"), onClick: closeModal }}
        loading={isSubmitting}
        contentStyle={{ width: "848px" }}
      >
        <p>{t("Locations:create_new_description")}</p>
        <Spacer size={16} />
        <CameraEditForm
          ref={editCameraRef}
          organisationTree={organisationTree}
          isNewCamera
        />
      </PrimeModal>
    </>
  );
};
